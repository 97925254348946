<template>
  <div ref="tableWrapper" class="full-width q-mt-md q-pt-lg relative-position column scrollable-section">
    <div class="">
      <div class="row items-baseline border-bottom r-p-all">
        <div class="col">
          <span class="r-heading-3 q-pr-lg q-pl-md border-right">
            2.   Bulk code search results
          </span>
          <span class="q-caption on-right">
            {{ researchOutputsForBulkCode.length }} results found
          </span>
        </div>

        <div class="col r-p-side q-pb-sm text-right">
          <template v-if="!codedListVisible">
            <q-btn
              flat
              label="Validate"
              color="secondary"
              class="on-left"
              @click="validate"
            />
            <q-btn label="Submit valid claims" color="secondary" @click="confirmSubmission" />
          </template>
        </div>
        <q-btn
          round
          color="mq-dark"
          dark
          class="self-start"
          icon="fullscreen"
          @click="toggleFullscreen"
        />
      </div>
      <div class="row">
        <div class="col">
          <div class="row justify-between items-baseline border-bottom border-secondary">
            <div class="col-auto">
              <q-tabs v-model="visibleList" inverted color="secondary">
                <q-tab slot="title" name="pending">
                  Pending
                </q-tab>
                <q-tab slot="title" name="coded">
                  Coded - {{ codedResearchOutputs.length }}
                </q-tab>
              </q-tabs>
            </div>
            <div class="col-4 q-pr-lg">
              <q-search
                v-model="filter"
                placeholder="Filter"
                icon="mdi-filter-outline"
                class="col-3 text-black no-shadow filter-bar-dense"
                color="white"
                inverted
                clearable
              />
            </div>
          </div>
          <div class="col-12">
            <q-btn
              flat
              color="secondary"
              size="sm"
              class="q-mt-sm"
              @click="selectAllPages"
            >
              Select all {{ currentResearchOutputsForBulk.length }}
            </q-btn>
            <q-btn
              v-if="!codedListVisible"
              flat
              color="secondary"
              class="on-right q-mt-sm"
              size="sm"
              @click="deSelectAllPages"
            >
              Remove claims from selected
            </q-btn>
          </div>
        </div>
        <div class="bulk-claim-wrapper new-claim-area bg-mq-sand q-pt-sm q-pl-md">
          <bulk-claim
            v-if="!codedListVisible "
            :forcs="userForcsForRoClaim"
            class="justify-center q-pt-sm"
            :disable="!selected.length"
            @addClaim="addClaimToSelected"
          />
        </div>
      </div>
    </div>
    <q-table
      ref="tableWithData"
      :data="currentResearchOutputsForBulk"
      table-class="bg-white"
      class="bulk-code-table scrollable-section"
      :columns="columns"
      selection="multiple"
      :selected.sync="selected"
      :pagination.sync="pagination"
      row-key="id"
      :rows-per-page-options="[20, 30, 40, 50]"
      :filter="filter"
      binary-state-sort
      no-data-label="No data found"
      no-results-label="No data matches your filter"
    >
      <div v-if="props.inFullscreen" slot="top" slot-scope="props" class="fit">
        <div class="row items-baseline border-bottom r-p-all">
          <div class="col">
            <span class="r-heading-3 q-pr-lg q-pl-md border-right">
              2.   Bulk code search results
            </span>
            <span class="q-caption on-right">
              {{ researchOutputsForBulkCode.length }} results found
            </span>
          </div>

          <div class="col r-p-side q-pb-sm text-right">
            <template v-if="!codedListVisible">
              <q-btn
                flat
                label="Validate"
                color="secondary"
                class="on-left"
                @click="validate"
              />
              <q-btn label="Submit valid claims" color="secondary" @click="confirmSubmission" />
            </template>
          </div>
          <q-btn
            round
            color="mq-dark"
            dark
            class="self-start"
            :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
            @click="props.toggleFullscreen"
          />
        </div>
        <div class="row">
          <div class="col">
            <div class="row justify-between items-baseline border-bottom border-secondary">
              <div class="col-auto">
                <q-tabs v-model="visibleList" inverted color="secondary">
                  <q-tab slot="title" name="pending">
                    Pending
                  </q-tab>
                  <q-tab slot="title" name="coded">
                    Coded - {{ codedResearchOutputs.length }}
                  </q-tab>
                </q-tabs>
              </div>
              <div class="col-4 q-pr-lg">
                <q-search
                  v-model="filter"
                  placeholder="Filter"
                  icon="mdi-filter-outline"
                  class="col-3 text-black no-shadow filter-bar-dense"
                  color="white"
                  inverted
                  clearable
                />
              </div>
            </div>
            <div class="col-12">
              <q-btn
                flat
                color="secondary"
                size="sm"
                class="q-mt-sm"
                @click="selectAllPages"
              >
                Select all {{ currentResearchOutputsForBulk.length }}
              </q-btn>
              <q-btn
                v-if="!codedListVisible"
                flat
                color="secondary"
                class="on-right q-mt-sm"
                size="sm"
                @click="deSelectAllPages"
              >
                Remove claims from selected
              </q-btn>
            </div>
          </div>
          <div class="bulk-claim-wrapper new-claim-area bg-mq-sand q-pt-sm q-pl-md">
            <bulk-claim
              v-if="!codedListVisible "
              :forcs="userForcsForRoClaim"
              class="justify-center q-pt-sm"
              :disable="!selected.length"
              @addClaim="addClaimToSelected"
            />
          </div>
        </div>
      </div>
      <q-tr slot="header" slot-scope="props">
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
          :class="col.classes"
          :style="col.style"
        >
          <div v-if="col.name==='selected'">
            <q-checkbox v-model="props.selected" indeterminate-value="some" size="sm" />
          </div>
          <q-checkbox
            v-else-if="col.name==='title'"
            v-model="allExpandMode"
            color="primary"
            checked-icon="keyboard_arrow_down"
            unchecked-icon="chevron_right"
            keep-color
            @input="setAllExpandedSlots"
          />
          {{ col.label }}
        </q-th>
      </q-tr>
      <template slot="body" slot-scope="props">
        <q-tr :props="props">
          <q-td auto-width class="bg-white">
            <div>
              <q-checkbox v-model="props.selected" />
            </div>
          </q-td>
          <q-td
            key="title"
            :props="props"
            :style="props.colsMap.title.style"
            :class="props.colsMap.title.classes"
          >
            <div class="row items-start items-baseline">
              <q-checkbox
                v-model="expansionSlots[props.row.id]"
                color="primary"
                checked-icon="keyboard_arrow_down"
                unchecked-icon="chevron_right"
                keep-color
              />
              <div class="col">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span class="overflow-hidden wrap" v-html="props.row.effectiveTitle()" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <q-tooltip><span v-html="props.row.effectiveTitle()" /></q-tooltip>
              </div>
            </div>
          </q-td>
          <q-td
            key="abstract"
            :props="props"
            :style="props.colsMap.abstract.style"
            :class="props.colsMap.abstract.classes"
          >
            <span>
              <MQBylineLogo v-if="props.row.has_bylines" class="mq-byline" />
              <a
                v-for="(pdfUrl,index) in props.row.electronic_locations"
                :key="index"
                :href="pdfUrl"
                target="_blank"
                class="icon-link items-baseline"
              >
                <q-icon color="mq-soft" name="description" />
              </a>
              <span v-if="!props.row.electronic_locations.length">No pdf</span>
            </span>
            <q-btn
              flat
              dense
              class="icon-link no-padding"
              icon="notes"
              @click="openAbstract(props.row)"
            />
          </q-td>
          <q-td
            key="authors"
            :props="props"
            :style="props.colsMap.authors.style"
            :class="props.colsMap.authors.classes"
          >
            {{ props.row.getInternalAuthorsAsCsv() }}
          </q-td>
          <q-td
            key="journalName"
            :props="props"
            :style="props.colsMap.journalName.style"
            :class="props.colsMap.journalName.classes"
          >
            <span class="two-line-wrapper">
              <span v-html="props.row.journal_name" /><br>
              <span class="q-caption"> {{ props.row.getJournalForcsRepresentation() }}</span>
              <q-tooltip>{{ props.row.journal_name }}</q-tooltip>
            </span>
          </q-td>
          <q-td
            key="claim1"
            :props="props"
            :style="props.colsMap.claim1.style"
            :class="props.colsMap.claim2.classes"
          >
            <popup-editable-claim
              v-if="(props.row.ro_claims.length>=1)"
              :claim="props.row.ro_claims[0]"
              class="full-width"
              :research-output-for-bulk="props.row"
              :forcs="forcsForWorkingEra"
              :under-edit="isUnderEdit(props.row, 0)"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :disable="codedListVisible"
              @edit="edit(props.row, 0)"
              @removeEdit="cancelEdit"
              @delete="deleteClaim(props.row, 0)"
            />
            <bulk-single-claim-add
              v-else-if="!codedListVisible"
              :under-edit="newClaimAt.researchOutput===props.row"
              :research-output-for-bulk="props.row"
              :claim="newClaimAt.claim"
              :forcs="forcsForWorkingEra"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              @initiateEdit="initiateAddNewClaimFor(props.row)"
              @add="addNewClaim"
              @removeEdit="cancelEdit"
            />
          </q-td>
          <q-td
            key="claim2"
            :props="props"
            :style="props.colsMap.claim2.style"
            :class="props.colsMap.claim2.classes"
          >
            <popup-editable-claim
              v-if="(props.row.ro_claims.length>=2)"
              :claim="props.row.ro_claims[1]"
              class="full-width"
              :research-output-for-bulk="props.row"
              :forcs="forcsForWorkingEra"
              :under-edit="isUnderEdit(props.row, 1)"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :disable="codedListVisible"
              @edit="edit(props.row, 1)"
              @removeEdit="cancelEdit"
              @delete="deleteClaim(props.row, 1)"
            />
            <bulk-single-claim-add
              v-else-if="!codedListVisible && props.row.ro_claims.length === 1"
              :under-edit="newClaimAt.researchOutput === props.row"
              :research-output-for-bulk="props.row"
              :claim="newClaimAt.claim"
              :forcs="forcsForWorkingEra"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              @initiateEdit="initiateAddNewClaimFor(props.row)"
              @add="addNewClaim"
              @removeEdit="cancelEdit"
            />
            <div v-else class="fit bg-mq-sand-dark" />
          </q-td>
          <q-td
            key="claim3"
            :props="props"
            :style="props.colsMap.claim3.style"
            :class="props.colsMap.claim3.classes"
          >
            <popup-editable-claim
              v-if="(props.row.ro_claims.length===3)"
              :claim="props.row.ro_claims[2]"
              class="full-width"
              :research-output-for-bulk="props.row"
              :forcs="forcsForWorkingEra"
              :under-edit="isUnderEdit(props.row, 2)"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :disable="codedListVisible"
              @edit="edit(props.row, 2)"
              @removeEdit="cancelEdit"
              @delete="deleteClaim(props.row, 2)"
            />
            <bulk-single-claim-add
              v-else-if="!codedListVisible && props.row.ro_claims.length === 2"
              :claim="newClaimAt.claim"
              :under-edit="newClaimAt.researchOutput===props.row"
              :research-output-for-bulk="props.row"
              :forcs="forcsForWorkingEra"
              :user-forcs="userForcsForRoClaim"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              @initiateEdit="initiateAddNewClaimFor(props.row)"
              @add="addNewClaim"
              @removeEdit="cancelEdit"
            />
            <div v-else class="fit bg-mq-sand-dark" />
          </q-td>
          <q-td
            key="validations"
            :props="props"
            :style="props.colsMap.validations.style"
            :class="props.colsMap.validations.classes"
          >
            <div class="validation-field">
              <q-btn
                v-if="props.row.isValid()"
                round
                dense
                flat
                size="16px"
                color="positive"
                icon="check_circle"
                class="no-pointer-events"
              />
              <q-btn
                v-if="props.row.isInvalid()"
                round
                dense
                flat
                size="16px"
                color="negative"
                icon="warning"
                @click="toggleExpanded(props.row.id)"
              />
            </div>
          </q-td>
        </q-tr>
        <q-tr
          v-show="isExpanded(props.row.id)"
          :props="props"
          class="bg-white extra-row-info"
          style="margin-top: -1px"
        >
          <q-td colauto />
          <q-td colspan="100%" class="text-left extra-info-col">
            <div class="r-p-large-left">
              <div class="row" />
              <div class="row">
                <div class="col-auto term-title-condensed line-height-large">
                  Managing org:
                </div>
                <div
                  class="col term-definition-condensed line-height-large"
                  v-html="props.row.managing_organisation_name"
                />
              </div>
              <div class="row">
                <div class="col-auto term-title-condensed line-height-large">
                  Year:
                </div>
                <div class="col term-definition-condensed line-height-large">
                  {{ props.row.pub_year }}
                </div>
              </div>
              <div class="row">
                <div class="col-auto term-title-condensed line-height-large">
                  Internal Authors:
                </div>
                <div class="col row term-definition-condensed line-height-large">
                  <span
                    v-for="author in props.row.getInternalAuthors()"
                    :key="'author-'+author.id"
                    class="col-auto"
                  >
                    <router-link
                      class="q-mr-xs author-names extra-info-col"
                      :to="{name: 'researcher-details',params: {researcherId: author.id}}"
                      target="_blank"
                    >{{ author.researcher_name }}&semi;
                    </router-link>
                  </span>
                </div>
              </div>
              <div v-if="props.row.getAbdcRanking()" class="row">
                <div class="col-auto term-title-condensed line-height-large">
                  ABDC:
                </div>
                <div
                  class="col term-definition-condensed line-height-large"
                  v-html="props.row.getAbdcRanking()"
                />
              </div>
            </div>
            <div
              v-if="props.row.isInvalid()"
              class="q-pa-md q-ml-sm q-my-sm border border-primary round-borders bg-white bulk-error-wrapper bg-mq-sand"
            >
              <grid-validation-errors :validation="props.row.validation" />
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <radar-loading :visible="searchingOrSaving" />
    <radar-modal v-model="isBulkCodeConfirmDisplayed">
      <bulk-code-confirm-modal
        ref="bulkCodeConfirm"
        :valid-research-outputs="validResearchOutputs"
        @saved="bulkCodeSaved"
        @cancel="hideBulkCodeConfirm"
        @error="$notify.failure"
      />
    </radar-modal>
    <research-output-abstract-modal
      ref="abstract"
      :title="abstractTitle"
      :abstract="abstract"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { booleanPromise } from '@/utils/promise-util';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import RadarModal from '@/components/common/RadarModal.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import ResearchOutputAbstractModal from '@/components/research-output/ResearchOutputAbstractModal.vue';
import MQBylineLogo from '@/components/common/MQBylineLogo.vue';
import { bulkFields } from './bulk-fields';
import BulkClaim from './BulkClaim.vue';
import BulkSingleClaimAdd from './BulkSingleClaimAdd.vue';
import PopupEditableClaim from './PopupEditableClaim.vue';
import BulkCodeConfirmModal from './BulkCodeConfirmModal.vue';

export default {
  name: 'BulkCodeList',
  components: {
    BulkCodeConfirmModal,
    RadarLoading,
    GridValidationErrors,
    BulkSingleClaimAdd,
    PopupEditableClaim,
    BulkClaim,
    RadarModal,
    ResearchOutputAbstractModal,
    MQBylineLogo,
  },
  mixins: [AllFieldsExpandableTable],
  data() {
    return {
      filter: '',
      rowUnderEdit: undefined,
      columns: bulkFields,
      selected: [],
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      underEdit: {
        researchOutput: undefined,
        claimIndex: undefined,
      },
      newClaimAt: {
        researchOutput: undefined,
        claim: new ResearchOutputClaim(),
      },
      visibleList: 'pending',
      isBulkCodeConfirmDisplayed: false,
      validResearchOutputs: [],
      abstractTitle: undefined,
      abstract: undefined,
    };
  },
  computed: {
    ...mapState('bulkCode', {
      researchOutputsForBulkCode: state => state.researchOutputsForBulkCode,
      codedResearchOutputs: state => state.codedResearchOutputs,
      bulkProgress: state => state.progress,
    }),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsForWorkingEra']),
    ...mapGetters('auth', ['userForcsForRoClaim', 'clawbackBypassedForcs']),
    ...mapGetters('bulkCode', { researchOutputsPendingCoding: 'pending' }),
    currentResearchOutputsForBulk() {
      if (this.visibleList === 'coded') {
        return this.codedResearchOutputs;
      }
      return this.researchOutputsPendingCoding;
    },
    codedListVisible() {
      return this.visibleList === 'coded';
    },
    searchingOrSaving() {
      return this.bulkProgress.searching || this.bulkProgress.saving;
    },
    expansionSlotDataItems() {
      return this.currentResearchOutputsForBulk;
    },
  },
  methods: {
    ...mapActions('bulkCode', ['addToCoded']),
    initiateAddNewClaimFor(researchOutput) {
      this.cancelEdit();
      this.newClaimAt.researchOutput = researchOutput;
      this.newClaimAt.claim = new ResearchOutputClaim();
    },
    cancelAddNewClaim() {
      this.newClaimAt.researchOutput = undefined;
      this.newClaimAt.claim = new ResearchOutputClaim();
    },
    addNewClaim() {
      this.newClaimAt.researchOutput.addClaim(this.newClaimAt.claim);
      this.newClaimAt.researchOutput.revalidate();
      this.cancelAddNewClaim();
      this.cancelEdit();
    },
    addClaimToSelected(claim) {
      this.cancelEdit();
      this.cancelAddNewClaim();
      this.selected.forEach((ro) => {
        ro.addClaim(new ResearchOutputClaim(claim));
        ro.revalidate();
      });
    },
    edit(researchOutput, claimIndex) {
      this.underEdit.researchOutput = researchOutput;
      this.underEdit.claimIndex = claimIndex;
      this.cancelAddNewClaim();
    },
    deleteClaim(researchOutput, claimIndex) {
      this.cancelEdit();
      researchOutput.removeClaimAt(claimIndex);
      researchOutput.revalidate();
    },
    isUnderEdit(researchOutput, claimIndex) {
      return this.underEdit.researchOutput === researchOutput
        && this.underEdit.claimIndex === claimIndex;
    },
    cancelEdit() {
      if (this.underEdit.researchOutput) {
        this.underEdit.researchOutput.revalidate();
      }
      this.underEdit = {
        researchOutput: undefined,
        claimIndex: undefined,
      };
    },
    deSelectAllPages() {
      this.clearAllClaimsOnSelected();
      this.selected = [];
    },
    clearAllClaimsOnSelected() {
      this.selected.forEach(ro => ro.clearClaims());
    },
    selectAllPages() {
      this.selected = [...this.researchOutputsForBulkCode];
    },
    notifyNoValidResearchOutputs() {
      return booleanPromise(this.$q.dialog({
        title: 'Bulk code',
        message: 'No valid claims.',
        color: 'negative',
        ok: false,
        cancel: 'OK',
      }));
    },
    validate() {
      this.cancelEdit();
      this.cancelAddNewClaim();
      return !_.includes(this.researchOutputsForBulkCode
        .map(ro => ro.validate(this.clawbackBypassedForcs)), false);
    },
    removeFromSelection(reserachOutputs) {
      this.selected = _.difference(this.selected, reserachOutputs);
    },
    moveToCoded(researchOutputs) {
      this.removeFromSelection(researchOutputs);
      this.addToCoded(researchOutputs);
    },
    openAbstract(researchOutput) {
      this.abstractTitle = researchOutput.is_translated
        ? researchOutput.title_translated
        : researchOutput.title_nativescript;
      this.abstract = researchOutput.abstract;
      this.$refs.abstract.open();
    },
    async confirmSubmission() {
      this.$notify.clear();
      this.validate();
      this.validResearchOutputs = this.researchOutputsPendingCoding.filter(ro => ro.hasClaims() && ro.isValid());

      if (this.validResearchOutputs.length === 0) {
        await this.notifyNoValidResearchOutputs();
        return;
      }
      this.isBulkCodeConfirmDisplayed = true;
    },
    async bulkCodeSaved() {
      this.isBulkCodeConfirmDisplayed = false;
      this.$notify.success(`${this.validResearchOutputs.length} research outputs successfully submitted as bulk coding.
        These have been moved to the Coded tab for your reference`);
      this.moveToCoded(this.validResearchOutputs);
    },
    hideBulkCodeConfirm() {
      this.isBulkCodeConfirmDisplayed = false;
    },
    toggleFullscreen() {
      this.$refs.tableWithData.toggleFullscreen();
    },
  },
  watch: {
    researchOutputsForBulkCode() {
      this.selected = [];
      this.setAllExpandedSlots(this.allExpandMode);
    },
  },
};
</script>
<style lang="stylus" scoped>
  .forc-selector-min
    width: 4rem

  .apportion-min
    width: 4rem

  .bulk-claim-wrapper, .bulk-error-wrapper
    width 637px

  .bulk-error-wrapper
    & > div
      margin-right 37px

  .bulk-claim-wrapper
    min-height: 57px;

  .authors-wrapper
    min-height 2rem
    max-width 1px
    height 100%
    overflow hidden
    word-wrap break-word
    border 1px solid yellow

  .term-title-condensed
    min-width: 102px !important

  .bulk-code-table
    >>> table
      table-layout: fixed

    >>> .q-table-top:empty
      display none

    &.fullscreen
      >>> .q-table-top
        position fixed
        top 0
        left 0
        right 0
        padding 0
        background #fff
        z-index 910

      >>> .q-table-middle
        padding-top 147px

        th
          top 147px
</style>
