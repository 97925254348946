import Role from './role';

class RolePermission {
  constructor(roleAndPermission = {}) {
    this.role = new Role(roleAndPermission.role);
    this.era_faculty_id = roleAndPermission.era_faculty_id;
    this.forc_ids_by_era = roleAndPermission.era_forcs || [];
    this.clawback_bypassed_forcs_ids_by_era = roleAndPermission.era_clawback_bypassed_forcs || [];
  }

  static fromList(rolePermissions = []) {
    return rolePermissions
      .map(roleAndPermission => new RolePermission(roleAndPermission));
  }

  getForcsForActions(actions) {
    if (!this.hasPermission(actions)) { return []; }
    return this.forc_ids_by_era;
  }

  getFacultiesForActions(actions) {
    if (!this.hasPermission(actions)) { return []; }
    return this.era_faculty_id;
  }

  hasPermission(actions) {
    return this.role.hasPermissions(actions);
  }

  getForcsForBypassingClawback() {
    return this.clawback_bypassed_forcs_ids_by_era;
  }

  hasViewCitationRole() {
    return this.role.code_name === 'view_citation';
  }
}

export default RolePermission;
