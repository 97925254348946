<template>
  <q-page class="layout-height bg-white">
    <portal to="app-header">
      <div class="page-title row items-center justify-between">
        <div>
          ERA submissions overview
        </div>
        <q-btn
          :to="{name: 'new-era'}"
          icon="add"
          color="primary"
          :disable="!!hasActiveAndUpcomingEras"
        >
          Create New
        </q-btn>
      </div>
    </portal>
    <div class="q-pa-lg row justify-around">
      <div class="col-xs-12 col-lg-10 col-xl-9">
        <q-table
          :data="eras"
          table-class="bg-white"
          class="eras-table"
          :columns="columns"
          row-key="id"
          :rows-per-page-options="[0]"
          hide-bottom
          no-data-label="No data found"
        >
          <q-tr slot="header" slot-scope="props">
            <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
              :class="col.classes"
              class="bg-white"
              :style="col.style"
            >
              <div class="term-title-condensed text-mq-soft wrappable-table-column">
                {{ col.label }}
              </div>
            </q-th>
          </q-tr>
          <template slot="body" slot-scope="props">
            <q-tr :props="props">
              <q-td
                key="name"
                :props="props"
                :style="props.colsMap.name.style"
                :class="props.colsMap.name.classes"
                class="border-right"
              >
                <router-link
                  v-if="canUserManageEra"
                  :to="{ name: 'era-details', params: { eraId: props.row.id } }"
                  class="text-mq-highlight link-button wrappable-table-column"
                >
                  {{ props.row.name }}
                </router-link>
                <span v-else class="text-mq-highlight wrappable-table-column">
                  {{ props.row.name }}
                </span>
              </q-td>
              <q-td
                key="researchOutput"
                :props="props"
                :style="props.colsMap.researchOutput.style"
                :class="props.colsMap.researchOutput.classes"
              >
                <div class="wrappable-table-column">
                  {{ props.row.research_output_start_year }}-{{ props.row.research_output_end_year }}
                </div>
              </q-td>
              <q-td
                key="researchIncome"
                :props="props"
                :style="props.colsMap.researchIncome.style"
                :class="props.colsMap.researchIncome.classes"
              >
                <div class="wrappable-table-column">
                  {{ props.row.research_income_start_year }}-{{ props.row.research_income_end_year }}
                </div>
              </q-td>
              <q-td
                key="censusDate"
                :props="props"
                :style="props.colsMap.censusDate.style"
                :class="props.colsMap.censusDate.classes"
              >
                <div class="wrappable-table-column">
                  {{ props.row.census_date }}
                </div>
              </q-td>
              <q-td
                key="status"
                :props="props"
                :style="props.colsMap.status.style"
                :class="props.colsMap.status.classes"
              >
                <era-status :era="props.row" dense />
              </q-td>
              <q-td
                v-if="canUserManageEra"
                key="actions"
                :props="props"
                :style="props.colsMap.actions.style"
                :class="props.colsMap.actions.classes"
              >
                <q-btn
                  v-if="props.row.isActive() || props.row.isUpcoming()"
                  label="View/Edit"
                  color="mq-soft-muted"
                  class="q-mr-md"
                  :to="{ name: 'era-details', params: { eraId: props.row.id } }"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EraStatus from './EraStatus.vue';

export default {
  name: 'EraList',
  components: { EraStatus },
  data() {
    return {
      baseColumns: [
        {
          name: 'name',
          label: 'Name',
          align: 'center',
          field: 'name',
          classes: 'bg-white',
        },
        {
          name: 'researchOutput',
          label: 'Research output',
          align: 'center',
          classes: 'bg-mq-neutral',
        },
        {
          name: 'researchIncome',
          label: 'Research income',
          align: 'left',
          classes: 'bg-mq-neutral',
        },
        {
          name: 'censusDate',
          label: 'Census date',
          align: 'left',
          field: 'census_date',
          classes: 'bg-mq-neutral',
        },
        {
          name: 'status',
          label: '',
          align: 'left',
          field: 'submission_status',
          classes: 'bg-mq-neutral',
        },

      ],
    };
  },
  computed: {
    ...mapState('eras', ['eras']),
    ...mapGetters('eras', ['hasActiveAndUpcomingEras']),
    ...mapGetters('auth', ['canUserManageEra']),
    columns() {
      const actionsField = {
        name: 'actions',
        label: '',
        align: 'right',
        field: '',
        classes: 'bg-mq-neutral',
      };
      return [
        ...this.baseColumns,
        ...(this.canUserManageEra ? [actionsField] : []),
      ];
    },
  },
  methods: {
    ...mapActions('eras', ['getAllEras']),
    viewDetails(era) {
      this.$router.push({ name: 'era-details', params: { eraId: era.id } });
    },
  },
  mounted() {
    this.getAllEras();
  },
};
</script>

<style scoped lang="stylus">
.eras-table
  >>> th
    border-bottom 1px solid #888

.term-title-condensed
  font-weight 500
  text-transform uppercase
</style>
