class PeerReviewCategory {
  constructor(peerReviewCategory = {}) {
    this.threshold = peerReviewCategory.threshold;
    this.tagged_count = peerReviewCategory.tagged_count;
  }

  get tagged() {
    return this.tagged_count;
  }

  hasBeenTaggedAboveThreshold() {
    return this.tagged_count >= this.threshold;
  }
}

export default PeerReviewCategory;
