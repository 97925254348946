import _ from 'lodash';
import api from '@/api';
import { getNonEmptyValues } from '@/utils/search';
import AppliedMeasure from '@/models/applied-measure/applied-measure';
import AppliedMeasureStatistics from '@/models/applied-measure/applied-measure-statistics';

export default {
  namespaced: true,
  state: {
    searchPagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    appliedMeasures: [],
    appliedMeasuresStatistics: [],
    types: [
      'PATENT_FAMILY: Patent Family',
      'PLANT_BREEDER_RIGHTS_FAMILY: Plant Breeder Rights Family',
      'REGISTERED_DESIGN: Registered Design',
      'NHMRC_ENDORSED_GUIDELINE: NHMRC Endorsed Guidelines',
    ],
    editByChoices: [
      'ME: Myself',
      'MY_FACULTY: My Faculty',
      'ANYONE: Anyone',
    ],
    progress: {
      searching: false,
      fetchingAppliedMeasures: false,
      savingClaim: false,
      fetchingClaims: false,
      deletingClaim: false,
    },
  },
  mutations: {
    UPDATE_APPLIED_MEASURES(state, { appliedMeasures, currentPage, totalResults }) {
      state.appliedMeasures = appliedMeasures;
      state.searchPagination.currentPage = currentPage || 1;
      state.searchPagination.totalResults = totalResults || 0;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
    UPDATE_APPLIED_MEASURES_STATISTICS(state, stats) {
      state.appliedMeasuresStatistics = stats;
    },
    UPDATE_EXISTING_APPLIED_MEASURES_WITH_LATEST_CLAIM_VERSION(state, updatedAppliedMeasures) {
      updatedAppliedMeasures.forEach((updatedItem) => {
        const index = _.findIndex(
          state.appliedMeasures,
          existing => existing.id === updatedItem.id,
        );
        if (index >= 0) {
          state.appliedMeasures.splice(index, 1, updatedItem);
        }
      });
    },
  },
  actions: {
    async searchAppliedMeasures({ commit }, searchParameters) {
      commit('UPDATE_PROGRESS', { searching: true });
      const params = getNonEmptyValues(searchParameters.toApiParams());
      const response = await api.appliedMeasures.search(params);
      const appliedMeasures = AppliedMeasure.fromList(response.data.results);
      commit('UPDATE_APPLIED_MEASURES', {
        appliedMeasures,
        currentPage: Number(params.page),
        totalResults: response.data.count,
      });
      commit('UPDATE_PROGRESS', { searching: false });
    },
    async getStatistics({ commit }, searchParameters) {
      const params = searchParameters.toApiParams();
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.appliedMeasures.getStatistics(params);
        const data = AppliedMeasureStatistics.toArray(response.data);
        commit('UPDATE_APPLIED_MEASURES_STATISTICS', data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async saveClaims({ commit }, claims) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        const bulkClaims = claims.map(claim => claim.basicClaimDetails());
        const response = await api.appliedMeasures.saveClaims(bulkClaims);
        commit(
          'UPDATE_EXISTING_APPLIED_MEASURES_WITH_LATEST_CLAIM_VERSION',
          AppliedMeasure.fromList(response.data),
        );
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
    async saveEligibilities({ commit }, eligibilities) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        await api.appliedMeasures.saveEligibilities(eligibilities);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
  },
  getters: {},
};
