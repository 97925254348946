<template>
  <div class="flex items-center justify-end">
    <span>{{ currentPageMin }}-{{ currentPageMax }} OF {{ totalResults }}</span>
    <q-btn
      :disable="isFirstPage"
      icon="keyboard_arrow_left"
      flat
      size="sm"
      round
      dense
      @click="previous"
    />
    <q-btn
      :disable="isLastPage"
      icon="keyboard_arrow_right"
      flat
      size="sm"
      round
      dense
      @click="next"
    />
  </div>
</template>

<script>
export default {
  name: 'PaginationTotalCount',
  props: {
    pageSize: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    isInEditMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPageMin() {
      if (this.totalPages === 0) {
        return 0;
      }
      return ((this.currentPage - 1) * this.pageSize) + 1;
    },
    currentPageMax() {
      return Math.min(this.currentPage * this.pageSize, this.totalResults);
    },
    totalPages() {
      return Math.ceil(this.totalResults / this.pageSize);
    },
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    next() {
      if (this.isInEditMode) {
        this.$emit('abort', this.next);
      } else {
        this.change(this.currentPage + 1);
      }
    },
    previous() {
      if (this.isInEditMode) {
        this.$emit('abort', this.previous);
      } else {
        this.change(this.currentPage - 1);
      }
    },
    change(page) {
      this.$emit('change', page);
    },
  },
};
</script>

<style scoped>

</style>
