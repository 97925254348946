import Forc from '@/models/era/forc';

class UserAccessForc {
  constructor(userAccessForc = {}) {
    this.forc = new Forc(userAccessForc.forc);
  }

  static fromList(userAccessForcs = []) {
    return userAccessForcs
      .map(userAccessForc => new UserAccessForc(userAccessForc));
  }
}

export default UserAccessForc;
