<template>
  <q-list class="no-border relative-position scroll-y dense q-pa-none">
    <q-item v-if="!researchOutputs.length" class="r-p-all justify-center term-definition">
      No research outputs found. Please expand your search criteria.
    </q-item>
    <q-item
      v-for="(researchOutput, index) in researchOutputs"
      :key="'researchOutput-'+researchOutput.id +'-'+ index"
      class="q-pa-none"
    >
      <research-output-minimal
        :is-selected="isSelected(researchOutput)"
        :research-output="researchOutput"
        :class="{'bg-neutral': isSelected(researchOutput)}"
        @select="selectResearchOutput(researchOutput)"
      />
    </q-item>
    <slot name="pagination" />
    <slot v-if="researchOutputs.length" name="paginationTotal" />
    <radar-loading :visible="progress.searching" />
  </q-list>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ResearchOutputMinimal from './ResearchOutputMinimal.vue';

export default {
  name: 'ResearchOutputList',
  components: {
    RadarLoading,
    ResearchOutputMinimal,
  },
  computed: {
    ...mapState('researchOutputs', ['researchOutputs', 'progress']),
    ...mapState('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsByReferenceMetaId']),
    ...mapGetters('auth', ['userForcsForRoClaim']),
    ...mapGetters('researchOutputs', ['researchOutput']),
    forcsForWorkingEra() {
      return this.forcsByReferenceMetaId(this.workingEra.forc_reference_meta_id);
    },
  },
  methods: {
    ...mapActions('researchOutputs', ['fetchResearchOutput']),
    isSelected(researchOutput) {
      return this.researchOutput.id === researchOutput.id;
    },
    async selectResearchOutput(researchOutput) {
      if (!this.isSelected(researchOutput)) {
        await this.fetchResearchOutput({
          researchOutputId: researchOutput.id,
          eraId: this.workingEra.id,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
  .research-output-slider-open
    font-size 11.7px

  .highlighted-left-border
    border-left solid 1px $secondary

  .active-border
    border-bottom solid 1px $secondary

</style>
