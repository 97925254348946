<template>
  <q-modal v-model="isOpen" content-classes="modal-content-wrapper">
    <q-btn
      v-close-overlay
      color="mq-soft"
      round
      icon="close"
      flat
      dense
      class="absolute-top-right"
    />
    <div>
      <div class="r-heading-1-dense text-primary r-p-vertical">
        {{ researchOutput.portfolio.name }}
        <q-icon
          v-if="isFinalised"
          name="lock"
          size="24px"
          color="mq-dark"
          class="q-ml-sm"
        />
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="page-title-dense r-heading-1-dense r-p-vertical" v-html="researchOutput.effectiveTitle()" />
    </div>
    <div class="col-xs-12 full-height">
      <q-tabs ref="tabs" class="full-width" inverted>
        <q-tab slot="title" name="portfolioResearchStatementTab" label="Portfolio Research Statement" default />
        <q-tab slot="title" name="roResearchStatementTab" label="RO Research Statement" />
        <q-tab-pane name="portfolioResearchStatementTab" class="q-pr-none" keep-alive>
          <portfolio-research-statement-tab
            :research-output="researchOutput"
          />
        </q-tab-pane>
        <q-tab-pane name="roResearchStatementTab" class="q-pr-none" keep-alive>
          <research-output-research-statement-tab
            :research-statement="researchStatement"
            :research-output="researchOutput"
          />
        </q-tab-pane>
      </q-tabs>
    </div>
  </q-modal>
</template>

<script>

import { mapState } from 'vuex';
import ResearchOutput from '@/models/research-output/research-output';
import PortfolioResearchStatementTab from './PortfolioResearchStatementTab.vue';
import ResearchOutputResearchStatementTab from './ResearchOutputResearchStatementTab.vue';

export default {
  name: 'ResearchStatementModal',
  components: { ResearchOutputResearchStatementTab, PortfolioResearchStatementTab },
  props: {
    researchOutput: {
      type: ResearchOutput,
      default: () => new ResearchOutput(),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolioStatus']),
    ...mapState('researchStatements', ['researchStatement']),
    isFinalised() {
      return this.researchOutput.portfolio.status === this.portfolioStatus.finalised;
    },
  },
  methods: {
    async open() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
  },
  watch: {
    researchOutput() {
      this.$refs.tabs.selectTab('portfolioResearchStatementTab');
    },
  },
};
</script>
