import api from '@/api';
import DataQuality from '@/models/data-quality/data-quality';
import DataQualityDefinition from '@/models/data-quality/data-quality-definition';
import DataQualityCount from '@/models/data-quality/data-quality-count';
import DataQualityStats from '@/models/data-quality/data-quality-stats';

export default {
  namespaced: true,
  state: {
    dqChecks: [],
    statDetails: [],
    businessRules: [],
    searchPagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    progress: {
      searching: false,
      processing: false,
    },
  },
  mutations: {
    UPDATE_CHECKS(state, { data, page, totalResults }) {
      state.dqChecks = data;
      state.searchPagination.currentPage = Number(page) || 1;
      state.searchPagination.totalResults = totalResults || 0;
    },
    UPDATE_DQ_STATS(state, data) {
      state.statDetails = data;
    },
    UPDATE_BUSINESS_RULES(state, data) {
      state.businessRules = data;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },

  actions: {
    async searchChecks({ commit }, searchParams) {
      const params = searchParams.toApiParams();
      commit('UPDATE_PROGRESS', { searching: true });
      try {
        const response = await api.dataQuality.search(params);
        const data = DataQuality.fromList(response.data.results);
        commit('UPDATE_CHECKS', { data, page: params.page, totalResults: response.data.count });
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },
    async getStatistics({ commit }) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.dataQuality.getStatistics();
        const data = DataQualityStats.toArray(response.data);
        commit('UPDATE_DQ_STATS', data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async getBusinessRules({ commit }) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.dataQuality.getBusinessRules();
        const data = DataQualityDefinition.fromList(response.data);
        commit('UPDATE_BUSINESS_RULES', data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
  },
  getters: {
    statTotal: state => state.statDetails.reduce(DataQualityCount.add, new DataQualityCount()),
  },
};
