import { ACCESS_WORK_QUEUE, MANAGE_REVIEW_REQUIRED } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import ResearchOutputSearchAndList
  from '@/components/research-output/search/ResearchOutputSearchAndList.vue';
import ResearchOutputSearchFieldState from '@/models/research-output/research-output-search-fields-state';

function propsForWorkQueue(workQueueType) {
  // `route.props` is undefined - should be `route.params`
  return route => ({ ...route.props, workQueueType });
}

function propsForReviewRequired(reviewRequiredProps) {
  // `route.props` is undefined - should be `route.params`
  return route => ({ ...route.props, ...reviewRequiredProps });
}

const workQueueRoutes = {
  path: 'my-work',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'my-work',
      redirect: {
        name: 'research-outputs-updated',
      },
    },
    {
      path: 'research-outputs/new',
      name: 'research-outputs-new',
      props: propsForWorkQueue('NEW'),
      component: ResearchOutputSearchAndList,
      meta: {
        auth: [ACCESS_WORK_QUEUE],
        activeRoute: 'work-queue-research-output-new',
      },
    },
    {
      path: 'research-outputs/updated',
      name: 'research-outputs-updated',
      props: propsForWorkQueue('CHANGED'),
      component: ResearchOutputSearchAndList,
      meta: {
        auth: [ACCESS_WORK_QUEUE],
        activeRoute: 'work-queue-research-output-updated',
      },
    },
    {
      path: 'research-outputs/escalated-l1',
      name: 'research-outputs-escalated-l1',
      component: ResearchOutputSearchAndList,
      props: propsForWorkQueue('L1'),
      meta: {
        auth: [ACCESS_WORK_QUEUE],
        activeRoute: 'work-queue-research-output-escalated-l1',
      },
    },
    {
      path: 'research-outputs/escalated-l2',
      name: 'research-outputs-escalated-l2',
      component: ResearchOutputSearchAndList,
      props: propsForWorkQueue('L2'),
      meta: {
        auth: [ACCESS_WORK_QUEUE],
        activeRoute: 'work-queue-research-output-escalated-l2',
      },
    },
    {
      path: 'research-outputs/review-required',
      name: 'research-outputs-review-required',
      component: ResearchOutputSearchAndList,
      props: propsForReviewRequired(
        {
          researchOutputTypes: ['C1'],
          roStatus: 'clawback_invalid',
          eligibility: 'eligible',
          claimsHasMyForcs: true,
          fieldsToMarkReadonly: new ResearchOutputSearchFieldState({ status: true, category: true }),
        },
      ),
      meta: {
        auth: [MANAGE_REVIEW_REQUIRED],
        activeRoute: 'work-queue-research-output-review-required',
      },
    },
  ],
};

export default workQueueRoutes;
