<template>
  <form novalidate class="full-width" @submit.prevent="saveAttributes()">
    <div class="inputs r-p-side">
      <q-checkbox
        v-model="researchOutput.is_indigenous_research"
        v-validate="'no_op'"
        :disable="disable"
        name="peer-review"
      >
        Indigenous research
      </q-checkbox>
    </div>

    <div class="group">
      <template v-if="!disable">
        <q-btn
          type="button"
          label="Cancel"
          color="primary"
          class="border border-primary"
          flat
          :disable="disable"
          @click="reset"
        />
        <q-btn
          type="submit"
          label="Save"
          color="primary"
          class="border border-primary"
          flat
          :disable="disable"
        />
      </template>
    </div>
  </form>
</template>

<script>
import ResearchOutput from '@/models/research-output/research-output';

export default {
  name: 'ResearchOutputAttributeExtension',
  props: {
    researchOutput: {
      type: ResearchOutput,
      required: true
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async reset() {
      this.researchOutput.is_indigenous_research = this.originalValues.isIndigenousResearch;
    },
    saveAttributes() {
      this.$emit('save');
      this._saveOriginalValues();
    },
    _saveOriginalValues() {
      // Backup the original values for the "Cancel" button to reset
      this.originalValues = {
        isIndigenousResearch: this.researchOutput.is_indigenous_research,
      }
    }
  },
  created: function() {
    this._saveOriginalValues();
  },
}
</script>
<style scoped>
  .inputs {
    margin-bottom: 1em;
  }
</style>
