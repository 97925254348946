import moment from 'moment';

const HONORARY_WORK_FUNCTIONS = ['Honorary Academic', 'Honorary Academics'];

class ResearcherPosition {
  constructor(researcherPosition = {}) {
    this.id = researcherPosition.id;
    this.staff_id = researcherPosition.staff_id;
    this.one_id = researcherPosition.one_id;
    this.external_id = researcherPosition.external_id;
    this.department_id = researcherPosition.department && Number(researcherPosition.department);
    this.discipline_id = researcherPosition.discipline && Number(researcherPosition.discipline);
    this.faculty_id = researcherPosition.faculty && Number(researcherPosition.faculty);
    this.job_number = researcherPosition.job_number;
    this.occupancy = researcherPosition.occupancy;
    this.position_title = researcherPosition.position_title;
    this.position_type = researcherPosition.position_type;
    this.work_function = researcherPosition.work_function;
    this.start_date = researcherPosition.start_date && moment(researcherPosition.start_date);
    this.end_date = researcherPosition.end_date && moment(researcherPosition.end_date);
    this.fte_percent = researcherPosition.fte_percent && Number(researcherPosition.fte_percent);
    this.is_current = researcherPosition.is_current;
    this.is_primary = !!researcherPosition.is_primary;
  }

  static fromList(researcherPositions = []) {
    return researcherPositions
      .map(researcherPosition => new ResearcherPosition(researcherPosition));
  }

  isHonorary() {
    return HONORARY_WORK_FUNCTIONS.includes(this.work_function);
  }
}

export default ResearcherPosition;
