import _ from 'lodash';
import {
  canUserActOnAllForcs,
  canUserActOnAnyForcs,
} from '@/utils/forc-utils';
import PermissionsMixin from './PermissionsMixin';

const EscalationsMixin = {
  mixins: [PermissionsMixin],
  computed: {
    isEscalated() {
      return this.dispute.isEscalated();
    },
    isL0() {
      return !this.isEscalated;
    },
    isL1() {
      return this.dispute.isL1Dispute();
    },
    isL2() {
      return this.dispute.isL2Dispute();
    },
    claimsAddsToHundred() {
      return _.sumBy(this.claims, claim => claim.apportion) === 100;
    },
    isValidStatus() {
      return !this.hasActiveClaims || this.claimsAddsToHundred;
    },
    hasActiveClaims() {
      return !!this.claims.length;
    },
    claimForcs() {
      return this.claims.map(claim => claim.forc);
    },
    slotForcs() {
      // escalatation happens on claims
      // resolutions happens on slots
      return this.dispute.active_dispute_slots.map(slot => slot.forc);
    },
    canUserEscalateToL1() {
      return canUserActOnAnyForcs(
        this.claimForcs,
        this.userForcsForRoL1Escalations,
      );
    },
    canUserEscalateToL2() {
      let forcsToCompareWith = [];

      if (this.isL0) {
        forcsToCompareWith = this.claimForcs;
      }
      if (this.isL1) {
        forcsToCompareWith = this.slotForcs;
      }
      return canUserActOnAnyForcs(
        forcsToCompareWith,
        this.userForcsForRoL2Escalations,
      );
    },
    canEscalateToL1() {
      return this.isL0 && this.canUserEscalateToL1
        && !this.canUserEscalateAllToL1;
    },
    canEscalateToL2() {
      if (!this.canUserEscalateToL2) return false;
      return !this.isL2;
    },
    canUserEscalateAllToL1() {
      return canUserActOnAllForcs(
        this.claimForcs,
        this.userForcsForRoL1Escalations,
      );
    },
    canUserResolveAllL1() {
      return canUserActOnAllForcs(
        this.slotForcs,
        this.userForcsForRoL1Resolutions,
      );
    },
    canUserResolveL1() {
      return canUserActOnAnyForcs(
        this.slotForcs,
        this.userForcsForRoL1Resolutions,
      );
    },
    canResolveL1OrL2() {
      return this.canResolveL1 || this.canResolveL2;
    },
    canResolveL1() {
      return this.isL1 && this.canUserResolveL1;
    },
    canResolveAllL1() {
      return this.isL1 && this.canUserResolveAllL1;
    },
    canResolveL2() {
      return this.isL2 && this.canUserResolveL2;
    },
    resolutionConditionsMet() {
      if (this.isL0) return true;
      if (this.isL1) {
        if (this.canUserResolveAllL1) {
          return this.isValidStatus;
        }
        return this.canUserResolveL1;
      }

      return this.isL2 && this.isValidStatus;
    },
    escalationsConditionsMet() {
      if (this.isL2) return false;
      if (this.isL1) return true;

      if (this.isValidStatus) {
        return false;
      }
      if (!this.canUserEscalateAllToL1) {
        return true;
      }

      return this.canUserEscalateToL2 && !this.canUserResolveAllL1;
    },
    userCanEscalate() {
      return (this.canEscalateToL1 || this.canEscalateToL2)
        && !this.canResolveAllL1;
    },
  },
  methods: {
    canUserResolveL1For(forc) {
      return canUserActOnAnyForcs(
        [forc],
        this.userForcsForRoL1Resolutions,
      );
    },
  },
};

export default EscalationsMixin;
