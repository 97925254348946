<template>
  <div style="display: contents">
    <q-tabs
      v-model="selectedTab"
      class="pp-tabs shadow-1 z-fab"
      style="overflow: visible !important"
      color="primary"
      two-lines
      inverted
    >
      <q-tab
        v-for="tab in publishingProfiles"
        :key="tab.tabLabel"
        slot="title"
        :name="tab.key"
        :label="tab.tabLabel"
      />
    </q-tabs>
    <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
      <report-table
        slot="table"
        class="bg-white"
        :data="profileData"
        :columns="profileCols"
        row-key="outputType"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatFloat, formatPercent } from '@/utils/formatter';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptPublishingProfile',
  components: {
    ReportTable,
  },
  data() {
    return {
      selectedTab: 'journalArticles',
    };
  },
  computed: {
    ...mapState('reporting', ['publishingProfiles', 'publishingProfileData']),
    ...mapGetters('reporting', ['roByYear', 'roTotalCount']),
    profileData() {
      return this.publishingProfileData[this.selectedTab];
    },
    profileCols() {
      const columnLabel = this.publishingProfiles.find(p => p.key === this.selectedTab).columnName;
      return [
        { label: columnLabel, field: 'name', align: 'left' },
        ...[
          { label: 'Outputs (apportioned)', field: 'count', format: formatFloat(2) },
          { label: '% of Output', field: 'percent', format: formatPercent(0) },
          { label: 'Cumulative %', field: 'cumulative', format: formatPercent(0) },
        ],
      ];
    },
  },
};
</script>

<style lang="stylus" scoped>
.pp-tabs
  margin 0 15px 15px
</style>
