import _ from 'lodash';
import api from '@/api';
import { createForcPerformanceComparisons } from '@/models/analytics/forc-performance-comparison-factory';
import WhatIf from '@/models/what-if/what-if';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';
import ResearchOutputSearchParam from '@/models/what-if/what-if-research-output-search-param';

const defaultPagination = () => ({
  totalResults: 0,
  pageSize: 50,
  currentPage: 1,
});

export default {
  namespaced: true,
  state: {
    whatIf: new WhatIf(),
    researchOutputsForWhatIf: [],
    searchPagination: defaultPagination(),
    impactedStats: {
      totalResults: 0,
    },
    progress: {
      searching: false,
      saving: false,
      forcPerformanceComparision: false,
    },
    forcPerformanceComparisons: [],
  },
  mutations: {
    UPDATE_RESEARCH_OUTPUTS(state, { researchOutputs, currentPage, totalResults }) {
      state.researchOutputsForWhatIf = researchOutputs;
      state.searchPagination.currentPage = Number(currentPage) || 1;
      state.searchPagination.totalResults = totalResults || 0;
    },
    UPDATE_EXISTING_RESEARCH_OUTPUTS(state, researchOutputs) {
      researchOutputs.forEach((researchOutput) => {
        const index = _.findIndex(
          state.researchOutputsForWhatIf,
          existing => existing.id === researchOutput.id,
        );
        state.researchOutputsForWhatIf.splice(index, 1, researchOutput);
      });
    },
    UPDATE_WHAT_IF(state, whatIf) {
      state.whatIf = whatIf;
    },
    UPDATE_IMPACTED_TOTAL(state, total) {
      state.impactedStats.totalResults = total;
    },
    UPDATE_FORC_PERFORMANCE_COMPARISION(state, forcPerformanceComparisons) {
      state.forcPerformanceComparisons = forcPerformanceComparisons;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
    RESET(state) {
      state.whatIf = new WhatIf();
      state.researchOutputsForWhatIf = [];
      state.searchPagination = defaultPagination();
      state.impactedStats = {
        totalResults: 0,
      };
      _.keys(state.progress).forEach((key) => {
        state.progress[key] = false;
      });
      state.forcPerformanceComparisons = [];
    },
  },
  actions: {
    reset({ commit }) {
      return commit('RESET');
    },
    async searchResearchOutputs({ commit, rootGetters }, { id, searchParams }) {
      commit('UPDATE_PROGRESS', { searching: true });

      const searchParameters = ResearchOutputSearchParam.create(searchParams);
      searchParameters.excludeActive = rootGetters['eras/eras'].find(era => era.id === searchParams.eraId).isUpcoming();
      searchParameters.page = searchParameters.page || 1;

      try {
        const response = await api.whatIfs.getResearchOutputs(id, searchParameters.toApiParams());
        const researchOutputsForWhatifs = {
          researchOutputs: ResearchOutputForWhatIf.fromList(response.data.results),
          currentPage: searchParameters.page,
          totalResults: response.data.count,
        };
        commit('UPDATE_RESEARCH_OUTPUTS', researchOutputsForWhatifs);
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },

    async fetchWhatIf({ commit }, whatIfId) {
      commit('UPDATE_PROGRESS', { whatIfDetails: true });
      try {
        const response = await api.whatIfs.get(whatIfId);
        commit('UPDATE_WHAT_IF', new WhatIf(response.data));
      } finally {
        commit('UPDATE_PROGRESS', { whatIfDetails: false });
      }
    },
    async fetchImpactedStats({ commit }, whatIfId) {
      const response = await api.whatIfs.getImpactedStats(whatIfId);
      commit('UPDATE_IMPACTED_TOTAL', response.data.impacted_count);
    },
    async fetchForcPerformanceComparisons({ commit }, whatIfId) {
      await commit('UPDATE_PROGRESS', { forcPerformanceComparision: true });
      try {
        const response = await api.whatIfs.getForcCamparisons(whatIfId);
        commit('UPDATE_FORC_PERFORMANCE_COMPARISION', createForcPerformanceComparisons(response.data));
      } finally {
        commit('UPDATE_PROGRESS', { forcPerformanceComparision: false });
      }
    },
    async save({ commit }, { whatIfId, researchOutputsForWhatIf }) {
      commit('UPDATE_PROGRESS', { saving: true });
      const data = {
        what_if_claims: researchOutputsForWhatIf.map(ro => ro.claimDetails()),
      };

      try {
        const response = await api.whatIfs.saveClaims(whatIfId, data);
        const researchOutputsForWhatifs = ResearchOutputForWhatIf.fromList(response.data.results);
        commit('UPDATE_EXISTING_RESEARCH_OUTPUTS', researchOutputsForWhatifs);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
  },
  getters: {
    canLoggedInUserReviewCurrentWhatIf(state, getters, rootState, rootGetters) {
      const loggedInUser = rootGetters['auth/loggedInUser'];
      if (state.whatIf.canManageWhatIf(loggedInUser)) return true;

      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];
      return state.whatIf.impactsAnyOfTheFaculties(userFacultyIdsForManageWhatIfs);
    },
    canLoggedInUserEditCurrentWhatIf(state, getters, rootState, rootGetters) {
      const loggedInUser = rootGetters['auth/loggedInUser'];
      return state.whatIf.canEditWhatIf(loggedInUser);
    },
    canLoggedInUserProposeCurrentWhatIf(state, getters, rootState, rootGetters) {
      const canUserProposeWhatIf = rootGetters['auth/canUserProposeWhatIf'];
      if (canUserProposeWhatIf) return true;

      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];
      return !state.whatIf.impactsOtherFaculties(userFacultyIdsForManageWhatIfs);
    },
    canLoggedInUserAcceptCurrentWhatIf(state, getters, rootState, rootGetters) {
      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForProposeWhatIf'];
      return state.whatIf.impactsAnyOfTheFacultiesNotAccepted(userFacultyIdsForManageWhatIfs);
    },
    doesLoggedInUsersFacultyGetImpactedByWhatIf(state, getters, rootState, rootGetters) {
      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];
      return state.whatIf.impactsAnyOfTheFaculties(userFacultyIdsForManageWhatIfs);
    },
    hasLoggedInUsersFacultyAlreadyAcceptedCurrentWhatIf(state, getters, rootState, rootGetters) {
      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];
      return state.whatIf.hasBeenAcceptedBy(userFacultyIdsForManageWhatIfs);
    },
    canLoggedInUserApplyWhatIf(state, getters, rootState, rootGetters) {
      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];

      const isWhatIfEnabledForUserAndEra = rootGetters['auth/canUserProposeWhatIf'];
      return isWhatIfEnabledForUserAndEra
        && state.whatIf.canApplyWhatIf(userFacultyIdsForManageWhatIfs);
    },
    hasLoggedInUsersFacultySubmittedCurrentWhatIf(state, getters, rootState, rootGetters) {
      const userFacultyIdsForManageWhatIfs = rootGetters['auth/userFacultiesForManageWhatIf'];
      return state.whatIf.hasBeenSubmittedBy(userFacultyIdsForManageWhatIfs);
    },
    workingWhatIf(state) {
      return state.whatIf;
    },
  },
};
