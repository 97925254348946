<template>
  <form class="items-baseline fit" @submit.prevent="submit">
    <div
      v-if="!underEdit"
      class="row items-center bg-mq-sand-dark reverse full-width claim-cell-item"
    >
      <q-btn
        dense
        color="blue-grey-5"
        size="md"
        icon="add"
        @click="$emit('initiateEdit')"
      />
    </div>
    <form v-else class="row items-baseline" novalidate @submit.prevent="submit">
      <claim-editor
        ref="claimEditor"
        v-model="claim"
        class="col"
        :is-multi-disciplinary="!researchOutputForBulk.isForcRestrictedByArc()"
        :journal-forcs="researchOutputForBulk.getJournalForcs()"
        :forcs="forcs"
        :user-forcs="userForcs"
        :clawback-bypassed-forcs="clawbackBypassedForcs"
      />
      <div class="col-auto">
        <q-btn
          dense
          color="primary"
          size="md"
          type="submit"
          label="Ok"
        />
      </div>
    </form>
  </form>
</template>

<script>
import ResearchOutputForBulkCode from '@/models/bulk-code/research-output-for-bulk-code';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';
import ClaimEditor from './ClaimEditor.vue';

export default {
  name: 'BulkSingleClaimAdd',
  components: { ClaimEditor },
  props: {
    researchOutputForBulk: {
      type: [ResearchOutputForBulkCode, ResearchOutputForWhatIf],
      required: true,
    },
    underEdit: {
      type: Boolean,
      required: true,
    },
    forcs: {
      type: Array,
      required: true,
    },
    userForcs: {
      type: Array,
      required: true,
    },
    claim: {
      type: ResearchOutputClaim,
      required: true,
    },
    clawbackBypassedForcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
    };
  },
  methods: {
    async submit() {
      const successful = await this.$refs.claimEditor.accept();
      if (successful) this.$emit('add');
    },
  },
};
</script>
<style lang="stylus" scoped>
</style>
