import User from '@/models/user/user';

class ResearchIncomeFileUpoadHistory {
  constructor(riFileUploadHistory = {}) {
    this.id = riFileUploadHistory.id;
    this.created_by = riFileUploadHistory.created_by && new User(riFileUploadHistory.created_by);
    this.created_at = riFileUploadHistory.created_at;
    this.updated_at = riFileUploadHistory.updated_at;
    this.era_submission_id = riFileUploadHistory.era_submission_id;
    this.status = riFileUploadHistory.status;
    this.isCommercialIncome = riFileUploadHistory.is_commercial_income;
  }

  static fromList(riFileUploadHistory = []) {
    return riFileUploadHistory.map(riFileUpload => new ResearchIncomeFileUpoadHistory(riFileUpload));
  }
}

export default ResearchIncomeFileUpoadHistory;
