import api from '@/api';
import ResearchIncomeFileUpoadHistory from '@/models/research-income/research-income-upload-history';

export default {
  namespaced: true,
  state: {
    researchIncomeFileHistory: [],
    types: [
      'true: Commercialisation',
      'false: Research',
    ],
    totalsSummary: [],
    /* progress: {
      searching: false,
      processing: false
    }, */
  },
  mutations: {
    UPDATE_RESEARCH_INCOME_FILE_HISTORY(state, researchIncomeFileHistory) {
      state.researchIncomeFileHistory = researchIncomeFileHistory;
    },
    /* UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    }, */
    UPDATE_TOTALS_SUMMARY(state, data) {
      state.totalsSummary = data;
    },
  },
  actions: {
    async uploadResearchIncomeFile(context, { file, isCommercialIncome }) {
      await api.researchIncomes.fileUpload(file, isCommercialIncome);
    },
    async downloadResearchIncomeFile(context, { isCommercialIncome, year }) {
      const response = await api.researchIncomes.fileDownload(isCommercialIncome, year);
      return response.data;
    },
    async fetchResearchIncomeUploadHistory({ commit }) {
      const response = await api.researchIncomes.getFileUploadHistory();
      const researchIncomeUploadeHistory = ResearchIncomeFileUpoadHistory.fromList(response.data.results);
      commit('UPDATE_RESEARCH_INCOME_FILE_HISTORY', researchIncomeUploadeHistory);
    },
    async downloadResearchIncomeHistoryFile(context, { researchIncomeId }) {
      const response = await api.researchIncomes.downloadFileUploadHistory(researchIncomeId);
      return response.data;
    },
    async getSummary({ commit }) {
      // commit('UPDATE_PROGRESS', { processing: true });
      const finalSummary = [];

      try {
        let isCommercial = false;
        let type = 'Research';
        let output = {};

        // Get Income Totals for Research
        const response = await api.researchIncomes.getSummary(isCommercial);
        output = response.data;
        let single;

        const keys = Object.keys(output);
        const values = Object.values(output);
        for (let i = 0; i < keys.length; i += 1) {
          single = {
            type,
            year: keys[i],
            total: values[i],
          };
          finalSummary.push(single);
        }

        // Get Income Totals for Commercial
        isCommercial = true;
        type = 'Commercial';

        const response2 = await api.researchIncomes.getSummary(isCommercial);
        output = response2.data;

        const keys2 = Object.keys(output);
        const values2 = Object.values(output);
        for (let i = 0; i < keys2.length; i += 1) {
          single = {
            type,
            year: keys2[i],
            total: values2[i],
          };
          finalSummary.push(single);
        }
        commit('UPDATE_TOTALS_SUMMARY', finalSummary);
      } catch (e) {
        this.$notify.failure(e);
      }
    },

  },
};
