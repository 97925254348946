<template>
  <form
    class="row justify-between"
    @submit.prevent="userTriggeredSearch(searchParameters.impactedOnly)"
  >
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            color="mq-soft"
            label="Clear"
            flat
            class="r-m-right"
            @click="clearSearch()"
          />
          <q-btn type="submit" color="primary" label="Search" />
        </div>
      </div>
      <q-field label="Income Type">
        <q-select
          v-model="searchParameters.incomeType"
          class="col-12"
          :options="incomeTypeOptions"
          clearable
          autofocus-filter
        />
      </q-field>
      <q-field label="Chief Investigator Name/MQ Id">
        <q-input v-model.trim="searchParameters.chiefInvestigatorName" clearable />
      </q-field>
      <q-field label="Faculty">
        <q-input v-model.trim="searchParameters.faculty" clearable />
      </q-field>
      <q-field label="Department">
        <q-input v-model.trim="searchParameters.department" clearable />
      </q-field>
      <q-field v-if="!isCommercial" label="HERDC Category">
        <q-select
          v-model="searchParameters.herdcIncomeCategory"
          :options="herdcCategoryOptions"
          clearable
          multiple
          chips
        />
      </q-field>
      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" clearable />
      </q-field>
      <q-field label="Funding Scheme">
        <q-input v-model.trim="searchParameters.schemeBody" clearable />
      </q-field>
      <q-field label="Pure ID">
        <q-input v-model.trim="searchParameters.pureId" clearable />
      </q-field>
      <div class="row q-field">
        <div class="q-field-label q-field-margin col-xs-12 col-sm-5">Year</div>
        <div class="q-field-content col-xs-12 col-sm row justify-between">
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">Start</span>
            <q-select
              v-model="searchParameters.yearStart"
              class="col"
              align="center"
              :options="yearStartOptions"
              clearable
              autofocus-filter
            />
          </div>
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">End</span>
            <q-select
              v-model="searchParameters.yearEnd"
              class="col"
              align="center"
              :options="yearEndOptions"
              clearable
              autofocus-filter
            />
          </div>
        </div>
      </div>
      <q-field label="Claim FoRCs">
        <div class="row">
          <q-select
            v-model="searchParameters.claimForcIds"
            class="col-12"
            :options="claimForcOptions"
            :filter="labelStartWithOrSublabelContainsFilter"
            chips
            clearable
            multiple
            autofocus-filter
            chips-color="primary"
            chips-bg-color="mq-sand-dark"
          />
          <q-checkbox
            v-model="searchParameters.forcSubmittedPreviously"
            label="Submitted previously to ERA"
            class="col-xs-12 q-if-label-above floating-label-font q-mt-lg block"
          />
          <q-checkbox
            v-model="searchParameters.modifiedForcs"
            label="Modified FoRCs"
            class="col-sm-6 col-xs-12 q-if-label-above floating-label-font q-mt-lg block"
          />
          <q-checkbox
            v-model="searchParameters.isCoded"
            label="Coded FoRCs"
            class="col-sm-6 col-xs-12 q-if-label-above floating-label-font q-mt-lg block"
          />
        </div>
      </q-field>
      <div class="text-right r-p-large-vertical">
        <q-btn
          type="button"
          color="mq-soft"
          label="Clear"
          flat
          class="q-mr-lg"
          @click="clearSearch()"
        />
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </div>
  </form>
</template>

<script>

import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { valueAsArray } from '@/utils/collections-util';
import { labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import { getFieldsFromQueryParametersBasedOn } from '@/utils/url-util';
import Forc from '@/models/era/forc';
import FundingSearchParam from '@/models/research-income/funding-search-param';

export default {
  name: 'FundingSearch',
  data() {
    return {
      searchParameters: new FundingSearchParam(),
    };
  },
  computed: {
    ...mapState('fundings', ['fundings', 'incomeTypes', 'herdcIncomeTypes']),
    ...mapState('researchOutputs', ['types']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'fourDigitForcByReferenceMetaId']),
    ...mapGetters('eras', ['workingEra']),
    claimForcOptions() {
      const sortedForcs = _.sortBy(
        this.fourDigitForcByReferenceMetaId(this.workingEra.forc_reference_meta_id),
        forc => forc.text,
      );
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    herdcCategoryOptions() {
      return Array.from(this.herdcIncomeTypes, ([value, description]) => ({ value, label: `${value}: ${description}` }));
    },
    yearStartOptions() {
      return _.range(this.workingEra.research_income_start_year, this.workingEra.research_income_end_year + 1)
        .map(y => ({ label: `${y}`, value: `${y}` }));
    },
    yearEndOptions() {
      const startYear = this.searchParameters.yearStart || this.workingEra.research_income_start_year;
      return _.range(startYear, this.workingEra.research_income_end_year + 1)
        .map(y => ({ label: `${y}`, value: `${y}` }));
    },
    incomeTypeOptions() {
      return Object.entries(this.incomeTypes).map(([val, lbl]) => ({ label: lbl, value: val }));
    },
    isCommercial() {
      return this.searchParameters.incomeType === 'commercial';
    },
  },
  methods: {
    ...mapActions('fundings', ['searchFundings']),
    labelStartWithOrSublabelContainsFilter,
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    hasActiveFilters() {
      const defaultParameters = _.omit(new FundingSearchParam(), 'page');
      const currentParameters = _.omit(this.searchParameters, 'page');
      return !_.isEqual(defaultParameters, currentParameters);
    },
    clearSearch() {
      this.updateParameters({});
    },
    updateQuery(searchParams) {
      this.$router.push({ query: searchParams })
        .catch((e) => {
          if (e.name !== 'NavigationDuplicated') { throw e; }
        });
    },
    updateParameters(searchParams) {
      const params = {
        ...new FundingSearchParam(),
        ...getFieldsFromQueryParametersBasedOn(this.searchParameters, searchParams),
        claimForcIds: valueAsArray(searchParams.claimForcIds).map(Number),
        eraId: Number(this.$route.params.eraId),
      };

      this.searchParameters = FundingSearchParam.create(params);
    },
    hasSearchParametersChanged(previous, current) {
      return _.keys(new FundingSearchParam())
        .some(key => !_.isEqual(current[key], previous[key]));
    },
    userTriggeredSearch(impactedOnly) {
      this.search(1, impactedOnly);
      this.$emit('searched');
    },
    search(pageToSearch) {
      const page = pageToSearch || this.searchParameters.page;
      const searchParams = Object.fromEntries(Object.entries(this.searchParameters).filter(keyVal => !!keyVal[1]));
      const newSearchParameters = {
        ...searchParams,
        herdcIncomeCategory: this.isCommercial ? [] : this.searchParameters.herdcIncomeCategory,
        page,
      };

      this.updateQuery(newSearchParameters);
      this.$emit('searched');
    },
    searchOnUrlChange() {
      this.$notify.clear();
      const { query } = this.$route;
      this.updateParameters(query);
      this.searchFundings(this.searchParameters);
    },
    reSyncParametersFromQuery() {
      this.updateParameters(this.$route.query);
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
    // eslint-disable-next-line
    'searchParameters.yearStart': function (val) {
      if (this.searchParameters.yearEnd < val) {
        this.searchParameters.yearEnd = null;
      }
    },
  },
  created() {
    if (this.hasSearchParametersChanged(new FundingSearchParam(), this.$route.query)) {
      this.searchOnUrlChange();
    }
  },
};
</script>

<style lang="stylus" scoped>
  .field-groups
    & > .q-field
      margin-top 20px
</style>
