import _ from 'lodash';
import api from '@/api';
import Portfolio, { PORTFOLIO_STATUS } from '@/models/portfolio/portfolio';

export default {
  namespaced: true,
  state: {
    portfolio: new Portfolio(),
    portfolios: [],
    portfolioStatus: PORTFOLIO_STATUS,
    progress: {
      searching: false,
      processing: false,
      savingPortfolio: false,
      savingMembership: false,
      savingClaims: false,
    },
    types: [
      'Q1: Live Performance',
      'Q2: Original Creative Work',
      'Q3: Recorded or Rendered Work',
      'Q4: Curated Exhibition Or Event',
      'Q5: Report For External Body',
    ],
    portfolioStatuses: [
      'DRAFT: Draft',
      'FINALISED: Finalised',
    ],
    eligibility: [
      'eligible: Eligible',
      'ineligible: Ineligible',
    ],
  },
  mutations: {
    UPDATE_PORTFOLIO(state, value) {
      state.portfolio = value;
    },
    UPDATE_PORTFOLIOS(state, list) {
      state.portfolios = list;
      const portfolioSummary = _.find(state.portfolios, { id: state.portfolio.id });
      if (portfolioSummary) {
        state.portfolio.roCount = portfolioSummary.roCount;
      }
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async fetchAll() {
      const response = await api.portfolios.getAll();
      return _.sortBy(Portfolio.fromList(response.data.results), 'name');
    },
    async fetchPortfolios({ commit }) {
      const response = await api.portfolios.getSummary();
      const portfolios = _.sortBy(Portfolio.fromList(response.data.results), 'name');
      commit('UPDATE_PORTFOLIOS', portfolios);
    },
    async fetchPortfolio({ commit }, id) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.portfolios.get(id);
        const portfolio = new Portfolio(response.data);
        commit('UPDATE_PORTFOLIO', portfolio);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async createPortfolio({ commit, dispatch }, name) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.portfolios.create(name);
        await dispatch('fetchPortfolios');
        return response.data;
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async deletePortfolio({ commit, dispatch }, portfolioId) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        await api.portfolios.delete(portfolioId);
        await dispatch('fetchPortfolios');
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async updatePortfolio({ state, commit, dispatch }, portfolio) {
      commit('UPDATE_PROGRESS', { savingPortfolio: true });
      try {
        await api.portfolios.update(state.portfolio.id, portfolio);
        await dispatch('fetchPortfolios');
        commit('UPDATE_PORTFOLIO', Object.assign(state.portfolio, portfolio));
      } finally {
        commit('UPDATE_PROGRESS', { savingPortfolio: false });
      }
    },
    async updatePortfolioName({ dispatch }, name) {
      await dispatch('updatePortfolio', { name });
    },
    async updateResearchStatement({ dispatch }, statement) {
      await dispatch('updatePortfolio', { statement });
    },
    async finalisePortfolio({ state, dispatch }, finaliseStatus = true) {
      const status = finaliseStatus ? state.portfolioStatus.finalised : state.portfolioStatus.draft;
      await dispatch('updatePortfolio', { status });
    },
    async updatePortfolioMembership({ commit, dispatch }, membership) {
      commit('UPDATE_PROGRESS', { savingMembership: true });
      const payload = {
        bulk_research_outputs: membership.map(u => ({ portfolio_id: u.portfolioId, research_output_id: u.roId })),
      };
      try {
        await api.portfolios.updateMembership(payload);
        await dispatch('fetchPortfolios');
      } finally {
        commit('UPDATE_PROGRESS', { savingMembership: false });
      }
    },
    async saveClaims({ commit, dispatch }, portfolioClaims) {
      commit('UPDATE_PROGRESS', { savingClaims: true });
      const payload = {
        bulk_portfolio_claims: [{
          portfolio_id: portfolioClaims.portfolioId,
          claims: portfolioClaims.claims
            .filter(c => c.apportion)
            .map(c => ({ forc_id: c.forc.id, apportion: c.apportion, is_peer_reviewed: c.isPeerReviewed })),
          comment: portfolioClaims.comment,
        }],
      };
      try {
        await api.portfolios.saveClaims(payload);
        await dispatch('fetchPortfolio', portfolioClaims.portfolioId);
      } finally {
        commit('UPDATE_PROGRESS', { savingClaims: false });
      }
    },
  },
  getters: {
    portfolioOptions: state => state.portfolios.map(p => ({
      label: p.displayText(),
      value: p.id,
      rightColor: 'mq-blood-red',
      rightIcon: p.status === state.portfolioStatus.finalised ? 'lock' : null,
      className: p.status === state.portfolioStatus.finalised ? 'text-mq-blood-red' : null,
    })),
    allPortfolioOptions: (state, getters) => (disabled) => {
      const options = [
        { label: 'Navigate to ...', value: 0, className: 'text-grey' },
        { label: 'Portfolios List', value: 'home', className: 'text-bold' },
        { label: 'All Non-Traditional Research Outputs', value: 'assign', className: 'text-bold' },
        ...getters.portfolioOptions,
      ];
      if (options.length > 3) {
        options[3].separator = true;
      }
      const item = _.find(options, { value: disabled });
      if (item) {
        item.disable = true;
        item.className = 'text-grey cursor-not-allowed';
      }
      return options;
    },
  },
};
