<template>
  <q-page>
    <portal to="app-header">
      <div class="row page-title justify-between">
        <div>{{ title }}</div>
        <div class="flex items-center">
          <template v-if="searchQueries.length">
            <label class="body-2" for="search-query">Saved Searches:</label>
            <q-select
              id="search-query"
              v-model="selectedQuery"
              class="search-query q-ml-md"
              :options="queryOptions"
              clearable
              @input="retrieveSearchQuery()"
            />
          </template>
          <q-btn
            class="search-bar no-shadow q-ml-lg"
            icon="search"
            align="left"
            no-ripple
            no-caps
            @click.native="showSearch()"
          >
            Search {{ hasActiveFilters() ? ' - Filters present': '' }}
          </q-btn>
        </div>
      </div>
    </portal>
    <div class="row full-height">
      <div class="col q-mr-lg bg-white page-body-height">
        <div class="row q-mr-md">
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            @change="fetchPage"
          />
        </div>
        <q-card-separator />
        <research-output-list
          ref="researchOutputList"
          class="page-body-height-minus-navigation"
          @fetch-page="fetchPage"
        />
        <q-card-separator />
        <div class="row q-mr-md">
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            @change="fetchPage"
          />
        </div>
      </div>
      <div class="col-7 bg-mq-neutral highlighted-left-border">
        <div class="page-body-height scroll-y">
          <research-output-detailed v-if="isResearchOutputSelected" />
        </div>
      </div>
    </div>

    <radar-modal v-model="isSearchDisplayed">
      <div slot="modal-definition">Search</div>
      <research-outputs-search
        ref="roSearch"
        :work-queue-type="workQueueType"
        :research-output-types="researchOutputTypes"
        :ro-status="roStatus"
        :eligibility-status="eligibility"
        :claims-has-my-forcs="claimsHasMyForcs"
        :fields-to-mark-readonly="fieldsToMarkReadonly"
        @searched="scrollToTop"
      />
    </radar-modal>
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { scrollToElement } from '@/utils/scroll-util';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import RadarModal from '@/components/common/RadarModal.vue';
import ResearchOutputSearchFieldState from '@/models/research-output/research-output-search-fields-state';
import ResearchOutputDetailed from '../details/ResearchOutputDetailed.vue';
import ResearchOutputList from './ResearchOutputList.vue';
import ResearchOutputsSearch from './ResearchOutputsSearch.vue';

export default {
  name: 'ResearchOutputSearchAndList',
  components: {
    RadarModal,
    ResearchOutputsSearch,
    ResearchOutputList,
    ResearchOutputDetailed,
    PaginationTotalCount,
  },
  props: {
    workQueueType: {
      type: String,
      default: undefined,
    },
    researchOutputTypes: {
      type: Array,
      default: () => [],
    },
    roStatus: {
      type: String,
      default: '',
    },
    eligibility: {
      type: String,
      default: '',
    },
    claimsHasMyForcs: {
      type: Boolean,
      default: false,
    },
    fieldsToMarkReadonly: {
      type: Object,
      default: () => new ResearchOutputSearchFieldState(),
    },
  },
  data() {
    return {
      isSearchDisplayed: false,
      titleOptions: {
        L1: 'Work queue - Faculty Escalated',
        L2: 'Work queue - University Escalated',
        CHANGED: 'Work queue - Updated',
      },
      selectedQuery: null,
    };
  },
  computed: {
    ...mapState('researchOutputs', ['researchOutputs', 'searchQueries', 'pagination']),
    ...mapGetters('researchOutputs', ['researchOutput', 'orderedSearchQueries']),
    title() {
      return _.get(this.titleOptions, this.workQueueType, 'Research Outputs');
    },
    totalPages() {
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
    currentPage() {
      return this.pagination.currentPage;
    },
    isResearchOutputSelected() {
      return !_.isEmpty(this.researchOutputs) && !!this.researchOutput.id;
    },
    queryOptions() {
      return this.orderedSearchQueries.map(q => ({ label: q.name, value: q.id }));
    },
  },
  methods: {
    ...mapActions('researchOutputs', ['fetchSearchQueries']),
    fetchPage(page) {
      this.$refs.roSearch.search(page);
    },
    showSearch() {
      this.isSearchDisplayed = true;
    },
    hasActiveFilters() {
      if (!this.$refs.roSearch) return false;
      return this.$refs.roSearch.hasActiveFilters();
    },
    scrollToTop() {
      scrollToElement(this.$refs.researchOutputList, true);
      this.isSearchDisplayed = false;
    },
    retrieveSearchQuery() {
      const searchParam = _.find(this.searchQueries, { id: this.selectedQuery });
      if (searchParam) {
        const query = Object.fromEntries(Object.entries(JSON.parse(searchParam.data)).filter(v => !!v[1]));
        this.$router.push({ query })
          .catch((e) => {
            if (e.name !== 'NavigationDuplicated') { throw e; }
          });
      }
    },
  },
  async created() {
    await this.fetchSearchQueries();
  },
};
</script>
<style lang="stylus" scoped>
.body-2
  font-weight 500

.page-body-height
  height calc(100vh - 68px)

.page-body-height-minus-navigation
  height calc(100vh - 134px)

.search-bar
  max-width 220px

.search-query
  min-width 200px
</style>
