class ResearchOutputByYear {
  constructor(value, validYears = []) {
    this.roType = value.research_output_type;
    this.roTypeName = value.research_output_type_name;
    if (value.years) {
      this.years = Object.fromEntries(value.years.map(y => [y.year, y]));

      const { outputCount, peerReviewCount } = value.years.reduce((sum, val) => ({
        outputCount: sum.outputCount + val.count,
        peerReviewCount: sum.peerReviewCount + val.peer_reviewed_whole,
      }),
      { outputCount: 0, peerReviewCount: 0 });
      this.outputCount = outputCount;
      this.peerReviewCount = peerReviewCount;
    } else {
      this.years = Object.fromEntries(validYears.map(year => [year, { count: null, peer_reviewed_whole: null }]));
      this.outputCount = value.count;
      this.peerReviewCount = value.peer_reviewed_whole;
    }
  }

  static fromList(list = [], validYears) {
    return list
      .map(item => new ResearchOutputByYear(item, validYears));
  }
}

export default ResearchOutputByYear;
