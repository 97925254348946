import _ from 'lodash';
import { Notify } from 'quasar';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store';
import DefaultLayout from '@/layouts/Default.vue';
import Home from '@/components/Home.vue';
import eraRoutes from './era-router';
import userRoutes from './user-router';

Vue.use(VueRouter);

const Router = new VueRouter({
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
        },
        ...eraRoutes,
        userRoutes,
      ],
    },
    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
});

const navigateIfRoutePresent = (to, next) => {
  if (_.isEmpty(to.matched)) {
    return next('/');
  }
  return next();
};

Router.beforeEach(async (to, from, next) => {
  await store.dispatch('initialize', to.params.eraId);
  if (!to.meta || _.isEmpty(to.meta.auth)) {
    return navigateIfRoutePresent(to, next);
  }

  const hasPermission = store.getters['auth/hasPermission'](...to.meta.auth);

  if (!hasPermission) {
    Notify.create({
      message: 'Error!',
      detail: 'You don\'t have access to requested content',
      type: 'negative',
    });
    return next('/');
  }

  return next();
});

export default Router;
