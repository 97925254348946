<template>
  <q-table
    :data="allUserAccess"
    :columns="columns"
    :rows-per-page-options="[0]"
    :pagination.sync="pagination"
    table-class="user-access-table round-borders"
    class="no-shadow"
    row-key="id"
    hide-bottom
    no-data-label="No data found"
  >
    <template slot="body" slot-scope="props">
      <q-tr :props="props" class="vertical-top">
        <q-td
          key="role"
          :props="props"
          :style="props.colsMap.role.style"
          :class="props.colsMap.role.classes"
        >
          <span class="wrappable-table-column">
            {{ props.row.role.name }}
          </span>
        </q-td>
        <q-td
          key="faculty"
          :props="props"
          :style="props.colsMap.faculty.style"
          :class="props.colsMap.role.classes"
        >
          <div v-if="props.row.era_faculty " class="wrappable-table-column">
            {{ props.row.era_faculty.name }}
          </div>
        </q-td>
        <q-td
          key="forcs"
          :props="props"
          :style="props.colsMap.forcs.style"
          :class="props.colsMap.role.classes"
        >
          <div class="wrappable-table-column row">
            <div class="col">
              <template v-if="props.row.getAllForcs().length > 0">
                <div
                  v-for="(forcs, twoDigitCode) in getForcsGroupedByTwoDigitCode(props.row)"
                  :key="twoDigitCode"
                  class="q-pb-sm"
                >
                  <div class="text-weight-bolder q-pb-sm">
                    {{ twoDigitCode }}
                  </div>
                  <div class="q-pb-md">
                    <span v-for="forc in forcs" :key="forc.id" class="q-pr-lg">
                      {{ forc.text }}<q-tooltip>{{ forc.description }}</q-tooltip>
                    </span>
                  </div>
                </div>
              </template>
              <template v-else-if="props.row.era_faculty">
                Access to all {{ props.row.era_faculty.name }} ERA FoRCs
              </template>
            </div>
            <div v-if="!readonly" class="col-auto">
              <div class="row justify-end items-baseline">
                <div class="col-xs-12 col-lg-auto text-right on-right">
                  <q-icon
                    v-if="isUserAccessSelected(props.row)"
                    name="rotate_right"
                    class="animate-spin on-left"
                    size="1rem"
                  />
                  <q-btn
                    icon="edit"
                    color="primary"
                    label="Edit"
                    size="sm"
                    @click="onEdit(props.row)"
                  />
                </div>
                <div class="col-xs-12 col-lg-auto text-right ">
                  <q-btn
                    icon="delete"
                    color="negative"
                    flat
                    class="on-right"
                    @click="onDeleteChoose(props.row)"
                  />
                </div>
              </div>
            </div>
          </div>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import { booleanPromise } from '@/utils/promise-util';

export default {
  name: 'UserAccessList',
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 0, // 0 means all rows
      },
      columns: [
        {
          name: 'role',
          label: 'Role',
          align: 'left',
          field: 'role.name',
          style: 'width: 20%',
          sortable: true,
          classes: 'bg-white',
        }, {
          name: 'faculty',
          label: 'Faculty',
          align: 'left',
          style: 'width: 30%',
          classes: 'bg-mq-neutral',
        }, {
          name: 'forcs',
          label: 'FoRCs',
          align: 'left',
          style: 'width: 50%',
          classes: 'bg-mq-neutral',
        },
      ],
    };
  },
  computed: {
    ...mapState('userAccess', ['userAccess']),
    ...mapState('userAccess', ['allUserAccess']),
  },
  methods: {
    ...mapActions(
      'userAccess',
      ['getAllUserAccess', 'getUserAccess', 'deleteUserAccess', 'resetUserAccess'],
    ),
    isUserAccessSelected(userAccess) {
      return _.get(this.userAccess, 'id') === userAccess.id;
    },
    async onEdit(userAccess) {
      this.$notify.clear();
      await this.getUserAccess(userAccess);
    },
    async onDeleteOk(userAccess) {
      this.$notify.clear();
      await this.deleteUserAccess(userAccess);
      this.resetUserAccess();
    },
    async onDeleteChoose(userAccess) {
      const hasConfirmed = await booleanPromise(this.$q.dialog({
        title: 'Delete',
        message: 'Are you sure you want to delete this user access?',
        ok: 'Ok',
        cancel: 'Cancel',
      }));
      if (!hasConfirmed) return;

      try {
        await this.onDeleteOk(userAccess);
        this.$notify.success('Successfully Removed');
      } catch (error) {
        this.$notify.failure(error);
      }
    },
    getForcsGroupedByTwoDigitCode(userAccess) {
      return _.groupBy(userAccess.getAllForcs(), 'two_digit_code');
    },
  },
  mounted() {
    this.getAllUserAccess(this.userId);
  },
};
</script>
<style lang="stylus">
  .highlight-border
    border 1px solid red

  .user-access-table th
    @extend $table-header-text
</style>
