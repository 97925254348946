export const fundingFields = [
  {
    name: 'title',
    field: 'project_title',
  }, {
    name: 'pureId',
    field: 'pure_id',
  }, {
    name: 'leadCI',
    field: funding => funding.chief_investigator.displayName(),
  }, {
    name: 'fundingScheme',
    field: 'funding_scheme',
  }, {
    name: 'year',
    label: 'Year',
  }, {
    name: 'total',
    label: 'Total',
  }, {
    name: 'claim1',
    field: 'claims',
  }, {
    name: 'claim2',
    field: 'claims',
  }, {
    name: 'claim3',
    field: 'claims',
  }, {
    name: 'validations',
  },
];

export default { fundingFields };
