import { useHttp } from './http';

const http = useHttp('xml');

export default {
  search: params => http.get('xsd', { params }),
  getStatistics: () => http.get('xsd/overview_stats'),
  getSummary: () => http.get('summary'),
  getXmlSnippet: uuid => http.get(['xsd', uuid]),
  getJobStatus: () => http.get('run'),
  runGenerateJob: () => http.post('run', { action: 'generate' }),
};
