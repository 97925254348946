<template>
  <div class="scrollable-section">
    <q-table
      class="xml-table"
      table-class="sticky-header-table bg-white"
      :data="xmlSchemaValidations"
      :columns="columns"
      :rows-per-page-options="[0]"
      :pagination.sync="pagination"
      row-key="lineNumber"
      no-data-label="No data found"
    >
      <q-td slot="body-cell-uuid" slot-scope="props" :props="props">
        <q-btn
          flat
          no-ripple
          dense
          class="icon-link no-padding"
          title="Xml snippet"
          icon="text_snippet"
          @click.native="openXml(props.row)"
        />
      </q-td>
      <div slot="bottom" slot-scope="props" :props="props" class="row justify-between fit">
        <div class="col text-right">
          <pagination-total-count
            class="pagination-total border-top"
            :current-page="searchPagination.currentPage"
            :page-size="searchPagination.pageSize"
            :total-results="searchPagination.totalResults"
            @change="fetchPage"
          />
        </div>
      </div>
    </q-table>
    <xml-snippet-modal
      ref="xmlSnippet"
      :check-type="checkType"
      :line-number="lineNumber"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import { xmlFields } from './xml-fields';
import XmlSnippetModal from './XmlSnippetModal.vue';

export default {
  name: 'XmlList',
  components: {
    XmlSnippetModal,
    PaginationTotalCount,
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      columns: xmlFields,
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
      checkType: undefined,
      lineNumber: undefined,
    };
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    ...mapState('xml', ['xmlSchemaValidations', 'searchPagination', 'progress']),
    eraId() {
      return this.$route.params.eraId;
    },
  },
  methods: {
    ...mapActions('xml', ['getXmlSnippet']),
    async fetchPage(page) {
      this.$emit('fetchPage', page);
    },
    async openXml(xml) {
      try {
        await this.getXmlSnippet(xml.uuid);
        this.checkType = xml.checkType;
        this.lineNumber = xml.lineNumber;
        this.$refs.xmlSnippet.open();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
  watch: {
    eraId() {
      this.fetchPage(1);
    },
  },
};
</script>
<style lang="stylus" scoped>
</style>
