import axios, { useHttp } from './http';

const headers = { 'Content-Type': 'text/csv' };

const http = useHttp('eras');
const benchmarkAPI = useHttp('benchmarks');

export default {
  getAll: () => http.get(),
  get: id => http.get(id),
  create: era => http.post('', era),
  update: (id, era) => http.put(id, era),
  submitStage: (id, stage) => http.put([id, 'stage'], { submission_stage: stage }),
  getAllEraFaculties: () => axios.get('api/era_faculties/'),
  getEraFacultyForcs: (efId, metaId) => axios.get('api/forcs/', { params: { era_faculty_id: efId, meta_id: metaId } }),
  uploadBenchmark: file => benchmarkAPI.put('upload_download', file, { headers }),
  downloadBenchmark: () => benchmarkAPI.get('upload_download'),
};
