class DisciplinaryProfileCount {
  constructor(value) {
    this.forcId = value.id;
    this.fourDigitCode = value.four_digit_code;
    this.description = value.description;
    this.weightedCount = value.weighted_count;
  }

  static fromList(list = []) {
    return list.map(item => new DisciplinaryProfileCount(item));
  }
}

export default DisciplinaryProfileCount;
