<template>
  <div class="editor">
    <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
      <div class="editor__header">
        <q-btn
          :class="{ 'is-active': isActive.bold() }"
          icon="format_bold"
          title="Bold"
          flat
          @click="commands.bold"
        />

        <q-btn
          :class="{ 'is-active': isActive.italic() }"
          icon="format_italic"
          title="Italic"
          flat
          @click="commands.italic"
        />

        <q-btn
          :class="{ 'is-active': isActive.underline() }"
          icon="format_underline"
          title="Underline"
          flat
          @click="commands.underline"
        />

        <q-btn
          :class="{ 'is-active': isActive.bullet_list() }"
          icon="format_list_bulleted"
          title="Bullet list"
          flat
          @click="commands.bullet_list"
        />

        <q-btn
          :class="{ 'is-active': isActive.ordered_list() }"
          icon="format_list_numbered"
          title="Numbered list"
          flat
          @click="commands.ordered_list"
        />

        <q-btn
          icon="undo"
          title="Undo"
          flat
          @click="commands.undo"
        />

        <q-btn
          icon="redo"
          title="Redo"
          flat
          @click="commands.redo"
        />
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
    <div class="editor__footer">
      <q-btn
        color="primary"
        label="Save"
        @click="save()"
      />
      <q-btn
        color="tertiary"
        text-color="dark"
        label="Cancel"
        @click="cancel()"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Underline,
  History,
} from 'tiptap-extensions';

export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new BulletList(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
        ],
      }),
    };
  },
  methods: {
    focus() {
      this.editor.focus();
    },
    save() {
      this.$emit('input', this.editor.getHTML());
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.editor.setContent(val);
      },
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="stylus" scoped>
$border-color = rgba(black, 40%);

.editor
  border 1px solid $border-color
  border-radius 6px
  overflow hidden

  &__content
    padding 20px 10px
    background-color rgba(white, 30%)

    >>> .ProseMirror:focus
      outline none

  &__header
    padding 5px;
    border-bottom 1px solid $border-color

    > * + *
      margin-left 5px

  &__footer
    padding 10px;
    border-top 1px solid $border-color

    > * + *
      margin-left 10px

.is-active
  background-color rgba(0,0,0,.1)

</style>
