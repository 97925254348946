import _ from 'lodash';
import { getNonEmptyValues } from '@/utils/search';

class XmlSchemaSearchParam {
  constructor(eraId) {
    this.checkType = null;
    this.choiceType = null;
    this.businessRules = [];
    this.pureId = '';
    this.authorNameContains = '';
    this.facultyId = undefined;
    this.departmentId = undefined;
    this.eraCategory = [];
    this.title = '';
    this.year = '';
    this.issnOrIsbn = '';
    this.journalForcId = null;
    this.claimForcId = null;
    this.withNoClaimForcApportions = false;
    this.withMdJournalForc = false;
    this.withPeerReview = false;
    this.radarId = null;
    this.page = 1;
    this.eraId = eraId;
  }

  static create(params = {}) {
    const updateParams = {
      journalForcId: _.toInteger(params.journalForcId) || null,
      claimForcId: _.toInteger(params.claimForcId) || null,
      withNoClaimForcApportions: _.trim(params.withNoClaimForcApportions) === 'true',
      withMdJournalForc: _.trim(params.withMdJournalForc) === 'true',
      withPeerReview: _.trim(params.withPeerReview) === 'true',
    };

    return Object.assign(
      new XmlSchemaSearchParam(),
      params,
      updateParams,
    );
  }

  toApiParams() {
    return getNonEmptyValues({
      check_type: this.checkType,
      choice_type: this.choiceType,
      check_code: this.businessRules,
      author_name_contains: this.authorNameContains,
      faculty_id: this.facultyId,
      department_id: this.departmentId,
      pure_id: this.pureId,
      era_category: this.eraCategory,
      title: this.title,
      year: this.year,
      issn_or_isbn: this.issnOrIsbn,
      with_md_journal_forc: this.withMdJournalForc,
      journal_forc_id: this.withMdJournalForc
        ? null
        : this.journalForcId,
      with_no_claim_forc_apportions: this.withNoClaimForcApportions,
      claim_forc_id: this.withNoClaimForcApportions
        ? null
        : this.claimForcId,
      with_peer_review: this.withNoClaimForcApportions
        ? null
        : this.withPeerReview,
      page: this.page || 1,
      era_id: this.eraId,
    });
  }
}

export default XmlSchemaSearchParam;
