import api from '@/api';
import CitationBenchmarks from '@/models/analytics/citation-benchmarks';
import ForcCitationClassDistribution from '@/models/analytics/forc-citation-class-distribution';
import PeerReviewStatsForForc from '@/models/analytics/peer-review-stats-for-forc';

export default {
  namespaced: true,
  state: {
    citationBenchmarks: new CitationBenchmarks(),
    forcCitations: [],
    peerReviews: [],
    progress: {
      fetchingCitationBenchmarks: false,
      fetchingForcCitations: false,
      fetchingPeerReviews: false,
    },
  },
  mutations: {
    UPDATE_CITATION_BENCHMARKS(state, citationBenchmarks) {
      state.citationBenchmarks = citationBenchmarks;
    },
    UPDATE_FORC_CITATIONS(state, forcCitations) {
      state.forcCitations = forcCitations;
    },
    UPDATE_PEER_REVIEWS(state, peerReviews) {
      state.peerReviews = peerReviews;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async fetchCitationBenchmarks({ commit }) {
      try {
        commit('UPDATE_PROGRESS', { fetchingCitationBenchmarks: true });
        const response = await api.analytics.getCitationBenchmarks();
        commit('UPDATE_CITATION_BENCHMARKS', new CitationBenchmarks(response.data));
      } finally {
        commit('UPDATE_PROGRESS', { fetchingCitationBenchmarks: false });
      }
    },
    async fetchForcCitations({ commit, rootGetters }) {
      try {
        commit('UPDATE_PROGRESS', { fetchingForcCitations: true });
        const response = await api.analytics.getForcCitations();
        const forcs = rootGetters['referenceData/fourDigitForcsForWorkingEra'];
        const forcCitations = Object.entries(response.data);
        commit('UPDATE_FORC_CITATIONS', ForcCitationClassDistribution.fromList(forcCitations, forcs));
      } finally {
        commit('UPDATE_PROGRESS', { fetchingForcCitations: false });
      }
    },
    async fetchPeerReviews({ commit }) {
      try {
        commit('UPDATE_PROGRESS', { fetchingPeerReviews: true });
        const response = await api.analytics.getPeerReviews();
        const peerReviews = Object.entries(response.data);
        commit('UPDATE_PEER_REVIEWS', PeerReviewStatsForForc.fromList(peerReviews));
      } finally {
        commit('UPDATE_PROGRESS', { fetchingPeerReviews: false });
      }
    },
  },
  getters: {
    citationBenchmarkSource: state => state.citationBenchmarks.source,
  },
};
