<template>
  <div ref="tableWrapper" class="full-width relative-position">
    <q-table
      ref="tableWithData"
      :data="researchOutputsForWhatIf"
      table-class="sticky-header-table bg-white"
      class="what-if-scenario-table"
      :columns="columns"
      selection="multiple"
      :selected.sync="selected"
      row-key="id"
      :rows-per-page-options="[0]"
      binary-state-sort
      no-data-label="No data found"
      :pagination.sync="pagination"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <div slot="top" slot-scope="props" class="fit">
        <div class="row items-baseline ">
          <div class="col">
            <div class="page-title">
              <span class="text-secondary">{{ whatIf.title }}</span>
            </div>
          </div>
          <q-btn
            class="search-bar no-shadow on-left"
            icon="search"
            label="Search"
            align="left"
            no-ripple
            no-caps
            @click="initiateSearch"
          />
          <q-btn
            color="mq-radar-dark"
            label="View what-ifs list"
            class="on-left"
            wait-for-ripple
            @click="$router.push({name: 'what-ifs-draft'})"
          />
          <q-btn
            color="mq-soft-muted"
            label="Save"
            class="on-left"
            wait-for-ripple
            @click="explicitSave"
          />
          <q-btn
            color="secondary"
            label="Save & Review"
            class="on-left"
            wait-for-ripple
            @click="saveAndReview()"
          />
        </div>
        <div class="row justify-between items-baseline border-bottom border-secondary">
          <div class="col-auto">
            <q-tabs v-model="visibleList" inverted color="secondary" @select="selectTab">
              <q-tab slot="title" name="search-results">
                Search
              </q-tab>
              <q-tab slot="title" name="impacted">
                Edited - {{ impactedStats.totalResults }}
              </q-tab>
            </q-tabs>
          </div>
        </div>
        <div class="row justify-between">
          <div class="col row q-pt-md" />
          <div class="col-auto">
            <div class="bulk-claim-wrapper new-claim-area bg-mq-sand q-pt-sm q-pl-md">
              <bulk-claim
                :forcs="fourAndSixDigitForcsForWorkingEra"
                class="justify-center q-pt-sm"
                :disable="!selected.length"
                @addClaim="addClaimToSelected"
              />
            </div>
          </div>
        </div>
      </div>
      <q-tr slot="header" slot-scope="props">
        <q-th class="width-30 bg-white">
          <q-checkbox v-model="props.selected" indeterminate-value="some" />
        </q-th>
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
          :class="col.classes"
          :style="col.style"
        >
          <q-checkbox
            v-if="col.name==='title'"
            v-model="allExpandMode"
            color="primary"
            checked-icon="keyboard_arrow_down"
            unchecked-icon="chevron_right"
            keep-color
            @input="setAllExpandedSlots"
          />
          {{ col.label }}
        </q-th>
      </q-tr>
      <template slot="body" slot-scope="props">
        <q-tr :props="props" class="vertical-top">
          <q-td class="width-30 bg-white">
            <q-checkbox v-model="props.selected" />
          </q-td>
          <q-td
            key="title"
            :props="props"
            :style="props.colsMap.title.style"
            :class="props.colsMap.title.classes"
          >
            <div class="row items-start">
              <q-checkbox
                v-model="expansionSlots[props.row.id]"
                color="primary"
                checked-icon="keyboard_arrow_down"
                unchecked-icon="chevron_right"
                keep-color
              />
              <what-if-research-output-metadata-cell
                class="col"
                :expanded="isExpanded(props.row.id)"
                :research-output-for-what-if="props.row"
              />
            </div>
          </q-td>
          <q-td
            key="abstract"
            :props="props"
            :style="props.colsMap.abstract.style"
            :class="props.colsMap.abstract.classes"
          >
            <MQBylineLogo v-if="props.row.has_bylines" class="mq-byline" />
            <span>
              <a
                v-for="(pdfUrl,index) in props.row.electronic_locations"
                :key="index"
                :href="pdfUrl"
                target="_blank"
                class="icon-link items-baseline"
              >
                <q-icon color="mq-soft" name="description" />
              </a>
              <span v-if="!props.row.electronic_locations.length">No pdf</span>
            </span>
            <q-btn
              flat
              dense
              class="icon-link no-padding"
              icon="notes"
              @click="openAbstract(props.row)"
            />
          </q-td>
          <q-td
            key="arcForcs"
            :props="props"
            :style="props.colsMap.arcForcs.style"
            :class="props.colsMap.arcForcs.classes"
          >
            <div v-if="props.row.isMultiDisciplinary()">MD</div>
            <template v-else>
              <div v-for="(forc, index) in props.row.getJournalForcs()" :key="`forc-${forc.id}-${index}`">
                {{ forc.text }}
              </div>
            </template>
          </q-td>
          <q-td
            key="arcRcis"
            :props="props"
            :style="props.colsMap.arcRcis.style"
            :class="props.colsMap.arcRcis.classes"
          >
            <template v-if="!props.row.isMultiDisciplinary()">
              <div v-for="(forc, index) in props.row.getJournalForcs()" :key="`rci-${forc.id}-${index}`">
                {{ getRciRepresentationFor(props.row, forc) }}
              </div>
            </template>
            <template v-else>-</template>
          </q-td>
          <q-td
            key="claim1"
            :props="props"
            :style="props.colsMap.claim1.style"
            :class="props.colsMap.claim1.classes"
          >
            <what-if-editable-cell
              :claim="props.row.ro_claims[0]"
              :previous-claim="props.row.existing_claims[0]"
              :citation-benchmarks="citationBenchmarks"
              :allow-add="props.row.ro_claims.length===0"
              :research-output="props.row"
              :forcs="forcsForWorkingEra"
              :user-forcs="fourAndSixDigitForcsForWorkingEra"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :under-edit="isUnderEdit(props.row, 0)"
              :show-previous-claim="isExpanded(props.row.id)"
              @edit="initiateEditClaimFor(props.row, 0)"
              @add="addNewClaim"
              @removeEdit="exitEdit"
              @initiateEdit="initiateEditClaimFor(props.row, 0)"
              @delete="deleteClaim(props.row, 0)"
            />
          </q-td>
          <q-td
            key="claim2"
            :props="props"
            :style="props.colsMap.claim2.style"
            :class="props.colsMap.claim2.classes"
          >
            <what-if-editable-cell
              :claim="props.row.ro_claims[1]"
              :previous-claim="props.row.existing_claims[1]"
              :citation-benchmarks="citationBenchmarks"
              :allow-add="props.row.ro_claims.length===1"
              :research-output="props.row"
              :forcs="forcsForWorkingEra"
              :user-forcs="fourAndSixDigitForcsForWorkingEra"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :under-edit="isUnderEdit(props.row, 1)"
              :show-previous-claim="isExpanded(props.row.id)"
              @edit="initiateEditClaimFor(props.row, 1)"
              @add="addNewClaim"
              @removeEdit="exitEdit"
              @initiateEdit="initiateEditClaimFor(props.row, 1)"
              @delete="deleteClaim(props.row, 1)"
            />
          </q-td>
          <q-td
            key="claim3"
            :props="props"
            :style="props.colsMap.claim3.style"
            :class="props.colsMap.claim3.classes"
          >
            <what-if-editable-cell
              :claim="props.row.ro_claims[2]"
              :previous-claim="props.row.existing_claims[2]"
              :citation-benchmarks="citationBenchmarks"
              :allow-add="props.row.ro_claims.length===2"
              :research-output="props.row"
              :forcs="forcsForWorkingEra"
              :user-forcs="fourAndSixDigitForcsForWorkingEra"
              :clawback-bypassed-forcs="clawbackBypassedForcs"
              :under-edit="isUnderEdit(props.row, 2)"
              :show-previous-claim="isExpanded(props.row.id)"
              @edit="initiateEditClaimFor(props.row, 2)"
              @add="addNewClaim"
              @removeEdit="exitEdit"
              @initiateEdit="initiateEditClaimFor(props.row, 2)"
              @delete="deleteClaim(props.row, 2)"
            />
          </q-td>

          <q-td
            key="validations"
            :style="props.colsMap.validations.style"
            :class="props.colsMap.validations.classes"
          >
            <div class="validation-field">
              <q-btn
                v-if="props.row.isValid()"
                round
                dense
                flat
                size="16px"
                color="positive"
                icon="check_circle"
                class="no-pointer-events"
              />
              <q-btn
                v-if="props.row.isInvalid()"
                round
                dense
                flat
                size="16px"
                color="negative"
                icon="warning"
                @click="toggleExpanded(props.row.id)"
              />
            </div>
          </q-td>
        </q-tr>
        <q-tr
          v-if="isExpanded(props.row.id) && props.row.isInvalid()"
          :props="props"
          class="bg-white"
          style="margin-top: -1px"
        >
          <q-td colauto />
          <q-td colspan="100%" class="text-left extra-info-col" />
          <q-td class="bulk-error-wrapper bg-mq-sand">
            <div
              class="q-pa-md q-mx-sm border border-primary round-borders bg-white bulk-error-wrapper-offset"
            >
              <grid-validation-errors :validation="props.row.validation" />
            </div>
          </q-td>
        </q-tr>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <div slot="bottom" slot-scope="props" class="flex no-wrap justify-between fit items-center">
        <div>
          Selected: <span class="text-primary">{{ selected.length }} </span>
          <span v-if="progress.saving" class="on-right">Saving...</span>
        </div>
        <div class="flex text-right">
          <q-btn
            color="negative"
            label="Clear claims on selected"
            icon="mdi-trash-can-outline"
            outline
            class="on-left"
            size="sm"
            @click="clearAllClaimsOnSelected"
          />
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :current-page="searchPagination.currentPage"
            :page-size="searchPagination.pageSize"
            :total-results="searchPagination.totalResults"
            @change="paginateTo"
          />
        </div>
      </div>
    </q-table>
    <radar-loading :visible="searchingOrSaving" />
    <research-output-abstract-modal
      ref="abstract"
      :title="abstractTitle"
      :abstract="abstract"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { booleanPromise } from '@/utils/promise-util';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import ResearchOutputClaimEditorGrid from '@/mixins/ResearchOutputClaimEditorGrid';
import RadarLoading from '@/components/common/RadarLoading.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import BulkClaim from '@/components/bulk-code/BulkClaim.vue';
import WhatIfResearchOutputMetadataCell from '@/components/analytics/scenario/common/WhatIfResearchOutputMetadataCell.vue';
import WhatIfEditableCell from '@/components/analytics/scenario/editor/WhatIfEditableCell.vue';
import ResearchOutputAbstractModal from '@/components/research-output/ResearchOutputAbstractModal.vue';
import MQBylineLogo from '@/components/common/MQBylineLogo.vue';
import { whatIfFields } from './what-if-fields';
import ResearchOutputSearchParam from '@/models/what-if/what-if-research-output-search-param';

export default {
  name: 'WhatIfResearchOutputList',
  components: {
    RadarLoading,
    WhatIfResearchOutputMetadataCell,
    GridValidationErrors,
    WhatIfEditableCell,
    PaginationTotalCount,
    BulkClaim,
    ResearchOutputAbstractModal,
    MQBylineLogo,
  },
  mixins: [ResearchOutputClaimEditorGrid, AllFieldsExpandableTable],
  data() {
    return {
      selectedValue: undefined,
      rowUnderEdit: undefined,
      selected: [],
      columns: whatIfFields,
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      visibleList: 'search-results',
      testClaimAddToHundred: true,
      abstractTitle: undefined,
      abstract: undefined,
    };
  },
  computed: {
    ...mapState('whatIfScenario', {
      progress: 'progress',
      researchOutputsForWhatIf: 'researchOutputsForWhatIf',
      searchPagination: 'searchPagination',
      whatIf: 'whatIf',
      impactedStats: 'impactedStats',
    }),
    ...mapState('analytics', { citationBenchmarks: 'citationBenchmarks' }),
    ...mapGetters('analytics', ['citationBenchmarkSource']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'fourAndSixDigitForcsForWorkingEra']),
    ...mapGetters('auth', ['clawbackBypassedForcs']),
    impactedListVisible() {
      return this.visibleList === 'impacted';
    },
    searchingOrSaving() {
      return this.progress.searching || this.progress.saving;
    },
    whatIfId() {
      return this.$route.params.whatIfId;
    },
    expansionSlotDataItems() {
      return this.researchOutputsForWhatIf;
    },
  },
  methods: {
    ...mapActions('whatIfScenario', [
      'save', 'fetchWhatIf', 'fetchImpactedStats', 'fetchForcPerformanceComparisons',
    ]),
    selectTab() {
      this.paginateTo(1, true);
      this.selectedValue = 'select';
    },
    openAbstract(researchOutput) {
      this.abstractTitle = researchOutput.is_translated
        ? researchOutput.title_translated
        : researchOutput.title_nativescript;
      this.abstract = researchOutput.abstract;
      this.$refs.abstract.open();
    },
    updatedResearchOutputs() {
      return this.researchOutputsForWhatIf
        .filter(researchOutputForWhatIf => researchOutputForWhatIf.hasClaimChanges());
    },
    hasClaimChanges() {
      return this.updatedResearchOutputs().length > 0;
    },
    notifyNoChangedResearchOutputs(notificationEnabled) {
      if (notificationEnabled) {
        return booleanPromise(this.$q.dialog({
          title: 'What if scenario testing',
          message: 'No changes.',
          color: 'negative',
          ok: false,
          cancel: 'OK',
        }));
      }
      return Promise.resolve();
    },
    fetchPage(page, clearSearch = false) {
      this.updateParameters(this.$route.query,page);
    },
    updateParameters({ eligibility, ...searchParams },page) {
      const searchParameters = ResearchOutputSearchParam.create(searchParams);
      const impactedOnly = this.impactedListVisible
      const newSearchParameters = { ...searchParameters, impactedOnly, page };
      this.$emit('fetchPage',newSearchParameters);
    },
    async saveChanges(notifyOfSave = true) {
      if (!this.hasClaimChanges()) {
        await this.notifyNoChangedResearchOutputs(notifyOfSave);
        return false;
      }
      try {
        await this.save({
          whatIfId: this.whatIfId,
          researchOutputsForWhatIf: this.updatedResearchOutputs(),
        });
        if (notifyOfSave) {
          this.$notify.success('Saved successfully.');
        }
        return true;
      } catch (e) {
        this.$notify.failure(e);
        throw e;
      }
    },
    async saveChangesReloadImpactedStatsAndComparisons(notifyOfSave = true) {
      const hasBeenSaved = await this.saveChanges(notifyOfSave);
      if (hasBeenSaved) {
        await this.fetchImpactedStats(this.whatIfId);
        this.fetchForcPerformanceComparisons(this.whatIfId);
      }
      return hasBeenSaved;
    },
    async explicitSave() {
      await this.saveChangesReloadImpactedStatsAndComparisons(true);
      this.fetchPage(this.searchPagination.currentPage, false);
    },
    async paginateTo(page, clearSearch = false) {
      await this.saveChangesReloadImpactedStatsAndComparisons(false);
      this.fetchPage(page, clearSearch);
    },
    async saveAndReview() {
      await this.saveChanges(false);
      this.$router.push({ name: 'what-if-review' });
    },
    async initiateSearch() {
      this.saveChangesReloadImpactedStatsAndComparisons(false);
      this.$emit('initiateSearch');
    },
    getRciRepresentationFor(researchOutputForWhatIf, forc) {
      return researchOutputForWhatIf
        .getRciRepresentationByBenchmark(forc, this.citationBenchmarks, '-');
    },
    identifySelectedList() {
      this.visibleList = (this.$route.query.impactedOnly === 'true') ? 'impacted' : 'search-results';
    },
    clearAllClaimsOnSelected() {
      this.selected.forEach(ro => ro.clearClaims());
    },
  },
  watch: {
    whatIfId() {
      this.fetchPage(1);
      this.fetchImpactedStats(this.whatIfId);
    },
    researchOutputsForWhatIf() {
      this.selected = [];
      this.researchOutputsForWhatIf.forEach(ro => ro.validate(this.clawbackBypassedForcs, this.testClaimAddToHundred));
      this.setAllExpandedSlots(this.allExpandMode);
    },
    $route() {
      this.identifySelectedList();
    },
    'impactedStats.totalResults': function (totalResults) {
      if (totalResults) {
        this.visibleList = 'impacted';
      }
    },
  },
  created() {
    this.identifySelectedList();
  },
  mounted() {
    this.fetchImpactedStats(this.whatIfId);
  },
};

</script>
<style lang="stylus" scoped>
  .forc-selector-min
    width: 4rem

  .apportion-min
    width: 4rem

  .bulk-claim-wrapper,
  .bulk-error-wrapper
    width 607px

  .bulk-error-wrapper-offset
    margin-right 37px

  .authors-wrapper
    min-height 2rem
    max-width 1px
    height 100%
    overflow hidden
    word-wrap break-word
    border 1px solid yellow

  .validation-field
    margin-top 26px

  .width-30
     width 30px
</style>
