import _ from 'lodash';
import { getNonEmptyValues } from '@/utils/search';

class FundingSearchParam {
  constructor(eraId) {
    this.incomeType = null;
    this.pureId = '';
    this.chiefInvestigatorName = '';
    this.faculty = '';
    this.department = '';
    this.title = '';
    this.herdcIncomeCategory = [];
    this.schemeBody = '';
    this.yearStart = null;
    this.yearEnd = null;
    this.majorAccount = '';
    this.claimForcIds = [];
    this.forcSubmittedPreviously = null; // null, true, or false;
    this.modifiedForcs = null; // null, true, or false;
    this.isCoded = null; // null, true, or false;
    this.page = 1;
    this.eraId = eraId;
  }

  static create(params = {}) {
    const updateParams = {
      forcSubmittedPreviously: _.trim(params.forcSubmittedPreviously) === 'true',
      modifiedForcs: _.trim(params.modifiedForcs) === 'true',
      isCoded: _.trim(params.isCoded) === 'true',
    };

    return Object.assign(
      new FundingSearchParam(),
      params,
      updateParams,
    );
  }

  toApiParams() {
    return getNonEmptyValues({
      income_type: this.incomeType,
      pure_id: this.pureId,
      chief_investigator_searchname: this.chiefInvestigatorName,
      faculty_description_contains: this.faculty,
      department_description_contains: this.department,
      title: this.title,
      herdc_income_type: this.herdcIncomeCategory,
      scheme_body: this.schemeBody,
      year_min: this.yearStart,
      year_max: this.yearEnd,
      major_account_description: this.majorAccount,
      claim_forc_ids: this.claimForcIds,
      is_submitted_previously_to_era: this.forcSubmittedPreviously,
      is_modified_forcs: this.modifiedForcs,
      is_coded: this.isCoded,
      page: this.page || 1,
      era_submission_id: this.eraId,
    });
  }
}

export default FundingSearchParam;
