import _ from 'lodash';
import { DisputeSlot } from './dispute_slots';

export const DISPUTE_STATES = {
  L0: 'L0',
  L1: 'L1',
  L2: 'L2',
};

export const DISPUTE_STATE_NAMES = {
  L1: 'faculty',
  L2: 'university',
};

export class Dispute {
  constructor(data) {
    const dispute = data || {};
    this.id = dispute.id;
    this.state = dispute.state;
    this.active_dispute_slots = [];

    if (this.state === DISPUTE_STATES.L1) {
      this.active_dispute_slots = DisputeSlot
        .fromList(dispute.active_dispute_slots);
    }
  }

  disputeStateName() {
    return DISPUTE_STATE_NAMES[this.state];
  }

  isAwaitingResolutionFor(forc) {
    if (this.state === DISPUTE_STATES.L2) return true;
    return _.some(
      this.active_dispute_slots,
      slot => (slot.forc.id === forc.id && !slot.isResolved()),
    );
  }

  isEscalated() {
    return this.isL1Dispute() || this.isL2Dispute();
  }

  isL1Dispute() {
    return this.state === DISPUTE_STATES.L1;
  }

  isL2Dispute() {
    return this.state === DISPUTE_STATES.L2;
  }
}

export default Dispute;
