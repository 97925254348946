<template>
  <div>
    <div class="dq-stats" :class="[dqColor]">
      <div class="dq__icon" :title="title">
        <q-icon :name="stats.icon" :class="[stats.iconClass]" />
      </div>
      <div class="dq__title">{{ title }}</div>
      <button
        class="dq__item dq__total"
        :disabled="isZero(stats.total)"
        @click="onClick(null)"
      >
        {{ stats.total }}
      </button>
      <button
        class="dq__item dq__arc"
        :disabled="isZero(stats.arc)"
        @click="onClick('ARC')"
      >
        {{ stats.arc }}
      </button>
      <button
        class="dq__item dq__mq"
        :disabled="isZero(stats.mq)"
        @click="onClick('MQ')"
      >
        {{ stats.mq }}
      </button>
    </div>
  </div>
</template>

<script>
import { CHECK_TYPES, CHECK_TYPE_NAMES } from '@/models/data-quality/data-quality-types';
import DataQualityStats from '@/models/data-quality/data-quality-stats';

export default {
  name: 'DataQualityStats',
  props: {
    stats: {
      type: DataQualityStats,
      default: () => new DataQualityStats(),
    },
  },
  computed: {
    title() {
      return this.stats.checkType === CHECK_TYPES.Income
        ? CHECK_TYPE_NAMES[this.stats.checkType]
        : `${CHECK_TYPE_NAMES[this.stats.checkType]}s`;
    },
    dqColor() {
      return `dq--${this.stats.color}`;
    },
  },
  methods: {
    isZero(val) {
      return !val;
    },
    onClick(val) {
      this.$emit('click', { checkType: this.stats.checkType, choiceType: val });
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
