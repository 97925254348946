<template>
  <div class="researcher-card">
    <div class="researcher-card__details">
      <div class="researcher-card__ribbon ribbon ribbon-mq-bright-red-soft">
        <div class="triangle-bottom-right" />
        <div class="triangle-top-left" />
      </div>
      <div class="researcher-card__layout">
        <router-link
          class="researcher-card__name"
          :to="{ name: 'researcher-details', params: { researcherId: researcher.id }}"
        >
          {{ researcher.displayName() }}
        </router-link>
        <div
          v-if="!progress.loading"
          class="researcher-card__statistics"
        >
          <div class="researcher-card__ro-count">
            <div class="researcher-card__status-label" style="margin-bottom: -5px">Eligible Research Output</div>
            <researcher-publications
              :one-id="oneId"
              :stats="researcher.statistics"
            />
          </div>
          <div v-if="researcher.statistics.hasAppliedMeasureStatistics()" class="researcher-card__am-count">
            <div class="researcher-card__status-label" style="margin-bottom: -5px">Applied Measures</div>
            <researcher-applied-measure-count
              :one-id="oneId"
              :stats="researcher.statistics"
            />
          </div>
        </div>
        <dl class="researcher-card__affiliation">
          <template v-if="researcher.getCurrentPositions().length !== 0">
            <template v-for="position in researcher.getCurrentPositions()">
              <dt :key="`t-${position.id}`">{{ position.position_title }}</dt>
              <dd :key="`d-${position.id}`">{{ getPositionFacultyAndDepartmentLabel(position) }}</dd>
            </template>
          </template>
          <div v-else>
            <em>This researcher does not hold a current position.</em>
          </div>
        </dl>
      </div>
    </div>
    <div class="researcher-card__status">
      <div>
        <div class="researcher-card__status-label">Eligibility</div>
        <div class="text-mq-purple">
          {{ researcher.status.displayText() }}
        </div>
      </div>
      <div v-if="!researcher.status.isAutomatic() && researcher.status.comment">
        <div>Reason</div>
        <div>
          {{ researcher.status.comment }}
        </div>
      </div>
      <researcher-claim-version-read-only
        :researcher-claim-version="researcher.researcher_claim_version"
      >
        <div slot="title" class="researcher-card__status-label">Currently allocated FoRCs</div>
      </researcher-claim-version-read-only>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Researcher from '@/models/researcher/researcher';
import ResearcherClaimVersionReadOnly from '../common/ResearcherClaimVersionReadOnly.vue';
import ResearcherPublications from './ResearcherPublications.vue';
import ResearcherAppliedMeasureCount from './ResearcherAppliedMeasureCount.vue';

export default {
  name: 'ResearcherCard',
  components: {
    ResearcherClaimVersionReadOnly,
    ResearcherPublications,
    ResearcherAppliedMeasureCount,
  },
  props: {
    researcher: {
      type: Researcher,
      required: true,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    faculties: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('researchers', ['progress']),
    oneId() {
      return _.first(this.researcher.getAllOneIds());
    },
  },
  methods: {
    departmentNameById(id) {
      // eslint-disable-next-line eqeqeq
      const department = this.departments.find(d => d.id == id);
      return department && department.name;
    },
    facultyNameById(id) {
      // eslint-disable-next-line eqeqeq
      const faculty = this.faculties.find(f => f.id == id);
      return faculty && faculty.name;
    },

    getPositionFacultyAndDepartmentLabel(position) {
      const departmentName = this.departmentNameById(position.department_id);
      const facultyName = this.facultyNameById(position.faculty_id);

      return _.compact([departmentName, facultyName]).join(', ');
    },
  },
};
</script>

<style lang="stylus" scoped>
.researcher-card
  @extend $body-text
  display flex
  flex-wrap wrap
  background-color white
  border 1px solid $mq-sand-dark

  > div
    flex-basis 100%
    width 100%

  &__details
    display flex
    padding 15px 15px 15px 0

  &__ribbon
    padding-right 10px

  &__layout
    flex-grow 1
    display grid
    gap: 15px;
    grid-template-columns 1fr
    grid-template-areas "name" "statistics" "affiliations"

  &__name
    grid-area name
    @extend $h3
    display block
    text-transform capitalize
    text-decoration none

    &:hover
      text-decoration underline

    &:link,
    &:visited
      color $mq-purple

  &__statistics
    grid-area statistics
    display flex
    flex-wrap wrap
    margin -5px -10px

  &__ro-count
    flex-grow 1
    min-width 185px
    padding 10px

  &__am-count
    flex-basis 120px
    padding 10px

  &__affiliation
    grid-area affiliations
    margin-bottom 10px

    dt:not(:first-of-type)
      margin-top 15px

  &__status
    padding 15px
    background-color $mq-neutral

    > div
      margin-bottom 15px

      &:last-of-type
        flex: 1
        margin-bottom 0
        padding 0

  &__status-label
    @extend $label-text
    margin-bottom 5px
    color $mq-soft

  &__ro-count
    font-size $font-size-5

    a&
      text-decoration none
      &:hover
        text-decoration underline

  @media (min-width 768px)
    & &__details
      flex-basis 60%

    & &__status
      flex-basis 40%

  @media (min-width 1024px)
    & &__details
      flex-basis 70%

    & &__status
      flex-basis 30%

  @media (min-width 1400px)
    &__layout
      grid-template-columns 60% 40%
      grid-template-areas "name name" "affiliations statistics"

    &__statistics
      margin 0

    &__ro-count,
    &__am-count
      padding 0 15px 15px 0

</style>
