import api from '@/api';
import WhatIf from '@/models/what-if/what-if';

export default {
  namespaced: true,
  state: {
    whatIfs: [],
    whatIf: new WhatIf(),
    workflowStats: {
      draft_count: 0,
      pending_count: 0,
      approved_count: 0,
      applied_count: 0,
      rejected_count: 0,
    },
    progress: {
      searching: false,
    },
  },
  mutations: {
    UPDATE_WHAT_IFS(state, whatIfs) {
      state.whatIfs = whatIfs;
    },
    UPDATE_WHAT_IF(state, whatIf) {
      state.whatIf = whatIf;
    },
    UPDATE_WORKFLOW_STATS(state, workflowStats) {
      state.workflowStats = workflowStats;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async saveWhatIf({ commit }, whatIf) {
      const isNew = !whatIf.id;
      let response;
      if (isNew) {
        response = await api.whatIfs.create(whatIf);
      } else {
        response = await api.whatIfs.update(whatIf);
      }
      commit('UPDATE_WHAT_IF', new WhatIf(response.data));
    },
    async fetchWhatIfs({ commit }, status) {
      commit('UPDATE_PROGRESS', { searching: true });
      try {
        const response = await api.whatIfs.search(status);
        const whatIfs = WhatIf.fromList(response.data);
        commit('UPDATE_WHAT_IFS', whatIfs);
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },
    async fetchWorkflowStats({ commit }) {
      const response = await api.analytics.getWorkflowStats();
      commit('UPDATE_WORKFLOW_STATS', response.data);
    },
    async deleteWhatIf(context, whatIf) {
      await api.whatIfs.delete(whatIf);
    },
    async submitWhatIf({ dispatch }, whatIf) {
      await dispatch('actionWhatIf', { whatIf, eventType: 'submitted' });
    },
    async acceptWhatIf({ dispatch }, whatIf) {
      await dispatch('actionWhatIf', { whatIf, eventType: 'accepted' });
    },
    async rejectWhatIf({ dispatch }, whatIf) {
      await dispatch('actionWhatIf', { whatIf, eventType: 'rejected' });
    },
    async applyWhatIf({ dispatch }, whatIf) {
      await dispatch('actionWhatIf', { whatIf, eventType: 'applied' });
    },
    async reopenWhatIf({ dispatch }, whatIf) {
      await dispatch('actionWhatIf', { whatIf, eventType: 're-opened' });
    },
    async actionWhatIf(context, { whatIf, eventType }) {
      await api.whatIfs.createEvent(whatIf, eventType);
    },
  },
};
