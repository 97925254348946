<template>
  <form novalidate class="full-width ro-claims ro-claim-editor" @submit.prevent="saveClaim()">
    <div class="row items-baseline apportion-row justify-between r-p-side">
      <q-field
        class="col-9 forc-selector"
        error-label="Required"
        :disable="disable"
        :error="errors.has('forc-selector')"
      >
        <forc-selector
          v-model="researchOutputClaim.forc"
          v-validate.passive="'required'"
          :forcs="sortedUserApplicableForcs"
          :disable="disable"
          placeholder="Select a FoRC"
          name="forc-selector"
          :forc-description-length="38"
          @change="researchOutputClaim.is_peer_reviewed = false"
        />
      </q-field>

      <q-field
        class="col-auto on-right"
        :error-label="minApportion+'% - 100%'"
        :error="errors.has('forc-apportion')"
      >
        <q-input
          v-model.number="researchOutputClaim.apportion"
          v-validate.passive="{ required: true, min_value: minApportion, max_value: 100 }"
          :disable="disable"
          type="number"
          class="apportion"
          suffix="%"
          placeholder="e.g. 100"
          name="forc-apportion"
          :min="minApportion"
          :max="100"
          :step="1"
          :decimals="0"
        />
      </q-field>
    </div>

    <div class="row items-baseline peer-review-row justify-between r-p-side">
      <div class="col-auto">
        <q-checkbox
          v-model="researchOutputClaim.is_peer_reviewed"
          :disable="disable ||!isForcPeerReviewEligible"
        >
          Peer review
        </q-checkbox>
      </div>
    </div>

    <div class="full-width comment-row">
      <q-field>
        <q-input
          v-model.trim="researchOutputClaim.comment"
          v-validate="'no_op'"
          name="comment"
          type="textarea"
          hide-underline
          class="border border-black comfortable-textarea"
          :disable="disable"
          placeholder="Comment"
          :max-height="48"
          maxlength="1000"
        />
      </q-field>
    </div>
    <div class="group">
      <q-btn
        type="button"
        label="Cancel"
        color="primary"
        class="border border-primary"
        flat
        :disable="disable"
        @click="reset"
      />
      <q-btn
        type="submit"
        label="Add"
        color="primary"
        class="border border-primary"
        flat
        :disable="disable"
      />
    </div>
  </form>
</template>

<script>
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ForcSelector from '@/components/common/ForcSelector.vue';
import ResearchOutputClaimsMixin from '@/mixins/ResearchOutputClaimsMixin';

export default {
  name: 'NewResearchOutputClaim',
  components: { ForcSelector },
  mixins: [ResearchOutputClaimsMixin],
  props: {
    isMultiDisciplinary: {
      type: Boolean,
      required: true,
    },
    journalForcs: {
      type: Array,
      default: () => [],
    },
    forcs: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    userForcs: {
      type: Array,
      default: () => [],
    },
    clawbackBypassedForcs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
      researchOutputClaim: new ResearchOutputClaim(),
    };
  },
  methods: {
    async reset() {
      this.researchOutputClaim = new ResearchOutputClaim();
      this.$validator.reset();
    },
  },
};
</script>
<style lang="stylus" scoped>
  .apportion-row
    background-color $mq-sand
    padding-top 20.5px
    padding-bottom 1.5px

  .peer-review-row
    background-color $mq-sand
    padding-top 5.5px
    padding-bottom 20.5px
    min-width 83px
    font-size 16px
  .comment-row
    padding-top $radar-general-padding

  .forc-selector
    min-width 140px
    font-size 16px

  .apportion
    min-width 83px
    font-size 16px
</style>
