import _ from 'lodash';

class Role {
  static ERA_FACULTY_RESTRICTED = 'era_faculty_restricted';

  static ERA_FORC_RESTRICTED = 'era_forc_restricted';

  static UNRESTRICTED = 'unrestricted';

  constructor(role = {}) {
    this.id = role.id;
    this.name = role.name;
    this.code_name = role.code_name;
    this.permission_type = role.permission_type;
    this.permission_list = role.permission_list || [];
  }

  static fromList(roles = []) {
    return roles.map(role => new Role(role));
  }

  isFacultyBased() {
    return [Role.ERA_FACULTY_RESTRICTED, Role.ERA_FORC_RESTRICTED]
      .includes(this.permission_type);
  }

  isEraFacultyRestricted() {
    return Role.ERA_FACULTY_RESTRICTED === this.permission_type;
  }

  isEraForcRestricted() {
    return Role.ERA_FORC_RESTRICTED === this.permission_type;
  }

  isForcUnrestricted() {
    return Role.UNRESTRICTED === this.permission_type;
  }

  hasPermission(action) {
    return _.includes(this.permission_list, action);
  }

  hasPermissions(actions) {
    if (_.isArray(actions)) {
      return _.some(actions, action => this.hasPermission(action));
    }
    return this.hasPermission(actions);
  }
}

export default Role;
