import _ from 'lodash';
import { Notify } from 'quasar';

const alerts = new Map();

let index = 0;

// eslint-disable-next-line no-plusplus
const getNextId = () => ++index;

function extractError(error = '') {
  return _.get(error, 'response.data.error')
    // don't use the 'response.data' result if it contains the content of a webpage.
    || (error?.response?.data && !/<html>/.test(error.response.data) && _.get(error, 'response.data'))
    || _.get(error, 'message')
    || error;
}

const displayAlert = (options) => {
  const id = getNextId();
  const alert = Notify.create({
    position: 'top',
    closeBtn: '✕',
    onDismiss() {
      alerts.delete(id);
    },
    ...options,
  });
  alerts.set(id, alert);
};

export default {
  success(msg) {
    displayAlert({
      message: msg,
      timeout: 5000,
      type: 'positive',
    });
  },
  failure(msg) {
    displayAlert({
      message: extractError(msg),
      timeout: 0,
      type: 'negative',
    });
  },
  clear() {
    alerts.forEach(dismiss => dismiss());
  },
};
