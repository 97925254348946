import _ from 'lodash';
import api from '@/api';
import EraFaculty from '@/models/era/era-faculty';
import Forc from '@/models/era/forc';

export default {
  namespaced: true,
  state: {
    eraFaculties: [],
    eraFacultyForcs: [],
  },
  mutations: {
    UPDATE_ERA_FACULTIES(state, eraFaculties) {
      state.eraFaculties = eraFaculties;
    },
    UPDATE_ERA_FACULTY_FORCS(state, eraFacultyForcs) {
      state.eraFacultyForcs = eraFacultyForcs;
    },
  },
  actions: {
    async getAllEraFaculties({ commit }) {
      const response = await api.eras.getAllEraFaculties();
      const eraFaculties = EraFaculty.fromList(response.data.results);
      commit('UPDATE_ERA_FACULTIES', _.sortBy(eraFaculties, faculty => faculty.name));
    },
    async getEraFacultyForcs({ commit }, { eraFacultyId, metaId }) {
      const response = await api.eras.getEraFacultyForcs(eraFacultyId, metaId);
      const eraFacultyForcs = Forc.fromList(response.data);
      commit('UPDATE_ERA_FACULTY_FORCS', eraFacultyForcs);
    },
  },
};
