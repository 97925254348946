import Axios from 'axios';
import Qs from 'qs';
import _ from 'lodash';
import config from '@/config';

const axios = Axios.create({
  crossDomain: true,
  withCredentials: true,
  baseURL: config.apiEndpoints.base,
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

const includeEraId = (options) => {
  const { eraId } = window.app.$route?.params || {};
  const params = { era_submission_id: eraId, era_id: eraId };
  return _.merge({}, options, { params });
};

const apiUrl = baseUrl => (path) => {
  const pathArray = path || path === 0 ? [path] : [];
  return ['api', baseUrl, ...pathArray, ''].flatMap(x => x).join('/');
};

export const useHttp = (baseUrl) => {
  const url = apiUrl(baseUrl);
  const get = (path, options = {}) => axios.get(url(path), includeEraId(options));
  const put = (path, payload, options = {}) => axios.put(url(path), payload, includeEraId(options));
  const post = (path, payload, options = {}) => axios.post(url(path), payload, includeEraId(options));
  const deleteCmd = (path, options = {}) => axios.delete(url(path), includeEraId(options));

  return {
    get,
    put,
    post,
    delete: deleteCmd,
  };
};

export default axios;
