<template>
  <div class="column full-width full-height">
    <div class="col full-width">
      <q-list no-border dense separator class="q-pa-none">
        <q-item
          v-for="roClaim in claims"
          :key="roClaim.id"
          multiline
          class="no-padding p-mt-sm q-mb-sm"
        >
          <research-output-claim-component
            class="full-width"
            :awaiting-resolution="isAwaitingResolutionFor(roClaim)"
            :is-resolved="isResolved(roClaim)"
            :is-multi-disciplinary="isMultiDisciplinary"
            :research-output-claim="roClaim"
            :forcs="forcs"
            :disable="!canUserManageResearchOutputClaim || isEditBlockedByEscalation(roClaim)"
            :user-forcs="userForcsForRoClaim"
            :journal-forcs="journalForcs"
            :should-show-sensitivity-note="shouldShowSensitivityNote"
            @save="saveClaim(roClaim)"
            @delete="deleteClaim(roClaim)"
          />
        </q-item>
        <q-item v-if="!claims.length" class="row full-width justify-center q-pt-lg">
          No claims yet.
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import { Dispute } from '@/models/disputes/dispute';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import EscalationsMixin from '@/mixins/EscalationsMixin';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import ResearchOutputClaimComponent from './ResearchOutputClaim.vue';

export default {
  name: 'ResearchOutputClaims',
  components: { ResearchOutputClaimComponent },
  mixins: [EscalationsMixin, PermissionsMixin],
  props: {
    researchOutput: {
      type: ResearchOutput,
      required: true,
    },
    forcs: {
      type: Array,
      required: true,
    },
    researchOutputClaims: {
      type: Array,
      required: true,
    },
    dispute: {
      type: Dispute,
      required: true,
    },
  },
  data() {
    return { claims: [] };
  },
  computed: {
    isEscalated() {
      return this.dispute.isEscalated();
    },
    isMultiDisciplinary() {
      return !this.researchOutput.isForcRestrictedByArc();
    },
    journalForcs() {
      return this.researchOutput.getJournalForcs();
    },
  },
  methods: {
    isAwaitingResolutionFor(claim) {
      return this.dispute.isAwaitingResolutionFor(claim.forc);
    },
    isResolved(claim) {
      return this.isEscalated && !this.dispute.isAwaitingResolutionFor(claim.forc);
    },
    saveClaim(claim) {
      this.$emit('save', claim);
    },
    deleteClaim(claim) {
      this.$emit('delete', claim);
    },
    isEditBlockedByEscalation(claim) {
      if (!this.isEscalated) return false;

      if (this.isL1) {
        return !this.canUserResolveL1For(claim.forc);
      }

      return !this.canUserResolveL2;
    },
    shouldShowSensitivityNote(id) {
      return this.researchOutput.hasSensitiveContent && this.researchOutput.hasSinglePrClaim && this.researchOutput.isPeerReviewedClaim(id);
    },
  },
  watch: {
    researchOutputClaims() {
      this.claims = ResearchOutputClaim.fromList(this.researchOutputClaims);
    },
  },

};
</script>
<style lang="stylus" scoped>
  .apportion
    width: 6rem

  .unresolved
    border: 1px solid $warning

  .resolved
    border: 1px solid $positive
</style>
