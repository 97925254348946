<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="Research Outputs" :show-buttons="true">
      <report-table
        slot="table"
        :data="roByTypeData"
        :summary-row="roByTypeSummary"
        :columns="roByTypeCols"
        row-key="outputType"
      />
      <apexchart
        slot="graph"
        type="pie"
        height="400"
        :options="chartOptions"
        :series="chartSeries"
      />
    </report-container>

    <report-container title="FTE Profile by Academic Level" class="q-mt-xl">
      <report-table
        slot="table"
        :data="fteProfileTable"
        :summary-row="fteProfileSummary"
        :columns="fteProfileCols"
        row-key="herdc_level"
      />
    </report-container>

    <report-container title="Research Outputs by Year" class="q-mt-xl">
      <report-table
        slot="table"
        :data="researchOutputData"
        :summary-row="roByYearSummary"
        :columns="roByYearCols"
        row-key="outputType"
      />
    </report-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatFloat, formatPercent, div } from '@/utils/formatter';
import VueApexCharts from 'vue-apexcharts';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptVolumeAndActivity',
  components: {
    Apexchart: VueApexCharts,
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      roByTypeCols: [
        { label: 'Output Type', field: 'outputType', align: 'left' },
        { label: 'Outputs (apportioned)', field: 'outputCount', format: formatFloat(2) },
        { label: '% of Output', field: 'percent', format: formatPercent(0) },
      ],
      chartData: [],
      fteProfileCols: [
        { label: 'HESDC Level', field: 'herdc_level', align: 'left' },
        { label: 'FTE', field: 'fte', format: formatFloat(2) },
        { label: '% FTE', field: 'ftePercent', format: formatPercent(0) },
        { label: 'Headcount', field: 'headcount', format: formatFloat(2) },
        { label: '% Headcount', field: 'headcountPercent', format: formatPercent(0) },
      ],
    };
  },
  computed: {
    ...mapState('reporting', ['fteProfileData', 'researchOutputData']),
    ...mapGetters('eras', ['researchOutputYearRange']),
    ...mapGetters('reporting', [
      'roTotals',
      'fteTotalCount',
      'fteHeadcountTotal',
    ]),
    roByTypeData() {
      return this.researchOutputData.map(row => ({
        outputType: `${row.roType}: ${row.roTypeName}`,
        outputCount: row.outputCount,
        percent: div(row.outputCount, this.roTotals.outputCount),
      }));
    },
    roByTypeSummary() {
      return { outputType: 'TOTAL', outputCount: this.roTotals.outputCount, percent: this.roTotals.outputCount ? 1 : 0 };
    },
    roByYearCols() {
      return [
        { label: 'Output Type', field: 'roTypeName', align: 'left' },
        ...this.researchOutputYearRange.map(year => ({ label: year, field: row => row.years[year].count, format: formatFloat(2) })),
        { label: 'Total', field: 'outputCount', format: formatFloat(2, true) },
      ];
    },
    roByYearSummary() {
      return {
        roTypeName: 'TOTAL',
        years: this.roTotals,
        outputCount: this.roTotals.outputCount,
      };
    },
    fteProfileTable() {
      return this.fteProfileData.map(row => ({
        ...row,
        ftePercent: div(row.fte, this.fteTotalCount),
        headcountPercent: div(row.headcount, this.fteHeadcountTotal),
      }));
    },
    fteProfileSummary() {
      return {
        herdc_level: 'TOTAL',
        fte: this.fteTotalCount,
        ftePercent: div(this.fteTotalCount, this.fteTotalCount),
        headcount: this.fteHeadcountTotal,
        headcountPercent: div(this.fteHeadcountTotal, this.fteHeadcountTotal),
      };
    },
    chartSeries() {
      return this.chartData.map(row => row.outputCount);
    },
    chartOptions() {
      const percentage = val => formatPercent(2)(div(val, this.roTotals.outputCount));
      return {
        title: {
          text: 'Research Ouputs',
          align: 'center',
        },
        chart: {
          id: 'research-output',
        },
        labels: this.chartData.map(r => r.outputType),
        legend: {
          formatter(seriesName, opts) {
            return [seriesName, `  (${percentage(opts.w.globals.series[opts.seriesIndex])})`];
          },
        },
      };
    },
  },
  activated() {
    this.chartData = this.roByTypeData;
  },
};
</script>

<style lang="stylus" scoped>
</style>
