const claimCellStyle = 'width: 190px';

export const whatIfFields = [
  {
    name: 'title',
    label: 'Title',
    align: 'left',
    field: researchOutput => researchOutput.titleAndAuthors(),
    style: '',
    sortable: true,
    classes: 'bg-white',
  }, {
    name: 'abstract',
    label: '',
    align: 'left',
    field: 'abstract',
    style: 'width: 15%',
    classes: 'bg-white',
  }, {
    name: 'arcForcs',
    label: 'ARC',
    align: 'right',
    field: researchOutput => researchOutput.getJournalForcs(),
    style: 'width: 50px',
    classes: 'bg-white',
  }, {
    name: 'arcRcis',
    label: 'RCI',
    align: 'right',
    style: 'width: 50px',
    classes: 'bg-white',
  }, {
    name: 'claim1',
    label: 'Claim 1',
    field: 'ro_claims',
    align: 'center',
    style: `${claimCellStyle}; padding-left: 10px`,
    sortable: false,
    classes: 'bg-mq-sand',
  }, {
    name: 'claim2',
    label: 'Claim 2',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    sortable: false,
    classes: 'bg-mq-sand',
  }, {
    name: 'claim3',
    label: 'Claim 3',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    sortable: false,
    classes: 'bg-mq-sand',
  }, {
    name: 'validations',
    label: '',
    field: researchOutput => researchOutput.getValidationStatusText(),
    align: 'center',
    sortable: true,
    style: 'width: 37px;',
    classes: 'bg-mq-sand no-padding',
  },
];

export default { whatIfFields };
