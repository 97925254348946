class CitationBenchmarkForc {
  constructor(forcId, citationBenchmarkForc = {}) {
    this.forc_id = Number(forcId);
    /*
    {
    "2014": {
                "article_count": 33191,
                "cites_count": 340303,
                "citations_per_paper": 10.25
            },
    "2015": {
                "article_count": 33191,
                "cites_count": 340303,
                "citations_per_paper": 10.25
            },
      }
     */
    this.benchmark_by_year = citationBenchmarkForc;
  }

  static fromForcToYearlyCitationBenchmarkMapping(yearlyCitationBenchmarkMapping = {}) {
    return Object.entries(yearlyCitationBenchmarkMapping)
      .map(([forcId, benchmarkByYear]) => {
        const citationBenchmarkForc = new CitationBenchmarkForc(
          forcId,
          benchmarkByYear,
        );
        return citationBenchmarkForc;
      });
  }

  getRciFor(year) {
    const benchmark = this.benchmark_by_year[year];
    return benchmark.citations_per_paper;
  }

  getYearsSorted() {
    return Object.keys(this.benchmark_by_year).sort();
  }
}

export default CitationBenchmarkForc;
