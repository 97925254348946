<template>
  <q-page>
    <portal to="app-header">
      <div class="page-title row items-center">
        ERA <era-status :era="era" class="q-ml-md" />
      </div>
    </portal>
    <div class="bg-white row q-pt-lg q-pb-lg">
      <basic-era-info class="col" />
      <era-stage-stepper class="col-auto" />
    </div>
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import BasicEraInfo from './BasicEraInfo.vue';
import EraStageStepper from './EraStageStepper.vue';
import EraStatus from './EraStatus.vue';

export default {
  name: 'EraDetail',
  components: {
    EraStatus,
    EraStageStepper,
    BasicEraInfo,
  },
  props: {
    eraId: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    ...mapState('eras', ['era']),
    isNew() {
      return !_.toInteger(this.eraId);
    },
  },
  methods: {
    ...mapActions('eras', ['getEra', 'resetEra']),
  },
  async created() {
    this.resetEra();
    if (!this.isNew) await this.getEra(this.eraId);
  },
};
</script>
