import { MANAGE_USER } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import UserProfile from '@/components/user/UserProfile.vue';
import UsersSearchAndList from '@/components/user/UsersSearchAndList.vue';

const userRoutes = {
  path: 'users',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'users',
      component: UsersSearchAndList,
      meta: {
        auth: [MANAGE_USER],
        activeRoute: 'users',
      },
    }, {
      path: 'new',
      name: 'new-user',
      component: UserProfile,
      meta: {
        activeRoute: 'users',
        auth: [MANAGE_USER],
      },
    }, {
      name: 'user-details',
      path: ':userId',
      component: UserProfile,
      props: true,
      meta: {
        auth: [MANAGE_USER],
        activeRoute: 'users',
      },
    },
  ],
};

export default userRoutes;
