<template>
  <div class="full-width relative-position flex column no-wrap scroll">
    <radar-modal v-model="isSearchDisplayed">
      <div slot="modal-definition">Search</div>
      <portfolio-search ref="portfolioSearch" @searched="isSearchDisplayed = false" />
    </radar-modal>
    <portal to="app-header">
      <div class="page-title bg-mq-sand">
        <div class="lhs">Non-Traditional Research Outputs</div>
        <div class="rhs">
          <q-btn
            class="p-search-bar search-bar no-shadow"
            icon="search"
            align="left"
            no-ripple
            no-caps
            @click="isSearchDisplayed=true"
          >
            Search
            {{ hasActiveFilters()? ' - Filters present': '' }}
          </q-btn>
          <q-btn
            v-if="canUserManagePortfolio"
            icon="add"
            color="primary"
            label="New Portfolio"
            @click="addPortfolio()"
          />
          <q-select
            v-model="selectedPortfolio"
            class="portfolio-nav no-shadow"
            color="white"
            inverted-light
            :options="allPortfolioOptions('assign')"
            hide-underline
            @input="openPortfolio()"
          />
        </div>
      </div>
    </portal>

    <portfolio-list :portfolio-options="portfolioOptions" @change="refreshResearchOutputList()">
      <div slot="bottom" class="row justify-between fit">
        <div class="col text-right">
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            @change="fetchPage"
          />
        </div>
      </div>
    </portfolio-list>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import RadarModal from '@/components/common/RadarModal.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import PortfolioList from './PortfolioList.vue';
import PortfolioSearch from './PortfolioSearch.vue';

export default {
  name: 'PortfolioSearchAndList',
  components: {
    RadarModal,
    PortfolioList,
    PortfolioSearch,
    PaginationTotalCount,
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      isSearchDisplayed: false,
      selectedPortfolio: 0,
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolios', 'progress']),
    ...mapState('researchOutputs', ['pagination']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('portfolios', ['portfolioOptions', 'allPortfolioOptions']),
    currentPage() {
      return this.pagination.currentPage;
    },
    totalPages() {
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
  },
  methods: {
    ...mapActions('portfolios', ['createPortfolio', 'fetchPortfolios']),
    hasActiveFilters() {
      if (!this.$refs.portfolioSearch) return false;
      return this.$refs.portfolioSearch.hasActiveFilters();
    },
    fetchPage(page) {
      this.$refs.portfolioSearch.search(page);
    },
    refreshResearchOutputList() {
      this.$refs.portfolioSearch.searchOnUrlChange();
    },
    addPortfolio() {
      const vm = this;
      this.$q.dialog({
        title: 'New portfolio title',
        prompt: {
          model: '',
          type: 'text',
        },
        cancel: true,
        ok: 'Create',
      }).then((portfolioName) => {
        this.createPortfolio(portfolioName)
          .then(
            () => { vm.$notify.success('Portfolio created'); },
            (e) => { vm.$notify.failure(e); },
          );
      });
    },
    openPortfolio() {
      if (this.selectedPortfolio) {
        let nextPage;
        switch (this.selectedPortfolio) {
          case 'home':
            nextPage = { name: 'portfolio-home', params: { eraId: this.workingEra.id } };
            break;
          case 'assign':
            nextPage = { name: 'portfolios', params: { eraId: this.workingEra.id } };
            break;
          default:
            nextPage = { name: 'portfolio-details', params: { portfolioId: this.selectedPortfolio, eraId: this.workingEra.id } };
        }
        this.$router.push(nextPage);
      }
    },
  },
  watch: {
    eraId() {
      this.fetchPage(1);
    },
  },
  async created() {
    await this.fetchPortfolios();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmPageExit);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.confirmPageExit);
    });
  },
};
</script>
<style lang="stylus">
.lhs
  float left
  margin-bottom .5rem

.rhs
  display flex
  float right
  margin -.25rem 0 .5rem

  > * + *
    margin-left 10px
</style>

<style lang="stylus" scoped>
.p-search-bar
  height 34px
  max-width 220px

.portfolio-nav
  height 34px
</style>
