<template>
  <div>
    <pagination-total-count
      :page-size="pagination.pageSize"
      class="text-primary text-right full-width"
      :current-page="pagination.currentPage"
      :total-results="pagination.totalResults"
      @change="paginate"
    />
    <div
      v-for="claimEvent in researchOutputClaimEvents"
      :key="claimEvent.id"
      class="p-mt-sm q-mb-sm"
    >
      <dispute-event v-if="claimEvent.isOfDisputeType()" :event="claimEvent" />
      <eligibility-event v-else-if="claimEvent.isOfEligibilityType()" :event="claimEvent" />
      <portfolio-event v-else-if="claimEvent.isOfPortfolioType()" :event="claimEvent" />
      <ro-attribute-extension-update-event v-if="claimEvent.isOfRoAttributeExtensionType()" :event="claimEvent" />
      <claim-update-event v-else :event="claimEvent" />
    </div>
    <pagination-total-count
      :page-size="pagination.pageSize"
      class="text-primary text-right full-width"
      :current-page="pagination.currentPage"
      :total-results="pagination.totalResults"
      @change="paginate"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDateTime } from '@/utils/date';
import ResearchOutput from '@/models/research-output/research-output';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import ClaimUpdateEvent from './claim-events/ClaimUpdateEvent.vue';
import DisputeEvent from './claim-events/DisputeEvent.vue';
import EligibilityEvent from './claim-events/EligibilityEvent.vue';
import PortfolioEvent from './claim-events/PortfolioEvent.vue';
import RoAttributeExtensionUpdateEvent from './claim-events/RoAttributeExtensionUpdateEvent.vue';

export default {
  name: 'ResearchOutputClaimEvents',
  components: {
    PaginationTotalCount, ClaimUpdateEvent, DisputeEvent, EligibilityEvent, PortfolioEvent, RoAttributeExtensionUpdateEvent,
  },
  props: {
    researchOutput: {
      type: ResearchOutput,
      required: true,
    },
    researchOutputClaimEvents: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: true,
    },
    eraId: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    ...mapActions('researchOutputClaimEvents', ['fetchClaimEvents']),
    formatDateTime,
    paginate(page) {
      this.$emit('paginate', page);
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
