import _ from 'lodash';
import { replaceAllPureWsUrlWithAdminUrl } from '@/utils/url-util';
import { Dispute } from '@/models/disputes/dispute';
import Forc from '@/models/era/forc';
import Portfolio from '@/models/portfolio/portfolio';
import Citation from '@/models/what-if/citation';
import Author from './author';
import ResearchOutputClaim from './research-output-claim';
import ResearchOutputStatus from './research-output-status';
import ResearchOutputInterest from './research-output-interest';
import ResearchOutputAbdc from './research-output-abdc';
import ResearchStatement from './research-statement';

export const RESEARCH_STATEMENT_STATUS_COLOR_INDICATORS = {
  contains: 'positive',
  missing: 'negative',
  partial: 'info',
};

class ResearchOutput {
  static TYPE = {
    TRADITIONAL: 'traditional',
    NTRO: 'ntro',
  };

  static TYPES = {
    BOOK: { value: 'A1', type: ResearchOutput.TYPE.TRADITIONAL },
    BOOK_CHAPTER: { value: 'B1', type: ResearchOutput.TYPE.TRADITIONAL },
    JOURNAL_ARTICLE: { value: 'C1', type: ResearchOutput.TYPE.TRADITIONAL },
    CONFERENCE_PUBLICATION: { value: 'E1', type: ResearchOutput.TYPE.TRADITIONAL },
    LIVE_PERFORMANCE: { value: 'Q1', type: ResearchOutput.TYPE.NTRO },
    ORIGINAL_CREATIVE_WORK: { value: 'Q2', type: ResearchOutput.TYPE.NTRO },
    RECORDED_OR_RENDERED_WORK: { value: 'Q3', type: ResearchOutput.TYPE.NTRO },
    CURATED_EXHIBITION_OR_EVENT: { value: 'Q4', type: ResearchOutput.TYPE.NTRO },
    REPORT_FOR_EXTERNAL_BODY: { value: 'Q5', type: ResearchOutput.TYPE.NTRO },
  };

  static typesMapping() {
    return Object.entries(ResearchOutput.TYPES)
      .map(([key, type]) => ({ key, type: type.type, value: type.value }));
  }

  constructor(researchOutput = {}) {
    this.id = researchOutput.id;
    this.pure_id = researchOutput.pure_id;
    this.pure_link = researchOutput.pure_link;
    this.is_translated = researchOutput.is_translated;
    this.research_output_type = researchOutput.research_output_type;
    this.research_output_type_name = researchOutput.research_output_type_name;
    this.managing_organisation_name = researchOutput.managing_organisation_name;
    this.title_nativescript = researchOutput.title_nativescript;
    this.title_language = researchOutput.title_language;
    this.title_translated = researchOutput.title_translated;
    this.pub_year = researchOutput.pub_year;
    this.publisher_name = researchOutput.publisher_name;
    this.journal_name = researchOutput.journal_name;
    this.parent_doc = researchOutput.parent_doc;
    this.creators = Author.fromList(researchOutput.creators);
    this.abstract = researchOutput.abstract;

    this.electronic_locations = replaceAllPureWsUrlWithAdminUrl(researchOutput.electronic_locations);

    this.is_peer_reviewed = researchOutput.is_peer_reviewed;
    this.citation_count = researchOutput.citation_count;
    this.citations_data_for_all_sources = new Citation(researchOutput.citations_data_for_all_sources);

    this.journal_issn = researchOutput.journal_issn;
    this.journal_forcs = researchOutput.journal_forcs
      ? Forc.fromList(researchOutput.journal_forcs) : [];
    this.previous_era_journal_forcs = researchOutput.previous_era_journal_forcs
      ? Forc.fromList(researchOutput.previous_era_journal_forcs) : [];
    this.journal_is_multi_disciplinary = !!researchOutput.journal_is_multi_disciplinary;
    this.dispute_data = new Dispute(researchOutput.dispute_data);
    this.ro_claims = ResearchOutputClaim.fromList(researchOutput.ro_claims);
    this.ro_status = researchOutput.ro_status
      ? new ResearchOutputStatus(researchOutput.ro_status)
      : ResearchOutputStatus.noClaimsStatus();
    this.previous_era_claims = ResearchOutputClaim.fromList(researchOutput.previous_era_claims);
    this.interests = ResearchOutputInterest.fromList(researchOutput.interests);
    this.previous_era_claims = researchOutput.previous_era_claims
      ? ResearchOutputClaim.fromList(researchOutput.previous_era_claims)
      : [];
    this.abdc_rankings = researchOutput.abdc_rankings
      ? ResearchOutputAbdc.fromList(researchOutput.abdc_rankings)
      : [];
    // This is to be used when latest ARC Journal list is not available during current ERA
    // When disabled, we do not do the check for: is Forc Restricted By Arc Journal
    this.disable_clawback_logic = researchOutput.disable_clawback_logic;
    this.portfolio = new Portfolio(researchOutput.portfolio);
    this.isMemberOfPortfolio = !!researchOutput.portfolio;
    this.has_po_pr_claim = researchOutput.has_po_pr_claim;
    this.has_ro_pr_claim = researchOutput.has_ro_pr_claim;
    this.ro_rs_supplied = researchOutput.ro_rs_supplied;
    this.po_rs_supplied = researchOutput.po_rs_supplied;
    this.is_indigenous_research = researchOutput.is_indigenous_research ?? false; // Default to false if null
    this.sensitivity_types = researchOutput.sensitivity_types || [];
    this.other_sensitivity_note = researchOutput.other_sensitivity_note || '';
    this.has_bylines = researchOutput.has_bylines;
  }

  static fromList(researchOutputs = []) {
    return researchOutputs
      .map(researchOutput => new ResearchOutput(researchOutput));
  }

  effectiveTitle() {
    return this.is_translated ? this.title_translated : this.title_nativescript;
  }

  isMultiDisciplinary() {
    return this.journal_is_multi_disciplinary;
  }

  getJournalForcs() {
    if (!this.isJournalArticle() || this.isMultiDisciplinary()) {
      return [];
    }

    return this.journal_forcs;
  }

  getPreviousJournalForcs() {
    if (!this.isJournalArticle() || this.isMultiDisciplinary()) {
      return [];
    }

    return this.previous_era_journal_forcs;
  }

  disableClawbackLogic() {
    return this.disable_clawback_logic;
  }

  isForcRestrictedByArc() {
    return (
      !this.disableClawbackLogic()
      && (this.isJournalArticle()
        && !this.isMultiDisciplinary()
        && !_.isEmpty(this.journal_forcs))
    );
  }

  getActiveClaims() {
    return this.ro_claims.filter(claim => claim.isActive());
  }

  getPreviousEraClaims() {
    return this.previous_era_claims;
  }

  getAuthorInterests() {
    const authorInterest = this.interests.filter(interest => interest.isAuthor());
    return (!_.isEmpty(authorInterest)) ? authorInterest[0] : new ResearchOutputInterest();
  }

  getJournalInterests() {
    const journalInterest = this.interests.filter(interest => interest.isJournal());
    return (!_.isEmpty(journalInterest)) ? journalInterest[0] : new ResearchOutputInterest();
  }

  getOrgUnitInterests() {
    const orgUnitInterest = this.interests.filter(interest => interest.isOrgUnit());
    return (!_.isEmpty(orgUnitInterest)) ? orgUnitInterest[0] : new ResearchOutputInterest();
  }

  activeClaimsAddsToHundred() {
    return _.sumBy(this.getActiveClaims(), claim => claim.apportion) === 100;
  }

  isJournalArticle() {
    return this.research_output_type === ResearchOutput.TYPES.JOURNAL_ARTICLE.value;
  }

  static getNonTraditionalROTypes() {
    const ntros = _.filter(ResearchOutput.typesMapping(), { type: ResearchOutput.TYPE.NTRO });
    return _.map(ntros, 'value');
  }

  static getTraditionalROTypes() {
    const traditionals = _.filter(ResearchOutput.typesMapping(), { type: ResearchOutput.TYPE.TRADITIONAL });
    return _.map(traditionals, 'value');
  }

  isNonTraditionalResearchOutput() {
    return _.includes(ResearchOutput.getNonTraditionalROTypes(), this.research_output_type);
  }

  isValidStatus() {
    return this.hasActiveClaims() && this.activeClaimsAddsToHundred();
  }

  hasActiveClaims() {
    return this.getActiveClaims().length !== 0;
  }

  uniqueCreators() {
    return _.uniqBy(this.creators, 'order');
  }

  getAbdcRanking() {
    if (_.isEmpty(this.abdc_rankings)) {
      return undefined;
    }
    return `${this.abdc_rankings[0].value} -  ${this.abdc_rankings[0].year}`;
  }

  getResearchStatementStatus() {
    if (this.containsPOResearchStatement() || this.containsROResearchStatement()) {
      return RESEARCH_STATEMENT_STATUS_COLOR_INDICATORS.contains;
    } if (this.missingPOResearchStatement() || this.missingROResearchStatement()) {
      return RESEARCH_STATEMENT_STATUS_COLOR_INDICATORS.missing;
    } if (this.containsPOResearchStatementAndNoPRClaim() || this.isMemberOfPortfolioWithNoResearchStatementAndWithROResearchStatement()) {
      return RESEARCH_STATEMENT_STATUS_COLOR_INDICATORS.partial;
    }
    return undefined;
  }

  containsPOResearchStatement() {
    return (this.isMemberOfPortfolio && this.has_po_pr_claim && this.po_rs_supplied);
  }

  containsROResearchStatement() {
    return (!this.isMemberOfPortfolio && this.has_ro_pr_claim && this.ro_rs_supplied);
  }

  missingPOResearchStatement() {
    return (this.isMemberOfPortfolio && this.has_po_pr_claim && !this.po_rs_supplied);
  }

  missingROResearchStatement() {
    return (!this.isMemberOfPortfolio && this.has_ro_pr_claim && !this.ro_rs_supplied);
  }

  containsPOResearchStatementAndNoPRClaim() {
    return (this.isMemberOfPortfolio && !this.has_po_pr_claim && this.po_rs_supplied);
  }

  isMemberOfPortfolioWithNoResearchStatementAndWithROResearchStatement() {
    return (this.isMemberOfPortfolio && !this.has_po_pr_claim && !this.po_rs_supplied && this.ro_rs_supplied);
  }

  isResearchStatementRequired() {
    return this.containsPOResearchStatement() || this.containsROResearchStatement() || this.missingPOResearchStatement()
      || this.missingROResearchStatement() || this.containsPOResearchStatementAndNoPRClaim()
      || this.isMemberOfPortfolioWithNoResearchStatementAndWithROResearchStatement();
  }

  hasPortfolioResearchStatement() {
    return this.po_rs_supplied;
  }

  hasROResearchStatement() {
    return this.ro_rs_supplied;
  }

  getResearchStatementTitle() {
    if (!this.isResearchStatementRequired()) {
      return undefined;
    }

    return this.isMemberOfPortfolio ? 'Portfolio Research Statement' : 'RO Research Statement';
  }

  getPortfolioName() {
    return this.isMemberOfPortfolio ? this.portfolio.name : undefined;
  }

  getPortfolioStatus() {
    return this.isMemberOfPortfolio ? _.startCase(_.toLower(this.portfolio.status)) : undefined;
  }

  hasPortfolioClaims() {
    return this.isMemberOfPortfolio ? this.portfolio.hasClaims() : false;
  }

  getPortfolioClaims() {
    return this.hasPortfolioClaims() ? this.portfolio.getClaims() : [];
  }

  getPortfolioResearchStatement() {
    return this.isMemberOfPortfolio ? this.portfolio.researchStatement : new ResearchStatement();
  }

  compare(id) {
    return this.id === id;
  }

  get hasSensitiveContent() {
    return !!(this.other_sensitivity_note || this.sensitivity_types.length);
  }

  reComputeHasRoPrClaim() {
    this.has_ro_pr_claim = this.ro_claims.some(claim => claim.is_peer_reviewed);
  }

  get hasSinglePrClaim() {
    return this.ro_claims.filter(claim => claim.is_peer_reviewed).length === 1;
  }

  clearSensitivityInfo() {
    this.sensitivity_types = [];
    this.other_sensitivity_note = '';
  }

  addOrUpdateClaim(claim) {
    const claims = claim.id ? this.ro_claims.map(
      roClaim => (roClaim.id === claim.id ? new ResearchOutputClaim(claim) : roClaim),
    ) : [...this.ro_claims, new ResearchOutputClaim(claim)];
    this.ro_claims = claims;
    this.reComputeHasRoPrClaim();
    if (!this.has_ro_pr_claim) {
      this.clearSensitivityInfo();
    }
  }

  deleteClaim(claim) {
    this.ro_claims = this.ro_claims.filter(roClaim => roClaim.id !== claim.id);
    this.reComputeHasRoPrClaim();
    if (!this.has_ro_pr_claim) {
      this.clearSensitivityInfo();
    }
  }

  updatePartial(params) {
    Object.assign(this, params);
  }

  isPeerReviewedClaim(id) {
    return this.ro_claims.find(roClaim => roClaim.id === id).is_peer_reviewed;
  }
}

export default ResearchOutput;
