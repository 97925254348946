<template>
  <q-tabs
    align="justify"
    class="bg-white scrollable-section"
    style="overflow-y: auto !important"
    panes-container-class="scrollable-section"
    inverted
  >
    <q-route-tab
      v-if="hasPermission($permissions.MANAGE_WHAT_IF)"
      slot="title"
      :count="workflowStats.draft_count"
      name="drafts"
      :to="{name: 'what-ifs-draft'}"
      exact
      label="Drafts"
      icon="far fa-edit"
      default
    />
    <q-route-tab
      v-if="hasPermission($permissions.MANAGE_WHAT_IF)"
      slot="title"
      :count="workflowStats.pending_count"
      name="pending"
      :to="{name: 'what-ifs-pending'}"
      exact
      label="Pending"
      icon="far fa-clock"
    />
    <q-route-tab
      v-if="hasPermission($permissions.MANAGE_WHAT_IF)"
      slot="title"
      :count="workflowStats.approved_count"
      name="approved"
      :to="{name: 'what-ifs-approved'}"
      exact
      label="Ready to Apply"
      icon="mdi-thumb-up-outline"
    />
    <q-route-tab
      v-if="hasPermission($permissions.MANAGE_WHAT_IF)"
      slot="title"
      :count="workflowStats.applied_count"
      name="applied"
      :to="{name: 'what-ifs-applied'}"
      exact
      label="Applied"
      icon="far fa-check-circle"
    />
    <q-route-tab
      v-if="hasPermission($permissions.MANAGE_WHAT_IF)"
      slot="title"
      :count="workflowStats.rejected_count"
      name="rejected"
      :to="{name: 'what-ifs-rejected'}"
      exact
      label="Rejected"
      icon="mdi-thumb-down-outline"
    />
    <q-card flat class="col-xs-12 q-pt-lg scrollable-section items-start">
      <router-view />
    </q-card>
  </q-tabs>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'WhatifOverview',
  computed: {
    ...mapState('whatIfs', ['whatIfs', 'workflowStats']),
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    ...mapActions(
      'whatIfs',
      ['fetchWorkflowStats'],
    ),
  },
  watch: {
    whatIfs() {
      this.fetchWorkflowStats();
    },
  },
  mounted() {
    this.fetchWorkflowStats();
  },
};
</script>

<style lang="stylus" scoped>
  .q-tab
    min-height: 65px

</style>
