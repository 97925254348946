export const whatIfStatusDisplays = {
  DRAFT: {
    icon: 'far fa-edit',
    label: 'Not submitted',
    color: 'mq-soft',
  },
  PROPOSED: {
    icon: 'far fa-clock',
    label: 'Pending Approval',
    color: 'mq-soft',
  },
  REJECTED: {
    icon: 'mdi-thumb-down-outline',
    label: 'Rejected',
    color: 'negative',
  },
  APPROVED: {
    icon: 'mdi-thumb-up-outline',
    label: 'Ready to Apply',
    color: 'positive',
  },
  APPLIED: {
    icon: 'far fa-check-circle',
    label: 'Applied',
    color: 'mq-positive',
  },
};

export default { whatIfStatusDisplays };
