<template>
  <span class="justify-center">
    <q-chip
      v-if="!whatIf.isEmpty()"
      square
      class="on-left q-pl-lg"
      :icon="whatIfStatusDisplay.icon"
    >
      {{ whatIfStatusDisplay.label }}
    </q-chip>
    <q-btn
      v-if="canLoggedInUserEditCurrentWhatIf"
      class="r-m-right"
      color="mq-soft-muted"
      label="Edit"
      @click="navigateToEdit()"
    />
    <q-btn
      color="mq-radar-dark"
      label="View what-ifs list"
      class="on-left"
      wait-for-ripple
      @click="$router.push({name: 'what-ifs-draft'})"
    />
    <template v-if="whatIf.isProposed()">
      <template v-if="doesLoggedInUsersFacultyGetImpactedByWhatIf">
        <q-btn
          v-if="!hasLoggedInUsersFacultySubmittedCurrentWhatIf"
          class="r-m-right"
          color="blue-grey-5"
          label="Reject"
          :icon="whatIfIconForReject"
          :disable="isWhatIfWorkflowDisabled"
          @click="submitEvent('reject')"
        />
        <q-btn
          v-if="!hasLoggedInUsersFacultyAlreadyAcceptedCurrentWhatIf"
          color="primary"
          :icon="whatIfIconForAccept"
          label="Accept"
          :disable="isWhatIfWorkflowDisabled"
          @click="submitEvent('accept')"
        />
        <span v-else>
          {{ hasLoggedInUsersFacultySubmittedCurrentWhatIf ? 'Pending review': 'Accepted' }}
        </span>
      </template>
    </template>
    <q-btn
      v-if="canLoggedInUserApplyWhatIf"
      color="primary"
      :icon="whatIfIconForApply"
      :disable="!hasLoggedInUsersFacultySubmittedCurrentWhatIf"
      label="Apply"
      @click="submitEvent('apply')"
    />
    <q-btn
      v-else-if="whatIf.isNotSubmitted()"
      color="primary"
      :icon="whatIfIconForPropose"
      :disabled="!canSubmitWhatIf"
      label="Propose"
      @click="submitEvent('propose')"
    />
    <radar-modal v-model="isWhatIfWorkflowDetailDisplayed">
      <what-if-scenario-workflow-comment
        :what-if="whatIf"
        :event-type="eventType"
        @cancel="hideWhatIfWorkflowDetailDisplayed"
        @submitted="submitted"
        @error="$notify.failure"
      />
    </radar-modal>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import WhatIf, { WHAT_IF_STATES } from '@/models/what-if/what-if';
import RadarModal from '@/components/common/RadarModal.vue';
import { whatIfStatusDisplays } from '../../overview/what-if-status-display';
import WhatIfScenarioWorkflowComment from './WhatIfScenarioWorkflowComment.vue';

export default {
  name: 'WhatIfScenarioWorkflow',
  components: { WhatIfScenarioWorkflowComment, RadarModal },
  props: {
    whatIf: {
      type: WhatIf,
      required: true,
    },
  },
  data() {
    return {
      isWhatIfWorkflowDetailDisplayed: false,
      eventType: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'canUserManageWhatIf',
      'canUserProposeWhatIf',
      'loggedInUser',
      'userFacultiesForManageWhatIf']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('whatIfScenario', [
      'canLoggedInUserApplyWhatIf',
      'canLoggedInUserEditCurrentWhatIf',
      'doesLoggedInUsersFacultyGetImpactedByWhatIf',
      'hasLoggedInUsersFacultyAlreadyAcceptedCurrentWhatIf',
      'hasLoggedInUsersFacultySubmittedCurrentWhatIf',
      'canLoggedInUserProposeCurrentWhatIf',
    ]),
    isWhatIfWorkflowDisabled() {
      return this.canUserManageWhatIf && !this.canUserProposeWhatIf;
    },
    canSubmitWhatIf() {
      return this.whatIf.id && this.canUserManageWhatIf
        && this.whatIf.canSubmitWhatIf(this.loggedInUser) && this.canLoggedInUserProposeCurrentWhatIf;
    },
    whatIfStatusDisplay() {
      return whatIfStatusDisplays[this.whatIf.state];
    },
    whatIfIconForReject() {
      return whatIfStatusDisplays[WHAT_IF_STATES.REJECTED].icon;
    },
    whatIfIconForAccept() {
      return whatIfStatusDisplays[WHAT_IF_STATES.APPROVED].icon;
    },
    whatIfIconForApply() {
      return whatIfStatusDisplays[WHAT_IF_STATES.APPLIED].icon;
    },
    whatIfIconForPropose() {
      return whatIfStatusDisplays[WHAT_IF_STATES.PROPOSED].icon;
    },
  },
  methods: {
    navigateToEdit() {
      this.$router.push({
        name: 'what-if-edit',
        params: { eraId: this.workingEra.id, whatIfId: this.whatIf.id },
        query: { impactedOnly: true, page: 1 },
      });
    },
    hideWhatIfWorkflowDetailDisplayed() {
      this.isWhatIfWorkflowDetailDisplayed = false;
    },
    showWhatIfWorkflowDetailDisplayed() {
      this.isWhatIfWorkflowDetailDisplayed = true;
    },
    submitEvent(eventType) {
      this.eventType = eventType;
      this.showWhatIfWorkflowDetailDisplayed();
    },
    async submitted(msg) {
      this.hideWhatIfWorkflowDetailDisplayed();
      this.saveSuccess = `What If successfully ${msg}`;
      let status;
      switch (this.eventType) {
        case 'reject':
          status = 'rejected';
          break;
        case 'accept':
          status = 'pending';
          break;
        case 'apply':
          status = 'applied';
          break;
        case 'propose':
          status = 'pending';
          break;
        default:
          break;
      }
      this.$router.push({ name: `what-ifs-${status}`, params: { eraId: this.workingEra.id, status } });
    },
  },
};
</script>
