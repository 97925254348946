<template>
  <div
    v-if="isEscalated || canBeEscalated"
    class="self-start items-center row border round-borders bg-mq-neutral group"
  >
    <div class="col-auto">
      <q-icon name="warning" color="negative" class="alert-icon" />
    </div>
    <div class="main col term-definition">
      <span v-if="isEscalated">
        Claims are currently escalated to {{ isL1? 'Faculty': 'University' }} level.
      </span>
      <div v-if="canBeEscalated">
        Note that claims are currently invalid. Escalate disputes if they can’t be resolved.
      </div>
      <div v-if="canResolveL1OrL2">
        <div v-if="canResolveAllL1 || canResolveL2">
          You can resolve all disputes.
          <div v-if="!resolutionConditionsMet">
            Status needs to be valid before resolving.
          </div>
        </div>
        <div v-if="canResolveL1 && !canResolveAllL1">
          You can resolve some disputes.
        </div>
      </div>
    </div>
    <div class="actions col-auto">
      <div class="q-mb-sm">
        <q-btn
          v-if="canBeEscalated"
          type="button"
          :disable="!escalationsConditionsMet"
          label="Escalate"
          color="primary"
          @click="escalate()"
        />
      </div>
      <div>
        <q-btn
          v-if="canResolveL1OrL2"
          type="button"
          :disable="!resolutionConditionsMet"
          label="Resolve"
          color="primary"
          @click="resolve()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dispute } from '@/models/disputes/dispute';
import ResearchOutputStatus from '@/models/research-output/research-output-status';
import EscalationsMixin from '@/mixins/EscalationsMixin';

export default {
  name: 'ResearchOutputClaimEscalation',
  mixins: [EscalationsMixin],
  props: {
    dispute: {
      type: Dispute,
      required: true,
    },
    status: {
      type: ResearchOutputStatus,
      default: () => ResearchOutputStatus.noClaimsStatus(),
    },
    claims: {
      type: Array,
      required: true,
    },
  },
  computed: {
    canBeEscalated() {
      return this.status.hasClaims() && !this.status.isValid() && this.userCanEscalate;
    },
  },
  methods: {
    escalate() {
      this.$emit('escalate');
    },
    resolve() {
      this.$emit('resolve');
    },
  },
};
</script>

<style lang="stylus" scoped>
  .alert-icon
    font-size 24px
    color $negative

</style>
