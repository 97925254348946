<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="container">
    <polygon
      class="cls-2"
      points="9.24 8.72 10.52 9.45 10.61 9.45 10.6 9.5 14.92 12 14.92 4 9.24 7.28 9.24 8.72"
    />
    <polygon
      class="cls-3"
      points="5.47 9.45 6.75 8.72 6.75 7.28 1.07 4 1.07 12 5.4 9.49 5.39 9.45 5.47 9.45"
    />
    <polygon class="cls-2" points="8 0 14.92 4 14.92 0 8 0" />
    <polygon class="cls-3" points="1.07 0 1.07 4 8 0 1.07 0" />
    <path
      class="cls-3"
      d="M10.84,10.57v.49h-.23c-.24,.15-.39,.41-.42,.69h-.26l.03,.54,.14,2.48,4.82-2.79-4.33-2.5-.24,1.07h.48Z"
    />
    <polygon class="cls-3" points="10.52 9.45 9.24 8.72 9.24 9.45 10.52 9.45" />
    <path
      class="cls-2"
      d="M5.64,10.57l-.24-1.08L1.07,12l4.82,2.79,.16-3.03h-.25c-.03-.28-.19-.54-.43-.69h-.23v-.49h.49Z"
    />
    <polygon class="cls-2" points="6.75 8.72 5.47 9.45 6.75 9.45 6.75 8.72" />
    <path
      class="cls-1"
      d="M8.15,4.92s.06,0,.09,0c.07-.07,.11-.16,.11-.26,0-.2-.16-.36-.36-.36-.03,0-.06,0-.09,.01-.14,.13-.15,.36-.01,.5,.07,.07,.16,.11,.26,.11Z"
    />
    <path
      class="cls-1"
      d="M8.56,11.86c-1,.01-1.28,.62-1.28,.62l-.06,.13-.1,2.88,.88,.52,2.1-1.21-.14-2.49s-.27-.45-1.4-.44Z"
    />
    <path
      class="cls-4"
      d="M5.15,10.57v.49h.23c.24,.15,.4,.41,.43,.69h.25l-.16,3.03,1.22,.7,.1-2.87,.06-.13s.28-.61,1.28-.63c1.13-.02,1.39,.41,1.4,.44l-.03-.54h.26c.03-.28,.19-.54,.43-.69h.23v-.49H5.15Z"
    />
    <path
      class="cls-4"
      d="M8.24,13.89c0-.13-.11-.24-.24-.24s-.24,.11-.24,.24v.62h.48v-.62Z"
    />
    <polygon
      class="cls-3"
      points="7.08 6.84 7.08 7.2 8 7.73 8.91 7.2 8.91 6.84 7.08 6.84"
    />
    <polygon
      class="cls-3"
      points="8 8.27 7.08 8.8 7.08 9.15 8.91 9.15 8.91 8.8 8 8.27"
    />
    <path
      class="cls-3"
      d="M9.25,6.55h.1c.08,0,.15,.07,.15,.15s-.07,.15-.15,.15h-.1v.44l5.68-3.28L8,0V4.3c.2,0,.35,.16,.35,.36,0,.09-.03,.17-.09,.24h0c-.06,.05-.09,.12-.09,.2,0,.09,.04,.18,.12,.23,.57,.13,.97,.64,.97,1.22Z"
    />
    <path
      class="cls-4"
      d="M8,9.15h-.92v-.35l.92-.53h0v-.55l-.92-.53v-.34h.4s-.05-.08-.05-.15,.03-.15,.06-.15h.04c0-.69,.21-1.24,.47-1.24,.09,0,.19,.01,.28,.03-.07-.05-.12-.14-.12-.23,0-.07,.03-.14,.08-.19-.03,0-.06,0-.09,0-.2,0-.35-.16-.36-.35,0-.1,.04-.19,.11-.26-.16,.04-.27,.18-.27,.34,0,.09,.04,.17,.1,.23,.06,.05,.09,.13,.09,.21,0,.09-.04,.18-.12,.23-.56,.13-.96,.63-.96,1.21h-.1c-.08,0-.15,.07-.15,.15s.07,.15,.15,.15h.1v2.62h1.24v-.3Z"
    />
    <path
      class="cls-1"
      d="M8,7.72v.55l.92,.53v.35h-.92v.3h-2.61l.25,1.12h4.72l.25-1.12h-1.36v-2.6h.1c.08,0,.15-.07,.15-.15s-.07-.15-.15-.15h-.1c0-.69-.56-1.24-1.24-1.24-.26,0-.47,.56-.47,1.24h-.04s-.06,.07-.06,.15,.02,.14,.05,.15h1.43v.35l-.92,.52Z"
    />
    <polygon class="cls-3" points="7.08 7.66 7.67 8 7.08 8.34 7.08 7.66" />
    <polygon class="cls-2" points="8.91 7.66 8.32 8 8.91 8.34 8.91 7.66" />
    <polygon
      class="cls-1"
      points="10.57 4.34 10.04 4.83 10.1 4.11 9.38 4 9.98 3.59 9.62 2.97 10.31 3.18 10.57 2.51 10.84 3.18 11.53 2.97 11.17 3.59 11.76 4 11.05 4.11 11.1 4.83 10.57 4.34"
    />
    <path
      class="cls-2"
      d="M6.66,6.84c-.08,0-.15-.07-.15-.15s.07-.15,.15-.15h.1c0-.58,.4-1.08,.96-1.21,.07-.06,.12-.14,.12-.23,0-.08-.03-.16-.09-.21-.06-.07-.1-.15-.1-.25,0-.16,.11-.3,.27-.34,.03,0,.06-.01,.09-.01V0L1.07,4l5.68,3.28v-.44h-.1Z"
    />
    <path class="cls-4" d="M6.9,10.35h.36l-.05-.67h-.4l.08,.67Z" />
    <path class="cls-4" d="M7.5,10.35h.47v-.68h-.52l.05,.68Z" />
    <path class="cls-2" d="M6.32,10.35h.33l-.08-.67h-.38l.13,.67Z" />
    <path class="cls-2" d="M5.82,10.35h.25l-.13-.67h-.27l.15,.67Z" />
    <path class="cls-3" d="M9.34,10.35h.33l.13-.67h-.38l-.08,.67Z" />
    <path class="cls-3" d="M9.92,10.35h.27l.15-.67h-.29l-.13,.67Z" />
  </svg>
</template>
<script>
export default {
  name: 'MQBylineLogo',
};
</script>
<style lang="stylus" scoped>

.container
    height 16px
    width 16px

    .cls-1
        fill #fff

    .cls-2
        fill #e52823

    .cls-3
        fill #6d0020

    .cls-4
        fill #b3b3b3

</style>
