<template>
  <div class="full-width relative-position scrollable-section">
    <div v-if="requiresResearchStatement" class="col-6 q-mt-lg">
      <div class="bg-white q-pa-lg round-borders border border-mq-magenta flex items-center">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
        <span>Research Statement is  mandatory for this Portfolio as it’s marked as Peer Review.</span>
      </div>
    </div>
    <div class="research-statement-section">
      <research-statement-component
        :research-statement="portfolio.researchStatement"
        :disable="isDisabled"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import ResearchStatementComponent from '@/components/common/ResearchStatementComponent.vue';

export default {
  name: 'PortfolioResearchStatement',
  components: {
    ResearchStatementComponent,
  },
  mixins: [PermissionsMixin],
  computed: {
    ...mapState('portfolios', ['portfolio', 'portfolioStatus']),
    ...mapGetters('eras', ['workingEra']),
    isFinalised() {
      return this.portfolio.status === this.portfolioStatus.finalised;
    },
    isDisabled() {
      return this.isFinalised || !this.canUserManagePortfolio;
    },
    isPeerReviewed() {
      return this.portfolio.hasPeerReviewedClaims();
    },
    requiresResearchStatement() {
      return this.portfolio.hasPeerReviewedClaims() && !this.portfolio.hasResearchStatement();
    },
  },
  methods: {
    ...mapActions('portfolios', ['updateResearchStatement']),
    ...mapActions('researchOutputs', ['searchResearchOutputs']),
    isPeerReviewedMessage() {
      return !this.isPeerReviewed ? 'The portfolio is not tagged for peer review. '
              + 'This means it will not be included in the ERA submission.' : '';
    },
    refreshResearchOutputs() {
      this.searchResearchOutputs({ portfolioId: this.portfolio.id, eraId: this.workingEra.id, excludeLoadingFirstRecord: true });
    },
    async save(researchStatement) {
      try {
        await this.updateResearchStatement(researchStatement);
        await this.refreshResearchOutputs();
        this.$notify.success(`Research Statement Saved. ${this.isPeerReviewedMessage()}`);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
  .research-statement-section
    display flex
    padding 1.5rem
    background-color $mq-white
    overflow-x auto
</style>
