<template>
  <div :key="researchOutput.id" class="full-width">
    <div class="col-xs-12 full-height">
      <q-tabs class="full-width" inverted>
        <q-tab
          v-if="!researchOutput.isMemberOfPortfolio"
          slot="title"
          name="claims"
          label="Claims"
          default
          @select="fetchActiveClaimsForWorkingEra()"
        />
        <q-tab
          v-else
          slot="title"
          name="portfolio"
          label="Portfolio"
          default
        />
        <q-tab
          slot="title"
          name="sensitivities"
          label="Sensitivities"
          :disable="!hasPeerReviewedClaims"
          :alert="researchOutput.hasSensitiveContent"
          class="sensitivity"
          @select="fetchSensitivityTypes()"
        />
        <q-tab
          v-if="researchOutput.isMemberOfPortfolio"
          slot="title"
          name="portfolioResearchStatement"
          label="Portfolio Research Statement"
          @select="fetchResearchStatementForWorkingEra()"
        />
        <q-tab
          v-if="isNtroWithAnyPeerReview"
          slot="title"
          name="researchStatement"
          label="RO Research Statement"
          @select="fetchResearchStatementForWorkingEra()"
        />

        <q-tab v-if="canUserManageEra" slot="title" name="eligibility" label="Eligibility" />
        <q-tab
          slot="title"
          name="history"
          label="History"
          @select="fetchClaimEventsForWorkingEra()"
        />
        <q-tab-pane name="claims" class="q-pr-none">
          <template v-if="researchOutput.ro_status.isEligible()">
            <div class="full-width">
              <research-output-claim-escalation
                :dispute="dispute"
                :claims="claims"
                :status="researchOutput.ro_status"
                @escalate="escalate"
                @resolve="resolve"
              />
            </div>
            <div v-if="canUserManageResearchOutputClaim" class="self-end full-width q-pa-sm">
              <h2 class="r-heading-3 text-mq-soft">Research Output Attributes</h2>
              <research-output-attribute-extension
                ref="researchOutputAttributeExtension"
                :research-output="researchOutput"
                :disable="!doesEscalationsAllowNewClaimToBeAdded"
                class="full-width"
                @save="saveResearchOutputAttributes"
              />
              <h2 class="r-heading-3 text-mq-soft">New Claim</h2>
              <new-research-output-claim
                ref="newResearchOutputClaim"
                :disable="!doesEscalationsAllowNewClaimToBeAdded"
                class="full-width"
                :journal-forcs="researchOutput.journal_forcs"
                :is-multi-disciplinary="isMultiDisciplinary"
                :forcs="forcsForWorkingEra"
                :user-forcs="userForcsForRoClaim"
                :clawback-bypassed-forcs="clawbackBypassedForcs"
                @save="saveResearchOutputClaim"
              />
            </div>
            <div class="self-end full-width q-pa-sm">
              <h2 class="r-heading-3 text-mq-soft">
                Current Claims — {{ workingEraForcReference.year }} ANZSRC Classifications
              </h2>
              <div>
                <a :href="anzsrcMappingUrl" target="_blank" class="icon-link items-baseline">
                  <q-icon color="mq-primary" name="description" />
                  View All ANZSRC Standard Classifications</a>
              </div>
            </div>
            <research-output-claims
              :research-output="researchOutput"
              :research-output-claims="claims"
              :dispute="dispute"
              :forcs="forcsForWorkingEra"
              class="r-p-vertical"
              @save="saveResearchOutputClaim"
              @delete="deleteResearchOutputClaim"
            />
          </template>
          <div v-else class="q-mt-lg q-pa-lg border border-mq-magenta round-borders flex no-wrap items-center">
            <q-icon name="lock" class="material-icons-outlined q-mr-lg" color="mq-magenta" size="24px" />
            <span>This title is locked. Only eligible Research Outputs can be coded.</span>
          </div>
        </q-tab-pane>
        <q-tab-pane name="portfolio" class="q-pr-none">
          <div class="full-width">
            <h2 class="r-heading-3 text-mq-soft">
              Portfolio Title
            </h2>
            <div>
              <q-icon color="mq-primary" name="business_center" />
              <router-link
                :to="{
                  name: 'portfolio-details',
                  params: { portfolioId: researchOutput.portfolio.id, eraId: workingEra.id }
                }"
                class="text-mq-highlight"
              >
                {{ researchOutput.getPortfolioName() }}
              </router-link>
            </div>
          </div>
        </q-tab-pane>
        <q-tab-pane name="portfolioResearchStatement" class="q-pr-none">
          <portfolio-research-statement
            :research-statement="researchOutput.getPortfolioResearchStatement()"
            disable
            :is-finalised="researchOutput.portfolio.isFinalised()"
          />
        </q-tab-pane>
        <q-tab-pane name="researchStatement" class="q-pr-none">
          <research-output-research-statement
            :disable="!canUserManageResearchStatement || researchOutput.isMemberOfPortfolio"
            :is-member-of-portfolio="researchOutput.isMemberOfPortfolio"
          />
        </q-tab-pane>
        <q-tab-pane name="eligibility" class="q-pr-none">
          <research-output-eligibility
            :eligibility-status="researchOutput.ro_status.forced"
            @save="saveEligibility"
          />
        </q-tab-pane>
        <q-tab-pane name="history" class="q-pr-none">
          <research-output-claim-events
            :research-output="researchOutput"
            :research-output-claim-events="claimEvents"
            :pagination="eventsPagination"
            :era-id="workingEra.id"
            @paginate="fetchClaimEventsForWorkingEra"
          />
        </q-tab-pane>
        <q-tab-pane name="sensitivities" class="q-pr-none">
          <research-output-sensitivities
            :sensitivity-types="sensitivityTypes"
            :selected-sensitivity-types="researchOutput.sensitivity_types"
            :selected-other-type-text="researchOutput.other_sensitivity_note"
            :is-readonly-mode-enabled="!canUserManageSensitivity"
            @save="saveResearchOutputSensitivity"
          />
        </q-tab-pane>
      </q-tabs>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ReferenceMeta from '@/models/era/reference-meta';
import EscalationsMixin from '@/mixins/EscalationsMixin';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import NewResearchOutputClaim from '../claims-and-escalations/NewResearchOutputClaim.vue';
import ResearchOutputAttributeExtension from '../claims-and-escalations/ResearchOutputAttributeExtension.vue';
import ResearchOutputClaimEscalation
  from '../claims-and-escalations/ResearchOutputClaimEscalation.vue';
import ResearchOutputClaimEvents from '../claims-and-escalations/ResearchOutputClaimEvents.vue';
import ResearchOutputClaims from '../claims-and-escalations/ResearchOutputClaims.vue';
import ResearchOutputResearchStatement from '../claims-and-escalations/ResearchOutputResearchStatement.vue';
import PortfolioResearchStatement from '../claims-and-escalations/PortfolioResearchStatement.vue';
import ResearchOutputEligibility from '../claims-and-escalations/ResearchOutputEligibility.vue';
import ResearchOutputSensitivities from './ResearchOutputSensitivities.vue';

export default {
  name: 'ResearchOutputWorkArea',
  components: {
    PortfolioResearchStatement,
    ResearchOutputClaims,
    ResearchOutputClaimEscalation,
    ResearchOutputAttributeExtension,
    NewResearchOutputClaim,
    ResearchOutputClaimEvents,
    ResearchOutputResearchStatement,
    ResearchOutputEligibility,
    ResearchOutputSensitivities,
  },
  mixins: [EscalationsMixin, PermissionsMixin],
  data() {
    return {
      newClaim: new ResearchOutputClaim(),
    };
  },
  computed: {
    ...mapState('researchOutputs', ['progress']),
    ...mapState('researchOutputClaimEvents', {
      claimEvents: state => state.claimEvents,
      eventsPagination: state => state.pagination,
      fetchingClaimEvents: state => state.progress.fetching,
    }),
    ...mapState('sensitivityTypes', {
      sensitivityTypes: state => state.sensitivityTypes,
    }),
    ...mapState('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'workingEraForcReference']),
    ...mapGetters('auth', ['userForcsForRoClaim', 'clawbackBypassedForcs', 'canUserManageSensitivity']),
    ...mapGetters('researchOutputs', ['researchOutput', 'dispute', 'claims']),

    showLoading() {
      return this.progress.fetchingClaims
        || this.progress.deletingClaim
        || this.progress.savingClaim
        || this.progress.savingResearchOutput
        || this.fetchingClaimEvents;
    },
    isEscalated() {
      return this.dispute;
    },
    hasClaims() {
      return !_.isEmpty(this.claims);
    },
    isMultiDisciplinary() {
      return !this.researchOutput.isForcRestrictedByArc();
    },
    hasPeerReviewedClaims() {
      return this.researchOutput.has_ro_pr_claim;
    },
    isNtroWithAnyPeerReview() {
      return this.researchOutput.isNonTraditionalResearchOutput()
        && this.hasPeerReviewedClaims;
    },
    doesEscalationsAllowNewClaimToBeAdded() {
      if (!this.dispute.isEscalated()) return true;

      return this.canUserResolveL1 || this.canUserResolveL2;
    },
    anzsrcMappingUrl() {
      return ReferenceMeta.anzsrcMappingUrl();
    },
  },
  methods: {
    ...mapActions(
      'researchOutputs',
      [
        'saveResearchOutput',
        'saveClaim',
        'deleteClaim',
        'fetchClaims',
        'escalateClaim',
        'resolveClaim',
        'saveEligibilityStatus',
      ],
    ),
    ...mapActions('researchStatements', ['fetchResearchStatement']),
    ...mapActions({
      fetchSensitivityTypes: 'sensitivityTypes/fetchAll',
    }),
    ...mapActions('researchOutputClaimEvents', ['fetchClaimEvents']),
    fetchActiveClaimsForWorkingEra() {
      this.fetchClaims({
        researchOutputId: this.researchOutput.id,
        claimStatus: 'active',
      });
    },
    fetchResearchStatementForWorkingEra() {
      this.fetchResearchStatement({
        researchOutputId: this.researchOutput.id,
      });
    },
    fetchClaimEventsForWorkingEra(page = 1) {
      this.fetchClaimEvents({
        page,
        researchOutputId: this.researchOutput.id,
      });
    },
    async saveResearchOutputAttributes() {
      try {
        const { id, is_indigenous_research } = this.researchOutput;
        await this.saveResearchOutput({
          id,
          params: {
            is_indigenous_research,
          },
        });
        this.$notify.success('Research Output Attributes Saved.');
      } catch (e) {
        this.$notify.failure(e);
        return e;
      }
    },
    async saveResearchOutputSensitivity(params) {
      try {
        await this.saveResearchOutput({
          id: this.researchOutput.id,
          params,
        });
        this.$notify.success('Sensitivity Flag updated');
      } catch (e) {
        return e;
      }
    },
    async saveResearchOutputClaim(claim) {
      const researchOutputId = this.researchOutput.id;
      const claimToSave = new ResearchOutputClaim({
        ...claim, era_submission_id: this.workingEra.id, research_output_id: researchOutputId,
      });

      try {
        await this.saveClaim(claimToSave);
        this.$notify.success('Claim successfully saved.');
        if (claimToSave.isNew()) {
          this.newClaim = new ResearchOutputClaim();
        }
        this.$refs.newResearchOutputClaim.reset();
        return this.fetchActiveClaimsForWorkingEra();
      } catch (e) {
        this.$notify.failure(e);
        return e;
      }
    },
    async deleteResearchOutputClaim(claim) {
      try {
        await this.deleteClaim(claim);
        this.$notify.success('Claim successfully removed.');
        await this.fetchActiveClaimsForWorkingEra();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async escalate() {
      try {
        await this.escalateClaim(this.dispute.id);
        this.$notify.success('Claims successfully escalated.');
        this.fetchActiveClaimsForWorkingEra();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async resolve() {
      try {
        await this.resolveClaim(this.dispute.id);
        this.$notify.success('Resolved successfully.');
        this.fetchActiveClaimsForWorkingEra();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async saveEligibility(status) {
      try {
        await this.saveEligibilityStatus({
          researchOutputId: this.researchOutput.id,
          eligibility: status,
        });
        this.$notify.success('Eligibility successfully saved');
      } catch (e) {
        this.$notify.failure(e);
      }
    },

  },
};
</script>
<style lang="stylus" scoped>
  $indicator-color = #80235f
  $icon-link
    color $mq-purple
    font-size: 14px
    font-weight: normal
    font-style: normal
    line-height: 1.43;
    letter-spacing: 0.2px;

  a.icon-link
    @extend $icon-link
    text-decoration none
    &:visited
      color $mq-purple
      text-decoration: none

  .affiliations
    margin-left 2.2rem

  .author-name
    text-decoration none
    line-height 1.1rem

  .abstract
    text-decoration underline

  .q-collapsible
    .term-definition
      margin-bottom 24px

  .claim-status
    font-size 20px
    line-height 1.3
    color $secondary
    text-transform capitalize

  .dispute-status
    font-size 20px
    font-weight normal
    line-height 1.4
    color $mq-soft

    text-transform capitalize

  .forc
    font-size 16px
    font-weight normal
    font-style normal
    line-height 1.25
    letter-spacing 0.3px
    color $mq-soft-muted

  .apportion
    font-size 14px
    font-weight normal
    font-style normal
    line-height 1.43
    letter-spacing 0.2px
    color black
    word-break keep-all

  .icon-highlight-alert > .q-alert > .q-alert-side > .q-icon
    color $negative

  .sensitivity::v-deep .q-dot
    background $indicator-color
</style>
