import _ from 'lodash';
import User from '@/models/user/user';
import Portfolio from '@/models/portfolio/portfolio';
import ResearchOutputClaim from './research-output-claim';

export const allEventTypes = {
  ADD_CLAIM: {
    userFriendlyName: 'Added',
    changeType: 'claimEvent',
    icon: 'note_add',
  },
  UPDATE_CLAIM: {
    userFriendlyName: 'Edited',
    changeType: 'claimEvent',
    icon: 'edit',
  },
  REMOVE_CLAIM: {
    userFriendlyName: 'Deleted',
    changeType: 'claimEvent',
    icon: 'delete_forever',
  },
  CLAIM_SYNCED_FROM_PREVIOUS_ERA: {
    userFriendlyName: 'From previous era',
    changeType: 'claimEvent',
    icon: 'sync',
  },
  ADDED_FROM_BULK: {
    userFriendlyName: 'From bulk',
    changeType: 'claimEvent',
    icon: 'fas fa-cubes',
  },
  ESCALATE_TO_L1: {
    userFriendlyName: 'Escalated to faculty',
    changeType: 'disputeEvent',
    icon: 'fas fa-level-up-alt',
  },
  ESCALATE_TO_L2: {
    userFriendlyName: 'Escalated to university',
    changeType: 'disputeEvent',
    icon: 'fas fa-level-up-alt',
  },
  PARTIAL_RESOLUTION: {
    userFriendlyName: 'Partially resolved',
    changeType: 'disputeEvent',
    icon: 'gavel',
  },
  COMPLETE_RESOLUTION: {
    userFriendlyName: 'Resolved',
    changeType: 'disputeEvent',
    icon: 'gavel',
  },
  ADDED_FROM_WHATIF: {
    userFriendlyName: 'From what ifs',
    changeType: 'claimEvent',
    icon: 'question_answer',
  },
  ADDED_FROM_MAPPER: {
    userFriendlyName: 'ANZSRC mapped',
    changeType: 'claimEvent',
    icon: 'build',
  },
  APPLIED_AUTOMATIC_ELIGIBILITY: {
    userFriendlyName: 'Automatic Eligibility',
    changeType: 'eligibilityEvent',
    icon: 'flaky',
  },
  FORCED_ELIGIBLE: {
    userFriendlyName: 'Forced Eligible',
    changeType: 'eligibilityEvent',
    icon: 'done',
  },
  FORCED_INELIGIBLE: {
    userFriendlyName: 'Forced Ineligible',
    changeType: 'eligibilityEvent',
    icon: 'close',
  },
  REMOVED_FROM_PORTFOLIO: {
    userFriendlyName: 'Removed from Portfolio',
    changeType: 'portfolioEvent',
    icon: 'book',
  },
  ADDED_TO_PORTFOLIO: {
    userFriendlyName: 'Added to Portfolio',
    changeType: 'portfolioEvent',
    icon: 'book',
  },
  UPDATE_RO_ATTRIBX: {
    userFriendlyName: 'Update Research Output Attribute',
    changeType: 'researchOutputAttributeEvent',
    icon: 'edit_attributes',
  },
};

class ResearchOutputClaimEvent {
  constructor(claimEvent = {}) {
    this.id = claimEvent.id;
    this.event_type = claimEvent.event_type;
    this.updated_at = claimEvent.updated_at;
    this.updated_by = claimEvent.updated_by && new User(claimEvent.updated_by);
    this.claim_list = ResearchOutputClaim.fromList(claimEvent.claim_list);
    this.comment = claimEvent.comment;
    this.portfolio = claimEvent.portfolio && new Portfolio(claimEvent.portfolio);
  }

  static fromList(claimEvents = []) {
    return claimEvents
      .map(claimEvent => new ResearchOutputClaimEvent(claimEvent));
  }

  attributes() {
    return allEventTypes[this.event_type];
  }

  isOfDisputeType() {
    return this.attributes().changeType === 'disputeEvent';
  }

  isOfEligibilityType() {
    return this.attributes().changeType === 'eligibilityEvent';
  }

  isOfPortfolioType() {
    return this.attributes().changeType === 'portfolioEvent';
  }

  isOfRoAttributeExtensionType() {
    return this.attributes().changeType === 'researchOutputAttributeEvent';
  }

  getFirstClaim() {
    return _.first(this.claim_list);
  }

  userFriendlyActionName() {
    return this.attributes().userFriendlyName;
  }

  portfolioName() {
    return this.portfolio ? this.portfolio.name : undefined;
  }

  claims() {
    return this.claim_list;
  }

  actingUserDisplayName() {
    if (_.isNil(this.updated_by)) return 'System Change';
    return this.updated_by ? this.updated_by.displayName() : '';
  }
}

export default ResearchOutputClaimEvent;
