<template>
  <q-jumbotron dark class="flex items-center mq-banner-secondary q-py-lg overflow-hidden">
    <div class="mq-banner-overlay gt-md" />
    <div class="inline-block q-mr-sm">
      <badge
        class="researcher-badge text-secondary"
        color="mq-sand-dark"
        border
        :letter="badgeCharacter"
      />
    </div>
    <div v-if="researcher.id" class="inline-block q-pl-md">
      <div class="group">
        <div class="page-title-large capitalize">{{ researcher.displayName() }}</div>
        <div class="body-2">
          <span class="border-right border-mq-white q-pr-md">
            <q-icon name="fas fa-award q-pr-xs" />
            {{ researcher.getWorkFunction() }}
          </span>
          <span class="border-right border-mq-white q-px-md">
            {{ researcher.getAllOneIds().join(', ') }}
          </span>
          <span class="border-right border-mq-white q-px-md">Orc Id: {{ researcher.orcid }}</span>
          <span class="q-px-md" :class="{'border-right border-mq-white' : researcher.isIndigenousResearcher()}">{{ researcher.status.displayText() }}</span>
          <span v-if="researcher.isIndigenousResearcher()" class="q-px-md"> Indigenous Researcher </span>
        </div>
      </div>
    </div>
  </q-jumbotron>
</template>

<script>
import _ from 'lodash';
import Researcher from '@/models/researcher/researcher';
import ResearcherStatistics from '@/models/researcher/researcher-statistics';
import Badge from '@/components/common/Badge.vue';

export default {
  name: 'ResearcherBanner',
  components: {
    Badge,
  },
  props: {
    researcher: {
      type: Researcher,
      default: () => new Researcher(),
    },
    researcherStatistics: {
      type: ResearcherStatistics,
      default: () => new ResearcherStatistics(),
    },
  },
  computed: {
    herdcCountsAsPairs() {
      return this.researcherStatistics.herdcCountsAsPairs();
    },
    badgeCharacter() {
      return _.get(this.researcher, 'full_name', '')[0];
    },
    firstOneId() {
      return _.first(this.researcher.getAllOneIds());
    },
  },
};
</script>

<style lang="stylus" scoped>
  .researcher-banner
    background -webkit-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
    rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
    background -o-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
    rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
    background -ms-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
    rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
    background -moz-linear-gradient(left, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
    rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)
    background linear-gradient(to right, rgb(109, 0, 32) 0%, rgb(160, 13, 41) 21%,
    rgb(215, 25, 69) 57%, rgb(187, 10, 127) 78%, rgb(119, 0, 94) 98%, rgb(118, 0, 94) 100%)

  .search-researcher-link
    text-decoration none

    .q-chip:hover
        transform scale(1.05)
</style>
