import _ from 'lodash';
import Forc from './forc';

class ClaimItem {
  constructor(claim = {}) {
    this.id = claim.id;
    this.forc = new Forc(claim.forc);
    this.apportion = claim.apportion;
    this.isPeerReviewed = claim.is_peer_reviewed || false;
  }

  static fromList(claims = []) {
    return claims.map(claim => new ClaimItem(claim));
  }

  isSameAs(that) {
    return (
      _.get(this.forc, 'id') === _.get(that.forc, 'id')
      && this.apportion === that.apportion
      && this.isPeerReviewed === that.isPeerReviewed
    );
  }

  basicDetails() {
    return {
      forc_id: this.forc.id,
      apportion: this.apportion,
    };
  }
}

export default ClaimItem;
