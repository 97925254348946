<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="RCI Classes" :show-buttons="true">
      <report-table
        slot="table"
        :data="rciClassTable"
        :summary-row="rciClassSummary"
        :columns="rciClassCols"
        row-key="class"
      />
      <template slot="graph">
        <apexchart
          slot="graph"
          type="pie"
          height="400"
          :options="pieChartOptions"
          :series="pieChartSeries"
        />
        <apexchart
          type="bar"
          height="400"
          :options="barChartOptions"
          :series="barChartSeries"
        />
      </template>
    </report-container>
    <report-container title="RCO Class Comparison" class="q-mt-xl">
      <report-table
        slot="table"
        :data="rciComparisonTable"
        :columns="rciComparisonCols"
        row-key="lowClassCount"
      />
    </report-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatFloat, formatPercent, div } from '@/utils/formatter';
import VueApexCharts from 'vue-apexcharts';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptCitationAnalysis',
  components: {
    Apexchart: VueApexCharts,
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      rciClassCols: [
        { label: 'Class', field: 'class', align: 'center' },
        { label: 'RCI Range', field: 'range', align: 'center' },
        { label: 'No of Papers', field: 'count', format: formatFloat(2, true) },
        { label: '%', field: 'percent', format: formatPercent(0) },
      ],
      rciComparisonCols: [
        { label: 'Low RCI Class Count (Class 0-1)', field: 'lowClassCount', format: formatFloat(2, true) },
        { label: 'High RCI Class Count (Class 4-6)', field: 'highClassCount', format: formatFloat(2, true) },
        { label: 'Proportion of High to Low', field: 'proportion', format: formatFloat(2, true) },
      ],
      chartData: [],
    };
  },
  computed: {
    ...mapGetters('reporting', ['rciClassData']),
    totalCount() {
      return this.rciClassData.reduce((a, b) => a + b.count, 0);
    },
    rciClassTable() {
      return this.rciClassData.map(row => ({
        ...row,
        class: `Class ${row.class}`,
        percent: div(row.count, this.totalCount),
      }));
    },
    rciClassSummary() {
      return {
        class: 'TOTAL',
        range: ' ',
        count: this.totalCount,
        percent: this.totalCount ? 1 : 0,
      };
    },
    rciComparisonTable() {
      const lowClassCount = this.rciClassData.filter(r => r.class < 2).reduce((a, b) => a + b.count, 0);
      const highClassCount = this.rciClassData.filter(r => r.class > 3).reduce((a, b) => a + b.count, 0);
      const proportion = div(highClassCount, lowClassCount);
      return [{ lowClassCount, highClassCount, proportion }];
    },
    pieChartSeries() {
      return this.chartData.map(row => row.count);
    },
    pieChartOptions() {
      const percentage = val => formatPercent(2)(div(val, this.totalCount));
      return {
        title: {
          text: 'RCI Class Distribution',
          align: 'center',
        },
        chart: {
          id: 'pie-rci-class-distribution',
        },
        labels: this.chartData.map(r => r.class),
        legend: {
          formatter(seriesName, opts) {
            return [`Class ${seriesName}`, `  (${percentage(opts.w.globals.series[opts.seriesIndex])})`];
          },
        },
      };
    },
    barChartSeries() {
      return [{
        name: 'Percentage',
        data: this.chartData.map(row => div(row.count, this.totalCount)),
      }];
    },
    barChartOptions() {
      return {
        title: {
          text: 'RCI Class Distribution',
          align: 'center',
        },
        chart: {
          id: 'bar-rci-class-distribution',
        },
        labels: this.chartData.map(r => `Class ${r.class}`),
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          distributed: true,
          formatter: formatPercent(1),
          style: {
            colors: ['rgba(0,0,0,.5)'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
          },
        },
        yaxis: {
          labels: {
            formatter: formatPercent(0),
          },
        },
        tooltip: {
          y: {
            formatter: formatPercent(2),
          },
        },
      };
    },
  },
  activated() {
    this.chartData = this.rciClassData;
  },
};
</script>

<style lang="stylus" scoped>
</style>
