<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <radar-modal v-model="isSearchDisplayed">
      <div slot="modal-definition">Search</div>
      <xml-search
        :search-filter="searchParams"
        :show="isSearchDisplayed"
        @searched="onSearch"
      />
    </radar-modal>
    <portal to="app-header">
      <div class="page-title">
        XML
      </div>
    </portal>
    <div class="xml-dashboard">
      <div class="xml-overview shadow-1">
        <div class="xml-top">
          <div class="r-heading-3 col-grow">Overview</div>
          <div class="flex xml-totals">
            <q-btn
              class="q-ml-md no-shadow"
              color="mq-sand-dark"
              text-color="mq-dark"
              icon="list_alt"
              label="XML Summary"
              :disabled="!canUserViewXmlSubmission"
              @click="showSummary()"
            />
            <q-btn
              class="q-ml-md no-shadow"
              color="mq-sand-dark"
              text-color="mq-dark"
              icon="fa fa-download"
              label="Download XML"
              :disabled="!canUserViewXmlSubmission"
              @click="downloadXml()"
            />
            <q-btn
              class="q-ml-md no-shadow"
              color="mq-sand-dark"
              text-color="mq-dark"
              icon="fa fa-download"
              label="Download Schema Errors"
              :disabled="!canUserViewXmlSubmission"
              @click="downloadCsv()"
            />
            <q-btn
              class="q-ml-md no-shadow"
              color="mq-sand-dark"
              text-color="mq-dark"
              icon="mdi-xml"
              label="Generate New XML"
              :disabled="!canUserViewXmlSubmission || xmlJobStatus.processing"
              @click="runXmlJob()"
            />
          </div>
        </div>
        <div class="xml-panels">
          <xml-stats
            v-for="(stats, index) in statDetails"
            :key="index"
            class="xml-item"
            :stats="stats"
            @click="onSearch"
          />
          <div v-if="xmlJobStatus.processing" class="xml-notification">
            <span v-if="progress.xmlJob" class="loading">
              Checking status of XML generation job
            </span>
            <span v-else>
              <strong>{{ lastCheckedStatus }}:</strong>
              RADAR is generating new XML data. This process may take some time to complete.
              <a href="javascript:void(0)" @click="checkXmlJobStatus()">Click here to refresh status check.</a>
            </span>
          </div>
        </div>
      </div>
      <div class="xml-bar shadow-1">
        <div>
          <span>{{ tableTitle }}</span>
        </div>
        <q-btn
          class="search-bar no-shadow"
          icon="search"
          align="left"
          no-ripple
          no-caps
          @click="isSearchDisplayed = true"
        >
          Search
          {{ hasActiveFilters()? ' - Filters present': '' }}
        </q-btn>
      </div>
    </div>
    <xml-list
      class="q-px-lg"
      @fetchPage="fetchPage"
    />
    <radar-modal v-model="isSummaryOpen">
      <div slot="modal-definition">XML Summary</div>
      <q-table
        class="xml-summary-table"
        :data="xmlSummary"
        :columns="summaryCols"
        row-key="id"
        :rows-per-page-options="[0]"
        :pagination.sync="pagination"
        :visible-columns="['type', 'sub_type', 'count']"
        separator="cell"
        hide-bottom
      />
    </radar-modal>
    <radar-loading :visible="progress.searching || progress.processing" />
  </q-page>
</template>

<script>
import _ from 'lodash';
import FileSaver from 'file-saver';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatInt } from '@/utils/formatter';
import { CHECK_TYPE_NAMES } from '@/models/xml/xml-schema-validation-types';
import XmlSchemaSearchParam from '@/models/xml/xml-schema-search-param';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import RadarModal from '@/components/common/RadarModal.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import XmlSearch from './XmlSearch.vue';
import XmlStats from './XmlStats.vue';
import XmlList from './XmlList.vue';

const OMITTED_PROPERTIES = ['page', 'eraId'];
const DEFAULT_SEARCH_PARAMS = _.omit(new XmlSchemaSearchParam(), OMITTED_PROPERTIES);

export default {
  name: 'XmlOverview',
  components: {
    RadarModal,
    XmlSearch,
    XmlStats,
    XmlList,
    RadarLoading,
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      searchParams: new XmlSchemaSearchParam(),
      isSearchDisplayed: false,
      isSummaryOpen: false,
      summaryCols: [
        { name: 'id', label: 'Id', field: 'id', align: 'center' },
        { name: 'type', label: 'Type', field: 'type', align: 'center' },
        { name: 'sub_type', label: 'Sub-type', field: 'sub_type', align: 'center' },
        { name: 'count', label: 'Count', field: 'count', format: formatInt },
      ],
      pagination: {
        page: 0,
        rowsPerPage: 0,
      },
    };
  },
  computed: {
    ...mapState('xml', [
      'xmlSchemaValidations', 'statDetails', 'csv', 'searchPagination',
      'xmlJobStatus', 'lastCheckedStatus', 'xmlSummary', 'progress',
    ]),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('xml', ['exportCsvUrl', 'exportXmlUrl']),
    tableTitle() {
      const filtered = this.hasActiveFilters() ? 'Filtered' : '';
      const checkType = this.searchParams.checkType ? `${CHECK_TYPE_NAMES[this.searchParams.checkType]} -` : '';
      return `${filtered} ${checkType} (${this.searchPagination.totalResults})`;
    },
  },
  methods: {
    ...mapActions('xml', ['searchXmlSchemaValidation', 'getStatistics', 'getSummary', 'exportCsv', 'getJobStatus', 'runXmlJob']),
    hasActiveFilters() {
      return !_.isEqual(DEFAULT_SEARCH_PARAMS, _.omit(this.searchParams, OMITTED_PROPERTIES));
    },
    fetchPage(page) {
      const query = { ...this.searchParams, page };
      this.$router.push({ query });
    },
    onSearch(query) {
      this.isSearchDisplayed = false;
      this.$router.push({ query });
    },
    async downloadCsv() {
      const url = this.exportCsvUrl;
      try {
        FileSaver.saveAs(url);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async downloadXml() {
      const url = this.exportXmlUrl;
      try {
        FileSaver.saveAs(url);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    searchByQueryParams() {
      const { query } = this.$route;
      this.executeSearch(query);
    },
    async executeSearch(searchParameters = {}) {
      this.searchParams = XmlSchemaSearchParam.create(searchParameters);
      try {
        await this.searchXmlSchemaValidation(this.searchParams);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async showSummary() {
      await this.getSummary();
      this.isSummaryOpen = true;
    },
    checkJobStatus() {
      if (!this.xmlJobStatus.processing && this.xmlJobStatus.completed_at) {
        if (this.xmlJobStatus.error) {
          this.$notify.failure(`Please contact the administrators. XML generation failed. ${this.xmlJobStatus.error_reason}`);
        } else {
          this.$notify.success('XML generation successfully completed.');
          this.loadXmlData();
        }
      }
    },
    async checkXmlJobStatus() {
      try {
        await this.getJobStatus();
        this.checkJobStatus();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async runGenerateXmlJob() {
      try {
        await this.runXmlJob();
        this.checkJobStatus();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async loadXmlData() {
      await Promise.all([
        this.getStatistics(),
        this.searchByQueryParams(),
        this.getJobStatus(),
      ]);

      if (!this.xmlJobStatus.processing && this.xmlJobStatus.completed_at) {
        if (this.xmlJobStatus.error) {
          this.$notify.failure(`Please contact the administrators. XML generation failed. ${this.xmlJobStatus.error_reason}`);
        }
      }
    },
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height, overflowY: 'hidden' };
    },
  },
  watch: {
    $route() {
      this.searchByQueryParams();
    },
  },
  created() {
    this.loadXmlData();
  },
};
</script>

<style lang="stylus" scoped>
.search-bar
  width 15rem

.pagination-total
  padding 0 15px
  height 48px
  background-color #fff
  color $primary

.xml-notification
  @extend $status-text
  margin 10px 6px 0
  padding 5px
  width 100%
  background-color $yellow-2
  color rgba(0, 0, 0, .55)
  border 1px solid rgba(0, 0, 0, .2)
  border-radius 4px

@keyframes ellipsis
  to
    width 1.25em

.loading:after
  overflow hidden
  display inline-block
  vertical-align bottom
  animation ellipsis steps(4, end) 900ms infinite
  content: "\2026"; /* ascii code for the ellipsis character */
  width 0

.xml-summary-table
  >>> th
    @extend $table-header-text
    background-color $mq-sand-dark
</style>
