import _ from 'lodash';
import api from '@/api';
import { getNonEmptyValues } from '@/utils/search';
import ResearchOutputForBulkCode from '@/models/bulk-code/research-output-for-bulk-code';

export default {
  namespaced: true,
  state: {
    researchOutputsForBulkCode: [],
    codedResearchOutputs: [],
    totalResearchOutputsForBulkCode: undefined,
    researchOutputCount: undefined,
    progress: {
      searching: false,
      saving: false,
    },
  },
  mutations: {
    UPDATE_RESEARCH_OUTPUTS(state, researchOutputs) {
      state.researchOutputsForBulkCode = researchOutputs;
      state.totalResearchOutputsForBulkCode = researchOutputs.length;
      state.codedResearchOutputs = [];
    },
    UPDATE_RESEARCH_OUTPUTS_COUNT(state, count) {
      state.researchOutputCount = count;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
    RESET(state) {
      state.researchOutputsForBulkCode = [];
      state.codedResearchOutputs = [];
      _.keys(state.progress).forEach((key) => {
        state.progress[key] = false;
      });
    },
    ADD_TO_COMPLETED(state, researchOutputsForBulk) {
      state.codedResearchOutputs = state.codedResearchOutputs.concat(researchOutputsForBulk);
    },
  },
  actions: {
    async searchResearchOutputs({ commit }, searchParameters) {
      const params = getNonEmptyValues({
        one_id: searchParameters.oneId,
        era_id: searchParameters.eraId,
        department_name: searchParameters.departmentName,
        research_output_type: searchParameters.researchOutputType,
        journal_forc_id: searchParameters.journalForcId,
        pub_year: searchParameters.pubYear,
        exclude_coded_by: searchParameters.excludeCodedBy,
      });

      commit('UPDATE_PROGRESS', { searching: true });
      try {
        const response = await api.researchOutputs.searchBulk(params);
        const researchOutputs = ResearchOutputForBulkCode.fromList(response.data.results);

        commit('UPDATE_PROGRESS', { searching: false });
        commit('UPDATE_RESEARCH_OUTPUTS_COUNT', response.data.count);
        commit('UPDATE_RESEARCH_OUTPUTS', researchOutputs);
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },

    resetBulk({ commit }) {
      commit('RESET', []);
    },

    addToCoded({ commit }, researchOutputsForBulk) {
      commit('ADD_TO_COMPLETED', researchOutputsForBulk);
    },

    async submitBulk({ commit, state }, { researchOutputsForBulk, comment }) {
      commit('UPDATE_PROGRESS', { saving: true });
      const data = {
        bulk_claims: researchOutputsForBulk.map(ro => ro.bulkClaimDetails()),
        action: 'submit',
        comment,
      };
      try {
        await api.researchOutputs.saveBulkClaims(data);
      } catch (error) {
        const researchOutputErrors = error.response.data.errors.research_outputs;
        _.entries(researchOutputErrors)
          .forEach(([researchOutputIdAsString, errorMessage]) => {
            const matchingResearchOutput = state.researchOutputsForBulkCode.find(
              // eslint-disable-next-line eqeqeq
              researchOutput => researchOutput.id == researchOutputIdAsString,
            );
            matchingResearchOutput.addError(errorMessage);
          });

        const errorInfo = { count: _.keys(researchOutputErrors).length };
        throw errorInfo;
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
  },
  getters: {
    pending(state) {
      return _.difference(
        state.researchOutputsForBulkCode,
        state.codedResearchOutputs,
      );
    },
    hasChanges(state, getters) {
      return getters.pending.some(ro => ro.hasClaims());
    },
    searchLimitReached(state) {
      return state.researchOutputCount > state.totalResearchOutputsForBulkCode;
    },
  },
};
