<template>
  <form novalidate class="full-width ro-claims ro-claim-editor" @submit.prevent="saveClaim()">
    <div class="row items-baseline apportion-row justify-between r-p-side">
      <div class="col-auto">
        <div class="block fit">
          <div class="event-icon-wrapper flex flex-center" :class="event.event_type.toLowerCase()">
            <q-icon :name="event.attributes( ).icon" class="event-icon self-center" />
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="capitalize">
          {{ event.attributes().userFriendlyName }}
        </div>
      </div>
    </div>
    <div class="full-width r-p-all">
      <div class="full-width user-and-time">
        <span v-if="event.actingUserDisplayName()">
          {{ event.actingUserDisplayName() }} at
        </span>
        {{ formatDateTime(event.updated_at) }}
      </div>

      <div class="full-width r-p-top">
        <div class="term-title">
          Claims involved
        </div>
        <div class="term-definition">
          <div class="row">
            <div v-for="claim in claims" :key="claim.id" class="col-xl-4 col-lg-6 q-pr-sm">
              <span class="forc q-pr-md">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
              <span class="apportion">{{ claim.apportion }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { formatDateTime } from '@/utils/date';
import ResearchOutputClaimEvent from '@/models/research-output/research-output-claim-event';

export default {
  name: 'DisputeEvent',
  props: {
    event: {
      type: ResearchOutputClaimEvent,
      required: true,
    },
  },
  computed: {
    claims() {
      return this.event.claims();
    },
  },
  methods: {
    formatDateTime,
  },
};
</script>
<style lang="stylus" scoped>
  .apportion-row
    background-color $mq-sand
    /*padding-bottom 27.5px*/
    padding-top 20.5px
    padding-bottom 20.5px

  .comment-row
    padding-top $radar-general-padding

  .forc-selector
    min-width 140px
    font-size 16px

  .apportion
    min-width 83px
    font-size 16px

  .event-icon-wrapper
    height 58px
    width 58px
    border 1px solid
    border-radius 50%
    background-color $mq-soft

  .event-icon
    font-size 24px
    color white

  .partial_resolution, .complete_resolution
    .event-icon
      border 1px solid
      padding 6px
      border-radius 50%
      background white
      color $mq-soft

  .partial_resolution
    .event-icon
      background: linear-gradient(90deg, $mq-grey-dark 50%, white, 50%) !important

</style>
