<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="term-definition text-secondary">Count</div>
        <div class="row">
          <div class="col-6">
            <div
              :class="comparison.before_class_distribution.isAboveCountThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.before_class_distribution.count }}
            </div>
            <div class="body-4 text-mq-soft">Before</div>
          </div>
          <div class="col-6">
            <div
              :class="comparison.after_class_distribution.isAboveCountThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.after_class_distribution.count }}
            </div>
            <div class="body-4 text-mq-soft">After</div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="term-definition text-secondary"> RCI</div>
        <div class="row">
          <div class="col">
            <div class="body-2"> {{ comparison.before_class_distribution.rci }}</div>
            <div class="body-4 text-mq-soft"> Before</div>
          </div>
          <div class="col">
            <div class="body-2"> {{ comparison.after_class_distribution.rci }}</div>
            <div class="body-4 text-mq-soft"> After</div>
          </div>
          <div class="col">
            <div
              :class="{
                'text-mq-positive': comparison.rciDifference()> 0,
                'text-negative': comparison.rciDifference()< 0}"
              class="body-2"
            >
              {{ comparison.rciDifference() }}
            </div>
            <div class="body-4 text-mq-soft"> Change</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ForcCitationPerformanceComparison
  from '@/models/analytics/forc-citation-performance-comparison';

export default {
  name: 'ForcCitationCountComparision',
  props: {
    comparison: {
      type: ForcCitationPerformanceComparison,
      required: true,
    },
  },
};
</script>
