<template>
  <div class="items-baseline justify-between">
    <div v-if="!isUnderEdit" class="row items-center" @click="!disable && $emit('edit')">
      <claim-viewer
        :claim="claim"
        class="col-10"
        :allow-peer-review-tag="claim.isPeerReviewEligible()"
      />
      <div v-if="!disable" class="col-2 text-right">
        <q-btn
          dense
          color="primary"
          size="md"
          icon="close"
          @click.native.stop="$emit('delete')"
        />
      </div>
    </div>
    <form
      v-if="isUnderEdit"
      novalidate
      class="row items-baseline justify-between"
      @submit.prevent="update"
    >
      <claim-editor
        ref="claimEditor"
        v-model="claim"
        class="col-10"
        :is-multi-disciplinary="!researchOutputForBulk.isForcRestrictedByArc()"
        :journal-forcs="researchOutputForBulk.getJournalForcs()"
        :forcs="forcs"
        :user-forcs="userForcs"
        :clawback-bypassed-forcs="clawbackBypassedForcs"
      />
      <div class="col-2 text-right">
        <q-btn
          dense
          color="primary"
          size="md"
          type="submit"
          label="Ok"
        />
      </div>
    </form>
  </div>
</template>

<script>
import ResearchOutputForBulkCode from '@/models/bulk-code/research-output-for-bulk-code';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';
import ClaimEditor from './ClaimEditor.vue';
import ClaimViewer from './ClaimViewer.vue';

export default {
  name: 'PopupEditableClaim',
  components: { ClaimEditor, ClaimViewer },
  props: {
    researchOutputForBulk: {
      type: [ResearchOutputForBulkCode, ResearchOutputForWhatIf],
      required: true,
    },
    underEdit: {
      type: Boolean,
      required: true,
    },
    forcs: {
      type: Array,
      required: true,
    },
    userForcs: {
      type: Array,
      required: true,
    },
    claim: {
      type: ResearchOutputClaim,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    clawbackBypassedForcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
    };
  },
  computed: {
    isUnderEdit() {
      return !this.disable && this.underEdit;
    },
  },
  methods: {
    async update() {
      const successful = await this.$refs.claimEditor.accept();
      if (successful) this.$emit('removeEdit');
    },
  },
};
</script>
