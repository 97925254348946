<template>
  <q-modal :value="value" content-classes="modal-content-wrapper" @input="toggle">
    <q-btn
      v-close-overlay
      color="mq-soft"
      round
      icon="close"
      flat
      dense
      class="absolute-top-right"
    />
    <div>
      <div v-if="hasModalDefinitionSlot" class="r-heading-1-dense text-primary r-p-vertical">
        <slot name="modal-definition" />
      </div>
      <div v-if="hasTitleSlot" class="page-title-dense r-heading-1-dense r-p-vertical">
        <slot name="title" />
      </div>
      <slot name="default" />
    </div>
  </q-modal>
</template>

<script>
export default {
  name: 'RadarModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasModalDefinitionSlot() {
      return !!this.$slots['modal-definition'];
    },
  },
  methods: {
    toggle(to = false) {
      this.$emit('input', to);
    },
  },
};
</script>
