<template>
  <q-modal v-model="isOpen" content-classes="modal-content-wrapper">
    <q-btn
      v-close-overlay
      color="mq-soft"
      round
      icon="close"
      flat
      dense
      class="absolute-top-right"
    />
    <div>
      <div class="r-heading-1-dense text-primary r-p-vertical">
        XML Snippet
      </div>
      <div class="page-title-dense r-heading-1-dense r-p-vertical">
        {{ checkType }}: Line number {{ lineNumber }}
      </div>
    </div>
    <div class="col-xs-12 full-height">
      <q-field>
        <q-input
          v-model.trim="xmlSnippet"
          name="xmlSnippet"
          type="textarea"
          hide-underline
          class="border border-black comfortable-textarea"
          size="xl"
          rows="25"
          readonly
        />
      </q-field>
    </div>
  </q-modal>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'XmlSnippetModal',
  props: {
    checkType: String,
    lineNumber: Number,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState('xml', ['xmlSnippet']),
  },
  methods: {
    toggle(to) {
      this.$emit('input', to);
    },
    open() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
  },
};
</script>
