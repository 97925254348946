<template>
  <span class="flex items-center">
    <q-icon name="fiber_manual_record" :color="indicatorColorForEra(workingEra)" />
    <q-select
      class="inline on-right"
      color="mq-highlight"
      :display-value="eraDisplayLabel(workingEra)"
      :value="workingEra"
      :options="eraSelectOptions"
      hide-underline
      @change="selectEra"
    />
  </span>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ERA_SUBMISSION_STATUS_COLOR_INDICATORS } from '@/models/era/era';

export default {
  name: 'EraSelector',
  computed: {
    ...mapState('eras', {
      eras: state => state.eras,
      workingEra: state => state.workingEra,
    }),
    ...mapGetters('eras', ['activeEra']),
    eraSelectOptions() {
      return this.eras.map(era => ({
        label: era.name,
        stamp: era.statusAsString(),
        value: era,
      }));
    },
  },
  methods: {
    ...mapActions('eras', ['fetchEraAndUpdateWorkingEra', 'selectWorkingEraById']),
    async selectEra(era) {
      await this.selectWorkingEraById(era.id);
      if (this.$route.params.eraId) {
        this.updateRouteToReflectSelectedEra(era);
      }
    },
    updateRouteToReflectSelectedEra(era) {
      const location = { name: this.$route.name, params: { eraId: era.id } };
      this.$router.push(location);
    },
    eraDisplayLabel(era) {
      if (era.isEmpty()) return '';
      return `${era.name} : ${era.stageAsString()}`;
    },
    initializeEra(eraId) {
      this.fetchEraAndUpdateWorkingEra(eraId);
    },
    indicatorColorForEra(era) {
      return ERA_SUBMISSION_STATUS_COLOR_INDICATORS[era.submission_status];
    },
  },
  watch: {
    $route(newRoute) {
      const { eraId } = newRoute.params;
      if (!eraId || Number(eraId) === this.workingEra.id) return;

      this.initializeEra(_.toInteger(eraId));
    },
  },
};
</script>

<style scoped lang="stylus">
</style>
