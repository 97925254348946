<template>
  <div v-if="filteredEvents.length">
    <div class="radar-info">Activity</div>
    <q-timeline color="mq-soft">
      <q-timeline-entry
        v-for="(whatIfEvent, index) in filteredEvents"
        :key="'whatIfEvent-'+whatIfEvent.id +'-'+ index"
        :title="getTitle(whatIfEvent)"
        side="left"
      >
        <div slot="title" class="body-4 mq-soft q-py-sm">
          {{ getTitle(whatIfEvent) }}
        </div>
        <div class="body-2 text-mq-black">
          {{ whatIfEvent.comment }}
        </div>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { formatDate } from '@/utils/date';

export default {
  name: 'WhatIfScenarioTimeLine',
  props: {
    whatIfEvents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('auth', ['loggedInUser']),
    filteredEvents() {
      return this.whatIfEvents.filter(whatIfEvent => !whatIfEvent.isCreated());
    },
  },
  methods: {
    getUpdatedDate(whatIfEvent) {
      return formatDate(whatIfEvent.updated_at);
    },
    getStatusIcon(whatIfEvent) {
      if (whatIfEvent.isCreated()) return 'far fa-edit';
      if (whatIfEvent.isSubmitted()) return 'far fa-clock';
      if (whatIfEvent.isAccepted()) return 'far fa-thumbs-up';
      if (whatIfEvent.isApplied()) return 'far fa-check-circle';
      if (whatIfEvent.isRejected()) return 'far fa-thumbs-down';
      return whatIfEvent.state;
    },
    capitalize(eventType) {
      return _.capitalize(eventType);
    },
    getTitle(whatIfEvent) {
      return `${whatIfEvent.updated_by.displayName()} ${whatIfEvent.event_type
      } this What if on the ${this.getUpdatedDate(whatIfEvent)} on behalf of ${
        whatIfEvent.updated_by_faculty.name}`;
    },
  },

};
</script>

<style lang="stylus">
.q-timeline-title
  font-size: 12px;
  text-align: left;
</style>
