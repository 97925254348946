<template>
  <div class="additional-info">
    <div>
      <span class="info-label">Faculty:</span>
      <span>{{ funding.faculty }}</span>
    </div>
    <div>
      <span class="info-label">Department:</span>
      <span>{{ funding.department }}</span>
    </div>
    <div v-if="funding.is_commercial_income">
      <span class="info-label">Finance ID:</span>
      <span>{{ funding.finance_id }}</span>
    </div>
    <div v-else>
      <span class="info-label">Major account:</span>
      <span>{{ funding.major_account }}</span>
    </div>
  </div>
</template>
<script>
import Funding from '@/models/research-income/funding';

export default {
  name: 'ResearchIncomeMetadataCell',
  props: {
    funding: {
      type: Funding,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.info-label
  min-width 120px
</style>
