<template>
  <div class="relative-position">
    <div class="row shadow-1 full-width">
      <div class="col q-pr-none border-right border-mq-grey-dark">
        <div class="row r-p-side">
          <div class="radar-sublabel group items-baseline col-12 text-right">
            <span>{{ researchOutput.research_output_type }}</span>
            <span>{{ researchOutput.pub_year }}</span>
            <span>
              <a
                v-for="(pdfUrl,index) in researchOutput.electronic_locations"
                :key="index"
                :href="pdfUrl"
                target="_blank"
                class="icon-link items-baseline"
              >
                <q-icon color="mq-soft" name="description" />
              </a>
              <span v-if="!researchOutput.electronic_locations.length">No pdf</span>
            </span>
            <q-btn
              flat
              dense
              class="icon-link no-padding"
              icon="notes"
              @click.native="$refs.abstract.open()"
            />
          </div>
        </div>
        <div class="headline-title row r-p-side">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="col-12" v-html="title" />
        </div>
        <div
          class="r-p-top r-collapsible-container plus-minus-collapsible-container collapsible-theme-mq-soft"
        >
          <q-collapsible label="Status" opened>
            <div>
              <div class="q-mb-lg">
                <div class="term-title-small all-caps">
                  Eligibility
                </div>
                <div class="term-definition q-mt-xs text-mq-purple">
                  {{ researchOutput.ro_status.displayEligibilityText() }}
                </div>
              </div>
              <!-- Display REASON when eligibility is "Forced" and there is a comment  -->
              <div
                v-if="!researchOutput.ro_status.isAutomatic() && researchOutput.ro_status.comment"
                class="q-mb-lg"
              >
                <div class="term-title-small all-caps">
                  Reason
                </div>
                <div class="term-definition q-mt-sm">
                  {{ researchOutput.ro_status.comment }}
                </div>
              </div>
            </div>
            <template v-if="!researchOutput.isMemberOfPortfolio && researchOutput.ro_status.isEligible()">
              <div class="row">
                <div class="col-auto r-p-right">
                  <div class="term-title-small">
                    CLAIMS
                  </div>
                  <div class="term-definition claim-status">
                    {{ statusText }}
                    <q-icon :name="statusIcon" />
                  </div>
                </div>
                <div v-if="dispute.isEscalated()" class="col-auto dispute-status">
                  <div class="term-title-small">
                    DISPUTE
                  </div>
                  <div class="term-definition dispute-status">
                    {{ dispute.disputeStateName() }}
                  </div>
                </div>
              </div>
              <div>
                <div class="term-title-small">
                  FORCS
                </div>
                <div class="term-definition q-pt-lg">
                  <span v-if="!hasClaims">No claims yet.</span>
                  <div v-else class="row gutter">
                    <div v-for="claim in activeClaims" :key="claim.id" class="col-xs-6 col-md-4">
                      <div class="row">
                        <span class="col forc text-right q-pr-sm">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
                        <span class="col apportion">{{ claim.apportion }}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="researchOutput.isMemberOfPortfolio">
              <div class="row">
                <div class="col-auto r-p-right">
                  <div class="term-title-small">
                    PORTFOLIO STATUS
                  </div>
                  <div class="term-definition claim-status">
                    {{ portfolioStatusText }}
                  </div>
                </div>
              </div>
              <div>
                <div class="term-title-small">
                  PORTFOLIO CLAIMS
                </div>
                <div class="term-definition q-pt-lg">
                  <span v-if="!hasPortfolioClaims">No claims yet.</span>
                  <div v-else class="row gutter">
                    <div v-for="portfolioClaim in portfolioClaims" :key="portfolioClaim.id" class="col-xs-6 col-md-4">
                      <div class="row">
                        <span class="col forc text-right q-pr-sm">
                          {{ portfolioClaim.forc.text }}
                          <q-tooltip>{{ portfolioClaim.forc.description }}</q-tooltip>
                        </span>
                        <span class="col apportion">{{ portfolioClaim.apportion }}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </q-collapsible>
          <q-collapsible label="About This Research Output" opened>
            <div class="term-title-small">
              AUTHORS
            </div>
            <div class="term-definition">
              <div v-for="author in authors" :key="'author-'+author.order" class="inline-block">
                <router-link
                  v-if="author.isInternal()"
                  class="text-no-wrap author-name q-mr-sm"
                  :to="{ name: 'researcher-details', params: { researcherId: author.id }}"
                  target="_blank"
                >
                  {{ author.researcher_name }}&semi;
                  <q-tooltip v-if="author.canShowOrganisation()">
                    {{ author.organisation_name }}
                  </q-tooltip>
                </router-link>
                <span v-else class="text-no-wrap author-name q-mr-sm">
                  {{ author.researcher_name }}&semi;
                </span>
              </div>
            </div>
            <div class="term-title-small">
              PURE ID
            </div>
            <div class="term-definition">
              <component
                :is="researchOutput.pure_link ? 'a' : 'span'"
                :href="researchOutput.pure_link"
                target="_blank"
              >
                {{ researchOutput.pure_id }}
              </component>
            </div>
            <template v-if="canUserViewCitationInRO">
              <div class="term-title-small">
                CITATIONS
              </div>
              <div class="term-definition">
                <div>Scopus: {{ researchOutput.citations_data_for_all_sources.scopus }}</div>
                <div>WOS: {{ researchOutput.citations_data_for_all_sources.wos }}</div>
              </div>
            </template>
            <div class="term-title-small">
              MANAGING ORG
            </div>
            <div class="term-definition" v-text="researchOutput.managing_organisation_name" />
            <template v-if="researchOutput.parent_doc">
              <div class="term-title-small">
                PARENT DOC
              </div>
              <div class="term-definition" v-text="researchOutput.parent_doc" />
            </template>
            <template v-if="researchOutput.journal_issn">
              <div class="term-title-small">
                ISSN
              </div>
              <div class="term-definition" v-text="researchOutput.journal_issn" />
            </template>
            <template v-if="researchOutput.publisher_name">
              <div class="term-title-small">
                PUBLISHER
              </div>
              <div class="term-definition" v-text="researchOutput.publisher_name" />
            </template>
            <template v-if="researchOutput.isJournalArticle()">
              <div class="term-title-small">
                ARC Journal List FoRCs ({{ workingEraForcReference.year }} ANZSRC)
              </div>
              <div>
                <span v-if="researchOutput.isMultiDisciplinary()">MD</span>
                <span v-for="journalForc in journalForcs" v-else :key="journalForc.id" class="col text-right q-pr-sm">
                  {{ journalForc.text }}<q-tooltip>{{ journalForc.description }}</q-tooltip>
                </span>
              </div>
              <template v-if="togglePreviousEraJournalForcs">
                <div class="term-title-small">
                  ARC Journal List FoRCs ({{ previousEraForcReference.year }} ANZSRC)
                </div>
                <div>
                  <span v-if="researchOutput.isMultiDisciplinary()">MD</span>
                  <span v-for="journalForc in previousJournalForcs" v-else :key="journalForc.id" class="col text-right q-pr-sm">
                    {{ journalForc.text }}<q-tooltip>{{ journalForc.description }}</q-tooltip>
                  </span>
                </div>
              </template>
            </template>
            <template v-if="researchOutput.getAbdcRanking()">
              <div class="term-title-small">
                ABDC
              </div>
              <div class="term-definition">
                {{ researchOutput.getAbdcRanking() }}
              </div>
            </template>
          </q-collapsible>
          <q-collapsible
            v-if="hasPreviousEraClaims"
            label="Submitted codes from Previous ERA"
            opened
          >
            <div>
              <div class="term-title-small">
                PREVIOUS FORCS
              </div>
              <div class="term-definition q-pt-lg">
                <div class="row gutter">
                  <div
                    v-for="previous_claim in researchOutput.getPreviousEraClaims()"
                    :key="previous_claim.id"
                    class="col-xs-12 col-md-8"
                  >
                    <div class="row">
                      <span class="col forc text-right q-pr-sm">
                        {{ previous_claim.forc.text }}
                        <q-tooltip>{{ previous_claim.forc.description }}</q-tooltip>
                      </span>
                      <span class="col apportion">{{ previous_claim.apportion }}%</span>
                      <span>
                        <q-checkbox v-model="previous_claim.is_peer_reviewed" disable>
                          Peer review
                        </q-checkbox>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-collapsible>
          <q-collapsible label="Interests" opened>
            <research-output-interests
              title="BY AUTHOR"
              :interests="researchOutput.getAuthorInterests()"
            />
            <research-output-interests
              title="BY DEPARTMENT"
              :interests="researchOutput.getOrgUnitInterests()"
            />
            <research-output-interests
              title="BY ARC JOURNAL"
              :interests="researchOutput.getJournalInterests()"
            />
          </q-collapsible>
        </div>
      </div>
      <div class="col-xs-12 col-xl-7 bg-white r-p-side">
        <research-output-work-area />
      </div>
      <research-output-abstract-modal
        ref="abstract"
        :title="title"
        :abstract="researchOutput.abstract"
      />
    </div>
    <radar-loading :visible="progress.fetchingResearchOutput" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import ResearchOutputStatus from '@/models/research-output/research-output-status';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ResearchOutputAbstractModal from '../ResearchOutputAbstractModal.vue';
import ResearchOutputInterests from './ResearchOutputInterests.vue';
import ResearchOutputWorkArea from './ResearchOutputWorkArea.vue';

export default {
  name: 'ResearchOutputDetailed',
  components: {
    RadarLoading,
    ResearchOutputAbstractModal,
    ResearchOutputInterests,
    ResearchOutputWorkArea,
  },
  data() {
    return {
      isAbstractDisplayed: false,
    };
  },
  computed: {
    ...mapState('researchOutputs', ['progress']),
    ...mapState('researchOutputClaimEvents', ['claimEvents']),
    ...mapState('eras', ['workingEra']),
    ...mapGetters('researchOutputs', ['researchOutput', 'dispute', 'claims']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'workingEraForcReference', 'previousEraForcReference']),
    ...mapGetters('auth', ['userForcsForRoClaim', 'canUserViewCitationInRO']),
    title() {
      return this.researchOutput.is_translated
        ? this.researchOutput.title_translated
        : this.researchOutput.title_nativescript;
    },
    authors() {
      return _.sortBy(this.researchOutput.uniqueCreators(), 'order');
    },
    statusText() {
      return ResearchOutputStatus.getStatusTextFor(this.researchOutput.ro_status);
    },
    portfolioStatusText() {
      return this.researchOutput.getPortfolioStatus();
    },
    statusIcon() {
      return ResearchOutputStatus.getIconFor(this.researchOutput.ro_status);
    },
    isEscalated() {
      return this.dispute;
    },
    hasClaims() {
      return !_.isEmpty(this.claims);
    },
    activeClaims() {
      return this.claims.filter(c => c.is_active);
    },
    hasPortfolioClaims() {
      return this.researchOutput.hasPortfolioClaims();
    },
    portfolioClaims() {
      return this.researchOutput.getPortfolioClaims();
    },
    hasPreviousEraClaims() {
      return !_.isEmpty(this.researchOutput.getPreviousEraClaims());
    },
    isMultiDisciplinary() {
      return this.researchOutput.isMultiDisciplinary()
        || !this.researchOutput.isJournalArticle();
    },
    journalForcs() {
      return this.isMultiDisciplinary
        ? 'MD'
        : this.researchOutput.getJournalForcs();
    },
    previousJournalForcs() {
      return this.isMultiDisciplinary
        ? 'MD'
        : this.researchOutput.getPreviousJournalForcs();
    },
    togglePreviousEraJournalForcs() {
      // ERA-857: RO Module need to display both ANZSRC 2008 and 2020 ARC journal forcs because the 2020 are not available
      // Admin module can toggle to hide/unhide the 2008 ones.
      // This toggle is disableClawbackLogic and can be used for whenever waiting for the current ERA journal forcs
      return this.researchOutput.disableClawbackLogic();
    },
  },
};
</script>
<style lang="stylus" scoped>
  a:link
    color $mq-purple

  a:visited
    color $mq-purple

  .affiliations
    margin-left 2.2rem

  .author-name
    text-decoration none
    line-height 1.1rem

  .abstract
    text-decoration underline

  .q-collapsible
    .term-definition
      margin-bottom 24px

  .claim-status
    font-size 20px
    line-height 1.3
    color $secondary
    text-transform capitalize

  .dispute-status
    font-size 20px
    font-weight normal
    line-height 1.4
    color $mq-soft

    text-transform capitalize

  .forc
    font-size 16px
    font-weight normal
    font-style normal
    line-height 1.25
    letter-spacing 0.3px
    color $mq-soft-muted

  .apportion
    font-size 14px
    font-weight normal
    font-style normal
    line-height 1.43
    letter-spacing 0.2px
    color black
    word-break keep-all

  .icon-highlight-alert > .q-alert > .q-alert-side > .q-icon
    color $negative

</style>
