import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import ReportingHome from '@/components/reporting/ReportingHome.vue';

const ReportingRoutes = {
  path: 'reports',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'reporting',
      component: ReportingHome,
      meta: {
        activeRoute: 'reports',
      },
    },
  ],
};

export default ReportingRoutes;
