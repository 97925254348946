import { useHttp } from './http';

const http = useHttp('reporting');

export default {
  getDisciplinaryProfile: params => http.get('disciplinary_profile', { params }),
  getFteBenchmarks: params => http.get('fte_benchmarks', { params }),
  getFteProfile: params => http.get('fte_profile', { params }),
  getHerdcCount: params => http.get('herdc_counts', { params }),
  getIncome: params => http.get('income', { params }),
  getPublishingProfile: params => http.get('publishing_profile', { params }),
  getCountryPatents: params => http.get('patents_by_country', { params }),
};
