import _ from 'lodash';
import User from '@/models/user/user';
import { removeEscapeCharacters } from '@/utils/formatter';

class ResearchStatement {
  constructor(researchStatement = {}) {
    const item = researchStatement || {};
    this.id = item.id;
    this.updatedAt = item.updated_at && new Date(item.updated_at);
    this.createdBy = item.created_by && new User(item.created_by);
    this.eraSubmissionId = item.era_submission_id;
    this.researchOutputId = item.research_output_id;
    this.statement = item.statement || '';
    this.isLatest = item.is_latest;
    this.fromPreviousEra = item.research_statement_from_previous_era;
    this.portfolioId = item.portfolio;
    this.event = item.event;
  }

  static fromList(researchStatements = []) {
    return researchStatements
      .map(researchStatement => new ResearchStatement(researchStatement));
  }

  hasStatement() {
    return !(_.isNil(this.statement) || _.isEmpty(this.statement));
  }

  getStatement() {
    return this.statement;
  }

  basicDetails() {
    return {
      statement: this.statement,
    };
  }

  get statementCount() {
    return removeEscapeCharacters(this.statement).length;
  }
}

export default ResearchStatement;
