import User from '@/models/user/user';
import ResearcherClaim from './researcher-claim';

class ResearcherClaimVersion {
  constructor(researcherClaimVersion = {}) {
    this.id = researcherClaimVersion.id;
    this.eligible_researcher_id = researcherClaimVersion.eligible_researcher_id;
    this.is_manual_override = researcherClaimVersion.is_manual_override;
    this.has_claims = researcherClaimVersion.has_claims;
    this.is_latest = researcherClaimVersion.is_latest;
    this.researcher_claims = ResearcherClaim
      .fromList(researcherClaimVersion.researcher_claims);
    this.created_by = researcherClaimVersion.created_by
      && new User(researcherClaimVersion.created_by);
    this.updated_at = researcherClaimVersion.updated_at
      && new Date(researcherClaimVersion.updated_at);
  }

  static fromList(researcherClaimVersions = []) {
    return researcherClaimVersions.map(researcherClaimVersion => new ResearcherClaimVersion(researcherClaimVersion));
  }

  isAutomated() {
    return !this.is_manual_override;
  }

  isManualOverride() {
    return this.is_manual_override;
  }

  isLatest() {
    return this.is_latest;
  }
}

export default ResearcherClaimVersion;
