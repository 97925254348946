<template>
  <form @submit.prevent="confirm()">
    <div class="r-heading-1-dense text-primary r-p-vertical">
      {{ isPropose ? 'Propose': isAccept ? 'Accept': isReject ? 'Reject' : isApply ? 'Apply':'' }} {{ whatIf.title }}
    </div>
    <div class="col text-mq-soft items-end field-group¯s">
      <div class="text-right absolute-bottom r-m-large-right r-m-top">
        <div class="r-m-bottom">
          <q-btn
            type="button"
            color="mq-soft"
            label="Cancel"
            flat
            class="r-m-right"
            @click="cancel()"
          />
          <q-btn type="submit" color="primary" label="Confirm" />
        </div>
      </div>
      <q-field :error="errors.has('what-if-comment')" error-label="Comment is required">
        <q-input
          v-model.trim="whatIf.comment"
          v-validate="'required'"
          type="textarea"
          hide-underline
          class="border border-black comfortable-textarea"
          size="xl"
          rows="15"
          placeholder="Enter Comment"
          name="what-if-comment"
        />
      </q-field>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import WhatIf from '@/models/what-if/what-if';

export default {
  name: 'WhatIfScenarioWorkflowComment',
  props: {
    whatIf: {
      type: WhatIf,
      default: () => new WhatIf(),
    },
    eventType: {
      type: String,
    },
  },
  computed: {
    errors() {
      return this.$validator.errors;
    },
    isPropose() {
      return this.eventType === 'propose';
    },
    isAccept() {
      return this.eventType === 'accept';
    },
    isReject() {
      return this.eventType === 'reject';
    },
    isApply() {
      return this.eventType === 'apply';
    },
  },
  methods: {
    ...mapActions(
      'whatIfs',
      ['submitWhatIf', 'acceptWhatIf', 'rejectWhatIf', 'applyWhatIf'],
    ),
    async confirm() {
      await this.$validator.validateAll();
      if (this.errors.any()) return;

      if (this.isPropose) this.submit();
      if (this.isAccept) this.accept();
      if (this.isReject) this.reject();
      if (this.isApply) this.apply();
    },
    cancel() {
      this.$emit('cancel');
    },
    async submit() {
      try {
        await this.submitWhatIf(this.whatIf);
        this.$emit('submitted', 'proposed');
      } catch (e) {
        this.$emit('error', e);
      }
    },
    async reject() {
      try {
        await this.rejectWhatIf(this.whatIf);
        this.$emit('submitted', 'rejected');
      } catch (e) {
        this.$emit('error', e);
      }
    },
    async accept() {
      try {
        await this.acceptWhatIf(this.whatIf);
        this.$emit('submitted', 'accepted');
      } catch (e) {
        this.$emit('error', e);
      }
    },
    async apply() {
      try {
        await this.applyWhatIf(this.whatIf);
        this.$emit('submitted', 'applied');
      } catch (e) {
        this.$emit('error', e);
      }
    },
  },
};
</script>
