<template>
  <q-layout>
    <q-layout-drawer
      side="left"
      :mini="minimizeComparisons"
      :width="400"
      :mini-width="20"
      :overlay="false"
      behavior="desktop"
      :value="true"
      content-class="border-right border-mq-soft-muted bg-mq-soft-light overflow-visible"
    >
      <q-btn
        :key="minimizeComparisons"
        color="mq-soft-muted"
        round
        size="xs"
        class="drawer-toggle absolute-top-right z-top q-mt-lg"
        :icon="minimizeComparisons? 'keyboard_arrow_right':'keyboard_arrow_left'"
        @click="togglePerformanceComparisionVisibilityMode"
      />
      <what-if-forc-performance-comparisons
        key="what-if-forc-performance-comparisons"
        class="q-mini-drawer-hide"
        @select="selectForc"
      />
    </q-layout-drawer>
    <q-page-container>
      <q-page>
        <what-if-research-output-review @fetchPage="fetchPage" />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { notifyUnAuthorized } from '@/store/unauthorized-interceptor';
import ResearchOutputSearchParam from '@/models/what-if/what-if-research-output-search-param';
import WhatIfForcPerformanceComparisons from '../ForcPerformanceComparisons/WhatIfForcPerformanceComparisons.vue';
import WhatIfResearchOutputReview from './WhatIfResearchOutputReview.vue';

export default {
  name: 'WhatIfScenarioReview',
  components: {
    WhatIfForcPerformanceComparisons,
    WhatIfResearchOutputReview,
  },
  props: {
    whatIfId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSearchDisplayed: false,
      impactedOnly: false,
      minimizeComparisons: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedInUser']),
    ...mapGetters('whatIfScenario', ['canLoggedInUserReviewCurrentWhatIf']),
  },
  methods: {
    ...mapActions('whatIfScenario', ['fetchWhatIf', 'searchResearchOutputs', 'reset']),
    ...mapActions('analytics', ['fetchCitationBenchmarks']),
    togglePerformanceComparisionVisibilityMode() {
      this.minimizeComparisons = !this.minimizeComparisons;
    },
    showSearch() {
      this.isSearchDisplayed = true;
    },
    hasActiveFilters() {
      if (!this.$refs.roSearch) return false;
      return this.$refs.roSearch.hasActiveFilters();
    },
    fetchPage(page) {
      this.search(page);
    },
    search(page, forc) {
      const searchParams = {
        ...new ResearchOutputSearchParam(Number(this.$route.params.eraId)),
        impactedOnly: true,
        claimForcId: forc?.id,
        page,
      };

      this.searchResearchOutputs({ id: this.whatIfId, searchParams });
    },
    selectForc(forc) {
      this.search(1, forc);
    },
    async fetchWhatIfAndValidatePermission() {
      this.reset();
      this.fetchCitationBenchmarks();
      await this.fetchWhatIf(this.whatIfId);

      /* if (!this.canManageWhatIf) {
        notifyUnAuthorized();
        this.$router.replace({ name: 'home' });
      } */
    },
  },
  watch: {
    whatIfId() {
      this.fetchWhatIfAndValidatePermission();
    },
  },
  created() {
    this.fetchWhatIfAndValidatePermission();
  },
};
</script>
<style lang="stylus">
  .modal-search-wrapper
    min-width 741px
    min-height auto
    padding $radar-general-padding $radar-large-padding

  .q-layout-drawer
    overflow visible

  .drawer-toggle
    margin-right -14px
    overflow visible
</style>
