<template>
  <div class="row q-pa-md items-center separator text-primary">{{ title }}</div>
</template>
<script>
export default {
  name: 'SeparatorWithText',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="stylus" scoped>
.separator {
  text-align: center;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $primary;
}

.separator:not(:empty)::before {
  margin-right: 10px;
}

.separator:not(:empty)::after {
  margin-left: 10px;
}
</style>
