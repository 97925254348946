import { getForcsForReporting } from '@/utils/forc-utils';
import Forc from '@/models/era/forc';

const ReportingMixin = {
  computed: {
    sortedUserApplicableForcs() {
      return Forc.sortAsText(this.userApplicableForcs);
    },
    userApplicableForcs() {
      return getForcsForReporting(
        this.userForcsForRoClaim,
      );
    },
  },
};

export default ReportingMixin;
