<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <portal to="app-header">
      <div class="page-title">Research Income</div>
    </portal>
    <q-tabs
      class="head-bg-transparent border-bottom border-secondary"
      style="overflow: visible !important"
      color="secondary"
      inverted
    >
      <q-route-tab
        slot="title"
        name="research-income-code"
        :to="{ name: 'researchIncomeCode' }"
        exact
        label="Code"
      />
      <q-route-tab
        v-if="hasPermission($permissions.MANAGE_RESEARCH_INCOME_FILE)"
        slot="title"
        name="research-income-upload"
        :to="{ name: 'researchIncomeUpload' }"
        exact
        label="Upload"
      />
      <q-route-tab
        v-if="hasPermission($permissions.MANAGE_RESEARCH_INCOME_FILE)"
        slot="title"
        name="research-income-download"
        :to="{ name: 'researchIncomeDownload' }"
        exact
        label="Export"
      />
    </q-tabs>
    <router-view />
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ResearchIncomeOverview',
  computed: {
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('auth', ['hasPermission']),
  },
  methods: {
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height, overflowY: 'hidden' };
    },
  },
  mounted() {
    const childRouteNames = [
      'researchIncomeCode', 'researchIncomeUpload', 'researchIncomeDownload',
    ];
    const matchedNames = this.$route.matched.map(matchedRoute => matchedRoute.name);
    const childRoutesSelected = _.intersection(matchedNames, childRouteNames);
    if (childRoutesSelected.length === 0) {
      this.$router.replace({ name: 'researchIncomeCode' });
    }
  },
};
</script>
