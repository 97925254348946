import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import { MANAGE_WHAT_IF } from '@/models/user/access/permissions';
import WhatIfScenarioEditor
  from '@/components/analytics/scenario/editor/WhatIfScenarioEditor.vue';
import WhatIfScenarioReview
  from '@/components/analytics/scenario/review/WhatIfScenarioReview.vue';

const whatIfRoutes = {
  path: 'what-ifs/:whatIfId',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: 'edit',
      props: true,
      name: 'what-if-edit',
      component: WhatIfScenarioEditor,
      meta: {
        auth: [MANAGE_WHAT_IF],
        activeRoute: 'analytics',
      },
    }, {
      path: 'review',
      props: true,
      name: 'what-if-review',
      component: WhatIfScenarioReview,
      meta: {
        auth: [MANAGE_WHAT_IF],
        activeRoute: 'analytics',
      },
    },
  ],
};

export default whatIfRoutes;
