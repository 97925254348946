import { useHttp } from './http';

const http = useHttp('search_queries');

export default {
  getAll: () => http.get(),
  get: id => http.get(id),
  create: searchQry => http.post('', searchQry),
  update: (id, searchQry) => http.put(id, searchQry),
  delete: id => http.delete(id),
};
