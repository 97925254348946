<template>
  <q-collapsible
    v-model="showReport"
    class="collapsible-report q-pb-lg bg-white shadow-1"
    icon-toggle
    @hide="showTable = showTable || !showGraph"
  >
    <template slot="header">
      <span class="group-title cursor-pointer" @click="showReport = !showReport">
        {{ title }}
      </span>
      <div class="report-buttons" :class="{ invisible: !showButtons }">
        <q-checkbox
          v-model="showTable"
          checked-icon="table_chart"
          unchecked-icon="table_chart"
          class="border round-borders q-pa-sm"
          :class="{'active-button': showTable && showReport }"
        />
        <q-checkbox
          v-model="showGraph"
          checked-icon="pie_chart"
          unchecked-icon="pie_chart"
          class="border round-borders q-pa-sm q-ml-md"
          :class="{'active-button': showGraph && showReport }"
        />
      </div>
      <div class="gap" />
    </template>
    <q-list class="no-border">
      <q-collapsible v-model="showTable" label="test" header-class="hidden">
        <slot name="table" />
      </q-collapsible>
      <q-collapsible v-model="showGraph" header-class="hidden">
        <slot name="graph" />
      </q-collapsible>
    </q-list>
  </q-collapsible>
</template>

<script>
export default {
  name: 'ReportContainer',
  props: {
    title: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showReport: true,
      showTable: true,
      showGraph: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.collapsible-report
  min-width max-content

  /deep/ > .q-collapsible-inner > .q-item
    flex-wrap wrap
    position: sticky;
    top: 0;
    height 45px
    background-color: $grey-4;
    z-index: 2;

.gap
  order 2;
  flex-basis calc(100% + 28px);
  margin 5px -14px 0
  height 20px;
  background-color white;

.group-title
  font-size 18px

.report-buttons
  flex-grow 1
  order 1
  text-align right
  transition opacity .3s linear

  /.q-collapsible-closed &
    opacity 0

.active-button
  background-color $grey-5
  box-shadow inset 0 3px 5px rgba(0, 0, 0, 0.1);
</style>
