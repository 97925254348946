<template>
  <div class="row justify-center relative-position">
    <div class="col-12 col-xl-10">
      <q-table
        class="bg-white"
        :data="users"
        :columns="columns"
        :pagination.sync="pagination"
        :rows-per-page-options="[0]"
        binary-state-sort
        row-key="id"
        no-data-label="No data found"
      >
        <div slot="top" slot-scope="props" class="fit q-pb-md">
          <slot :props="props" name="top" />
        </div>
        <q-tr slot="header" slot-scope="props">
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            :class="col.classes"
            :style="col.style"
            class="user-header-row"
          >
            <template v-if="col.name==='role'">
              <div class="row text-left">
                <div class="col-md-6 col-xs-12 wrappable-table-column">
                  Role <span class="lt-md">/</span>
                </div>
                <div class="col-md-6 col-xs-12 wrappable-table-column">
                  Faculty
                </div>
              </div>
            </template>
            <template v-else>
              {{ col.label }}
            </template>
          </q-th>
        </q-tr>
        <template slot="body" slot-scope="props">
          <q-tr :props="props">
            <q-td
              key="mqid"
              :props="props"
              :style="props.colsMap.mqid.style"
              :class="props.colsMap.mqid.classes"
            >
              <router-link :to="{name: 'user-details', params: {userId: props.row.id}}">
                <span class="wrappable-table-column">
                  {{ props.row.username }}
                </span>
              </router-link>
            </q-td>
            <q-td
              key="name"
              :props="props"
              :style="props.colsMap.name.style"
              :class="props.colsMap.name.classes"
            >
              <div class="wrappable-table-column">
                {{ props.row.displayName() }}
              </div>
            </q-td>
            <q-td
              key="role"
              :props="props"
              :style="props.colsMap.role.style"
              :class="props.colsMap.role.classes"
            >
              <ul class="roles text-left">
                <li
                  v-for="userAccess in props.row.user_accesses"
                  :key="userAccess.id"
                  class="row"
                >
                  <div class="col-md-6 col-xs-12 wrappable-table-column">
                    {{ userAccess.role.name }} <span class="lt-md">&bull;</span>
                  </div>
                  <div class="col-md-6 col-xs-12 wrappable-table-column">
                    {{ userAccess.getEraFacultyName() }}
                  </div>
                </li>
              </ul>
            </q-td>
            <q-td
              key="status"
              :props="props"
              :style="props.colsMap.status.style"
              :class="props.colsMap.status.classes"
            >
              <q-chip v-if="props.row.isActive()" square small color="positive">
                Active
              </q-chip>
              <q-chip v-else square small color="negative">
                Inactive
              </q-chip>
            </q-td>
            <q-td
              key="lastLogin"
              :props="props"
              :style="props.colsMap.lastLogin.style"
              :class="props.colsMap.lastLogin.classes"
            >
              <span v-if="props.row.last_login" square>
                {{ moment(props.row.last_login).fromNow() }}
              </span>
              <span v-else class="text-negative">Never</span>
            </q-td>
          </q-tr>
        </template>
        <div slot="bottom" slot-scope="props" class="fit">
          <slot :props="props" name="bottom" />
        </div>
      </q-table>
      <radar-loading :visible="progress.searching" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { formatDateTime } from '@/utils/date';
import RadarLoading from '@/components/common/RadarLoading.vue';

const commonFieldStyle = 'width: 140px';
const commonSmallFieldStyle = 'width: 80px';

export default {
  name: 'UserList',
  components: { RadarLoading },
  data() {
    return {
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      columns: [{
        name: 'mqid',
        label: 'MQ ID',
        align: 'left',
        field: 'username',
        style: commonFieldStyle,
        sortable: true,
        classes: 'bg-white',
      }, {
        name: 'name',
        label: 'Name',
        align: 'left',
        sortable: true,
        field: user => user.displayName(),
        classes: 'bg-mq-neutral',
      }, {
        name: 'role',
        label: 'Role',
        align: 'left',
        classes: 'bg-mq-neutral',
      }, {
        name: 'status',
        label: 'Status',
        align: 'left',
        field: 'submission_status',
        style: commonSmallFieldStyle,
        classes: 'bg-mq-neutral',
      }, {
        name: 'lastLogin',
        label: '',
        align: 'left',
        sortable: true,
        field: '',
        style: commonFieldStyle,
        classes: 'bg-mq-neutral',
      },
      ],
    };
  },
  computed: {
    ...mapState('users', ['users', 'progress']),
  },
  methods: {
    formatDateTime,
    moment,
    viewDetails(user) {
      this.$router.push({ name: 'user-details', params: { userId: user.id } });
    },
  },
};
</script>

<style scoped lang="stylus">
.roles
  margin-top 0
  margin-bottom 0
  padding-inline-start 0

  li:not(:first-child)
    border-top 1px solid $mq-separator
    margin-top 2px
    padding-top 2px

.user-header-row
  border-bottom: 2px solid rgba(0,0,0,.25)
  border-top: 1px solid rgba(0,0,0,.25);
  color: #222;
  text-transform: uppercase;
</style>
