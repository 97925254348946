import _ from 'lodash';
import { getNonEmptyValues } from '@/utils/search';
import { valueAsArray } from '@/utils/collections-util';
import { CLAIM_OPTIONS_MINE, CLAIM_OPTIONS_OTHERS, CLAIM_OPTIONS_RECENT } from '@/models/research-output/research-output-search-param';

class WhatIfResearchOutputSearchParam {
  constructor(eraId) {
    this.radarId = null;
    this.authorNameContains = '';
    this.eraId = eraId;
    this.excludeActive = false;
    this.researchOutputTypes = [];
    this.title = '';
    this.yearStart = null;
    this.yearEnd = null;
    this.publisherName = '';
    this.parentDoc = '';
    this.withMdJournalForc = false;
    this.journalForcId = null;
    this.interestsForAllMyForcs = false;
    this.interestForcId = null;
    this.roStatus = null;
    this.withNoClaimForcApportions = false;
    this.claimForcIds = [];
    this.facultyOrDepartmentName = null;
    this.withPeerReview = false;
    this.withPeerReviewNoResearchStatement = false;
    this.workQueue = '';
    this.pureId = '';
    this.portfolioId = null;
    this.hasPortfolioMembership = false;
    this.portfolioName = '';
    this.portfolioStatus = null;
    this.withNoPortfolioForcApportions = false;
    this.portfolioForcId = null;
    this.withPortfolioPeerReview = false;
    this.withPortfolioPeerReviewNoResearchStatement = false;
    this.eligibility = 'eligible';
    this.myClaims = CLAIM_OPTIONS_MINE.notSelected.value;
    this.otherUserClaims = CLAIM_OPTIONS_OTHERS.notSelected.value;
    this.recentlyClaimed = CLAIM_OPTIONS_RECENT.notSelected.value;
    this.rciClass = null;
    this.impactedOnly = false;
    this.page = 1;
  }

  static create(params = {}) {
    const updateParams = {
      journalForcId: _.toInteger(params.journalForcId) || null,
      interestForcId: _.toInteger(params.interestForcId) || null,
      interestsForAllMyForcs: _.trim(params.interestsForAllMyForcs) === 'true',
      claimForcIds: valueAsArray(params.claimForcIds).map(Number),
      yearStart: _.toInteger(params.yearStart) || null,
      yearEnd: _.toInteger(params.yearEnd) || null,
      excludeActive: _.trim(params.excludeActive) === 'true',
      withMdJournalForc: _.trim(params.withMdJournalForc) === 'true',
      withNoClaimForcApportions: _.trim(params.withNoClaimForcApportions) === 'true',
      withPeerReview: _.trim(params.withPeerReview) === 'true',
      withPeerReviewNoResearchStatement: _.trim(params.withPeerReviewNoResearchStatement) === 'true',
      hasPortfolioMembership: _.trim(params.hasPortfolioMembership) === 'true',
      withNoPortfolioForcApportions: _.trim(params.withNoPortfolioForcApportions) === 'true',
      withPortfolioPeerReview: _.trim(params.withPortfolioPeerReview) === 'true',
      withPortfolioPeerReviewNoResearchStatement: _.trim(params.withPortfolioPeerReviewNoResearchStatement) === 'true',
      impactedOnly: _.trim(params.impactedOnly) === 'true',
      rciClass: _.toInteger(params.rciClass) || null,
    };

    return Object.assign(
      new WhatIfResearchOutputSearchParam(),
      params,
      updateParams,
    );
  }

  toApiParams() {
    return getNonEmptyValues({
      radar_id: this.radarId,
      author_name_contains: this.authorNameContains,
      era_id: this.eraId,
      exclude_active: this.excludeActive,
      research_output_type: this.researchOutputTypes,
      research_output_title: this.title,
      pub_year_min: this.yearStart,
      pub_year_max: this.yearEnd,
      pub_name_contains: this.publisherName,
      parent_doc_contains: this.parentDoc,

      with_md_journal_forc: this.withMdJournalForc,
      journal_forc_id: this.withMdJournalForc
        ? null
        : this.journalForcId,

      interest_for_all_my_forcs: this.interestsForAllMyForcs,
      interest_forc_id: this.interestsForAllMyForcs
        ? null
        : this.interestForcId,

      status: this.roStatus,
      faculty_department_name: this.facultyOrDepartmentName,
      with_no_claim_forc_apportions: this.withNoClaimForcApportions,

      claim_forc_ids: this.withNoClaimForcApportions
        ? null
        : this.claimForcIds,

      with_peer_review: this.withNoClaimForcApportions
        ? null
        : this.withPeerReview,

      with_peer_review_no_research_statement: this.withNoClaimForcApportions
        ? null
        : this.withPeerReviewNoResearchStatement,

      work_queue: this.workQueue,
      pure_id: this.pureId,
      portfolio_id: this.portfolioId,
      has_portfolio_membership: this.hasPortfolioMembership,
      portfolio_name: this.portfolioName,
      portfolio_status: this.portfolioStatus,
      with_no_portfolio_forc_apportions: this.withNoPortfolioForcApportions,

      portfolio_forc_id: this.withNoPortfolioForcApportions
        ? null
        : this.portfolioForcId,

      with_portfolio_peer_review: this.withNoPortfolioForcApportions
        ? null
        : this.withPortfolioPeerReview,

      with_portfolio_peer_review_no_research_statement: this.withNoPortfolioForcApportions
        ? null
        : this.withPortfolioPeerReviewNoResearchStatement,

      filter_by_eligibility_status: this.eligibility,
      my_claims: this.myClaims,
      other_user_claims: this.otherUserClaims,
      recently_claimed: this.recentlyClaimed,

      rci_class: this.rciClass,
      impacted_only: this.impactedOnly,

      page: this.page || 1,
    });
  }
}

export default WhatIfResearchOutputSearchParam;
