import _ from 'lodash';
import { validate as validateRoClaims } from '@/utils/ro-claims-validator';
import { replaceAllPureWsUrlWithAdminUrl } from '@/utils/url-util';
import CitationBenchmarks from '@/models/analytics/citation-benchmarks';
import Forc from '@/models/era/forc';
import Author from '@/models/research-output/author';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputAbdc from '@/models/research-output/research-output-abdc';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import Validation from '@/models/validation';
import Citation from './citation';

class ResearchOutputForWhatIf {
  constructor(researchOutput = {}) {
    this.id = researchOutput.id;
    this.research_output_type = researchOutput.research_output_type;
    this.managing_organisation_name = researchOutput.managing_organisation_name;
    this.parent_doc = researchOutput.parent_doc;
    this.title_nativescript = researchOutput.title_nativescript;
    this.title_language = researchOutput.title_language;
    this.title_translated = researchOutput.title_translated;
    this.pub_year = researchOutput.pub_year;

    this.journal_name = researchOutput.journal_name;
    this.creators = Author.fromList(researchOutput.creators);
    this.abstract = researchOutput.abstract;

    this.electronic_locations = replaceAllPureWsUrlWithAdminUrl(researchOutput.electronic_locations);

    this.is_peer_reviewed = researchOutput.is_peer_reviewed;
    this.citations = new Citation(researchOutput.citations_data_for_all_sources);
    this.citation_data = researchOutput.citation_data;

    this.journal_issn = researchOutput.journal_issn;
    this.journal_forcs = researchOutput.journal_forcs
      ? Forc.fromList(researchOutput.journal_forcs) : [];
    this.journal_is_multi_disciplinary = !!researchOutput.journal_is_multi_disciplinary;

    this.existing_claims = ResearchOutputClaim
      .fromList(researchOutput.existing_claims);
    this.existing_what_if_claims = ResearchOutputClaim.fromList(researchOutput.what_if_claims);
    this.what_if_claims_all_deleted = researchOutput.what_if_claims_all_deleted;

    const hasClaimOverrides = this.existing_what_if_claims.length || this.what_if_claims_all_deleted;
    this.populatedUserClaims = hasClaimOverrides
      ? this.existing_what_if_claims : this.existing_claims;
    this.ro_claims = ResearchOutputClaim.fromList(this.populatedUserClaims);
    this.abdc_rankings = researchOutput.abdc_rankings
      ? ResearchOutputAbdc.fromList(researchOutput.abdc_rankings)
      : [];
    this.disable_clawback_logic = researchOutput.disable_clawback_logic;
    this.validation = new Validation();
    this.has_bylines = researchOutput.has_bylines;
  }

  get title() {
    return `${this.research_output_type} • ${this.is_translated ? this.title_translated : this.title_nativescript}`;
  }

  get totalCitations() {
    return this.citation_data;
  }

  static fromList(researchOutputForBulkCode = []) {
    return researchOutputForBulkCode
      .map(researchOutput => new ResearchOutputForWhatIf(researchOutput));
  }

  clearClaims() {
    this.ro_claims = [];
    this.validate();
  }

  getJournalForcs() {
    if (!this.isJournalArticle() || this.isMultiDisciplinary()) {
      return [];
    }

    return this.journal_forcs;
  }

  getInternalAuthorsAsCsv() {
    return this.getInternalAuthors()
      .map(author => author.researcher_name)
      .join(', ');
  }

  isForcRestrictedByArc() {
    return (
      !this.disable_clawback_logic
      && (this.isJournalArticle()
      && !this.isMultiDisciplinary()
      && !_.isEmpty(this.journal_forcs))
    );
  }

  getInternalAuthors() {
    // same author present as part of multiple affiliations
    const uniqueInternalAuthors = _.uniqBy(
      this.creators.filter(author => author.isInternal()),
      'id',
    );
    return _.sortBy(uniqueInternalAuthors, 'order');
  }

  isJournalArticle() {
    return this.research_output_type === ResearchOutput.TYPES.JOURNAL_ARTICLE.value;
  }

  titleAndAuthors() {
    return `${this.title} ${this.getInternalAuthorsAsCsv()}`;
  }

  isMultiDisciplinary() {
    return this.journal_is_multi_disciplinary;
  }

  getJournalForcsRepresentation() {
    if (!this.isJournalArticle()) {
      return undefined;
    }
    if (this.isMultiDisciplinary()) {
      return 'MD';
    }
    if (_.isEmpty(this.journal_forcs)) {
      return undefined;
    }
    return this.journal_forcs.map(forc => forc.text).join(', ');
  }

  addClaim(claim) {
    const existingClaimForSameForc = this.ro_claims
      .find(existingClaim => existingClaim.forc.isSameAs(claim.forc));

    if (existingClaimForSameForc) {
      existingClaimForSameForc.apportion = claim.apportion;
      existingClaimForSameForc.is_peer_reviewed = claim.is_peer_reviewed;
      return;
    }

    if (this.ro_claims.length === 3) {
      return;
    }
    this.ro_claims.push(new ResearchOutputClaim(claim));
  }

  removeClaimAt(index) {
    this.ro_claims.splice(index, 1);
    this.validate();
  }

  addError(error) {
    this.validation.addErrors(error);
  }

  isValid() {
    return this.validation.isValid();
  }

  isInvalid() {
    return this.validation.isInvalid();
  }

  hasClaims() {
    return this.ro_claims.length > 0;
  }

  hasBeenValidated() {
    return this.validation.hasBeenValidated();
  }

  claimDetails() {
    return {
      research_output_id: this.id,
      claims: this.ro_claims.map(claim => claim.basicDetails(false)),
    };
  }

  validate(clawbackBypassedForcs = []) {
    this.validation.clearErrors();

    const errors = validateRoClaims(
      this.ro_claims,
      this.getJournalForcs(),
      this.isForcRestrictedByArc(),
      true,
      false,
      clawbackBypassedForcs,
    );

    this.validation.addErrors(...errors);
    return !this.validation.hasErrors();
  }

  getValidationStatusText() {
    if (!this.hasBeenValidated()) return '';
    if (this.isValid()) return 'valid';
    return 'invalid';
  }

  hasClaimChanges() {
    if (this.populatedUserClaims.length !== this.ro_claims.length) {
      return true;
    }

    if (this.ro_claims.length === 0) {
      return false;
    }
    return this.ro_claims
      .some((existingClaim, index) => !existingClaim.isSameAs(this.populatedUserClaims[index]));
  }

  getRciByBenchmark(forc, citationBenchmarks = new CitationBenchmarks()) {
    if (!forc || !this.totalCitations) return NaN;

    const benchmark = citationBenchmarks.getBenchmarkForForcAndYear(forc, this.pub_year);

    return _.round(this.totalCitations / benchmark, 2);
  }

  getRciRepresentationByBenchmark(forc, citationBenchmarks, fallback = '') {
    const rci = this.getRciByBenchmark(forc, citationBenchmarks);
    return _.isFinite(rci) ? rci : fallback;
  }

  getAbdcRanking() {
    if (_.isEmpty(this.abdc_rankings)) {
      return undefined;
    }
    return `${this.abdc_rankings[0].value} -  ${this.abdc_rankings[0].year}`;
  }
}

export default ResearchOutputForWhatIf;
