import { useHttp } from './http';

const http = useHttp('researchers');

export default {
  search: params => http.get(null, { params }),
  get: id => http.get(id),
  getClaimVersions: id => http.get([id, 'researcher-claim-versions']),
  getStatistics: id => http.get([id, 'statistics']),
  saveClaims: (id, claims) => http.post([id, 'researcher-claims'], claims),
  updateEligibilityStatus: (id, status) => http.put([id, 'eligibility_status'], status),
  assignPrimaryPosition: (id, positionId) => http.put([id, 'assign_primary_position'], { position_id: positionId }),
};
