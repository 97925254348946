import User from '@/models/user/user';
import ClaimItem from '@/models/era/claim-item';

class ClaimVersion {
  constructor(item = {}) {
    const version = item || {};
    this.id = version.id;
    this.is_latest = version.is_latest;
    this.updated_at = version.updated_at && new Date(version.updated_at);
    this.created_by = version.created_by && new User(version.created_by);
    this.is_manual_override = version.is_manual_override;
    this.has_claims = version.has_claims;
    this.claims = ClaimItem.fromList(version.claims || version.income_claims);
    this.comment = version.comment;
  }

  static fromList(versions = []) {
    return versions.map(claim => new ClaimVersion(claim));
  }

  hasPeerReviewedClaims() {
    return this.claims.some(c => c.isPeerReviewed);
  }

  addEmptyClaims() {
    while (this.claims.length < 3) {
      this.claims.push(new ClaimItem());
    }
  }

  removeEmptyClaims() {
    this.claims = this.claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
  }

  hasPeerReviewClaim() {
    return this.claims.some(c => c.isPeerReviewed);
  }

  hasClaimsSameAs(that) {
    return this.claims.length === that.claims.length
      && this.claims.every((claim, index) => claim.isSameAs(that.claims[index]));
  }
}

export default ClaimVersion;
