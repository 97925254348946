<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <radar-modal v-model="isSearchDisplayed">
      <div slot="modal-definition">Search</div>
      <data-quality-search
        :search-filter="searchParams"
        :show="isSearchDisplayed"
        @searched="onSearch"
      />
    </radar-modal>
    <portal to="app-header">
      <div class="page-title">
        Data Quality
      </div>
    </portal>
    <div class="dq-dashboard" :class="{ 'dq-dashboard--min': isMinDashboard || isMobile }">
      <div class="dq-overview shadow-1">
        <div class="dq-top">
          <div class="r-heading-3 col-grow">Overview</div>
          <div class="flex dq-totals">
            <div>
              <span>Total errors:</span>
              <a
                href="javascript:void(0)"
                :disabled="!statTotal.total"
                @click="statTotal.total && onSearch({ choiceType: null })"
              >{{ statTotal.total }}</a>
            </div>
            <div>
              <span>ARC:</span>
              <a
                href="javascript:void(0)"
                :disabled="!statTotal.arc"
                @click="statTotal.arc && onSearch({ choiceType: 'ARC' })"
              >{{ statTotal.arc }}</a>
            </div>
            <div>
              <span>MQ:</span>
              <a
                href="javascript:void(0)"
                :disabled="!statTotal.mq"
                @click="statTotal.mq && onSearch({ choiceType: 'MQ' })"
              >{{ statTotal.mq }}</a>
            </div>
          </div>
        </div>
        <div class="dq-panels">
          <data-quality-stats
            v-for="(dqStats, index) in statDetails"
            :key="index"
            class="dq-item"
            :stats="dqStats"
            @click="onSearch"
          />
        </div>
      </div>
      <div class="dq-bar shadow-1">
        <div>
          <span>{{ tableTitle }}</span>
          <q-btn
            type="a"
            :href="exportCsvUrl"
            class="q-ml-xl no-shadow"
            color="mq-sand-dark"
            text-color="mq-dark"
            icon="fa fa-download"
            label="Export to Excel"
          />
        </div>
        <q-btn
          class="search-bar no-shadow"
          icon="search"
          align="left"
          no-ripple
          no-caps
          @click="isSearchDisplayed = true"
        >
          Search
          {{ hasActiveFilters()? ' - Filters present': '' }}
        </q-btn>
      </div>
    </div>
    <data-quality-list
      class="q-px-lg"
      @dashboardState="setDashboardState"
    />
    <pagination-total-count
      class="pagination-total border-top"
      :current-page="searchPagination.currentPage"
      :page-size="searchPagination.pageSize"
      :total-results="searchPagination.totalResults"
      @change="fetchPage"
    />
    <radar-loading :visible="progress.searching || progress.processing" />
  </q-page>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import { mapActions, mapGetters, mapState } from 'vuex';
import { CHECK_TYPE_NAMES } from '@/models/data-quality/data-quality-types';
import DataQualitySearchParam from '@/models/data-quality/data-quality-search-param';
import RadarModal from '@/components/common/RadarModal.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import DataQualitySearch from './DataQualitySearch.vue';
import DataQualityStats from './DataQualityStats.vue';
import DataQualityList from './DataQualityList.vue';

const OMITTED_PROPERTIES = ['page', 'checkType', 'choiceType', 'eraId'];
const DEFAULT_SEARCH_PARAMS = _.omit(new DataQualitySearchParam(), OMITTED_PROPERTIES);

export default {
  name: 'DataQualityOverview',
  components: {
    RadarModal,
    DataQualitySearch,
    DataQualityStats,
    DataQualityList,
    PaginationTotalCount,
    RadarLoading,
  },
  data() {
    return {
      searchParams: new DataQualitySearchParam(),
      isSearchDisplayed: false,
      isMinDashboard: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapState('dataQuality', ['dqChecks', 'statDetails', 'searchPagination', 'progress']),
    ...mapGetters('dataQuality', ['statTotal']),
    ...mapGetters('eras', ['workingEra']),
    tableTitle() {
      const filtered = this.hasActiveFilters() ? 'Filtered' : '';
      const checkType = this.searchParams.checkType ? `${CHECK_TYPE_NAMES[this.searchParams.checkType]} -` : '';
      const choiceType = this.searchParams.choiceType || 'Total';
      return `${filtered} ${checkType} ${choiceType} (${this.searchPagination.totalResults})`;
    },
    exportCsvUrl() {
      const endpoint = '/api/data_quality/checks/csv/';
      const params = { ...this.searchParams.toApiParams(), era_id: this.workingEra.id };
      delete params.page;
      const queryParams = qs.stringify(params, { arrayFormat: 'repeat' });
      return `${endpoint}?${queryParams}`;
    },
  },
  methods: {
    ...mapActions('dataQuality', ['searchChecks', 'getStatistics']),
    setDashboardState(val) {
      this.isMinDashboard = val;
    },
    setResize() {
      this.isMobile = window.innerWidth < 768;
    },
    hasActiveFilters() {
      return !_.isEqual(DEFAULT_SEARCH_PARAMS, _.omit(this.searchParams, OMITTED_PROPERTIES));
    },
    fetchPage(page) {
      const query = { ...this.searchParams, page };
      this.$router.push({ query });
    },
    onSearch(query) {
      this.isSearchDisplayed = false;
      this.$router.push({ query });
    },
    searchByQueryParams() {
      const { query } = this.$route;
      this.executeSearch(query);
    },
    async executeSearch(searchParameters = {}) {
      this.searchParams = DataQualitySearchParam.create(searchParameters);
      try {
        await this.searchChecks(this.searchParams);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height, overflowY: 'hidden' };
    },
  },
  watch: {
    $route() {
      this.searchByQueryParams();
    },
  },
  async created() {
    await Promise.all([
      this.getStatistics(),
      this.searchByQueryParams(),
    ]);
  },
  mounted() {
    window.addEventListener('resize', this.setResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.setResize);
  },
};
</script>

<style lang="stylus" scoped>
.search-bar
  width 15rem

.pagination-total
  padding 0 15px
  height 48px
  background-color #fff
  color $primary
</style>
