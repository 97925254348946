<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="Peer Reviewable Research Outputs">
      <report-table
        slot="table"
        :data="peerReviewData"
        :summary-row="peerReviewSummary"
        :columns="peerReviewCols"
        row-key="outputType"
      />
    </report-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { formatFloat, formatPercent, div } from '@/utils/formatter';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptPeerReview',
  components: {
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      peerReviewCols: [
        { label: 'Output Type', field: 'outputType', align: 'left' },
        { label: 'Outputs', field: 'outputCount', format: formatFloat(2) },
        { label: '% of Output', field: 'percent', format: formatPercent(0) },
        { label: 'Peer Review', field: 'peerReviewCount', format: formatFloat(0, true) },
      ],
    };
  },
  computed: {
    ...mapState('reporting', ['researchOutputData']),
    ...mapGetters('reporting', ['roTotals']),
    peerReviewData() {
      return this.researchOutputData.map(row => ({
        outputType: `${row.roType}: ${row.roTypeName}`,
        outputCount: row.outputCount,
        percent: div(row.outputCount, this.roTotals.outputCount),
        peerReviewCount: row.peerReviewCount,
      }));
    },
    peerReviewSummary() {
      return {
        outputType: 'TOTAL',
        outputCount: this.roTotals.outputCount,
        percent: this.roTotals.outputCount ? 1 : 0,
        peerReviewCount: this.roTotals.peerReviewCount,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
