<template>
  <form @submit.prevent="save()">
    <div class="r-heading-1-dense text-primary r-p-vertical">
      {{ isNew ? 'Give this What if a title': 'Rename' }}
    </div>
    <div class="col text-mq-soft items-end field-group¯s">
      <div class="text-right absolute-bottom r-m-large-right r-m-top">
        <div class="r-m-bottom">
          <q-btn
            type="button"
            color="mq-soft"
            label="Cancel"
            flat
            class="r-m-right"
            @click="cancel()"
          />
          <q-btn type="submit" color="primary" label="Save" />
        </div>
      </div>
      <q-field label="Title" :error="errors.has('what-if-id')" error-label="Title is required">
        <q-input v-model.trim="whatIf.title" v-validate="'required'" name="what-if-id" />
      </q-field>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WhatIf from '@/models/what-if/what-if';

export default {
  name: 'WhatIfDetail',
  props: {
    whatIf: {
      type: WhatIf,
      default: () => new WhatIf(),
    },
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    errors() {
      return this.$validator.errors;
    },
    isNew() {
      return !this.whatIf.id;
    },
  },
  methods: {
    ...mapActions('whatIfs', ['saveWhatIf']),
    async save() {
      await this.$validator.validateAll();
      if (this.errors.any()) return;

      this.whatIf.era_submission_id = this.workingEra.id;
      try {
        await this.saveWhatIf(this.whatIf);
        this.$emit('saved');
      } catch (e) {
        this.$emit('error', e);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
