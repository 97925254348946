const ELIGIBILITY_STATUS = {
  eligible: 'Eligible',
  ineligible: 'Ineligible',
};

class ResearcherStatus {
  constructor(value) {
    const status = value || {};
    this.id = status.id;
    this.forced = status.forced_eligibility;
    this.automatic = status.automatic_eligibility;
    this.comment = status.comment;
  }

  isAutomatic() {
    return this.forced === 'automatic';
  }

  isEligible() {
    return (this.isAutomatic() ? this.automatic : this.forced) === 'eligible';
  }

  displayText() {
    if (this.id) {
      return this.isAutomatic()
        ? `Automatic ${ELIGIBILITY_STATUS[this.automatic]}`
        : `Forced ${ELIGIBILITY_STATUS[this.forced]}`;
    }
    return '-';
  }
}

export default ResearcherStatus;
