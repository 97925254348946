export const MANAGE_RESEARCH_OUTPUT_CLAIM = 'manage_research_output_claim';
export const MANAGE_RESEARCH_STATEMENT = 'manage_research_statement';
export const MANAGE_RESEARCHER_CLAIM = 'manage_researcher_claim';
export const MANAGE_LOCK_RESEARCH_OUTPUT_CLAIM = 'manage_lock_research_output_claim';
export const MANAGE_RESEARCH_STATEMENTS = 'manage_research_statements';
export const ACCESS_WORK_QUEUE = 'access_work_queue';
export const ESCALATE_L1_DISPUTE = 'escalate_l1_dispute';
export const RESOLVE_L1_DISPUTE = 'resolve_l1_dispute';
export const ESCALATE_L2_DISPUTE = 'escalate_l2_dispute';
export const RESOLVE_L2_DISPUTE = 'resolve_l2_dispute';
export const MANAGE_USER = 'manage_user';
export const MANAGE_ERA = 'manage_era';
export const MANAGE_WHAT_IF = 'manage_what_if';
export const VIEW_ANALYTICS_VOLUME_MONITORING = 'view_analytics_volume_monitoring';
export const VIEW_ANALYTICS_PERFORMANCE_METRICS = 'view_analytics_performance_metrics';
export const PROPOSE_WHAT_IF = 'propose_what_if';
export const MANAGE_RESEARCH_INCOME_FILE = 'manage_research_income_file';
export const MANAGE_RESEARCH_INCOME_CLAIM = 'manage_research_income_claim';
export const MANAGE_APPLIED_MEASURE_CLAIM = 'manage_applied_measure_claim';
export const MANAGE_PORTFOLIO = 'manage_portfolio';
export const FINALISE_PORTFOLIO = 'finalise_portfolio';
export const UNFINALISE_PORTFOLIO = 'unfinalise_portfolio';
export const VIEW_DATA_QUALITY = 'view_data_quality';
export const VIEW_XML_SUBMISSION = 'view_xml_submission';
// Can select researcher's primary position in researchers module "Affiliations"
export const MANAGE_RESEARCHER_PRIMARY_POSITION = 'manage_researcher_primary_position';
export const MANAGE_SENSITIVITY = 'manage_sensitivity';
export const MANAGE_REVIEW_REQUIRED = 'manage_review_required';

const Permissions = {
  MANAGE_RESEARCH_OUTPUT_CLAIM,
  MANAGE_RESEARCH_STATEMENT,
  MANAGE_RESEARCHER_CLAIM,
  MANAGE_LOCK_RESEARCH_OUTPUT_CLAIM,
  MANAGE_RESEARCH_STATEMENTS,
  ACCESS_WORK_QUEUE,
  ESCALATE_L1_DISPUTE,
  ESCALATE_L2_DISPUTE,
  RESOLVE_L1_DISPUTE,
  RESOLVE_L2_DISPUTE,
  MANAGE_USER,
  MANAGE_ERA,
  MANAGE_WHAT_IF,
  VIEW_ANALYTICS_VOLUME_MONITORING,
  VIEW_ANALYTICS_PERFORMANCE_METRICS,
  PROPOSE_WHAT_IF,
  MANAGE_RESEARCH_INCOME_FILE,
  MANAGE_RESEARCH_INCOME_CLAIM,
  MANAGE_APPLIED_MEASURE_CLAIM,
  MANAGE_PORTFOLIO,
  FINALISE_PORTFOLIO,
  UNFINALISE_PORTFOLIO,
  VIEW_DATA_QUALITY,
  VIEW_XML_SUBMISSION,
  MANAGE_RESEARCHER_PRIMARY_POSITION,
  MANAGE_SENSITIVITY,
};

export default Permissions;
