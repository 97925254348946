<template>
  <div class="q-mr-lg">
    <div class="row">
      <div class="col-1 q-pr-xl" />
      <div class="col row body-4 stats-header">
        <div class="col" />
        <div class="col text-right text-mq-soft">Before</div>
        <div class="col text-right text-mq-soft">After</div>
        <div class="col text-right text-mq-soft">Change</div>
      </div>
    </div>
    <div class="stats-body">
      <div v-for="category in categories" :key="category" class="row q-py-sm items-baseline">
        <div class="col-1 col-grow self-center term-definition text-secondary q-pr-xl">
          {{ category }}
        </div>
        <div class="col">
          <div class="row body-2 items-baseline">
            <span class="col body-4">THRESHOLD</span>
            <span class="col text-right">
              {{ comparison.before_peer_review_stats[category].threshold }}
            </span>
            <span class="col text-right">
              {{ comparison.after_peer_review_stats[category].threshold }}
            </span>
            <span class="col text-right">
              <q-icon
                v-if="comparison.thresholdChangeByCategory(category) > 0"
                name="arrow_upward"
                class="text-mq-positive"
              />
              <q-icon
                v-else-if="comparison.thresholdChangeByCategory(category) < 0"
                name="arrow_downward"
                class="text-negative"
              />
              <q-icon
                v-else
                name="compare_arrows"
              />
            </span>
          </div>
          <div class="row body-2 items-baseline">
            <span class="col body-4">TAGGED</span>
            <span
              class="col text-right"
              :class="comparison.before_peer_review_stats.hasBeenTaggedToThresholdForCategory(category)
                ? 'text-mq-positive':'text-negative'"
            >
              {{ comparison.before_peer_review_stats[category].tagged }}
            </span>
            <span
              class="col text-right"
              :class="comparison.after_peer_review_stats.hasBeenTaggedToThresholdForCategory(category)
                ? 'text-mq-positive':'text-negative'"
            >
              {{ comparison.after_peer_review_stats[category].tagged }}
            </span>
            <span class="col text-right">
              <q-icon
                v-if="comparison.taggedChangeByCategory(category) > 0"
                name="arrow_upward"
                class="text-mq-positive"
              />
              <q-icon
                v-else-if="comparison.taggedChangeByCategory(category) < 0"
                name="arrow_downward"
                class="text-negative"
              />
              <q-icon
                v-else
                name="compare_arrows"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForcPeerReviewPerformanceComparison
  from '@/models/analytics/forc-peer-review-performance-comparison';

export default {
  name: 'ForcPeerReviewClassDistributionComparison',
  props: {
    comparison: {
      type: ForcPeerReviewPerformanceComparison,
      required: true,
    },
  },
  computed: {
    categories() {
      return this.comparison.before_peer_review_stats.getCategories();
    },
  },
};
</script>
<style lang="stylus" scoped>
  .stats-body
    > div:nth-child(odd)
      background-color $mq-soft-light

</style>
