<template>
  <div class="text-left full-width q-pb-sm claim-cell-enrich-item">
    <span v-if="claim && claim.forc" class="group legend text-weight-medium">
      <span>Rci: {{ rci }}</span>
      <span aria-hidden="true">&bull;</span>
      <span>Class: {{ rciClass }}</span>
    </span>
  </div>
</template>

<script>
import _ from 'lodash';
import CitationBenchmarks from '@/models/analytics/citation-benchmarks';
import ClassDistribution from '@/models/analytics/class-distribution';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';

export default {
  name: 'RciAndClassForClaim',
  props: {
    claim: ResearchOutputClaim,
    citationBenchmarks: {
      type: CitationBenchmarks,
      required: true,
    },
    researchOutput: {
      type: ResearchOutputForWhatIf,
      required: true,
    },
  },
  computed: {
    isRciValid() {
      return _.isFinite(this.rci);
    },
    rci() {
      return this.researchOutput
        .getRciRepresentationByBenchmark(this.claim.forc, this.citationBenchmarks);
    },
    rciClass() {
      if (!this.isRciValid) return '';

      return ClassDistribution.getClassValueForRci(this.rci);
    },
  },
};
</script>

<style scoped>

</style>
