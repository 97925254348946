<template>
  <div class="am-stats__container">
    <router-link
      class="am-stats__total"
      target="_blank"
      :tag="totalCount ? 'a' : 'div'"
      :to="amLink()"
    >
      <div :class="totalCount ? null : 'no-link'">
        <div>Total</div>
        <div>{{ totalCount }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ResearcherAppliedMeasureCount',
  props: {
    oneId: {
      type: String,
      default: null,
    },
    stats: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    totalCount() {
      return this.stats.appliedMeasureTotal();
    },
  },
  methods: {
    amLink() {
      return {
        name: 'applied-measures',
        query: {
          authorNameContains: this.oneId,
        },
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
$stats-item
  color black
  text-decoration none

  & > div
    padding 6px 0
    border-radius 4px

    &:not(.no-link):hover
      background-color $indigo-2
      font-weight 700

    &.no-link:hover
      cursor not-allowed

    div:last-child
      padding-top 5px
      color $mq-magenta

.am-stats
  &__container
    display flex
    flex-wrap wrap
    margin-top 10px
    border 1px solid rgba(0,0,0,.2)
    border-radius 6px
    font-size 12px
    text-align center
    text-transform uppercase
    overflow hidden

  &__total
    flex-basis 50px
    padding 4px
    @extend $stats-item
</style>
