export const dataQualityFields = [
  {
    name: 'identifier',
    label: 'identifier',
    field: 'identifier',
    classes: '',
    align: 'left',
  }, {
    name: 'eraCategory',
    label: 'Category',
    field: 'eraCategory',
    classes: '',
    align: 'center',
  }, {
    name: 'title',
    label: 'Title',
    field: 'title',
    classes: '',
    align: 'left',
  }, {
    name: 'code',
    label: 'Code',
    field: 'code',
    classes: '',
    align: 'left',
  }, {
    name: 'businessRule',
    label: 'Business Rule Code & Description',
    field: 'description',
    classes: '',
    align: 'left',
  }, {
    name: 'type',
    label: 'Type',
    field: 'checkType',
    classes: '',
    align: 'left',
  }, {
    name: 'radar',
    label: 'Radar',
    field: 'radarId',
    classes: '',
    align: 'center',
  }, {
    name: 'pure',
    label: 'Pure',
    field: 'pureUrl',
    classes: '',
    align: 'center',
  },
];

export default { dataQualityFields };
