import { useHttp } from './http';

const http = useHttp('portfolios');

export default {
  getAll: () => http.get(),
  getSummary: () => http.get('light'),
  get: id => http.get(id),
  create: name => http.post('', { name }),
  update: (id, portfolio) => http.put(id, portfolio),
  delete: id => http.delete(id),
  updateMembership: membership => http.post('research_outputs_membership', membership),
  saveClaims: claims => http.post('bulk_claims', claims),
};
