import _ from 'lodash';

export const replacePureWsUrlWithAdminUrl = url => url.replace('/ws/', '/admin/');

export const replaceAllPureWsUrlWithAdminUrl = (urls = []) => urls.map(replacePureWsUrlWithAdminUrl);

export const getFieldsFromQueryParametersBasedOn = (existingObject, queryParameters) => _.pick(queryParameters, _.keys(existingObject));

export default {
  replacePureWsUrlWithAdminUrl,
  replaceAllPureWsUrlWithAdminUrl,
  getFieldsFromQueryParametersBasedOn,
};
