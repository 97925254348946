const claimCellStyle = 'width: 190px; min-width: 150px';

export const appliedMeasureFields = [
  {
    name: 'title',
    label: 'Applied Measures',
    align: 'left',
    style: 'min-width: 300px',
    classes: 'bg-white',
  }, {
    name: 'appliedMeasureTypeName',
    label: 'Type',
    align: 'left',
    style: 'width: 91px',
    classes: 'bg-white',
  }, {
    name: 'pubYear',
    label: 'Year',
    align: 'right',
    style: 'width: 50px',
    classes: 'bg-white',
  }, {
    name: 'autoForcs',
    label: 'Automated Forcs',
    align: 'right',
    style: 'width: 150px',
    classes: 'bg-white',
  }, {
    name: 'claim1',
    label: 'Current Claim 1',
    field: 'ro_claims',
    align: 'center',
    style: `${claimCellStyle}; padding-left: 10px`,
    sortable: false,
    classes: 'col-claim',
  }, {
    name: 'claim2',
    label: 'Current Claim 2',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    sortable: false,
    classes: 'col-claim',
  }, {
    name: 'claim3',
    label: 'Current Claim 3',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    sortable: false,
    classes: 'col-claim',
  }, {
    name: 'validations',
    label: '',
    align: 'center',
    sortable: true,
    style: 'width: 50px',
    classes: 'col-claim no-padding',
  },
];

export default { appliedMeasureFields };
