var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"term-definition text-secondary"},[_vm._v("Count")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"body-2",class:_vm.comparison.before_peer_review_stats.isAboveCountThreshold()?
              'text-mq-positive':
              'text-negative'},[_vm._v(" "+_vm._s(_vm.comparison.before_peer_review_stats.count)+" ")]),_c('div',{staticClass:"body-4 text-mq-soft"},[_vm._v("Before")])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"body-2",class:_vm.comparison.after_peer_review_stats.isAboveCountThreshold()?
              'text-mq-positive':
              'text-negative'},[_vm._v(" "+_vm._s(_vm.comparison.after_peer_review_stats.count)+" ")]),_c('div',{staticClass:"body-4 text-mq-soft"},[_vm._v("After")])])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"term-definition text-secondary"},[_vm._v("Tagged")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"body-2",class:_vm.comparison.before_peer_review_stats.hasBeenTaggedToThreshold()?
              'text-mq-positive':
              'text-negative'},[_vm._v(" "+_vm._s(_vm.comparison.before_peer_review_stats.tagged_count)+" ")]),_c('div',{staticClass:"body-4 text-mq-soft"},[_vm._v("Before")])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"body-2",class:_vm.comparison.after_peer_review_stats.hasBeenTaggedToThreshold()?
              'text-mq-positive':
              'text-negative'},[_vm._v(" "+_vm._s(_vm.comparison.after_peer_review_stats.tagged_count)+" ")]),_c('div',{staticClass:"body-4 text-mq-soft"},[_vm._v("After")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }