import axios, { useHttp } from './http';

const http = useHttp('users');

export default {
  getCurrent: eraId => axios.get('api/user_detail/', { params: { era_submission_id: eraId } }),

  search: params => http.get('', { params }),
  get: id => http.get(id),
  create: user => http.post('', user),
  update: (id, user) => http.put(id, user),

  getAllAccess: userId => http.get([userId, 'access']),
  getAccess: ua => http.get([ua.user.id, 'access', ua.id]),
  createAccess: ua => http.post([ua.user_id, 'access'], ua),
  updateAccess: ua => http.put([ua.user_id, 'access', ua.id], ua),
  deleteAccess: ua => http.delete([ua.user.id, 'access', ua.id]),

  getAllRoles: () => axios.get('api/roles/'),
};
