import api from '@/api';

export default {
  namespaced: true,
  state: {
    sensitivityTypes: [],
  },
  mutations: {
    SET_SENSITIVITY_TYPES(state, sensitivityTypes = []) {
      state.sensitivityTypes = sensitivityTypes;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      const { data } = await api.sensitivityTypes.all();
      commit('SET_SENSITIVITY_TYPES', data.results);
    },
  },

};
