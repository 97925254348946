<template>
  <div>
    <div class="headline-title flex justify-between">
      <span>Affiliations</span>
    </div>
    <div v-if="positions.length">
      <div
        v-for="position in sortedResearchPositions"
        :key="position.id"
        class="border-bottom q-ml-lg"
      >
        <div class="position-title text-weight-medium q-pt-lg">
          <span>{{ position.position_title }}</span>
          <div
            v-if="position.is_current"
            class="float-right q-mx-sm q-mx-md q-body-1"
          >
            <q-toggle
              v-if="_canUserManageResearcherPrimaryPosition"
              v-model="primarySelected"
              label="Primary"
              color="green-9"
              left-label
              :val="position.id"
              @input="selectPrimary(position.id)"
            >
              <q-tooltip v-if="position.id !== prevSelected">Set as primary affiliation</q-tooltip>
            </q-toggle>
            <q-chip
              v-if="!_canUserManageResearcherPrimaryPosition && position.is_primary"
              color="green-9"
              class="is-primary"
              square
              dense
            >
              PRIMARY
            </q-chip>
          </div>
        </div>
        <div class="q-caption q-mt-sm"> {{ getAffiliationLabelFor(position) }}</div>
        <div class="q-mt-lg">
          <dl>
            <dt class="term-title-small">JOB ID</dt>
            <dd class="term-definition">{{ position.job_number }}</dd>
            <dt class="term-title-small">WORK FUNCTION</dt>
            <dd class="term-definition">{{ position.work_function }}</dd>
            <dt class="term-title-small">FTE</dt>
            <dd class="term-definition">{{ position.fte_percent }}%</dd>
            <dt class="term-title-small">POSITION TYPE</dt>
            <dd class="term-definition">{{ position.position_type }}</dd>
            <dt class="term-title-small">Active on Census Date</dt>
            <dd
              class="term-definition"
              :class="position.is_current ? 'text-positive': 'text-negative'"
            >
              {{ position.is_current ? 'Yes': 'No' }}
            </dd>
          </dl>
          <div class="row">
            <dl class="col">
              <dt class="term-title-small"> Start</dt>
              <dd class="term-definition">{{ formatDateAbbreviated(position.start_date) }}</dd>
            </dl>
            <dl class="col">
              <dt class="term-title-small"> End</dt>
              <dd class="term-definition">{{ formatDateAbbreviated(position.end_date) }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Researcher from '@/models/researcher/researcher';
import { formatDateAbbreviated } from '@/utils/date';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ResearcherAffiliations',
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    faculties: {
      type: Array,
      default: () => [],
    },
    researcher: {
      type: Researcher,
      default: () => new Researcher(),
    },
  },
  data() {
    return {
      prevSelected: null,
      primarySelected: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['canUserManageResearcherPrimaryPosition']),
    sortedResearchPositions() {
      return _.orderBy(this.researcher.researcher_positions, ['end_date'], ['desc']);
    },
    isPositionEditable() {
      return this.researcher.getCurrentPositions().length > 1;
    },
    /* eslint no-underscore-dangle: ["error", { "allow": ["_canUserManageResearcherPrimaryPosition()"] }] */
    _canUserManageResearcherPrimaryPosition() {
      // Admin / Faculty Admin can manage the primary position toggle - they have "manage_researcher_primary_position" permission
      return this.canUserManageResearcherPrimaryPosition;
    },
  },
  methods: {
    ...mapActions('researchers', ['assignPrimaryPosition']),
    formatDateAbbreviated,
    getAffiliationLabelFor(position) {
      const departmentName = this.departmentNameById(position.department_id);
      const facultyName = this.facultyNameById(position.faculty_id);

      return _.compact([departmentName, facultyName]).join(', ');
    },
    departmentNameById(id) {
      // eslint-disable-next-line eqeqeq
      const department = this.departments.find(d => d.id == id);
      return department && department.name;
    },
    facultyNameById(id) {
      // eslint-disable-next-line eqeqeq
      const faculty = this.faculties.find(f => f.id == id);
      return faculty && faculty.name;
    },
    async selectPrimary(id) {
      try {
        this.primarySelected = [id];
        if (this.prevSelected !== id) {
          await this.assignPrimaryPosition({
            researcherId: this.researcher.id,
            positionId: id,
          });
          this.prevSelected = id;
        }
      } catch (e) {
        this.primarySelected = [this.prevSelected];
        this.$notify.failure(e);
      }
    },
  },
  watch: {
    researcher: {
      immediate: true,
      handler() {
        this.primarySelected = this.researcher.researcher_positions
          .filter(p => p.is_primary)
          .map(p => p.id);
        this.prevSelected = this.primarySelected.length > 0 ? this.primarySelected[0] : null;
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.headline-title
  position sticky
  top 0
  padding-left 20px
  padding-bottom 1rem
  background-color $mq-sand
  z-index 1

.position-title
  position sticky
  top 44px
  margin-left -20px
  padding-left 20px
  padding-bottom 15px
  background-color darken($mq-sand, 7.5%)
  border-top 2px solid rgba(0,0,0, .2)
  overflow-y auto
  overflow-x hidden

.is-primary
  margin-top 5px
  margin-left 5px
  border-radius 3px

dl
  margin-bottom .5rem

dd
  margin-bottom .5rem

dt
  text-transform uppercase
  margin-top 1rem

.q-toggle
  margin-bottom 5px

  >>> .q-option-label
    opacity 0
    transition none

  >>> .active + .q-option-label
    padding 2px 6px
    border-radius 4px
    background-color $green-9
    color #fff
    font-size 12px
    text-transform uppercase
    opacity 1
    transition opacity .4s ease-in-out
    cursor default

</style>
