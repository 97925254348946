<template>
  <div>
    <div v-if="!progress.loading && !researchers.length" class="q-mt-lg">
      No researchers found. Please expand your search criteria.
    </div>
    <researcher-card
      v-for="researcher in researchers"
      :key="researcher.id"
      :researcher="researcher"
      :departments="departments"
      :faculties="faculties"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ResearcherCard from './ResearcherCard.vue';

export default {
  name: 'ResearchersList',
  components: {
    ResearcherCard,
  },
  props: {
    researchers: {
      type: Array,
      required: true,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    faculties: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('researchers', ['progress']),
  },
};
</script>

<style lang="stylus" scoped>
  .researcher-card + .researcher-card
    margin-top 15px;
</style>
