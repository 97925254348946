import _ from 'lodash';
import EraFaculty from '@/models/era/era-faculty';
import User from '@/models/user/user';
import WhatIfEvent from './what-if-event';

export const WHAT_IF_STATES = {
  DRAFT: 'DRAFT',
  PROPOSED: 'PROPOSED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  APPLIED: 'APPLIED',
};

class WhatIf {
  constructor(whatIf = {}) {
    this.id = whatIf.id;
    this.era_submission_id = whatIf.era_submission_id;
    this.state = whatIf.state;
    this.created_at = whatIf.created_at;
    this.created_by_faculty = whatIf.created_by_faculty
      && new EraFaculty(whatIf.created_by_faculty);
    this.created_by = whatIf.created_by && new User(whatIf.created_by);
    this.is_active = whatIf.is_active;
    this.title = whatIf.title;
    this.what_if_events = WhatIfEvent.fromList(whatIf.what_if_events);
    this.is_valid = whatIf.is_valid;
    this.impacted_faculty_ids = whatIf.impacted_faculty_ids || [];
    this.impacted_faculty_ids_not_accepted = whatIf.impacted_faculty_ids_not_accepted || [];
    this.researchOutputCount = whatIf.research_output_count;
  }

  static fromList(whatIfs = []) {
    return whatIfs.map(whatIf => new WhatIf(whatIf));
  }

  isProposed() {
    return this.state === WHAT_IF_STATES.PROPOSED;
  }

  isNotSubmitted() {
    return this.state === WHAT_IF_STATES.DRAFT;
  }

  isApproved() {
    return this.state === WHAT_IF_STATES.APPROVED;
  }

  isApplied() {
    return this.state === WHAT_IF_STATES.APPLIED;
  }

  isRejected() {
    return this.state === WHAT_IF_STATES.REJECTED;
  }

  canSubmitWhatIf(user) {
    return this.isCreatedBy(user)
      && this.isNotSubmitted()
      && this.roSubmissionStatusIsValid();
  }

  canApplyWhatIf(facultyIds = []) {
    return this.hasBeenSubmittedBy(facultyIds) && this.isApproved();
  }

  canManageWhatIf(user) {
    return this.isCreatedBy(user);
  }

  canEditWhatIf(user) {
    return this.isCreatedBy(user) && this.isNotSubmitted();
  }

  canDeleteWhatIf(user) {
    return this.isCreatedBy(user) && !this.isApplied();
  }

  isCreatedBy(user) {
    if (!user) return false;
    return _.get(this.created_by, 'id') === user.id;
  }

  isEmpty() {
    return !this.id;
  }

  roSubmissionStatusIsValid() {
    return this.is_valid;
  }

  impactsAnyOfTheFaculties(facultyIds) {
    const commonFacultyIds = _.intersection(
      facultyIds,
      this.impacted_faculty_ids,
    );
    return commonFacultyIds > 0;
  }

  impactsOtherFaculties(loggedInUserFacultyIds) {
    const otherFacultyIds = _.difference(
      this.impacted_faculty_ids,
      loggedInUserFacultyIds,
    );
    return otherFacultyIds > 0;
  }

  impactsAnyOfTheFacultiesNotAccepted(facultyIds) {
    const commonFacultyIds = _.intersection(
      facultyIds,
      this.impacted_faculty_ids_not_accepted,
    );
    return commonFacultyIds > 0;
  }

  facultyIdsAlreadyAccepted() {
    return _.difference(
      this.impacted_faculty_ids,
      this.impacted_faculty_ids_not_accepted,
    );
  }

  hasBeenAcceptedBy(facultyIds) {
    const commonFacultyIds = _.intersection(
      facultyIds,
      this.facultyIdsAlreadyAccepted(),
    );
    return commonFacultyIds.length > 0;
  }

  hasBeenSubmittedBy(facultyIds = []) {
    return !!this.what_if_events.find(whatIfEvent => whatIfEvent.hasBeenSubmittedBy(facultyIds));
  }
}

export default WhatIf;
