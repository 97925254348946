import Forc from '@/models/era/forc';

class ResearchOutputInterest {
  static TYPES = {
    ORG_UNIT: 'ORG_UNIT',
    AUTHOR: 'AUTHOR',
    JOURNAL: 'JOURNAL',
  }

  constructor(interest = {}) {
    this.id = interest.id;
    this.interest_type = interest.interest_type;
    this.forcs = Forc.fromList(interest.forcs);
  }

  static fromList(interests = []) {
    return interests
      .map(interest => new ResearchOutputInterest(interest));
  }

  isOrgUnit() {
    return this.interest_type === ResearchOutputInterest.TYPES.ORG_UNIT;
  }

  isAuthor() {
    return this.interest_type === ResearchOutputInterest.TYPES.AUTHOR;
  }

  isJournal() {
    return this.interest_type === ResearchOutputInterest.TYPES.JOURNAL;
  }
}

export default ResearchOutputInterest;
