<template>
  <div class="row items-baseline justify-between">
    <div class="col-auto row">
      <q-field
        error-label="Required"
        class="col-auto"
        :error="errors.has(name)"
      >
        <forc-selector
          v-model="claim.forc"
          v-validate="'required'"
          class="apportion-entry inline q-mr-sm"
          :forcs="forcs"
          :disable="disable"
          :data-vv-name="name"
        />
      </q-field>
      <q-field
        class="col-auto q-pl-md"
        :error-label="minApportion+'% - 100%'"
        :error="errors.has(name+'-apportion')"
      >
        <q-input
          v-model.number="claim.apportion"
          v-validate="{ required: true, min_value: minApportion, max_value: 100 }"
          class="apportion-entry inline"
          type="number"
          :min="minApportion"
          :max="1"
          :step="1"
          placeholder="Percentage"
          :decimals="0"
          :data-vv-name="name+'-apportion'"
          suffix="%"
          :disable="disable"
        />
      </q-field>
    </div>
    <span class="col-auto">
      <slot name="suffix" />
    </span>
  </div>
</template>

<script>
import ResearcherClaim from '@/models/researcher/researcher-claim';
import ForcSelector from '@/components/common/ForcSelector.vue';

export default {
  name: 'Claim',
  components: { ForcSelector },
  inject: ['$validator'],
  props: {
    claim: {
      type: ResearcherClaim,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    forcs: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    minApportion: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    errors() {
      return this.$validator.errors;
    },
    forcOptions() {
      return this.forcs.map(forc => ({
        label: forc.text,
        value: forc,
      }));
    },
    forcSelectedValue() {
      if (!this.claim.forc) return undefined;
      return this.forcs.find(forc => forc.id === this.claim.forc.id);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .apportion-entry
    min-width 6rem

  .forc-selector
    min-width 4rem
</style>
