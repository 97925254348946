<template>
  <div class="mq-banner-secondary text-white q-px-md q-pt-md">
    <div class="r-heading-3">
      1. Search research outputs
    </div>
    <form class="row justify-center q-mx-lg" @submit.prevent="search">
      <div class="col">
        <div class="row gutter-xs q-field-group items-baseline search-fields">
          <q-field
            class="col-lg-2 col-md-4 col-xs-12 q-field-required"
            :error="errors.has('department') || errors.has('oneId')"
            label=""
            :label-width="12"
          >
            <q-input
              v-model.trim="searchParameters.oneId"
              v-validate="isDepartmentOrOneIdRequired ? 'required' : ''"
              name="oneId"
              color="white"
              inverted
              class="bg-white text-black tall-input"
              :error="false"
            />
            <span>MQ Id/Author name  OR</span>
          </q-field>
          <q-field
            class="col-lg-3 col-md-4 col-xs-12 q-field-required"
            :error="errors.has('department') || errors.has('oneId')"
            label=""
            :label-width="12"
          >
            <q-select
              v-model="searchParameters.departmentName"
              v-validate="isDepartmentOrOneIdRequired ? 'required' : ''"
              name="department"
              color="white"
              inverted
              class="bg-white text-black tall-input"
              :options="departmentsOptions"
              clearable
              filter
            />
            <span>Author Department  (one of the MQ Id or Department is required)</span>
          </q-field>
          <q-field
            class="col-lg-2 col-md-4 col-xs-12 q-field-required"
            label=""
            :label-width="12"
            :error="errors.has('category')"
          >
            <q-select
              v-model="searchParameters.researchOutputType"
              v-validate="'required'"
              :options="typeOptions"
              color="white"
              inverted
              class="bg-white text-black tall-input"
              clearable
              filter
              name="category"
            />
            <span>HERDC Category   (REQUIRED)</span>
          </q-field>
          <q-field
            class="col-lg-2 col-md-4 col-xs-12"
            label=""
            :label-width="12"
          >
            <q-select
              v-model="searchParameters.journalForcId"
              :options="journalForcOptions"
              color="white"
              inverted
              class="bg-white text-black tall-input"
              :filter="labelStartWithOrSublabelContainsFilter"
              clearable
            />
            <span>ARC Journal FoRCs</span>
          </q-field>
          <q-field
            class="col-lg-1 col-md-2 col-xs-12"
            label=""
            :label-width="12"
          >
            <q-input
              v-model="searchParameters.pubYear"
              clearable
              color="white"
              inverted
              class="
              bg-white text-black tall-input"
            />
            <span>Year</span>
          </q-field>
          <q-field
            class="col-lg-1 col-md-2 col-xs-12"
            label=""
            :label-width="12"
          >
            <q-btn-group class="q-pt-xs full-width">
              <q-btn
                type="submit"
                label="Search"
                color="primary"
                class="tall-button full-width"
              />
              <q-btn
                type="button"
                label="Clear"
                color="mq-sand-dark"
                class="tall-button text-dark full-width"
                @click="clearSearch()"
              />
            </q-btn-group>
          </q-field>
        </div>

        <div class="row full-width q-py-md items-center">
          <div class="col-auto">
            <q-radio
              v-model="searchParameters.excludeCodedBy"
              val="me"
              color="white"
              label="Exclude those I've coded"
              keep-color
              class="small-label"
            />
            <q-radio
              v-model="searchParameters.excludeCodedBy"
              val="myforcs"
              color="white"
              label="Exclude those coded with my FoRCs"
              style="margin-left: 10px"
              class="small-label"
              keep-color
            />
            <q-radio
              v-model="searchParameters.excludeCodedBy"
              val="anyone"
              color="white"
              label="Exclude those coded by anyone"
              style="margin-left: 10px"
              class="small-label"
              keep-color
            />
          </div>
          <div class="col-sm-12 col-lg body text-right">
            Note: bulk coding does not show records submitted in the previous ERA
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { booleanPromise } from '@/utils/promise-util';
import { labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import Forc from '@/models/era/forc';

function getDefaultSearchParameters() {
  return {
    oneId: undefined,
    departmentName: null,
    researchOutputType: null,
    pubYear: '',
    journalForcId: null,
    excludeCodedBy: 'anyone',
  };
}

export default {
  name: 'BulkCodeSearch',
  data() {
    return {
      searchParameters: getDefaultSearchParameters(),
    };
  },
  computed: {
    ...mapState('researchOutputs', {
      types: state => state.types,
    }),
    ...mapGetters('bulkCode', ['hasChanges', 'searchLimitReached']),
    ...mapState('bulkCode', {
      researchOutputCount: state => state.researchOutputCount,
      totalResearchOutputsForBulkCode: state => state.totalResearchOutputsForBulkCode,
    }),
    ...mapState('organisationEntities', {
      faculties: state => state.faculties,
      departments: state => state.departments,
    }),
    ...mapGetters('referenceData', ['forcsForWorkingEra']),
    ...mapGetters('eras', ['workingEra']),
    typeOptions() {
      return this.types.map(type => ({ label: type, value: type.split(':')[0] }));
    },
    facultiesOptions() {
      const sortedFaculties = _.sortBy(this.faculties, 'name');
      return sortedFaculties.map(faculty => ({ label: faculty.name, value: faculty.id }));
    },
    departmentsOptions() {
      // Get unique + sorted "name"s from department list of objects
      const uniqueDepartmentNames = [ ...new Set([ ...this.departments ].map(i => (i.name))) ].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentNames.map( i => ({ label:i, value:i }) );
    },
    isDepartmentOrOneIdRequired() {
      return _.isEmpty(this.searchParameters.oneId) && !this.searchParameters.departmentName;
    },
    journalForcOptions() {
      const forcsForJournals = Forc.getTwoAndFourDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForJournals);
    },
  },
  methods: {
    ...mapActions('bulkCode', ['searchResearchOutputs', 'resetBulk']),
    labelStartWithOrSublabelContainsFilter,
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    confirmDiscardChangesAndSearch() {
      return booleanPromise(this.$q.dialog({
        title: 'Warning',
        message: 'Discard un-submitted work and make a new search?',
        color: 'primary',
        ok: true,
        cancel: true,
      }));
    },
    bulkLimitReachedWarning() {
      return booleanPromise(this.$q.dialog({
        title: 'Warning',
        message: `Your Search Result has ${this.researchOutputCount} outputs, only the first
        ${this.totalResearchOutputsForBulkCode} will be shown.
        You could either refine your search criteria or continue to bulk code the first ${this.totalResearchOutputsForBulkCode} outputs.
        After coding the first ${this.totalResearchOutputsForBulkCode},
        you can repeat the search to show the next ${this.totalResearchOutputsForBulkCode} outputs.`,
        color: 'primary',
        ok: true,
        cancel: true,
      }));
    },
    async search() {
      if (this.hasChanges) {
        const accepted = await this.confirmDiscardChangesAndSearch();
        if (!accepted) return;
      }

      this.$notify.clear();
      await this.$validator.validateAll();

      if (this.errors.any()) { return; }
      try {
        await this.searchResearchOutputs({ ...this.searchParameters, eraId: this.workingEra.id });
        if (this.searchLimitReached) {
          const accepted = await this.bulkLimitReachedWarning();
          if (!accepted) return;
        }
      } catch (e) {
        this.searchError = e;
      }
    },
    clearSearch() {
      this.searchParameters = getDefaultSearchParameters();
      this.$validator.reset();
    },
  },
};
</script>

<style lang="stylus" scoped>
.search-fields
  >>> .q-field
    .q-field-label,
    .q-field-error
      color #fff !important
      font-size 12px !important

    &:first-of-type
      .q-field-error
        white-space nowrap

     .q-field-required
       color: green!important

.q-field-required span
  &::before
    content: '*'
    color: red

 .q-btn-group
    padding-top: 0px;

.search-fields
  >>> .q-field span
      font-size 12px !important
</style>
