<template>
  <div
    class="r-p-top r-p-left q-pr-sm row full-width"
    :class="{ 'bg-mq-neutral': isSelected, 'bg-white':!isSelected }"
  >
    <div class="col border-bottom">
      <div class="row items-center">
        <div class="col-auto q-pb-md r-p-left">
          <research-output-claim-status :status="researchOutput.ro_status" />
        </div>
        <div class="col q-pb-md r-p-left">
          <div class="row r-m-right radar-sublabel justify-between items-center">
            <div class="col">
              <span>{{ researchOutput.research_output_type }}</span>
              <span>&bull; {{ researchOutput.pub_year }}</span>
              <span v-if="researchOutput.isMemberOfPortfolio">&bull; {{ portfolioName }} </span>
              <span v-if="researchOutput.isMemberOfPortfolio && researchOutput.portfolio.hasPeerReviewedClaims()">&#x2713; Peer Reviewed</span>
              <span v-if="researchOutput.isJournalArticle()">
                &bull;
                <span class="text-weight-bold">ARC: </span>
                <span v-if="researchOutput.isMultiDisciplinary()">MD</span>
                <span v-for="journalForc in journalForcs" v-else :key="journalForc.id" class="col text-right q-pr-sm">
                  {{ journalForc.text }}<q-tooltip>{{ journalForc.description }}</q-tooltip>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <MQBylineLogo v-if="researchOutput.has_bylines" class="mq-byline" />
          <a
            v-for="(pdfUrl,index) in researchOutput.electronic_locations"
            :key="index"
            :href="pdfUrl"
            target="_blank"
            class="icon-link q-pr-sm"
          >
            <q-icon color="mq-soft" name="description" />
          </a>
          <span v-if="!researchOutput.electronic_locations.length">No pdf</span>
          <q-btn
            flat
            no-ripple
            dense
            class="icon-link no-padding"
            icon="notes"
            @click.native="$refs.abstract.open()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col q-pb-md r-p-left">
          <div class="row r-m-right radar-sublabel justify-between items-center">
            <div class="r-heading-2 col-12">
              <a
                href="javascript;"
                class="ro-title javascript-link"
                @click.prevent="$emit('select')"
                v-html="title"
              />
            </div>
            <div class="q-pb-sm q-pr-none q-mr-none authors">
              <span v-for="author in authors" :key="'author-'+author.order">
                <router-link
                  v-if="author.isInternal()"
                  class="author-name q-mr-xs"
                  :to="{ name: 'researcher-details', params: { researcherId: author.id }}"
                  target="_blank"
                >
                  {{ author.researcher_name }}&semi;
                  <q-tooltip v-if="author.canShowOrganisation()">
                    {{ author.organisation_name }}
                  </q-tooltip>
                </router-link>
                <div v-else class="inline-block author-name q-mr-xs q-body-1">
                  {{ author.researcher_name }}&semi;
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto col-grow">
      <span class="full-height flex">
        <q-btn
          dense
          round
          flat
          class="self-center"
          color="mq-grey-dark"
          @click="$emit('select')"
        >
          <q-icon name="keyboard_arrow_right" class="select-ro-button" />
        </q-btn>
      </span>
    </div>
    <research-output-abstract-modal
      ref="abstract"
      :title="title"
      :abstract="researchOutput.abstract"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ResearchOutput from '@/models/research-output/research-output';
import MQBylineLogo from '@/components/common/MQBylineLogo.vue';
import ResearchOutputClaimStatus from '../claims-and-escalations/ResearchOutputClaimStatus.vue';
import ResearchOutputAbstractModal from '../ResearchOutputAbstractModal.vue';

export default {
  name: 'ResearchOutputMinimal',
  components: {
    ResearchOutputAbstractModal,
    ResearchOutputClaimStatus,
    MQBylineLogo,
  },
  props: {
    researchOutput: {
      type: ResearchOutput,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.researchOutput.is_translated
        ? this.researchOutput.title_translated
        : this.researchOutput.title_nativescript;
    },
    authors() {
      return _.sortBy(this.researchOutput.uniqueCreators(), 'order');
    },
    journalForcs() {
      return this.researchOutput.getJournalForcs();
    },
    activeRoClaims() {
      return this.researchOutput.getActiveClaims();
    },
    portfolioName() {
      return this.researchOutput.getPortfolioName();
    },
  },
  methods: {
    saveClaim(claim) {
      this.$emit('save-claim', claim);
    },
    deleteClaim(claim) {
      this.$emit('delete-claim', claim);
    },
  },
};
</script>
<style lang="stylus" scoped>
  .ro-title
    text-align justify

  a:link
    &.javascript-link
      color: $dark
      text-decoration none

      &:hover
        text-decoration underline

  a:visited
    color $blue

    &:hover
      text-decoration underline

  .affiliations
    margin-left 2.2rem

  span.author-name
    text-decoration none

  a.author-name
    text-decoration none

    &:hover
      text-decoration underline

  .author-name
    font-size 10px
    overflow-wrap break-word

  .term
    font-size 10px
    font-weight 500

  .term-items
    font-size 10px

  .authors
    margin-top 12px

  .select-ro-button
    font-size 28px !important
    font-weight 500

</style>
