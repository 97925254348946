<template>
  <div class="full-width relative-position scrollable-section">
    <radar-modal v-model="isSearchDisplayed">
      <div slot="modal-definition">Search</div>
      <funding-search
        ref="fundingSearch"
        @searched="isSearchDisplayed = false"
      />
    </radar-modal>

    <!-- Page header -->
    <div class="page-title fixed-top-right z-fullscreen">
      <q-btn
        class="search-bar search-input no-shadow"
        icon="search"
        align="left"
        no-ripple
        no-caps
        @click="isSearchDisplayed = true"
      >
        Search {{ hasActiveFilters() ? ' - Filters present' : '' }}
      </q-btn>
    </div>
    <q-table
      class="research-income-editor-table"
      table-class="bg-white"
      :data="fundings"
      :columns="columns"
      :rows-per-page-options="[0]"
      :filter="filter"
      :pagination.sync="pagination"
      row-key="id"
      binary-state-sort
      no-data-label="No data found"
      no-results-label="No data matches your filter"
    >
      <!-- Table header -->
      <q-tr slot="header" slot-scope="props">
        <q-th key="title" class="grid-cell bg-white" :props="props">
          <div class="detail-section">
            <div class="col-toggle">
              <q-checkbox
                v-model="allExpandMode"
                color="primary"
                checked-icon="keyboard_arrow_down"
                unchecked-icon="chevron_right"
                keep-color
                @input="setAllExpandedSlots"
              />
            </div>
            <div class="col-title">Title</div>
            <div class="col-pureid">Pure ID</div>
            <div class="col-leadci">Lead CI</div>
            <div class="col-schemebody">Funding Scheme</div>
            <div class="col-herdc">HERDC Category</div>
          </div>
        </q-th>
        <th class="grid-cell bg-mq-neutral" width="15%">
          <div class="fund-section">
            <span class="col-income-type">Income Type</span>
            <span class="col-funding">Amount</span>
          </div>
        </th>
        <th class="grid-cell bg-mq-sand" width="480">
          <div class="claim-section">
            <div class="col-claim1">Claim 1</div>
            <div class="col-claim2">Claim 2</div>
            <div class="col-claim3">Claim 3</div>
            <div class="col-status" />
          </div>
        </th>
      </q-tr>
      <!-- Line detail -->
      <template slot="body" slot-scope="props">
        <q-tr :props="props">
          <q-td class="grid-cell">
            <div class="detail-section">
              <div class="col-toggle">
                <q-checkbox
                  v-model="expansionSlots[props.row.id]"
                  color="primary"
                  checked-icon="keyboard_arrow_down"
                  unchecked-icon="chevron_right"
                  keep-color
                />
              </div>
              <div class="col-title">
                {{ props.row.project_title ? props.row.project_title : props.row.major_account }}
              </div>
              <div class="col-pureid">
                <component
                  :is="props.row.pure_link ? 'a' : 'span'"
                  :href="props.row.pure_link"
                  target="_blank"
                >
                  {{ props.row.pure_id }}
                </component>
              </div>
              <div class="col-leadci">
                <router-link
                  v-if="props.row.chief_investigator.id"
                  :to="{ name: 'researcher-details', params: {researcherId: props.row.chief_investigator.id }}"
                  target="_blank"
                >
                  {{ props.row.chief_investigator.full_name }}
                </router-link>
                <span v-else>-</span>
              </div>
              <div class="col-schemebody">{{ props.row.funding_scheme_description }}</div>
              <div class="col-herdc">{{ props.row.herdc_category }}</div>
              <div class="col-info">
                <research-income-metadata-cell
                  v-if="isExpanded(props.row.id)"
                  class="col wrappable-table-column"
                  :funding="props.row"
                />
              </div>
            </div>
          </q-td>
          <q-td class="grid-cell">
            <div class="fund-section">
              <div class="col-income-type">{{ props.row.incomeType }}</div>
              <div class="col-funding">
                <div
                  v-for="fundingYear in props.row.getYearsSorted()"
                  :key="fundingYear.id"
                  class="flex justify-between"
                >
                  <span class="col-year">{{ fundingYear.year }}</span>
                  <span>{{ formatCurrency(fundingYear.total) }}</span>
                </div>
                <template v-if="props.row.incomeType === 'Research'">
                  <div
                    v-if="props.row.awarded_amount"
                    class="flex justify-between text-secondary q-mt-sm"
                  >
                    <span class="col-year">Total Awarded</span>
                    <span>{{ formatCurrency(props.row.awarded_amount) }}</span>
                  </div>
                  <div
                    v-if="canUserManageEra && !selectedEdit && (props.row.awarded_amount === null || props.row.is_awarded_amount_manual)"
                    class="text-left"
                  >
                    <q-btn
                      class="q-mt-sm"
                      color="mq-purple"
                      style="letter-spacing: 1px"
                      label="Update Awarded Amount"
                      size="xs"
                      outline
                      :loading="progress.awardUpdating && props.row.id == selectedRow.id"
                      @click="editAwardedAmount(props.row)"
                    />
                  </div>
                </template>
              </div>
            </div>
          </q-td>
          <q-td class="grid-cell">
            <form class="claim-section" @submit.prevent="saveClaims(props.row)">
              <div v-for="(claimClass, claimIndex) in ['col-claim1', 'col-claim2', 'col-claim3']" :key="claimClass" :class="[claimClass]">
                <claim-editor
                  v-model="props.row.under_edit_claims[claimIndex]"
                  :forc-list="fourDigitForcsForWorkingEra"
                  :edit-mode="selectedEdit === props.row.id"
                  @blur="props.row.validate()"
                />
                <div v-if="isExpanded(props.row.id) && props.row.hasPreviousClaimVersions()" class="q-mt-md">
                  <div class="q-mt-lg term-title-small text-left all-caps">
                    Previous Era
                  </div>
                  <claim-editor
                    class="q-mt-sm read-only"
                    :value="props.row.getPreviousClaimAt(claimIndex)"
                    :edit-mode="false"
                  />
                </div>
              </div>
              <div class="col-status">
                <q-btn
                  v-if="canUserManageResearchIncomeClaim && !selectedEdit"
                  type="button"
                  class="claim-btn no-shadow"
                  color="blue-grey-5"
                  icon="edit"
                  size="md"
                  @click="editClaims(props.row)"
                />
                <q-icon
                  v-if="props.row.isValid()"
                  round
                  dense
                  flat
                  size="22px"
                  color="positive"
                  name="check_circle"
                />
                <q-icon
                  v-if="props.row.isInvalid()"
                  round
                  dense
                  flat
                  size="22px"
                  color="negative"
                  name="warning"
                />
              </div>
              <div class="col-claim-info">
                <div v-if="selectedEdit === props.row.id" class="q-mt-lg">
                  <q-field>
                    <q-input
                      v-model.trim="props.row.comment"
                      v-validate="'no_op'"
                      name="comment"
                      type="textarea"
                      class="border bg-white comfortable-textarea"
                      hide-underline
                      placeholder="Write a comment"
                      maxlength="1000"
                      required
                    />
                  </q-field>
                  <div
                    v-if="props.row.isInvalid()"
                    class="q-pa-md q-my-md border border-primary round-borders bg-white text-left"
                  >
                    <grid-validation-errors :validation="props.row.validation" />
                  </div>
                  <div class="flex justify-end q-my-lg">
                    <span v-if="progress.saving">Saving...</span>
                    <template v-else>
                      <q-btn
                        type="button"
                        color="blue-grey-5"
                        @click="cancelClaims(props.row)"
                      >
                        Cancel
                      </q-btn>
                      <q-btn
                        class="q-ml-md"
                        type="submit"
                        color="primary"
                      >
                        Save
                      </q-btn>
                    </template>
                  </div>
                </div>
                <!-- Claims history -->
                <div v-if="isExpanded(props.row.id)" class="q-py-md">
                  <claims-history :claim-versions="props.row.last_three_version_history" />
                </div>
              </div>
            </form>
          </q-td>
        </q-tr>
      </template>
      <div slot="bottom" slot-scope="props" class="row justify-between fit">
        <div class="q-pt-sm col group">
          <span v-if="progress.saving" class="on-right">Saving...</span>
        </div>
        <div class="col text-right">
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :props="props"
            :current-page="searchPagination.currentPage"
            :page-size="searchPagination.pageSize"
            :total-results="searchPagination.totalResults"
            :is-in-edit-mode="!!selectedEdit"
            @change="fetchPage"
            @abort="checkForUnsaveChanges"
          />
        </div>
      </div>
    </q-table>
    <save-dialog ref="saveDialog" />
    <radar-loading :visible="progress.searching" />
    <q-dialog
      v-model="awardEntryModal"
      prevent-close
      @show="showAwardEntry"
      @ok="saveAwardEntry"
    >
      <span slot="title">Awarded Amount</span>
      <span slot="message">Enter the amount awarded for this applied measure.</span>
      <div slot="body">
        <q-field>
          <q-input
            ref="awardInput"
            v-model="awardAmountEntry"
            type="number"
            :decimals="2"
            autofocus
            prefix="$"
          />
        </q-field>
      </div>
      <template slot="buttons" slot-scope="props">
        <q-btn color="primary" label="Save" @click="props.ok" />
        <q-btn flat label="Cancel" @click="props.cancel" />
      </template>
    </q-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatCurrency } from '@/utils/currency';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import RadarModal from '@/components/common/RadarModal.vue';
import SaveDialog from '@/components/common/SaveDialog.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import ClaimEditor from '@/components/claim/ClaimEditor.vue';
import ClaimsHistory from '@/components/claim/ClaimsHistory.vue';
import { fundingFields } from './funding-fields';
import FundingSearch from './FundingsSearch.vue';
import ResearchIncomeMetadataCell from './ResearchIncomeMetadataCell.vue';

export default {
  name: 'ResearchIncomeList',
  components: {
    RadarModal,
    RadarLoading,
    SaveDialog,
    PaginationTotalCount,
    GridValidationErrors,
    ClaimEditor,
    ClaimsHistory,
    FundingSearch,
    ResearchIncomeMetadataCell,
  },
  mixins: [AllFieldsExpandableTable, PermissionsMixin],
  data() {
    return {
      isSearchDisplayed: false,
      columns: fundingFields,
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      filter: '',
      selectedEdit: null,
      selectedRow: null,
      awardEntryModal: false,
      awardAmountEntry: null,
    };
  },
  computed: {
    ...mapState('fundings', ['fundings', 'searchPagination', 'progress']),
    ...mapGetters('referenceData', ['fourDigitForcsForWorkingEra']),
    eraId() {
      return this.$route.params.eraId;
    },
    expansionSlotDataItems() {
      return this.fundings;
    },
    hasUnsavedChanges() {
      return !!this.selectedEdit;
    },
  },
  methods: {
    ...mapActions('fundings', ['searchFundings', 'saveFundingClaims', 'saveAwardedAmount']),
    formatCurrency,
    // pureHasNoLink(pureId) {
    //   return pureId.toS
    // }
    fetchPage(page) {
      this.$refs.fundingSearch.search(page);
    },
    hasActiveFilters() {
      if (!this.$refs.fundingSearch) return false;
      return this.$refs.fundingSearch.hasActiveFilters();
    },
    editClaims(row) {
      row.addEmptyClaims();
      this.selectedRow = row;
      this.selectedEdit = row.id;
    },
    cancelClaims(row) {
      row.updateLatestClaimVersionWith(row.latest_income_claim_version);
      this.exitClaims(row);
    },
    exitClaims(row) {
      row.validation.reset();
      this.selectedEdit = null;
    },
    async saveClaims(row) {
      if (row.validate()) {
        row.removeEmptyClaims();
        if (row.hasClaimChanges()) {
          try {
            await this.saveFundingClaims([row]);
            this.$notify.success('Successfully Saved');
            this.exitClaims(row);
          } catch (e) {
            this.$notify.failure(e);
          }
        } else {
          this.exitClaims(row);
        }
      }
    },
    checkForUnsaveChanges(next) {
      this.$refs.saveDialog.show({
        save: async () => this.saveClaims(this.selectedRow),
        dontSave: async () => this.cancelClaims(this.selectedRow),
        hasUnsaved: () => this.hasUnsavedChanges,
        next,
      });
    },
    confirmPageExit(e) {
      const evt = e;
      if (this.hasUnsavedChanges) {
        evt.preventDefault();
        evt.returnValue = '';
      }
    },
    editAwardedAmount(row) {
      this.selectedRow = row;
      this.awardAmountEntry = row.awarded_amount || 0;
      this.awardEntryModal = true;
    },
    showAwardEntry() {
      const inputElement = this.$refs.awardInput.$el.querySelector('input');
      inputElement.focus();
      inputElement.select();
    },
    async saveAwardEntry() {
      try {
        await this.saveAwardedAmount({ id: this.selectedRow.id, amount: this.awardAmountEntry });
        this.$notify.success('Awarded Amount updated.');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
  watch: {
    eraId() {
      this.fetchPage(1);
    },
    fundings() {
      this.resetExpansion();
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmPageExit);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.confirmPageExit);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.checkForUnsaveChanges(next);
  },
};
</script>

<style lang="stylus" scoped>
.search-bar
  width 250px

.grid-cell
  padding 5px 0
  vertical-align top

$grid-container
  position relative
  display grid

  > div
    padding 0 5px

.detail-section
  @extend $grid-container
  grid-template-columns 25px 75px 120px 2fr 2fr 3fr
  grid-template-rows 1fr auto
  grid-template-areas \
    "toggle pureid  leadci schemebody herdc title" \
    "toggle info    info   info       info  title"

.col-toggle
  grid-area toggle
  position absolute
  margin-top -2px
  padding-left 0

.col-title
  grid-area title
  min-width 180px
  white-space normal
  text-align left

.col-pureid
  grid-area pureid
  text-align left

.col-leadci
  grid-area leadci
  white-space normal
  text-align left

.col-schemebody
  grid-area schemebody
  min-width 100px
  white-space normal
  text-align left

.col-herdc
  grid-area herdc
  white-space normal
  text-align left

.col-info
  grid-area info

.fund-section
  min-width 160px

  > *
    padding 0 5px

.col-income-type
  float left
  text-transform uppercase

  /tbody &
    font-size 10px

.col-funding
  float right
  text-align right

td
  .col-income-type
    margin 0 10px 5px 0

  .col-funding
    min-width 190px

  .col-year
    margin-right 10px
    width 50px

.claim-section
  @extend $grid-container
  min-width 450px
  grid-template-columns 1fr 1fr 1fr 40px
  grid-template-rows 1fr auto
  grid-template-areas \
    "claim1     claim2     claim3     status" \
    "claim-info claim-info claim-info status"

  [class^=col-claim]
    min-width 120px
    text-align center

.col-claim1
  grid-area claim1

.col-claim2
  grid-area claim2

.col-claim3
  grid-area claim3

.col-status
  grid-area status
  padding-top 2px

.col-claim-info
  grid-area claim-info
</style>
