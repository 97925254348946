<template>
  <q-item
    v-if="showItem"
    :to="linkTo"
    :class="isActive"
    class="q-py-md"
    exact
  >
    <q-item-side v-if="iconClass" class="menu-icon">
      <q-icon :name="icon" :class="[iconClass]" class="q-item-icon" />
    </q-item-side>
    <q-item-side v-else :icon="icon" class="menu-icon" />
    <q-item-main
      :label="title"
      class="menu-text body-2 q-mini-drawer-hide"
    />
    <q-tooltip
      :disable="!mini"
      :delay="300"
      anchor="center right"
      self="center left"
    >
      {{ title }}
    </q-tooltip>
  </q-item>
</template>

<script>
import config from '@/config';
import { mapGetters } from 'vuex';

export default {
  name: 'SideNavLink',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: '#',
    },
    activeLink: {
      type: String,
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    permission: {
      type: String,
      default: null,
    },
    feature: {
      type: String,
      default: null,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('auth', { checkPermission: 'hasPermission' }),
    linkTo() {
      const params = this.params || undefined;
      return this.availableRoutes.includes(this.link)
        ? { name: this.link, params }
        : undefined;
    },
    showItem() {
      return this.isFeatureEnabled(this.feature) && this.hasPermission(this.permission);
    },
    availableRoutes() {
      return this.flattenRoutes(this.$router.options.routes);
    },
    isActive() {
      const isActiveLink = this.$route.meta.activeRoute === (this.activeLink || this.link);
      return { 'router-link-exact-active': isActiveLink };
    },
  },
  methods: {
    isFeatureEnabled(feature) {
      return feature ? config.isFeatureEnabled(feature) : true;
    },
    hasPermission(token) {
      return token ? this.checkPermission(token) : true;
    },
    flattenRoutes(routes = []) {
      let flatRoutes = [];
      routes.forEach((route) => {
        if (route.name) {
          flatRoutes.push(route.name);
        }
        if (typeof route.children !== 'undefined' && route.children.length > 0) {
          flatRoutes = [...flatRoutes, ...this.flattenRoutes(route.children)];
        }
      });
      return flatRoutes;
    },
  },
};
</script>

<style lang="stylus" scoped>
.menu-icon, .menu-text
  color $mq-black

.q-item.router-link-exact-active
  background-color $mq-highlight
  color white

  .menu-icon, .menu-text
    color white

</style>
