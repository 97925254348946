<template>
  <div class="status-container text-center" :class="statusValue">
    <div class="self-center text-white q-caption q-pa-xs">{{ statusText }}</div>
    <q-icon
      :name="statusIcon"
      color="white"
      class="status-icon"
      size="large"
    />
  </div>
</template>

<script>
import ResearchOutputStatus from '@/models/research-output/research-output-status';

export default {
  name: 'ResearchOutputClaimStatus',
  props: {
    status: ResearchOutputStatus,
  },
  data() {
    return {
      statusDisplay: ResearchOutputStatus.ICONS,
    };
  },
  computed: {
    statusValue() {
      return ResearchOutputStatus.getStatusValue(this.status);
    },
    statusText() {
      return ResearchOutputStatus.getStatusTextFor(this.status);
    },
    statusIcon() {
      return ResearchOutputStatus.getIconFor(this.status);
    },
  },
};
</script>
<style lang="stylus" scoped>
  .status-container
    display flex
    gap 10px
    padding: 5px 10px
    border-radius: 14px

  .valid
    background-color #659d4e

  .under
    background-color #066ba0

  .over
    background-color #802243

  .claim_count_error
    background-color $primary

  .no_claims
    background-color #a79c78

  .portfolio_member
    background-color #606a8b

  .ineligible
    background-color #4e4e4e

  .clawback_invalid
    background-color #cd4646
</style>
