import _ from 'lodash';
import config from '@/config';
import rciClasses from './rci_classes.json';

class ClassDistribution {
  constructor(classDistribution = {}) {
    this.class0 = classDistribution.CLASS0;
    this.class1 = classDistribution.CLASS1;
    this.class2 = classDistribution.CLASS2;
    this.class3 = classDistribution.CLASS3;
    this.class4 = classDistribution.CLASS4;
    this.class5 = classDistribution.CLASS5;
    this.class6 = classDistribution.CLASS6;
    this.count = _.round(classDistribution.COUNT, 2);
    this.high_low = classDistribution.HIGH_LOW;
    this.rci = _.round(classDistribution.RCI, 2);
  }

  static classNames() {
    return _.map(rciClasses, 'name');
  }

  static getClassValueForRci(rci) {
    if (!_.isFinite(rci)) {
      return undefined;
    }

    const rciClass = _.findLast(rciClasses, rc => rci > rc.rangeMinValue);
    return _.get(rciClass, 'code', 0);
  }

  static rciClasses() {
    return rciClasses;
  }

  getHighForHighLowRatio() {
    return this.high_low.split('-')[0];
  }

  getLowForHighLowRatio() {
    return this.high_low.split('-')[1];
  }

  isAboveCountThreshold() {
    return this.count >= config.arc.apportionThreshold;
  }

  getPercentageFor(className) {
    return _.round((this[className] * 100.0) / this.count, 2) || 0;
  }
}

export default ClassDistribution;
