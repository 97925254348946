<template>
  <div>
    <div v-if="claim">
      <rci-and-class-for-claim
        :claim="claim"
        :citation-benchmarks="citationBenchmarks"
        :research-output="researchOutput"
      />
      <popup-editable-claim
        class="full-width"
        :research-output-for-bulk="researchOutput"
        :forcs="forcs"
        :under-edit="underEdit"
        :claim="claim"
        :user-forcs="userForcs"
        :clawback-bypassed-forcs="clawbackBypassedForcs"
        @edit="$emit('edit')"
        @removeEdit="$emit('removeEdit')"
        @delete="$emit('delete')"
      />
    </div>
    <div v-else-if="allowAdd">
      <rci-and-class-for-claim
        :claim="claimToBeAdded"
        :citation-benchmarks="citationBenchmarks"
        :research-output="researchOutput"
      />
      <bulk-single-claim-add
        :research-output-for-bulk="researchOutput"
        :forcs="forcs"
        :under-edit="underEdit"
        :user-forcs="userForcs"
        :clawback-bypassed-forcs="clawbackBypassedForcs"
        :claim="claimToBeAdded"
        @initiateEdit="$emit('initiateEdit')"
        @removeEdit="$emit('removeEdit')"
        @add="$emit('add', claimToBeAdded)"
      />
    </div>
    <div v-else>
      <div class="claim-cell-enrich-item" />
      <div class="bg-mq-sand-dark claim-cell-item" />
    </div>

    <div v-if="showPreviousClaim" class="q-mt-sm">
      <div v-if="previousClaim">
        <rci-and-class-for-claim
          :claim="previousClaim"
          :research-output="researchOutput"
          :citation-benchmarks="citationBenchmarks"
        />
        <claim-viewer
          :claim="previousClaim"
          :allow-peer-review-tag="false"
          class="forc-apportion-item bg-mq-soft-muted-light forc-apportion-cell-item"
        />
      </div>
      <div v-else>
        <div class="claim-cell-enrich-item" />
        <div class="bg-mq-sand-dark claim-cell-item" />
      </div>
    </div>
  </div>
</template>

<script>
import CitationBenchmarks from '@/models/analytics/citation-benchmarks';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';
import BulkSingleClaimAdd from '@/components/bulk-code/BulkSingleClaimAdd.vue';
import ClaimViewer from '@/components/bulk-code/ClaimViewer.vue';
import PopupEditableClaim from '@/components/bulk-code/PopupEditableClaim.vue';
import RciAndClassForClaim from '../common/ClaimRciClassEnrich.vue';

export default {
  name: 'WhatIfEditableCell',
  components: {
    RciAndClassForClaim, ClaimViewer, BulkSingleClaimAdd, PopupEditableClaim,
  },
  props: {
    claim: ResearchOutputClaim,
    previousClaim: ResearchOutputClaim,
    citationBenchmarks: {
      type: CitationBenchmarks,
      required: true,
    },
    researchOutput: {
      type: ResearchOutputForWhatIf,
      required: true,
    },
    forcs: {
      type: Array,
      required: true,
    },
    userForcs: {
      type: Array,
      required: true,
    },
    underEdit: {
      type: Boolean,
      default: false,
    },
    showPreviousClaim: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: false,
    },
    clawbackBypassedForcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      claimToBeAdded: new ResearchOutputClaim(),
    };
  },
};
</script>
<style lang="stylus" scoped>
  .forc-selector-min
    width: 4rem

  .apportion-min
    width: 4rem

  .bulk-claim-wrapper
    width 866px

  .authors-wrapper
    min-height 2rem
    max-width 1px
    height 100%
    overflow hidden
    word-wrap break-word
    border 1px solid yellow

  .what-if-claim-viewer
    width: 181.5px;
    height: 36px;
    border: solid 1px #6b7c93;
    background-color: rgb(107, 124, 147, 0.1);

</style>
