import config from '@/config';
import PeerReviewCategory from './peer-review-category';

class PeerReviewStatsForForc {
  static CATEGORIES = ['A1', 'B1', 'C1', 'E1', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Portfolio'];

  constructor([forcId, peerReview] = []) {
    this.forc_id = forcId;
    this.four_digit_code = peerReview.four_digit_code;
    this.description = peerReview.description;
    this.threshold = peerReview.threshold;
    this.weighted_count = peerReview.weighted_count;
    this.count = peerReview.count;
    this.tagged_count = peerReview.tagged_count;
    this.A1 = new PeerReviewCategory(peerReview.category.A1);
    this.B1 = new PeerReviewCategory(peerReview.category.B1);
    this.C1 = new PeerReviewCategory(peerReview.category.C1);
    this.E1 = new PeerReviewCategory(peerReview.category.E1);
    this.Q1 = new PeerReviewCategory(peerReview.category.Q1);
    this.Q2 = new PeerReviewCategory(peerReview.category.Q2);
    this.Q3 = new PeerReviewCategory(peerReview.category.Q3);
    this.Q4 = new PeerReviewCategory(peerReview.category.Q4);
    this.Q5 = new PeerReviewCategory(peerReview.category.Q5);
    this.Portfolio = new PeerReviewCategory(peerReview.category.Portfolio);
  }

  static fromList(peerReviews = {}) {
    return peerReviews.map(peerReview => new PeerReviewStatsForForc(peerReview));
  }

  // eslint-disable-next-line class-methods-use-this
  getCategories() {
    return PeerReviewStatsForForc.CATEGORIES;
  }

  isAboveCountThreshold() {
    return this.weighted_count >= config.arc.apportionThreshold;
  }

  hasBeenTaggedToThreshold() {
    return this.tagged_count >= this.threshold;
  }

  hasBeenTaggedToThresholdForCategory(category) {
    return this[category].hasBeenTaggedAboveThreshold();
  }
}

export default PeerReviewStatsForForc;
