import api from '@/api';
import User from '@/models/user/user';

export default {
  namespaced: true,
  state: {
    pagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    users: [],
    user: new User(),
    progress: {
      searching: false,
    },
  },
  mutations: {
    UPDATE_USERS(state, { users, currentPage, totalResults }) {
      state.users = users;
      state.pagination.currentPage = currentPage || 1;
      state.pagination.totalResults = totalResults || 0;
    },
    UPDATE_USER(state, user) {
      state.user = user;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async saveUser(context, user) {
      let userResponse;

      if (user.id) {
        userResponse = await api.users.update(user.id, user.basicInfo());
      } else {
        userResponse = await api.users.create(user.basicInfo());
      }

      context.commit('UPDATE_USER', new User(userResponse.data));
    },

    async searchUsers({ commit }, searchParameters) {
      const params = {
        page: searchParameters.page || 1,
        full_name_contains: searchParameters.userNameContains,
        role: searchParameters.roleId,
        faculty: searchParameters.eraFacultyId,
      };

      commit('UPDATE_PROGRESS', { searching: true });
      try {
        const response = await api.users.search(params);
        const users = User.fromList(response.data.results);
        commit('UPDATE_USERS', {
          users,
          currentPage: searchParameters.page || 1,
          totalResults: response.data.count,
        });
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },

    async getUser({ commit }, userId) {
      const response = await api.users.get(userId);
      const user = new User(response.data);
      commit('UPDATE_USER', user);
    },

    resetUser({ commit }) {
      commit('UPDATE_USER', new User({ is_active: true }));
    },
  },
};
