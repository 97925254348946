import { Validator } from 'vee-validate';

Validator.extend(
  'greater_than',
  (value, [otherValue]) => {
    if (!otherValue) return true;
    return value > otherValue;
  }, {
    hasTarget: true,
  },
);
