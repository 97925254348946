<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <portal to="app-header">
      <div class="row page-title bg-mq-sand">
        <div class="col">Analytics</div>
        <q-btn
          v-if="$config.isFeatureEnabled('whatIfs') && hasPermission($permissions.MANAGE_WHAT_IF)"
          color="primary"
          icon="add"
          label="Create New What-if"
          @click.native="showWhatIfDetail()"
        />
      </div>
      <div class="relative-position">
        <q-tabs
          class="full-width head-bg-transparent border-bottom border-secondary"
          inverted
          color="secondary"
        >
          <q-route-tab
            v-if="$config.isFeatureEnabled('whatIfs') &&
              hasPermission($permissions.MANAGE_WHAT_IF)"
            slot="title"
            name="what-ifs"
            :to="{name: 'what-ifs-draft'}"
            exact
            label="What-ifs"
          />
          <q-route-tab
            v-if="hasPermission($permissions.VIEW_ANALYTICS_VOLUME_MONITORING)"
            slot="title"
            name="citations"
            :to="{name: 'citationsOverview'}"
            exact
            label="Citations Overview"
          />
          <q-route-tab
            v-if="hasPermission($permissions.VIEW_ANALYTICS_VOLUME_MONITORING)"
            slot="title"
            name="peer-review"
            :to="{name: 'peerReviewOverview'}"
            label="Peer Review Overview"
            exact
          />
          <q-route-tab
            v-if="hasPermission($permissions.VIEW_ANALYTICS_PERFORMANCE_METRICS)"
            slot="title"
            name="citation-benchmarks"
            :to="{name: 'citationBenchmarks'}"
            label="Benchmarks"
            exact
          />
        </q-tabs>
      </div>
    </portal>
    <radar-modal v-model="isWhatIfDetailDisplayed">
      <what-if-detail
        ref="whatIfDetail"
        :what-if="whatIf"
        @saved="whatIfSaved"
        @cancel="hideWhatIfDetail"
        @error="$notify.failure"
      />
    </radar-modal>
    <router-view class="q-pa-lg" />
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import WhatIf from '@/models/what-if/what-if';
import RadarModal from '@/components/common/RadarModal.vue';
import WhatIfDetail from '../WhatIfDetail.vue';

export default {
  name: 'AnalyticsOverview',
  components: { WhatIfDetail, RadarModal },
  data() {
    return {
      whatIf: new WhatIf(),
      isWhatIfDetailDisplayed: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['hasPermission', 'canUserManageWhatIf']),
  },
  methods: {
    ...mapActions('whatIfs', ['fetchWhatIfs']),
    async fetchWhatIfsOverview(status) {
      await this.fetchWhatIfs(status);
    },
    resetWhatIfDetail() {
      this.whatIf = new WhatIf();
    },
    async whatIfSaved() {
      this.hideWhatIfDetail();
      await this.fetchWhatIfsOverview('draft');
      this.$router.push({ name: 'what-ifs-draft' });
      this.$notify.success('What If successfully created.');
    },
    showWhatIfDetail() {
      this.$notify.clear();
      this.isWhatIfDetailDisplayed = true;
    },
    hideWhatIfDetail() {
      this.isWhatIfDetailDisplayed = false;
      this.resetWhatIfDetail();
    },
    navigateToChildPage() {
      const childRouteNames = [
        'whatIfsWorkflow', 'citationsOverview', 'peerReviewOverview', 'citationBenchmarks',
      ];
      const matchedNames = this.$route.matched.map(matchedRoute => matchedRoute.name);
      const childRoutesSelected = _.intersection(matchedNames, childRouteNames);
      if (childRoutesSelected.length === 0) {
        this.$router.replace({ name: 'citationsOverview' });
      }
    },
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height, overflowY: 'hidden' };
    },
  },
  watch: {
    $route() {
      this.navigateToChildPage();
    },
  },
  mounted() {
    this.navigateToChildPage();
  },
};
</script>

<style lang="stylus" scoped>
.page-title
  box-shadow 0 1px 8px rgba(0,0,0,0.2), 0 3px 4px rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12)
</style>
