<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 540">
    <g class="spin">
      <circle class="outer-circle" cx="270" cy="270" r="250" />
      <polygon class="outer-arrow" points="290.26 20.01 250.22 0 250.22 20.01 250.22 40.02 290.26 20.01" />
      <polygon class="outer-arrow" points="38.68 173.63 7.27 205.5 26.31 211.69 45.35 217.87 38.68 173.63" />
      <polygon class="outer-arrow" points="107.11 460.25 127.74 499.96 139.5 483.77 151.27 467.58 107.11 460.25" />
      <polygon class="outer-arrow" points="400.98 483.77 445.14 476.43 433.37 460.25 421.61 444.06 400.98 483.77" />
      <polygon class="outer-arrow" points="514.17 211.69 520.84 167.44 501.8 173.63 482.76 179.81 514.17 211.69" />
    </g>
    <circle class="mid-circle" cx="270" cy="270" r="200" />
    <g class="reverse-spin">
      <circle class="inner-circle" cx="270" cy="270" r="150" />
      <polygon class="inner-arrow" points="249.94 120.06 290.06 100 290.06 120.06 290.06 140.12 249.94 120.06" />
      <polygon class="inner-arrow" points="290.06 419.94 249.94 440 249.94 419.94 249.94 399.88 290.06 419.94" />
    </g>
    <g>
      <!-- eslint-disable-next-line vue/max-attributes-per-line -->
      <rect x="91" y="218" width="358" height="104" fill="currentColor" />
      <!-- eslint-disable max-len -->
      <path d="M146.27,260.24a14.57,14.57,0,0,1-1.55,6.66,17.08,17.08,0,0,1-4.21,5.33,18.45,18.45,0,0,1-6,3.42l14.76,19.59-9.72-.08-14-18.57H108.25v18.57h-7.77v-50.4h29.45a16.61,16.61,0,0,1,8.17,2.06,16.22,16.22,0,0,1,5.94,5.54A14.4,14.4,0,0,1,146.27,260.24Zm-18.5-8H108.25V269.1h19.52a10.8,10.8,0,0,0,5.11-1.22,10.52,10.52,0,0,0,3.71-3.24,7.59,7.59,0,0,0,1.4-4.4,6.43,6.43,0,0,0-1.4-4,10.06,10.06,0,0,0-3.71-2.88A11.91,11.91,0,0,0,127.77,252.25Z" />
      <path d="M216.4,295.16l-5.76-11.8H180.76L175,295.16h-8.21l24.62-50.4h9.07L225,295.16Zm-24.63-34.41-7.34,15.12H207l-7.48-15.41c-.68-1.39-1.32-2.72-1.95-4s-1.25-2.65-1.87-4.14q-.93,2.31-1.91,4.32Z" />
      <path d="M270.18,244.76a35,35,0,0,1,12.28,2,25,25,0,0,1,8.85,5.48,22.94,22.94,0,0,1,5.37,8.06,26.41,26.41,0,0,1,1.79,9.68,25.4,25.4,0,0,1-3.2,12.64,23.27,23.27,0,0,1-9.47,9.14q-6.26,3.42-15.62,3.42H244.69v-50.4Zm-.36,42.92q7.2,0,11.66-2.38a15.3,15.3,0,0,0,6.59-6.41A18.81,18.81,0,0,0,290.2,270a18.53,18.53,0,0,0-2.13-8.78,15.73,15.73,0,0,0-6.59-6.48q-4.46-2.44-11.66-2.45H252.47v35.43Z" />
      <path d="M362.48,295.16l-5.76-11.8H326.84l-5.76,11.8h-8.2l24.62-50.4h9.07l24.48,50.4Zm-24.62-34.41-7.35,15.12h22.54l-7.49-15.41-1.94-4q-.94-1.9-1.87-4.14c-.63,1.54-1.26,3-1.91,4.32Z" />
      <path d="M436.57,260.24A14.57,14.57,0,0,1,435,266.9a16.94,16.94,0,0,1-4.21,5.33,18.45,18.45,0,0,1-6.05,3.42l14.76,19.59-9.72-.08-14-18.57H398.55v18.57h-7.77v-50.4h29.45a16.58,16.58,0,0,1,8.17,2.06,16.22,16.22,0,0,1,5.94,5.54A14.32,14.32,0,0,1,436.57,260.24Zm-18.5-8H398.55V269.1h19.52a10.8,10.8,0,0,0,5.11-1.22,10.43,10.43,0,0,0,3.71-3.24,7.59,7.59,0,0,0,1.4-4.4,6.43,6.43,0,0,0-1.4-4,10,10,0,0,0-3.71-2.88A11.91,11.91,0,0,0,418.07,252.25Z" />
      <!-- eslint-enable max-len -->
    </g>
  </svg>
</template>

<script>
export default {
  name: 'RadarLogo',
};
</script>

<style lang="stylus" scoped>
$outer-color = $mq-soft
$inner-color = $mq-magenta

.outer-circle, .mid-circle, .inner-circle
  fill currentColor

.outer-circle, .inner-circle
  stroke-width 8px

.mid-circle
  stroke #80225f
  stroke-width 20px

.outer-circle
  stroke $outer-color

.outer-arrow
  fill $outer-color

.inner-circle
  stroke $inner-color

.inner-arrow
  fill $inner-color

@keyframes rotating
  from
    transform rotate(0deg)
  to
    transform rotate(360deg)

.spin
  animation rotating 7.5s linear infinite

.reverse-spin
  animation rotating 6s linear infinite
  animation-direction reverse

.spin, .reverse-spin
  transform-origin 50%
  animation-play-state paused

.active
  .spin, .reverse-spin
    animation-play-state running
</style>
