import _ from 'lodash';
import api from '@/api';
import config from '@/config';
import {
  ESCALATE_L1_DISPUTE,
  ESCALATE_L2_DISPUTE,
  FINALISE_PORTFOLIO,
  MANAGE_APPLIED_MEASURE_CLAIM,
  MANAGE_ERA,
  MANAGE_PORTFOLIO,
  MANAGE_RESEARCH_INCOME_CLAIM,
  MANAGE_RESEARCH_OUTPUT_CLAIM,
  MANAGE_RESEARCH_STATEMENT,
  MANAGE_RESEARCHER_CLAIM,
  MANAGE_WHAT_IF,
  PROPOSE_WHAT_IF,
  RESOLVE_L1_DISPUTE,
  RESOLVE_L2_DISPUTE,
  UNFINALISE_PORTFOLIO,
  VIEW_XML_SUBMISSION,
  MANAGE_RESEARCHER_PRIMARY_POSITION,
  MANAGE_SENSITIVITY,

} from '@/models/user/access/permissions';
import RolePermission from '@/models/user/access/role-permission';
import User from '@/models/user/user';

const baseUrl = config.apiEndpoints.base;

export default {
  namespaced: true,
  state: {
    user: undefined,
    userAccesses: [],
    rolePermissions: [],
    isInitialised: false,
  },
  mutations: {
    INITIALIZE_USER(state, user) {
      state.user = user;
      state.isInitialised = true;
    },
    UPDATE_ROLE_PERMISSIONS(state, rolePermissions) {
      state.rolePermissions = rolePermissions;
    },
    LOGOUT_USER(state) {
      state.isInitialised = false;
    },
  },
  actions: {
    async login() {
      const loginUrl = `${baseUrl}api/saml_redirect/`;
      window.location.href = loginUrl;
    },
    async logout({ commit }) {
      window.location.href = `${baseUrl}api/logout/`;
      return commit('LOGOUT_USER');
    },
    async initialize({ commit, dispatch }, params) {
      try {
        const eraSubmissionId = params ? params.eraId : null;
        const response = await api.users.getCurrent(eraSubmissionId);
        const user = new User(response.data);
        const rolePermissions = RolePermission
          .fromList(response.data.roles_and_permissions);

        commit('INITIALIZE_USER', user);
        return commit('UPDATE_ROLE_PERMISSIONS', rolePermissions);
      } catch (e) {
        return dispatch('login');
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    isInitialised(state) {
      return state.isInitialised;
    },
    loggedInUser(state) {
      return state.user;
    },
    hasPermission: state => (...actions) => _.some(
      state.rolePermissions,
      rolePermission => rolePermission.hasPermission(actions),
    ),
    roles(state) {
      return state.rolePermissions.map(rolePermission => rolePermission.role);
    },
    userForcs: (state, getters, rootState, rootGetters) => (forcIds) => {
      const forcs = rootGetters['referenceData/forcs'];
      const uniqueForcIds = _.union(...forcIds);
      return forcs.filter(forc => uniqueForcIds.includes(forc.id));
    },
    userForcsForPermissions: (state, getters) => (permissions) => {
      const forcIds = state.rolePermissions
        .map(rolePermission => rolePermission.getForcsForActions(permissions));
      return getters.userForcs(forcIds);
    },
    userFacultiesForPermissions: state => (permissions) => {
      const faculties = _.flatMap(
        state.rolePermissions,
        rolePermission => rolePermission.getFacultiesForActions(permissions),
      );
      return faculties;
    },
    userForcsForRoClaim(state, getters) {
      return getters.userForcsForPermissions([MANAGE_RESEARCH_OUTPUT_CLAIM]);
    },
    userForcsForRoL1Escalations(state, getters) {
      return getters.userForcsForPermissions([ESCALATE_L1_DISPUTE]);
    },
    userForcsForRoL2Escalations(state, getters) {
      return getters.userForcsForPermissions([ESCALATE_L2_DISPUTE]);
    },
    userForcsForRoL1Resolutions(state, getters) {
      return getters.userForcsForPermissions([RESOLVE_L1_DISPUTE]);
    },
    userFacultiesForManageWhatIf(state, getters) {
      return getters.userFacultiesForPermissions([MANAGE_WHAT_IF]);
    },
    clawbackBypassedForcs(state, getters) {
      const forcIds = state.rolePermissions
        .map(rolePermission => rolePermission.getForcsForBypassingClawback());
      return getters.userForcs(forcIds);
    },
    canUserManageEra(state, getters) {
      return getters.hasPermission(MANAGE_ERA);
    },
    userFacultiesForProposeWhatIf(state, getters) {
      return getters.userFacultiesForPermissions([PROPOSE_WHAT_IF]);
    },
    canUserResolveL2(state, getters) {
      return getters.hasPermission(RESOLVE_L2_DISPUTE);
    },
    canUserManageResearcherClaim(state, getters) {
      return getters.hasPermission(MANAGE_RESEARCHER_CLAIM);
    },
    canUserManageResearchOutputClaim(state, getters) {
      return getters.hasPermission(MANAGE_RESEARCH_OUTPUT_CLAIM);
    },
    canUserManageResearchStatement(state, getters) {
      return getters.hasPermission(MANAGE_RESEARCH_STATEMENT);
    },
    canUserManageWhatIf(state, getters) {
      return getters.hasPermission(MANAGE_WHAT_IF);
    },
    canUserProposeWhatIf(state, getters) {
      return getters.hasPermission(PROPOSE_WHAT_IF);
    },
    canUserManageResearchIncomeClaim(state, getters) {
      return getters.hasPermission(MANAGE_RESEARCH_INCOME_CLAIM);
    },
    canUserManageAppliedMeasureClaim(state, getters) {
      return getters.hasPermission(MANAGE_APPLIED_MEASURE_CLAIM);
    },
    canUserManagePortfolio(state, getters) {
      return getters.hasPermission(MANAGE_PORTFOLIO);
    },
    canUserFinalisePortfolio(state, getters) {
      return getters.hasPermission(FINALISE_PORTFOLIO);
    },
    canUserUnfinalisePortfolio(state, getters) {
      return getters.hasPermission(UNFINALISE_PORTFOLIO);
    },
    canUserViewXmlSubmission(state, getters) {
      return getters.hasPermission(VIEW_XML_SUBMISSION);
    },
    canUserManageResearcherPrimaryPosition(state, getters) {
      return getters.hasPermission(MANAGE_RESEARCHER_PRIMARY_POSITION);
    },
    canUserManageSensitivity(state, getters) {
      return getters.hasPermission(MANAGE_SENSITIVITY);
    },
    canUserViewCitationInRO(state) {
      return state.rolePermissions.some(rolePermission => rolePermission.hasViewCitationRole());
    },
  },
};
