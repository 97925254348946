import _ from 'lodash';
import { formatDateTime } from '@/utils/date';
import { getForcsWithClawbacksByJournalAndUserForcs } from '@/utils/forc-utils';
import Forc from '@/models/era/forc';

const ResearchOutputClaimsMixin = {
  computed: {
    isForcPeerReviewEligible() {
      return _.get(
        this.researchOutputClaim.forc, 'is_peer_review_eligible',
        false,
      );
    },
    selectedForcText() {
      return _.get(this.researchOutputClaim.forc, 'text');
    },
    minApportion() {
      return this.researchOutputClaim.isClawback()
        ? this.clawbackMinApportion
        : this.nonClawbackMinApportion;
    },
    isForcWithinUserForcs() {
      return this.researchOutputClaim.forc
        && this.researchOutputClaim.forc.isWithinRangeOfAny(this.userForcs);
    },
    sortedUserApplicableForcs() {
      return Forc.sortAsNumbers(this.userApplicableForcs);
    },
    userApplicableForcs() {
      return getForcsWithClawbacksByJournalAndUserForcs(
        this.forcs,
        this.userForcs,
        this.journalForcs,
        this.isMultiDisciplinary,
        this.clawbackBypassedForcs,
      );
    },
  },
  methods: {
    formatDateTime,
    async saveClaim() {
      const result = await this.$validator.validateAll();
      if (!result) return;
      await this.$emit('save', this.researchOutputClaim);
    },
    async deleteClaim() {
      this.$emit('delete', this.researchOutputClaim);
    },
    async reset() {
      this.$validator.reset();
    },
  },
};

export default ResearchOutputClaimsMixin;
