import Portfolio from '@/components/portfolio/Portfolio.vue';
import PortfolioHome from '@/components/portfolio/PortfolioHome.vue';
import PortfolioSearchAndList from '@/components/portfolio/search/PortfolioSearchAndList.vue';
import PortfolioDetails from '@/components/portfolio/details/PortfolioDetails.vue';
import PortfolioOverview from '@/components/portfolio/details/PortfolioOverview.vue';
import PortfolioResearchStatement from '@/components/portfolio/details/PortfolioResearchStatement.vue';
import PortfolioHistory from '@/components/portfolio/details/PortfolioHistory.vue';

const portfolioRoutes = {
  path: 'portfolios',
  component: Portfolio,
  props: true,
  children: [
    {
      path: '',
      name: 'portfolio-home',
      component: PortfolioHome,
      meta: {
        activeRoute: 'portfolios',
      },
    }, {
      path: 'assign',
      name: 'portfolios',
      component: PortfolioSearchAndList,
      meta: {
        activeRoute: 'portfolios',
      },
    }, {
      path: ':portfolioId',
      component: PortfolioOverview,
      props: true,
      meta: {
        activeRoute: 'portfolios',
      },
      children: [
        {
          path: '',
          name: 'portfolio-details',
          component: PortfolioDetails,
          props: true,
          meta: {
            activeRoute: 'portfolios',
          },
        }, {
          path: 'research-statement',
          name: 'portfolio-research-statement',
          component: PortfolioResearchStatement,
          props: true,
          meta: {
            activeRoute: 'portfolios',
          },
        }, {
          path: 'history',
          name: 'portfolio-history',
          component: PortfolioHistory,
          props: true,
          meta: {
            activeRoute: 'portfolios',
          },
        },
      ],
    },
  ],
};

export default portfolioRoutes;
