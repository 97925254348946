<template>
  <div padding class="upload-container">
    <div class="row col-11">
      <q-btn
        class="q-ml-md no-shadow btn-summary"
        color="mq-sand-dark"
        text-color="mq-dark"
        icon="list_alt"
        label="Research Income totals by year summary"
        @click="showSummary()"
      />

      <radar-modal v-model="isSummaryOpen">
        <div slot="modal-definition">Income Totals by Year</div>
        <q-table
          class="xml-summary-table"
          :data="totalsSummary"
          :columns="summaryCols"
          :rows-per-page-options="[0]"
          :pagination.sync="pagination"
          :visible-columns="['type', 'year', 'total']"
          separator="cell"
          hide-bottom
        />
      </radar-modal>
    </div>

    <div class="row col-11">
      <div class="text-container">
        <p>The RADAR system has been built to support the upload of both Research Income and Commercialisation Income. </p>

        <p>
          You will need to prepare a CSV file, with the specifications for each field as well as more information
          about the process detailed in the following document:
        </p>
        <!-- eslint-disable max-len -->
        <p><a href="https://wiki.mq.edu.au/download/attachments/317949940/Research%20Income%20-%20Process%20and%20specs-FINAL.docx?version=2&modificationDate=1622604450240&api=v2" target="_blank">Research Income - Process and specs-FINAL.docx</a></p>

        <p>
          <strong>Please note:</strong> When you load a research income file with any data record from a certain year:
          for example, 2017 –RADAR will then remove all existing records of that income type from 2017, and only load what
          is in the new file. As such, it is built to load an entire year (or multiple years) worth of research income data at a time, and this will replace any previous data loaded for the years included in the new file.
        </p>
      </div>
    </div>

    <div class="row col-11 q-ma-lg q-pa-lg bg-mq-neutral border shadow-1 justify-around">
      <q-field
        label="Income Type"
        label-width="4"
        class="col-5"
        :error="errors.has('income-type')"
        error-label="Income Type is required"
      >
        <q-select
          v-model="incomeType"
          v-validate="'required'"
          :options="incomeTypeOptions"
          name="income-type"
        />
      </q-field>

      <q-field
        class="col-5"
        icon="monetization_on"
        helper="Upload csv file"
        :error="error"
        error-label="There was an error processing file. View downloaded error_file.csv."
      >
        <q-uploader
          url=""
          extensions=".csv"
          auto-expand
          hide-upload-progress
          :upload-factory="uploadFile"
          :error="error"
        />
      </q-field>
    </div>
    <p class="q-mt-lg q-mb-none caption">Recent Uploads</p>
    <div class="col-grow q-py-sm q-px-lg new-sticky-header-table">
      <q-table
        table-class="bg-white"
        :data="researchIncomeFileHistory"
        :columns="columns"
        row-key="name"
        color="primary"
        hide-bottom
      >
        <q-td slot="body-cell-csv" slot-scope="props" :props="props">
          <q-btn color="primary" icon="cloud_download" @click="download(props.row)" />
        </q-td>
      </q-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import FileSaver from 'file-saver';
import { formatDateTime } from '@/utils/date';
import RadarModal from '@/components/common/RadarModal.vue';
import { formatCurrency } from '@/utils/currency';

export default {
  name: 'ResearchIncomeUpload',
  components: {
    RadarModal,
  },
  data() {
    return {
      error: false,
      incomeType: undefined,
      isSummaryOpen: false,
      summaryCols: [
        { name: 'type', label: 'Type', field: 'type', align: 'center' },
        { name: 'year', label: 'Year', field: 'year', align: 'center' },
        { name: 'total', label: 'Total Income', field: 'total', format: formatCurrency },
      ],
      pagination: {
        page: 0,
        rowsPerPage: 0,
      },
      columns: [
        {
          name: 'created_by',
          required: true,
          label: 'Owner',
          align: 'left',
          field: row => this.getUserDisplayName(row.created_by),
        },
        {
          name: 'date',
          required: true,
          label: 'Date',
          align: 'left',
          field: row => this.formatDateTime(row.updated_at),
        },
        {
          name: 'status', required: true, label: 'Status', align: 'left', field: 'status',
        },
        {
          name: 'type',
          required: true,
          label: 'Income Type',
          align: 'left',
          field: row => this.getIncomeType(row.isCommercialIncome),
        },
        {
          name: 'csv',
          required: true,
          label: 'CSV File',
          align: 'left',
          field: 'name',
        },
      ],
    };
  },
  computed: {
    ...mapState('researchIncomeFile', {
      types: 'types',
      researchIncomeFileHistory: state => state.researchIncomeFileHistory,
    }),
    ...mapState('researchIncomeFile', [
      'totalsSummary',
    ]),
    ...mapGetters('researchIncomeFile', ['downloadHistoryFileUrl']),
    incomeTypeOptions() {
      return this.types.map(type => ({ label: type.split(':')[1], value: type.split(':')[0] }));
    },
  },
  methods: {
    ...mapActions(
      'researchIncomeFile',
      ['uploadResearchIncomeFile', 'fetchResearchIncomeUploadHistory', 'downloadResearchIncomeHistoryFile', 'getSummary'],
    ),
    formatDateTime,
    getUserDisplayName(user) {
      return user.displayName();
    },
    getIncomeType(isCommercialIncome) {
      const incomeType = this.incomeTypeOptions.filter(type => type.value === isCommercialIncome.toString());
      return incomeType[0].label;
    },
    async download(csv) {
      try {
        const fileData = await this.downloadResearchIncomeHistoryFile({ researchIncomeId: csv.id });
        const fileName = csv.isCommercialIncome ? 'commercial_income_file' : 'research_income_file';
        const uploadedDate = moment(csv.created_at).format('DDMMYYYY-HHmm');
        FileSaver.saveAs(new Blob([fileData]), `${fileName}-${uploadedDate}.csv`, { type: 'text/csv' });
        this.$notify.success('File successfully downloaded.');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async uploadFile(file) {
      try {
        this.error = false;
        await this.$validator.validateAll();
        if (this.errors.any()) return;

        await this.uploadResearchIncomeFile({ file, isCommercialIncome: this.incomeType });
        await this.displayResearchUploadHistory();
        this.$notify.success('File successfully uploaded.');
      } catch (error) {
        this.error = true;
        if (error.response) {
          const blob = new Blob([error.response.data]);
          FileSaver.saveAs(blob, 'error_file.csv');
        }
        throw error;
      }
    },
    async showSummary() {
      await this.getSummary();
      this.isSummaryOpen = true;
    },
    async displayResearchUploadHistory() {
      await this.fetchResearchIncomeUploadHistory();
    },
  },
  mounted() {
    this.displayResearchUploadHistory();
  },
};
</script>

<style lang="stylus" scoped>
.upload-container
  flex 1
  display flex
  flex-direction column
  text-align center

.q-field
  @extend $body-text

  >>> .q-field-label-inner
    justify-content flex-end
    text-align right

.xml-summary-table
  >>> th
    @extend $table-header-text
    background-color $mq-sand-dark

.btn-summary
   margin-top: 15px

.text-container
   padding:20px;
   text-align:left;
   font-size: 13px;
</style>
