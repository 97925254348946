<template>
  <div ref="tableWrapper" class="full-width relative-position">
    <q-table
      ref="tableWithData"
      :data="researchOutputsForWhatIf"
      table-class="sticky-header-table bg-white q-mr-sm"
      class="what-if-scenario-review-table"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="[0]"
      binary-state-sort
      no-data-label="No data found"
      :pagination.sync="pagination"
      no-results-label="No data matches your filter"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <div slot="top" slot-scope="props" class="fit">
        <div class="row items-center r-p-top">
          <div class="col">
            <div class="page-title q-pl-sm">
              <span class="text-secondary">{{ whatIf.title }}</span>
            </div>
          </div>
          <div class="col-auto group q-pr-sm page-title">
            <what-if-scenario-workflow :what-if="whatIf" />
          </div>
        </div>
      </div>
      <q-tr slot="header" slot-scope="props">
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
          :class="col.classes"
          :style="col.style"
        >
          <q-checkbox
            v-if="col.name==='title'"
            v-model="allExpandMode"
            color="primary"
            checked-icon="keyboard_arrow_down"
            unchecked-icon="chevron_right"
            keep-color
            @input="setAllExpandedSlots"
          />
          {{ col.label }}
        </q-th>
      </q-tr>
      <template slot="body" slot-scope="props">
        <q-tr :props="props" class="vertical-top">
          <q-td
            key="title"
            :props="props"
            :style="props.colsMap.title.style"
            :class="props.colsMap.title.classes"
          >
            <div class="row items-start">
              <q-checkbox
                v-model="expansionSlots[props.row.id]"
                color="primary"
                checked-icon="keyboard_arrow_down"
                unchecked-icon="chevron_right"
                keep-color
              />
              <what-if-research-output-metadata-cell
                class="col"
                :expanded="isExpanded(props.row.id)"
                :research-output-for-what-if="props.row"
              />
            </div>
          </q-td>
          <q-td
            key="arc"
            :props="props"
            :style="props.colsMap.arc.style"
            :class="props.colsMap.arc.classes"
          >
            <div v-for="forc in props.row.getJournalForcs()" :key="forc.id">
              {{ forc.text }}
            </div>
          </q-td>
          <q-td
            key="claim1"
            :props="props"
            :style="props.colsMap.claim1.style"
            :class="props.colsMap.claim1.classes"
          >
            <what-if-claim-cell-read-only
              :citation-benchmarks="citationBenchmarks"
              :research-output="props.row"
              :claim="props.row.ro_claims[0]"
              :previous-claim="props.row.existing_claims[0]"
              :show-previous-claim="isExpanded(props.row.id)"
            />
          </q-td>
          <q-td
            key="claim2"
            :props="props"
            :style="props.colsMap.claim2.style"
            :class="props.colsMap.claim2.classes"
          >
            <what-if-claim-cell-read-only
              :citation-benchmarks="citationBenchmarks"
              :research-output="props.row"
              :claim="props.row.ro_claims[1]"
              :previous-claim="props.row.existing_claims[1]"
              :show-previous-claim="isExpanded(props.row.id)"
            />
          </q-td>
          <q-td
            key="claim3"
            :props="props"
            :style="props.colsMap.claim3.style"
            :class="props.colsMap.claim3.classes"
          >
            <what-if-claim-cell-read-only
              :citation-benchmarks="citationBenchmarks"
              :research-output="props.row"
              :claim="props.row.ro_claims[2]"
              :previous-claim="props.row.existing_claims[2]"
              :show-previous-claim="isExpanded(props.row.id)"
            />
          </q-td>
          <q-td
            key="validations"
            :style="props.colsMap.validations.style"
            :class="props.colsMap.validations.classes"
          >
            <div class="validation-field">
              <q-btn
                v-if="props.row.isValid()"
                round
                dense
                flat
                size="16px"
                color="positive"
                icon="check_circle"
                class="no-pointer-events"
              />
              <q-btn
                v-if="props.row.isInvalid()"
                round
                dense
                flat
                size="16px"
                color="negative"
                icon="warning"
                @click="toggleExpanded(props.row.id)"
              />
            </div>
          </q-td>
        </q-tr>
        <q-tr
          v-if="isExpanded(props.row.id) && props.row.isInvalid()"
          :props="props"
          class="bg-white"
          style="margin-top: -1px"
        >
          <q-td colauto />
          <q-td colspan="100%" class="text-left extra-info-col" />
          <q-td class="bulk-error-wrapper bg-mq-sand">
            <div
              class="q-pa-md q-mx-sm border border-primary round-borders bg-white bulk-error-wrapper-offset"
            >
              <grid-validation-errors :validation="props.row.validation" />
            </div>
          </q-td>
        </q-tr>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <div slot="bottom" slot-scope="props" class="row flex-center fit">
        <pagination-total-count
          slot="paginationTotal"
          class="q-ma-sm text-primary col-12 text-right"
          :current-page="searchPagination.currentPage"
          :page-size="searchPagination.pageSize"
          :total-results="searchPagination.totalResults"
          @change="fetchPage"
        />
      </div>
    </q-table>
    <radar-loading :visible="progress.searching" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { booleanPromise } from '@/utils/promise-util';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import RadarLoading from '@/components/common/RadarLoading.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import WhatIfResearchOutputMetadataCell from '../common/WhatIfResearchOutputMetadataCell.vue';
import { whatIfReviewFields } from './what-if-review-fields';
import WhatIfClaimCellReadOnly from './WhatIfClaimCellReadOnly.vue';
import WhatIfScenarioWorkflow from './WhatIfScenarioWorkflow.vue';

export default {
  name: 'WhatIfResearchOutputList',
  components: {
    RadarLoading,
    GridValidationErrors,
    WhatIfScenarioWorkflow,
    WhatIfClaimCellReadOnly,
    WhatIfResearchOutputMetadataCell,
    PaginationTotalCount,
  },
  mixins: [AllFieldsExpandableTable],
  data() {
    return {
      rowUnderEdit: undefined,
      columns: whatIfReviewFields,
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
    };
  },
  computed: {
    ...mapState('whatIfScenario', {
      researchOutputsForWhatIf: 'researchOutputsForWhatIf',
      searchPagination: 'searchPagination',
      whatIf: 'whatIf',
      progress: 'progress',
    }),
    ...mapState('analytics', { citationBenchmarks: 'citationBenchmarks' }),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsForWorkingEra']),
    ...mapGetters('auth', ['userForcsForRoClaim', 'clawbackBypassedForcs']),
    whatIfId() {
      return this.$route.params.whatIfId;
    },
    expansionSlotDataItems() {
      return this.researchOutputsForWhatIf;
    },
  },
  methods: {
    ...mapActions('whatIfScenario', [
      'fetchWhatIf', 'fetchForcPerformanceComparisons',
    ]),
    notifyNoChangedResearchOutputs(notificationEnabled) {
      if (notificationEnabled) {
        return booleanPromise(this.$q.dialog({
          title: 'What if scenario testing',
          message: 'No changes.',
          color: 'negative',
          ok: false,
          cancel: 'OK',
        }));
      }
      return Promise.resolve();
    },
    fetchPage(page = 1) {
      this.$emit('fetchPage', page);
    },
  },
  watch: {
    researchOutputsForWhatIf() {
      this.selected = [];
      this.researchOutputsForWhatIf.forEach(ro => ro.validate(this.clawbackBypassedForcs, this.testClaimAddToHundred));
      this.setAllExpandedSlots(this.allExpandMode);
    },
    whatIfId() {
      this.fetchPage(1);
    },
  },
  created() {
    this.fetchPage(1);
  },
};

</script>
<style lang="stylus" scoped>
  .authors-wrapper
    min-height 2rem
    max-width 1px
    height 100%
    overflow hidden
    word-wrap break-word
    border 1px solid yellow

  .bulk-error-wrapper
    width 517px

  .bulk-error-wrapper-offset
    margin-right 37px

  .validation-field
    margin-top 26px
</style>
