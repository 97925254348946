<template>
  <div class="row justify-center relative-position">
    <div class="col-12 col-lg-11">
      <q-table
        :columns="columns"
        :data="citationBenchmarks.benchmark_forcs"
        class="analytics-overview-table-with-footer no-shadow bg-white q-pa-sm"
        binary-state-sort
        :filter="filter"
        :filter-method="filterForcBeginsWith"
        :pagination.sync="pagination"
        table-class="sticky-header-table"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="top" slot-scope="props">
          <div class="row full-width items-center">
            <div class="col-4 col-xl-2">
              <q-search
                v-model.trim="filter"
                placeholder="Filter FoRC"
                icon="mdi-filter-outline"
                class="text-black q-mt-sm q-mb-sm no-shadow filter-bar-dense"
                color="white"
                inverted
              />
            </div>
            <div class="col text-center text-secondary all-caps">
              Reference period benchmarks
            </div>
          </div>
        </template>
        <q-tr slot="body" slot-scope="props" :props="props">
          <q-td
            key="forc"
            :props="props"
            class="bg-white"
            :class="props.colsMap.forc.classes"
            :style="props.colsMap.forc.style"
          >
            <span class="text-weight-bold"> {{ getForcById(props.row.forc_id).text }} </span>
            <span class="on-right">{{ getForcById(props.row.forc_id).description }}</span>
          </q-td>
          <template v-for="(column, index) in yearColumns" :props="props">
            <q-td
              v-if="column.name"
              :key="column.name"
              :props="props"
              :class="{
                'border-mq-grey-dark': index%2===0,
                'bg-mq-neutral':index%2===0,
                'bg-white': index%2!==0
              }"
              :style="props.colsMap[column.name].style"
            >
              {{ column.field(props.row) }}
            </q-td>
          </template>
        </q-tr>
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <div slot="bottom" slot-scope="props" class="row flex-center fit text-mq-bright-red-soft">
          * {{ footnote }}
        </div>
      </q-table>
    </div>
    <radar-loading :visible="fetchingCitationBenchmarks" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { tableForcFieldSearchStartWithCodeOrContainsDescription } from '@/utils/quasar-utils';
import RadarLoading from '@/components/common/RadarLoading.vue';

export default {
  name: 'CitationBenchmark',
  components: { RadarLoading },
  data() {
    return {
      filter: '',
      pagination: {
        sortBy: 'forc',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      defaultCitationColumns: [
        {
          name: 'forc',
          label: 'FoRC',
          align: 'left',
          sortable: true,
          style: 'width: 20%',
          field: citationBenchmarkForForc => this.getForcById(citationBenchmarkForForc.forc_id),
        },
      ],
    };
  },
  computed: {
    ...mapState('eras', ['workingEra']),
    ...mapState('analytics', {
      citationBenchmarks: 'citationBenchmarks',
      fetchingCitationBenchmarks: state => state.progress.fetchingCitationBenchmarks,
    }),
    ...mapGetters('referenceData', ['forcs']),
    columns() {
      return [...this.defaultCitationColumns, ...this.yearColumns];
    },
    yearColumns() {
      const years = this.citationBenchmarks.getYearsSorted();

      return years.map((year, index) => ({
        name: `year${year}`,
        label: year,
        classes: ['bg-mq-soft-muted', 'text-white'],
        style: index < this.workingEra.benchmark_years_hidden ? 'display: none' : null,
        align: 'center',
        sortable: true,
        field: benchmarkForc => benchmarkForc.getRciFor(year),
      }));
    },
    footnote() {
      return this.workingEra.benchmark_footnote || 'Information contained here is confidential. Please do not reshare. Benchmarks are approximations only.';
    },
  },
  methods: {
    ...mapActions('analytics', ['fetchCitationBenchmarks']),
    getForcById(id) {
      return this.forcs.find(forc => forc.id === id) || { text: `Unknown FoRC Id: ${id}`, description: '' };
    },
    filterForcBeginsWith(rows, terms, cols, cellValue) {
      return tableForcFieldSearchStartWithCodeOrContainsDescription(
        rows,
        terms,
        cols,
        cellValue,
        'forc',
      );
    },
  },
  created() {
    this.fetchCitationBenchmarks();
  },
};
</script>
<style lang="stylus" scoped>

  .forc-filter
    width 20%

  tr
    :not(:last-child)
      border-right-color rgba(0, 0, 0, .1)
      border-right-width 1px
      border-right-style solid

</style>
