import Vue from 'vue';
import Vuex from 'vuex';
import analytics from './analytics/analytics';
import whatIfScenario from './analytics/what-if-scenario';
import whatIfs from './analytics/what-ifs';
import auth from './auth';
import bulkCode from './research-output/bulk-code';
import eraFacultyForcs from './era/era-faculty-forcs';
import eras from './era/eras';
import organisationEntities from './organisation-entities';
import referenceData from './era/reference-data';
import fundings from './research-income/fundings';
import reporting from './reporting/reporting';
import researchIncomeFile from './research-income/research-income-file';
import researchOutputClaimEvents from './research-output/research-output-claim-events';
import researchOutputs from './research-output/research-outputs';
import researchStatements from './research-output/research-statements';
import sensitivityTypes from './research-output/sensitivityTypes';
import researchers from './researcher/researchers';
import userAccess from './user/user-access';
import users from './user/users';
import appliedMeasures from './applied-measure/applied-measures';
import portfolios from './portfolio/portfolios';
import dataQuality from './data-quality/data-quality';
import xml from './xml/xml';
import radarSettings from './radar-settings/radar-settings';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async initialize({ dispatch, state }, eraId) {
      const currentWorkingEra = state.eras.workingEra;
      const selectedEraId = Number(eraId) || currentWorkingEra.id;

      const isFirstLoad = currentWorkingEra.isEmpty();
      const hasEraChanged = !isFirstLoad
        && (selectedEraId !== currentWorkingEra.id);

      if (isFirstLoad) {
        await Promise.all([
          dispatch('eras/fetchEraAndUpdateWorkingEra'),
          dispatch('organisationEntities/initialize'),
          dispatch('referenceData/prepareAllReferenceData'),
        ]);
      }

      if (hasEraChanged) {
        await dispatch('eras/fetchEraAndUpdateWorkingEra', selectedEraId);
      }
    },
  },
  modules: {
    eras,
    auth,
    organisationEntities,
    researchers,
    users,
    referenceData,
    eraFacultyForcs,
    userAccess,
    researchOutputs,
    researchOutputClaimEvents,
    researchStatements,
    reporting,
    bulkCode,
    whatIfs,
    whatIfScenario,
    analytics,
    researchIncomeFile,
    fundings,
    appliedMeasures,
    portfolios,
    dataQuality,
    xml,
    radarSettings,
    sensitivityTypes,
  },
});

export default store;
