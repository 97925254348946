import _ from 'lodash';

class ResearcherStatistics {
  constructor(researcherStatistics = {}) {
    this.herdc_counts = researcherStatistics.herdc_counts || {};
    this.eligible_herdc_counts = researcherStatistics.eligible_herdc_counts || {};
    this.appliedmeasure_counts = researcherStatistics.appliedmeasure_counts || {};
    this.research_output_count = researcherStatistics.research_output_count;
    this.eligible_research_output_count = researcherStatistics.eligible_research_output_count;
  }

  herdcCountsAsPairs() {
    return _.sortBy(
      _.toPairs(this.herdc_counts),
      // eslint-disable-next-line no-unused-vars
      ([category, count]) => category,
    );
  }

  eligibleHerdcCountsAsPairs() {
    return _.sortBy(
      _.toPairs(this.eligible_herdc_counts),
      // eslint-disable-next-line no-unused-vars
      ([category, count]) => category,
    );
  }

  hasHerdcCategoryStatistics() {
    return !_.isEmpty(this.herdc_counts);
  }

  appliedMeasureTotal() {
    return _.sumBy(
      _.toPairs(this.appliedmeasure_counts),
      // eslint-disable-next-line no-unused-vars
      ([category, count]) => count,
    );
  }

  hasAppliedMeasureStatistics() {
    return !_.isEmpty(this.appliedmeasure_counts);
  }
}

export default ResearcherStatistics;
