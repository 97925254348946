<template>
  <div class="full-width relative-position scrollable-section">
    <div class="claim-section">
      <form class="claim-container" @submit.prevent="updateClaims()">
        <div class="row items-end justify-between">
          <div v-for="index in [0, 1, 2]" :key="index" class="portfolio-claim">
            <label>Portfolio Claim {{ index + 1 }}</label>
            <claim-editor
              v-model="portfolioClaims.claims[index]"
              :forc-list="fourDigitForcsForWorkingEra"
              :edit-mode="editMode"
              :include-peer-review="true"
              @blur="validateClaims()"
            />
          </div>
        </div>
        <div v-if="editMode" class="row q-mt-lg">
          <q-field class="col-12">
            <q-input
              v-model.trim="portfolioClaims.comment"
              name="comment"
              type="textarea"
              class="border bg-white comfortable-textarea"
              hide-underline
              placeholder="Write a comment"
              maxlength="1000"
              @blur="validateClaims()"
            />
          </q-field>
          <div
            v-if="validation.isInvalid()"
            class="col-12 q-pa-md q-mt-lg border border-primary round-borders bg-white text-left"
          >
            <grid-validation-errors :validation="validation" />
          </div>
          <div class="col-12 q-mt-lg">
            <span v-if="progress.saving">Saving...</span>
            <template v-else>
              <q-btn
                type="button"
                color="blue-grey-5"
                @click="cancelClaims()"
              >
                Cancel
              </q-btn>
              <q-btn
                class="q-ml-md"
                type="submit"
                color="primary"
              >
                Save
              </q-btn>
            </template>
          </div>
        </div>
        <div v-if="portfolio.roCount === 0" class="col-12 q-mt-lg">
          <div class="bg-white q-pa-lg round-borders border border-mq-magenta flex items-center">
            <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
            <span>Assign research outputs to this portfolio to activate coding / finalising it.</span>
          </div>
        </div>
        <div v-if="portfolio.isFinalised()" class="col-12 q-mt-lg">
          <div class="bg-white q-pa-lg round-borders border border-mq-magenta flex items-center">
            <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
            <span>This portfolio is finalised and is no longer editable</span>
          </div>
        </div>
      </form>
      <q-btn
        v-if="canUserManagePortfolio && !editMode && !portfolio.isFinalised() && portfolio.roCount > 0"
        type="button"
        class="claim-edit-btn no-shadow"
        color="blue-grey-5"
        icon="edit"
        size="md"
        @click="editClaims()"
      />
    </div>
    <portfolio-list :portfolio-options="portfolioOptions" @change="refreshResearchOutputs()">
      <div slot="bottom" class="row justify-between fit">
        <div class="col text-right">
          <pagination-total-count
            slot="paginationTotal"
            class="q-ma-sm text-primary col-12 text-right"
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            :is-in-edit-mode="editMode"
            @abort="checkForUnsaveChanges"
          />
        </div>
      </div>
    </portfolio-list>
    <save-dialog ref="saveDialog" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  validateAddsToHundred,
  validateDuplicateClaims,
} from '@/utils/generic-claims-validator';
import Validation from '@/models/validation';
import ClaimVersion from '@/models/era/claim-version';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import SaveDialog from '@/components/common/SaveDialog.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import ClaimEditor from '@/components/claim/ClaimEditor.vue';
import PortfolioList from '../search/PortfolioList.vue';

const newClaims = new ClaimVersion();
newClaims.addEmptyClaims();

export default {
  name: 'PortfolioDetails',
  components: {
    PortfolioList,
    SaveDialog,
    PaginationTotalCount,
    GridValidationErrors,
    ClaimEditor,
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      editMode: false,
      portfolioClaims: newClaims,
      validation: new Validation(),
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolio', 'portfolios', 'progress']),
    ...mapState('researchOutputs', ['pagination']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['fourDigitForcsForWorkingEra']),
    ...mapGetters('portfolios', ['portfolioOptions', 'allPortfolioOptions']),
    currentPage() {
      return this.pagination.currentPage;
    },
    totalPages() {
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
  },
  methods: {
    ...mapActions('portfolios', ['addPortfolio', 'fetchPortfolio', 'fetchPortfolios', 'saveClaims']),
    ...mapActions('researchOutputs', ['searchResearchOutputs']),
    checkForUnsaveChanges(next) {
      this.$refs.saveDialog.show({
        save: this.updateClaims,
        dontSave: this.cancelClaims,
        hasUnsaved: () => this.editMode,
        next,
      });
    },
    confirmPageExit(e) {
      const evt = e;
      if (this.editMode) {
        evt.preventDefault();
        evt.returnValue = '';
      }
    },
    refreshResearchOutputs() {
      this.searchResearchOutputs({ portfolioId: this.portfolio.id, eraId: this.workingEra.id, excludeLoadingFirstRecord: true });
    },
    copyClaims() {
      this.portfolioClaims = _.cloneDeep(this.portfolio.claimVersion);
    },
    editClaims() {
      this.portfolioClaims.addEmptyClaims();
      this.portfolioClaims.comment = '';
      this.validation.clearErrors();
      this.editMode = true;
    },
    cancelClaims() {
      this.copyClaims();
      this.editMode = false;
    },
    async updateClaims() {
      if (this.validateClaims()) {
        const params = Object.assign(this.portfolioClaims, { portfolioId: this.portfolio.id });
        try {
          this.portfolioClaims.removeEmptyClaims();
          if (this.portfolioClaims.comment || !this.portfolioClaims.hasClaimsSameAs(this.portfolio.claimVersion)) {
            await this.saveClaims(params);
            await this.refreshResearchOutputs();
            let msg = 'Portfolio claims saved.';
            if (this.portfolioClaims.hasPeerReviewClaim() && !this.portfolio.researchStatement) {
              msg += ' A Research Statement is required for Peer Reviewed claims';
            }
            this.$notify.success(msg);
          }
          this.editMode = false;
        } catch (e) {
          this.portfolioClaims.addEmptyClaims();
          this.$notify.failure(e);
        }
      }
    },
    validateClaims() {
      const missingValues = this.portfolioClaims.claims.map((claim, index) => {
        if (!claim.forc.four_digit_code && claim.apportion) {
          return `Claim ${index + 1}: The FoRC code is missing`;
        } if (claim.forc.four_digit_code && !claim.apportion) {
          return `Claim ${index + 1}: The apportion is missing`;
        } if (claim.isPeerReviewed && !(claim.forc.four_digit_code && claim.apportion)) {
          return `Claim ${index + 1}: Cannot peer review an incomplete FoRC`;
        }
        return null;
      });

      const claims = this.portfolioClaims.claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
      const noClaims = claims.length === 0 ? 'You must have at least one claim present' : null;
      const duplicateClaimsError = validateDuplicateClaims(claims);
      const addsToHundredError = validateAddsToHundred(claims, true);

      this.validation.clearErrors();
      this.validation.errors.push(...missingValues);
      this.validation.addErrors(noClaims, duplicateClaimsError, addsToHundredError);

      return !this.validation.hasErrors();
    },
  },
  created() {
    this.copyClaims();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmPageExit);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.confirmPageExit);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.checkForUnsaveChanges(next);
  },
};
</script>

<style lang="stylus" scoped>
.claim-section
  display flex
  padding 1.5rem
  background-color $mq-neutral
  overflow-x auto

.claim-container
  flex 1 1 800px
  min-width 600px
  max-width 800px

.portfolio-claim
  flex 1 1 30%
  max-width 240px

  > label
    @extend $term-title
    display block
    margin-bottom 5px
    text-transform uppercase

  & + &
    margin-left 10px

.claim-edit-btn
  width 32px
  height 32px
  margin-left 15px
  margin-top 20px
</style>
