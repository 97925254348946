<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="Interdisciplinary Profile" :show-buttons="true">
      <report-table
        slot="table"
        :data="profileTable"
        :columns="profileCols"
        row-key="forc"
      />
      <apexchart
        slot="graph"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="chartSeries"
      />
    </report-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatFloat, formatPercent, div } from '@/utils/formatter';
import VueApexCharts from 'vue-apexcharts';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptInterdisciplinaryProfile',
  components: {
    Apexchart: VueApexCharts,
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      profileCols: [
        { label: 'FoRC', field: 'fourDigitCode' },
        { label: 'Name', field: 'description', align: 'left' },
        { label: 'Count', field: 'weightedCount', format: formatFloat(2) },
        { label: '%', field: 'percent', format: formatPercent(0) },
      ],
    };
  },
  computed: {
    ...mapState('reporting', {
      profileData: 'interDisciplinaryProfileData',
    }),
    profileTotalCount() {
      return this.profileData.reduce((a, b) => a + b.weightedCount, 0);
    },
    profileTable() {
      return this.profileData.map(row => ({ ...row, percent: div(row.weightedCount, this.profileTotalCount) }));
    },
    chartSeries() {
      return [{
        name: 'Percentage',
        data: this.profileTable.map(row => row.percent),
      }];
    },
    chartOptions() {
      return {
        title: {
          text: 'Interdisciplinary Profile',
          align: 'center',
        },
        chart: {
          id: 'interdisciplinary-profile',
        },
        labels: this.profileTable.map(r => r.fourDigitCode),
        colors: [
          ({ value }) => (value > 0.02 ? '#00f' : '#f00'),
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          distributed: true,
          formatter: formatPercent(1),
          style: {
            colors: ['rgba(0,0,0,.5)'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
          },
        },
        yaxis: {
          labels: {
            formatter: formatPercent(0),
          },
        },
        tooltip: {
          y: {
            formatter: formatPercent(2),
          },
        },
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
