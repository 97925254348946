import { useHttp } from './http';

const http = useHttp('income');
const headers = { 'Content-Type': 'text/csv' };

export default {
  search: params => http.get('', { params }),
  get: id => http.get(id),
  update: (id, income) => http.put(id, income),
  saveClaims: claims => http.post('bulk_claim', claims),
  fileUpload: (file, isCommercial) => http.put('upload', file, { headers, params: { is_commercial_income: isCommercial } }),
  fileDownload: (isCommercial, year) => http.get('download', { params: { is_commercial_income: isCommercial, year } }),
  getFileUploadHistory: () => http.get('upload/history'),
  downloadFileUploadHistory: pk => http.get(['upload/history', pk]),
  getSummary: isCommercial => http.get('stats', { params: { is_commercial_income: isCommercial } }),
};
