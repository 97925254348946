<template>
  <div class="card row items-start q-mb-lg q-px-lg relative-position full-width">
    <div class="col-xs-12 col-xl-10 body">
      <div v-if="!workingEra.isWhatIfStage()" class="bg-warning wrap-text quote">
        NOTE: This ERA submission is not in the What Ifs stage.
        Cross-Faculty What Ifs can only be proposed during the What If stage.
      </div>
    </div>
    <q-list separator class="bg-white no-padding col-xs-12 col-xl-10">
      <q-item v-if="!sortedWhatIfs.length">
        No what ifs for the selected type
      </q-item>
      <q-item
        v-for="(whatIf, index) in sortedWhatIfs"
        :key="'whatIf-'+whatIf.id +'-'+ index"
        multiline
        class="no-padding row items-stretch"
      >
        <div class="col-xs-12 col-md-6 full-height q-pa-xl">
          <div class="r-heading-3 q-pb-md">
            <div>
              <router-link
                :to="{
                  name: canUserEditWhatIf(whatIf)? 'what-if-edit': 'what-if-review',
                  params:{whatIfId: whatIf.id},
                  query: { impactedOnly: !!whatIf.researchOutputCount }
                }"
                class="text-mq-highlight"
              >
                {{ whatIf.title }}
              </router-link>
            </div>
          </div>
          <div class="term-title-small q-pb-sm">
            Created {{ formatDate(whatIf.created_at) }} by {{ whatIf.created_by.displayName() }} of
            {{ whatIf.created_by_faculty.name }}
          </div>
          <what-if-scenario-actions
            class="q-pt-lg"
            :what-if="whatIf"
            @saved="whatIfSaved"
            @deleted="deleteWhatIf"
            @applied="applyWhatIf"
            @reopened="reopenWhatIf"
            @error="$notify.failure"
          />
        </div>
        <div class="col-xs-12 col-md-6 bg-mq-neutral text-mq-soft q-pa-xl">
          <div class="simple-title q-pb-md"> Status</div>
          <div
            :class="'text-'+getStatusDisplay(whatIf).color"
            class="flex items-center body-4"
          >
            <span class="text-weight-bold">
              <q-icon :name="getStatusDisplay(whatIf).icon" class="on-left simple-title" />
              {{ getStatusDisplay(whatIf).label }}
            </span>
            <span v-if="whatIf.isProposed()" class="">
              - Awaiting reply from {{ getImpactedFacultiesNotAccepted(whatIf) }}
            </span>
          </div>

          <div class="q-pt-xl">
            <what-if-scenario-time-line :what-if-events="whatIf.what_if_events" />
          </div>
        </div>
      </q-item>
    </q-list>
    <radar-loading :visible="progress.searching" />
  </div>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatDate } from '@/utils/date';
import RadarLoading from '@/components/common/RadarLoading.vue';
import { whatIfStatusDisplays } from './what-if-status-display';
import WhatIfScenarioActions from './WhatIfScenarioActions.vue';
import WhatIfScenarioTimeLine from './WhatIfScenarioTimeLine.vue';

export default {
  name: 'WhatIfScenarioList',
  components: {
    RadarLoading,
    WhatIfScenarioActions,
    WhatIfScenarioTimeLine,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('eras', ['workingEra']),
    ...mapState('whatIfs', ['whatIfs', 'progress']),
    ...mapState('organisationEntities', ['faculties']),
    ...mapGetters('auth', ['loggedInUser']),
    sortedWhatIfs() {
      return _.orderBy(this.whatIfs, ['created_at'], ['desc']);
    },
  },
  methods: {
    ...mapActions('whatIfs', ['fetchWhatIfs']),
    formatDate,
    getStatusDisplay(whatIf) {
      return whatIfStatusDisplays[whatIf.state];
    },
    getImpactedFacultiesNotAccepted(whatIf) {
      const notAcceptedFaculties = _.filter(this.faculties, faculty => _.find(
        whatIf.impacted_faculty_ids_not_accepted,
        notAcceptedFacultiesId => Number(faculty.id) === notAcceptedFacultiesId,
      ));
      return _.join(notAcceptedFaculties.map(faculty => faculty.name), ', and ');
    },
    async fetchWhatIfsByStatus(status) {
      await this.fetchWhatIfs(status);
    },
    async deleteWhatIf() {
      await this.fetchWhatIfsByStatus('draft');
      this.$notify.success('What If successfully deleted.');
    },
    async applyWhatIf() {
      await this.fetchWhatIfsByStatus('applied');
      this.$notify.success('What If successfully applied.');
    },
    async whatIfSaved() {
      await this.fetchWhatIfsByStatus('draft');
      this.$notify.success('What If successfully saved.');
    },
    async reopenWhatIf() {
      await this.fetchWhatIfsByStatus('rejected');
      this.$notify.success('What If successfully reopened.');
    },
    canUserEditWhatIf(whatIf) {
      return whatIf.canEditWhatIf(this.loggedInUser);
    },
  },
  watch: {
    $route() {
      this.fetchWhatIfsByStatus(this.status);
    },
  },
  mounted() {
    this.fetchWhatIfsByStatus(this.status);
  },
};
</script>
