class Faculty {
  constructor(faculty = {}) {
    this.id = faculty.id;
    this.name = faculty.name;
  }

  static fromList(faculties = []) {
    return faculties.map(era => new Faculty(era));
  }
}

export default Faculty;
