import ForcCitationPerformanceComparison from './forc-citation-performance-comparison';
import ForcPeerReviewPerformanceComparison from './forc-peer-review-performance-comparison';

export const createForcPerformanceComparison = (forcId, forcPerformanceComparison = {}) => {
  if (forcPerformanceComparison.type === ForcPeerReviewPerformanceComparison.TYPE) {
    return new ForcPeerReviewPerformanceComparison(forcId, forcPerformanceComparison);
  }
  return new ForcCitationPerformanceComparison(forcId, forcPerformanceComparison);
};

export const createForcPerformanceComparisons = (forcPerformanceComparisonsForcMapped = []) => Object.entries(forcPerformanceComparisonsForcMapped)
  .map(([forcId, forcPerformanceComparison]) => createForcPerformanceComparison(forcId, forcPerformanceComparison));

export default {
  createForcPerformanceComparison,
  createForcPerformanceComparisons,
};
