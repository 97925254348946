class Department {
  constructor(department = {}) {
    this.id = department.id;
    this.name = department.name;
  }

  static fromList(departments = []) {
    return departments.map(department => new Department(department));
  }
}

export default Department;
