import * as permissions from '@/models/user/access/permissions';
import ResearchOutputSearchFieldState from '@/models/research-output/research-output-search-fields-state';

export const adminMenuItems = [
  {
    title: 'ERA Submissions',
    icon: 'far fa-calendar-check',
    iconClass: null,
    link: 'eras',
    params: null,
    permission: null,
  },
  {
    title: 'Users',
    icon: 'supervisor_account',
    iconClass: null,
    link: 'users',
    activeLink: 'a',
    params: null,
    permission: permissions.MANAGE_USER,
  },
];

export const workQueueMenuItems = eraId => [
  {
    title: 'Faculty Escalated',
    icon: 'business',
    iconClass: null,
    link: 'research-outputs-escalated-l1',
    activeLink: 'work-queue-research-output-escalated-l1',
    params: {
      eraId,
      workQueue: 'L1',
    },
    permission: permissions.RESOLVE_L1_DISPUTE,
  },
  {
    title: 'University Escalated',
    icon: 'account_balance',
    iconClass: null,
    link: 'research-outputs-escalated-l2',
    activeLink: 'work-queue-research-output-escalated-l2',
    params: {
      eraId,
      workQueue: 'L2',
    },
    permission: permissions.RESOLVE_L2_DISPUTE,
  },
  {
    title: 'Review Required',
    icon: 'preview',
    iconClass: 'material-icons-outlined',
    link: 'research-outputs-review-required',
    activeLink: 'work-queue-research-output-review-required',
    params: {
      eraId,
      researchOutputTypes: ['C1'],
      roStatus: 'clawback_invalid',
      eligibility: 'eligible',
      claimsHasMyForcs: true,
      fieldsToMarkReadonly: new ResearchOutputSearchFieldState({ status: true, category: true }),
    },
    permission: permissions.MANAGE_REVIEW_REQUIRED,
  },
];

export const libraryMenuItems = eraId => [
  {
    title: 'Researchers',
    icon: 'mdi-school',
    iconClass: null,
    link: 'researchers',
    params: { eraId },
    feature: null,
    permission: null,
  },
  {
    title: 'Research Outputs',
    icon: 'article',
    iconClass: 'material-icons-outlined',
    link: 'research-outputs',
    params: { eraId },
    feature: null,
    permission: null,
  },
  {
    title: 'Portfolios',
    icon: 'business_center',
    link: 'portfolio-home',
    params: { eraId },
    feature: null,
    permission: null,
  },
  {
    title: 'Applied Measures',
    icon: 'straighten',
    link: 'applied-measures',
    params: { eraId },
    feature: 'appliedMeasures',
    permission: null,
  },
  {
    title: 'Research Income',
    icon: 'monetization_on',
    iconClass: null,
    link: 'research-income',
    params: { eraId },
    feature: 'researchIncome',
    permission: null,
  },
  {
    title: 'Bulk Code',
    icon: 'fas fa-tasks',
    iconClass: null,
    link: 'bulk-code',
    params: { eraId },
    feature: null,
    permission: permissions.MANAGE_RESEARCH_OUTPUT_CLAIM,
  },
  {
    title: 'Analytics',
    icon: 'multiline_chart',
    iconClass: null,
    link: 'analytics',
    params: { eraId },
    feature: 'analytics',
    permission: permissions.VIEW_ANALYTICS_VOLUME_MONITORING,
  },
  {
    title: 'Data Quality',
    icon: 'verified',
    iconClass: null,
    link: 'data-quality',
    params: { eraId },
    feature: 'dataQuality',
    permission: permissions.VIEW_DATA_QUALITY,
  },
  {
    title: 'XML',
    icon: 'local_library',
    iconClass: 'material-icons-outlined',
    link: 'xml',
    params: { eraId },
    feature: 'xml',
    permission: permissions.VIEW_XML_SUBMISSION,
  },
  {
    title: 'Reporting',
    icon: 'table_chart',
    iconClass: 'material-icons-outlined',
    link: 'reporting',
    params: { eraId },
    feature: 'reporting',
    permission: null,
  },
];
