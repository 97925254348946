<template>
  <div class="bg-mq-neutral q-px-lg round-borders">
    <div class="headline-title q-py-md">
      User Access:
      <span class="text-secondary on-right">
        <span v-if="isEdit">Edit access</span>
        <span v-else>Add new access</span>
      </span>
    </div>
    <form class="row stretch-container q-pl-sm" @submit.prevent="submitUserAccess()">
      <div class="col-12">
        <q-field
          :error="errors.has('role')"
          label="Role"
          label-width="3"
          error-label="Role is required"
        >
          <q-select
            v-model="roleSelected"
            v-validate="'required'"
            :options="rolesOptions"
            name="role"
            @input="eraRoleOrFacultyChanged"
          />
        </q-field>
        <q-field
          v-if="eraFacultyRequired"
          :error="errors.has('era-faculty')"
          label="ERA Faculty"
          label-width="3"
          error-label="ERA Faculty is required"
        >
          <q-select
            v-model="eraFacultySelected"
            v-validate="'required'"
            :options="eraFacultiesOptions"
            name="era-faculty"
            @input="eraRoleOrFacultyChanged"
          />
        </q-field>
        <template v-if="eraFacultyId && eraForcRequired">
          <q-field
            :error="errors.has('era-forcs')"
            label="FoRCs"
            label-width="3"
            error-label="A FoRC is required"
          >
            <forc-tree-selector
              v-model="forcIdsSelected"
              v-validate="'required'"
              :two-digit-forcs="twoDigitForcsForWorkingEra"
              :four-digit-forcs="eraFacultyForcs"
              name="era-forcs"
            />
          </q-field>
        </template>
      </div>
      <div class="col-12 self-end q-pb-md">
        <q-btn type="submit" color="primary" label="Save" />
        <q-btn
          v-if="isEdit"
          flat
          color="secondary"
          class="on-right"
          label="Cancel Edit"
          @click="resetUserAccessDetails()"
        />
      </div>
    </form>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import ForcTreeSelector from './ForcTreeSelector.vue';

export default {
  name: 'UserAccess',
  components: { ForcTreeSelector },
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      roleSelected: null,
      eraFacultySelected: null,
      forcIdsSelected: [],
    };
  },
  computed: {
    ...mapState('userAccess', ['roles', 'userAccess']),
    ...mapGetters('referenceData', ['twoDigitForcsForWorkingEra']),
    ...mapState('eraFacultyForcs', ['eraFaculties', 'eraFacultyForcs']),
    ...mapState('eras', ['workingEra']),
    rolesOptions() {
      return this.roles.map(role => ({ label: role.name, value: role }));
    },
    eraFacultiesOptions() {
      return this.eraFaculties.map(eraFaculty => ({ label: eraFaculty.name, value: eraFaculty }));
    },
    roleId() {
      return _.get(this.roleSelected, 'id');
    },
    eraFacultyId() {
      return _.get(this.eraFacultySelected, 'id');
    },
    eraFacultyRequired() {
      return this.roleSelected ? this.roleSelected.isFacultyBased() : false;
    },
    eraForcRequired() {
      return this.roleSelected ? this.roleSelected.isEraForcRestricted() : false;
    },
    errors() {
      return this.$validator.errors;
    },
    isEdit() {
      return !!this.userAccess.id;
    },
  },
  methods: {
    ...mapActions('userAccess', ['getAllRoles', 'saveUserAccess', 'resetUserAccess']),
    ...mapActions('eraFacultyForcs', ['getAllEraFaculties', 'getEraFacultyForcs']),
    resetUserAccessDetails() {
      this.resetUserAccess();
      this.roleSelected = null;
      this.eraFacultySelected = null;
      this.forcIdsSelected = [];
    },
    async saveUserAccessDetails() {
      try {
        const userAccess = {
          role_id: this.roleId,
          user_id: this.userId,
          era_faculty_id: this.eraFacultyId,
          forcs: this.forcIdsSelected.map(forcId => ({ forc_id: forcId })),
          id: this.userAccess.id,
        };
        await this.saveUserAccess(userAccess);
        this.resetUserAccessDetails();

        this.$notify.success('Successfully Saved');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async submitUserAccess() {
      await this.$validator.validateAll();
      if (!this.errors.any()) await this.saveUserAccessDetails();
    },
    resetFacultyForcsSelected() {
      if (!this.eraFacultyRequired) { this.eraFacultySelected = null; }
      if (!this.eraForcRequired) { this.forcIdsSelected = []; }
    },
    async eraRoleOrFacultyChanged() {
      this.resetFacultyForcsSelected();

      if (!this.eraForcRequired) return;

      await this.getEraFacultyForcs({ eraFacultyId: this.eraFacultySelected.id, metaId: this.workingEra.forc_reference_meta_id });
      if (this.userAccess.forcs) {
        this.forcIdsSelected = this.getSelectedForcIds();
      }
    },
    getSelectedForcIds() {
      const eraFacultyForcIds = this.eraFacultyForcs.map(forc => forc.id);
      const userAccessForcIds = this.userAccess.getAllForcs().map(forc => forc.id);

      return _.intersection(eraFacultyForcIds, userAccessForcIds);
    },
  },
  watch: {
    async userAccess() {
      this.roleSelected = _.find(this.roles, this.userAccess.role);
      this.eraFacultySelected = _.find(this.eraFaculties, this.userAccess.era_faculty);
      await this.eraRoleOrFacultyChanged(this.userAccess.era_faculty);
    },
  },
  async created() {
    this.getAllRoles();
    this.getAllEraFaculties();
  },
};
</script>

<style lang="stylus" scoped>
  .stretch-container
    height calc(100% - 48px)
</style>
