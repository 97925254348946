<template>
  <div class="full-width">
    <q-field v-if="researchOutput.hasROResearchStatement()">
      <q-input
        v-model.trim="researchStatement.statement"
        name="statement"
        type="textarea"
        hide-underline
        class="border border-black comfortable-textarea"
        size="xl"
        rows="25"
        readonly
      />
    </q-field>
    <div class="col text-mq-soft items-end field-group¯s">
      <div class="text-left r-m-large-right r-m-top">
        <div v-if="isMemberOfPortfolio && researchOutput.hasROResearchStatement()" class="r-m-bottom">
          Note: The Research Statement for this research output is no longer editable because it is part of a portfolio
        </div>
        <div v-if="!researchOutput.hasROResearchStatement()" class="r-m-bottom">
          Note: This title has no “Research Output Research Statement”.
        </div>
        <div v-if="!isMemberOfPortfolio" class="r-m-bottom">
          <q-btn
            type="submit"
            color="mq-soft"
            label="Edit RO Research Statement"
            @click="$router.push({name: 'research-outputs', query: {pureId: researchOutput.pure_id}})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ResearchStatement from '@/models/research-output/research-statement';
import ResearchOutput from '@/models/research-output/research-output';

export default {
  name: 'PortfolioResearchStatementTab',
  props: {
    researchOutput: {
      type: ResearchOutput,
      default: () => new ResearchOutput(),
    },
    researchStatement: {
      type: ResearchStatement,
      default: () => new ResearchStatement(),
    },
  },
  computed: {
    isMemberOfPortfolio() {
      return this.researchOutput.portfolio.id;
    },
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
