<template>
  <div class="scrollable-section">
    <q-table
      class="data-quality-table"
      table-class="bg-white"
      :data="dqChecks"
      :columns="columns"
      :rows-per-page-options="[0]"
      :pagination.sync="pagination"
      row-key="id"
      binary-state-sort
      no-data-label="No data found"
      hide-bottom
    >
      <!-- Table header -->
      <template slot="header" slot-scope="props">
        <q-tr>
          <q-th
            v-for="col in props.cols.slice(0, 3)"
            :key="col.name"
            :props="props"
            :class="col.classes"
          >
            <div>{{ col.label }}</div>
          </q-th>
          <th colspan="2" class="text-left">
            <div>Business Rule Code & Description</div>
          </th>
          <q-th
            v-for="col in props.cols.slice(5)"
            :key="col.name"
            :props="props"
            :class="col.classes"
          >
            <div>{{ col.label }}</div>
          </q-th>
        </q-tr>
      </template>
      <!-- Line detail -->
      <template slot="body" slot-scope="{ row }">
        <q-tr :props="row">
          <td>{{ row.identifier }}</td>
          <td>{{ row.eraCategory }}</td>
          <td>{{ row.title }}</td>
          <td>{{ row.definition.code }}</td>
          <td>{{ row.definition.description }}</td>
          <td>{{ checkTypeName(row.definition.checkType) }}</td>
          <td>
            <router-link
              v-if="row.radarId"
              class="radar-link"
              :to="radarLink(row)"
              target="_blank"
            >
              View
            </router-link>
            <span v-else>-</span>
          </td>
          <td>
            <a
              v-if="hasPureLink(row)"
              class="radar-link"
              :href="pureLink(row)"
              target="_blank"
            >
              View
            </a>
            <span v-else>-</span>
          </td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { CHECK_TYPES, CHECK_TYPE_NAMES } from '@/models/data-quality/data-quality-types';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import { dataQualityFields } from './data-quality-fields';

export default {
  name: 'DataQualityList',
  components: {
  },
  mixins: [PermissionsMixin, AllFieldsExpandableTable],
  data() {
    return {
      columns: dataQualityFields,
      prevPos: 0,
      pagination: {
        page: 1,
        rowsPerPage: 50,
      },
      dqScrollSection: null,
      dqTable: null,
      wasMinDashboard: false,
      radarRoutes: new Map([
        [CHECK_TYPES.Researcher, 'researcher-details'],
        [CHECK_TYPES.ResearchOutput, 'research-outputs'],
        [CHECK_TYPES.AppliedMeasure, 'applied-measures'],
        [CHECK_TYPES.Income, 'research-income'],
      ]),
    };
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    ...mapState('dataQuality', ['dqChecks', 'progress']),
  },
  methods: {
    ...mapActions('dataQuality', ['searchChecks']),
    checkTypeName(val) {
      return CHECK_TYPE_NAMES[val];
    },
    radarLink(dq) {
      const name = this.radarRoutes.get(dq.definition.checkType);
      const params = { eraId: this.workingEra.id };
      const query = {};
      if (dq.definition.checkType === CHECK_TYPES.Researcher) {
        params.researcherId = dq.radarId;
      } else {
        query.radarId = dq.radarId;
        query.eraId = this.workingEra.id;
      }
      return { name, params, query };
    },
    hasPureLink(dq) {
      const validPureTypes = [
        CHECK_TYPES.ResearchOutput,
        CHECK_TYPES.AppliedMeasure,
      ];
      return validPureTypes.includes(dq.definition.checkType);
    },
    pureLink(dq) {
      const host = 'https://research-management.mq.edu.au';
      const path = '/admin/editor/systemname.xhtml';
      return `${host}${path}?systemName=ResearchOutput&id=${dq.identifier}`;
    },
    onScroll() {
      const DASHBOARD_HEIGHT_DIFF = 136; // Height difference between full-height and minimised dashboards.
      const MAX_OFFSET = 20; // Scroll threshold when dashboard is minimised.

      const { top: viewPos, bottom: viewBottom, height: viewHeight } = this.dqScrollSection.getBoundingClientRect();
      const { top: listPos, bottom: listBottom, height: listHeight } = this.dqTable.getBoundingClientRect();

      const isScrollingDown = listPos < this.prevPos;
      const isMinDashboard = ((this.wasMinDashboard && isScrollingDown) || viewPos - listPos > MAX_OFFSET)
        && ((this.wasMinDashboard) || listHeight - viewHeight > DASHBOARD_HEIGHT_DIFF)
        && ((this.wasMinDashboard && !isScrollingDown) || Math.trunc(listBottom - viewBottom) || Math.trunc(listHeight - viewHeight));
      if (Math.abs(listPos - this.prevPos) > 5) {
        if (this.wasMinDashboard !== isMinDashboard) {
          this.wasMinDashboard = isMinDashboard;
          this.$emit('dashboardState', this.wasMinDashboard);
        }
        this.prevPos = listPos;
      }
    },
  },
  mounted() {
    this.dqScrollSection = document.querySelector('.scrollable-section');
    this.dqTable = document.querySelector('.q-table');
    this.dqScrollSection.addEventListener('scroll', _.throttle(this.onScroll, 500));
  },
  destroyed() {
    this.dqScrollSection.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="stylus" scoped>
.radar-link
  color $primary
  text-decoration none

  &:hover
    text-decoration underline
</style>
