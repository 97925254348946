import { render, staticRenderFns } from "./PortfolioResearchStatement.vue?vue&type=template&id=2391ea99&scoped=true&"
import script from "./PortfolioResearchStatement.vue?vue&type=script&lang=js&"
export * from "./PortfolioResearchStatement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2391ea99",
  null
  
)

export default component.exports