<template>
  <div class="full-width relative-position flex column no-wrap scroll">
    <portal to="app-header">
      <div class="page-title p-title bg-mq-sand">
        <div class="p-lhs">
          <div v-if="canUserManagePortfolio && !isFinalised">
            <q-btn
              icon="edit"
              color="mq-sand"
              text-color="radar-dark"
              class="no-shadow"
              size="18px"
              title="Edit portfolio name"
              dense
              :disable="editName"
              @click="editPortfolioName()"
            />
            <q-btn
              icon="delete_forever"
              color="mq-sand"
              text-color="radar-dark"
              class="no-shadow"
              size="18px"
              title="Delete portfolio"
              dense
              :disable="editName"
              @click="removePortfolio()"
            />
          </div>
          <div class="col relative-position" :class="{ 'editing': editName, 'saving': progress.savingPortfolio }">
            <div class="row items-center">
              <span class="portfolio-name-display">{{ portfolioName }}</span>
              <q-icon
                v-if="isFinalised"
                name="lock"
                size="24px"
                color="mq-dark"
                class="q-ml-sm"
              />
              <q-btn
                icon="check"
                text-color="positive"
                class="portfolio-name-btn no-shadow"
                title="Save"
                dense
                @click="savePortfolioName()"
              />
              <q-btn
                icon="clear"
                text-color="negative"
                class="portfolio-name-btn no-shadow"
                title="Cancel"
                dense
                @click="cancelPortfolioName()"
              />
              <span class="portfolio-name-progress">Saving...</span>
            </div>
            <q-input
              ref="portfolioName"
              v-model="portfolioName"
              type="text"
              class="portfolio-name-edit"
              :readonly="progress.savingPortfolio"
              @keyup.enter="savePortfolioName"
              @keyup.esc="cancelPortfolioName"
            />
          </div>
          <q-btn
            v-if="canUserFinalisePortfolio && !isFinalised"
            color="secondary"
            label="Finalise"
            :disable="editName"
            title="Finalise portfolio"
            @click="finalise()"
          />
          <q-btn
            v-if="canUserUnfinalisePortfolio && isFinalised"
            color="secondary"
            label="Unfinalise"
            :disable="editName"
            title="Unfinalise portfolio"
            @click="unfinalise()"
          />
        </div>
        <div class="p-rhs">
          <q-btn
            v-if="canUserManagePortfolio"
            icon="add"
            color="primary"
            label="New Portfolio"
            title="Create new portfolio"
            @click="addPortfolio()"
          />
          <q-select
            v-model="selectedPortfolio"
            class="portfolio-nav no-shadow"
            color="white"
            inverted-light
            :options="allPortfolioOptions($route.params.portfolioId)"
            hide-underline
            @input="navigateToPortfolio()"
          />
        </div>
      </div>
    </portal>

    <q-tabs class="head-bg-transparent border-bottom border-secondary" inverted color="secondary">
      <q-route-tab
        slot="title"
        label="Claims"
        name="portfolio-details"
        :to="{ name: 'portfolio-details' }"
        exact
      />
      <q-route-tab
        slot="title"
        label="Research Statement"
        name="portfolio-research-statement"
        :to="{ name: 'portfolio-research-statement' }"
        exact
      />
      <q-route-tab
        slot="title"
        label="History"
        name="portfolio-history"
        :to="{ name: 'portfolio-history' }"
        exact
      />
    </q-tabs>

    <router-view v-if="isPortfolioLoaded" class="full-page" />
    <q-dialog v-model="showDeleteDialog" @ok="onDeleteOk">
      <span slot="title">Delete Portfolio</span>
      <div slot="body" class="dialog-body">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-sm" />
        <span>Please <strong>remove</strong> all research outputs before deleting this portfolio.</span>
      </div>
    </q-dialog>
    <q-dialog v-model="showInvalidFinaliseDialog" @ok="onInvalidFinaliseOk">
      <span slot="title">Finalise Portfolio</span>
      <span slot="message">
        "{{ portfolio.name }}" cannot be finalised because of the following reason{{ validationErrors.length > 1 ? 's' : '' }}:
      </span>
      <div slot="body" class="dialog-body">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
        <ul class="q-ma-none">
          <li v-for="error in validationErrors" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
    </q-dialog>
    <q-dialog v-model="showFinaliseDialog" @cancel="onFinaliseCancel" @ok="onFinaliseOk">
      <span slot="title">Finalise Portfolio</span>
      <span slot="message">Are you sure you want to finalise "{{ portfolio.name }}"?</span>
      <div slot="body" class="dialog-body">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
        <span>
          Once finalised, this portfolio will be locked and no additional research outputs can be assigned to it.
          To unlock it again, please contact <a href="">administrators</a>.
        </span>
      </div>
      <template slot="buttons" slot-scope="props">
        <q-btn flat label="Cancel" @click="props.cancel" />
        <q-btn flat label="Ok" @click="props.ok" />
      </template>
    </q-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PermissionsMixin from '@/mixins/PermissionsMixin';

export default {
  name: 'PortfolioOverview',
  mixins: [PermissionsMixin],
  data() {
    return {
      isPortfolioLoaded: false,
      editName: false,
      portfolioName: '',
      selectedPortfolio: 0,
      validationErrors: [],
      showDeleteDialog: false,
      showFinaliseDialog: false,
      showInvalidFinaliseDialog: false,
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolio', 'portfolios', 'portfolioStatus', 'progress']),
    ...mapState('researchOutputs', ['pagination']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('portfolios', ['allPortfolioOptions']),
    isFinalised() {
      return this.portfolio.status === this.portfolioStatus.finalised;
    },
  },
  methods: {
    ...mapActions('portfolios', ['createPortfolio', 'deletePortfolio', 'fetchPortfolio', 'fetchPortfolios', 'updatePortfolioName', 'finalisePortfolio']),
    ...mapActions('researchOutputs', ['searchResearchOutputs']),
    addPortfolio() {
      const vm = this;
      this.$q.dialog({
        title: 'New portfolio title',
        prompt: {
          model: '',
          type: 'text',
        },
        cancel: true,
        ok: 'Create',
      }).then((portfolioName) => {
        this.createPortfolio(portfolioName)
          .then(
            () => { vm.$notify.success('Portfolio created'); },
            (e) => { vm.$notify.failure(e); },
          );
      });
    },
    async confirmPortfolioDeletion() {
      try {
        await this.$q.dialog({
          title: 'Delete Portfolio',
          message: 'Are you sure you want to delete this portfolio?',
          ok: true,
          cancel: true,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async removePortfolio() {
      if (this.portfolio.roCount > 0) {
        this.showDeleteDialog = true;
      } else {
        try {
          const confirm = await this.confirmPortfolioDeletion();
          if (confirm) {
            await this.deletePortfolio(this.portfolio.id);
            this.$notify.success('Portfolio deleted');
            this.$router.push({ name: 'portfolios', params: { eraId: this.workingEra.id } });
          }
        } catch (e) {
          this.$notify.failure(e);
        }
      }
    },
    finalise() {
      this.validationErrors = [];
      if (this.portfolio.roCount === 0) {
        this.validationErrors.push('This portfolio has no research outputs.');
      }
      if (this.portfolio.claimVersion.claims.length === 0) {
        this.validationErrors.push('Portfolio claims are missing.');
      }
      if (this.portfolio.claimVersion.hasPeerReviewedClaims() && !this.portfolio.researchStatement.statement) {
        this.validationErrors.push('A Research statement is required for peer-reviewed claims.');
      }

      if (this.validationErrors.length === 0) {
        this.showFinaliseDialog = true;
      } else {
        this.showInvalidFinaliseDialog = true;
      }
    },
    async unfinalise() {
      await this.finalisePortfolio(false);
      await this.searchResearchOutputs({ portfolioId: this.$route.params.portfolioId, eraId: this.workingEra.id, excludeLoadingFirstRecord: true });
    },
    async navigateToPortfolio() {
      if (this.selectedPortfolio) {
        let nextPage;
        switch (this.selectedPortfolio) {
          case 'home':
            nextPage = { name: 'portfolio-home', params: { eraId: this.workingEra.id } };
            break;
          case 'assign':
            nextPage = { name: 'portfolios', params: { eraId: this.workingEra.id } };
            break;
          default:
            nextPage = { name: 'portfolio-details', params: { portfolioId: this.selectedPortfolio, eraId: this.workingEra.id } };
        }
        this.$router.push(nextPage);
      }
    },
    editPortfolioName() {
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.portfolioName.focus();
        this.$refs.portfolioName.select();
      });
    },
    cancelPortfolioName() {
      this.portfolioName = this.portfolio.name;
      this.editName = false;
    },
    async savePortfolioName() {
      if (this.portfolioName !== this.portfolio.name) {
        await this.updatePortfolioName(this.portfolioName);
      }
      this.editName = false;
    },
    onDeleteOk() {
      this.showDeleteDialog = false;
    },
    onInvalidFinaliseOk() {
      this.showFinaliseDialog = false;
    },
    onFinaliseCancel() {
      this.showFinaliseDialog = false;
    },
    async onFinaliseOk() {
      await this.finalisePortfolio();
      await this.searchResearchOutputs({ portfolioId: this.$route.params.portfolioId, eraId: this.workingEra.id, excludeLoadingFirstRecord: true });
      this.showFinaliseDialog = false;
    },
  },
  async created() {
    this.isPortfolioLoaded = false;
    try {
      await Promise.all([
        this.fetchPortfolios(),
        this.fetchPortfolio(this.$route.params.portfolioId),
        this.searchResearchOutputs({ portfolioId: this.$route.params.portfolioId, eraId: this.workingEra.id, excludeLoadingFirstRecord: true }),
      ]);
      this.portfolioName = this.portfolio.name;
      this.isPortfolioLoaded = true;
    } catch (e) {
      this.$router.push({ name: 'portfolios', params: { eraId: this.workingEra.id } });
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmPageExit);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.confirmPageExit);
    });
  },
};
</script>

<style lang="stylus">
.p-title
  > div
    display flex
    align-items center

    > * + *
      margin-left 10px

.p-lhs
  float left
  margin -.25rem 0 .5rem

.p-rhs
  float right
  margin -.25rem 0 .5rem
</style>

<style lang="stylus" scoped>
.portfolio-name
  &-edit
    display none
    position absolute !important
    top 0
    left 0
    right 0
    font-size 24px

  &-btn
    display none
    font-size 12px !important
    z-index 1

    &:first-of-type
      margin-left 10px

  &-progress
    display none
    margin-left 10px
    color $mq-magenta
    font-size 12px
    z-index 1

.editing
  .portfolio-name
    &-display
      opacity 0

    &-edit
      display flex

    &-btn
      display inline-block

.saving
  .portfolio-name
    &-btn
      display none

    &-progress
      display inline

.portfolio-nav
  height 34px

.dialog-body
  display flex
  align-items flex-start
  line-height 1.4
</style>
