<template>
  <div>
    <div
      v-for="(className) in allClasses"
      :key="className"
      class="inline-block text-left bar-container"
      :class="'bar-container-'+className"
      :style="getStyleFor(className)"
    >
      <template v-if="classDistribution.getPercentageFor(className) > 0">
        <div :ref="'barLabels'+className" class="bar-labels">
          <div class="bar-label">{{ classDistribution[className] }}</div>
          <div class="bar-label">{{ classDistribution.getPercentageFor(className) }}%</div>
        </div>
        <div class="bar full-width" :class="'bg-mq-'+className">
          <q-tooltip
            class="no-padding class-info"
            :class="{inverted: className === 'class0'}"
            :offset="[0,5]"
          >
            <div class="bg-white">
              <div class="body-2 text-white q-pa-sm" :class="'bg-mq-'+className">
                {{ displayName(className) }}
              </div>
              <div class="body q-pa-sm text-black border border-mq-soft-dark">
                {{ classDistribution[className] }} ({{ classDistribution.getPercentageFor(className) }}%)
              </div>
            </div>
          </q-tooltip>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ClassDistribution from '@/models/analytics/class-distribution';
import { makeElementInvisibleOnOverflow } from '@/utils/dom-util';

export default {
  name: 'SimpleCitationsOverviewChart',
  components: {},
  props: {
    classDistribution: {
      type: ClassDistribution,
      default: () => new ClassDistribution(),
    },
  },
  data() {
    return {
      allClasses: ClassDistribution.classNames(),
    };
  },
  methods: {
    handleLabelDisplays() {
      this.allClasses.forEach((className) => {
        const refName = `barLabels${className}`;
        const element = this.$refs[refName] && this.$refs[refName][0];
        if (!element) return;
        makeElementInvisibleOnOverflow(element);
      });
    },
    getStyleFor(distributionClass) {
      return `width: ${this.classDistribution.getPercentageFor(distributionClass)}%;`;
    },
    displayName(className) {
      return _.startCase(className);
    },
  },
  watch: {
    classDistribution() {
      this.handleLabelDisplays();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleLabelDisplays();
    });
    window.addEventListener('resize', this.handleLabelDisplays);
  },
  updated() {
    this.handleLabelDisplays();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleLabelDisplays);
  },
};
</script>
<style lang="stylus" scoped>
  .bar
    height: 10px;
    border solid 1px #ffffff

  .bar-container
    position relative
    display inline-block

  .bar-labels
    width 100%
    overflow hidden
    font-size 12px
    font-width: normal

  .class-info
    &.inverted
      .class-info-title
        color: $mq-white

    .class-info-title
      color: $mq-dark

</style>
