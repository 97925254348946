import _ from 'lodash';
import {
  validateAddsToHundred,
  validateDuplicateClaims,
} from '@/utils/generic-claims-validator';
import Researcher from '@/models/researcher/researcher';
import Validation from '@/models/validation';
import ClaimItem from '@/models/era/claim-item';
import ClaimVersion from '@/models/era/claim-version';
import FundingYear from './funding-year';

export const HERDC_INCOME_TYPES = new Map([
  ['1', 'Australian Competitive Grants'],
  ['2', 'Other Public Sector Research Income'],
  ['3', 'Industry and Other Research Income'],
  ['4', 'Cooperative Research Centre Research Income'],
]);

const incomeType = isCommercialIncome => (isCommercialIncome ? 'Commercialisation' : 'Research');

class Funding {
  constructor(funding = {}) {
    this.id = funding.id;
    this.is_commercial_income = funding.is_commercial_income;
    this.incomeType = incomeType(this.is_commercial_income);
    this.award_id = funding.award_id;
    this.herdc_income_type = funding.herdc_income_type;
    const herdcCategory = HERDC_INCOME_TYPES.get(this.herdc_income_type);
    this.herdc_category = herdcCategory
      ? `${this.herdc_income_type}: ${herdcCategory}`
      : '-';
    this.awarded_amount = funding.awarded_amount;
    this.is_awarded_amount_manual = funding.is_awarded_amount_manual;
    this.funding_scheme_description = funding.scheme_body;
    this.project_title = funding.title;
    this.chief_investigator = new Researcher(funding.chief_investigator);
    this.faculty = funding.faculty;
    this.department = funding.department;
    this.pure_id = funding.pure_id;
    this.pure_link = funding.pure_link;
    this.major_account = funding.major_account;
    this.years = FundingYear.fromList(funding.years);
    this.last_three_version_history = funding.last_three_version_history
      ? ClaimVersion.fromList(funding.last_three_version_history) : [];
    this.latest_income_claim_version = undefined;
    this.under_edit_claims = undefined;
    this.updateLatestClaimVersionWith(funding.claim_version);
    this.validation = new Validation();
  }

  static fromList(fundings = []) {
    return fundings.map(item => new Funding(item));
  }

  updateLatestClaimVersionWith(latestClaimVersion) {
    this.latest_income_claim_version = (latestClaimVersion)
      ? new ClaimVersion(latestClaimVersion) : [];
    this.under_edit_claims = (this.latest_income_claim_version)
      ? ClaimItem.fromList(this.latest_income_claim_version.claims) : [];
  }

  getExistingClaimAt(index) {
    return this.getLatestClaims()[index];
  }

  getLatestClaims() {
    return _.get(this.latest_income_claim_version, 'claims', []);
  }

  getPreviousClaims() {
    return _.get(this.previous_era_claim_version, 'claims', []);
  }

  hasPreviousClaimVersions() {
    return !_.isEmpty(this.getPreviousClaims());
  }

  getPreviousClaimAt(index) {
    return this.getPreviousClaims()[index];
  }

  addClaim(claim) {
    const existingClaimForSameForc = this.under_edit_claims
      .find(existingClaim => existingClaim.forc.isSameAs(claim.forc));

    if (existingClaimForSameForc) {
      existingClaimForSameForc.apportion = claim.apportion;
      return;
    }

    if (this.under_edit_claims && this.under_edit_claims.length === 3) {
      return;
    }
    this.under_edit_claims.push(new ClaimItem(claim));
  }

  addEmptyClaims() {
    while (this.under_edit_claims.length < 3) {
      this.under_edit_claims.push(new ClaimItem());
    }
  }

  removeEmptyClaims() {
    this.under_edit_claims = this.under_edit_claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
  }

  removeClaimAt(index) {
    this.under_edit_claims.splice(index, 1);
  }

  addError(error) {
    this.validation.addErrors(error);
  }

  isValid() {
    return this.validation.isValid();
  }

  isInvalid() {
    return this.validation.isInvalid();
  }

  hasClaims() {
    return this.claims.length > 0;
  }

  claimDetails() {
    return {
      research_output_id: this.id,
      claims: this.under_edit_claims.map(claim => claim.basicDetails(false)),
    };
  }

  validate() {
    const missingValues = this.under_edit_claims
      .map((claim, index) => {
        if (!claim.forc.four_digit_code && claim.apportion) {
          return `Claim ${index + 1}: The FoRC code is missing`;
        } if (claim.forc.four_digit_code && !claim.apportion) {
          return `Claim ${index + 1}: The apportion is missing`;
        }
        return null;
      });

    const claims = this.under_edit_claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
    const noClaims = claims.length === 0 ? 'You must have at least one claim present' : null;
    const duplicateClaimsError = validateDuplicateClaims(claims);
    const addsToHundredError = validateAddsToHundred(claims, true);
    const noComment = !this.comment ? 'You are required to provide a comment with your changes' : null;

    this.validation.clearErrors();
    this.validation.errors.push(...missingValues);
    this.validation.addErrors(noClaims, duplicateClaimsError, addsToHundredError, noComment);

    return !this.validation.hasErrors();
  }

  hasClaimChanges() {
    const latestClaims = this.getLatestClaims();
    if (latestClaims.length !== this.under_edit_claims.length) {
      return true;
    }

    if (this.under_edit_claims.length === 0) return false;

    return this.under_edit_claims
      .some((existingClaim, index) => !existingClaim.isSameAs(latestClaims[index]));
  }

  basicClaimDetails() {
    return {
      income_item: this.id,
      comment: this.comment,
      claims: this.under_edit_claims.map(claim => claim.basicDetails()),
    };
  }

  getYearsSorted() {
    return _.orderBy(this.years, ['year'], ['asc']);
  }
}

export default Funding;
