<template>
  <q-inner-loading :visible="visible" color="mq-magenta" class="z-fullscreen text-mq-magenta">
    <q-spinner-rings size="90px" color="mq-magenta" />
  </q-inner-loading>
</template>

<script>
export default {
  name: 'RadarLoading',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
