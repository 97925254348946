<template>
  <q-layout-drawer
    :width="234"
    :mini="miniMode"
    :mini-width="52"
    :value="true"
    behavior="desktop"
    class="side-nav-bar"
    content-class="bg-mq-sand-dark text-secondary no-scroll"
  >
    <div class="column no-wrap full-height">
      <div class="col-auto">
        <div class="q-pt-lg">
          <q-btn
            icon="menu"
            color="mq-highlight"
            flat
            class="mini-toggle"
            @click="miniMode = !miniMode"
          />
          <span class="q-toolbar-title text-mq-highlight q-mini-drawer-hide">RADAR</span>
        </div>
        <div
          class="border-bottom border-mq-white q-my-sm q-pb-sm q-pl-md flex items-center"
        >
          <q-icon
            :color="workingEraIndicator"
            name="fiber_manual_record"
            class="q-mini-drawer-only q-pt-xs q-pb-sm q-pl-md"
          />
          <era-selector class="q-mini-drawer-hide" />
        </div>
      </div>

      <q-list no-border inset-delimiter class="col scroll flex column no-wrap">
        <side-nav-section
          :links="adminMenu"
          :mini="miniMode"
          heading="ADMINISTRATIVE"
        />
        <template v-if="!workingEra.isEmpty()">
          <side-nav-section
            v-if="hasPermission($permissions.ACCESS_WORK_QUEUE)"
            :links="workQueueMenu"
            :mini="miniMode"
            heading="My WORK"
          />
          <side-nav-section
            :links="libraryMenu"
            :mini="miniMode"
            heading="RESEARCH LIBRARY"
          />
          <div class="r-p-large-bottom col-grow" @click="miniMode = !miniMode" />
        </template>
      </q-list>
      <div
        class="row group items-center bg-mq-sand-dark body-2 col-auto border-top border-mq-white"
      >
        <div :title="loggedInUser.displayName()" class="col-auto">
          <router-link to="/">
            <badge
              v-if="loggedInUser"
              :letter="loggedInUser.first_name[0]"
              size="small"
              color="mq-highlight"
              class="text-white"
            />
          </router-link>
        </div>
        <div class="col q-mini-drawer-hide">
          <div class="text-secondary">
            {{ loggedInUser.displayName() }}
          </div>
          <q-btn
            color="negative"
            icon="fas fa-sign-out-alt"
            label="Logout"
            dense
            rounded
            small
            flat
            no-wrap
            @click="logout"
          />
        </div>
      </div>
    </div>
  </q-layout-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { ERA_SUBMISSION_STATUS_COLOR_INDICATORS } from '@/models/era/era';
import Badge from '@/components/common/Badge.vue';
import SideNavSection from '@/components/side-nav-bar/SideNavSection.vue';
import EraSelector from '@/components/era/EraSelector.vue';
import { adminMenuItems, workQueueMenuItems, libraryMenuItems } from './nav-menu';

export default {
  name: 'SideNavBar',
  components: {
    SideNavSection,
    EraSelector,
    Badge,
  },
  data() {
    return {
      miniMode: true,
    };
  },
  computed: {
    ...mapState('eras', ['workingEra']),
    ...mapGetters('auth', ['loggedInUser', 'hasPermission']),
    workingEraIndicator() {
      return ERA_SUBMISSION_STATUS_COLOR_INDICATORS[this.workingEra.submission_status];
    },
    adminMenu() {
      return adminMenuItems;
    },
    workQueueMenu() {
      return workQueueMenuItems(this.workingEra.id);
    },
    libraryMenu() {
      return libraryMenuItems(this.workingEra.id);
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
  },
};
</script>

<style lang="stylus" scoped>
.side-nav-bar >>>.q-layout-drawer-mini
  .q-item
    padding 10px 7px
</style>
