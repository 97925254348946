<template>
  <form novalidate class="full-width ro-claim-editor" @submit.prevent="saveClaim()">
    <div class="row items-baseline apportion-row justify-between r-p-side">
      <q-field
        class="col-9 forc-selector"
        error-label="Required"
        :disable="disable"
        :error="errors.has('forc-selector')"
      >
        <forc-selector
          v-if="isClaimEditable && !disable"
          v-model="researchOutputClaim.forc"
          v-validate.passive="'required'"
          :forcs="sortedUserApplicableForcs"
          placeholder="Select a FoRC"
          name="forc-selector"
          :forc-description-length="38"
          @change="researchOutputClaim.is_peer_reviewed = false"
        />
        <q-input v-else :value="selectedForcText" disable class="forc-selector-disabled" />
      </q-field>

      <q-field
        class="col-auto on-right"
        :error-label="minApportion+'% - 100%'"
        :error="errors.has('forc-apportion')"
      >
        <q-input
          v-model.number="researchOutputClaim.apportion"
          v-validate.passive="{ required: true, min_value: minApportion, max_value: 100 }"
          :disable="disable || !isClaimEditable"
          type="number"
          class="apportion"
          suffix="%"
          placeholder="e.g. 100"
          name="forc-apportion"
          :min="minApportion"
          :max="100"
          :step="1"
          :decimals="0"
        />
      </q-field>
    </div>

    <div class="row items-baseline peer-review-row justify-between r-p-side">
      <div class="col-auto">
        <q-checkbox
          v-model="researchOutputClaim.is_peer_reviewed"
          v-validate="'no_op'"
          :disable="disable || !isClaimEditable || !isForcPeerReviewEligible"
          name="peer-review"
        >
          Peer review
        </q-checkbox>
      </div>
    </div>
    <span v-if="shouldShowSensitivityNote(researchOutputClaim.id)" class="row items-baseline justify-between r-p-top r-p-side text-primary text-caption text-weight-light">
      Note: Removing this peer review tag will also remove any sensitivity flags applied. 'History' will capture the details of any flags applied/removed.
    </span>

    <div class="full-width user-and-time r-p-top r-p-side">
      {{ researchOutputClaim.actingUserDisplayName() }} at
      {{ formatDateTime(researchOutputClaim.updated_at) }}
      <span class="float-right">
        <span v-if="isResolved">Resolved</span>
        <span v-if="awaitingResolution">Awaiting resolution</span>
      </span>
    </div>
    <div class="full-width comment-row">
      <q-field v-if="isClaimEditable && !disable" :error="errors.has('comment')">
        <q-input
          v-model.trim="researchOutputClaim.comment"
          v-validate="'no_op'"
          name="comment"
          type="textarea"
          hide-underline
          class="border border-black comfortable-textarea"
          size="xl"
          placeholder="Comment"
          :max-height="48"
          maxlength="1000"
        />
      </q-field>
      <div v-else class="r-p-bottom">{{ researchOutputClaim.comment }}</div>
    </div>
    <div class="group">
      <template v-if="isClaimEditable && !disable">
        <q-btn
          type="submit"
          color="green-9"
          class="border border-green-9"
          label="Update Claim"
          flat
          :disable="!changed"
        />
        <q-btn
          type="button"
          color="negative"
          class="border border-negative"
          label="Remove Claim"
          flat
          @click="deleteClaim()"
        />
      </template>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { hasUserChangedAny } from '@/utils/validation-util';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ForcSelector from '@/components/common/ForcSelector.vue';
import ResearchOutputClaimsMixin from '@/mixins/ResearchOutputClaimsMixin';

export default {
  name: 'ResearchOutputClaim',
  components: { ForcSelector },
  mixins: [ResearchOutputClaimsMixin],
  props: {
    researchOutputClaim: {
      type: ResearchOutputClaim,
      required: true,
    },
    awaitingResolution: {
      type: Boolean,
      default: false,
    },
    isResolved: {
      type: Boolean,
      default: false,
    },
    journalForcs: {
      type: Array,
      default: () => [],
    },
    forcs: {
      type: Array,
      required: true,
    },
    userForcs: {
      type: Array,
      required: true,
    },
    isMultiDisciplinary: {
      type: Boolean,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    shouldShowSensitivityNote: {
      type: Function,
      default: () => id => false,
    },
  },
  data() {
    return {
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
      previousForcId: null,
    };
  },
  computed: {
    changed() {
      return hasUserChangedAny(this.veeFields) || this.previousForcId !== this.researchOutputClaim.forc.id;
    },
    isForcWithinUserForcs() {
      return this.researchOutputClaim.forc.isWithinRangeOfAny(this.userForcs);
    },
    isClaimEditable() {
      // Return `true` if one of user's FoRC matches the 4-digit FoRC of this component
      return _.find(this.userForcs, userForc => userForc.text === this.researchOutputClaim.forc.four_digit_code) !== undefined;
    },
  },
  watch: {
    researchOutputClaim: {
      immediate: true,
      handler(val) {
        if (!this.previousForcId) {
          this.previousForcId = val.forc.id;
        }
      },
    },
  },
};
</script>
<style lang="stylus" scoped>
  .apportion-row
    background-color $mq-sand
    padding-top 20.5px
    padding-bottom 1.5px

  .peer-review-row
    background-color $mq-sand
    padding-top 5.5px
    padding-bottom 20.5px
    min-width 83px
    font-size 16px

  .forc-selector
    min-width 140px
    font-size 16px

  .apportion
    min-width 83px
    font-size 16px

  .user-and-time
    font-size 14px
    font-weight normal
    font-style normal
    line-height 1.36
    letter-spacing 0.2px
    color rgba(0, 0, 0, 0.6)

  .border-green-9
    border-color $green-9
</style>
