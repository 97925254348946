import Forc from '@/models/era/forc';
import ClassDistribution from './class-distribution';

class ForcCitationClassDistribution {
  constructor([forc = {}, citationClassDistribution] = []) {
    this.forc = forc;
    this.class_distribution = new ClassDistribution(citationClassDistribution);
  }

  static fromList(forcCitations = [], forcs = []) {
    return forcCitations.map(([forcId, citationClassDistribution]) => {
      const forc = forcs.find(f => f.id === Number(forcId)) || new Forc({ id: forcId, description: `Unknown FoRC Id: ${forcId}` });
      return new ForcCitationClassDistribution([forc, citationClassDistribution]);
    });
  }

  isAboveCountThreshold() {
    return this.class_distribution.isAboveCountThreshold();
  }
}

export default ForcCitationClassDistribution;
