const claimCellStyle = 'width: 200px';

export const bulkFields = [
  {
    name: 'selected',
    label: '',
    style: 'width: 25px',
    classes: 'bg-white',
  },
  {
    name: 'title',
    label: 'Title',
    align: 'left',
    field: researchOutput => researchOutput.effectiveTitle(),
    style: 'width: 30%;',
    sortable: true,
    classes: 'bg-white',
  }, {
    name: 'abstract',
    label: '',
    align: 'left',
    field: 'abstract',
    style: 'width: 15%',
    classes: 'bg-white',
  }, {
    name: 'authors',
    label: 'Internal Authors',
    align: 'left',
    field: researchOutput => researchOutput.getInternalAuthorsAsCsv(),
    style: 'width: 30%',
    classes: 'bg-white',
  }, {
    name: 'journalName',
    label: 'Journal/FoRC',
    field: 'journal_name',
    align: 'left',
    style: 'width: 20%',
    sortable: true,
    classes: 'bg-white',
  }, {
    name: 'claim1',
    label: 'Claim 1',
    field: 'ro_claims',
    align: 'center',
    style: `${claimCellStyle}; padding-left: 10px`,
    sortable: false,
    classes: 'bg-mq-sand',
  }, {
    name: 'claim2',
    label: 'Claim 2',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    sortable: false,
    classes: 'bg-mq-sand',
  }, {
    name: 'claim3',
    label: 'Claim 3',
    field: 'ro_claims',
    align: 'center',
    style: claimCellStyle,
    classes: 'bg-mq-sand',
    sortable: false,
  }, {
    name: 'validations',
    label: '',
    field: researchOutput => researchOutput.getValidationStatusText(),
    align: 'center',
    sortable: true,
    style: 'width: 37px;',
    classes: 'bg-mq-sand no-padding',
  },
];

export default { bulkFields };
