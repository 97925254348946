import _ from 'lodash';
import { hasDuplicates } from './collections-util';

export const validateDuplicateClaims = (claims) => {
  if (claims.length <= 1) return undefined;
  if (hasDuplicates(claims, claim => claim.forc.four_digit_code)) {
    return 'Duplicate claims.';
  }
  return undefined;
};

export const validateAddsToHundred = (claims) => {
  if (_.sumBy(claims, claim => claim.apportion) !== 100) {
    return 'Apportions must add up to exactly 100%';
  }
  return undefined;
};

export const validateMoreThanThreeClaims = (claims) => {
  if (claims.length > 3) {
    return 'There are more than 3 claims present';
  }
  return undefined;
};

export default { validateDuplicateClaims, validateAddsToHundred };
