import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import UserAccess from './user-access';

class User {
  constructor(user = {}) {
    this.id = user.id;
    this.username = user.username;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.is_active = !!user.is_active;
    this.is_superuser = !!user.is_superuser;
    this.email = user.email;
    this.last_login = user.last_login;
    this.user_accesses = UserAccess.fromList(user.user_accesses);
  }

  static fromList(users = []) {
    return users.map(user => new User(user));
  }

  displayName() {
    const firstName = this.first_name ? `${this.first_name} ` : '';
    const lastName = this.last_name ? `${this.last_name} ` : '';

    return `${firstName}${lastName}`;
  }

  basicInfo() {
    return _.pick(this, ['username', 'is_active', 'is_superuser']);
  }

  isActive() {
    return this.is_active;
  }

  isSuperuser() {
    return this.is_superuser;
  }
}

export default User;
