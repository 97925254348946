import _ from 'lodash';
import api from '@/api';
import Role from '@/models/user/access/role';
import UserAccess from '@/models/user/user-access';

export default {
  namespaced: true,
  state: {
    roles: [],
    userAccess: new UserAccess(),
    allUserAccess: [],
  },
  mutations: {
    UPDATE_ROLES(state, roles) {
      state.roles = roles;
    },
    UPDATE_USER_ACCESS(state, userAccess) {
      state.userAccess = userAccess;
    },
    UPDATE_ALL_USER_ACCESS(state, allUserAccess) {
      state.allUserAccess = allUserAccess;
    },
  },
  actions: {
    async getAllRoles({ commit }) {
      const response = await api.users.getAllRoles();
      const roles = Role.fromList(response.data);
      commit('UPDATE_ROLES', _.sortBy(roles, role => role.name));
    },

    async resetUserAccess(context) {
      return context.commit('UPDATE_USER_ACCESS', new UserAccess());
    },

    async saveUserAccess({ dispatch }, userAccess) {
      if (userAccess.id) {
        await api.users.updateAccess(userAccess);
      } else {
        await api.users.createAccess(userAccess);
      }
      dispatch('getAllUserAccess', userAccess.user_id);
    },

    async getAllUserAccess({ commit }, userId) {
      const response = await api.users.getAllAccess(userId);
      const allUserAccess = UserAccess.fromList(response.data.results);
      commit('UPDATE_ALL_USER_ACCESS', allUserAccess);
    },

    async getUserAccess({ commit }, userAccess) {
      const response = await api.users.getAccess(userAccess);
      const retrievedUserAccess = new UserAccess(response.data);
      commit('UPDATE_USER_ACCESS', retrievedUserAccess);
    },

    async deleteUserAccess({ dispatch }, userAccess) {
      await api.users.deleteAccess(userAccess);
      dispatch('getAllUserAccess', userAccess.user.id);
    },
  },
};
