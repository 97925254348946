import { VIEW_DATA_QUALITY } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import DataQualityOverview from '@/components/data-quality/DataQualityOverview.vue';

const DataQualityRoutes = {
  path: 'data-quality',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'data-quality',
      component: DataQualityOverview,
      meta: {
        auth: [VIEW_DATA_QUALITY],
        activeRoute: 'data-quality',
      },
    },
  ],
};

export default DataQualityRoutes;
