<!--
  TO DEPRECATE: If nothing else uses this we can deprecate
  From TASK0831649 we're using string-based search to keep it simple
-->
<template>
  <q-select
    v-model="entity"
    :options="entitiesOptions"
    clear-value=""
    clearable
    filter
    v-bind="$attrs"
  />
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FacultyAndDepartmentSelector',
  props: {
    value: {
      type: Object,
      default: () => ({ facultyId: undefined, departmentId: undefined }),
    },
    faculties: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    entity: {
      get() {
        if (!this.value || (this.value && !this.value.facultyId && !this.value.departmentId)) return '';
        return JSON.stringify(this.value);
      },
      set(newValue) {
        const jsonVal = newValue ? JSON.parse(newValue) : {};
        this.$emit('input', jsonVal);
      },
    },
    entitiesOptions() {
      return [...this.facultiesOptions, ...this.departmentsOptions];
    },
    facultiesOptions() {
      const sortedFaculties = _.sortBy(this.faculties, 'name');
      return sortedFaculties.map(faculty => ({
        label: faculty.name,
        value: JSON.stringify({ facultyId: faculty.id }),
      }));
    },
    departmentsOptions() {
      const sortedDepartments = _.sortBy(this.departments, 'name');
      return sortedDepartments.map(department => ({
        label: department.name,
        value: JSON.stringify({ departmentId: department.id }),
      }));
    },
  },
};
</script>
