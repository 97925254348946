<template>
  <span>
    <q-checkbox
      :value="allForcsSelectionState"
      color="primary"
      name="era-forcs"
      keep-color
      label="Select/Deselect All"
      class="q-mb-sm"
      @change="toggleAllForcSelection"
    />
    <q-tree
      ref="forcsTree"
      :nodes="forcOptions"
      :ticked="value"
      node-key="id"
      control-color="primary"
      tick-strategy="leaf"
      no-nodes-label=" "
      no-results-label=" "
      @update:ticked="updateSelected"
    >
      <div slot="header-root" slot-scope="prop" class="row justify-between">
        <span class="col-auto">{{ prop.node.forc.text }}</span>
        <span class=""> {{ prop.node.forc.description }}</span>
      </div>

      <div slot="default-header" slot-scope="prop" class="items-center">
        <span :class="{'text-primary': prop.ticked}" class="text-weight-bold ">
          {{ prop.node.forc.text }}
        </span>
        &bull;
        <span :class=" {'text-primary': prop.ticked}">
          {{ prop.node.forc.description }}
        </span>
      </div>
    </q-tree>
  </span>
</template>
<script>

import _ from 'lodash';

export default {
  name: 'ForcTreeSelector',
  props: {
    twoDigitForcs: {
      type: Array,
      default: () => [],
    },
    fourDigitForcs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    allForcsSelectionState() {
      if (this.areAllForcsSelected) return true;
      if (this.areSomeForcsSelected) return null;
      return false;
    },
    areAllForcsSelected() {
      return this.fourDigitForcs.length === this.value.length;
    },
    areSomeForcsSelected() {
      return this.value.length > 0;
    },
    forcOptions() {
      return this.getNestedTreeOptionsForForcs(this.twoDigitForcs, this.fourDigitForcs);
    },
  },
  methods: {
    toggleAllForcSelection() {
      if (this.areAllForcsSelected) {
        return this.emitUpdates([]);
      }
      return this.emitUpdates(this.fourDigitForcs.map(forc => forc.id));
    },
    updateSelected(value) {
      this.emitUpdates(value);
    },
    emitUpdates(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    },
    getNestedTreeOptionsForForcs(twoDigitForcs, fourDigitForcs) {
      const forcsByTwoDigitId = _.groupBy(fourDigitForcs, 'two_digit_forc_id');

      const forcOptions = _.entries(forcsByTwoDigitId).map(([, forcs]) => {
        const fourDigitForc = forcs[0];
        const twoDigitForc = twoDigitForcs
          .find(forc => forc.id === fourDigitForc.two_digit_forc_id);

        return {
          label: `${twoDigitForc.text}: ${twoDigitForc.description}`,
          id: twoDigitForc.id,
          forc: twoDigitForc,
          children: forcs.map(forc => ({
            label: `${forc.text} -- ${forc.description}`,
            id: forc.id,
            forc,
          })),
        };
      });
      return forcOptions;
    },
  },
};
</script>
