const ResearchOutputClaimEditorGrid = {
  data() {
    return {
      underEdit: {
        researchOutput: undefined,
        claimIndex: undefined,
      },
    };
  },
  computed: {},
  methods: {
    initiateEditClaimFor(researchOutput, claimIndex) {
      this.underEdit.researchOutput = researchOutput;
      this.underEdit.claimIndex = claimIndex;
    },
    addNewClaim(claim) {
      this.underEdit.researchOutput.addClaim(claim);
      this.exitEdit();
    },
    addClaimToSelected(claim) {
      this.resetEdit();
      this.selected.forEach((researchOutput) => {
        researchOutput.addClaim(claim);
        researchOutput.validate(this.clawbackBypassedForcs, this.testClaimAddToHundred);
      });
    },
    deleteClaim(researchOutput, claimIndex) {
      this.resetEdit();
      researchOutput.removeClaimAt(claimIndex);
      researchOutput.validate(this.clawbackBypassedForcs, this.testClaimAddToHundred);
    },
    isUnderEdit(researchOutput, claimIndex) {
      return this.underEdit.researchOutput === researchOutput
        && this.underEdit.claimIndex === claimIndex;
    },
    exitEdit() {
      if (this.underEdit.researchOutput) {
        this.underEdit.researchOutput.validate(this.clawbackBypassedForcs, this.testClaimAddToHundred);
      }
      this.resetEdit();
    },
    resetEdit() {
      this.underEdit = {
        researchOutput: undefined,
        claimIndex: undefined,
      };
    },
  },
};

export default ResearchOutputClaimEditorGrid;
