import api from '@/api';
import Department from '@/models/organisation/department';
import Faculty from '@/models/organisation/faculty';

export default {
  namespaced: true,
  state: {
    faculties: [],
    departments: [],
  },
  mutations: {
    UPDATE_FACULTIES(state, faculties) {
      state.faculties = faculties;
    },
    UPDATE_DEPARMENTS(state, departments) {
      state.departments = departments;
    },
  },
  actions: {
    async getAllFaculties({ commit }) {
      const response = await api.organisations.getAllFaculties();
      const faculties = Faculty.fromList(response.data);
      return commit('UPDATE_FACULTIES', faculties);
    },

    async getAllDepartments({ commit }) {
      const response = await api.organisations.getAllDepartments();
      const departments = Department.fromList(response.data);
      return commit('UPDATE_DEPARMENTS', departments);
    },

    async initialize({ dispatch }) {
      return Promise.all([
        dispatch('getAllFaculties'),
        dispatch('getAllDepartments'),
      ]);
    },
  },
};
