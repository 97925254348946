class ResearchOutputAbdc {
  constructor(researchOutputAbdc = {}) {
    this.year = researchOutputAbdc.year;
    this.value = researchOutputAbdc.value;
  }

  static fromList(researchOutputAbdcs = []) {
    return researchOutputAbdcs
      .map(researchOutputAbdc => new ResearchOutputAbdc(researchOutputAbdc));
  }
}

export default ResearchOutputAbdc;
