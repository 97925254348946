<template>
  <div class="full-height scroll">
    <portal to="app-header">
      <div class="page-title row items-center justify-between bg-mq-sand">
        <div>Portfolios</div>
        <div class="flex">
          <q-btn
            color="primary"
            label="Assign NTROs to Portfolios"
            title="Assign NTROs to Portfolios"
            :to="{ name: 'portfolios' }"
          />
          <q-btn
            v-if="canUserManagePortfolio"
            class="q-ml-md"
            icon="add"
            color="primary"
            label="Create portfolio"
            title="Create new portfolio"
            @click="addPortfolio()"
          />
          <q-select
            v-model="selectedPortfolio"
            class="portfolio-nav no-shadow q-ml-md"
            color="white"
            inverted-light
            :options="allPortfolioOptions('home')"
            hide-underline
            @input="navigateToPortfolio()"
          />
        </div>
      </div>
    </portal>
    <div class="q-pa-xl">
      <radar-loading :visible="isLoading" />
      <q-card color="white" text-color="mq-charcoal" class="q-pa-lg">
        <template v-if="!isLoading">
          <div v-if="portfolios.length === 0" class="no-portfolios">
            <div class="h2 q-mb-xl">There are no portfolios yet.</div>
            <div v-if="canUserManagePortfolio" class="text-center">
              <div>Would you like to create one?</div>
              <q-btn
                icon="add"
                color="primary"
                label="Create a new portfolio"
                title="Create new portfolio"
                class="q-mt-lg"
                @click="addPortfolio()"
              />
            </div>
          </div>
          <template v-else>
            <div class="h2 p-header">
              <div class="flex nowrap full-width">
                <span class="p-name">Portfolio Name</span>
                <span class="p-claims">Portfolio Claims</span>
              </div>
            </div>
            <div v-for="(item, index) in sortedPortfolios" :key="item.id" class="p-item">
              <q-icon
                class="q-mr-md"
                :name="item.status === portfolioStatus.finalised ? 'lock' : ' '"
              >
                <q-tooltip v-if="item.status === portfolioStatus.finalised">Finalised</q-tooltip>
              </q-icon>
              <q-btn
                v-if="item.researchStatement.statement"
                flat
                no-ripple
                dense
                class="icon-link no-padding q-mr-md"
                @click="displayStatement(index)"
              >
                <q-icon
                  class="material-icons-outlined"
                  name="sticky_note_2"
                />
                <q-tooltip>Research Statement</q-tooltip>
              </q-btn>
              <q-icon v-else name=" " class="q-mr-lg" />
              <router-link :to="{ name: 'portfolio-details', params: { portfolioId: item.id} }">
                <div class="p-name">
                  <span>{{ item.displayText() }}</span>
                </div>
                <div class="p-claims">
                  <span v-for="claim in item.claimVersion.claims" :key="claim.id">
                    {{ claim.forc.four_digit_code }} ({{ claim.apportion }}%)
                    <span v-if="claim.isPeerReviewed">&#x2713;</span>
                    <q-tooltip>{{ claim.forc.description }}</q-tooltip>
                  </span>
                </div>
              </router-link>
            </div>
          </template>
        </template>
      </q-card>
    </div>
    <q-modal v-model="showStatement" :content-css="{ minWidth: '80vw', minHeight: '80vh', maxWidth: '80vw' }">
      <q-modal-layout footer-class="bg-white">
        <q-toolbar slot="header">
          <q-toolbar-title>
            Portfolio Research Statement
          </q-toolbar-title>
        </q-toolbar>

        <q-toolbar slot="footer">
          <q-toolbar-title>
            <q-btn
              v-close-overlay
              flat
              class="bg-white"
              text-color="primary"
            >
              Close
            </q-btn>
          </q-toolbar-title>
        </q-toolbar>

        <div class="layout-padding">
          <pre wrap>{{ selectedStatement }}</pre>
        </div>
      </q-modal-layout>
    </q-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import Portfolio from '@/models/portfolio/portfolio';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import RadarLoading from '@/components/common/RadarLoading.vue';

export default {
  name: 'PortfolioHome',
  components: {
    RadarLoading,
  },
  mixins: [PermissionsMixin],
  data() {
    return {
      isLoading: false,
      portfolios: [],
      showStatement: false,
      selectedIndex: 0,
      selectedPortfolio: 0,
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolioStatus', 'progress']),
    ...mapGetters('portfolios', ['allPortfolioOptions']),
    ...mapGetters('eras', ['workingEra']),
    sortedPortfolios() {
      return _.sortBy(this.portfolios, 'name');
    },
    selectedStatement() {
      return this.sortedPortfolios[this.selectedIndex]?.researchStatement?.statement;
    },
  },
  methods: {
    ...mapActions('portfolios', ['fetchAll', 'fetchPortfolios', 'createPortfolio']),
    displayStatement(index) {
      this.selectedIndex = index;
      this.showStatement = true;
    },
    addPortfolio() {
      const vm = this;
      this.$q.dialog({
        title: 'New portfolio title',
        prompt: {
          model: '',
          type: 'text',
        },
        cancel: true,
        ok: 'Create',
      }).then((portfolioName) => {
        this.createPortfolio(portfolioName)
          .then(
            (data) => {
              this.portfolios.push(new Portfolio(data));
              vm.$notify.success('Portfolio created');
            },
            (e) => { vm.$notify.failure(e); },
          );
      }).catch(() => { /* do nothing */ });
    },
    async navigateToPortfolio() {
      if (this.selectedPortfolio) {
        let nextPage;
        switch (this.selectedPortfolio) {
          case 'home':
            nextPage = { name: 'portfolio-home', params: { eraId: this.workingEra.id } };
            break;
          case 'assign':
            nextPage = { name: 'portfolios', params: { eraId: this.workingEra.id } };
            break;
          default:
            nextPage = { name: 'portfolio-details', params: { portfolioId: this.selectedPortfolio, eraId: this.workingEra.id } };
        }
        this.$router.push(nextPage);
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      const portfolios = await Promise.all([
        this.fetchAll(),
        this.fetchPortfolios(),
      ]);
      // eslint-disable-next-line prefer-destructuring
      this.portfolios = portfolios[0];
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.no-portfolios
  display flex
  flex-direction column
  justify-content center
  align-items center
  min-height 40vh
.p-name
  flex-basis 60%
  max-width 60%
  width 100%

.p-claims
  display flex
  flex-basis 40%

  span
    flex-basis 33%
    text-align center

.p-header
  position sticky
  top 0
  display flex
  align-items center
  padding 20px 10px 20px 68px
  background-color #fff
  border-bottom 1px solid
  z-index 1

  .p-claims
    justify-content center

.p-item
  display flex
  align-items center
  padding 0 10px
  min-height 56px

  &:nth-of-type(2n+1)
    background-color rgba(0,0,0, .1)

  a
    display flex
    align-self: stretch;
    align-items: center;
    width 100%

    &:link
      text-decoration none

    &:hover span
      font-weight 700
      text-decoration underline

  .p-name
    color $mq-purple

  .p-claims
    font-size 14px

.layout-padding pre
  font-family $typography-font-family

.portfolio-nav
  height 34px

</style>
