import api from '@/api';
import ResearchOutputClaimEvent from '@/models/research-output/research-output-claim-event';

export default {
  namespaced: true,
  state: {
    pagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    claimEvents: [],
    progress: {
      fetching: false,
    },
  },
  mutations: {
    UPDATE_CLAIM_EVENTS(state, { claimEvents, currentPage, totalResults }) {
      state.claimEvents = claimEvents;
      state.pagination.currentPage = currentPage || 1;
      state.pagination.totalResults = totalResults || 0;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async fetchClaimEvents({ commit }, { page, researchOutputId }) {
      commit('UPDATE_PROGRESS', { fetching: true });
      try {
        const response = await api.researchOutputs.getClaimEvents(researchOutputId, page);
        const claimEvents = ResearchOutputClaimEvent.fromList(response.data.results);
        commit(
          'UPDATE_CLAIM_EVENTS',
          {
            claimEvents,
            currentPage: page || 1,
            totalResults: response.data.count,
          },
        );
      } finally {
        commit('UPDATE_PROGRESS', { fetching: false });
      }
    },
  },
};
