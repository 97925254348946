<template>
  <div>
    <div v-if="claim">
      <rci-and-class-for-claim
        :claim="claim"
        :citation-benchmarks="citationBenchmarks"
        :research-output="researchOutput"
      />
      <div class="bg-mq-sand-dark claim-cell-item">
        <claim-viewer
          v-if="claim"
          :claim="claim"
          :allow-peer-review-tag="false"
          class=""
          classes="bg-mq-soft-muted-light"
        />
      </div>
    </div>
    <div v-else>
      <div class="claim-cell-enrich-item" />
      <div class="bg-mq-soft-muted-light claim-cell-item" />
    </div>

    <div v-if="showPreviousClaim" class="q-mt-sm">
      <div v-if="previousClaim">
        <rci-and-class-for-claim
          :claim="previousClaim"
          :citation-benchmarks="citationBenchmarks"
          :research-output="researchOutput"
        />
        <div class="bg-mq-neutral claim-cell-item">
          <claim-viewer
            :claim="previousClaim"
            :allow-peer-review-tag="false"
            classes="bg-mq-neutral"
          />
        </div>
      </div>
      <div v-else>
        <div class="claim-cell-enrich-item" />
        <div class="bg-mq-neutral claim-cell-item" />
      </div>
    </div>
  </div>
</template>

<script>
import CitationBenchmarks from '@/models/analytics/citation-benchmarks';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';
import ClaimViewer from '@/components/bulk-code/ClaimViewer.vue';
import RciAndClassForClaim from '../common/ClaimRciClassEnrich.vue';

export default {
  name: 'WhatIfClaimCellReadOnly',
  components: { RciAndClassForClaim, ClaimViewer },
  props: {
    claim: ResearchOutputClaim,
    previousClaim: ResearchOutputClaim,
    citationBenchmarks: {
      type: CitationBenchmarks,
      required: true,
    },
    researchOutput: {
      type: ResearchOutputForWhatIf,
      required: true,
    },
    showPreviousClaim: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="stylus" scoped>
  .apportion-item
    width 175px
</style>
