import PeerReviewStatsForForc from './peer-review-stats-for-forc';

class ForcPeerReviewPerformanceComparison {
  static TYPE = 'peer-review';

  constructor(forcId, { before, after }) {
    this.forc_id = Number(forcId);
    this.before_peer_review_stats = new PeerReviewStatsForForc([forcId, before]);
    this.after_peer_review_stats = new PeerReviewStatsForForc([forcId, after]);
  }

  // eslint-disable-next-line class-methods-use-this
  get type() {
    return ForcPeerReviewPerformanceComparison.TYPE;
  }

  thresholdChangeByCategory(category) {
    return this.after_peer_review_stats[category].threshold
      - this.before_peer_review_stats[category].threshold;
  }

  taggedChangeByCategory(category) {
    return this.after_peer_review_stats[category].tagged
      - this.before_peer_review_stats[category].tagged;
  }

  hasBeenTaggedToThresholdForCategory(category) {
    return this.after_peer_review_stats.hasBeenTaggedToThresholdForCategory(category);
  }
}

export default ForcPeerReviewPerformanceComparison;
