import Quasar from 'quasar';
import 'quasar-extras/animate';
import 'quasar-extras/fontawesome';
import 'quasar-extras/ionicons';
import 'quasar-extras/mdi';
import 'quasar-extras/roboto-font';
import 'quasar-framework/dist/quasar.ie.polyfills';
import veeValidate from 'vee-validate';
import PortalVue from 'portal-vue';
import Fragment from 'vue-fragment';
import Vue from 'vue';
import App from './App.vue';
import Permission from './models/user/access/permissions';
import Notify from './utils/notify';
import config from './config';
import './registerServiceWorker';
import router from './router/router';
import { store } from './store';
import './styles/app.styl';
import './validators';

Vue.use(Quasar, { config: {} });

Vue.config.productionTip = false;
Vue.prototype.$permissions = Permission;
Vue.prototype.$config = config;
Vue.prototype.$notify = Notify;

Vue.use(veeValidate, {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'veeFields',
  i18n: null,
  i18nRootKey: 'validations',
  inject: true,
  locale: 'en',
  validity: true,
});

Vue.use(PortalVue);
Vue.use(Fragment.Plugin);

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

window.app = app;
