<template>
  <form class="row justify-between" @submit.prevent="search()">
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            color="mq-soft"
            label="Clear"
            flat
            class="r-m-right"
            @click="clearSearch()"
          />
          <q-btn type="submit" color="primary" label="Search" />
        </div>
      </div>

      <p class="simple-title text-primary">Research Output Claims</p>

      <q-field label="Author Name/MQ Id">
        <q-input v-model.trim="searchParameters.authorNameContains" />
      </q-field>
      <q-field label="Author Faculty/Department/Schools">
        <q-select
          v-model="searchParameters.facultyOrDepartmentName"
          :filter="labelContainsFilter"
          :options="facultyOrDepartmentOptions"
          clearable
        />
      </q-field>
      <q-field label="Q Categories">
        <q-select
          v-model="searchParameters.researchOutputTypes"
          :options="typeOptions"
          clearable
          multiple
          chips
        />
      </q-field>
      <q-field label="Pure ID">
        <q-input v-model.trim="searchParameters.pureId" clearable />
      </q-field>
      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" />
      </q-field>
      <q-field label="Year">
        <q-input
          v-model.trim="searchParameters.pubYear"
          type="number"
        />
      </q-field>
      <q-field label="Claim FoRCs" class="r-p-vertical">
        <q-field
          :helper="searchParameters.withPeerReview?
            'Will search peer review tags associated with this claim only':''"
        >
          <q-select
            v-model="searchParameters.claimForcId"
            :options="claimForcOptions"
            :disable="searchParameters.withNoClaimForcApportions"
            autofocus-filter
            :filter="labelStartWithOrSublabelContainsFilter"
            clearable
          />
        </q-field>
        <q-checkbox
          v-model="searchParameters.withNoClaimForcApportions"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="With no Claim FoRC apportions"
        />
        <q-checkbox
          v-model="searchParameters.withPeerReview"
          color="black"
          :disable="searchParameters.withNoClaimForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged"
        />
        <q-checkbox
          v-model="searchParameters.withPeerReviewNoResearchStatement"
          color="black"
          :disable="searchParameters.withNoClaimForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged Without Research Statement"
        />
      </q-field>
      <q-field label="Eligibility">
        <q-option-group
          v-model="searchParameters.eligibility"
          type="radio"
          class="q-if-label-above floating-label-font q-mt-lg block"
          :options="eligibilityOptions"
        />
      </q-field>
      <p class="simple-title text-primary">Portfolio</p>
      <q-field label="Portfolio Title">
        <q-input v-model.trim="searchParameters.portfolioName" />
        <q-checkbox
          v-model="searchParameters.hasPortfolioMembership"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="All NTROs that belong to a portfolio"
        />
      </q-field>
      <q-field label="Portfolio Status">
        <q-option-group
          v-model="searchParameters.portfolioStatus"
          type="radio"
          class="q-if-label-above floating-label-font q-mt-lg block"
          :options="portfolioStatusOptions"
        />
      </q-field>
      <q-field label="Portfolio FoRCs" class="r-p-vertical">
        <q-field
          :helper="searchParameters.withPortfolioPeerReview?
            'Will search peer review tags associated with this claim only':''"
        >
          <q-select
            v-model="searchParameters.portfolioForcId"
            :options="portfolioForcOptions"
            :disable="searchParameters.withNoPortfolioForcApportions"
            autofocus-filter
            :filter="labelStartWithOrSublabelContainsFilter"
            clearable
          />
        </q-field>
        <q-checkbox
          v-model="searchParameters.withNoPortfolioForcApportions"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="With no Portfolio FoRC apportions"
        />
        <q-checkbox
          v-model="searchParameters.withPortfolioPeerReview"
          color="black"
          :disable="searchParameters.withNoPortfolioForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged"
        />
        <q-checkbox
          v-model="searchParameters.withPortfolioPeerReviewNoResearchStatement"
          color="black"
          :disable="searchParameters.withNoPortfolioForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged Without Research Statement"
        />
      </q-field>

      <div class="text-right">
        <q-btn
          type="button"
          color="mq-soft"
          label="Clear"
          flat
          class="q-mr-lg"
          @click="clearSearch()"
        />
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getNonEmptyValues, labelContainsFilter, labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import Forc from '@/models/era/forc';
import ResearchOutput from '@/models/research-output/research-output';

const getDefaultParameters = () => ({
  title: '',
  facultyOrDepartmentName: null,
  authorNameContains: '',
  researchOutputTypes: [],
  pubYear: '',
  publisherName: '',
  claimForcId: undefined,
  withNoClaimForcApportions: false,
  withPeerReview: false,
  withPeerReviewNoResearchStatement: false,
  portfolioStatus: undefined,
  pureId: '',
  portfolioName: '',
  hasPortfolioMembership: false,
  withPortfolioPeerReview: false,
  portfolioForcId: undefined,
  withNoPortfolioForcApportions: false,
  withPortfolioPeerReviewNoResearchStatement: false,
  eligibility: 'eligible',
});

export default {
  name: 'ResearchOutputsSearch',
  data() {
    return {
      searchParameters: getDefaultParameters(),
    };
  },
  computed: {
    ...mapState('portfolios', ['types', 'portfolioStatuses', 'eligibility']),
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapGetters('referenceData', ['forcsForWorkingEra']),
    typeOptions() {
      return this.types.map(type => ({ label: type, value: type.split(':')[0] }));
    },
    claimForcOptions() {
      const forcsForClaims = Forc.getFourAndSixDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForClaims);
    },
    portfolioForcOptions() {
      const forcsForClaims = Forc.getFourAndSixDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForClaims);
    },
    eligibilityOptions() {
      const options = this.eligibility.map(eligibility => ({ label: eligibility.split(':')[1], value: eligibility.split(':')[0] }));
      return [...options, ...[{ label: 'All', value: '' }]];
    },
    getNonTraditionalROTypes() {
      return ResearchOutput.getNonTraditionalROTypes();
    },
    portfolioStatusOptions() {
      return this.portfolioStatuses.map(status => ({ label: status.split(':')[1], value: status.split(':')[0] }));
    },
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [ ...new Set([ ...this.departments, ...this.faculties ].map(i => (i.name))) ].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map( i => ({ label:i, value:i }) );
    },
  },
  methods: {
    ...mapActions(
      'researchOutputs',
      ['searchResearchOutputs'],
    ),
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    updateQuery(searchParams) {
      const page = searchParams.page || 1;
      const nonEmptyParameters = getNonEmptyValues(searchParams);
      this.$router.push({ query: { ...nonEmptyParameters, page } });
    },
    updateParameters(searchParams) {
      this.searchParameters = {
        ...getDefaultParameters(),
        ...searchParams,
        claimForcId: _.toInteger(searchParams.claimForcId) || undefined,
        withNoClaimForcApportions: _.trim(searchParams.withNoClaimForcApportions) === 'true',
        withPeerReview: _.trim(searchParams.withPeerReview) === 'true',
        withNoPortfolioForcApportions: _.trim(searchParams.withNoPortfolioForcApportions) === 'true',
        withPortfolioPeerReviewNoResearchStatement: _.trim(searchParams.withPortfolioPeerReviewNoResearchStatement) === 'true',
      };
      this.searchParameters.researchOutputTypes = this.searchParameters.researchOutputTypes.length > 0
        ? this.searchParameters.researchOutputTypes
        : [...this.getNonTraditionalROTypes];
    },
    search(page = 1) {
      this.updateQuery({ ...this.searchParameters, page });
    },
    searchOnUrlChange(resetPage) {
      const { query } = this.$route;
      const page = resetPage ? 1 : Number(query.page);
      this.updateParameters(query);
      this.searchResearchOutputs({
        ...this.searchParameters,
        page,
        eraId: Number(this.$route.params.eraId),
        workQueue: this.workQueueType,
        excludeLoadingFirstRecord: true,
      });

      this.$emit('searched');
    },
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    clearSearch() {
      this.updateParameters({});
    },
    hasActiveFilters() {
      const defaultParameters = _.omit(getDefaultParameters(), 'page');
      const currentParameters = _.omit(this.searchParameters, 'page');
      return !_.isEqual(defaultParameters, currentParameters);
    },
    facultyOrDepartmentChanged(entity) {
      this.searchParameters.facultyId = undefined;
      this.searchParameters.departmentId = undefined;
      if (entity.type === 'faculty') {
        this.searchParameters.facultyId = entity.id;
      }
      if (entity.type === 'department') {
        this.searchParameters.departmentId = entity.id;
      }
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
  },
  mounted() {
    this.searchOnUrlChange();
  },
};
</script>

<style lang="stylus" scoped>
  .field-groups
    & > .q-field
      margin-top 20px
</style>
