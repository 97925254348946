import _ from 'lodash';
import ClassDistribution from './class-distribution';

class ForcCitationPerformanceComparison {
  static TYPE = 'citation';

  constructor(forcId, { before, after }) {
    this.forc_id = Number(forcId);
    this.before_class_distribution = new ClassDistribution(before);
    this.after_class_distribution = new ClassDistribution(after);
  }

  // eslint-disable-next-line class-methods-use-this
  get type() {
    return ForcCitationPerformanceComparison.TYPE;
  }

  rciDifference() {
    return _.round(this.after_class_distribution.rci
      - this.before_class_distribution.rci, 2);
  }
}

export default ForcCitationPerformanceComparison;
