<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <router-view :key="$route.params.portfolioId" />
  </q-page>
</template>

<script>

export default {
  name: 'Portfolio',
  methods: {
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height };
    },
  },
};
</script>
