import { useHttp } from './http';

const http = useHttp('what_ifs');

export default {
  search: status => http.get('', { params: { status } }),
  get: id => http.get(id),
  create: whatIf => http.post('', whatIf),
  update: whatIf => http.put(whatIf.id, whatIf),
  delete: whatIf => http.delete(whatIf.id),
  createEvent: (whatIf, eventType) => http.post([whatIf.id, 'workflow'], { event_type: eventType, comment: whatIf.comment }),
  getResearchOutputs: (id, searchParams) => http.get([id, 'research_outputs'], { params: searchParams }),
  getImpactedStats: id => http.get([id, 'impacted_stats']),
  getForcCamparisons: id => http.get([id, 'analytics']),
  saveClaims: (id, claims) => http.post([id, 'claims'], claims),
};
