<template>
  <q-page class="home-page bg-white">
    <div class="home-panel bg-mq-soft-muted" @click="animate = !animate">
      <radar-logo
        class="radar-logo"
        :class="{ active: animate }"
      />
      <img class="mq-logo" src="@/assets/mq-logo.png" alt="Macquarie University">
    </div>

    <div class="q-pa-lg bg-white row col-grow justify-center">
      <div class="col-xs-12 col-lg-11">
        <h1 class="">Welcome to RADAR</h1>
        <q-card v-if="hasAnnouncements || canUserManageEra" class="home__section home__announcements">
          <q-card-title>
            {{ announcementTitle }}
            <q-btn
              v-if="canUserManageEra && !inEditMode"
              class="self-start"
              icon="edit"
              flat
              @click="editAnnouncement"
            />
          </q-card-title>
          <q-card-main class="q-ml-lg">
            <rich-text-editor
              v-if="inEditMode"
              ref="editor"
              v-model.trim="settings.announcement"
              @input="updateAnnouncement"
              @cancel="cancelEdit"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-else v-html="settings.announcement" />
          </q-card-main>
        </q-card>

        <q-card class="home__section">
          <q-card-title>Research Output saved searches</q-card-title>
          <q-card-main v-if="orderedSearchQueries.length === 0" class="q-ml-lg">
            <em>You have no saved searches.</em>
          </q-card-main>
          <q-list v-else class="q-ml-md q-py-none no-border" style="margin-top: -10px">
            <q-item v-for="query in orderedSearchQueries" :key="query.id">
              <router-link :to="roSearchLink(query)">{{ query.name }}</router-link>
            </q-item>
          </q-list>
        </q-card>

        <q-card class="home__section">
          <q-card-title>Wiki</q-card-title>
          <q-card-main class="q-ml-lg">
            <p>For more information on RADAR and user guides, please visit our Wiki page:</p>
            <a :href="wikiUrl" target="_blank">{{ wikiUrl }}</a>
          </q-card-main>
        </q-card>

        <q-card class="home__section">
          <q-card-title>User's access details</q-card-title>
          <user-access-list v-if="user.id" :user-id="user.id" readonly />
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import RadarLogo from '@/components/common/RadarLogo.vue';
import RichTextEditor from '@/components/common/RichTextEditor.vue';
import UserAccessList from '@/components/user/UserAccessList.vue';

export default {
  name: 'Home',
  components: {
    RadarLogo,
    RichTextEditor,
    UserAccessList,
  },
  data() {
    return {
      animate: false,
      logo: false,
      wikiUrl: 'https://wiki.mq.edu.au/display/nra21/RADAR',
      announcement: 'This is a demo',
      inEditMode: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('radarSettings', ['settings']),
    ...mapGetters('auth', ['canUserManageEra']),
    ...mapGetters('eras', ['activeEra']),
    ...mapGetters('researchOutputs', ['orderedSearchQueries']),
    announcementTitle() {
      return this.inEditMode ? 'Edit Announcements' : 'Announcements';
    },
    hasAnnouncements() {
      // test for "<p> </p>" where the gap between <p> and </p> can be any number of white-space characters.
      return !!this.settings.announcement && !/^<p>(\s)*<\/p>$/.test(this.settings.announcement);
    },
  },
  methods: {
    ...mapActions('researchOutputs', ['fetchSearchQueries']),
    ...mapActions('radarSettings', ['getSettings', 'saveSettings']),
    roSearchLink(searchQuery) {
      const params = { eraId: this.activeEra.id };
      const query = JSON.parse(searchQuery.data);
      return { name: 'research-outputs', params, query };
    },
    editAnnouncement() {
      this.inEditMode = true;
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    async updateAnnouncement(announcement) {
      await this.saveSettings({ announcement });
      this.inEditMode = false;
    },
    cancelEdit() {
      this.inEditMode = false;
    },
  },
  async created() {
    await Promise.all([
      this.fetchSearchQueries(),
      this.getSettings(),
    ]);
  },
};
</script>

<style lang="stylus" scoped>
.home-panel
  display flex
  justify-content space-between
  align-items center
  padding 10px 25px
  background linear-gradient(90deg, #eee 0%, $mq-soft-muted 70%)

.mq-logo
  width clamp(50px, 80vw, 300px)
  height 100%

.radar-logo
  width 120px
  color #fff
  cursor pointer

.home__announcements
  background-color rgba($primary, 20%)
  border 2px solid rgba($primary, 20%)
  border-radius 6px

  span
    color $secondary

.home__section
  box-shadow none

  & + &
    margin-top 30px

  >>> .q-card-title
    color #000
    font-size $font-size-6

  >>> .q-item,
  >>> .q-card-main
    color $blue-grey-7
    font-size $font-size-4

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
