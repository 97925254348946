import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import BulkCodeSearchAndList
  from '@/components/bulk-code/BulkCodeSearchAndList.vue';

const researchOutputRoutes = {
  path: 'bulk-code',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'bulk-code',
      component: BulkCodeSearchAndList,
      meta: {
        activeRoute: 'bulk-code',
      },
    },
  ],
};

export default researchOutputRoutes;
