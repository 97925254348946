<template>
  <div class="full-width">
    <span class="justify-start items-baseline group">
      <q-chip v-if="researchStatement.fromPreviousEra" small> PREVIOUS ERA </q-chip>
    </span>
    <form novalidate @submit.prevent="submitResearchStatement()">
      <div>
        <q-field :error="errors.has('statement')" error-label="Required">
          <q-input
            v-model.trim="statement"
            v-validate.passive="'required'"
            name="statement"
            type="textarea"
            hide-underline
            class="border border-black comfortable-textarea"
            size="xl"
            :disable="disable"
            rows="15"
            placeholder="Enter Research Statement (up to 2000 characters)"
            :maxlength="maxlength"
          />
          <div class="q-field-bottom row no-wrap">
            <div class="col text-transparent">|</div>
            <div class="q-field-counter col-auto">
              {{ researchStatement.statementCount }} / {{ maxChars }}
            </div>
          </div>
        </q-field>
        <div v-if="!disable" class="col text-mq-soft items-end field-group¯s">
          <div class="text-left r-m-large-right r-m-top">
            <div class="r-m-bottom">
              <q-btn
                type="button"
                color="mq-soft"
                label="Cancel"
                flat
                class="r-m-right"
                @click="reset"
              />
              <q-btn
                type="submit"
                color="primary"
                label="Save"
                :disable="disable"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ResearchStatement from '@/models/research-output/research-statement';
import { getCharactersOnlyByPosition } from '@/utils/formatter';

export default {
  name: 'ResearchStatementComponent',
  props: {
    researchStatement: {
      type: ResearchStatement,
      default: () => new ResearchStatement(),
    },
    disable: {
      type: Boolean,
      default: false,
    },
    maxChars: {
      type: [Number, String],
      default: 2000,
    },
  },
  computed: {
    statement: {
      get() {
        return this.researchStatement.statement;
      },
      set(value) {
        this.researchStatement.statement = getCharactersOnlyByPosition(
          value,
          this.maxChars,
        ).trim();
      },
    },
    maxlength() {
      return this.researchStatement.statementCount === this.maxChars
        ? this.statement.length
        : null;
    },
  },
  methods: {
    async reset() {
      await this.$validator.reset();
      this.$emit('reset');
    },
    async submitResearchStatement() {
      this.$emit('save', this.researchStatement.statement);
    },
  },
};
</script>

<style scoped>

</style>
