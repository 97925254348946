<template>
  <div class="relative-position column no-wrap scrollable-section">
    <div class="flex justify-between items-center border-bottom q-pb-md q-mb-md">
      <q-search
        v-model.trim="filter"
        placeholder="Filter FoRC"
        icon="mdi-filter-outline"
        class="text-black q-mt-sm q-mb-sm no-shadow filter-bar-dense"
        color="white"
        inverted
        clearable
      />
      <div v-if="canUserViewPerformanceMetrics">
        <div class="row gutter-sm">
          <div v-for="rciClass in rciClasses" :key="rciClass.name" class="col-auto">
            <q-icon :color="'mq-'+rciClass.name" name="fiber_manual_record" />
            <span class="legend">
              {{ rciClass.description }} ({{ rciClass.legend }})
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="scrollable-section">
      <q-table
        class="citation-table no-shadow bg-white full-width"
        table-class="bg-white q-px-md q-pb-md q-mt-md"
        :data="forcCitations"
        :columns="dataColumns"
        row-key="name"
        :filter="filter"
        binary-state-sort
        hide-bottom
        :filter-method="filterForcBeginsWith"
        :pagination.sync="pagination"
      >
        <q-tr slot="body" slot-scope="props" :props="props" class="bg-mq-neutral">
          <q-td
            key="forc"
            :props="props"
            :class="props.colsMap.forc.classes"
            class="border-right"
            :style="props.colsMap.forc.style"
          >
            <span class="text-weight-bold">
              {{ props.row.forc.text }}
            </span>
            <span class="on-right">{{ props.row.forc.description }}</span>
            <q-tooltip>{{ props.row.forc.description }}</q-tooltip>
          </q-td>
          <q-td
            key="count"
            :props="props"
            :class="props.colsMap.count.classes"
            class="border-right"
            :style="props.colsMap.count.style"
          >
            <span :class="props.row.isAboveCountThreshold()? 'text-positive': 'text-negative'">
              {{ Number(props.row.class_distribution.count).toFixed(2) }}
            </span>
          </q-td>
          <q-td
            v-if="canUserViewPerformanceMetrics"
            key="rci"
            :props="props"
            :class="props.colsMap.rci.classes"
            class="border-right"
            :style="props.colsMap.rci.style"
          >
            {{ Number(props.row.class_distribution.rci).toFixed(2) }}
          </q-td>
          <q-td
            v-if="canUserViewPerformanceMetrics"
            key="high_low"
            :props="props"
            :class="props.colsMap.high_low.classes"
            class="border-right"
            :style="props.colsMap.high_low.style"
          >
            {{ props.row.class_distribution.getHighForHighLowRatio() }}
            : {{ props.row.class_distribution.getLowForHighLowRatio() }}
          </q-td>
          <q-td
            v-if="canUserViewPerformanceMetrics"
            key="chart"
            :props="props"
            :style="props.colsMap.chart.style"
            :class="props.colsMap.chart.classes"
          >
            <div class="row justify-center">
              <div class="col-12 col-xl-11">
                <simple-citations-overview-chart
                  :class-distribution="props.row.class_distribution"
                  class="q-px-xl"
                />
              </div>
            </div>
          </q-td>
        </q-tr>
      </q-table>
    </div>
    <radar-loading :visible="fetchingForcCitations" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import ClassDistribution from '@/models/analytics/class-distribution';
import Forc from '@/models/era/forc';
import { tableForcFieldSearchStartWithCodeOrContainsDescription } from '@/utils/quasar-utils';
import RadarLoading from '@/components/common/RadarLoading.vue';
import SimpleCitationsOverviewChart from '../charts/SimpleCitationsOverviewChart.vue';

const numberColumnWidthStyle = 'width: 87px;';
const longNumberColumnWidthStyle = 'width: 103px;';

export default {
  name: 'CitationsOverviewTable',
  components: { RadarLoading, SimpleCitationsOverviewChart },
  data() {
    return {
      filter: '',
      pagination: {
        sortBy: 'forc',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      citationsOverviewColumns: [
        {
          name: 'forc',
          label: 'FoRCS',
          align: 'left',
          field: row => row.forc,
          sortable: true,
          sort: Forc.compareForNumberSort,
          style: 'width: 22%',
          classes: 'bg-white no-padding',
          isPerformanceMetrics: false,
        }, {
          name: 'count',
          label: 'COUNT',
          align: 'center',
          field: row => row.class_distribution.count,
          sortable: true,
          style: numberColumnWidthStyle,
          isPerformanceMetrics: false,
        }, {
          name: 'rci',
          label: 'RCI',
          align: 'center',
          field: row => row.class_distribution.rci,
          sortable: true,
          style: numberColumnWidthStyle,
          isPerformanceMetrics: true,
        }, {
          name: 'high_low',
          label: 'HIGH:LOW',
          align: 'center',
          style: longNumberColumnWidthStyle,
          isPerformanceMetrics: true,
        }, {
          name: 'chart',
          label: 'CURRENT CLASS DISTRIBUTION - NO. OF RESEARCH OUTPUTS AND PERCENTAGE',
          align: 'center',
          isPerformanceMetrics: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('analytics', {
      forcCitations: 'forcCitations',
      fetchingForcCitations: state => state.progress.fetchingForcCitations,
    }),
    ...mapGetters('referenceData', ['forcs']),
    ...mapGetters('auth', ['hasPermission']),
    canUserViewPerformanceMetrics() {
      return this.hasPermission(this.$permissions.VIEW_ANALYTICS_PERFORMANCE_METRICS);
    },
    dataColumns() {
      if (this.canUserViewPerformanceMetrics) return this.citationsOverviewColumns;
      return this.citationsOverviewColumns.filter(column => !column.isPerformanceMetrics);
    },
    rciClasses() {
      return ClassDistribution.rciClasses();
    },
  },
  methods: {
    ...mapActions('analytics', ['fetchForcCitations']),
    round(value, precision) {
      return _.round(value, precision);
    },
    filterForcBeginsWith(rows, terms, cols, cellValue) {
      return tableForcFieldSearchStartWithCodeOrContainsDescription(
        rows,
        terms,
        cols,
        cellValue,
        'forc',
      );
    },
  },
  created() {
    this.fetchForcCitations();
  },
};
</script>

<style lang="stylus" scoped>
.citation-table
  flex: 1;
  display: flex;

  >>> table
    table-layout fixed

    td:first-of-type
      overflow hidden
      text-overflow ellipsis

  >>> th
    position sticky
    top 0
    padding-bottom 10px !important
    border-bottom 1px solid rgba(0,0,0,.2)
    background-color white
    z-index 901
</style>
