<template>
  <div class="claim-viewer claim-cell-item border round-borders q-pa-xs" :class="classes">
    <div class="row items-baseline">
      <q-field class="col-6 q-pb-sm small-field-font border-right">
        <span class="q-pl-sm">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
      </q-field>
      <q-field class="col-6 q-pb-sm relative-position small-field-font">
        <span class="q-pl-sm">{{ claim.apportion }}%</span>
        <q-checkbox
          v-model="claim.is_peer_reviewed"
          :disable="!allowPeerReviewTag"
          class="absolute-right"
          left-label
        />
      </q-field>
    </div>
  </div>
</template>

<script>
import ResearchOutputClaim from '@/models/research-output/research-output-claim';

export default {
  name: 'ClaimViewer',
  props: {
    claim: {
      type: ResearchOutputClaim,
      required: true,
    },
    classes: {
      type: String,
      default: 'bg-white',
    },
    allowPeerReviewTag: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="stylus" scoped>
  .claim-viewer
    height 36px
</style>
