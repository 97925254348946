<template>
  <form class="row justify-between" @submit.prevent="search()">
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            color="mq-soft"
            label="Clear"
            flat
            class="q-mr-md"
            @click="clearSearch()"
          />
          <q-btn type="submit" color="primary" label="Search" />
        </div>
      </div>
      <div class="border round-borders q-pa-lg bg-grey-3">
        <q-field label="Saved Search Queries">
          <q-select
            v-model="selectedQuery"
            :options="queryOptions"
            clearable
            @input="retrieveSearchQuery()"
          />
        </q-field>
        <div class="q-mt-lg flex justify-end">
          <q-btn
            type="button"
            icon="save"
            color="green-9"
            label="Save Search"
            outline
            @click="confirmSaveQuery()"
          />
          <q-btn
            label="Delete"
            icon="delete"
            color="mq-bright-red-soft"
            class="q-ml-md"
            :disable="!selectedQuery"
            outline
            @click="confirmDeleteQuery()"
          />
        </div>
      </div>

      <q-field label="Author Name/MQ Id">
        <q-input v-model.trim="searchParameters.authorNameContains" />
      </q-field>
      <q-field label="Author Faculty/Department/Schools">
        <q-select
          v-model="searchParameters.facultyOrDepartmentName"
          :options="facultyOrDepartmentOptions"
          autofocus-filter
          :filter="labelContainsFilter"
          clearable
        />
      </q-field>

      <q-field label="ERA Category">
        <q-select
          v-model="searchParameters.researchOutputTypes"
          :options="typeOptions"
          :disable="fieldsToMarkReadonly.category"
          clearable
          multiple
          chips
          @input="inputCategoryChange"
        />
      </q-field>

      <q-field label="Pure ID">
        <q-input v-model.trim="searchParameters.pureId" clearable />
      </q-field>

      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" />
      </q-field>

      <div class="row q-field">
        <div class="q-field-label q-field-margin col-xs-12 col-sm-5">Year</div>
        <div class="q-field-content col-xs-12 col-sm row justify-between">
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">Start</span>
            <q-select
              v-model="searchParameters.yearStart"
              class="col"
              align="center"
              :options="yearStartOptions"
              clearable
              autofocus-filter
            />
          </div>
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">End</span>
            <q-select
              v-model="searchParameters.yearEnd"
              class="col"
              align="center"
              :options="yearEndOptions"
              clearable
              autofocus-filter
            />
          </div>
        </div>
      </div>

      <q-field label="Parent Doc">
        <q-input v-model.trim="searchParameters.parentDoc" />
      </q-field>
      <q-field label="ARC Journal FoRCs" class="r-p-vertical">
        <q-select
          v-model="searchParameters.journalForcIds"
          :options="journalForcOptions"
          :disable="searchParameters.withMdJournalForc"
          autofocus-filter
          :filter="labelStartWithOrSublabelContainsFilter"
          clearable
          multiple
        />
        <q-checkbox
          v-model="searchParameters.withMdJournalForc"
          class="q-if-label-above floating-label-font q-mt-lg"
          label="With MD ARC Journal FoRCs"
        />
      </q-field>

      <q-field label="Claim FoRCs" class="r-p-vertical">
        <q-field
          :helper="
            searchParameters.withPeerReview
              ? 'Will search peer review tags associated with this claim only'
              : ''
          "
        >
          <q-select
            v-model="searchParameters.claimForcIds"
            :options="claimForcOptions"
            :disable="searchParameters.withNoClaimForcApportions"
            :filter="labelStartWithOrSublabelContainsFilter"
            autofocus-filter
            multiple
            chips
            clearable
          />
        </q-field>
        <q-checkbox
          v-model="searchParameters.withNoClaimForcApportions"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="With no Claim FoRC apportions"
        />
        <q-checkbox
          v-model="searchParameters.withPeerReview"
          color="black"
          :disable="searchParameters.withNoClaimForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged"
        />
        <q-checkbox
          v-model="searchParameters.notClaimedToMyForcs"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Not Claimed to my Forcs"
        />
      </q-field>

      <q-field label="Status" class="r-p-vertical">
        <q-select
          v-model="searchParameters.roStatus"
          :options="roStatusOptions"
          :disable="fieldsToMarkReadonly.status"
          autofocus-filter
          clearable
        />
      </q-field>

      <q-field label="Indigenous Research" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.isIndigenousResearch"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="yesNoAllRecordsOptions"
          clearable
        />
      </q-field>

      <q-field label="MQ Bylines" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.hasBylines"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="yesNoAllRecordsOptions"
          clearable
        />
      </q-field>

      <q-field v-if="canUserViewCitationInRO" label="Citations" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.citationData"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="citation_data_options"
          clearable
        />
      </q-field>

      <q-field label="Eligibility" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.eligibility"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="eligibilityOptions"
        />
      </q-field>

      <q-field class="r-p-vertical">
        <template v-slot:label>
          <div class="row items-center all-pointer-events">
            FoRC Interests
            <q-tooltip>
              RO's with Author, Departmental or ARC Journal FoRCs that match the
              selected FoRC Interest
            </q-tooltip>
          </div>
        </template>

        <q-select
          v-model="searchParameters.interestForcId"
          :options="journalForcOptions"
          :filter="labelStartWithOrSublabelContainsFilter"
          :disable="searchParameters.interestsForAllMyForcs"
          autofocus-filter
          clearable
        />
        <q-checkbox
          v-model="searchParameters.interestsForAllMyForcs"
          class="q-if-label-above floating-label-font q-mt-lg"
          label="All my FoRCs"
        />
      </q-field>

      <div class="r-p-vertical">
        <div>Claim Options</div>
        <q-field label="My Claims" inset="icon" class="q-mt-md">
          <q-option-group
            v-model="searchParameters.myClaims"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="myClaimOptions"
          />
        </q-field>
        <q-field label="Other User Claims" inset="icon" class="q-mt-lg">
          <q-option-group
            v-model="searchParameters.otherUserClaims"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="otherUserClaimOptions"
          />
        </q-field>
        <q-field label="Recently Claimed" inset="icon" class="q-mt-lg">
          <q-option-group
            v-model="searchParameters.recentlyClaimed"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="recentlyClaimedOptions"
          />
        </q-field>
      </div>
      <div class="q-mt-xl text-right">
        <q-btn
          type="button"
          color="mq-soft"
          label="Clear"
          flat
          class="q-mr-md"
          @click="clearSearch()"
        />
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  labelContainsFilter,
  labelStartWithOrSublabelContainsFilter,
} from '@/utils/search';
import Forc from '@/models/era/forc';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputSearchFieldState from '@/models/research-output/research-output-search-fields-state';
import ResearchOutputSearchParam, {
  CLAIM_OPTIONS_MINE,
  CLAIM_OPTIONS_OTHERS,
  CLAIM_OPTIONS_RECENT,
} from '@/models/research-output/research-output-search-param';

const ALL_RO_TYPES = {
  ALL_TRADITIONAL: 'ALL_TRADITIONAL',
  ALL_NTRO: 'ALL_NTRO',
};

export default {
  name: 'ResearchOutputsSearch',
  props: {
    workQueueType: {
      type: String,
      default: null,
    },
    researchOutputTypes: {
      type: Array,
      default: () => [],
    },
    roStatus: {
      type: String,
      default: '',
    },
    eligibilityStatus: {
      type: String,
      default: '',
    },
    claimsHasMyForcs: {
      type: Boolean,
      default: false,
    },
    fieldsToMarkReadonly: {
      type: Object,
      default: () => new ResearchOutputSearchFieldState(),
    },
  },
  data() {
    return {
      searchParameters: new ResearchOutputSearchParam(),
      allROTypes: ALL_RO_TYPES,
      selectedQuery: null,
    };
  },
  computed: {
    ...mapState('researchers', ['eligibility']),
    ...mapState('researchOutputs', ['types', 'roStatuses', 'searchQueries']),
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapGetters('referenceData', [
      'forcsForWorkingEra',
      'twoAndFourDigitForcByReferenceMetaId',
    ]),
    ...mapGetters('eras', ['workingEra', 'researchOutputYearRange']),
    ...mapGetters('researchOutputs', ['orderedSearchQueries']),
    ...mapGetters('auth', ['canUserViewCitationInRO']),
    typeOptions() {
      const allTypes = [
        { label: "All NTRO's", value: this.allROTypes.ALL_NTRO },
        {
          label: "All Traditional RO's",
          value: this.allROTypes.ALL_TRADITIONAL,
        },
      ];
      const types = this.types.map(type => ({
        label: type,
        value: type.split(':')[0],
      }));
      return [...allTypes, ...types];
    },
    journalForcOptions() {
      const forcsForJournals = Forc.getTwoAndFourDigitForcs(
        this.forcsForWorkingEra,
      );
      return this.forcOptions(forcsForJournals);
    },
    claimForcOptions() {
      const sortedForcs = _.sortBy(
        this.twoAndFourDigitForcByReferenceMetaId(
          this.workingEra.forc_reference_meta_id,
        ),
        forc => forc.text,
      );
      return this.forcOptions(sortedForcs);
    },
    getNonTraditionalROTypes() {
      return ResearchOutput.getNonTraditionalROTypes();
    },
    getTraditionalROTypes() {
      return ResearchOutput.getTraditionalROTypes();
    },
    roStatusOptions() {
      return this.roStatuses.map(status => ({
        label: status.split(':')[1],
        value: status.split(':')[0],
      }));
    },
    queryOptions() {
      return this.orderedSearchQueries.map(q => ({
        label: q.name,
        value: q.id,
      }));
    },
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [
        ...new Set([...this.departments, ...this.faculties].map(i => i.name)),
      ].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map(i => ({ label: i, value: i }));
    },
    eligibilityOptions() {
      const options = this.eligibility.map(eligibility => ({
        label: eligibility.split(':')[1],
        value: eligibility.split(':')[0],
      }));
      return [...options, ...[{ label: 'All records', value: 'all' }]];
    },
    yearStartOptions() {
      return this.researchOutputYearRange.map(year => ({
        label: `${year}`,
        value: year,
      }));
    },
    yearEndOptions() {
      const startYear = this.searchParameters.yearStart
        || this.workingEra.research_output_start_year;
      return _.range(
        startYear,
        this.workingEra.research_output_end_year + 1,
      ).map(year => ({ label: `${year}`, value: year }));
    },
    myClaimOptions() {
      return Object.values(CLAIM_OPTIONS_MINE);
    },
    otherUserClaimOptions() {
      return Object.values(CLAIM_OPTIONS_OTHERS);
    },
    recentlyClaimedOptions() {
      return Object.values(CLAIM_OPTIONS_RECENT);
    },
    yesNoAllRecordsOptions() {
      return [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
        { label: 'All Records', value: '' },
      ];
    },
    citation_data_options() {
      return [
        { label: "RO's with citation", value: 'YES' },
        { label: "RO's without citation", value: 'NO' },
        { label: "RO's with WOS citation", value: 'WOS' },
        { label: "RO's with SCOPUS citation", value: 'SCOPUS' },
        { label: 'All Records', value: '' },
      ];
    },
    propsData() {
      return {
        researchOutputTypes: this.researchOutputTypes,
        roStatus: this.roStatus,
        eligibility: this.eligibilityStatus,
        claimsHasMyForcs: this.claimsHasMyForcs,
      };
    },
  },
  methods: {
    ...mapActions('researchOutputs', [
      'searchResearchOutputs',
      'saveClaim',
      'deleteClaim',
      'fetchClaims',
      'fetchSearchQueries',
      'deleteSearchQuery',
      'createSearchQuery',
    ]),
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    inputCategoryChange(value) {
      if (_.includes(value, this.allROTypes.ALL_NTRO)) {
        this.searchParameters.researchOutputTypes = _.uniq([
          ...this.searchParameters.researchOutputTypes,
          ...this.getNonTraditionalROTypes,
        ]);
      }
      if (_.includes(value, this.allROTypes.ALL_TRADITIONAL)) {
        this.searchParameters.researchOutputTypes = _.uniq([
          ...this.searchParameters.researchOutputTypes,
          ...this.getTraditionalROTypes,
        ]);
      }
    },
    updateQuery(searchParams) {
      const page = searchParams.page || 1;
      const nonEmptyParameters = Object.fromEntries(
        Object.entries(searchParams).filter(v => !!v[1]),
      );
      this.$router
        .push({ query: { ...nonEmptyParameters, page } })
        .catch((e) => {
          if (e.name !== 'NavigationDuplicated') {
            throw e;
          }
        });
    },
    updateParameters(searchParams) {
      this.searchParameters = ResearchOutputSearchParam.create(searchParams);
      this.searchParameters.researchOutputTypes = _.pull(
        searchParams.researchOutputTypes,
        this.allROTypes.ALL_NTRO,
        this.allROTypes.ALL_TRADITIONAL,
      ) || [];
    },
    search(page = 1) {
      this.updateQuery({ ...this.searchParameters, page });
      this.$emit('searched');
    },
    searchOnUrlChange(resetPage) {
      const { query } = this.$route;
      const page = resetPage ? 1 : Number(query.page);
      this.updateParameters({
        ...this.propsData,
        ...query,
      });
      this.searchResearchOutputs({
        ...this.searchParameters,
        page,
        eraId: Number(this.$route.params.eraId),
        workQueue: this.workQueueType,
      });

      this.$emit('searched');
    },
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    clearSearch() {
      this.updateParameters({ ...this.propsData });
    },
    hasActiveFilters() {
      const defaultParameters = _.omit(new ResearchOutputSearchParam(), 'page');
      const currentParameters = _.omit(this.searchParameters, 'page');
      return !_.isEqual(defaultParameters, currentParameters);
    },
    retrieveSearchQuery() {
      const searchParam = _.find(this.searchQueries, {
        id: this.selectedQuery,
      });
      if (searchParam) {
        const query = JSON.parse(searchParam.data);
        this.searchParameters = ResearchOutputSearchParam.create(query);
      }
    },
    async confirmDeleteQuery() {
      try {
        await this.$q.dialog({
          title: 'Delete search query?',
          cancel: true,
        });
        await this.deleteSearchQuery(this.selectedQuery);
        this.selectedQuery = null;
      } catch (err) {
        // Picked "Cancel" or dismissed so don't delete query
      }
    },
    async confirmSaveQuery() {
      try {
        const name = await this.$q.dialog({
          title: 'Save search query?',
          message: 'Enter a name for your query',
          prompt: {
            model: '',
            type: 'text',
          },
          cancel: true,
        });
        const nameExists = _.find(this.searchQueries, { name });
        if (nameExists) {
          this.$notify.failure(
            'This name is already used by another saved query. Please choose another name.',
          );
        } else {
          const savedQuery = {
            module: 'RESEARCH_OUTPUT',
            name,
            data: JSON.stringify(this.searchParameters),
          };
          await this.createSearchQuery(savedQuery);
          this.selectedQuery = null;
        }
      } catch (err) {
        // Picked "Cancel" or dismissed so don't save query
      }
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
  },
  mounted() {
    this.searchOnUrlChange();
  },
};
</script>

<style lang="stylus" scoped>
.field-groups {
  & > .q-field {
    margin-top: 20px;
  }
}

.block.inset {
  margin-left: -30px;
}
</style>
