<template>
  <div class="relative-position column no-wrap scrollable-section">
    <div class="row full-width q-pr-lg border-bottom items-baseline justify-between">
      <div class="col-4 col-lg-3 col-xl-2">
        <q-search
          v-model.trim="filter"
          placeholder="Filter FoRC"
          icon="mdi-filter-outline"
          class="text-black q-mt-sm q-mb-sm no-shadow filter-bar-dense"
          color="white"
          inverted
          clearable
        />
      </div>
      <div class="col-auto q-pl-xl">
        <span class="term-title-small on-left">Show/Hide columns</span>
        <q-btn-toggle
          v-model="displayCategories"
          no-wrap
          size="sm"
          class="text-secondary bg-white"
          toggle-color="secondary"
          :options="[
            { label: 'All', value: 'all' },
            { label: 'Traditional', value: 'traditional' },
            { label: 'NTRO', value: 'NTRO' },
            { label: 'Portfolio', value: 'portfolio' }
          ]"
        />
      </div>
    </div>
    <div class="scrollable-section">
      <q-table
        :columns="columns"
        :data="peerReviews"
        class="analytics-overview-table bg-white no-shadow"
        :filter="filter"
        :filter-method="filterForcBeginsWith"
        :separator="separator"
        binary-state-sort
        :visible-columns="visibleColumns"
        hide-bottom
        :pagination.sync="pagination"
        table-class="bg-white q-px-sm q-pb-sm q-mt-sm"
      >
        <q-tr slot="header" slot-scope="props" :props="props" class="vertical-bottom">
          <q-th
            key="forc"
            :props="props"
            :class="props.colsMap.forc.classes"
            :style="props.colsMap.forc.style"
          >
            <span class="wrappable-table-column">
              FoRCS
            </span>
          </q-th>
          <q-th
            key="weighted_count"
            :props="props"
            :class="props.colsMap.weighted_count.classes"
            :style="props.colsMap.weighted_count.style"
          >
            <span class="wrappable-table-column">
              Count (W)
              <q-tooltip>
                Apportioned Count (Weighted)
              </q-tooltip>
            </span>
          </q-th>
          <q-th
            key="count"
            :props="props"
            :class="props.colsMap.count.classes"
            :style="props.colsMap.count.style"
          >
            <span class="wrappable-table-column">
              Count (NW)
              <q-tooltip>
                Apportioned Count (Non-Weighted)
              </q-tooltip>
            </span>
          </q-th>
          <q-th
            key="threshold"
            :props="props"
            :class="props.colsMap.threshold.classes"
            :style="props.colsMap.threshold.style"
          >
            <span class="wrappable-table-column">
              Total T/hold
              <q-tooltip>
                Total number of NW outputs to be tagged to meet threshold
              </q-tooltip>
            </span>
          </q-th>
          <q-th
            key="tagged_count"
            :props="props"
            :class="props.colsMap.tagged_count.classes"
            :style="props.colsMap.tagged_count.style"
          >
            <span class="wrappable-table-column">
              Total Tagged
            </span>
          </q-th>
          <template v-for="col in columns">
            <q-th
              v-if="col.name.startsWith('cat_') && props.colsMap[col.name]"
              :key="col.name"
              :props="props"
              :class="props.colsMap[col.name].classes"
              class="col-category"
              style="height: 55px"
            >
              <div class="row items-end no-wrap">
                <div class="text-white cat-label xbg-mq-soft-muted absolute-top">{{ col.label }}</div>
                <span class="col-6">30%</span>
                <span class="col-6">Tag</span>
              </div>
            </q-th>
          </template>
        </q-tr>

        <q-tr slot="body" slot-scope="props" :props="props">
          <q-td
            key="forc"
            :props="props"
            :class="props.colsMap.forc.classes"
            :style="props.colsMap.forc.style"
          >
            <span class="text-weight-bold">
              {{ getForcById(props.row.forc_id).text }}
            </span>
            <span class="on-right">{{ getForcById(props.row.forc_id).description }}</span>
            <q-tooltip>{{ getForcById(props.row.forc_id).description }}</q-tooltip>
          </q-td>
          <q-td
            key="weighted_count"
            :props="props"
            class="bg-mq-sand border-right"
            :class="props.colsMap.weighted_count.classes"
            :style="props.colsMap.weighted_count.style"
          >
            <span :class="props.row.isAboveCountThreshold()? 'text-positive':'text-negative'">
              {{ props.row.weighted_count }}
            </span>
          </q-td>
          <q-td
            key="count"
            :props="props"
            class="bg-mq-sand border-right"
            :class="props.colsMap.count.classes"
            :style="props.colsMap.count.style"
          >
            {{ props.row.count }}
          </q-td>
          <q-td
            key="threshold"
            :props="props"
            class="border-right bg-mq-sand"
            :class="props.colsMap.threshold.classes"
            :style="props.colsMap.threshold.style"
          >
            {{ props.row.threshold }}
          </q-td>
          <q-td
            key="tagged_count"
            :props="props"
            class="border-right bg-mq-sand"
            :class="props.colsMap.tagged_count.classes"
            :style="props.colsMap.tagged_count.style"
          >
            <span :class="props.row.hasBeenTaggedToThreshold()? 'text-positive':'text-negative'">
              {{ props.row.tagged_count }}
            </span>
          </q-td>
          <template v-for="col in columns">
            <q-td
              v-if="col.name.startsWith('cat_') && props.colsMap[col.name]"
              :key="col.name"
              :props="props"
              :class="props.colsMap[col.name].classes"
              class="col-category"
            >
              <div class="row items-center no-wrap">
                <span class="col-6">{{ props.row[col.label].threshold }}</span>
                <span class="col-6">{{ props.row[col.label].tagged_count }}</span>
              </div>
            </q-td>
          </template>
        </q-tr>
      </q-table>
    </div>
    <radar-loading :visible="fetchingPeerReviews" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Forc from '@/models/era/forc';
import { tableForcFieldSearchStartWithCodeOrContainsDescription } from '@/utils/quasar-utils';
import RadarLoading from '@/components/common/RadarLoading.vue';

export default {
  name: 'PeerReviewOverviewTable',
  components: { RadarLoading },
  data() {
    return {
      filter: '',
      separator: 'horizontal',
      pagination: {
        sortBy: 'forc',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      displayCategories: 'all',
      citationCategoriesColumns: [
        'cat_a1',
        'cat_b1',
        'cat_c1',
        'cat_e1'],
      peerReviewCategoriesColumns: [
        'cat_q1',
        'cat_q2',
        'cat_q3',
        'cat_q4',
        'cat_q5'],
      portfolioPeerReviewCategoriesColumns: [
        'cat_portfolio',
      ],
      columns: [
        {
          name: 'forc',
          align: 'left',
          field: row => this.getForcById(row.forc_id),
          sortable: true,
          required: true,
          classes: 'no-padding',
          style: 'width: clamp(200px, 15vw, 300px)',
          sort: Forc.compareForNumberSort,
        },
        {
          name: 'weighted_count',
          field: 'weighted_count',
          align: 'center',
          classes: 'no-padding',
          required: true,
        },
        {
          name: 'count',
          field: 'count',
          align: 'center',
          classes: 'no-padding',
          required: true,
        },
        {
          name: 'threshold',
          align: 'center',
          field: 'threshold',
          classes: 'no-padding',
          required: true,
        },
        {
          name: 'tagged_count',
          align: 'center',
          field: 'tagged_count',
          classes: 'no-padding',
          required: true,
        },
        {
          name: 'cat_a1',
          align: 'center',
          field: 'a1_threshold',
          label: 'A1',
        },
        {
          name: 'cat_b1',
          align: 'center',
          field: 'b1_threshold',
          label: 'B1',
        },
        {
          name: 'cat_c1',
          align: 'center',
          field: 'c1_threshold',
          label: 'C1',
        },
        {
          name: 'cat_e1',
          align: 'center',
          field: 'e1_threshold',
          label: 'E1',
        },
        {
          name: 'cat_q1',
          align: 'center',
          field: 'q1_threshold',
          label: 'Q1',
        },
        {
          name: 'cat_q2',
          align: 'center',
          field: 'q2_threshold',
          label: 'Q2',
        },
        {
          name: 'cat_q3',
          align: 'center',
          field: 'q3_threshold',
          label: 'Q3',
        },
        {
          name: 'cat_q4',
          align: 'center',
          field: 'q4_threshold',
          label: 'Q4',
        },
        {
          name: 'cat_q5',
          align: 'center',
          field: 'q5_threshold',
          label: 'Q5',
        },
        {
          name: 'cat_portfolio',
          align: 'center',
          field: 'portfolio_threshold',
          label: 'Portfolio',
        },
      ],
    };
  },
  computed: {
    ...mapState('analytics', {
      peerReviews: 'peerReviews',
      fetchingPeerReviews: state => state.progress.fetchingPeerReviews,
    }),
    ...mapGetters('referenceData', ['forcs']),
    visibleColumns() {
      if (this.displayCategories === 'all') {
        return [...this.citationCategoriesColumns, ...this.peerReviewCategoriesColumns, ...this.portfolioPeerReviewCategoriesColumns];
      } if (this.displayCategories === 'traditional') {
        return this.citationCategoriesColumns;
      } if (this.displayCategories === 'portfolio') {
        return this.portfolioPeerReviewCategoriesColumns;
      }
      return this.peerReviewCategoriesColumns;
    },
  },
  methods: {
    ...mapActions('analytics', ['fetchPeerReviews']),
    getForcById(id) {
      return this.forcs.find(forc => forc.id === Number(id));
    },
    filterForcBeginsWith(rows, terms, cols, cellValue) {
      return tableForcFieldSearchStartWithCodeOrContainsDescription(
        rows,
        terms,
        cols,
        cellValue,
        'forc',
      );
    },
  },
  created() {
    this.fetchPeerReviews();
  },
};
</script>
<style lang="stylus" scoped>
.analytics-overview-table
  flex: 1;
  display: flex;

  >>> table
    table-layout fixed

    td:first-of-type
      overflow hidden
      text-overflow ellipsis

  >>> th
    position sticky
    top 0
    padding-bottom 10px !important
    border-bottom 1px solid rgba(0,0,0,.2)
    background-color white
    z-index 901

    &.col-category span
      font-family 'Roboto Condensed', sans-serif

.col-category
  padding 0
  min-width 90px

  &:nth-of-type(even)
    background-color $mq-neutral

.cat-label
  padding 2px 0
  background-color rgba($mq-soft-muted, .55)
</style>
