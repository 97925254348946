import { mapGetters } from 'vuex';

const PermissionsMixin = {
  computed: {
    ...mapGetters('auth', [
      'userForcsForPermissions',
      'userForcsForRoClaim',
      'userForcsForRoL1Escalations',
      'userForcsForRoL2Escalations',
      'userForcsForRoL1Resolutions',
      'canUserResolveL2',
      'canUserManageEra',
      'canUserManageResearcherClaim',
      'canUserManageResearchOutputClaim',
      'canUserManageResearchStatement',
      'canUserManageResearchIncomeClaim',
      'canUserManageAppliedMeasureClaim',
      'canUserManagePortfolio',
      'canUserFinalisePortfolio',
      'canUserUnfinalisePortfolio',
      'canUserViewXmlSubmission',
    ]),
  },
  methods: {},
};

export default PermissionsMixin;
