<template>
  <div class="full-page bg-white">
    <bulk-code-search />
    <bulk-code-list />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BulkCodeList from './BulkCodeList.vue';
import BulkCodeSearch from './BulkCodeSearch.vue';

export default {
  name: 'BulkCodeSearchAndList',
  components: {
    BulkCodeList,
    BulkCodeSearch,
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
  },
  methods: {
    ...mapActions('bulkCode', ['resetBulk']),
  },
  watch: {
    workingEra() {
      this.resetBulk();
    },
  },
  mounted() {
    this.resetBulk();
  },
};
</script>
