<template>
  <div>
    <div v-if="isMemberOfPortfolio" class="col-6 q-mt-lg">
      <div class="bg-white q-pa-lg round-borders border border-mq-magenta flex items-center">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
        <span>Note: The Research Statement for this research output is no longer editable because it is part of a portfolio.</span>
      </div>
    </div>
    <research-statement-component
      :research-statement="researchStatement"
      :disable="disable"
      @save="submitResearchStatement"
      @reset="reset"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ResearchStatementComponent from '@/components/common/ResearchStatementComponent.vue';

export default {
  name: 'ResearchOutputResearchStatement',
  components: {
    ResearchStatementComponent,
  },
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    isMemberOfPortfolio: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('researchStatements', ['researchStatement']),
    ...mapGetters('researchStatements', ['latestResearchStatement']),
  },
  methods: {
    ...mapActions(
      'researchStatements',
      ['saveResearchStatement'],
    ),
    async reset() {
      this.researchStatement.statement = this.latestResearchStatement.statement;
    },
    async submitResearchStatement(statement) {
      await this.$validator.validateAll();
      if (this.errors.any()) return;

      try {
        await this.saveResearchStatement({ statement, researchStatementId: this.researchStatement.id });
        this.$notify.success('Research Statement successfully saved.');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
};
</script>
