import _ from 'lodash';
import Forc from '@/models/era/forc';
import User from '@/models/user/user';

class ResearchOutputClaim {
  constructor(researchOutputClaim = {}) {
    this.id = researchOutputClaim.id;
    this.forc = researchOutputClaim.forc
      && new Forc(researchOutputClaim.forc);
    this.apportion = researchOutputClaim.apportion;
    this.comment = researchOutputClaim.comment;
    this.research_output_id = researchOutputClaim.research_output_id;
    this.era_submission_id = researchOutputClaim.era_submission_id;
    this.action = researchOutputClaim.action;
    this.is_active = researchOutputClaim.is_active;
    this.previous_claim = researchOutputClaim.previous_claim;
    this.updated_at = researchOutputClaim.updated_at;
    this.created_by = researchOutputClaim.created_by
      && new User(researchOutputClaim.created_by);
    this.is_peer_reviewed = researchOutputClaim.is_peer_reviewed || false;
  }

  static fromList(researchOutputClaims = []) {
    return researchOutputClaims
      .map(researchOutputClaim => new ResearchOutputClaim(researchOutputClaim));
  }

  isClawback() {
    return this.forc ? this.forc.isSixDigitForc() : false;
  }

  isNew() {
    return !this.id;
  }

  isActive() {
    return this.is_active;
  }

  isPeerReviewed() {
    return this.is_peer_reviewed;
  }

  isPeerReviewEligible() {
    return this.forc ? this.forc.is_peer_review_eligible : false;
  }

  isSameAs(that) {
    return this.forc.id === that.forc.id
      && this.apportion === that.apportion
      && this.is_peer_reviewed === that.is_peer_reviewed;
  }

  basicDetails(includeComment = true) {
    const basicDetails = {
      forc_id: this.forc.id,
      apportion: this.apportion,
      is_peer_reviewed: this.is_peer_reviewed,
    };
    if (includeComment) basicDetails.comment = this.comment;
    return basicDetails;
  }

  actingUserDisplayName() {
    if (_.isNil(this.created_by)) return 'System Change';
    return this.created_by ? this.created_by.displayName() : '';
  }

  validate(journalForcs = [], isArcRestricted = false, clawbackBypassedForcs = []) {
    const forcValidForJournal = !isArcRestricted
      || this.forc.isWithinRangeOfAny(journalForcs);
    const errorCollector = [];

    // eslint-disable-next-line no-underscore-dangle
    this._validateClaimWithinArcJournal(forcValidForJournal, errorCollector);

    // eslint-disable-next-line no-underscore-dangle
    this._validateClaimOutsideArcJournal(forcValidForJournal, errorCollector, clawbackBypassedForcs);
    return errorCollector;
  }

  // eslint-disable-next-line no-underscore-dangle
  _validateClaimWithinArcJournal(forcValidForJournal, errorCollector) {
    if (!forcValidForJournal) return;

    if (!this.forc.isFourDigitForc()) {
      errorCollector.push(`${this.forc.text} needs to be a four digit FoRC.`);
    } else if (this.apportion < 20 || this.apportion > 100) {
      errorCollector.push(`FoRC ${this.forc.text}'s apportion must be 20-100%`);
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  _validateClaimOutsideArcJournal(forcValidForJournal, errorCollector, clawbackBypassedForcs = []) {
    if (forcValidForJournal || (this.forc.isWithinRangeOfAny(clawbackBypassedForcs) && !this.forc.isSixDigitForc())) return;
    if (!this.forc.isSixDigitForc()) {
      errorCollector.push(`${this.forc.text} needs to be a six digit FoRC with >=66% apportionment assigned`);
    } else if (this.apportion < 66 || this.apportion > 100) {
      errorCollector.push(`FoRC ${this.forc.text}'s apportion must be 66-100%`);
    }
  }
}

export default ResearchOutputClaim;
