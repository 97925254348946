class DataQualityCount {
  constructor(arc = 0, mq = 0) {
    this.arc = arc;
    this.mq = mq;
    this.total = arc + mq;
  }

  static add(a, b) { return new DataQualityCount(a.arc + b.arc, a.mq + b.mq); }
}

export default DataQualityCount;
