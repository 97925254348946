import { Validator } from 'vee-validate';

Validator.extend(
  'after_year',
  (value, [otherValue]) => {
    if (!otherValue) return true;
    return new Date(value).getFullYear() > otherValue;
  }, {
    hasTarget: true,
  },
);
