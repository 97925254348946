import _ from 'lodash';
import api from '@/api';
import Era from '@/models/era/era';

export default {
  namespaced: true,
  state: {
    eras: [],
    era: new Era(),
    workingEra: new Era(),
    areErasInitialised: false,
    initializedEraId: undefined,
    progress: {
      loading: false,
      saving: false,
    },
  },
  mutations: {
    UPDATE_WORKING_ERA(state, era) {
      state.workingEra = era;
    },
    UPDATE_ERAS(state, eras) {
      state.eras = eras;
      state.areErasInitialised = true;
    },
    UPDATE_ERA(state, era) {
      state.era = era;
    },
    UPDATE_ERA_WITH(state, params) {
      state.era.updateWith(params);
    },
    UPDATE_PROGRESS(state, progress) {
      state.progress = { ...state.progress, ...progress };
    },
  },
  actions: {
    async saveEra({ commit, dispatch }, era) {
      let eraResponse;
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        if (era.id) {
          eraResponse = await api.eras.update(era.id, era.basicInfo());
        } else {
          era = new Era(era);
          eraResponse = await api.eras.create(era.basicInfo());
        }
        commit('UPDATE_ERA', new Era(eraResponse.data));
        commit('UPDATE_WORKING_ERA', new Era(eraResponse.data));
        await dispatch('getAllEras');
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },

    async saveSubmissionStage({ state, commit, dispatch }, { era, stage }) {
      const eraResponse = await api.eras.submitStage(era.id, stage);

      commit('UPDATE_ERA_WITH', { submission_stage: eraResponse.data.submission_stage });

      await dispatch('getAllEras');
      if (era.id === state.workingEra.id) {
        await dispatch('selectWorkingEraById', era.id);
      }
    },

    async getAllEras({ commit }) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const response = await api.eras.getAll();
        const eras = Era.fromList(response.data.results);
        return commit('UPDATE_ERAS', eras);
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },

    async getEra({ commit }, eraId) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const response = await api.eras.get(eraId);
        const era = new Era(response.data);
        return commit('UPDATE_ERA', era);
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },

    resetEra({ commit }) {
      commit('UPDATE_ERA', new Era());
    },

    async selectWorkingEraById({ state, commit, getters, dispatch }, id) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        // eslint-disable-next-line eqeqeq
        const eraById = state.eras.find(era => era.id == id)
        || getters.activeEra || new Era();

        await Promise.all([
          dispatch('auth/initialize', { eraId: eraById.id }, { root: true }),
          dispatch('referenceData/getAllForcs', { metaId: eraById.forc_reference_meta_id }, { root: true }),
        ]);
        commit('UPDATE_WORKING_ERA', eraById);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },

    async fetchEraAndUpdateWorkingEra({ state, dispatch }, eraId) {
      if (state.eras.length === 0) {
        await dispatch('getAllEras');
      }
      await dispatch('selectWorkingEraById', eraId);
    },

    async uploadBenchmark({ commit }, file) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        await api.eras.uploadBenchmark(file);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },

    async downloadBenchmark({ commit }) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const response = await api.eras.downloadBenchmark();
        return response.data;
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },
  },
  getters: {
    areErasInitialised(state) {
      return state.areErasInitialised;
    },
    eras(state) {
      return state.eras;
    },
    workingEra(state) {
      return state.workingEra;
    },
    activeEra(state) {
      return state.eras.find(era => era.isActive());
    },
    hasActiveAndUpcomingEras(state) {
      return (state.eras.find(era => era.isActive())
        && state.eras.find(era => era.isUpcoming()));
    },
    researchOutputYearRange: state => _.range(state.workingEra.research_output_start_year, state.workingEra.research_output_end_year + 1),
    researchIncomeYearRange: state => _.range(state.workingEra.research_income_start_year, state.workingEra.research_income_end_year + 1),
    appliedMeasureYearRange: state => _.range(state.workingEra.applied_measures_start_year, state.workingEra.applied_measures_end_year + 1),
  },
};
