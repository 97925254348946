<template>
  <form @submit.prevent="submit()">
    <div class="col-auto">
      <div class="r-heading-1-dense text-primary r-p-vertical">
        Submit valid claims
      </div>
      <div class="col text-mq-soft items-end field-groups">
        <p class="caption">Are you sure you want to submit {{ validResearchOutputs.length }} valid claims?</p>
        <q-field>
          <q-input
            v-model.trim="comment"
            v-validate="'no_op'"
            type="textarea"
            hide-underline
            class="border border-black comfortable-textarea"
            rows="25"
            placeholder="Enter Comment"
            name="bulk-code-comment"
            :max-height="48"
            maxlength="1000"
          />
        </q-field>
        <div class="text-right r-p-large-vertical">
          <q-btn
            type="button"
            color="mq-soft"
            label="Cancel"
            flat
            class="q-mr-lg"
            @click="cancel()"
          />
          <q-btn type="submit" color="primary" label="Save" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BulkCodeConfirmModal',
  props: {
    validResearchOutputs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      comment: undefined,
    };
  },
  methods: {
    ...mapActions('bulkCode', ['submitBulk']),
    async submit() {
      try {
        await this.submitBulk({
          researchOutputsForBulk: this.validResearchOutputs,
          comment: this.comment,
        });
        this.$emit('saved');
      } catch (errorInfo) {
        this.$emit('error', `${errorInfo.count} errors found.
        Please refer to individual research outputs for errors`);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
