const currencyFormatOptions = {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};
const roundTo = digits => val => Math.round((val + Number.EPSILON) * (10 ** digits)) / (10 ** digits);
const testForNull = (val, fn) => (val == null ? null : fn(val));

export const div = (a, b) => (b ? a / b : 0);

export const formatNum = val => testForNull(val, x => (x === 0 ? '-' : x.toLocaleString()));
export const formatInt = val => testForNull(val, x => (x === 0 ? '-' : Math.round(x).toLocaleString()));
export const formatFloat = (digits, showZero = false) => val => testForNull(val, x => (
  x === 0 && !showZero ? '-' : roundTo(digits)(x).toLocaleString('en', { minimumFractionDigits: digits })
));
export const formatCurrency = val => testForNull(val, x => x.toLocaleString('en', currencyFormatOptions));
export const formatPercent = digits => val => testForNull(
  val,
  x => (digits === 0 && x > 0 && x < 0.01 ? '<1%' : x.toLocaleString('en', { style: 'percent', minimumFractionDigits: digits })),
);

export const removeEscapeCharacters = value => value.replace(/[\b\f\n\r\t\v\s]/g, '');

export const getCharactersOnlyByPosition = (value, position) => value
  .split('')
  .reduce(
    (statement, char) => (removeEscapeCharacters(`${statement}${char}`).length
        <= position
      ? `${statement}${char}`
      : statement),
    '',
  );
