<template>
  <div padding class="full-width row justify-center full-page-content-height-with-tabs scroll-y">
    <div class="col-xs-12 col-md-11 col-lg-6 col-xl-4 q-pa-lg q-pb-xl bg-white">
      <div class="row justify-center">
        <div class="col-11 bg-mq-neutral q-py-xl">
          <div class="row">
            <div class="col-4 text-right">
              <span class="q-field-margin q-mr-sm">Year</span>
            </div>
            <div class="col-6">
              <q-select
                v-model="ryear"
                class="col"
                align="center"
                :options="yearOptions"
                clearable
                autofocus-filter
              />
            </div>
          </div>
        </div>
        <div class="col-11 q-py-xl q-px-xl bg-mq-neutral text-center">
          <div class="q-subheading q-mb-md">Export latest Research Income data</div>
        </div>
        <div class="col-11 q-py-xl q-px-xl bg-mq-neutral text-center">
          <div>
            <q-btn color="primary" icon="cloud_download" label="Export Research" @click="download(isCommercialIncome=false)" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-11 col-lg-6 col-xl-4 q-pa-lg q-pb-xl bg-white">
      <div class="row justify-center">
        <div class="col-11 bg-mq-neutral q-py-xl">
          <div class="row">
            <div class="col-4 text-right">
              <span class="q-field-margin q-mr-sm">Year</span>
            </div>
            <div class="col-6">
              <q-select
                v-model="cyear"
                class="col"
                align="center"
                :options="yearOptions"
                clearable
                autofocus-filter
              />
            </div>
          </div>
        </div>
        <div class="col-11 q-py-xl q-px-xl bg-mq-neutral text-center">
          <div class="q-subheading q-mb-md">Export latest Commercialisation Income data</div>
        </div>
        <div class="col-11 q-py-xl q-px-xl bg-mq-neutral text-center">
          <div>
            <q-btn color="primary" icon="cloud_download" label="Export Commercialisation" @click="download(isCommercialIncome=true)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import FileSaver from 'file-saver';

export default {
  name: 'ResearchIncomeDownload',
  data() {
    return {
      error: false,
      isCommercialIncome: false,
      ryear: undefined,
      cyear: undefined,
    };
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    yearOptions() {
      return _.range(this.workingEra.research_income_start_year, this.workingEra.research_income_end_year + 1)
        .map(y => ({ label: `${y}`, value: `${y}` }));
    },
  },
  methods: {
    ...mapActions('researchIncomeFile', ['downloadResearchIncomeFile']),
    async download(isCommercialIncome) {
      try {
        const year = isCommercialIncome ? this.cyear : this.ryear;
        const fileData = await this.downloadResearchIncomeFile({ isCommercialIncome, year });
        const fileName = isCommercialIncome ? 'commercial_income_file.csv' : 'research_income_file.csv';
        FileSaver.saveAs(new Blob([fileData]), fileName, { type: 'text/csv' });
        this.$notify.success('File successfully downloaded.');
      } catch (error) {
        this.$notify.failure(error);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.extra-space
  padding-top: 15px

</style>
