<template>
  <div class="full-width">
    <q-field v-if="researchOutput.hasPortfolioResearchStatement()">
      <q-input
        v-model.trim="portfolio.researchStatement.statement"
        name="statement"
        type="textarea"
        hide-underline
        class="border border-black comfortable-textarea"
        size="xl"
        rows="25"
        readonly
      />
    </q-field>
    <div class="col text-mq-soft items-end field-group¯s">
      <div v-if="isMemberOfPortfolio && !researchOutput.hasPortfolioResearchStatement()" class="r-m-bottom">
        Note: This title has no “Portfolio Research Statement”.
      </div>
      <div class="text-left r-m-large-right r-m-top">
        <div v-if="!isMemberOfPortfolio" class="r-m-bottom">
          Note: This research output has no “Portfolio Research Statement” because it has not been assigned to any portfolio
        </div>
        <div v-if="portfolio.isFinalised()" class="r-m-bottom">
          Note: The Portfolio Research Statement can no longer be changed because the portfolio has been finalised. To resolve this, contact administrators.
        </div>
        <div v-if="portfolio.isResearchStatementRequired()" class="r-m-bottom">
          <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
          A portfolio research statement is mandatory because the portfolio’s claim was coded with Peer Review.
        </div>
        <div v-if="isMemberOfPortfolio && !researchOutput.hasPortfolioResearchStatement()" class="r-m-bottom">
          <q-btn
            type="submit"
            color="mq-blood-red"
            label="Insert Portfolio Research Statement"
            @click="$router.push({name: 'portfolio-research-statement', params: { eraId: workingEra.id, portfolioId: portfolio.id}})"
          />
        </div>
        <div v-if="!portfolio.isFinalised() && researchOutput.hasPortfolioResearchStatement()" class="r-m-bottom">
          <q-btn
            type="submit"
            color="mq-blood-red"
            label="Edit Portfolio Research Statement"
            @click="$router.push({name: 'portfolio-research-statement', params: { eraId: workingEra.id, portfolioId: portfolio.id}})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ResearchOutput from '@/models/research-output/research-output';
import { mapGetters } from 'vuex';

export default {
  name: 'PortfolioResearchStatementTab',
  props: {
    researchOutput: {
      type: ResearchOutput,
      default: () => new ResearchOutput(),
    },
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    isMemberOfPortfolio() {
      return this.researchOutput.portfolio.id;
    },
    portfolio() {
      return this.researchOutput.portfolio;
    },
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
