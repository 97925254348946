<template>
  <q-page class="full-page bg-sand" :style-fn="pageHeight">
    <portal to="app-header">
      <div class="page-title">Researcher Details</div>
      <researcher-banner :researcher="researcher" :researcher-statistics="researcherStatistics" />
    </portal>
    <div class="researcher-details" :style="areaStyle">
      <div class="affiliations-section">
        <researcher-affiliations
          :positions="positions"
          :researcher="researcher"
          :departments="departments"
          :faculties="faculties"
        />
      </div>
      <div class="forc-stats-section">
        <div class="forcs-section">
          <!-- Researcher Eligibility panel -->
          <div class="full-width bg-white q-pa-lg">
            <div class="term-title-small all-caps">
              Eligibility
            </div>
            <div class="q-mt-xs text-mq-purple">
              {{ researcher.status.displayText() }}
            </div>
            <div
              v-if="!researcher.status.isAutomatic() && researcher.status.comment"
              class="q-mt-xl"
            >
              <div class="term-title-small all-caps">
                Reason
              </div>
              <div class="q-mt-sm body-2">
                {{ researcher.status.comment }}
              </div>
            </div>
          </div>
          <!-- Automated FoRCs panel -->
          <div
            v-if="researcher.status.isEligible()"
            class="full-width bg-white q-pl-lg q-mt-lg"
          >
            <q-tabs inverted>
              <q-tab slot="title" default name="automated-forcs" label="Automated FoRCs" />
              <q-tab-pane name="automated-forcs" class="bg-white">
                <researcher-claim-version-read-only
                  :researcher-claim-version="researcherClaimVersionAutomated"
                />
              </q-tab-pane>
            </q-tabs>
          </div>
          <div class="full-width bg-white q-py-xl q-mt-lg">
            <q-tabs inverted>
              <q-tab slot="title" default name="manual-forcs" label="Manual FoRCs" />
              <q-tab slot="title" name="previous-forcs" label="Previous ERA FoRCs" />
              <q-tab slot="title" name="eligibility" label="Eligibility" />
              <q-tab slot="title" name="history" label="History" />
              <q-tab-pane v-show="!progress.loading" name="manual-forcs">
                <researcher-claim-version-component
                  v-if="researcher.status.isEligible()"
                  class="q-mb-lg"
                  :era="workingEra"
                  :researcher-claim-version="researcherClaimVersionLatestManual"
                  :researcher="researcher"
                  :valid-forcs="fourDigitForcsForWorkingEra"
                  @save="saveClaims"
                />
                <div v-else class="q-mt-lg q-pa-lg border border-mq-magenta round-borders flex no-wrap items-center">
                  <q-icon name="lock" class="material-icons-outlined q-mr-lg" color="mq-magenta" size="24px" />
                  <span>This Researcher is locked. Only eligible researchers can be coded.</span>
                </div>
              </q-tab-pane>
              <q-tab-pane name="previous-forcs">
                <researcher-claim-version-read-only
                  :researcher-claim-version="researcher.previous_researcher_claim_version"
                />
              </q-tab-pane>
              <q-tab-pane name="eligibility">
                <researcher-eligibility
                  class="q-pa-lg"
                  :eligibility-status="researcher.status.forced"
                  @save="saveEligibility"
                />
              </q-tab-pane>
              <q-tab-pane name="history">
                <researcher-claims-history
                  :researcher-claim-versions="researcherClaimVersions"
                />
              </q-tab-pane>
            </q-tabs>
          </div>
        </div>
        <div class="statistics-section">
          <div class="statistics-panel">
            <div>All Research Outputs ({{ workingEra.research_output_start_year }}&#8209;{{ workingEra.research_output_end_year }})</div>
            <researcher-stats
              link-name="research-outputs"
              eligibility="all"
              :one-id="firstOneId"
              :stats="researcherStatistics.herdcCountsAsPairs()"
            />
          </div>
          <div class="statistics-panel">
            <div>Eligible Research Outputs ({{ workingEra.research_output_start_year }}&#8209;{{ workingEra.research_output_end_year }})</div>
            <researcher-stats
              link-name="research-outputs"
              eligibility="eligible"
              :one-id="firstOneId"
              :stats="researcherStatistics.eligibleHerdcCountsAsPairs()"
            />
          </div>
          <div class="statistics-panel">
            <div>Applied Measures ({{ workingEra.applied_measures_start_year }}&#8209;{{ workingEra.applied_measures_end_year }})</div>
            <researcher-stats
              link-name="applied-measures"
              :one-id="firstOneId"
              :total="researcherStatistics.appliedMeasureTotal()"
            />
          </div>
        </div>
      </div>
    </div>
    <radar-loading :visible="progress.loading || progress.saving" />
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ResearcherClaimVersionReadOnly from '../common/ResearcherClaimVersionReadOnly.vue';
import ResearcherAffiliations from './ResearcherAffiliations.vue';
import ResearcherBanner from './ResearcherBanner.vue';
import ResearcherClaimsHistory from './ResearcherClaimsHistory.vue';
import ResearcherClaimVersionComponent from './ResearcherClaimVersion.vue';
import ResearcherEligibility from './ResearcherEligibility.vue';
import ResearcherStats from './ResearcherStats.vue';

export default {
  name: 'ResearcherDetails',
  components: {
    RadarLoading,
    ResearcherAffiliations,
    ResearcherClaimVersionReadOnly,
    ResearcherBanner,
    ResearcherClaimVersionComponent,
    ResearcherClaimsHistory,
    ResearcherEligibility,
    ResearcherStats,
  },
  props: {
    researcherId: {
      type: [Number, String],
      default: null,
    },
    eraId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      areaStyle: {},
    };
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    ...mapState('researchers', ['researcher', 'researcherClaimVersions', 'researcherStatistics', 'progress']),
    ...mapState('organisationEntities', ['departments', 'faculties']),
    ...mapGetters('referenceData', ['fourDigitForcByReferenceMetaId']),
    ...mapGetters('researchers', ['researcherClaimVersionLatestManual', 'researcherClaimVersionAutomated']),
    positions() {
      return this.researcher.researcher_positions;
    },
    fourDigitForcsForWorkingEra() {
      return this.fourDigitForcByReferenceMetaId(this.workingEra.forc_reference_meta_id);
    },
    firstOneId() {
      return _.first(this.researcher.getAllOneIds());
    },
  },
  methods: {
    ...mapActions(
      'researchers',
      [
        'getResearcherWithClaimsAndEraStats',
        'getResearcherClaimVersionsForEra',
        'saveResearcherClaims',
        'saveEligibilityStatus',
      ],
    ),
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      const style = { minHeight: height, maxHeight: height };
      this.areaStyle = style;
      return style;
    },
    async saveClaims(researcherClaims) {
      try {
        await this.saveResearcherClaims({
          researcherClaims,
          researcherId: this.researcher.id,
        });
        this.$notify.success('Successfully Saved');
        await this.getResearcherClaimVersionsForEra(this.researcher.id);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async saveEligibility(status) {
      try {
        await this.saveEligibilityStatus({
          researcherId: this.researcher.id,
          eligibility: status,
        });
        this.$notify.success('Eligibility successfully saved');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
  async created() {
    await this.getResearcherWithClaimsAndEraStats(this.researcherId);
  },
};
</script>

<style lang="stylus" scoped>
$breakpoint = 992px

.researcher-details
  flex-grow 1
  position relative
  display grid
  grid-template-columns 2fr 3fr
  grid-template-rows auto
  grid-template-areas "affiliations forc-stats"
  gap 20px
  padding 20px
  padding-left 0

  @media (min-width $breakpoint)
    grid-template-columns 1fr 3fr

.affiliations-section
  grid-area affiliations
  overflow-y auto
  overflow-x hidden

.forc-stats-section
  grid-area forc-stats
  overflow-y auto
  display flex
  flex-wrap wrap
  justify-content space-between
  width 100%

  > div
    flex-basis 100%
    max-width 100%
    width 100%

  > div + div
    margin-top 20px

  @media (min-width $breakpoint)
    .forcs-section
      flex-basis calc(66% - 10px)
      max-width calc(66% - 10px)

    .statistics-section
      margin-top 0
      flex-basis calc(33% - 10px)
      max-width calc(33% - 10px)

dl
  margin-bottom .5rem

dd
  margin-bottom .5rem

dt
  text-transform uppercase

.statistics-panel
  padding: 20px 10px;
  background-color #fff

  & + &
    border-top 4px solid $mq-sand

</style>
