import _ from 'lodash';
import EraFaculty from '@/models/era/era-faculty';
// eslint-disable-next-line import/no-cycle
import User from './user';
import Role from './access/role';
import UserAccessForc from './user-access-forc';

class UserAccess {
  constructor(userAccess = {}) {
    this.id = userAccess.id;
    this.user = new User(userAccess.user);
    this.role = new Role(userAccess.role);
    this.era_faculty = userAccess.era_faculty
      && new EraFaculty(userAccess.era_faculty);
    // this is User access forcs and not forcs
    // TODO: Fix this
    this.forcs = UserAccessForc.fromList(userAccess.forcs);
    this.permission_list = userAccess.permission_list || [];
  }

  static fromList(userAccesses = []) {
    return userAccesses.map(userAccess => new UserAccess(userAccess));
  }

  getUserAccessForcs() {
    return this.forcs;
  }

  getAllForcs() {
    return this.getUserAccessForcs()
      .map(userAccessForc => userAccessForc.forc);
  }

  isForcUnrestricted() {
    this.role.isForcUnrestricted();
  }

  isEraFacultyRestricted() {
    this.role.isEraFacultyRestricted();
  }

  isEraForcRestricted() {
    this.role.isEraForcRestricted();
  }

  getEraFacultyName() {
    return _.get(this.era_faculty, 'name');
  }
}

export default UserAccess;
