class XmlSchemaValidation {
  constructor(val) {
    const xml = val || {};
    this.checkType = xml.check_type;
    this.lineNumber = xml.line_number;
    this.errorMessage = xml.error_message;
    this.uuid = xml.uuid;
  }

  static fromList(data = []) {
    return data.map(xml => new XmlSchemaValidation(xml));
  }
}

export default XmlSchemaValidation;
