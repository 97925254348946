class EraFaculty {
  constructor(eraFaculty = {}) {
    this.id = eraFaculty.id;
    this.name = eraFaculty.name;
  }

  static fromList(eraFaculties = []) {
    return eraFaculties.map(eraFaculty => new EraFaculty(eraFaculty));
  }
}

export default EraFaculty;
