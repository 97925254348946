import { useHttp } from './http';

const http = useHttp('applied_measures');

export default {
  search: params => http.get('', { params }),
  getStatistics: params => http.get('stats', { params }),
  saveClaims: claims => http.post('bulk_claim', { bulk_claims: claims }),
  saveEligibilities: eligibilities => http.post('bulk_eligibility', { bulk_eligibility: eligibilities }),
};
