import api from '@/api';

export default {
  namespaced: true,
  state: {
    settings: {},
    progress: {
      loading: false,
      saving: false,
    },
  },
  mutations: {
    UPDATE_SETTINGS(state, settings) {
      state.settings = settings;
    },
    UPDATE_PROGRESS(state, progress) {
      state.progress = { ...state.progress, ...progress };
    },
  },
  actions: {
    async saveSettings({ commit }, settings) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        const response = await api.radarSettings.update(settings);
        commit('UPDATE_SETTINGS', response.data);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
    async getSettings({ commit }) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const response = await api.radarSettings.get();
        return commit('UPDATE_SETTINGS', response.data);
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },
  },
  getters: { },
};
