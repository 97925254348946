<template>
  <form class="row items-baseline justify-around" @submit.prevent="search()">
    <q-input
      v-model.trim="searchParameters.userNameContains"
      stack-label="Name/MQ ID"
      class="col-4"
      clearable
    />
    <q-select
      v-model="searchParameters.roleId"
      :options="rolesOptions"
      stack-label="Role"
      class="col-3"
      name="role"
      clearable
    />
    <q-select
      v-model="searchParameters.eraFacultyId"
      :options="eraFacultiesOptions"
      stack-label="Faculty"
      class="col-3"
      name="era-faculty"
      clearable
    />
    <q-btn
      type="submit"
      color="primary"
      label="Search"
    />
  </form>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import { getNonEmptyValues } from '@/utils/search';

export default {
  name: 'UsersSearch',
  data() {
    return {
      searchParameters: {
        oneId: null,
        userNameContains: '',
        roleId: null,
        eraFacultyId: null,
      },
    };
  },
  computed: {
    ...mapState('userAccess', ['roles']),
    ...mapState('eraFacultyForcs', ['eraFaculties']),
    rolesOptions() {
      return this.roles.map(role => ({ label: role.name, value: role.id }));
    },
    eraFacultiesOptions() {
      return this.eraFaculties.map(eraFaculty => ({ label: eraFaculty.name, value: eraFaculty.id }));
    },
  },
  methods: {
    ...mapActions('userAccess', ['getAllRoles']),
    ...mapActions('eraFacultyForcs', ['getAllEraFaculties']),
    ...mapActions('users', ['searchUsers']),
    updateQuery(searchParams) {
      const page = searchParams.page || 1;
      const nonEmptyParameters = getNonEmptyValues(searchParams);
      this.$router.push({ query: { ...nonEmptyParameters, page } });
    },
    updateParameters(searchParams) {
      this.searchParameters = {
        ...searchParams,
        userNameContains: searchParams.userNameContains || '',
        roleId: _.toInteger(searchParams.roleId) || null,
        eraFacultyId: _.toInteger(searchParams.eraFacultyId) || null,
      };
    },
    search(page = 1) {
      this.updateQuery({ ...this.searchParameters, page });
    },
    searchOnUrlChange(resetPage) {
      const { query } = this.$route;
      const page = resetPage ? 1 : Number(query.page);

      this.updateParameters(query);
      this.searchUsers({ ...query, page });
    },
    clearSearch() {
      this.updateQuery({});
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
  },
  async created() {
    this.getAllRoles();
    this.getAllEraFaculties();
  },

  mounted() {
    this.searchOnUrlChange();
  },
};
</script>

<style lang="stylus" scoped>
  .search-bar
    border-left 1px solid $tertiary

  .mini-search-title
    word-break break-all
    width 1em

  .mini
    width 60px

  .full
    width 400px

  .container
    margin-top $header-and-breadcrumb-height

</style>
