<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div class="flex no-wrap">
      <span class="ellipsis" v-html="researchOutputForWhatIf.title" />
      <q-tooltip><span v-html="researchOutputForWhatIf.title" /></q-tooltip>
    </div>
    <span class="term-title-small">Citations: {{ researchOutputForWhatIf.totalCitations }} &bull;</span>
    <span v-show="!expanded" class="q-pl-sm">{{ researchOutputForWhatIf.getInternalAuthorsAsCsv() }}</span>
    <div v-show="expanded" class="q-pt-md">
      <div class="row">
        <div class="col-auto term-title-small line-height-large">Managing org:</div>
        <div
          class="col term-definition-condensed line-height-large"
          v-html="researchOutputForWhatIf.managing_organisation_name"
        />
      </div>
      <div class="row">
        <div class="col-auto term-title-small line-height-large">Year:</div>
        <div class="col term-definition-condensed line-height-large">
          {{ researchOutputForWhatIf.pub_year }}
        </div>
      </div>
      <div class="row">
        <div class="col-auto term-title-small line-height-large">Internal Authors:</div>
        <div class="col row term-definition-condensed line-height-large">
          <span
            v-for="author in researchOutputForWhatIf.getInternalAuthors()"
            :key="'author-'+author.id"
            class="col-auto"
          >
            <router-link
              class="q-mr-xs author-names extra-info-col"
              :to="{name: 'researcher-details',params: {researcherId: author.id}}"
              target="_blank"
            >{{ author.researcher_name }}&semi;
            </router-link>
          </span>
        </div>
      </div>
      <div v-if="researchOutputForWhatIf.parent_doc" class="row">
        <div class="col-auto term-title-small line-height-large">Parent doc:</div>
        <div class="col term-definition-condensed line-height-large white-space-normal">
          {{ researchOutputForWhatIf.parent_doc }}
        </div>
      </div>
      <div v-if="researchOutputForWhatIf.getAbdcRanking()" class="row">
        <div class="col-auto term-title-small line-height-large">ABDC:</div>
        <div class="col term-definition-condensed line-height-large">
          {{ researchOutputForWhatIf.getAbdcRanking() }}
        </div>
      </div>
    </div>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>
<script>
import ResearchOutputForWhatIf from '@/models/what-if/research-output-for-what-if';

export default {
  name: 'WhatIfResearchOutputMetadataCell',
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    researchOutputForWhatIf: {
      type: ResearchOutputForWhatIf,
      required: true,
    },
  },
};
</script>
<style lang="stylus" scoped>
  .term-title-small
    min-width: 102px !important

  .white-space-normal
    white-space normal
</style>
