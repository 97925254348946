import _ from 'lodash';
import api from '@/api';
import Funding, { HERDC_INCOME_TYPES } from '@/models/research-income/funding';

const defaultPagination = () => ({
  totalResults: 0,
  pageSize: 50,
  currentPage: 1,
});

export default {
  namespaced: true,
  state: {
    fundings: [],
    searchPagination: defaultPagination(),
    progress: {
      searching: false,
      saving: false,
      awardUpdating: false,
    },
    incomeTypes: {
      research: 'Research',
      commercial: 'Commercial',
    },
    herdcIncomeTypes: HERDC_INCOME_TYPES,
  },
  mutations: {
    UPDATE_FUNDINGS(state, { fundings, currentPage, totalResults }) {
      state.fundings = fundings;
      state.searchPagination.currentPage = Number(currentPage) || 1;
      state.searchPagination.totalResults = totalResults || 0;
    },
    UPDATE_PROGRESS(state, progress) {
      state.progress = { ...state.progress, ...progress };
    },
    UPDATE_EXISTING_FUNDINGS(state, updatedFundings) {
      updatedFundings.forEach((updatedItem) => {
        const index = _.findIndex(
          state.fundings,
          existing => existing.id === updatedItem.id,
        );
        if (index >= 0) {
          state.fundings.splice(index, 1, updatedItem);
        }
      });
    },
    RESET(state) {
      state.researchIncomes = [];
      state.searchPagination = defaultPagination();
      _.keys(state.progress).forEach((key) => {
        state.progress[key] = false;
      });
    },
  },
  actions: {
    async searchFundings({ commit }, searchParameters) {
      commit('UPDATE_PROGRESS', { searching: true });
      const params = searchParameters.toApiParams();
      const response = await api.researchIncomes.search(params);
      const fundings = Funding.fromList(response.data.results);

      commit('UPDATE_FUNDINGS', {
        fundings,
        currentPage: params.page,
        totalResults: response.data.count,
      });
      commit('UPDATE_PROGRESS', { searching: false });
    },

    async saveFundingClaims({ commit }, claims) {
      commit('UPDATE_PROGRESS', { saving: true });
      const bulkClaims = {
        bulk_claims: claims.map(claim => claim.basicClaimDetails()),
      };
      try {
        const response = await api.researchIncomes.saveClaims(bulkClaims);
        commit('UPDATE_EXISTING_FUNDINGS', Funding.fromList(response.data));
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },

    async saveAwardedAmount({ commit }, { id, amount }) {
      commit('UPDATE_PROGRESS', { awardUpdating: true });
      try {
        const response = await api.researchIncomes.update(id, { id, awarded_amount: amount });
        commit('UPDATE_EXISTING_FUNDINGS', [new Funding(response.data)]);
      } finally {
        commit('UPDATE_PROGRESS', { awardUpdating: false });
      }
    },
    resetFundings({ commit }) {
      commit('RESET');
    },
  },
  getters: {},
};
