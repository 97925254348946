<template>
  <div>
    <div v-for="ro in researchOutputs" :key="ro.id" class="additional-info">
      <div class="child-eligibility">
        <q-toggle
          v-model="ro.eligibleEntry"
          :class="{ 'is-changed': ro.is_eligible !== ro.eligibleEntry }"
          :label="eligibilityStatus(ro.eligibleEntry)"
          :color="ro.is_eligible === ro.eligibleEntry ? 'green-9' : 'orange-9'"
          unchecked-icon="close"
          checked-icon="done"
          :keep-color="ro.is_eligible !== ro.eligibleEntry"
          :readonly="!canUserManageEra"
        />
      </div>
      <section>
        <div>
          <span class="info-label">ID:</span>
          <span>{{ ro.identifier }}</span>
        </div>
        <div>
          <span class="info-label">Year:</span>
          <span>{{ ro.pub_year }}</span>
        </div>
        <div>
          <span class="info-label">Country:</span>
          <span>{{ ro.country }}</span>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppliedMeasure from '@/models/applied-measure/applied-measure';

export default {
  name: 'AppliedMeasureMetadataCell',
  props: {
    appliedMeasure: {
      type: AppliedMeasure,
      required: true,
    },
  },
  data() {
    return {
      researchOutputs: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['canUserManageEra']),
    changed() {
      return this.researchOutputs.some(ro => ro.eligibleEntry !== ro.is_eligible);
    },
  },
  methods: {
    eligibilityStatus(isEligible) {
      return isEligible ? 'Eligible' : 'Ineligible';
    },
    cancel() {
      // eslint-disable-next-line no-param-reassign
      this.researchOutputs.forEach((ro) => { ro.eligibleEntry = ro.is_eligible; });
    },
    changedItems() {
      return this.researchOutputs
        .filter(ro => ro.eligibleEntry !== ro.is_eligible)
        .map(ro => ({ applied_measure: this.appliedMeasure.id, child_id: ro.id, is_eligible: ro.eligibleEntry }));
    },
  },
  watch: {
    appliedMeasure: {
      immediate: true,
      handler(am) {
        this.researchOutputs = am.children.map(ro => ({ ...ro, eligibleEntry: ro.is_eligible }));
      },
    },
    changed(val) {
      this.$emit('change', { id: this.appliedMeasure.id, changed: val });
    },
  },
};
</script>

<style lang="stylus" scoped>
.additional-info
  display grid
  grid-template-columns 100px 1fr
  padding 5px
  border 1px solid rgba(0, 0, 0, .2)
  border-radius 6px

.child-eligibility
  align-items center

  >>> .q-toggle-handle
    align-items center
    background-color #e5e5e5

  >>> .q-option-label
    display block
    margin 5px 0 0 0
    padding 2px 6px
    border-radius 4px
    background-color $grey-6
    color #fff
    font-size 10px
    text-transform uppercase
    opacity 1
    transition background-color .5s ease-out

  >>> .active + .q-option-label
    background-color $green-9

  .q-toggle
    display block
    margin-left 20px

    &[tabindex="-1"]
      cursor default !important

      >>> .q-option-inner
        display none

    &.is-changed
      >>> .q-option-label
        background-color $orange-9
.info-label
  min-width 60px
</style>
