<template>
  <q-page>
    <portal to="app-header">
      <div class="page-title row items-center justify-between">
        <div>
          Users
        </div>
        <q-btn
          :to="{name: 'new-user'}"
          icon="add"
          color="primary"
          label="Add User"
        />
      </div>
    </portal>
    <div class="fit q-pa-lg">
      <user-list>
        <div slot="top" class="row justify-between items-baseline">
          <div class="col">
            <users-search ref="userSearch" />
          </div>
          <pagination-total-count
            slot="top-pagination"
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            class="col-auto text-primary body"
            @change="fetchPage"
          />
        </div>
        <div slot="bottom">
          <div class="row reverse">
            <pagination-total-count
              :current-page="currentPage"
              :page-size="pagination.pageSize"
              :total-results="pagination.totalResults"
              class="text-primary"
              @change="fetchPage"
            />
          </div>
        </div>
      </user-list>
    </div>
  </q-page>
</template>
<script>
import { mapState } from 'vuex';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import UserList from './UserList.vue';
import UsersSearch from './UsersSearch.vue';

export default {
  name: 'UsersSearchAndList',
  components: { UserList, UsersSearch, PaginationTotalCount },
  computed: {
    ...mapState('users', {
      pagination: state => state.pagination,
    }),
    currentPage() {
      return this.pagination.currentPage;
    },
    totalPages() {
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
  },
  methods: {
    fetchPage(page) {
      this.$refs.userSearch.search(page);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .list
    height 100vh - $header-and-breadcrumb-height - 3rem
</style>
