import _ from 'lodash';
import Forc from '@/models/era/forc';

const sixDigitNotWithin = (forc, forcRange) => (
  forc.isSixDigitForc() && !forc.isWithinRangeOfAny(forcRange)
);

const fourDigitWithin = (forc, forcRange) => (
  forc.isFourDigitForc() && forc.isWithinRangeOfAny(forcRange)
);

export const forcsForClaimsByUserAndJournalForcsAndClawbackBypassedLogic = (userFourAndSixDigitForcs, forcs) => (
  userFourAndSixDigitForcs.filter(userForc => (
    fourDigitWithin(userForc, forcs)
      || sixDigitNotWithin(userForc, forcs)))
);

export const fourAndSixDigitCodesFor = (allForcs, forcRange) => (
  allForcs.filter(forc => forc.isWithinRangeOfAny(forcRange))
);

export const getForcsWithClawbacksByJournalAndUserForcs = (allForcs, userForcs, journalForcs, isMultiDisciplinary, clawbackBypassedForcs = []) => {
  if (isMultiDisciplinary) {
    return Forc.getFourAndSixDigitForcs(userForcs);
  }
  const journalAndClawbackBypassedLogicForcs = [...journalForcs, ...clawbackBypassedForcs];
  const userClaimableForcs = fourAndSixDigitCodesFor(allForcs, userForcs);
  return forcsForClaimsByUserAndJournalForcsAndClawbackBypassedLogic(
    userClaimableForcs,
    journalAndClawbackBypassedLogicForcs,
  );
};

export const getForcsForReporting = (userForcs) => {
  const forcsForJournals = Forc.getTwoAndFourDigitForcs(userForcs);
  return forcsForJournals;
};

export const canUserActOnAllForcs = (claimForcs, userForcs) => {
  const claimForcsNotAccessibleToUser = _.differenceWith(
    claimForcs,
    userForcs,
    (claimForc, userForc) => claimForc.isWithinRangeOf(userForc),
  );
  return _.isEmpty(claimForcsNotAccessibleToUser);
};

export const canUserActOnAnyForcs = (claimForcs, userForcs) => Forc.areForcsWithinRangeOfAnyOfTheForcs(
  claimForcs,
  userForcs,
);

export const canUserActOnSomeButNotAllForcs = (claimForcs, userForcs) => !canUserActOnAllForcs(claimForcs, userForcs)
    && canUserActOnAnyForcs(claimForcs, userForcs);
