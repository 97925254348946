class Author {
  constructor(author = {}) {
    this.id = author.id;
    this.order = author.order;
    this.party_id = author.party_id;
    this.researcher_name = author.researcher_name;
    this.is_controlled_entity = author.is_controlled_entity;
    this.is_internal_org = author.is_internal_org;
    this.organisation_name = author.organisation_name;
  }

  static fromList(authors = []) {
    return authors.map(author => new Author(author));
  }

  isInternal() {
    return !!this.id;
  }

  canShowOrganisation() {
    return this.is_controlled_entity || this.is_internal_org;
  }
}

export default Author;
