import config from '@/config';
import {
  MANAGE_WHAT_IF,
  VIEW_ANALYTICS_PERFORMANCE_METRICS,
  VIEW_ANALYTICS_VOLUME_MONITORING,
} from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import AnalyticsOverview from '@/components/analytics/overview/AnalyticsOverview.vue';
import CitationBenchmark from '@/components/analytics/overview/CitationBenchmark.vue';
import CitationsOverviewTable from '@/components/analytics/overview/CitationsOverviewTable.vue';
import PeerReviewOverviewTable from '@/components/analytics/overview/PeerReviewOverviewTable.vue';
import WhatifOverview from '@/components/analytics/overview/WhatifOverview.vue';
import WhatIfScenarioList from '@/components/analytics/overview/WhatIfScenarioList.vue';
import whatIfRoutes from './what-if-router';

function propsForWhatifSearch(status) {
  return route => ({ ...route.props, status });
}

const analyticsRoutes = {
  path: 'analytics',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'analytics',
      component: AnalyticsOverview,
      meta: {
        auth: [VIEW_ANALYTICS_VOLUME_MONITORING],
        activeRoute: 'analytics',
      },
      props: true,
      children: [
        {
          path: 'what-ifs-workflow',
          name: 'whatIfsWorkflow',
          component: WhatifOverview,
          meta: {
            auth: [MANAGE_WHAT_IF],
            activeRoute: 'analytics',
          },
          children: [
            {
              path: 'draft',
              name: 'what-ifs-draft',
              props: propsForWhatifSearch('draft'),
              component: WhatIfScenarioList,
              meta: {
                auth: [MANAGE_WHAT_IF],
                activeRoute: 'analytics',
              },
            },
            {
              path: 'pending',
              name: 'what-ifs-pending',
              props: propsForWhatifSearch('pending'),
              component: WhatIfScenarioList,
              meta: {
                auth: [MANAGE_WHAT_IF],
                activeRoute: 'analytics',
              },
            },
            {
              path: 'approved',
              name: 'what-ifs-approved',
              props: propsForWhatifSearch('approved'),
              component: WhatIfScenarioList,
              meta: {
                auth: [MANAGE_WHAT_IF],
                activeRoute: 'analytics',
              },
            },
            {
              path: 'applied',
              name: 'what-ifs-applied',
              props: propsForWhatifSearch('applied'),
              component: WhatIfScenarioList,
              meta: {
                auth: [MANAGE_WHAT_IF],
                activeRoute: 'analytics',
              },
            },
            {
              path: 'rejected',
              name: 'what-ifs-rejected',
              props: propsForWhatifSearch('rejected'),
              component: WhatIfScenarioList,
              meta: {
                auth: [MANAGE_WHAT_IF],
                activeRoute: 'analytics',
              },
            },
          ],
        },
        {
          path: 'citations',
          name: 'citationsOverview',
          component: CitationsOverviewTable,
          meta: {
            auth: [VIEW_ANALYTICS_VOLUME_MONITORING],
            activeRoute: 'analytics',
          },
        }, {
          path: 'peer-review',
          name: 'peerReviewOverview',
          component: PeerReviewOverviewTable,
          meta: {
            auth: [VIEW_ANALYTICS_VOLUME_MONITORING],
            activeRoute: 'analytics',
          },
        }, {
          path: 'citation-benchmarks',
          name: 'citationBenchmarks',
          component: CitationBenchmark,
          meta: {
            auth: [VIEW_ANALYTICS_PERFORMANCE_METRICS],
            activeRoute: 'analytics',
          },
        },
      ],
    },
    ...(config.isFeatureEnabled('whatIfs') ? [whatIfRoutes] : []),
  ],
};

export default analyticsRoutes;
