import _ from 'lodash';
import { replaceAllPureWsUrlWithAdminUrl } from '@/utils/url-util';
import Author from '@/models/research-output/author';
import Forc from '@/models/era/forc';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputAbdc from '@/models/research-output/research-output-abdc';
import Validation from '@/models/validation';

class ResearchOutputForBulkCode {
  constructor(researchOutput = {}) {
    this.id = researchOutput.id;
    this.research_output_type = researchOutput.research_output_type;
    this.managing_organisation_name = researchOutput.managing_organisation_name;
    this.title_nativescript = researchOutput.title_nativescript;
    this.title_language = researchOutput.title_language;
    this.title_translated = researchOutput.title_translated;
    this.pub_year = researchOutput.pub_year;

    this.journal_name = researchOutput.journal_name;
    this.creators = Author.fromList(researchOutput.creators);
    this.abstract = researchOutput.abstract;

    this.electronic_locations = replaceAllPureWsUrlWithAdminUrl(researchOutput.electronic_locations);

    this.is_peer_reviewed = researchOutput.is_peer_reviewed;
    this.citation_count = researchOutput.citation_count;

    this.journal_issn = researchOutput.journal_issn;
    this.journal_forcs = researchOutput.journal_forcs
      ? Forc.fromList(researchOutput.journal_forcs) : [];
    this.journal_is_multi_disciplinary = !!researchOutput.journal_is_multi_disciplinary;
    this.ro_claims = ResearchOutputClaim.fromList(researchOutput.ro_claims);
    this.abdc_rankings = researchOutput.abdc_rankings
      ? ResearchOutputAbdc.fromList(researchOutput.abdc_rankings)
      : [];
    this.disable_clawback_logic = researchOutput.disable_clawback_logic;
    this.validation = new Validation();
    this.has_bylines = researchOutput.has_bylines;
  }

  get title() {
    return this.is_translated ? this.title_translated : this.title_nativescript;
  }

  static fromList(researchOutputForBulkCode = [], forcs = []) {
    return researchOutputForBulkCode
      .map((researchOutput) => {
        const forBulk = new ResearchOutputForBulkCode(researchOutput);
        forBulk.forcs = forcs;
        return forBulk;
      });
  }

  getJournalForcs() {
    if (!this.isJournalArticle() || this.isMultiDisciplinary()) {
      return [];
    }

    return this.journal_forcs;
  }

  getInternalAuthorsAsCsv() {
    return this.getInternalAuthors()
      .map(author => author.researcher_name)
      .join(', ');
  }

  isForcRestrictedByArc() {
    return (
      !this.disable_clawback_logic
      && (this.isJournalArticle()
      && !this.isMultiDisciplinary()
      && !_.isEmpty(this.journal_forcs))
    );
  }

  getInternalAuthors() {
    // same author present as part of multiple affiliations
    const uniqueInternalAuthors = _.uniqBy(
      this.creators.filter(author => author.isInternal()),
      'id',
    );
    return _.sortBy(uniqueInternalAuthors, 'order');
  }

  isJournalArticle() {
    return this.research_output_type === ResearchOutput.TYPES.JOURNAL_ARTICLE.value;
  }

  effectiveTitle() {
    return this.is_translated ? this.title_translated : this.title_nativescript;
  }

  isMultiDisciplinary() {
    return this.journal_is_multi_disciplinary;
  }

  getJournalForcsRepresentation() {
    if (!this.isJournalArticle()) {
      return undefined;
    }
    if (this.isMultiDisciplinary()) {
      return 'MD';
    }
    if (_.isEmpty(this.journal_forcs)) {
      return undefined;
    }
    return this.journal_forcs.map(forc => forc.text).join(', ');
  }

  addClaim(claim) {
    const existingClaimForSameForc = this.ro_claims
      .find(existingClaim => existingClaim.forc.isSameAs(claim.forc));

    if (existingClaimForSameForc) {
      existingClaimForSameForc.apportion = claim.apportion;
      existingClaimForSameForc.is_peer_reviewed = claim.is_peer_reviewed;
      return;
    }

    if (this.ro_claims.length === 3) {
      return;
    }
    this.ro_claims.push(claim);
  }

  removeClaimAt(index) {
    this.ro_claims.splice(index, 1);
  }

  addError(error) {
    this.validation.validated = true;
    this.validation.errors.push(error);
  }

  isValid() {
    return this.hasBeenValidated() && !this.validation.errors.length;
  }

  isInvalid() {
    return this.hasBeenValidated() && !!this.validation.errors.length;
  }

  hasClaims() {
    return this.ro_claims.length > 0;
  }

  hasBeenValidated() {
    return this.validation.validated;
  }

  revalidate() {
    return this.validation.validated && this.validate();
  }

  bulkClaimDetails() {
    return {
      research_output_id: this.id,
      claims: this.ro_claims.map(claim => claim.basicDetails(false)),
    };
  }

  // eslint-disable-next-line no-underscore-dangle
  _validateClaims(clawbackBypassedForcs = []) {
    const hasArcRestriction = this.isForcRestrictedByArc();
    const claimErrors = this.ro_claims.map(claim => claim.validate(this.journal_forcs, hasArcRestriction, clawbackBypassedForcs));
    const totalApportionment = this.ro_claims.reduce((total, claim) => +claim.apportion + total, 0);
    this.validation.errors = _.concat(this.validation.errors, ...claimErrors);
    if (totalApportionment !== 100) {
      this.validation.errors.push('Sum of apportions must be equal to 100');
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  _validateDuplicateClaims() {
    if (this.ro_claims.length <= 1) return;
    const uniqueClaims = _.uniqBy(
      this.ro_claims,
      claim => claim.forc.four_digit_code,
    );
    if (uniqueClaims.length !== this.ro_claims.length) {
      this.validation.errors.push('Duplicate claims.');
    }
  }

  validate(clawbackBypassedForcs = []) {
    this.validation.errors = [];
    if (this.ro_claims.length === 0) return true;

    // eslint-disable-next-line no-underscore-dangle
    this._validateClaims(clawbackBypassedForcs);
    // eslint-disable-next-line no-underscore-dangle
    this._validateDuplicateClaims();

    this.validation.validated = true;
    return !this.validation.errors.length;
  }

  clearClaims() {
    this.ro_claims = [];
    this.validate();
  }

  getValidationStatusText() {
    if (!this.hasBeenValidated()) return '';
    if (this.isValid()) return 'valid';
    return 'invalid';
  }

  getAbdcRanking() {
    if (_.isEmpty(this.abdc_rankings)) {
      return undefined;
    }
    return `${this.abdc_rankings[0].value} -  ${this.abdc_rankings[0].year}`;
  }
}

export default ResearchOutputForBulkCode;
