import config from '@/config';
import { MANAGE_ERA } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import EraDetails from '@/components/era/EraDetail.vue';
import EraList from '@/components/era/EraList.vue';
import analyticsRoutes from './analytics-router';
import bulkCodeRoutes from './bulk-code-router';
import researchIncomeRouter from './research-income-router';
import researchOutputRouter from './research-output-router';
import researcherRoutes from './researcher-router';
import workQueueRoutes from './work-queue-router';
import appliedMeasureRoutes from './applied-measure-router';
import portfolioRoutes from './portfolio-router';
import dataQualityRoutes from './data-quality-router';
import xmlRoutes from './xml-router';
import reportingRoutes from './reporting-router';

const isFeatureEnabled = (feature, route) => (config.isFeatureEnabled(feature) ? [route] : []);

const eraRoutes = [
  {
    path: 'eras',
    component: EmptyRouterView,
    props: true,
    children: [
      {
        path: '',
        name: 'eras',
        component: EraList,
        meta: {
          activeRoute: 'eras',
        },
      }, {
        path: 'new',
        name: 'new-era',
        component: EraDetails,
        meta: {
          auth: [MANAGE_ERA],
          activeRoute: 'eras',
        },
      },
    ],
  }, {
    path: 'eras/:eraId',
    component: EmptyRouterView,
    children: [
      {
        path: '',
        name: 'era-details',
        component: EraDetails,
        meta: {
          auth: [MANAGE_ERA],
          activeRoute: 'eras',
        },
        props: true,
      },
      researcherRoutes,
      workQueueRoutes,
      researchOutputRouter,
      bulkCodeRoutes,
      ...isFeatureEnabled('appliedMeasures', appliedMeasureRoutes),
      ...isFeatureEnabled('portfolios', portfolioRoutes),
      ...isFeatureEnabled('analytics', analyticsRoutes),
      ...isFeatureEnabled('researchIncome', researchIncomeRouter),
      ...isFeatureEnabled('dataQuality', dataQualityRoutes),
      ...isFeatureEnabled('xml', xmlRoutes),
      ...isFeatureEnabled('reporting', reportingRoutes),
    ],
  },
];

export default eraRoutes;
