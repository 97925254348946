<template>
  <div class="row justify-around">
    <form class="col-xs-11" @submit.prevent="createEra()">
      <div class="row justify-around border round-borders bg-mq-neutral">
        <div class="col-xs-12 bg-mq-neutral q-pa-xl">
          <div class="r-heading-1-dense text-primary q-pb-xl">
            1. Give this ERA a title
          </div>
          <q-field
            label="Name"
            :label-width="3"
            :error="errors.has('era-name')"
            error-label="ERA name is required"
          >
            <q-input v-model="era.name" v-validate="'required|min:3|max:50'" name="era-name" />
          </q-field>
        </div>
        <div class="col bg-white q-pa-xl">
          <div class="r-heading-1-dense text-primary q-pb-xl">
            2. Setup criteria
          </div>
          <div class="era-attributes row gutter-md">
            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Research output
              </div>
              <div>
                <q-field
                  :label-width="6"
                  label="Start year"
                  :error="errors.has('research-output-start-year')"
                  error-label="Research output inclusion start year required"
                >
                  <q-input
                    ref="researchOutputStartYear"
                    v-model="era.research_output_start_year"
                    v-validate="'required|digits:4'"
                    type="number"
                    name="research-output-start-year"
                  />
                </q-field>
                <q-field
                  id="research-output-end-year"
                  :label-width="6"
                  label="End year"
                  :error="errors.has('research-output-end-year')"
                  error-label="Please enter a 4 digit year greater than the start year"
                >
                  <q-input
                    ref="researchOutputEndYear"
                    v-model.lazy="era.research_output_end_year"
                    v-validate="'required|digits:4|greater_than:researchOutputStartYear'"
                    type="number"
                    name="research-output-end-year"
                  />
                </q-field>
              </div>
            </div>

            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Research Income
              </div>
              <div>
                <q-field
                  :label-width="6"
                  label="Start year"
                  :error="errors.has('research-income-start-year')"
                  error-label="Research income inclusion start year required"
                >
                  <q-input
                    ref="researchIncomeStartYear"
                    v-model="era.research_income_start_year"
                    v-validate="'required|digits:4'"
                    type="number"
                    name="research-income-start-year"
                  />
                </q-field>
                <q-field
                  :label-width="6"
                  label="End year"
                  :error="errors.has('research-income-end-year')"
                  error-label="Please enter a 4 digit year greater than the start year"
                >
                  <q-input
                    v-model="era.research_income_end_year"
                    v-validate="'required|digits:4|greater_than:researchIncomeStartYear'"
                    type="number"
                    default-view="year"
                    name="research-income-end-year"
                  />
                </q-field>
              </div>
            </div>

            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Applied Measures
              </div>
              <div>
                <q-field
                  :label-width="6"
                  label="Start year"
                  :error="errors.has('applied-measures-start-year')"
                  error-label="Applied Measures inclusion start year required"
                >
                  <q-input
                    ref="appliedMeasuresStartYear"
                    v-model="era.applied_measures_start_year"
                    v-validate="'required|digits:4'"
                    type="number"
                    name="applied-measures-start-year"
                  />
                </q-field>
                <q-field
                  :label-width="6"
                  label="End year"
                  :error="errors.has('applied-measures-end-year')"
                  error-label="Please enter a 4 digit year greater than the start year"
                >
                  <q-input
                    v-model="era.applied_measures_end_year"
                    v-validate="'required|digits:4|greater_than:appliedMeasuresStartYear'"
                    type="number"
                    default-view="year"
                    name="applied-measures-end-year"
                  />
                </q-field>
              </div>
            </div>

            <div class="col-xs-12 col-md-6 col-lg-7">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Census
              </div>
              <div>
                <q-field
                  label="Census date"
                  :error="errors.has('census-date')"
                  error-label="Please enter a date after research output year range"
                >
                  <q-datetime
                    v-model="era.census_date"
                    v-validate="'required|after_year:researchOutputEndYear'"
                    default-view="year"
                    type="date"
                    format="DD/MM/YYYY"
                    name="census-date"
                  />
                </q-field>
              </div>
            </div>

            <div class="col-xs-12 col-xl-8">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Reference data for ERA submission
              </div>
              <div class="group">
                <q-field
                  label="FoRC (ARC published year)"
                  :label-width="6"
                  error-label="ARC published FoRC reference year is required for an ERA"
                >
                  <q-select
                    ref="forcReferenceMeta"
                    v-model="era.forc_reference_meta_id"
                    v-validate="'required'"
                    color="black"
                    :options="forcReferenceMetaChoices"
                    name="forc_reference_meta"
                  />
                </q-field>
                <q-field
                  label="ARC journal list (ERA Year)"
                  :label-width="6"
                  :error="errors.has('journal_reference_meta')"
                  error-label="ARC published FoRC reference year is required for an ERA"
                >
                  <q-select
                    ref="journal_reference_meta"
                    v-model="era.journal_reference_meta_id"
                    v-validate="'required'"
                    color="black"
                    :options="journalReferenceMetaChoices"
                    name="journal_reference_meta"
                  />
                  <q-checkbox
                    v-model="era.disable_clawback_logic"
                    class="q-if-label-above floating-label-font q-mt-lg"
                    label="Disable clawback logic"
                  />
                </q-field>
              </div>
            </div>

            <div class="col-xs-12">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Citation benchmark
              </div>
              <div class="group row">
                <q-field
                  class="col-12"
                  label="Citation benchmark"
                  :label-width="4"
                >
                  <q-btn
                    color="secondary"
                    icon="cloud_upload"
                    label="Upload"
                    class="q-px-lg"
                    @click="selectBenchmarkFile"
                  />
                  <input
                    ref="uploadFile"
                    type="file"
                    class="hidden"
                    accept="text/csv"
                    @change="importBenchmark"
                  >
                  <q-btn
                    color="secondary"
                    icon="cloud_download"
                    label="Download"
                    class="q-ml-lg q-px-lg"
                    @click="exportBenchmark"
                  />
                </q-field>
                <q-field
                  class="col-8"
                  label="Citation source"
                  :label-width="6"
                >
                  <q-select
                    v-model="era.citation_source"
                    color="black"
                    :options="citationSourceChoices"
                    name="citation_benchmark_reference_meta"
                  />
                </q-field>
                <q-field
                  class="col-8"
                  label="Hide benchmark years"
                  :label-width="6"
                >
                  <q-select
                    v-model="era.benchmark_years_hidden"
                    color="black"
                    :options="[
                      { value: 0, label: 'None' },
                      { value: 1, label: 'First year' },
                      { value: 2, label: 'First 2 years' },
                      { value: 3, label: 'First 3 years' },
                      { value: 4, label: 'First 4 years' },
                    ]"
                  />
                </q-field>
                <q-field
                  class="col-12"
                  label="Benchmark footnote"
                  :label-width="4"
                  :count="200"
                  :error="errors.has('benchmark-footnote')"
                  error-label="Footnote cannot be more than 200 characters"
                >
                  <q-input v-model="era.benchmark_footnote" v-validate="'max:200'" name="benchmark-footnote" />
                </q-field>
              </div>
            </div>

            <div class="col-12 col-xl-8">
              <div class="r-heading-3 text-mq-purple q-py-sm">
                Miscellaneous
              </div>
              <div class="group">
                <q-field
                  class="eligiblity-toggle"
                  label="Eligibility rules"
                  :label-width="6"
                  error-label="ARC published FoRC reference year is required for an ERA"
                >
                  <q-toggle v-model="era.eligiblity_rule_applied" :label="eligibilityLabel" color="primary" />
                </q-field>
              </div>
            </div>
          </div>

          <div class="row group r-p-vertical justify-end">
            <q-btn
              :to="{name: 'eras'}"
              flat
              text-color="mq-soft-muted"
              label="Cancel"
              class="q-px-xl"
            />
            <q-btn
              type="submit"
              color="primary"
              label="Save"
              class="q-px-xl"
            />
          </div>
        </div>
      </div>
    </form>
    <radar-loading :visible="progress.loading || progress.saving" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FileSaver from 'file-saver';
import RadarLoading from '@/components/common/RadarLoading.vue';

export default {
  name: 'BasicEraInfo',
  components: {
    RadarLoading,
  },
  data() {
    return {
      era: {},
    };
  },
  computed: {
    ...mapState('eras', { originalEra: 'era', progress: 'progress' }),
    ...mapState('referenceData', ['citationSources']),
    ...mapGetters('referenceData', [
      'forcReferenceMetas', 'journalReferenceMetas', 'citationBenchmarkReferenceMetas',
    ]),
    errors() {
      return this.$validator.errors;
    },
    forcReferenceMetaChoices() {
      return this.forcReferenceMetas.map(forcReferenceMeta => ({
        label: `ANZSRC ${forcReferenceMeta.year}`,
        value: forcReferenceMeta.id,
      }));
    },
    journalReferenceMetaChoices() {
      return this.journalReferenceMetas.map(journalReferenceMeta => ({
        label: `ERA ${journalReferenceMeta.year}`,
        value: journalReferenceMeta.id,
      }));
    },
    citationReferenceMetaChoices() {
      return this.citationBenchmarkReferenceMetas.map(citationReferenceMeta => ({
        label: `Citation benchmark ${citationReferenceMeta.year}`,
        value: citationReferenceMeta.id,
      }));
    },
    citationSourceChoices() {
      return this.citationSources.map(source => ({ label: source, value: source }));
    },
    eligibilityLabel() {
      return this.era.eligiblity_rule_applied ? 'Eligibility rules will apply' : 'All items will be eligible';
    },
  },
  methods: {
    ...mapActions('eras', ['saveEra', 'getEra', 'uploadBenchmark', 'downloadBenchmark']),
    async saveEraDetails() {
      try {
        await this.saveEra(this.era);
        this.$notify.success('Successfully Saved');
        this.navigateToList();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    navigateToList() {
      this.$router.push({ name: 'eras' });
    },
    async createEra() {
      await this.$validator.validateAll();
      if (!this.errors.any()) await this.saveEraDetails();
    },
    selectBenchmarkFile() {
      this.$refs.uploadFile.click();
    },
    async importBenchmark(evt) {
      try {
        const csvFile = evt.target.files[0];
        await this.uploadBenchmark(csvFile);
        this.$notify.success('Benchmark file successfully uploaded');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async exportBenchmark() {
      try {
        const data = await this.downloadBenchmark();
        FileSaver.saveAs(new Blob([data]), 'benchmarks.csv', { type: 'text/csv' });
        this.$notify.success('Benchmark successfully downloaded.');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
  watch: {
    originalEra(val) {
      this.era = val;
    },
  },
};
</script>
<style lang="stylus" scoped>
  .era-attributes .q-card
    margin-bottom 1rem

    .q-card-container
      padding .5rem

    .q-card-title
      line-height 1rem

  .eligiblity-toggle >>> .q-option-label
    color $grey-7

</style>
