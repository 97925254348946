import _ from 'lodash';
import { getNonEmptyValues } from '@/utils/search';
import { valueAsArray } from '@/utils/collections-util';

class AppliedMeasureSearchParam {
  constructor(eraId) {
    this.authorNameContains = undefined;
    this.facultyOrDepartmentName = null;
    this.categories = [];
    this.title = undefined;
    this.claimForcIds = [];
    this.withNoClaimForcApportions = false;
    this.editedBy = null;
    this.eligibility = 'eligible';
    this.restrictToLeadAuthor = false;
    this.yearStart = null;
    this.yearEnd = null;
    this.page = 1;
    this.eraId = eraId;
    this.radarId = null;
  }

  static create(params = {}) {
    const updateParams = {
      claimForcIds: valueAsArray(params.claimForcIds).map(Number),
      withNoClaimForcApportions: _.trim(params.withNoClaimForcApportions) === 'true',
      restrictToLeadAuthor: _.trim(params.restrictToLeadAuthor) === 'true',
      yearStart: _.toInteger(params.yearStart) || null,
      yearEnd: _.toInteger(params.yearEnd) || null,
    };
    return Object.assign(
      new AppliedMeasureSearchParam(),
      params,
      updateParams,
    );
  }

  toApiParams() {
    return getNonEmptyValues({
      author_name_contains: this.authorNameContains,
      restrict_to_lead_author: this.restrictToLeadAuthor,
      era_id: this.eraId,
      faculty_department_name: this.facultyOrDepartmentName,
      categories: this.categories,
      title: this.title,
      year_min: this.yearStart,
      year_max: this.yearEnd,
      claim_forc_ids: this.claimForcIds,
      with_no_claim_forc_apportions: this.withNoClaimForcApportions,
      edited_by: this.editedBy,
      filter_by_eligibility_status: this.eligibility,
      page: this.page || 1,
      radar_id: this.radarId,
    });
  }
}

export default AppliedMeasureSearchParam;
