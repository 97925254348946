import { useHttp } from './http';

const http = useHttp('analytics');

export default {
  getCitationBenchmarks: () => http.get('citation_benchmarks'),
  getForcCitations: () => http.get('citations'),
  getPeerReviews: () => http.get('peer_reviews'),
  getWorkflowStats: () => http.get('what_ifs/workflow_stats'),
};
