<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="HERDC Research Income by Year">
      <report-table
        slot="table"
        :data="riByYear"
        :summary-row="riByYearTotals"
        :columns="riByYearCols"
        row-key="subCategory"
      />
    </report-container>
    <report-container title="HERDC Research Income Summary (Level A-E Only)" class="q-mt-xl">
      <report-table
        slot="table"
        :data="incomeSummaryLevelAE"
        :summary-row="incomeSummaryLevelAESummary"
        :columns="incomeSummaryCols"
        row-key="category"
      />
    </report-container>
    <report-container title="HERDC Research Income Summary" class="q-mt-xl">
      <report-table
        slot="table"
        :data="incomeSummaryLevel"
        :summary-row="incomeSummaryLevelSummary"
        :columns="incomeSummaryCols"
        row-key="category"
      />
    </report-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatCurrency, formatFloat, div ,formatInt} from '@/utils/formatter';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptResearchIncome',
  components: {
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      incomeSummaryCols: [
        { label: 'HERDC Category', field: 'category' },
        { label: 'Research Income Type', field: 'subCategory', align: 'left' },
        { label: 'Grants', field: 'grantCount', format: formatInt },
        { label: 'Total Amount', field: 'total', format: formatCurrency },
        { label: 'Avg $ per grant', field: 'avgGrant', format: formatCurrency },
        { label: 'Avg $ per FTE', field: 'avgFte', format: formatCurrency },
        { label: 'Benchmark $ per FTE', field: 'fteBenchmark', format: formatCurrency },
        { label: 'Ratio Against Benchmark $ Per FTE', field: 'ratio', format: formatFloat(2) },
      ],
    };
  },
  computed: {
    ...mapState('reporting', ['researchIncomeData', 'fteBenchmarks']),
    ...mapGetters('reporting', ['fteAtoETotal', 'fteTotalCount']),
    ...mapGetters('eras', ['researchIncomeYearRange']),
    riByYearCols() {
      const yearColumns = this.researchIncomeYearRange.map(y => ({ label: y, field: y, format: formatCurrency }));
      return [
        { label: 'HERDC Category', field: 'category' },
        { label: 'Research Income Type', field: 'subCategory', align: 'left' },
        ...yearColumns,
        { label: 'Total for Period', field: 'total', format: formatCurrency },
      ];
    },
    riByYear() {
      const mapData = (row) => {
        const years = {};
        let total = 0;
        this.researchIncomeYearRange.forEach((y) => {
          years[y] = row.years[y] || 0;
          total += years[y];
        });
        return {
          category: row.category,
          subCategory: row.subCategory,
          grantCount: row.grantCount,
          ...years,
          total,
        };
      };
      return this.researchIncomeData.map(row => mapData(row));
    },
    riByCategory() {
      const summary = Object.values(_.groupBy(this.riByYear, r => r.category))
        .map((cat) => {
          const categoryRow = this.initialiseTotal();
          cat.forEach((row) => {
            this.researchIncomeYearRange.forEach((y) => { categoryRow[y] += (row[y] || 0); });
            categoryRow.total += row.total;
            categoryRow.category = row.category;
            categoryRow.subCategory = row.subCategory;
            categoryRow.grantCount = row.grantCount;
          });
          return categoryRow;
        });
      return summary;
    },
    totalCount() {
      const total = this.initialiseTotal();
      this.riByYear.forEach((row) => {
        this.researchIncomeYearRange.forEach((y) => { total[y] += (row[y] || 0); });
        total.total += row.total;
      });
      return total;
    },
    riByYearTotals() {
      return {
        category: ' ',
        subCategory: 'Total Income - All types',
        ...this.totalCount,
      };
    },
    incomeSummaryLevelAE() {
      return this.riByCategory
        .map(this.detailedRow('A-E', this.fteAtoETotal))
        .sort(this.compareCategories);
    },
    incomeSummaryLevelAESummary() {
      return this.summaryRow('Total FTEs (Level A-E only)', this.fteAtoETotal);
    },
    incomeSummaryLevel() {
      return this.riByCategory
        .map(this.detailedRow('ALL', this.fteTotalCount))
        .sort(this.compareCategories);
    },
    incomeSummaryLevelSummary() {
      return this.summaryRow('Total FTEs', this.fteTotalCount);
    },
  },
  methods: {
    ...mapActions('reporting', ['getResearchIncomeByYear']),
    compareCategories(a, b) {
      if (a.category < b.category) { return -1; }
      if (a.category > b.category) { return 1; }
      return 0;
    },
    initialiseTotal() {
      const total = { total: 0 };
      this.researchIncomeYearRange.forEach((y) => { total[y] = 0; });
      return total;
    },
    detailedRow(benchmarkProp, fteCount) {
      return (row) => {
        const avgFte = div(row.total, fteCount);

        const fteBenchmark = this.fteBenchmarks[row.category[0]][benchmarkProp];
        const ratio = div(avgFte, fteBenchmark);
        return {
          category: row.category,
          subCategory: row.subCategory,
          grantCount: row.grantCount,
          total: row.total,
          avgGrant: div(row.total, row.grantCount),
          avgFte,
          fteBenchmark,
          ratio,
        };
      };
    },
    summaryRow(label, fteCount) {
      return {
        category: label,
        subCategory: formatFloat(2)(fteCount),
        grantCount: null,
        total: '',
        avgGrant: '',
        avgFte: '',
        fteBenchmark: '',
        ratio: null,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
