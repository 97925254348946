import api from '@/api';
import ResearchStatement from '@/models/research-output/research-statement';

export default {
  namespaced: true,
  state: {
    pagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    researchStatementVersions: [],
    researchStatement: new ResearchStatement(),
    latestResearchStatement: new ResearchStatement(),
  },
  mutations: {
    UPDATE_RESEARCH_STATEMENT(state, researchStatement) {
      state.researchStatement = researchStatement;
    },
    UPDATE_LATEST_RESEARCH_STATEMENT(state, latestResearchStatement) {
      state.latestResearchStatement = latestResearchStatement;
    },
  },
  actions: {
    async fetchResearchStatement({ commit }, { researchOutputId }) {
      const response = await api.researchOutputs.getResearchStatement(researchOutputId);
      commit('UPDATE_LATEST_RESEARCH_STATEMENT', new ResearchStatement(response.data));
      commit('UPDATE_RESEARCH_STATEMENT', new ResearchStatement(response.data));
    },
    async saveResearchStatement({ commit, rootGetters }, { statement, researchStatementId }) {
      const researchOutputId = rootGetters['researchOutputs/researchOutput'].id;
      let response;
      if (!researchStatementId) {
        response = await api.researchOutputs.createResearchStatement(researchOutputId, statement);
      } else {
        response = await api.researchOutputs.updateResearchStatement(researchOutputId, researchStatementId, statement);
      }
      commit('UPDATE_LATEST_RESEARCH_STATEMENT', new ResearchStatement(response.data));
      commit('UPDATE_RESEARCH_STATEMENT', new ResearchStatement(response.data));
    },
  },
  getters: {
    latestResearchStatement: state => state.latestResearchStatement,
  },
};
