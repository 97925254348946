export const portfolioFields = [
  {
    name: 'toggle',
    label: '',
    classes: 'col-toggle',
  }, {
    name: 'researchOutput',
    label: 'Research Output',
    field: '',
    classes: 'col-research-output',
    align: 'left',
  }, {
    name: 'actions',
    label: '',
    classes: 'col-actions',
    align: 'center',
  }, {
    name: 'pureid',
    label: 'Pure ID',
    field: '',
    classes: 'col-pure-id',
    align: 'left',
  }, {
    name: 'portfolio',
    label: 'Portfolio',
    field: '',
    classes: 'col-portfolio',
    align: 'left',
  }, {
    name: 'claim1',
    label: 'Claim 1',
    field: '',
    classes: 'col-claim',
    align: 'center',
  }, {
    name: 'claim2',
    label: 'Claim 2',
    field: '',
    classes: 'col-claim',
    align: 'center',
  }, {
    name: 'claim3',
    label: 'Claim 3',
    field: '',
    classes: 'col-claim',
    align: 'center',
  },
];

export default { portfolioFields };
