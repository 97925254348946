<template>
  <form
    class="row justify-between"
    @submit.prevent="userTriggeredSearch()"
  >
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            color="mq-soft"
            label="Clear"
            flat
            class="r-m-right"
            @click="clearSearch()"
          />
          <q-btn type="submit" color="primary" label="Search" />
        </div>
      </div>
      <q-field label="Author MQ ID">
        <q-input v-model.trim="searchParameters.authorNameContains" />
        <q-checkbox
          v-model="searchParameters.restrictToLeadAuthor"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Restrict to Lead Author"
        />
      </q-field>
      <q-field label="Faculty/Department">
        <q-select
          v-model="searchParameters.facultyOrDepartmentName"
          :filter="labelContainsFilter"
          :options="facultyOrDepartmentOptions"
          clearable
        />
      </q-field>
      <q-field label="Applied Measures Category">
        <q-select
          v-model="searchParameters.categories"
          :options="categoryOptions"
          chips
          multiple
          clearable
        />
      </q-field>
      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" />
      </q-field>
      <div class="row q-field">
        <div class="q-field-label q-field-margin col-xs-12 col-sm-5">Year</div>
        <div class="q-field-content col-xs-12 col-sm row justify-between">
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">Start</span>
            <q-select
              v-model="searchParameters.yearStart"
              class="col"
              align="center"
              :options="yearStartOptions"
              clearable
              autofocus-filter
            />
          </div>
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">End</span>
            <q-select
              v-model="searchParameters.yearEnd"
              class="col"
              align="center"
              :options="yearEndOptions"
              clearable
              autofocus-filter
            />
          </div>
        </div>
      </div>
      <q-field label="Claim FoRCs">
        <q-select
          v-model="searchParameters.claimForcIds"
          :options="claimForcOptions"
          chips
          :disable="searchParameters.withNoClaimForcApportions"
          autofocus-filter
          multiple
          :filter="labelStartWithOrSublabelContainsFilter"
          clearable
        />
        <q-checkbox
          v-model="searchParameters.withNoClaimForcApportions"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="With no Claim FoRC apportions"
        />
      </q-field>

      <q-field label="Edited by">
        <q-select
          v-model="searchParameters.editedBy"
          :options="editByOptions"
          clearable
        />
      </q-field>

      <q-field label="Eligibility" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.eligibility"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="eligibilityOptions"
        />
      </q-field>

      <div class="text-right r-p-large-vertical">
        <q-btn
          type="button"
          color="mq-soft"
          label="Clear"
          flat
          class="q-mr-lg"
          @click="clearSearch()"
        />
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </div>
  </form>
</template>

<script>

import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { valueAsArray } from '@/utils/collections-util';
import { labelContainsFilter, labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import { getFieldsFromQueryParametersBasedOn } from '@/utils/url-util';
import Forc from '@/models/era/forc';
import AppliedMeasureSearchParam from '@/models/applied-measure/applied-measure-search-param';

function getDefaultSearchParameters() {
  return new AppliedMeasureSearchParam();
}

export default {
  name: 'AppliedMeasureSearch',
  data() {
    return {
      searchParameters: new AppliedMeasureSearchParam(),
    };
  },
  computed: {
    ...mapState('researchers', ['eligibility']),
    ...mapState('appliedMeasures', ['appliedMeasures', 'types', 'editByChoices']),
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'fourDigitForcByReferenceMetaId']),
    ...mapGetters('eras', ['workingEra', 'appliedMeasureYearRange']),
    ...mapGetters('eras', ['workingEra']),
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [ ...new Set([ ...this.departments, ...this.faculties ].map(i => (i.name))) ].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map( i => ({ label:i, value:i }) );
    },
    claimForcOptions() {
      const sortedForcs = _.sortBy(
        this.fourDigitForcByReferenceMetaId(this.workingEra.forc_reference_meta_id),
        forc => forc.text,
      );
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    categoryOptions() {
      return this.types.map(type => ({ label: type.split(':')[1], value: type.split(':')[0] }));
    },
    editByOptions() {
      return this.editByChoices.map(editBy => ({ label: editBy.split(':')[1], value: editBy.split(':')[0] }));
    },
    eligibilityOptions() {
      const options = this.eligibility.map(eligibility => ({ label: eligibility.split(':')[1], value: eligibility.split(':')[0] }));
      return [...options, ...[{ label: 'All records', value: 'all' }]];
    },
    yearStartOptions() {
      return this.appliedMeasureYearRange.map(year => ({ label: `${year}`, value: year }));
    },
    yearEndOptions() {
      const startYear = this.searchParameters.yearStart || this.workingEra.applied_measures_start_year;
      return _.range(startYear, this.workingEra.applied_measures_end_year + 1)
        .map(year => ({ label: `${year}`, value: year }));
    },
  },
  methods: {
    ...mapActions('appliedMeasures', ['searchAppliedMeasures', 'getStatistics']),
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    hasActiveFilters() {
      const defaultParameters = _.omit(getDefaultSearchParameters(), 'page');
      const currentParameters = _.omit(this.searchParameters, 'page');
      return !_.isEqual(defaultParameters, currentParameters);
    },
    clearSearch() {
      this.updateParameters({});
    },
    updateQuery(searchParams) {
      const nonEmptyParameters = searchParams;
      this.$router.push({ query: { ...nonEmptyParameters } });
    },
    updateParameters(searchParams) {
      const parameters = {
        ...getDefaultSearchParameters(),
        ...getFieldsFromQueryParametersBasedOn(this.searchParameters, searchParams),
        claimForcIds: valueAsArray(searchParams.claimForcIds).map(Number),
      };
      this.searchParameters = AppliedMeasureSearchParam.create(parameters);
    },
    hasSearchParametersChanged(previous, current) {
      return _.keys(getDefaultSearchParameters())
        .some(key => !_.isEqual(current[key], previous[key]));
    },
    userTriggeredSearch() {
      this.search(1);
      this.$emit('searched');
    },
    search(pageToSearch) {
      this.searchParameters.page = pageToSearch || this.searchParameters.page;
      const newSearchParameters = AppliedMeasureSearchParam.create(this.searchParameters);

      this.updateQuery(newSearchParameters);
      this.$emit('searched');
    },
    searchOnUrlChange() {
      this.$notify.clear();
      const { query } = this.$route;
      this.updateParameters(query);
      this.searchParameters.eraId = Number(this.$route.params.eraId);

      this.getStatistics(this.searchParameters);
      this.searchAppliedMeasures(this.searchParameters);

      this.$emit('searched');
    },
    reSyncParametersFromQuery() {
      this.updateParameters(this.$route.query);
    },
    async reload() {
      await this.searchAppliedMeasures(this.searchParameters);
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
  },
  created() {
    if (this.hasSearchParametersChanged(getDefaultSearchParameters(), this.$route.query)) {
      this.searchOnUrlChange();
    }
  },
};
</script>

<style lang="stylus" scoped>
  .field-groups
    & > .q-field
      margin-top 20px
</style>
