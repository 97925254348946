import moment from 'moment';
import api from '@/api';
import XmlSchemaValidation from '@/models/xml/xml-schema-validation';
import { XmlSchemaValidationStats } from '@/models/xml/xml-schema-validation-stats';

export default {
  namespaced: true,
  state: {
    xmlSchemaValidations: [],
    statDetails: [],
    xmlSnippet: '',
    searchPagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    xmlJobStatus: {},
    lastCheckedStatus: null,
    xmlSummary: [],
    progress: {
      searching: false,
      processing: false,
      xmlJob: false,
    },
  },
  mutations: {
    UPDATE_XML_SCHEMA_VALIDATIONS(state, { data, page, totalResults }) {
      state.xmlSchemaValidations = data;
      state.searchPagination.currentPage = Number(page) || 1;
      state.searchPagination.totalResults = totalResults || 0;
    },
    UPDATE_STATS(state, data) {
      state.statDetails = data;
    },
    UPDATE_XML_SUMMARY(state, data) {
      state.xmlSummary = data;
    },
    UPDATE_XML_SNIPPET(state, data) {
      state.xmlSnippet = data;
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
    UPDATE_XML_JOB_STATUS(state, status) {
      state.xmlJobStatus = status;
      state.lastCheckedStatus = moment().format('h:mm:ss A');
    },
  },

  actions: {
    async searchXmlSchemaValidation({ commit }, searchParams) {
      commit('UPDATE_PROGRESS', { searching: true });
      try {
        const params = searchParams.toApiParams();
        const response = await api.xml.search(params);
        const data = XmlSchemaValidation.fromList(response.data.results);
        commit('UPDATE_XML_SCHEMA_VALIDATIONS', { data, page: params.page, totalResults: response.data.count });
      } finally {
        commit('UPDATE_PROGRESS', { searching: false });
      }
    },
    async getStatistics({ commit }) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.xml.getStatistics();
        const data = XmlSchemaValidationStats.toArray(response.data);
        commit('UPDATE_STATS', data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async getSummary({ commit }) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.xml.getSummary();
        commit('UPDATE_XML_SUMMARY', response.data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async getXmlSnippet({ commit }, uuid) {
      commit('UPDATE_PROGRESS', { processing: true });
      try {
        const response = await api.xml.getXmlSnippet(uuid);
        commit('UPDATE_XML_SNIPPET', response.data);
      } finally {
        commit('UPDATE_PROGRESS', { processing: false });
      }
    },
    async getJobStatus({ commit }) {
      commit('UPDATE_PROGRESS', { xmlJob: true });
      try {
        const response = await api.xml.getJobStatus();
        commit('UPDATE_XML_JOB_STATUS', response.data);
      } finally {
        commit('UPDATE_PROGRESS', { xmlJob: false });
      }
    },
    async runXmlJob({ commit }) {
      commit('UPDATE_PROGRESS', { xmlJob: true });
      try {
        const response = await api.xml.runGenerateJob();
        commit('UPDATE_XML_JOB_STATUS', { ...response.data, ...{ processing: true } });
      } finally {
        commit('UPDATE_PROGRESS', { xmlJob: false });
      }
    },
  },
  getters: {
    exportCsvUrl(state, getters, rootState, rootGetters) {
      const endpoint = '/api/xml/xsd/csv/';
      const eraId = rootGetters['eras/workingEra'].id;
      return `${endpoint}?era_id=${eraId}`;
    },
    exportXmlUrl(state, getters, rootState, rootGetters) {
      const endpoint = '/api/xml/xsd/xml/';
      const eraId = rootGetters['eras/workingEra'].id;
      return `${endpoint}?era_id=${eraId}`;
    },

  },
};
