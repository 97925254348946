<template>
  <div>
    <div class="xml-stats" :class="[xmlColor]">
      <div class="xml__icon" :title="title">
        <q-icon :name="stats.icon" :class="[stats.iconClass]" />
      </div>
      <div class="xml__title">{{ title }}</div>
      <button
        class="xml__item xml__total"
        :disabled="isZero(stats.stats)"
        @click="onClick()"
      >
        {{ stats.stats }}
      </button>
    </div>
  </div>
</template>

<script>
import { CHECK_TYPES, CHECK_TYPE_NAMES } from '@/models/xml/xml-schema-validation-types';
import { XmlSchemaValidationStats } from '@/models/xml/xml-schema-validation-stats';

export default {
  name: 'XmlStats',
  props: {
    stats: {
      type: XmlSchemaValidationStats,
      default: () => new XmlSchemaValidationStats(),
    },
  },
  computed: {
    title() {
      return this.stats.checkType === CHECK_TYPES.Income
        ? CHECK_TYPE_NAMES[this.stats.checkType]
        : `${CHECK_TYPE_NAMES[this.stats.checkType]}s`;
    },
    xmlColor() {
      return `xml--${this.stats.color}`;
    },
  },
  methods: {
    isZero(val) {
      return !val;
    },
    onClick() {
      this.$emit('click', { checkType: this.stats.checkType });
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
