<template>
  <div class="full-width">
    <div class="row items-center apportion-row r-p-side">
      <div class="col-auto">
        <div class="block fit">
          <div class="event-icon-wrapper flex flex-center" :class="event.event_type.toLowerCase()">
            <q-icon :name="event.attributes().icon" class="event-icon self-center" />
          </div>
        </div>
      </div>
      <div class="col-auto capitalize q-pl-lg">
        {{ event.portfolioName() }}
      </div>
    </div>
    <div class="full-width r-p-all">
      <div class="full-width user-and-time">
        <span v-if="event.actingUserDisplayName()">
          {{ event.actingUserDisplayName() }} at
        </span>
        {{ formatDateTime(event.updated_at) }}
        <span class="float-right">
          {{ event.userFriendlyActionName() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/date';
import ResearchOutputClaimEvent from '@/models/research-output/research-output-claim-event';

export default {
  name: 'PortfolioEvent',
  props: {
    event: {
      type: ResearchOutputClaimEvent,
      required: true,
    },
  },
  methods: {
    formatDateTime,
  },
};
</script>
<style lang="stylus" scoped>
  .apportion-row
    background-color $mq-sand
    padding-top 20.5px
    padding-bottom 20.5px

  .event-icon-wrapper
    height 58px
    width 58px
    border 1px solid
    border-radius 50%
    background-color $mq-soft

  .event-icon
    font-size 24px
    color white
</style>
