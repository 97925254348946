<template>
  <div v-if="editMode" class="claim-editor__entry">
    <div class="claim-editor__forc">
      <forc-selector
        v-model="claim.forc"
        name="forc-selector"
        :forcs="forcList"
        classes="text-black full-width"
        align="center"
        placeholder="FoRC"
        @blur="$emit('blur')"
      />
      <input
        v-model="claim.forc.four_digit_code"
        class="claim-editor__hidden-forc"
        type="text"
        :required="!!claim.apportion"
        tabindex="-1"
      >
    </div>
    <div class="claim-editor__apportion" :class="{ 'claim-editor--has-peer-review': includePeerReview }">
      <q-input
        v-model.number.lazy="claim.apportion"
        name="apportion"
        type="number"
        align="center"
        :error="errors.has('apportion')"
        :min="minApportion"
        :max="100"
        :step="1"
        :decimals="0"
        :required="!!claim.forc.four_digit_code"
        @blur="$emit('blur')"
      />
      <div v-if="includePeerReview" class="claim-editor__peer-review">
        <q-checkbox
          v-model="claim.isPeerReviewed"
          color="primary"
        />
        <div class="claim-editor__peer-review--underline" />
      </div>
    </div>
    <div class="claim-editor__action">
      <q-btn
        class="claim-editor__btn no-shadow"
        type="button"
        icon="close"
        color="blue-grey-5"
        size="md"
        @click="clearClaim()"
      />
    </div>
  </div>
  <div v-else class="claim-editor__view" :class="isEmpty">
    <div class="claim-editor__forc">
      {{ forcVal }}
      <q-tooltip v-show="forcDescription">{{ forcDescription }}</q-tooltip>
    </div>
    <div class="claim-editor__apportion" :class="{ 'claim-editor--has-peer-review': includePeerReview }">
      <span>{{ apportionVal }}</span>
      <div v-if="includePeerReview" class="claim-editor__peer-review">
        <q-checkbox
          v-model="claim.isPeerReviewed"
          color="primary"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import Forc from '@/models/era/forc';
import ClaimItem from '@/models/era/claim-item';
import ForcSelector from '@/components/common/ForcSelector.vue';

export default {
  name: 'ClaimEditor',
  components: { ForcSelector },
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    forcList: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => new ClaimItem(),
    },
    includePeerReview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minApportion: 10,
    };
  },
  computed: {
    claim: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    apportionVal() {
      return this.claim.apportion ? `${this.claim.apportion}%` : '';
    },
    forcVal() {
      return this.claim.forc ? this.claim.forc.four_digit_code : '';
    },
    forcDescription() {
      return this.claim.forc ? this.claim.forc.description : '';
    },
    isEmpty() {
      return {
        empty: !this.claim.forc && !this.claim.apportion,
      };
    },
  },
  methods: {
    clearClaim() {
      this.claim.forc = new Forc();
      this.claim.apportion = null;
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
