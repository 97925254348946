<template>
  <q-list separator striped no-border>
    <q-item class="text-weight-medium">
      <div class="row full-width justify-between">
        <div class="col-6">
          <div class="row justify-center">
            FoRC apportions
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">Changed by</div>
            <div class="col">Changed on</div>
          </div>
        </div>
      </div>
    </q-item>
    <q-item
      v-for="researcherClaimVersion in reverseSortedManualResearcherClaimVersions"
      :key="'researcherClaimVersionPrevious' + researcherClaimVersion.id"
    >
      <div class="row full-width justify-between">
        <div class="col-6">
          <div v-if="researcherClaimVersion.researcher_claims.length > 0" class="row">
            <div
              v-for="claim in researcherClaimVersion.researcher_claims"
              :key="researcherClaimVersion.id+ '-' +claim.forc.id"
              class="col-4 group"
            >
              <span class="q-pr-sm">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
              <span> {{ claim.apportion }}% </span>
            </div>
          </div>
          <div v-else class="row justify-center">
            <div class="col-auto text-weight-thin">
              Removed all forc apportions
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <span class="col-6">{{ researcherClaimVersion.created_by.displayName() }}</span>
            <span class="col">{{ formatDateTime(researcherClaimVersion.updated_at) }}</span>
          </div>
        </div>
      </div>
    </q-item>
    <q-item v-if="!hasClaimVersions">
      <div class="row full-width justify-center">
        <div class="col-auto text-weight-thin">
          No history yet
        </div>
      </div>
    </q-item>
  </q-list>
</template>

<script>
import _ from 'lodash';
import { formatDateTime } from '@/utils/date';

export default {
  name: 'ResearcherClaimsHistory',
  props: {
    researcherClaimVersions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    reverseSortedManualResearcherClaimVersions() {
      const manualClaims = this.researcherClaimVersions.filter(claim => claim.isManualOverride());
      return _.sortBy(manualClaims, version => -version.updated_at);
    },
    hasClaimVersions() {
      return !_.isEmpty(this.reverseSortedManualResearcherClaimVersions);
    },
  },
  methods: {
    formatDateTime,
  },
};
</script>
