import _ from 'lodash';
import moment from 'moment';

const SUBMISSION_STAGES = {
  created_era: 'Created ERA',
  initial_coding: 'Initial Coding',
  refine_coding: 'Refine Coding',
  what_ifs: 'What Ifs',
  finalise_codes: 'Finalise Codes',
  prepare_submission: 'Prepare for Submission',
  awaiting_arc_feedback: 'Awaiting ARC\'s feedback',
  arc_feedback_correction: 'ARC\'s feedback Correction',
  submitted: 'Submitted',
};

export const ERA_SUBMISSION_STATUS_COLOR_INDICATORS = {
  upcoming: 'orange',
  active: 'positive',
  submitted: 'grey',
};

class Era {
  constructor(era = {}) {
    this.id = era.id;
    this.name = era.name;
    // the API returns the properties 'research_object_start_year' and 'research_object_end_year'
    // but if the era parameter is of type Era then the properties are
    // 'research_output_start_year' and 'research_output_end_year'.
    this.research_output_start_year = era.research_output_start_year || era.research_object_start_year;
    this.research_output_end_year = era.research_output_end_year || era.research_object_end_year;
    this.research_income_start_year = era.research_income_start_year;
    this.research_income_end_year = era.research_income_end_year;
    this.applied_measures_start_year = era.applied_measures_start_year;
    this.applied_measures_end_year = era.applied_measures_end_year;

    // eslint-disable-next-line no-underscore-dangle
    this._census_date = era.census_date;
    this.submission_status = era.submission_status;
    this.forc_reference_meta_id = era.forc_reference_meta_id;
    this.before_mapping_forc_reference_meta_id = era.before_mapping_forc_reference_meta_id;
    this.journal_reference_meta_id = era.journal_reference_meta_id;
    this.submission_stage = era.submission_stage;
    this.cite_reference_meta_id = era.cite_reference_meta_id;
    this.citation_source = era.citation_source;

    this.disable_clawback_logic = era.disable_clawback_logic;
    this.eligiblity_rule_applied = !era.bypass_eligibility;  //api returns the property eligiblity_rule_applied
    this.benchmark_years_hidden = era.benchmark_years_hidden || 0;
    this.benchmark_footnote = era.benchmark_footnote;
  }

  // eslint-disable-next-line camelcase
  get census_date() {
    // eslint-disable-next-line no-underscore-dangle
    return this._census_date;
  }

  // eslint-disable-next-line camelcase
  set census_date(dateToSet) {
    if (dateToSet) {
      // eslint-disable-next-line no-underscore-dangle
      this._census_date = new Date(dateToSet);
    }
  }

  static fromList(eras = []) {
    return eras.map(era => new Era(era));
  }

  static getStageText(stageCode) {
    return SUBMISSION_STAGES[stageCode];
  }

  updateWith(params) {
    return _.merge(this, params);
  }

  isEmpty() {
    return !this.id;
  }

  isUpcoming() {
    return this.submission_status === 'upcoming';
  }

  isActive() {
    return this.submission_status === 'active';
  }

  isWhatIfStage() {
    return this.submission_stage === 'what_ifs';
  }

  basicInfo() {
    // API mistakenly uses "research_object" so we must convert "research_output" props to match the API fields.
    // API mistakenly uses "bypass_eligibility" so we must convert "eligiblity_rule_applied"  and reverse the value of bypass_eligibility props to match the API fields.
    const apiEra = {
      ...this,
      bypass_eligibility:!this.eligiblity_rule_applied,
      research_object_start_year: this.research_output_start_year,
      research_object_end_year: this.research_output_end_year,
    };

    const basicFields = _.pick(apiEra, [
      'name',
      'research_object_start_year', // "research_object" instead of "research_output"
      'research_object_end_year', // "research_object" instead of "research_output"
      'research_income_start_year',
      'research_income_end_year',
      'applied_measures_start_year',
      'applied_measures_end_year',
      'forc_reference_meta_id',
      'journal_reference_meta_id',
      'cite_reference_meta_id',
      'citation_source',
      'disable_clawback_logic',
      'bypass_eligibility',//api uses bypass_eligibility instead of eligiblity_rule_applied
      'benchmark_years_hidden',
      'benchmark_footnote',
    ]);

    if (this.census_date) {
      basicFields.census_date = moment(this.census_date).format('YYYY-MM-DD');
    }

    return basicFields;
  }

  statusAsString() {
    return _.upperFirst(this.submission_status);
  }

  stageAsString() {
    return SUBMISSION_STAGES[this.submission_stage];
  }
}

export default Era;
