<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="term-definition text-secondary">Count</div>
        <div class="row">
          <div class="col-6">
            <div
              :class="comparison.before_peer_review_stats.isAboveCountThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.before_peer_review_stats.count }}
            </div>
            <div class="body-4 text-mq-soft">Before</div>
          </div>
          <div class="col-6">
            <div
              :class="comparison.after_peer_review_stats.isAboveCountThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.after_peer_review_stats.count }}
            </div>
            <div class="body-4 text-mq-soft">After</div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="term-definition text-secondary">Tagged</div>
        <div class="row">
          <div class="col-6">
            <div
              :class="comparison.before_peer_review_stats.hasBeenTaggedToThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.before_peer_review_stats.tagged_count }}
            </div>
            <div class="body-4 text-mq-soft">Before</div>
          </div>
          <div class="col-6">
            <div
              :class="comparison.after_peer_review_stats.hasBeenTaggedToThreshold()?
                'text-mq-positive':
                'text-negative'"
              class="body-2"
            >
              {{ comparison.after_peer_review_stats.tagged_count }}
            </div>
            <div class="body-4 text-mq-soft">After</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ForcPeerReviewPerformanceComparison
  from '@/models/analytics/forc-peer-review-performance-comparison';

export default {
  name: 'ForcPeerReviewCountComparison',
  props: {
    comparison: {
      type: ForcPeerReviewPerformanceComparison,
      required: true,
    },
  },
};
</script>
