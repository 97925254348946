<template>
  <div class="claim-cell-item">
    <div class="row items-baseline">
      <div class="col-6">
        <q-field
          :error="errors.has('forc-selector')"
          :class="{'border-negative': errors.has('forc-selector')}"
        >
          <forc-selector
            v-validate.passive="'required'"
            :forcs="sortedUserApplicableForcs"
            class="forc-selector round-borders"
            :forc="claimOnEdit.forc"
            required
            placeholder="FoRC"
            color="white"
            inverted
            classes="bg-white text-black"
            name="forc-selector"
            @change="changeForc"
          />
        </q-field>
      </div>
      <div class="col-6 relative-position">
        <q-input
          v-model="claimOnEdit.apportion"
          v-validate.passive="{ required: true, min_value: minApportion, max_value: 100 }"
          type="number"
          :error="errors.has('apportion')"
          :class=" errors.has('apportion')? 'border border-negative': 'no-border'"
          name="apportion"
          color="white"
          inverted
          :min="minApportion"
          :max="100"
          :step="1"
          :decimals="0"
          class="apportion bg-white text-black apportion with-control-on-right"
        />
        <q-checkbox
          v-model="claimOnEdit.is_peer_reviewed"
          color="primary"
          class="absolute-right q-pr-md"
          :disable="!isPeerReviewEligible"
          label="%"
          left-label
        />
      </div>
    </div>
  </div>
</template>

<script>
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ForcSelector from '@/components/common/ForcSelector.vue';
import ResearchOutputClaimsMixin from '@/mixins/ResearchOutputClaimsMixin';

export default {
  name: 'ClaimEditor',
  components: { ForcSelector },
  mixins: [ResearchOutputClaimsMixin],
  model: {
    prop: 'researchOutputClaim',
    event: 'change',
  },
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    isMultiDisciplinary: {
      type: Boolean,
      required: true,
    },
    journalForcs: {
      type: Array,
      default: () => [],
    },
    forcs: {
      type: Array,
      required: true,
    },
    userForcs: {
      type: Array,
      required: true,
    },
    researchOutputClaim: {
      type: ResearchOutputClaim,
      required: true,
    },
    clawbackBypassedForcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
      claimOnEdit: new ResearchOutputClaim(),
    };
  },
  computed: {
    isPeerReviewEligible() {
      return this.claimOnEdit.isPeerReviewEligible();
    },
    minApportion() {
      return this.claimOnEdit.isClawback()
        ? this.clawbackMinApportion
        : this.nonClawbackMinApportion;
    },
  },
  methods: {
    changeForc(forc) {
      this.claimOnEdit.forc = forc;
      if (!forc.is_peer_review_eligible) {
        this.claimOnEdit.is_peer_reviewed = false;
      }
    },
    updateClaim() {
      this.researchOutputClaim.forc = this.claimOnEdit.forc;
      this.researchOutputClaim.apportion = this.claimOnEdit.apportion;
      this.researchOutputClaim.is_peer_review = this.claimOnEdit.is_peer_review;
    },
    async accept() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return false;
      this.updateClaim();
      return true;
    },
  },
  mounted() {
    this.claimOnEdit = new ResearchOutputClaim(this.researchOutputClaim);
  },
};
</script>
<style lang="stylus" scoped>
  .forc-selector
    width 75px

  .apportion
    max-width 75px

</style>
