<template>
  <q-page class="full-page" :style-fn="pageHeight">
    <radar-modal v-model="isSearchDisplayed" max-width="">
      <div slot="modal-definition">Search</div>
      <applied-measure-search ref="appliedMeasureSearch" @searched="isSearchDisplayed=false" />
    </radar-modal>

    <!-- Page header -->
    <portal to="app-header">
      <div class="page-title row items-center bg-mq-sand">
        <div class="col">Applied Measures</div>
        <span class="justify-start items-baseline group q-mr-sm">
          <q-chip color="teal" dense>Patent: {{ patentStats.stats.children_count }}</q-chip>
          <q-chip color="blue" dense>Patent Families: {{ patentStats.stats.count }}</q-chip>
        </span>
        <q-btn
          class="search-bar no-shadow"
          icon="search"
          align="left"
          no-ripple
          no-caps
          @click="isSearchDisplayed=true"
        >
          Search {{ hasActiveFilters()? ' - Filters present': '' }}
        </q-btn>
      </div>
    </portal>
    <div class="scrollable-section">
      <q-table
        class="applied-measures-table"
        table-class="bg-white"
        :data="appliedMeasures"
        :columns="columns"
        :rows-per-page-options="[0]"
        :pagination.sync="pagination"
        row-key="id"
        binary-state-sort
        no-data-label="No data found"
      >
        <!-- Table header -->
        <q-tr slot="header" slot-scope="props">
          <q-th key="title" class="grid-cell bg-white" :props="props">
            <div class="detail-section">
              <div class="col-toggle">
                <q-checkbox
                  v-model="allExpandMode"
                  color="primary"
                  checked-icon="keyboard_arrow_down"
                  unchecked-icon="chevron_right"
                  keep-color
                  @input="setAllExpandedSlots"
                />
              </div>
              <div class="col-title">Applied Measure</div>
              <div class="col-am-type">Type</div>
              <div class="col-year">Year</div>
              <div class="col-autoforc">Automated FoRCs</div>
              <div v-show="changeCount" class="col-children q-my-md">
                <q-btn
                  color="primary"
                  label="Save Eligibility"
                  size="sm"
                  @click="saveEligibility()"
                />
                <q-btn
                  class="q-ml-md"
                  color="mq-sand-dark"
                  text-color="dark"
                  label="Cancel"
                  size="sm"
                  @click="cancelEligibility()"
                />
              </div>
            </div>
          </q-th>
          <th class="grid-cell bg-mq-sand" width="550">
            <div class="claim-section">
              <div class="col-claim1">Current Claim 1</div>
              <div class="col-claim2">Current Claim 2</div>
              <div class="col-claim3">Current Claim 3</div>
              <div class="col-status" />
            </div>
          </th>
        </q-tr>
        <!-- Line detail -->
        <template slot="body" slot-scope="props">
          <q-tr :props="props" :class="{ ineligible: !props.row.is_eligible }">
            <q-tooltip v-if="!props.row.is_eligible" class="ineligible-tooltip">
              Ineligible Applied Measure
            </q-tooltip>

            <q-td class="grid-cell">
              <div class="detail-section">
                <div class="col-toggle">
                  <q-checkbox
                    v-model="expansionSlots[props.row.id]"
                    color="primary"
                    checked-icon="keyboard_arrow_down"
                    unchecked-icon="chevron_right"
                    keep-color
                  />
                </div>
                <div class="col-title">
                  {{ props.row.name }}
                </div>
                <author-names-router-link
                  class="col-authors q-mt-xs"
                  tag="div"
                  :authors="props.row.uniqueCreators()"
                />
                <div v-show="isExpanded(props.row.id)" class="col-children">
                  <applied-measure-metadata-cell
                    :ref="`eligibility-${props.row.id}`"
                    :applied-measure="props.row"
                    @change="updateEligibleStatus"
                  />
                </div>
                <div class="col-am-type">{{ props.row.getAppliedMeasureTypeName() }}</div>
                <div class="col-year term-definition-condensed">
                  <div v-for="pubYear in props.row.getYearsSorted()" :key="pubYear">
                    {{ pubYear }}
                  </div>
                </div>
                <div class="col-autoforc term-definition-condensed">
                  <div v-for="claim in props.row.automated_claim_version.claims" :key="claim.id">
                    {{ claim.forc.four_digit_code }}
                    <span>{{ claim.apportion }}%</span>
                  </div>
                </div>
              </div>
            </q-td>
            <q-td class="grid-cell">
              <form class="claim-section" @submit.prevent="saveAmClaims(props.row)">
                <div v-for="(claimClass, claimIndex) in ['col-claim1', 'col-claim2', 'col-claim3']" :key="claimClass" :class="[claimClass]">
                  <claim-editor
                    v-model="props.row.under_edit_claims[claimIndex]"
                    :forc-list="fourDigitForcsForWorkingEra"
                    :edit-mode="selectedEdit === props.row.id"
                    @blur="props.row.validate()"
                  />
                  <div v-if="isExpanded(props.row.id) && props.row.hasPreviousClaimVersions()" class="q-mt-md">
                    <div class="q-mt-lg term-title-small text-left all-caps">
                      Previous Era
                    </div>
                    <claim-editor
                      class="q-mt-sm read-only"
                      :value="props.row.getPreviousClaimAt(claimIndex)"
                      :edit-mode="false"
                    />
                  </div>
                </div>
                <div class="col-status">
                  <q-btn
                    v-if="canUserManageAppliedMeasureClaim && !selectedEdit"
                    type="button"
                    class="claim-btn no-shadow"
                    color="blue-grey-5"
                    icon="edit"
                    size="md"
                    @click="editClaims(props.row)"
                  />
                  <q-icon
                    v-if="props.row.isValid()"
                    round
                    dense
                    flat
                    size="22px"
                    color="positive"
                    name="check_circle"
                  />
                  <q-icon
                    v-if="props.row.isInvalid()"
                    round
                    dense
                    flat
                    size="22px"
                    color="negative"
                    name="warning"
                  />
                </div>
                <div class="col-claim-info">
                  <div v-if="selectedEdit === props.row.id" class="q-mt-lg">
                    <q-field>
                      <q-input
                        v-model.trim="props.row.comment"
                        v-validate="'no_op'"
                        name="comment"
                        type="textarea"
                        class="border bg-white comfortable-textarea"
                        hide-underline
                        placeholder="Write a comment"
                        maxlength="1000"
                      />
                    </q-field>
                    <div
                      v-if="props.row.isInvalid()"
                      class="q-pa-md q-my-md border border-primary round-borders bg-white text-left"
                    >
                      <grid-validation-errors :validation="props.row.validation" />
                    </div>
                    <div class="flex justify-end q-my-lg">
                      <span v-if="progress.saving">Saving...</span>
                      <template v-else>
                        <q-btn
                          type="button"
                          color="blue-grey-5"
                          @click="cancelClaims(props.row)"
                        >
                          Cancel
                        </q-btn>
                        <q-btn
                          class="q-ml-md"
                          type="submit"
                          color="primary"
                        >
                          Save
                        </q-btn>
                      </template>
                    </div>
                  </div>
                  <!-- Claims history -->
                  <div v-if="isExpanded(props.row.id)" class="q-py-md">
                    <claims-history :claim-versions="props.row.last_three_version_history" />
                  </div>
                </div>
              </form>
            </q-td>
          </q-tr>
        </template>
        <div slot="bottom" slot-scope="props" class="row justify-between fit">
          <div class="q-pt-sm col group">
            <span v-if="progress.saving" class="on-right">Saving...</span>
          </div>
          <div class="col text-right">
            <pagination-total-count
              slot="paginationTotal"
              class="q-ma-sm text-primary col-12 text-right"
              :props="props"
              :current-page="searchPagination.currentPage"
              :page-size="searchPagination.pageSize"
              :total-results="searchPagination.totalResults"
              :is-in-edit-mode="!!selectedEdit || changeCount > 0"
              @change="fetchPage"
              @abort="checkForUnsaveChanges"
            />
          </div>
        </div>
      </q-table>
    </div>
    <save-dialog ref="saveDialog" />
    <radar-loading :visible="progress.searching" />
  </q-page>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import RadarModal from '@/components/common/RadarModal.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import AuthorNamesRouterLink from '@/components/common/AuthorNamesRouterLink.vue';
import SaveDialog from '@/components/common/SaveDialog.vue';
import GridValidationErrors from '@/components/grid/grid-validation-errors.vue';
import ClaimEditor from '@/components/claim/ClaimEditor.vue';
import ClaimsHistory from '@/components/claim/ClaimsHistory.vue';
import AppliedMeasureStatistics from '@/models/applied-measure/applied-measure-statistics';
import AppliedMeasureMetadataCell from './AppliedMeasureMetadataCell.vue';
import { appliedMeasureFields } from './applied-measure-fields';
import AppliedMeasureSearch from './AppliedMeasureSearch.vue';

export default {
  name: 'AppliedMeasureList',
  components: {
    RadarModal,
    RadarLoading,
    PaginationTotalCount,
    GridValidationErrors,
    AuthorNamesRouterLink,
    ClaimEditor,
    ClaimsHistory,
    AppliedMeasureSearch,
    AppliedMeasureMetadataCell,
    SaveDialog,
  },
  mixins: [AllFieldsExpandableTable, PermissionsMixin],
  data() {
    return {
      isSearchDisplayed: false,
      columns: appliedMeasureFields,
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      selectedEdit: null,
      selectedRow: null,
      eligibilityChanged: new Set(),
      changeCount: 0,
    };
  },
  computed: {
    ...mapState('appliedMeasures', ['appliedMeasures', 'searchPagination', 'progress', 'appliedMeasuresStatistics']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['fourDigitForcsForWorkingEra']),
    eraId() {
      return this.$route.params.eraId;
    },
    expansionSlotDataItems() {
      return this.appliedMeasures;
    },
    patentStats() {
      return !_.isEmpty(this.appliedMeasuresStatistics) ? AppliedMeasureStatistics.patentStats(this.appliedMeasuresStatistics)
        : new AppliedMeasureStatistics();
    },
    hasUnsavedChanges() {
      return this.changeCount > 0 || this.selectedEdit;
    },
  },
  methods: {
    ...mapActions('appliedMeasures', ['saveClaims', 'saveEligibilities']),
    fetchPage(page) {
      this.eligibilityChanged.clear();
      this.changeCount = 0;
      this.$refs.appliedMeasureSearch.search(page);
    },
    async paginateTo(page, clearSearch = false) {
      this.fetchPage(page, clearSearch);
    },
    async initiateSearch() {
      this.$emit('initiateSearch');
    },
    hasActiveFilters() {
      if (!this.$refs.appliedMeasureSearch) return false;
      return this.$refs.appliedMeasureSearch.hasActiveFilters();
    },
    updateEligibleStatus({ id, changed }) {
      if (changed) {
        this.eligibilityChanged.add(id);
      } else {
        this.eligibilityChanged.delete(id);
      }
      this.changeCount = this.eligibilityChanged.size;
    },
    async saveEligibility() {
      try {
        const changedItems = Array.from(this.eligibilityChanged).flatMap(id => this.$refs[`eligibility-${id}`].changedItems());
        await this.saveEligibilities(changedItems);
        this.eligibilityChanged.clear();
        this.changeCount = 0;
        await this.$refs.appliedMeasureSearch.reload();
        this.$notify.success('Eligibilities successfully saved');
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    cancelEligibility() {
      this.eligibilityChanged.forEach((id) => {
        const key = `eligibility-${id}`;
        this.$refs[key].cancel();
      });
      this.eligibilityChanged.clear();
      this.changeCount = 0;
    },
    editClaims(row) {
      row.addEmptyClaims();
      this.selectedRow = row;
      this.selectedEdit = row.id;
    },
    cancelClaims(row) {
      row.updateLatestClaimVersionWith(row.latest_claim_version);
      this.exitClaims(row);
    },
    exitClaims(row) {
      row.validation.reset();
      this.selectedEdit = null;
    },
    async saveAmClaims(row) {
      if (row.validate()) {
        row.removeEmptyClaims();
        if (row.hasClaimChanges()) {
          try {
            await this.saveClaims([row]);
            this.$notify.success('Claim successfully saved');
            this.exitClaims(row);
          } catch (e) {
            this.$notify.failure(e);
          }
        } else {
          this.exitClaims(row);
        }
      }
    },
    async saveChanges() {
      if (this.changeCount > 0) {
        await this.saveEligibility();
      }
      if (this.selectedEdit) {
        await this.saveAmClaims(this.selectedRow);
      }
    },
    async cancelChanges() {
      if (this.changeCount > 0) {
        await this.cancelEligibility();
      }
      if (this.selectedEdit) {
        await this.cancelClaims(this.selectedRow);
      }
    },
    checkForUnsaveChanges(next) {
      this.$refs.saveDialog.show({
        save: this.saveChanges,
        dontSave: this.cancelChanges,
        hasUnsaved: () => this.hasUnsavedChanges,
        next,
      });
    },
    confirmPageExit(e) {
      const evt = e;
      if (this.hasUnsavedChanges) {
        evt.preventDefault();
        evt.returnValue = '';
      }
    },
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height };
    },
  },
  watch: {
    eraId() {
      this.fetchPage(1);
    },
    appliedMeasures() {
      this.setAllExpandedSlots(this.allExpandMode);
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.confirmPageExit);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.confirmPageExit);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.checkForUnsaveChanges(next);
  },
};
</script>

<style lang="stylus" scoped>
.search-bar
  flex-basis 250px

.grid-cell
  padding 5px 0
  vertical-align top

.ineligible
  background-color #eee
  color #888

  .claim-btn
    display none

.ineligible-tooltip
  background-color $mq-blood-red
  color #fff
  font-size $font-size-3
  font-weight 500

.applied-measures-table
  >>> th.grid-cell
    vertical-align top
    padding-top 20px

$grid-container
  position relative
  display grid

  > div
    padding 0 5px

.detail-section
  @extend $grid-container
  grid-template-columns 25px 1fr 100px 50px 135px
  grid-template-areas \
    "toggle title    am-type  year     autoforc" \
    "toggle authors  authors  year     autoforc" \
    "toggle children children children children"

.col-toggle
  grid-area toggle
  position absolute
  margin-top -2px
  padding-left 0

.col-title
  grid-area title
  min-width 180px
  white-space normal

.col-authors
  grid-area authors

.col-am-type
  grid-area am-type

.col-year
  grid-area year

.col-autoforc
  grid-area autoforc
  text-align right

  span
    display inline-block
    width 50px

.col-info
  grid-area info

.col-children
  grid-area children

.claim-section
  @extend $grid-container
  min-width 470px
  grid-template-columns 1fr 1fr 1fr 40px
  grid-template-rows 1fr auto
  grid-template-areas \
    "claim1     claim2     claim3     status" \
    "claim-info claim-info claim-info status"

  [class^=col-claim]
    min-width 140px
    text-align center

.col-claim1
  grid-area claim1

.col-claim2
  grid-area claim2

.col-claim3
  grid-area claim3

.col-status
  grid-area status
  padding-top 2px

.col-claim-info
  grid-area claim-info
</style>
