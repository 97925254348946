class DataQualityDefinition {
  constructor(val) {
    const definition = val || {};
    this.id = definition.id;
    this.code = definition.code;
    this.checkType = definition.checkType || definition.check_type;
    this.description = definition.description;
    this.disable = definition.disable || false;
  }

  static fromList(items = []) {
    return items.map(item => new DataQualityDefinition(item));
  }
}

export default DataQualityDefinition;
