<template>
  <form
    class="row items-baseline full-width ro-claims ro-claim-editor"
    @submit.prevent="addClaim"
  >
    <q-field
      class="col-auto forc-selector items-baseline"
      error-label="Required"
      :error="errors.has('forc-selector')"
    >
      <forc-selector
        v-model="researchOutputClaim.forc"
        v-validate.lazy="'required'"
        :forcs="sortedUserApplicableForcs"
        placeholder="Select a FoRC"
        name="forc-selector"
        @change="resetPeerReviewIfNotApplicable"
      />
    </q-field>
    <q-field
      class="col-auto on-right"
      :error-label="minApportion+'% - 100%'"
      :error="errors.has('forc-apportion')"
    >
      <q-input
        v-model.number="researchOutputClaim.apportion"
        v-validate.lazy="{ required: true, min_value: minApportion, max_value: 100 }"
        type="number"
        class="apportion"
        suffix="%"
        placeholder="e.g. 100"
        name="forc-apportion"
        :min="minApportion"
        :max="100"
        :step="1"
        :decimals="0"
      />
    </q-field>
    <div class="col-auto">
      <div class="on-right">
        <q-checkbox
          v-model="researchOutputClaim.is_peer_reviewed"
          :disable="!researchOutputClaim.isPeerReviewEligible()"
        >
          <span class="q-pl-sm text-weight-regular q-pt-xs">Peer review</span>
        </q-checkbox>
      </div>
    </div>
    <div class="col-auto on-right group">
      <q-btn
        type="submit"
        label="Add to selection"
        color="primary"
        :disable="disable || !researchOutputClaim.forc || !researchOutputClaim.apportion"
      />
    </div>
  </form>
</template>

<script>
import Forc from '@/models/era/forc';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ForcSelector from '@/components/common/ForcSelector.vue';

export default {
  name: 'BulkClaim',
  components: { ForcSelector },
  props: {
    forcs: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      researchOutputClaim: new ResearchOutputClaim(),
      nonClawbackMinApportion: 20,
      clawbackMinApportion: 66,
    };
  },
  computed: {
    minApportion() {
      if (this.researchOutputClaim.isClawback()) {
        return this.clawbackMinApportion;
      }
      return this.nonClawbackMinApportion;
    },
    sortedUserApplicableForcs() {
      return Forc.sortAsNumbers(this.forcs);
    },
  },
  methods: {
    addClaim() {
      return this.$emit('addClaim', this.researchOutputClaim);
    },
    resetPeerReviewIfNotApplicable() {
      if (!this.researchOutputClaim.forc.is_peer_review_eligible) {
        this.researchOutputClaim.is_peer_reviewed = false;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
  .apportion
    min-width 83px
    font-size 16px

  .forc-selector
    min-width 140px
    font-size 16px

</style>
