import { CHECK_TYPES } from '@/models/data-quality/data-quality-types';
import DataQualityCount from './data-quality-count';

const CHECK_DETAILS = new Map([
  [CHECK_TYPES.ResearchOutput, { color: 'blue', icon: 'article', iconClass: 'material-icons-outlined' }],
  [CHECK_TYPES.Researcher, { color: 'teal', icon: 'school', iconClass: null }],
  [CHECK_TYPES.AppliedMeasure, { color: 'orange', icon: 'straighten', iconClass: null }],
  [CHECK_TYPES.Income, { color: 'indigo', icon: 'monetization_on', iconClass: null }],
]);

class DataQualityStats extends DataQualityCount {
  constructor(checkType = null, stats = {}) {
    super(stats.ARC, stats.MQ);
    this.checkType = checkType;

    const details = CHECK_DETAILS.get(checkType);
    if (details) {
      this.icon = details.icon;
      this.iconClass = details.iconClass;
      this.color = details.color;
    }
  }

  static toArray(val) {
    return Object.entries(val || {})
      .map(([key, stats]) => new DataQualityStats(key, stats));
  }
}

export default DataQualityStats;
