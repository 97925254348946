<template>
  <div v-if="forc" :id="comparison.forc_id" class="cursor-pointer all-pointer-events" @click="selectForc">
    <div class="comparision-title">
      <q-btn
        size="sm"
        flat
        round
        class="float-left"
        dense
        :icon="detailsVisible?'keyboard_arrow_down':'keyboard_arrow_right'"
        @click.stop="toggleDetailsVisibility"
      />
      <div class="q-pl-sm flex items-center">
        <span class="body-2">{{ forcText }}</span>
        <q-chip
          text-color="light"
          color="white"
          square
          dense
          class="border on-right forc-type-label"
        >
          {{ forcTypeText }}
        </q-chip>
      </div>
    </div>
    <component
      :is="renderComponent"
      class="q-pl-xl"
      :comparison="comparison"
      :show-details="detailsVisible"
      :forcs="forcs"
    />
  </div>
</template>

<script>
import ForcCitationPerformanceComparison from '@/models/analytics/forc-citation-performance-comparison';
import ForcPeerReviewPerformanceComparison from '@/models/analytics/forc-peer-review-performance-comparison';
import ForcCitationPerformanceComparisonComponent from './ForcCitationPerformanceComparison.vue';
import ForcPeerReviewPerformanceComparisonComponent from './ForcPeerReviewPerformanceComparison.vue';

export default {
  name: 'ForcPerformanceComparison',
  components: {
    ForcCitationPerformanceComparisonComponent,
    ForcPeerReviewPerformanceComparisonComponent,
  },
  props: {
    comparison: {
      type: [
        ForcCitationPerformanceComparison,
        ForcPeerReviewPerformanceComparison,
      ],
      required: true,
    },
    forcs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      detailsVisible: false,
    };
  },
  computed: {
    renderComponent() {
      return this.isPeerReview
        ? ForcPeerReviewPerformanceComparisonComponent
        : ForcCitationPerformanceComparisonComponent;
    },
    isPeerReview() {
      return this.comparison.type === ForcPeerReviewPerformanceComparison.TYPE;
    },
    forc() {
      return this.forcs.find(forc => forc.id === this.comparison.forc_id);
    },
    forcText() {
      return this.forc.text;
    },
    forcTypeText() {
      return this.isPeerReview ? 'Peer review' : 'Citation';
    },
  },
  methods: {
    expandDetails() {
      this.detailsVisible = true;
    },
    collapseDetails() {
      this.detailsVisible = false;
    },
    toggleDetailsVisibility() {
      this.detailsVisible = !this.detailsVisible;
    },
    selectForc() {
      this.$emit('select', this.forc);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .forc-type-label
    font-size 11px !important
    color #777f88
    height: 1rem
</style>
