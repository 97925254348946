import api from '@/api';
import Forc from '@/models/era/forc';
import ReferenceMeta from '@/models/era/reference-meta';

export default {
  namespaced: true,
  state: {
    referenceMetas: [],
    forcs: [],
    citationSources: ['SCOPUS', 'WOS'],
  },
  mutations: {
    UPDATE_REFERENCE_METAS(state, referenceMetas) {
      state.referenceMetas = referenceMetas;
    },
    UPDATE_FORCS(state, forcs) {
      state.forcs = forcs;
    },
  },
  actions: {
    async getAllReferenceMetas({ commit }) {
      const response = await api.reference.getAllReferenceMetas();
      const referenceMetas = ReferenceMeta.fromList(response.data);
      commit('UPDATE_REFERENCE_METAS', referenceMetas);
    },
    async getAllForcs({ commit }, params) {
      const response = await api.reference.getAllForcs(params.metaId);
      const forcs = Forc.fromList(response.data);
      commit('UPDATE_FORCS', forcs);
    },
    async prepareAllReferenceData(context) {
      context.dispatch('getAllReferenceMetas');
    },
  },
  getters: {
    forcs(state) {
      return state.forcs;
    },
    // TODO : Remove this and use the forcs getter, because on every era change,
    //  we fetch forcs for the era, and only those forcs are kept in the store.
    //  All notions of forcs for working era can be removed
    forcsForWorkingEra(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      return state.forcs.filter(forc => forc.meta_id === workingEra.forc_reference_meta_id);
    },
    twoAndFourDigitForcsForWorkingEra(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      return state.forcs.filter(forc => forc.meta_id === workingEra.forc_reference_meta_id
        && !forc.isSixDigitForc());
    },
    twoDigitForcsForWorkingEra(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      return state.forcs.filter(forc => forc.meta_id === workingEra.forc_reference_meta_id
        && forc.isTwoDigitForc());
    },
    fourAndSixDigitForcsForWorkingEra(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      return state.forcs.filter(forc => forc.meta_id === workingEra.forc_reference_meta_id
        && !forc.isTwoDigitForc());
    },
    fourDigitForcsForWorkingEra(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      return state.forcs.filter(forc => forc.meta_id === workingEra.forc_reference_meta_id
        && forc.isFourDigitForc());
    },
    forcReferenceMetas(state) {
      return state.referenceMetas
        .filter(referenceMeta => referenceMeta.isForcType());
    },
    workingEraForcReference(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      const forcReferenceMeta = state.referenceMetas
        .filter(referenceMeta => referenceMeta.isForcType() && referenceMeta.id === workingEra.forc_reference_meta_id);
      return forcReferenceMeta && forcReferenceMeta.length === 1 ? forcReferenceMeta[0] : {};
    },
    previousEraForcReference(state, getters, rootState, rootGetters) {
      const workingEra = rootGetters['eras/workingEra'];
      const forcReferenceMeta = state.referenceMetas
        .filter(referenceMeta => referenceMeta.isForcType() && referenceMeta.id === workingEra.before_mapping_forc_reference_meta_id);
      return forcReferenceMeta && forcReferenceMeta.length === 1 ? forcReferenceMeta[0] : {};
    },
    journalReferenceMetas(state) {
      return state.referenceMetas
        .filter(referenceMeta => referenceMeta.isJournalType());
    },
    citationBenchmarkReferenceMetas(state) {
      return state.referenceMetas
        .filter(referenceMeta => referenceMeta.isCitationBenchmarkType());
    },
    // eslint-disable-next-line eqeqeq
    forcsByReferenceMetaId: state => referenceMetaId => state.forcs.filter(forc => forc.meta_id == referenceMetaId),
    // eslint-disable-next-line eqeqeq
    fourDigitForcByReferenceMetaId: state => referenceMetaId => state.forcs.filter(forc => forc.meta_id == referenceMetaId && forc.isFourDigitForc()),
    twoAndFourDigitForcByReferenceMetaId(state) {
      // eslint-disable-next-line eqeqeq
      return referenceMetaId => state.forcs.filter(forc => forc.meta_id == referenceMetaId && (forc.isFourDigitForc() || forc.isTwoDigitForc()));
    },
  },
};
