<template>
  <form class="row justify-between" @submit.prevent="userTriggeredSearch(searchParameters.impactedOnly)">
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            color="mq-soft"
            label="Clear"
            flat
            class="q-mr-md"
            @click="clearSearch()"
          />
          <q-btn type="submit" color="primary" label="Search" />
        </div>
      </div>

      <q-field label="Author Name/MQ Id">
        <q-input v-model.trim="searchParameters.authorNameContains" />
      </q-field>
      <q-field label="Author Faculty/Department/Schools">
        <q-select
          v-model="searchParameters.facultyOrDepartmentName"
          :filter="labelContainsFilter"
          :options="facultyOrDepartmentOptions"
          clearable
        />
      </q-field>

      <q-field label="ERA Category">
        <q-select
          v-model="searchParameters.researchOutputTypes"
          :options="typeOptions"
          clearable
          multiple
          chips
          @input="inputCategoryChange"
        />
      </q-field>

      <q-field label="Pure ID">
        <q-input v-model.trim="searchParameters.pureId" clearable />
      </q-field>

      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" />
      </q-field>

      <div class="row q-field">
        <div class="q-field-label q-field-margin col-xs-12 col-sm-5">Year</div>
        <div class="q-field-content col-xs-12 col-sm row justify-between">
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">Start</span>
            <q-select
              v-model="searchParameters.yearStart"
              class="col"
              align="center"
              :options="yearStartOptions"
              clearable
              autofocus-filter
            />
          </div>
          <div class="col-5 row">
            <span class="q-field-margin q-mr-sm">End</span>
            <q-select
              v-model="searchParameters.yearEnd"
              class="col"
              align="center"
              :options="yearEndOptions"
              clearable
              autofocus-filter
            />
          </div>
        </div>
      </div>

      <q-field label="Parent Doc">
        <q-input v-model.trim="searchParameters.parentDoc" />
      </q-field>
      <q-field label="ARC Journal FoRCs" class="r-p-vertical">
        <q-select
          v-model="searchParameters.journalForcId"
          :options="journalForcOptions"
          :disable="searchParameters.withMdJournalForc"
          autofocus-filter
          :filter="labelStartWithOrSublabelContainsFilter"
          clearable
        />
        <q-checkbox
          v-model="searchParameters.withMdJournalForc"
          class="q-if-label-above floating-label-font q-mt-lg"
          label="With MD ARC Journal FoRCs"
        />
      </q-field>

      <q-field label="Claim FoRCs" class="r-p-vertical">
        <q-field
          :helper="searchParameters.withPeerReview?
            'Will search peer review tags associated with this claim only':''"
        >
          <q-select
            v-model="searchParameters.claimForcIds"
            :options="claimForcOptions"
            :disable="searchParameters.withNoClaimForcApportions"
            :filter="labelStartWithOrSublabelContainsFilter"
            autofocus-filter
            multiple
            chips
            clearable
          />
        </q-field>
        <q-checkbox
          v-model="searchParameters.withNoClaimForcApportions"
          color="black"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="With no Claim FoRC apportions"
        />
        <q-checkbox
          v-model="searchParameters.withPeerReview"
          color="black"
          :disable="searchParameters.withNoClaimForcApportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          label="Peer Review Tagged"
        />
      </q-field>

      <q-field label="RCI Class" helper="Select only one claim forc to search by its class">
        <q-select
          v-model="searchParameters.rciClass"
          :options="rciClassOptions"
          :disable="withNoForc"
          clearable
          filter
        />
      </q-field>

      <q-field label="Status" class="r-p-vertical">
        <q-select
          v-model="searchParameters.roStatus"
          :options="roStatusOptions"
          autofocus-filter
          clearable
        />
      </q-field>

      <q-field label="Eligibility" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.eligibility"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="eligibilityOptions"
          :disable="true"
        />
      </q-field>

      <q-field class="r-p-vertical">
        <template v-slot:label>
          <div class="row items-center all-pointer-events">
            FoRC Interests
            <q-tooltip>
              RO's with Author, Departmental or ARC Journal FoRCs that match the selected FoRC Interest
            </q-tooltip>
          </div>
        </template>

        <q-select
          v-model="searchParameters.interestForcId"
          :options="journalForcOptions"
          :filter="labelStartWithOrSublabelContainsFilter"
          :disable="searchParameters.interestsForAllMyForcs"
          autofocus-filter
          clearable
        />
        <q-checkbox
          v-model="searchParameters.interestsForAllMyForcs"
          class="q-if-label-above floating-label-font q-mt-lg"
          label="All my FoRCs"
        />
      </q-field>

      <div class="r-p-vertical">
        <div>Claim Options</div>
        <q-field label="My Claims" inset="icon" class="q-mt-md">
          <q-option-group
            v-model="searchParameters.myClaims"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="myClaimOptions"
          />
        </q-field>
        <q-field label="Other User Claims" inset="icon" class="q-mt-lg">
          <q-option-group
            v-model="searchParameters.otherUserClaims"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="otherUserClaimOptions"
          />
        </q-field>
        <q-field label="Recently Claimed" inset="icon" class="q-mt-lg">
          <q-option-group
            v-model="searchParameters.recentlyClaimed"
            type="radio"
            class="q-if-label-above floating-label-font block inset"
            :options="recentlyClaimedOptions"
          />
        </q-field>
      </div>
      <div class="q-mt-xl text-right">
        <q-btn
          type="button"
          color="mq-soft"
          label="Clear"
          flat
          class="q-mr-md"
          @click="clearSearch()"
        />
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { labelContainsFilter, labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import { CLAIM_OPTIONS_MINE, CLAIM_OPTIONS_OTHERS, CLAIM_OPTIONS_RECENT } from '@/models/research-output/research-output-search-param';
import Forc from '@/models/era/forc';
import ClassDistribution from '@/models/analytics/class-distribution';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputSearchParam from '@/models/what-if/what-if-research-output-search-param';

const ALL_RO_TYPES = {
  ALL_TRADITIONAL: 'ALL_TRADITIONAL',
  ALL_NTRO: 'ALL_NTRO',
};

export default {
  name: 'WhatIfResearchOutputSearch',
  props: {
    workQueueType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchParameters: new ResearchOutputSearchParam(Number(this.$route.params.eraId)),
      allROTypes: ALL_RO_TYPES,
      selectedQuery: null,
    };
  },
  computed: {
    ...mapState('researchers', ['eligibility']),
    ...mapState('researchOutputs', ['types', 'roStatuses']),
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapGetters('referenceData', ['forcsForWorkingEra', 'fourDigitForcByReferenceMetaId']),
    ...mapGetters('eras', ['workingEra', 'researchOutputYearRange']),
    whatIfId() {
      return this.$route.params.whatIfId;
    },
    typeOptions() {
      const allTypes = [{ label: 'All NTRO\'s', value: this.allROTypes.ALL_NTRO },
        { label: 'All Traditional RO\'s', value: this.allROTypes.ALL_TRADITIONAL }];
      const types = this.types.map(type => ({ label: type, value: type.split(':')[0] }));
      return [...allTypes, ...types];
    },
    journalForcOptions() {
      const forcsForJournals = Forc.getTwoAndFourDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForJournals);
    },
    claimForcOptions() {
      const sortedForcs = _.sortBy(
        this.fourDigitForcByReferenceMetaId(this.workingEra.forc_reference_meta_id),
        forc => forc.text,
      );
      return this.forcOptions(sortedForcs);
    },
    getNonTraditionalROTypes() {
      return ResearchOutput.getNonTraditionalROTypes();
    },
    getTraditionalROTypes() {
      return ResearchOutput.getTraditionalROTypes();
    },
    roStatusOptions() {
      return this.roStatuses.map(status => ({ label: status.split(':')[1], value: status.split(':')[0] }));
    },
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [...new Set([...this.departments, ...this.faculties].map(i => (i.name)))].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map(i => ({ label: i, value: i }));
    },
    eligibilityOptions() {
      const options = this.eligibility.map(eligibility => ({ label: eligibility.split(':')[1], value: eligibility.split(':')[0] }));
      return [...options, ...[{ label: 'All records', value: 'all' }]];
    },
    yearStartOptions() {
      return this.researchOutputYearRange.map(year => ({ label: `${year}`, value: year }));
    },
    yearEndOptions() {
      const startYear = this.searchParameters.yearStart || this.workingEra.research_output_start_year;
      return _.range(startYear, this.workingEra.research_output_end_year + 1)
        .map(year => ({ label: `${year}`, value: year }));
    },
    myClaimOptions() {
      return Object.values(CLAIM_OPTIONS_MINE);
    },
    otherUserClaimOptions() {
      return Object.values(CLAIM_OPTIONS_OTHERS);
    },
    recentlyClaimedOptions() {
      return Object.values(CLAIM_OPTIONS_RECENT);
    },
    rciClassOptions() {
      const sortedRciClasss = _.sortBy(ClassDistribution.rciClasses(), 'description');
      return sortedRciClasss.map(rciClass => ({
        label: rciClass.description,
        sublabel: `(${rciClass.legend})`,
        value: rciClass.code,
      }));
    },
    withNoForc() {
      return this.searchParameters.claimForcIds.length !== 1;
    },
  },
  methods: {
    ...mapActions('whatIfScenario', ['searchResearchOutputs']),
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    hasActiveFilters() {
      const defaultParameters = _.omit(new ResearchOutputSearchParam(Number(this.$route.params.eraId)), 'page');
      const currentParameters = _.omit(this.searchParameters, 'page');
      return !_.isEqual(defaultParameters, currentParameters);
    },
    clearSearch() {
      this.updateParameters({});
    },
    updateQuery(searchParams) {
      const page = searchParams.page || 1;
      const nonEmptyParameters = Object.fromEntries(Object.entries(searchParams).filter(v => !!v[1]));
      this.$router.push({ query: { ...nonEmptyParameters, page } })
        .catch((e) => {
          if (e.name !== 'NavigationDuplicated') { throw e; }
        });
    },
    updateParameters({ eligibility, ...searchParams }) {
      this.searchParameters = ResearchOutputSearchParam.create(searchParams);
      this.searchParameters.researchOutputTypes = _.pull(searchParams.researchOutputTypes, this.allROTypes.ALL_NTRO, this.allROTypes.ALL_TRADITIONAL) || [];
    },
    hasSearchParametersChanged(previous, current) {
      return _.keys(new ResearchOutputSearchParam(Number(this.$route.params.eraId)))
        .some(key => !_.isEqual(current[key], previous[key]));
    },
    userTriggeredSearch(impactedOnly) {
      this.search(1, impactedOnly);
      this.$emit('searched');
    },
    search(pageToSearch, searchImpactedOnly = false, resetSearch = false) {
      const searchParameters = resetSearch ? new ResearchOutputSearchParam(Number(this.$route.params.eraId)) : this.searchParameters;
      const page = pageToSearch || searchParameters.page;
      const impactedOnly = _.isNil(searchImpactedOnly) ? searchParameters.impactedOnly : searchImpactedOnly;
      const newSearchParameters = { ...searchParameters, impactedOnly, page };

      this.updateQuery(newSearchParameters);
      this.$emit('searched');
    },
    searchOnUrlChange(resetPage) {
      const { query } = this.$route;
      const page = resetPage ? 1 : Number(query.page);
      this.updateParameters(query);
      const searchParams = {
        ...this.searchParameters,
        eraId: Number(this.$route.params.eraId),
        workQueue: this.workQueueType,
        page,
      };
      this.searchResearchOutputs({ id: this.whatIfId, searchParams });
      this.$emit('searched');
    },
    reSyncParametersFromQuery() {
      this.updateParameters(this.$route.query);
    },
    inputCategoryChange(value) {
      if (_.includes(value, this.allROTypes.ALL_NTRO)) {
        this.searchParameters.researchOutputTypes = _.uniq([...this.searchParameters.researchOutputTypes, ...this.getNonTraditionalROTypes]);
      }
      if (_.includes(value, this.allROTypes.ALL_TRADITIONAL)) {
        this.searchParameters.researchOutputTypes = _.uniq([...this.searchParameters.researchOutputTypes, ...this.getTraditionalROTypes]);
      }
    },
  },
  watch: {
    $route() {
      this.searchOnUrlChange();
    },
  },
  created() {
    if (this.hasSearchParametersChanged(new ResearchOutputSearchParam(Number(this.$route.params.eraId)), this.$route.query)) {
      this.searchOnUrlChange();
    }
  },
};
</script>

<style lang="stylus" scoped>
.field-groups
  & > .q-field
    margin-top 20px

.block.inset
  margin-left -30px
</style>
