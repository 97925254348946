<template>
  <div class="">
    <q-btn
      v-if="canManageWhatIf"
      color="blue-grey-5"
      icon="file_copy"
      class="q-mr-md q-mb-md"
      label="Rename"
      @click="updateWhatIfDetail()"
    />
    <q-btn
      v-if="canManageWhatIf"
      color="blue-grey-5"
      icon="delete"
      class="q-mr-md q-mb-md"
      label="Delete"
      :disabled="!canDeleteWhatIf"
      @click="deleteWhatIfDetail()"
    />
    <q-btn
      v-if="canManageWhatIf"
      color="secondary"
      icon="far fa-edit"
      class="q-mr-md q-mb-md"
      label="Edit"
      :disabled="!canEditWhatIf"
      :to="{ name:'what-if-edit', params:{ eraId: workingEra.id, whatIfId: whatIf.id }, query: { impactedOnly: !!whatIf.researchOutputCount } }"
    />
    <q-btn
      v-if="canReviewWhatIf"
      label="Review"
      color="secondary"
      icon="insert_chart_outlined"
      class="q-mr-md q-mb-md"
      :to="{ name:'what-if-review', params:{ eraId: workingEra.id, whatIfId: whatIf.id } }"
    />
    <q-btn
      v-if="canApplyWhatIf"
      color="primary"
      icon="send"
      class="q-mb-md"
      label="Apply"
      @click="applyWhatIfDetail()"
    />
    <q-btn
      v-if="canReopenWhatIf"
      color="primary"
      icon="open_in_new"
      class="q-mb-md"
      label="Reopen"
      @click="reopenWhatIfDetail()"
    />
    <radar-modal v-model="isWhatIfDetailDisplayed">
      <what-if-detail
        ref="whatIfDetail"
        :what-if="whatIf"
        @saved="whatIfSaved"
        @cancel="hideWhatIfDetail"
        @error="error"
      />
    </radar-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import WhatIf from '@/models/what-if/what-if';
import RadarModal from '@/components/common/RadarModal.vue';
import WhatIfDetail from '../WhatIfDetail.vue';

export default {
  name: 'WhatIfScenarioActions',
  components: {
    WhatIfDetail,
    RadarModal,
  },
  props: {
    whatIf: {
      type: WhatIf,
      default: () => new WhatIf(),
    },
  },
  data() {
    return {
      isWhatIfDetailDisplayed: false,
    };
  },
  computed: {
    ...mapState('eras', ['workingEra']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('auth', ['canUserManageWhatIf', 'canUserProposeWhatIf', 'loggedInUser', 'userFacultiesForManageWhatIf']),
    canManageWhatIf() {
      return this.canUserManageWhatIf && this.whatIf.canManageWhatIf(this.loggedInUser);
    },
    canEditWhatIf() {
      return this.canUserManageWhatIf && this.whatIf.canEditWhatIf(this.loggedInUser);
    },
    canDeleteWhatIf() {
      return this.canUserManageWhatIf && this.whatIf.canDeleteWhatIf(this.loggedInUser);
    },
    canReviewWhatIf() {
      return this.canUserManageWhatIf;
    },
    canApplyWhatIf() {
      return this.canUserProposeWhatIf && this.whatIf.canApplyWhatIf(this.userFacultiesForManageWhatIf);
    },
    canReopenWhatIf() {
      return this.canUserManageWhatIf && this.whatIf.isRejected();
    },
  },
  methods: {
    ...mapActions(
      'whatIfs',
      ['deleteWhatIf', 'applyWhatIf', 'reopenWhatIf'],
    ),
    updateWhatIfDetail() {
      this.showWhatIfDetail();
    },
    async deleteWhatIfDetail() {
      try {
        await this.$q.dialog({
          title: 'Delete',
          message: `Are you sure you want to delete ${this.whatIf.title}?`,
          ok: 'Ok',
          cancel: 'Cancel',
        });
        const whatIf = new WhatIf({ ...this.whatIf, era_submission_id: this.workingEra.id });
        await this.deleteWhatIf(whatIf);
        this.$emit('deleted');
      } catch (e) {
        if (e.message) this.$emit('error', e);
      }
    },
    async applyWhatIfDetail() {
      try {
        await this.$q.dialog({
          title: 'Apply',
          message: `Are you sure you want to apply ${this.whatIf.title}?`,
          ok: 'Ok',
          cancel: 'Cancel',
        });
        await this.applyWhatIf(this.whatIf);
        this.$emit('applied');
      } catch (e) {
        if (e.message) this.$emit('error', e);
      }
    },
    async reopenWhatIfDetail() {
      try {
        await this.$q.dialog({
          title: 'Reopen',
          message: `Are you sure you want to reopen ${this.whatIf.title}?`,
          ok: 'Ok',
          cancel: 'Cancel',
        });
        await this.reopenWhatIf(this.whatIf);
        this.$emit('reopened');
      } catch (e) {
        if (e.message) this.$emit('error', e);
      }
    },
    whatIfSaved() {
      this.hideWhatIfDetail();
      this.$emit('saved');
    },
    showWhatIfDetail() {
      this.isWhatIfDetailDisplayed = true;
    },
    hideWhatIfDetail() {
      this.isWhatIfDetailDisplayed = false;
    },
    error(errorMessage) {
      this.$emit('error', errorMessage);
    },
  },
};
</script>

<style scoped>

</style>
