<template>
  <q-layout v-if="isLoggedIn" view="LHh Lpr lFf">
    <q-layout-header class="app-header">
      <portal-target name="app-header" />
    </q-layout-header>
    <side-nav-bar />
    <q-page-container>
      <router-view />
    </q-page-container>
    <q-layout-footer class="app-footer">
      <portal-target name="app-footer" />
    </q-layout-footer>
  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import SideNavBar from '@/components/side-nav-bar/SideNavBar.vue';

export default {
  name: 'LayoutDefault',
  components: { SideNavBar },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
  },
};
</script>

<style lang="stylus" scoped>
.app-header
  background-color $mq-sand !important
  z-index 1000 !important

.app-footer
  background-color white
  border-top 1px solid #ccc
  box-shadow none
  z-index: 1000;
</style>
