import { render, staticRenderFns } from "./WhatIfDetail.vue?vue&type=template&id=b197b744&scoped=true&"
import script from "./WhatIfDetail.vue?vue&type=script&lang=js&"
export * from "./WhatIfDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b197b744",
  null
  
)

export default component.exports