import _ from 'lodash';
import {
  validateAddsToHundred,
  validateDuplicateClaims,
  validateMoreThanThreeClaims,
} from './generic-claims-validator';

const validateJournalForcs = (claims, journalForcs, hasArcRestriction, clawbackBypassedForcs = []) => {
  const errors = claims
    .map(claim => claim.validate(journalForcs, hasArcRestriction, clawbackBypassedForcs));
  return _.flatten(errors);
};

export const validate = (
  claims, journalForcs, isArcRestricted, testClaimAddToHundred,
  allowNoClaims, clawbackBypassedForcs = [],
) => {
  if (allowNoClaims && claims.length === 0) return [];

  const hasMoreThanThreeClaims = validateMoreThanThreeClaims(claims);
  const journalForcErrors = validateJournalForcs(
    claims, journalForcs,
    isArcRestricted,
    clawbackBypassedForcs,
  );
  const duplicateClaimsErrors = validateDuplicateClaims(claims);
  const addsToHundredErrors = testClaimAddToHundred
    ? validateAddsToHundred(claims) : undefined;

  return _.compact(_.concat(
    hasMoreThanThreeClaims,
    journalForcErrors,
    duplicateClaimsErrors,
    addsToHundredErrors,
  ));
};

export default { validate };
