<template>
  <div v-if="!isNew" class="flex-grow" flat>
    <q-stepper
      ref="eraStages"
      v-model="era.submission_stage"
      vertical
      color="secondary"
      class="full-height bg-white"
      no-header-navigation
    >
      <q-step
        v-for="stage in stages"
        :key="stage.code"
        :name="stage.code"
        :title="getLabelFor(stage.code)"
      >
        <q-stepper-navigation>
          <div class="block group">
            <q-btn
              v-for="previous in stage.previousStages"
              :key="previous"
              color="mq-soft-muted"
              text-color="white"
              class="q-mt-lg"
              :label="getLabelFor(previous)"
              @click="transitionTo(previous)"
            />
            <q-btn
              v-for="next in stage.nextStages"
              :key="next"
              color="primary"
              class="block q-mt-lg"
              :label="getLabelFor(next)"
              @click="transitionTo(next)"
            />
          </div>
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import Era from '@/models/era/era';
import eraSubmissionStages from '@/models/era/era-submission-stages.json';

export default {
  name: 'EraStageStepper',
  data() {
    return {
      options: [],
      stages: eraSubmissionStages,
    };
  },
  computed: {
    ...mapState('eras', {
      era: state => state.era,
    }),
    isNew() {
      return !_.toInteger(this.era.id);
    },
  },
  methods: {
    ...mapActions('eras', ['saveSubmissionStage']),
    getLabelFor(stageCode) {
      return Era.getStageText(stageCode);
    },
    async transitionTo(stage) {
      try {
        await this.saveSubmissionStage({
          era: this.era,
          stage,
        });
        this.$notify.success(`Transitioned successfully to ${_.startCase(stage)}`);
      } catch (e) {
        this.$notify.failure(e);
      }
    },
  },
};
</script>
