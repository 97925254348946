import DataQualityDefinition from '@/models/data-quality/data-quality-definition';

class DataQuality {
  constructor(val) {
    const dq = val || {};
    this.identifier = dq.identifier;
    this.eraCategory = dq.eraCategory || dq.era_category;
    this.title = dq.title;
    this.definition = new DataQualityDefinition(dq.definition);
    this.radarId = dq.radarId || dq.radar_id;
    this.pureUrl = dq.pureUrl || dq.pure_url;
  }

  static fromList(data = []) {
    return data.map(dq => new DataQuality(dq));
  }
}

export default DataQuality;
