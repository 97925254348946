<template>
  <div>
    <forc-citation-count-comparison
      :comparison="comparison"
      class="q-pb-sm"
      :class="{'border-double border-bottom':showDetails}"
    />
    <div v-if="showDetails" :key="comparison.forc_id" class="q-pt-sm">
      <div class="row items-end justify-around q-py-sm">
        <div class="body-4 text-mq-soft inline-block col-1">Before</div>
        <simple-citations-overview-chart
          :key="'before-'+comparison.forc_id"
          :class-distribution="comparison.before_class_distribution"
          class="q-px-lg col-11"
        />
      </div>
      <div :key="comparison.forc_id" class="row items-end justify-around col-1 q-py-sm">
        <div class="body-4 text-mq-soft inline-block">After</div>
        <simple-citations-overview-chart
          :key="'after-'+comparison.forc_id"
          :class-distribution="comparison.after_class_distribution"
          class="q-px-lg col-11"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ForcCitationPerformanceComparison from '@/models/analytics/forc-citation-performance-comparison';
import SimpleCitationsOverviewChart from '../../charts/SimpleCitationsOverviewChart.vue';
import ForcCitationCountComparison from './ForcCitationCountComparison.vue';

export default {
  name: 'ForcCitationPerformanceComparison',
  components: { SimpleCitationsOverviewChart, ForcCitationCountComparison },
  props: {
    comparison: {
      type: ForcCitationPerformanceComparison,
      required: true,
    },
    forcs: {
      type: Array,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    forc() {
      return this.forcs.find(forc => forc.id === this.comparison.forc_id);
    },
    forcText() {
      return _.get(this.forc, 'text');
    },
  },
};
</script>
