<template>
  <q-page class="bg-white">
    <portal to="app-header">
      <div class="page-title">
        User
      </div>
    </portal>
    <div class="q-pa-lg bg-white row col-grow justify-center">
      <div class="col-xs-12 col-lg-11 row">
        <basic-user-info :user-id="userId" class="col-xs-12 col-md-6" />
        <user-access
          v-if="userId"
          :user-id="userId"
          class="col-xs-12 col-md-6"
        />
        <div class="headline-title q-mt-xl q-mb-md">
          User's access details
        </div>
        <user-access-list v-if="userId" :user-id="userId" class="col-12" />
      </div>
    </div>
  </q-page>
</template>

<script>
import BasicUserInfo from './BasicUserInfo.vue';
import UserAccess from './UserAccess.vue';
import UserAccessList from './UserAccessList.vue';

export default {
  name: 'UserProfile',
  components: {
    BasicUserInfo,
    UserAccessList,
    UserAccess,
  },
  props: {
    userId: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>
