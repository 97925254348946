import { setScrollPosition } from 'quasar-framework/src/utils/scroll';

export const scrollToElement = (vueOrDomElement, scrollWindowToTop = false) => {
  const domElement = vueOrDomElement.$el
    ? vueOrDomElement.$el
    : vueOrDomElement;
  domElement.scrollTop = 0;

  if (scrollWindowToTop) {
    setScrollPosition(window, 0);
  }
};

export default { scrollToElement };
