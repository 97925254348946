<template>
  <div>
    <div v-if="isFinalised" class="col-6 q-mt-lg">
      <div class="bg-white q-pa-lg round-borders border border-mq-magenta flex items-center">
        <q-icon name="warning" color="negative" size="1.5rem" class="q-mr-md" />
        <span>Note: The Portfolio Research Statement is no longer editable because the portfolio has been finalised.</span>
      </div>
    </div>

    <research-statement-component
      :research-statement="researchStatement"
      :disable="disable"
    />
  </div>
</template>

<script>
import ResearchStatementComponent from '@/components/common/ResearchStatementComponent.vue';
import ResearchStatement from '@/models/research-output/research-statement';

export default {
  name: 'PortfolioResearchStatement',
  components: {
    ResearchStatementComponent,
  },
  props: {
    researchStatement: {
      type: ResearchStatement,
      default: () => new ResearchStatement(),
    },
    disable: {
      type: Boolean,
      default: false,
    },
    isFinalised: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
