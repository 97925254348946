import { CHECK_TYPES } from '@/models/xml/xml-schema-validation-types';

const CHECK_DETAILS = new Map([
  [CHECK_TYPES.ResearchOutput, { color: 'blue', icon: 'article', iconClass: 'material-icons-outlined' }],
  [CHECK_TYPES.Researcher, { color: 'teal', icon: 'school', iconClass: null }],
  [CHECK_TYPES.AppliedMeasure, { color: 'orange', icon: 'straighten', iconClass: null }],
  [CHECK_TYPES.Income, { color: 'indigo', icon: 'monetization_on', iconClass: null }],
]);

export class XmlSchemaValidationStats {
  constructor(checkType = null, stats = {}) {
    this.stats = stats;
    this.checkType = checkType;

    const details = CHECK_DETAILS.get(checkType);
    if (details) {
      this.icon = details.icon;
      this.iconClass = details.iconClass;
      this.color = details.color;
    }
  }

  static toArray(val) {
    return Object.entries(val || {})
      .map(([key, stats]) => new XmlSchemaValidationStats(key, stats));
  }
}

export default XmlSchemaValidationStats;
