const AllFieldsExpandableTable = {
  data() {
    return {
      expandableTableExpansionKey: 'id',
      expansionSlots: {},
      allExpandMode: false,
    };
  },
  computed: {},
  methods: {
    setAllExpandedSlots(isExpanded) {
      this.allExpandMode = isExpanded;
      this.expansionSlots = this.expansionSlotDataItems.reduce((slots, row) => {
        const key = row[this.expandableTableExpansionKey];
        // eslint-disable-next-line no-param-reassign
        slots[key] = isExpanded;
        return slots;
      }, {});
    },
    isExpanded(key) {
      return this.expansionSlots[key];
    },
    toggleExpanded(key) {
      this.expansionSlots[key] = !this.expansionSlots[key];
    },
    expand(key) {
      this.expansionSlots[key] = true;
    },
    resetExpansion() {
      this.setAllExpandedSlots(this.allExpandMode);
    },
  },
};

export default AllFieldsExpandableTable;
