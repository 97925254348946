export const CHECK_TYPES = {
  AppliedMeasure: 'AppliedMeasure',
  Income: 'Income',
  Researcher: 'Researcher',
  ResearchOutput: 'ResearchOutput',
};

export const CHECK_TYPE_NAMES = {
  [CHECK_TYPES.AppliedMeasure]: 'Applied Measure',
  [CHECK_TYPES.Income]: 'Income',
  [CHECK_TYPES.Researcher]: 'Researcher',
  [CHECK_TYPES.ResearchOutput]: 'Research Output',
};
