import { MANAGE_RESEARCH_INCOME_FILE } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import ResearchIncomeOverview from '@/components/research-income/ResearchIncomeOverview.vue';
import ResearchIncomeList from '@/components/research-income/ResearchIncomeList.vue';
import ResearchIncomeUpload from '@/components/research-income/ResearchIncomeUpload.vue';
import ResearchIncomeDownload from '@/components/research-income/ResearchIncomeDownload.vue';

const researchIncomeRoutes = {
  path: 'research-income',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'research-income',
      component: ResearchIncomeOverview,
      meta: {
        activeRoute: 'research-income',
      },
      props: true,
      children: [
        {
          path: 'code',
          name: 'researchIncomeCode',
          component: ResearchIncomeList,
          meta: {
            activeRoute: 'research-income',
          },
        }, {
          path: 'research-income-upload',
          name: 'researchIncomeUpload',
          component: ResearchIncomeUpload,
          meta: {
            auth: [MANAGE_RESEARCH_INCOME_FILE],
            activeRoute: 'research-income',
          },
        },
        {
          path: 'research-income-download',
          name: 'researchIncomeDownload',
          component: ResearchIncomeDownload,
          meta: {
            auth: [MANAGE_RESEARCH_INCOME_FILE],
            activeRoute: 'research-income',
          },
        },
      ],
    },
  ],
};

export default researchIncomeRoutes;
