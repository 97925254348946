import config from '@/config';

class ReferenceMeta {
  static FORC_TYPE = 'forc';

  static JOURNAL_TYPE = 'arc_journal';

  static CITATION_BENCHMARK = 'citation_benchmark';

  constructor(referenceMeta = {}) {
    this.id = referenceMeta.id;
    this.reference_type = referenceMeta.reference_type;
    this.year = referenceMeta.year;
  }

  static fromList(referenceMetas = []) {
    return referenceMetas
      .map(referenceMeta => new ReferenceMeta(referenceMeta));
  }

  static anzsrcMappingUrl() {
    return config.referenceMeta.anzsrcMappingUrl;
  }

  isForcType() {
    return this.reference_type === ReferenceMeta.FORC_TYPE;
  }

  isJournalType() {
    return this.reference_type === ReferenceMeta.JOURNAL_TYPE;
  }

  isCitationBenchmarkType() {
    return this.reference_type === ReferenceMeta.CITATION_BENCHMARK;
  }
}

export default ReferenceMeta;
