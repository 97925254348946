<template>
  <div class="forc-container">
    <q-select
      v-bind="$attrs"
      :value="forcSelectedValue"
      :options="forcOptions"
      :disable="disable"
      dense
      :inverted="inverted"
      autofocus-filter
      filter-placeholder="Enter a FoRC"
      :filter="forcStartWithOrDescriptionContainsFilter"
      :color="color"
      :display-value="displayValue"
      class="inline forc-selector"
      :class="classes"
      :placeholder="placeholder"
      :title="forc && forc.description"
      v-on="$listeners"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <q-tooltip v-show="forc && forc.description">
      {{ forc && forc.description }}
    </q-tooltip>
  </div>
</template>

<script>
import _ from 'lodash';
import Forc from '@/models/era/forc';
import { forcStartWithOrDescriptionContainsFilter } from '@/utils/search';

export default {
  name: 'ForcSelector',
  inheritAttrs: false,
  model: {
    prop: 'forc',
    event: 'change',
  },
  props: {
    forc: {
      type: Forc,
      default: null,
    },
    forcs: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'FoRC',
    },
    classes: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    forcDescriptionLength: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    forcOptions() {
      return this.forcs.map(forc => ({
        label: `${forc.text} ${forc.description}`,
        sublabel: forc.description,
        value: forc,
      }));
    },
    forcSelectedValue() {
      if (!this.forc) return null;
      return this.forcs.find(forc => forc.isSameAs(this.forc));
    },
    displayValue() {
      if (!this.forc) return undefined;
      return `${this.forc.text} ${this.truncateDescription}`;
    },
    truncateDescription() {
      return this.forcDescriptionLength > 0 ? _.truncate(
        this.forc.description,
        { length: this.forcDescriptionLength, separator: ' ' },
      )
        : '';
    },
  },
  methods: {
    forcStartWithOrDescriptionContainsFilter,
  },
};
</script>

<style lang="stylus" scoped>
  .forc-container
    display inline-flex
    min-width 4rem

  .forc-selector
    flex-basis 100%
</style>
