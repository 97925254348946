import _ from 'lodash';
import ResearcherClaimVersion from './researcher-claim-version';
import ResearcherPosition from './researcher-position';
import ResearcherStatus from './researcher-status';
import ResearcherStatistics from './researcher-statistics';

class Researcher {
  constructor(data) {
    const researcher = data || {};
    this.id = researcher.id;
    this.external_id = researcher.external_id;
    this.title = researcher.title;
    this.full_name = researcher.full_name;
    this.preferred_name = researcher.preferred_name;
    this.given_name = researcher.given_name;
    this.family_name = researcher.family_name;
    this.gender = researcher.gender;
    this.orcid = researcher.orcid;
    this.researcher_positions = ResearcherPosition.fromList(researcher.positions);
    this.researcher_claim_version = researcher.researcher_claim_version
      && new ResearcherClaimVersion(researcher.researcher_claim_version);
    this.previous_researcher_claim_version = researcher.previous_researcher_claim_version
      && new ResearcherClaimVersion(researcher.previous_researcher_claim_version);
    this.status = new ResearcherStatus(researcher.er_status);
    this.statistics = new ResearcherStatistics(researcher.statistics);
    this.is_indigenous = researcher.is_indigenous;
  }

  static fromList(researchers = []) {
    return researchers
      .map(researcher => new Researcher(researcher));
  }

  getWorkFunction() {
    if (this.isHonorary()) return 'Honorary';
    if (this.isInternal()) return 'Internal';
    return 'External';
  }

  isInternal() {
    return !_.isEmpty(this.getCurrentPositions());
  }

  isHonorary() {
    return this.getCurrentPositions().every(p => p.isHonorary());
  }

  getAllOneIds() {
    return _.uniq(this.researcher_positions.map(p => p.one_id));
  }

  getCurrentPositions() {
    return this.researcher_positions.filter(position => position.is_current);
  }

  displayName() {
    const preferredName = this.preferred_name ? `(${this.preferred_name})` : '';
    return `${(this.title)} ${(this.full_name)} ${preferredName}`.toLowerCase();
  }

  isIndigenousResearcher() {
    return this.is_indigenous;
  }
}

export default Researcher;
