import Forc from '@/models/era/forc';

export class DisputeSlot {
  constructor(slot = {}) {
    this.id = slot.id;
    this.escalated_by = slot.escalated_by;
    this.approved_by = slot.approved_by;
    this.is_archived = slot.is_archived;
    this.slot_type = slot.slot_type;
    this.forc = new Forc(slot.forc);
    this.updated_at = new Date(slot.updated_at);
  }

  static fromList(disputeSlots = []) {
    return disputeSlots.map(slot => new DisputeSlot(slot));
  }

  isResolved() {
    return !!this.approved_by;
  }
}

export default DisputeSlot;
