<template>
  <div>
    <div class="r-heading-3">Override System Eligibility</div>
    <q-list link class="no-border">
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="automatic" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Automatic eligibility</q-item-tile>
          <q-item-tile sublabel>Allow system to determine eligibility</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="eligible" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Force Eligible</q-item-tile>
          <q-item-tile sublabel>Override and make eligible</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="ineligible" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Force Ineligible</q-item-tile>
          <q-item-tile sublabel>Override and make ineligible</q-item-tile>
        </q-item-main>
      </q-item>
    </q-list>
    <q-dialog v-model="showDialog" @cancel="cancel" @ok="save" @show="setFocus">
      <span slot="title">{{ dialogTitle }}</span>
      <span slot="message">{{ dialogQuestion }}</span>
      <div v-if="!isAutomaticSelected" slot="body" class="dialog-body">
        <q-icon name="subject" size="1.5rem" class="q-mt-md q-mr-md" />
        <q-field class="col-grow">
          <q-input
            ref="comment"
            v-model.trim="comment"
            name="comment"
            type="textarea"
            class="bg-mq-neutral-light comfortable-textarea"
            hide-underline
            placeholder="Please provide a reason for the change"
            maxlength="1000"
          />
        </q-field>
      </div>
      <template slot="buttons" slot-scope="props">
        <q-btn flat label="Cancel" @click="props.cancel" />
        <q-btn flat label="Ok" :disable="!isValid" @click="props.ok">
          <q-tooltip v-if="!isValid">You must enter a reason before you can save</q-tooltip>
        </q-btn>
      </template>
    </q-dialog>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ResearchOutputEligibility',
  props: {
    eligibilityStatus: {
      type: String,
      default: 'automatic',
    },
  },
  data() {
    return {
      eligibilityOption: this.eligibilityStatus,
      comment: '',
      showDialog: false,
    };
  },
  computed: {
    isAutomaticSelected() {
      return this.eligibilityOption === 'automatic';
    },
    dialogTitle() {
      return this.isAutomaticSelected
        ? 'Automatic eligibility'
        : `Forced ${_.capitalize(this.eligibilityOption)}`;
    },
    dialogQuestion() {
      return this.isAutomaticSelected
        ? 'Are you sure you want to make this Research Output\'s eligibility automatic?'
        : `Are you sure you want to override and make this Research Output ${this.eligibilityOption}?`;
    },
    isValid() {
      return this.isAutomaticSelected || !!this.comment;
    },
  },
  methods: {
    cancel() {
      this.eligibilityOption = this.eligibilityStatus;
      this.comment = '';
      this.showDialog = false;
    },
    save() {
      const eligibilityStatus = { status: this.eligibilityOption, comment: this.comment };
      this.$emit('save', eligibilityStatus);
      this.comment = '';
    },
    setFocus() {
      if (!this.isAutomaticSelected) {
        this.$refs.comment.focus();
      }
    },
  },
  watch: {
    eligibilityOption(val) {
      if (val !== this.eligibilityStatus) {
        this.showDialog = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.option-item
  padding 15px

  .q-item-label
    font-size 16px

  .q-item-sublabel
    font-size 12px
    padding-top 2px

.dialog-body
  display flex
  align-items flex-start
</style>
