import _ from 'lodash';

import CitationBenchmarkForc from './citation-benchmark-forc';

class CitationBenchmarks {
  constructor(benchmarks = {}) {
    this.id = benchmarks.id;
    this.source = benchmarks.source;
    this.benchmark_forcs = CitationBenchmarkForc
      .fromForcToYearlyCitationBenchmarkMapping(benchmarks.benchmark_forcs);
  }

  getYearsSorted() {
    const forcBenchmark = _.first(this.benchmark_forcs);
    if (!forcBenchmark) return [];
    return forcBenchmark.getYearsSorted();
  }

  getBenchmarkForForcAndYear(forc, year) {
    const benchmarkForc = this.benchmark_forcs.find(benchmark => forc.fourDigitForcId === benchmark.forc_id);
    if (!benchmarkForc) return 0;
    return benchmarkForc.getRciFor(year);
  }
}

export default CitationBenchmarks;
