<template>
  <form novalidate @submit.prevent="addClaim">
    <claim-component
      :claim="claim"
      :forcs="forcs"
      :name="name"
      :min-apportion="minApportion"
      :disable="disable"
    >
      <q-btn
        slot="suffix"
        type="submit"
        label="Add"
        color="secondary"
        :disable="disable"
      />
    </claim-component>
  </form>
</template>

<script>
import ResearcherClaim from '@/models/researcher/researcher-claim';
import ClaimComponent from '@/components/researcher/common/ResearcherClaim.vue';

export default {
  name: 'ClaimInput',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ClaimComponent,
  },
  props: {
    forcs: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'claim-input',
    },
    minApportion: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      claim: new ResearcherClaim(),
    };
  },
  computed: {
    errors() {
      return this.$validator.errors;
    },
  },
  methods: {
    reset() {
      this.$validator.reset();
      this.claim = new ResearcherClaim();
    },
    async addClaim() {
      await this.$validator.validateAll();
      if (this.errors.any()) return;
      this.$emit('add', this.claim);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .apportion-entry
    min-width 4rem

</style>
