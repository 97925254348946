import _ from 'lodash';
import api from '@/api';
import ClassDistribution from '@/models/analytics/class-distribution';
import ForcCitationClassDistribution from '@/models/analytics/forc-citation-class-distribution';
import DisciplinaryProfileCount from '@/models/reporting/disciplinary-profile-count';
import ResearchOutputByYear from '@/models/reporting/research-output-count';
import ResearchIncomeByYear from '@/models/reporting/research-income';

const DISTRIBUTION_CLASS_NAMES = ClassDistribution.classNames();
const ZERO_VALUE = Object.fromEntries(DISTRIBUTION_CLASS_NAMES.map(name => [name, 0]));

const ADD_CITATION = (sum, citation) => Object.fromEntries(DISTRIBUTION_CLASS_NAMES.map(name => [name, sum[name] + citation.class_distribution[name]]));

const PUBLISHING_PROFILES = [
  { key: 'journalArticles', tabLabel: 'Journal Articles', columnName: 'Journal Title' },
  { key: 'books', tabLabel: 'Books', columnName: 'Publisher Name' },
  { key: 'bookChapters', tabLabel: 'Book Chapters', columnName: 'Publisher Name' },
  { key: 'conferences', tabLabel: 'Conference Publications', columnName: 'Conference Title' },
];

export default {
  namespaced: true,
  state: {
    selectedForc: null,
    intraDisciplinaryProfileData: [],
    interDisciplinaryProfileData: [],
    researchOutputData: [],
    fteProfileData: [],
    citationData: [],
    publishingProfiles: PUBLISHING_PROFILES,
    publishingProfileData: {
      journalArticles: [],
      books: [],
      bookChapters: [],
      conferences: [],
    },
    researchIncomeData: [],
    fteBenchmarks: {},
    countryPatentsData: [],
    commercialIncomeData: [],
    processCount: 0,
  },
  mutations: {
    UPDATE_SELECTED_FORC(state, forc) {
      state.selectedForc = forc;
    },
    UPDATE_INTRA_DISCIPLINARY_PROFILE(state, data) {
      state.intraDisciplinaryProfileData = data;
    },
    UPDATE_INTER_DISCIPLINARY_PROFILE(state, data) {
      state.interDisciplinaryProfileData = data;
    },
    UPDATE_RESEARCH_OUTPUTS(state, data) {
      state.researchOutputData = data;
    },
    UPDATE_FTE_PROFILE(state, data) {
      state.fteProfileData = data;
    },
    UPDATE_CITATIONS(state, data) {
      state.citationData = data;
    },
    UPDATE_PUBLISHING_PROFILES(state, { key, data }) {
      const totalCount = data.reduce((sum, item) => sum + item.count, 0);
      let cumulativeSum = 0;

      state.publishingProfileData[key] = data.map((item) => {
        cumulativeSum += item.count;
        return {
          name: item.name || '< No name supplied >',
          count: item.count,
          percent: item.count / totalCount,
          cumulative: cumulativeSum / totalCount,
        };
      });
    },
    UPDATE_RESEARCH_INCOME(state, data) {
      state.researchIncomeData = data;
    },
    UPDATE_FTE_BENCHMARKS(state, data) {
      state.fteBenchmarks = data;
    },
    UPDATE_COUNTRY_PATENTS(state, data) {
      state.countryPatentsData = data;
    },
    UPDATE_COMMERCIALISATION_INCOME(state, data) {
      state.commercialIncomeData = data;
    },
    START_PROCESS(state) {
      // eslint-disable-next-line no-plusplus
      state.processCount++;
    },
    END_PROCESS(state) {
      if (state.processCount > 0) {
        // eslint-disable-next-line no-plusplus
        state.processCount--;
      }
    },
  },
  actions: {
    setSelectedForc({ commit }, forc) {
      commit('UPDATE_SELECTED_FORC', forc);
    },
    async getDisciplinaryProfile({ commit, rootGetters }, { mode, forcId, mutation }) {
      commit('START_PROCESS');
      const params = {
        mode,
        claim_forc_id: forcId,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        const response = await api.reporting.getDisciplinaryProfile(params);
        const data = _.sortBy(Object.values(response.data), 'weighted_count').reverse();
        commit(mutation, DisciplinaryProfileCount.fromList(data));
      } finally {
        commit('END_PROCESS');
      }
    },
    getIntraDisciplinaryProfile({ dispatch }, forcId) {
      dispatch('getDisciplinaryProfile', {
        mode: 'intra',
        forcId,
        mutation: 'UPDATE_INTRA_DISCIPLINARY_PROFILE',
      });
    },
    getInterDisciplinaryProfile({ dispatch }, forcId) {
      dispatch('getDisciplinaryProfile', {
        mode: 'inter',
        forcId,
        mutation: 'UPDATE_INTER_DISCIPLINARY_PROFILE',
      });
    },
    async getHerdcCount({ commit, rootGetters }, forcId) {
      commit('START_PROCESS');
      const params = {
        claim_forc_id: forcId,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        const response = await api.reporting.getHerdcCount(params);
        return commit(
          'UPDATE_RESEARCH_OUTPUTS',
          ResearchOutputByYear.fromList(response.data, rootGetters['eras/researchOutputYearRange']),
        );
      } finally {
        commit('END_PROCESS');
      }
    },
    async getFteProfile({ commit, rootGetters }, forcId) {
      const params = {
        claim_forc_id: forcId,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getFteProfile(params);
        commit('UPDATE_FTE_PROFILE', response.data);
      } finally {
        commit('END_PROCESS');
      }
    },
    async getRciClass({ commit, rootGetters }) {
      const forcs = rootGetters['referenceData/fourDigitForcsForWorkingEra'];
      try {
        commit('START_PROCESS');
        const response = await api.analytics.getForcCitations();
        const data = ForcCitationClassDistribution
          // Get list of ForcCitationClassDistribution instance matching remote citation data and list of known forcs
          .fromList(Object.entries(response.data), forcs)
          // Only use 4-digit code
          .filter(citation => !!citation.forc.four_digit_code);
        commit('UPDATE_CITATIONS', data);
      } finally {
        commit('END_PROCESS');
      }
    },
    async getPublishingProfile({ commit, rootGetters }, { forcId, outputType }) {
      const params = {
        claim_forc_id: forcId,
        research_output_type: outputType,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getPublishingProfile(params);
        return response.data;
      } finally {
        commit('END_PROCESS');
      }
    },
    async getPublishingProfiles({ commit, dispatch }, forcId) {
      const [books, chapters, journals, conferences] = await Promise.all([
        dispatch('getPublishingProfile', { forcId, outputType: 'A1' }),
        dispatch('getPublishingProfile', { forcId, outputType: 'B1' }),
        dispatch('getPublishingProfile', { forcId, outputType: 'C1' }),
        dispatch('getPublishingProfile', { forcId, outputType: 'E1' }),
      ]);
      commit('UPDATE_PUBLISHING_PROFILES', { key: 'books', data: books });
      commit('UPDATE_PUBLISHING_PROFILES', { key: 'bookChapters', data: chapters });
      commit('UPDATE_PUBLISHING_PROFILES', { key: 'journalArticles', data: journals });
      commit('UPDATE_PUBLISHING_PROFILES', { key: 'conferences', data: conferences });
    },
    async getResearchIncomeByYear({ commit, rootGetters }, forcId) {
      const params = {
        claim_forc_id: forcId,
        income_type: 'research',
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getIncome(params);
        const data = ResearchIncomeByYear.fromList(response.data);
        commit('UPDATE_RESEARCH_INCOME', data);
      } finally {
        commit('END_PROCESS');
      }
    },
    async getFteBenchmarks({ commit, rootGetters }, forcId) {
      const params = {
        claim_forc_id: forcId,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getFteBenchmarks(params);
        commit('UPDATE_FTE_BENCHMARKS', response.data);
      } finally {
        commit('END_PROCESS');
      }
    },
    async getCountryPatents({ commit, rootGetters }, forcId) {
      const params = {
        claim_forc_id: forcId,
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getCountryPatents(params);
        commit('UPDATE_COUNTRY_PATENTS', response.data);
      } finally {
        commit('END_PROCESS');
      }
    },
    async getCommercialisationIncome({ commit, rootGetters }, forcId) {
      const params = {
        claim_forc_id: forcId,
        income_type: 'commercial',
        era_id: rootGetters['eras/workingEra'].id,
      };
      try {
        commit('START_PROCESS');
        const response = await api.reporting.getIncome(params);
        commit('UPDATE_COMMERCIALISATION_INCOME', response.data.year_totals);
      } finally {
        commit('END_PROCESS');
      }
    },
  },
  getters: {
    roTotals: (state, getters, rootState, rootGetters) => state.researchOutputData
      .reduce((sum, val) => ({
        ...Object.fromEntries(rootGetters['eras/researchOutputYearRange'].map(year => [year, { count: sum[year].count + val.years[year].count }])),
        outputCount: sum.outputCount + val.outputCount,
        peerReviewCount: sum.peerReviewCount + val.peerReviewCount,
      }),
      {
        ...Object.fromEntries(rootGetters['eras/researchOutputYearRange'].map(year => [year, { count: 0 }])),
        outputCount: 0,
        peerReviewCount: 0,
      }),
    rciClassData: (state) => {
      const filteredData = state.citationData
        .filter(r => !state.selectedForc || r.forc.four_digit_code.startsWith(state.selectedForc.text))
        .reduce(ADD_CITATION, ZERO_VALUE);

      return ClassDistribution.rciClasses()
        .map(c => ({ class: c.code, range: c.legend, count: filteredData[c.name] }));
    },
    fteHeadcountTotal: state => state.fteProfileData.reduce((sum, item) => sum + item.headcount, 0),
    fteTotalCount: state => state.fteProfileData.reduce((sum, item) => sum + item.fte, 0),
    fteAtoETotal: state => state.fteProfileData
      .filter(r => r.herdc_level.startsWith('Level'))
      .reduce((sum, item) => sum + item.fte, 0),
  },
};
