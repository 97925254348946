<template>
  <div class="ro-stats__container">
    <template v-if="(typeof totalCount) !== 'undefined'">
      <router-link
        class="ro-stats__total"
        target="_blank"
        :tag="totalCount ? 'a' : 'div'"
        :to="roLink()"
      >
        <div :class="totalCount ? null : 'no-link'">
          <div>Total</div>
          <div>{{ totalCount }}</div>
        </div>
      </router-link>
      <div class="ro-stats__count">
        <span v-if="!totalCount" style="padding-left:15px; align-self: center">No research outputs</span>
        <router-link
          v-for="(count, category) in displayStats"
          :key="category"
          class="ro-stats__item"
          target="_blank"
          :to="roLink(category)"
        >
          <div>
            <div>{{ category }}</div>
            <div>{{ count }}</div>
          </div>
        </router-link>
      </div>
    </template>
    <div v-else class="ro-stats__count justify-center">
      <q-spinner-dots size="30" style="margin: 7px 0" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearcherPublications',
  props: {
    oneId: {
      type: String,
      default: null,
    },
    stats: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    displayStats() {
      return this.stats.eligible_herdc_counts;
    },
    totalCount() {
      return this.stats.eligible_research_output_count;
    },
  },
  methods: {
    roLink(category = undefined) {
      return {
        name: 'research-outputs',
        query: {
          authorNameContains: this.oneId,
          researchOutputTypes: category,
          eligibility: 'eligible',
        },
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
$stats-item
  color black
  text-decoration none

  & > div
    padding 6px 0
    border-radius 4px

    &:not(.no-link):hover
      background-color $indigo-2
      font-weight 700

    &.no-link:hover
      cursor not-allowed

    div:last-child
      padding-top 5px
      color $mq-magenta

.ro-stats
  &__container
    display flex
    flex-wrap wrap
    margin-top 10px
    border 1px solid rgba(0,0,0,.2)
    border-radius 6px
    font-size 12px
    text-align center
    text-transform uppercase
    overflow hidden

  &__total
    flex-basis 50px
    padding 4px
    @extend $stats-item

  &__count
    flex 1
    display flex
    flex-wrap wrap
    padding 4px
    background-color $mq-neutral

  &__item
    flex-basis 50px
    max-width 50px
    @extend $stats-item
</style>
