<template>
  <component :is="tag" class="flex">
    <template v-for="(author, index) in authors">
      <router-link
        v-if="author.isInternal()"
        :key="`author-${index}`"
        class="author-name text-no-wrap extra-info-col"
        :to="authorUrl(author)"
        target="_blank"
        v-text="author.researcher_name"
      />
      <span
        v-else
        :key="`author-${index}`"
        class="author-name text-no-wrap"
        v-text="author.researcher_name"
      />
    </template>
  </component>
</template>

<script>
export default {
  name: 'AuthorNamesRouterLink',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    authors: {
      type: Array,
      required: true,
    },
  },
  methods: {
    authorUrl(author) {
      return {
        name: 'researcher-details',
        params: {
          researcherId: author.id,
        },
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.author-name
  &::after
    content "; "
    white-space pre
</style>
