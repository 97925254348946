import _ from 'lodash';

class Validation {
  constructor() {
    this.validated = false;
    this.errors = [];
  }

  hasBeenValidated() {
    return this.validated;
  }

  hasErrors() {
    return this.errors.length > 0;
  }

  isValid() {
    return this.hasBeenValidated() && !this.errors.length;
  }

  isInvalid() {
    return this.hasBeenValidated() && !!this.errors.length;
  }

  clearErrors() {
    this.validated = true;
    this.errors = [];
  }

  addErrors(...errors) {
    this.validated = true;
    this.errors = _.compact(this.errors.concat(errors));
  }

  reset() {
    this.validated = false;
    this.errors = [];
  }
}

export default Validation;
