<template>
  <div>
    <div class="r-heading-3">Override System Eligibility</div>
    <q-list link class="no-border">
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="automatic" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Automatic eligibility</q-item-tile>
          <q-item-tile sublabel>Allow system to determine eligibility</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="eligible" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Force Eligible</q-item-tile>
          <q-item-tile sublabel>Override and make eligible</q-item-tile>
        </q-item-main>
      </q-item>
      <q-item tag="label" class="option-item">
        <q-item-side>
          <q-radio v-model="eligibilityOption" val="ineligible" />
        </q-item-side>
        <q-item-main>
          <q-item-tile label>Force Ineligible</q-item-tile>
          <q-item-tile sublabel>Override and make ineligible</q-item-tile>
        </q-item-main>
      </q-item>
    </q-list>
    <q-field class="q-mt-lg">
      <q-input
        v-model.trim="comment"
        name="comment"
        type="textarea"
        class="border bg-white comfortable-textarea"
        hide-underline
        placeholder="Please provide a reason for the change"
        maxlength="1000"
      />
    </q-field>
    <div class="q-mt-xl">
      <q-btn
        type="button"
        color="primary"
        @click="save()"
      >
        Save
      </q-btn>
      <q-btn
        class="q-ml-md"
        type="button"
        color="mq-sand"
        text-color="dark"
        @click="cancel()"
      >
        Cancel
      </q-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearcherEligibility',
  props: {
    eligibilityStatus: {
      type: String,
      default: 'automatic',
    },
  },
  data() {
    return {
      eligibilityOption: this.eligibilityStatus,
      comment: '',
    };
  },
  methods: {
    cancel() {
      this.eligibilityOption = this.eligibilityStatus;
      this.comment = '';
    },
    save() {
      const eligibilityStatus = { status: this.eligibilityOption, comment: this.comment };
      this.$emit('save', eligibilityStatus);
      this.comment = '';
    },
  },
};
</script>

<style lang="stylus" scoped>
.option-item
  padding 15px

  .q-item-label
    font-size 16px

  .q-item-sublabel
    font-size 12px
    padding-top 2px
</style>
