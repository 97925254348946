import moment from 'moment';

const radarDateDisplayFormatAbbrev = 'DD/MM/YYYY';
const radarDateDisplayFormat = 'DD MMMM YYYY';
const radarDateTimeDisplayFormat = 'DD/MM/YYYY HH:mm';

export const formatDateTime = (date) => {
  if (!date) return undefined;
  return moment(date).format(radarDateTimeDisplayFormat);
};

export const formatDate = (date) => {
  if (!date) return undefined;
  return moment(date).format(radarDateDisplayFormat);
};

export const formatDateAbbreviated = (date) => {
  if (!date) return undefined;
  return moment(date).format(radarDateDisplayFormatAbbrev);
};

export default { formatDate, formatDateTime, formatDateAbbreviated };
