<template>
  <div v-if="hasInterests">
    <div class="term-title"> {{ title }}</div>
    <div class="term-definition q-pt-sm">
      <q-chip
        v-for="forc in interests.forcs"
        :key="forc.id"
        dense
        color="primary"
      >
        {{ forc.text }}
        <q-tooltip>{{ forc.description }}</q-tooltip>
      </q-chip>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ResearchOutputInterest from '@/models/research-output/research-output-interest';

export default {
  name: 'ResearchOutputInterests',
  props: {
    title: {
      type: String,
      required: true,
    },
    interests: {
      type: ResearchOutputInterest,
      required: true,
    },
  },
  computed: {
    hasInterests() {
      return !_.isEmpty(this.interests.forcs);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .q-chip
    margin-right 1rem

  .q-collapsible
    .term-definition
      margin-bottom 24px

</style>
