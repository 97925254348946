import _ from 'lodash';
import Forc from '@/models/era/forc';

class ResearcherClaim {
  constructor(researcherClaim = {}) {
    this.forc = researcherClaim.forc && new Forc(researcherClaim.forc);
    this.apportion = researcherClaim.apportion;
    this.version = researcherClaim.version;
  }

  static fromList(researcherClaims = []) {
    return researcherClaims
      .map(researcherClaim => new ResearcherClaim(researcherClaim));
  }

  hasSameForcAs(claim) {
    return _.get(this, 'forc.id') === _.get(claim, 'forc.id');
  }

  basicDetails() {
    return {
      forc_id: this.forc.id,
      apportion: this.apportion,
    };
  }

  isSameAs(claim) {
    return this.hasSameForcAs(claim) && this.apportion === claim.apportion;
  }
}

export default ResearcherClaim;
