<template>
  <div>
    <ForcPeerReviewCountComparison
      :comparison="comparison"
      class="q-pb-sm"
      :class="{'border-double border-bottom':showDetails}"
    />
    <ForcPeerReviewClassDistributionComparison
      v-show="showDetails"
      :comparison="comparison"
      class="q-pt-md"
    />
  </div>
</template>

<script>
import ForcPeerReviewPerformanceComparison
  from '@/models/analytics/forc-peer-review-performance-comparison';
import ForcPeerReviewClassDistributionComparison
  from './ForcPeerReviewClassDistributionComparison.vue';
import ForcPeerReviewCountComparison from './ForcPeerReviewCountComparison.vue';

export default {
  name: 'ForcPeerReviewPerformanceComparison',
  components: { ForcPeerReviewClassDistributionComparison, ForcPeerReviewCountComparison },
  props: {
    comparison: {
      type: ForcPeerReviewPerformanceComparison,
      required: true,
    },
    forcs: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    forc() {
      return this.forcs.find(forc => forc.id === this.comparison.forc_id);
    },
    forcText() {
      return this.forc.text;
    },
  },
};
</script>
