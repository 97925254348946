<template>
  <q-page class="bg-tertiary column">
    <portal to="app-header">
      <div class="page-title">Researchers</div>
    </portal>
    <div class="row justify-center q-pa-lg col-grow">
      <div class="col-8 col-lg-9 col-xl-9 q-pr-xl">
        <div class="column full-height">
          <pagination-total-count
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            class="q-mb-sm border bg-white text-primary"
            @change="fetchPage"
          />
          <q-scroll-area ref="researcherScrollArea" class="researcher-list">
            <researchers-list
              :researchers="researchers"
              :departments="departments"
              :faculties="faculties"
            />
          </q-scroll-area>
          <pagination-total-count
            :current-page="currentPage"
            :page-size="pagination.pageSize"
            :total-results="pagination.totalResults"
            class="q-mt-sm border bg-white text-primary"
            @change="fetchPage"
          />
        </div>
      </div>
      <researchers-search
        ref="search"
        class="col-4 col-lg-3"
        :faculties="faculties"
        :departments="departments"
        :priority-list="priorityList"
        :work-functions="workFunctions"
        :forcs="forcsForWorkingEra"
        @search="updateQuery"
      />
    </div>
    <radar-loading :visible="progress.loading" />
  </q-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PaginationTotalCount from '@/components/common/PaginationTotalCount.vue';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ResearchersList from './ResearchersList.vue';
import ResearchersSearch from './ResearchersSearch.vue';

export default {
  name: 'ResearchersHome',
  components: {
    ResearchersList,
    ResearchersSearch,
    PaginationTotalCount,
    RadarLoading,
  },
  computed: {
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapState('researchers', ['researchers', 'pagination', 'priorityList', 'workFunctions', 'progress']),
    ...mapState('eras', ['workingEra']),
    ...mapGetters('referenceData', ['fourDigitForcByReferenceMetaId']),
    currentPage() {
      return this.pagination.currentPage;
    },
    totalPages() {
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
    forcsForWorkingEra() {
      return this.fourDigitForcByReferenceMetaId(this.workingEra.forc_reference_meta_id);
    },
  },
  methods: {
    ...mapActions('organisationEntities', ['getAllFaculties', 'getAllDepartments']),
    ...mapActions('researchers', ['searchResearchers']),

    updateQuery(searchParameters, page) {
      this.$router.push({ query: { ...searchParameters, page } });
    },
    fetchPage(page) {
      const query = { ...this.$route.query, page };
      this.$router.push({ query });
    },
    syncSearchParamsFromQueryString(query) {
      this.$refs.search.updateParameters(query);
    },
    async searchOnLoad(resetPage) {
      const { query } = this.$route;

      if (!query.eligibility) {
        query.eligibility = 'eligible';
      }
      const page = resetPage ? 1 : Number(query.page);

      this.syncSearchParamsFromQueryString(query);
      try {
        await this.searchResearchers({ ...query, page, eraId: Number(this.$route.params.eraId) });
        if (this.$refs.researcherScrollArea) {
          this.$refs.researcherScrollArea.setScrollPosition(0, 0.3);
        }
      } catch (err) {
        this.$notify.failure(err);
      }
    },
  },
  watch: {
    $route() {
      this.searchOnLoad();
    },
  },
  mounted() {
    this.searchOnLoad();
  },
};

</script>

<style lang="stylus" scoped>
  .researcher-list
    flex 1
</style>
