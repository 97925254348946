import ClaimVersion from '@/models/era/claim-version';
import ResearchStatement from '@/models/research-output/research-statement';
import _ from 'lodash';

export const PORTFOLIO_STATUS = {
  draft: 'DRAFT',
  finalised: 'FINALISED',
};

class Portfolio {
  constructor(portfolio) {
    const item = portfolio || {};
    this.id = item.id;
    this.name = item.name;
    this.roCount = item.roCount || item.research_output_count;
    this.eraSubmissionId = item.eraSubmissionId || item.era_submission_id;
    this.status = item.status;
    this.claimVersion = item.claimVersion || new ClaimVersion(item.claim_version);
    this.claimsHistory = item.claimsHistory || ClaimVersion.fromList(item.last_three_version_history);
    this.researchStatement = item.researchStatement || new ResearchStatement(item.research_statement);
  }

  static fromList(list = []) {
    return list.map(item => new Portfolio(item));
  }

  displayText() {
    switch (this.roCount) {
      case 1: return `${this.name} (1 RO)`;
      default: return `${this.name} (${this.roCount} RO's)`;
    }
  }

  hasPeerReviewedClaims() {
    return this.claimVersion.claims.filter(claim => claim.isPeerReviewed).length !== 0;
  }

  hasClaims() {
    return !_.isEmpty(this.claimVersion.claims);
  }

  getClaims() {
    return this.claimVersion.claims;
  }

  hasResearchStatement() {
    return this.researchStatement && this.researchStatement.hasStatement();
  }

  getResearchStatement() {
    return this.hasResearchStatement() ? this.researchStatement.getStatement() : undefined;
  }

  isResearchStatementRequired() {
    return this.hasPeerReviewedClaims() && !this.hasResearchStatement();
  }

  isFinalised() {
    return this.status === PORTFOLIO_STATUS.finalised;
  }
}

export default Portfolio;
