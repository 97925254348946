import _ from 'lodash';

export const hasDuplicates = (items, duplicateFunction = _.identity) => {
  const uniqueValues = _.uniqBy(items, duplicateFunction);
  return uniqueValues.length !== items.length;
};

export const valueAsArray = (item) => {
  if (_.isNil(item)) return [];
  if (_.isArray(item)) return item;
  return [item];
};

export default { hasDuplicates, valueAsArray };
