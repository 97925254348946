import users from './users';
import organisations from './organisation';
import researchers from './researchers';
import reference from './reference-data';
import eras from './eras';
import researchOutputs from './research-outputs';
import analytics from './analytics';
import whatIfs from './what-ifs';
import disputes from './disputes';
import appliedMeasures from './applied-measures';
import researchIncomes from './research-incomes';
import portfolios from './portfolios';
import dataQuality from './data-quality';
import xml from './xml';
import reporting from './reporting';
import searchQueries from './search-queries';
import radarSettings from './radar-settings';
import sensitivityTypes from './sensitivity-types';

export default {
  users,
  organisations,
  researchers,
  reference,
  eras,
  researchOutputs,
  analytics,
  whatIfs,
  disputes,
  appliedMeasures,
  researchIncomes,
  portfolios,
  dataQuality,
  xml,
  reporting,
  searchQueries,
  radarSettings,
  sensitivityTypes,
};
