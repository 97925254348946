<template>
  <div class="side-nav-section">
    <q-list-header class="term-title desktop-only">
      {{ heading }}
    </q-list-header>

    <side-nav-link
      v-for="link in links"
      :key="link.title"
      :mini="mini"
      v-bind="link"
    />
  </div>
</template>

<script>
import SideNavLink from '@/components/side-nav-bar/SideNavLink.vue';

export default {
  name: 'SideNavSection',
  components: {
    SideNavLink,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      default: () => [],
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus" scoped>
.side-nav-section
  & + &
    margin-top: 15px;

.term-title
  padding 15px 0 10px 14px
  color $mq-blood-red
  min-height 0;
  font-weight 700
</style>
