<template>
  <q-layout>
    <q-layout-drawer
      side="right"
      :mini="minimizeComparisons"
      :width="400"
      :mini-width="20"
      :overlay="false"
      behavior="desktop"
      :value="true"
      content-class="border-left border-mq-soft-muted bg-mq-soft-light overflow-visible"
    >
      <q-btn
        :key="minimizeComparisons"
        color="mq-soft-muted"
        round
        size="xs"
        class="drawer-toggle absolute-top-left z-top q-mt-lg"
        :icon="minimizeComparisons? 'keyboard_arrow_left':'keyboard_arrow_right'"
        @click="togglePerformanceComparisionVisibilityMode"
      />
      <what-if-forc-performance-comparisons
        key="what-if-forc-performance-comparisons"
        class="q-mini-drawer-hide"
      />
    </q-layout-drawer>
    <q-page-container>
      <q-page>
        <what-if-research-output-list
          @fetchPage="fetchPage"
          @result-type-change="fetchPage"
          @initiateSearch="isSearchDisplayed=true"
        />
        <radar-modal v-model="isSearchDisplayed" max-width="">
          <div slot="modal-definition">Search</div>
          <what-if-research-output-search
            ref="whatIfSearch"
            @searched="isSearchDisplayed=false"
          />
        </radar-modal>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notifyUnAuthorized } from '@/store/unauthorized-interceptor';
import RadarModal from '@/components/common/RadarModal.vue';
import WhatIfForcPerformanceComparisons
  from '../ForcPerformanceComparisons/WhatIfForcPerformanceComparisons.vue';
import WhatIfResearchOutputList from './WhatIfResearchOutputList.vue';
import WhatIfResearchOutputSearch from './WhatIfResearchOutputSearch.vue';

export default {
  name: 'WhatIfScenarioEditor',
  components: {
    WhatIfForcPerformanceComparisons,
    WhatIfResearchOutputList,
    RadarModal,
    WhatIfResearchOutputSearch,
  },
  props: {
    whatIfId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSearchDisplayed: false,
      minimizeComparisons: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['loggedInUser']),
    ...mapGetters('whatIfScenario', ['workingWhatIf']),
  },
  methods: {
    ...mapActions('whatIfScenario', ['fetchWhatIf', 'reset']),
    ...mapActions('analytics', ['fetchCitationBenchmarks']),
    togglePerformanceComparisionVisibilityMode() {
      this.minimizeComparisons = !this.minimizeComparisons;
    },
    showSearch() {
      this.isSearchDisplayed = true;
    },
    hasActiveFilters() {
      if (!this.$refs.roSearch) return false;
      return this.$refs.roSearch.hasActiveFilters();
    },
    fetchPage({ page, impactedOnly = false, clearSearch = false }) {
      this.search(page, impactedOnly, clearSearch);
    },
    search(page, impactedOnly, clearSearch) {
      this.$refs.whatIfSearch.search(page, impactedOnly, clearSearch);
    },
    async fetchWhatIfAndValidatePermission() {
      await this.reset();
      await this.fetchCitationBenchmarks();
      await this.fetchWhatIf(this.whatIfId);

      if (!this.workingWhatIf.isCreatedBy(this.loggedInUser)) {
        notifyUnAuthorized();
        this.$router.replace({ name: 'home' });
      }
    },
  },
  watch: {
    isSearchDisplayed(isVisible) {
      if (isVisible) {
        this.$refs.whatIfSearch.reSyncParametersFromQuery();
      }
    },
  },
  created() {
    this.fetchWhatIfAndValidatePermission();
  },
};
</script>
<style lang="stylus">
  .modal-search-wrapper
    min-width 741px
    min-height auto
    padding $radar-general-padding $radar-large-padding

  .q-layout-drawer
    overflow visible

  .drawer-toggle
    margin-left -12px
    overflow visible

</style>
