<template>
  <div>
    <div class="headline-title q-py-md">
      {{ title }}
    </div>
    <div class="row">
      <form
        class="generic-margin group col-xs-12 col-sm-10 col-md-6"
        @submit.prevent="createUser()"
      >
        <q-field
          :error="errors.has('mq-id')"
          label="MQ Id"
          error-label="MQ ID name is required"
        >
          <q-input
            v-model="user.username"
            v-validate="'required'"
            :disabled="!isNew"
            :readonly="!isNew"
            noautocomplete
            name="mq-id"
          />
        </q-field>
        <q-field label="First name">
          <div>{{ user.first_name }}</div>
        </q-field>
        <q-field label="Last name">
          <div>{{ user.last_name }}</div>
        </q-field>
        <q-field label="Email">
          <div>{{ user.email }}</div>
        </q-field>
        <q-field label="Last logged in at">
          <div v-if="user.last_login">
            {{ formatDateTime(user.last_login) }}
          </div>
          <div v-else>
            Never
          </div>
        </q-field>
        <template v-if="!readonly">
          <q-field label="Is active">
            <q-toggle v-model="user.is_active" />
          </q-field>
          <div class="block group">
            <q-btn
              type="submit"
              color="primary"
              class="q-mt-lg"
              label="Save"
            />
            <q-btn
              :to="{name: 'users'}"
              color="tertiary"
              text-color="dark"
              class="q-mt-lg"
              label="Cancel"
            />
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatDateTime } from '@/utils/date';

export default {
  name: 'BasicUserInfo',
  props: {
    title: {
      type: String,
      default: 'Basic information',
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('users', {
      user: state => state.user,
    }),
    errors() {
      return this.$validator.errors;
    },
    isNew() {
      return !this.userId || this.userId === 'new';
    },
  },
  methods: {
    ...mapActions('users', ['saveUser', 'getUser', 'resetUser']),
    formatDateTime,
    async saveUserDetails() {
      try {
        const isNewRecord = !this.user.id;
        await this.saveUser(this.user);
        this.$notify.success('Successfully Saved');
        if (isNewRecord) this.viewDetails();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async createUser() {
      await this.$validator.validateAll();
      if (!this.errors.any()) await this.saveUserDetails();
    },
    viewDetails() {
      this.$router.push({ name: 'user-details', params: { userId: this.user.id } });
    },
  },
  async created() {
    this.resetUser();
    if (!this.isNew) await this.getUser(this.userId);
  },
};
</script>
