import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import AppliedMeasureList from '@/components/applied-measure/AppliedMeasureList.vue';

const appliedMeasureRoutes = {
  path: 'applied-measures',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'applied-measures',
      component: AppliedMeasureList,
      meta: {
        activeRoute: 'applied-measures',
      },
    },
  ],
};

export default appliedMeasureRoutes;
