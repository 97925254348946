<template>
  <radar-modal v-model="isOpen">
    <div slot="modal-definition">Abstract</div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div slot="title" v-html="title" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="abstract" />
  </radar-modal>
</template>

<script>

import RadarModal from '@/components/common/RadarModal.vue';

export default {
  name: 'ResearchOutputAbstractModal',
  components: { RadarModal },
  props: {
    title: {
      type: String,
      default: null,
    },
    abstract: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle(to) {
      this.$emit('input', to);
    },
    open() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
  },
};
</script>
