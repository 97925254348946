<template>
  <q-dialog
    v-model="showModal"
    prevent-close
    @show="onShow"
  >
    <span slot="title">
      Unsaved changes
    </span>
    <span slot="message">
      Do you wish to save your changes before leaving this page?
      <br>
      Your changes will be lost if you don't save them.
    </span>
    <template slot="buttons" slot-scope="props">
      <q-btn
        ref="save"
        label="Save"
        color="primary"
        @click="choose(props.ok, 'save')"
      />
      <q-btn
        label="Don't Save"
        color="mq-sand-dark"
        text-color="dark"
        @click="choose(props.ok, 'dont-save')"
      />
      <div class="divider" />
      <q-btn
        label="Cancel"
        color="secondary"
        flat
        @click="props.cancel"
      />
    </template>
  </q-dialog>
</template>

<script>
export default {
  name: 'SaveDialog',
  props: {
  },
  data() {
    return {
      showModal: false,
      save: null,
      dontSave: null,
      hasUnsaved: null,
      next: null,
    };
  },
  methods: {
    async choose(okFn, action) {
      await okFn();
      if (action === 'save') {
        await this.save();
      } else {
        await this.dontSave();
      }
      if (typeof (this.next) === 'function' && !this.hasUnsaved()) {
        this.next();
      }
    },
    show({ save, dontSave, hasUnsaved, next }) {
      this.save = save;
      this.dontSave = dontSave;
      this.hasUnsaved = hasUnsaved;
      this.next = next;

      if (this.hasUnsaved()) {
        this.showModal = true;
      } else {
        this.next();
      }
    },
    onShow() {
      this.$refs.save.$el.focus();
    },
  },
};
</script>

<style lang="stylus" scoped>
.divider
  margin-left 20px
</style>
