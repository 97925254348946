<template>
  <div class="bg-white full-height q-pa-lg border border-mq-grey-dark">
    <div class="page-subtitle text-primary"> Search</div>

    <form class="field-text-mq-soft" @submit.prevent="search()">
      <q-input v-model.trim="searchParameters.name" float-label="Name/MQ Id" />
      <q-select
        v-model="searchParameters.facultyOrDepartmentName"
        :filter="labelContainsFilter"
        :options="facultyOrDepartmentOptions"
        clearable
        float-label="Faculty/Department"
      />
      <q-select
        v-model="searchParameters.priority"
        :options="priorityListOptions"
        clearable
        float-label="Priority List"
      />
      <q-select
        v-model="searchParameters.workFunction"
        :options="workFunctionOptions"
        float-label="Primary Work Function"
        multiple
        chips
      />
      <q-checkbox
        v-model="searchParameters.withNoForcOnly"
        class="q-if-label-above floating-label-font q-mt-lg"
        label="With no FoRCs only"
      /><br>
      <q-checkbox
        v-model="searchParameters.withoutAssignedPrimaryPosition"
        class="q-if-label-above floating-label-font q-mt-lg"
        label="With no Primary position/affiliation"
      /><br>
      <q-checkbox
        v-model="searchParameters.onlyIndigenousResearcher"
        class="q-if-label-above floating-label-font q-mt-lg"
        label="Indigenous Researcher"
      />
      <q-field label="Eligibility" class="q-mt-lg">
        <q-option-group
          v-model="searchParameters.eligibility"
          type="radio"
          class="q-if-label-above floating-label-font block"
          :options="eligibilityOptions"
        />
      </q-field>
      <q-field
        helper="Selecting multiple forcs searches for researchers with ALL of the FoRCs"
        no-underline
      >
        <q-select
          v-model="searchParameters.forcIds"
          :options="forcOptions"
          chips
          clearable
          multiple
          autofocus-filter
          :filter="labelStartWithOrSublabelContainsFilter"
          :disable="searchParameters.withNoForcOnly"
          float-label="FoRCs"
        />
      </q-field>
      <q-field>
        <q-select
          v-model="searchParameters.hasOrcId"
          :options="hasOrcIdOptions"
          float-label="Orc ID"
          clearable
        />
      </q-field>
      <div class="q-mt-xl row reverse">
        <q-btn type="submit" color="primary" label="Search" />
      </div>
    </form>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapState } from 'vuex';
import { labelContainsFilter, labelStartWithOrSublabelContainsFilter } from '@/utils/search';

const getDefaultSearchParameters = () => ({
  name: '',
  priority: null,
  workFunction: [],
  forcIds: [],
  facultyOrDepartmentName: null,
  withNoForcOnly: false,
  withoutAssignedPrimaryPosition: false,
  onlyIndigenousResearcher: false,
  eligibility: 'eligible',
  radarId: null,
  hasOrcId: null,
});

export default {
  name: 'ResearchersSearch',
  props: {
    departments: {
      type: Array,
      required: true,
    },
    faculties: {
      type: Array,
      required: true,
    },
    priorityList: {
      type: Array,
      default: () => [],
    },
    workFunctions: {
      type: Array,
      default: () => [],
    },
    forcs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchParameters: getDefaultSearchParameters(),
      hasOrcIdOptions: [
        { label: 'Researchers with Orc IDs', value: 'true' },
        { label: 'Researchers without Orc IDs', value: 'false' },
      ],
    };
  },
  computed: {
    ...mapState('researchers', ['eligibility']),
    priorityListOptions() {
      return this.priorityList.map(priority => ({ label: priority, value: priority }));
    },
    workFunctionOptions() {
      return this.workFunctions.map(workFunction => ({ label: workFunction, value: workFunction }));
    },
    forcOptions() {
      const sortedForcs = _.sortBy(this.forcs, forc => forc.text);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [...new Set([...this.departments, ...this.faculties].map(i => (i.name)))].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map(i => ({ label: i, value: i }));
    },
    eligibilityOptions() {
      const options = this.eligibility.map(eligibility => ({ label: eligibility.split(':')[1], value: eligibility.split(':')[0] }));
      return [...options, ...[{ label: 'All records', value: 'all' }]];
    },
  },
  methods: {
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    getNonEmptyValues(query) {
      return _.pickBy(query);
    },
    updateParameters(searchParams) {
      const forcIdsAsNumbers = _.concat([], searchParams.forcIds)
        .map(Number)
        .filter(_.isInteger);

      this.searchParameters = {
        ...getDefaultSearchParameters(),
        ...searchParams,
        facultyOrDepartmentName: searchParams.facultyOrDepartmentName ? searchParams.facultyOrDepartmentName : null,
        forcIds: forcIdsAsNumbers,
        withNoForcOnly: _.trim(searchParams.withNoForcOnly) === 'true',
        withoutAssignedPrimaryPosition: _.trim(searchParams.withoutAssignedPrimaryPosition) === 'true',
        hasOrcId: searchParams.hasOrcId ? _.trim(searchParams.hasOrcId) === 'true' : null,
        onlyIndigenousResearcher: _.trim(searchParams.onlyIndigenousResearcher) === 'true',
      };
    },
    fetchPage(page) {
      this.$emit('search', this.getNonEmptyValues({ ...this.searchParameters, page }));
    },
    search() {
      this.fetchPage(1);
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
