<template>
  <div class="q-px-lg q-pb-lg scrollable-section column no-wrap relative-position">
    <report-container title="Patents">
      <div slot="table">
        <report-table
          :data="countryPatentsData"
          :summary-row="countryPatentsSummary"
          :columns="patentCols"
          row-key="country"
        />
        <div class="q-ml-lg q-mt-sm">* Triadic patents count as 3 for total</div>
      </div>
    </report-container>

    <report-container title="Research Commercialisation Income by Year" class="q-mt-xl">
      <report-table
        slot="table"
        :data="commercialIncomeData"
        :summary-row="commercialIncomeSummary"
        :columns="incomeByYearCols"
        row-key="year"
      />
    </report-container>

    <report-container title="Research Commercialisation income by FTE" class="q-mt-xl">
      <report-table
        slot="table"
        :data="incomeByFte"
        :columns="incomeByFteCols"
        row-key="fteCategory"
      />
    </report-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { formatFloat, formatCurrency, div } from '@/utils/formatter';
import ReportContainer from '@/components/reporting/common/ReportContainer.vue';
import ReportTable from '@/components/reporting/common/ReportTable.vue';

export default {
  name: 'RptAppliedMeasures',
  components: {
    ReportContainer,
    ReportTable,
  },
  data() {
    return {
      patentCols: [
        { label: 'Country', field: 'country', align: 'left' },
        { label: 'Patents (apportioned)', field: 'weighted_count', format: formatFloat(2) },
        { label: 'Patents (whole counted)', field: 'count', format: formatFloat(0) },
      ],
      incomeByYearCols: [
        { label: 'Year', field: 'year', align: 'left' },
        { label: 'Total', field: 'total_apportioned', format: formatCurrency },
      ],
      incomeByFteCols: [
        { label: 'FTE category', field: 'fteCategory', align: 'left' },
        { label: 'FTE', field: 'fteCount', format: formatFloat(2) },
        { label: 'Total Amount', field: 'total', format: formatCurrency },
        { label: '$ per FTE', field: 'avgFte', format: formatCurrency },
      ],
    };
  },
  computed: {
    ...mapState('reporting', ['countryPatentsData', 'commercialIncomeData']),
    ...mapGetters('reporting', ['fteAtoETotal', 'fteTotalCount']),
    countryPatentsSummary() {
      // business rule: "Triadic (USA, Europe, Japan)" patents counts as 3 for total.
      const itemCount = (row, key) => (row.country.startsWith('Triadic') ? 3 : 1) * row[key];
      const total = this.countryPatentsData.reduce((sum, item) => ({
        weighted_count: sum.weighted_count + itemCount(item, 'weighted_count'),
        count: sum.count + itemCount(item, 'count'),
      }), { weighted_count: 0, count: 0 });
      return { country: 'Total Patents in Reference Period*', ...total };
    },
    commercialIncomeTotal() {
      return this.commercialIncomeData.reduce((sum, item) => sum + item.total_apportioned, 0);
    },
    commercialIncomeSummary() {
      return { year: 'Total for Period', total_apportioned: this.commercialIncomeTotal };
    },
    incomeByFte() {
      if (this.commercialIncomeData.length === 0) return [];
      return [
        {
          fteCategory: 'Level A-E only',
          fteCount: this.fteAtoETotal,
          total: this.commercialIncomeTotal,
          avgFte: div(this.commercialIncomeTotal, this.fteAtoETotal),
        },
        {
          fteCategory: 'All',
          fteCount: this.fteTotalCount,
          total: this.commercialIncomeTotal,
          avgFte: div(this.commercialIncomeTotal, this.fteTotalCount),
        },
      ];
    },
  },
};
</script>

<style lang="stylus" scoped>
</style>
