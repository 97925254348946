<template>
  <div class="full-width relative-position scrollable-section">
    <claims-history
      class="q-pa-lg"
      style="max-width: 800px"
      :claim-versions="portfolio.claimsHistory"
      :include-peer-review="true"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ClaimsHistory from '@/components/claim/ClaimsHistory.vue';

export default {
  name: 'PortfolioHistory',
  components: {
    ClaimsHistory,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('portfolios', ['portfolio', 'portfolios', 'progress']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('portfolios', ['portfolioOptions']),
  },
  methods: {
    ...mapActions('portfolios', ['addPortfolio', 'fetchPortfolio', 'fetchPortfolios']),

  },
};
</script>

<style lang="stylus" scoped>
</style>
