<template>
  <form class="row justify-between" @submit.prevent="updateSearchParams()">
    <div class="col text-mq-soft items-end field-groups">
      <div class="text-right absolute-top r-m-large-right r-m-top">
        <div class="r-m-top">
          <q-btn
            type="button"
            class="r-m-right"
            color="mq-soft"
            label="Clear"
            flat
            @click="clearSearchParams()"
          />
          <q-btn
            type="submit"
            color="primary"
            label="Search"
          />
        </div>
      </div>
      <q-field label="Type">
        <q-select
          v-model="searchParameters.checkType"
          :options="checkTypeOptions"
          autofocus-filter
          clearable
        />
      </q-field>
      <q-field label="Category">
        <q-select
          v-model="searchParameters.choiceType"
          :options="choiceTypeOptions"
          autofocus-filter
          clearable
        />
      </q-field>
      <q-field label="Business Rules">
        <q-select
          v-model="searchParameters.businessRules"
          :options="businessRuleOptions"
          multiple
          chips
          clearable
        />
      </q-field>
      <q-field label="Pure ID">
        <q-input v-model.trim="searchParameters.pureId" clearable />
      </q-field>
      <q-field label="Name/MQ Id">
        <q-input v-model.trim="searchParameters.authorNameContains" />
      </q-field>
      <q-field label="Faculty/Department">
        <q-select
          v-model="searchParameters.facultyOrDepartmentName"
          :filter="labelContainsFilter"
          :options="facultyOrDepartmentOptions"
          clearable
        />
      </q-field>
      <q-field label="ERA Category">
        <q-select
          v-model="searchParameters.eraCategory"
          :options="eraCategoryOptions"
          multiple
          chips
          clearable
        />
      </q-field>
      <q-field label="Title">
        <q-input v-model.trim="searchParameters.title" />
      </q-field>
      <q-field label="Year">
        <q-input
          v-model.trim="searchParameters.year"
          type="number"
        />
      </q-field>
      <q-field label="ISSN - ISBN">
        <q-input
          v-model.trim="searchParameters.issnOrIsbn"
        />
      </q-field>
      <q-field label="ARC Journal FoRCs" class="r-p-vertical">
        <div>
          <q-select
            v-model="searchParameters.journalForcId"
            :options="journalForcOptions"
            :disable="searchParameters.withMdJournalForc"
            autofocus-filter
            :filter="labelStartWithOrSublabelContainsFilter"
            clearable
          />
          <q-checkbox
            v-model="searchParameters.withMdJournalForc"
            class="q-if-label-above floating-label-font q-mt-lg"
            label="With MD ARC Journal FoRCs"
          />
        </div>
      </q-field>
      <q-field label="Claim FoRCs" class="r-p-vertical">
        <q-field
          :helper="searchParameters.withPeerReview
            ? 'Will search peer review tags associated with this claim only' : ''"
        >
          <q-select
            v-model="searchParameters.claimForcId"
            :options="claimForcOptions"
            :disable="searchParameters.withNoClaimForcApportions"
            autofocus-filter
            :filter="labelStartWithOrSublabelContainsFilter"
            clearable
          />
        </q-field>
        <q-checkbox
          v-model="searchParameters.withNoClaimForcApportions"
          label="With no Claim FoRC apportions"
          class="q-if-label-above floating-label-font q-mt-lg block"
          color="black"
        />
        <q-checkbox
          v-model="searchParameters.withPeerReview"
          label="Peer Review Tagged"
          class="q-if-label-above floating-label-font q-mt-lg block"
          color="black"
          :disable="searchParameters.withNoClaimForcApportions"
        />
      </q-field>
      <div class="text-right">
        <q-btn
          type="button"
          class="q-mr-lg"
          color="mq-soft"
          label="Clear"
          flat
          @click="clearSearchParams()"
        />
        <q-btn
          type="submit"
          color="primary"
          label="Search"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getNonEmptyValues, labelContainsFilter, labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import Forc from '@/models/era/forc';
import { CHECK_TYPE_NAMES, CHOICE_TYPES } from '@/models/data-quality/data-quality-types';
import DataQualitySearchParam from '@/models/data-quality/data-quality-search-param';

export default {
  name: 'DataQualitySearch',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    searchFilter: {
      type: DataQualitySearchParam,
      default: () => new DataQualitySearchParam(this.workingEra.id),
    },
  },
  data() {
    return {
      searchParameters: DataQualitySearchParam.create(this.searchFilter),
    };
  },
  computed: {
    ...mapState('researchOutputs', ['types']),
    ...mapState('organisationEntities', ['faculties', 'departments']),
    ...mapState('dataQuality', ['businessRules']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('referenceData', ['forcsForWorkingEra']),
    journalForcOptions() {
      const forcsForJournals = Forc.getTwoAndFourDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForJournals);
    },
    claimForcOptions() {
      const forcsForClaims = Forc.getFourAndSixDigitForcs(this.forcsForWorkingEra);
      return this.forcOptions(forcsForClaims);
    },
    checkTypeOptions() {
      return Object.entries(CHECK_TYPE_NAMES).map(([value, label]) => ({ label, value }));
    },
    choiceTypeOptions() {
      return Object.entries(CHOICE_TYPES).map(([value, label]) => ({ label, value }));
    },
    eraCategoryOptions() {
      return this.types.map(type => ({ label: type, value: type.split(':')[0] }));
    },
    businessRuleOptions() {
      return this.businessRules.map(rule => ({
        label: rule.code,
        sublabel: rule.description,
        value: rule.code,
      }));
    },
    facultyOrDepartmentOptions() {
      // Get unique + sorted "name"s from faculty and department list of objects
      const uniqueDepartmentFacultyNames = [ ...new Set([ ...this.departments, ...this.faculties ].map(i => (i.name))) ].sort();
      // Generate a list of objects to use in filter, in format of: {label: "Department Name", value: "Department Name"}
      return uniqueDepartmentFacultyNames.map( i => ({ label:i, value:i }) );
    },
  },
  methods: {
    ...mapActions('dataQuality', ['getBusinessRules']),
    labelContainsFilter,
    labelStartWithOrSublabelContainsFilter,
    forcOptions(forcs) {
      const sortedForcs = Forc.sortAsNumbers(forcs);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc.id,
      }));
    },
    facultyOrDepartmentChanged(entity) {
      this.searchParameters.facultyId = undefined;
      this.searchParameters.departmentId = undefined;
      if (entity.type === 'faculty') {
        this.searchParameters.facultyId = entity.id;
      }
      if (entity.type === 'department') {
        this.searchParameters.departmentId = entity.id;
      }
    },
    clearSearchParams() {
      this.searchParameters = new DataQualitySearchParam(this.workingEra.id);
    },
    updateSearchParams() {
      const params = getNonEmptyValues({ ...this.searchParameters, page: 1 });
      this.$emit('searched', params);
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.searchParameters = DataQualitySearchParam.create(this.searchFilter);
        }
      },
    },
  },
  async created() {
    if (this.businessRules.length === 0) {
      await this.getBusinessRules();
    }
  },
};
</script>

<style lang="stylus" scoped>
  .field-groups
    & > .q-field
      margin-top 20px
</style>
