<template>
  <div class="scrollable-section">
    <q-table
      class="portfolio-table"
      table-class="bg-white"
      :data="researchOutputs"
      :columns="columns"
      selection="multiple"
      :selected.sync="selected"
      :rows-per-page-options="[0]"
      :pagination.sync="pagination"
      row-key="id"
      binary-state-sort
      no-data-label="No data found"
    >
      <!-- Table header -->
      <template slot="header" slot-scope="props">
        <q-tr>
          <q-th
            v-for="col in props.cols.slice(0, 5)"
            :key="col.name"
            :props="props"
            :class="col.classes"
            rowspan="2"
          >
            <div class="header-top-section">
              <q-select
                v-if="col.name === 'portfolio'"
                v-model="bulkPortfolioId"
                class="portfolio-select border no-shadow"
                :color="props.selected ? 'primary' : 'white'"
                align="left"
                inverted
                :options="availableOptions"
                :disable="!props.selected"
                hide-underline
                placeholder="Bulk assign selected to a portfolio"
                @input="changeBulkMembership()"
              />
            </div>
            <template v-if="col.name === 'toggle'">
              <div>
                <q-checkbox
                  v-if="canUserManagePortfolio"
                  v-model="props.selected"
                  indeterminate-value="some"
                  size="sm"
                  @input="unselectFinalised()"
                />
              </div>
            </template>
            <div>{{ col.label }}</div>
          </q-th>
          <th colspan="3" class="col-claim text-center bg-mq-sand">Research Output</th>
        </q-tr>
        <q-tr>
          <q-th
            v-for="col in props.cols.slice(5)"
            :key="col.name"
            :props="props"
            :class="col.classes"
            class="bg-mq-sand"
          >
            <div>{{ col.label }}</div>
          </q-th>
        </q-tr>
      </template>
      <!-- Line detail -->
      <template slot="body" slot-scope="props">
        <q-tr :props="props" :class="{ finalised: isFinalised(props.row) }">
          <q-td>
            <div class="col-toggle">
              <q-checkbox
                v-if="canUserManagePortfolio"
                v-model="props.selected"
                size="sm"
                :readonly="isFinalised(props.row)"
                :disabled="isFinalised(props.row)"
              />
            </div>
          </q-td>
          <q-td>
            <div class="ro-container">
              <div class="ro-type">
                <div>{{ props.row.research_output_type }}</div>
                <q-icon
                  :name="roStatusIcon(props.row.ro_status)"
                  :class="roStatusClass(props.row.ro_status)"
                  color="white"
                  size="20px"
                  :title="roStatusText(props.row.ro_status)"
                />
              </div>
              <div class="ro-details wrappable-table-column">
                <div>
                  <router-link
                    class="ro-title text-mq-purple javascript-link"
                    :to="{name: 'research-outputs', query: {pureId: props.row.pure_id}}"
                    target="_blank"
                  >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span class="overflow-hidden wrap" v-html="props.row.effectiveTitle()" />
                  </router-link>
                </div>
                <div class="ro-creators">
                  <author-names-router-link
                    tag="div"
                    :authors="props.row.uniqueCreators()"
                  />
                </div>
                <div class="ro-extra-info">
                  <div>Year: {{ props.row.pub_year }}</div>
                  <div v-if="props.row.publisher_name">Publisher: {{ props.row.publisher_name }}</div>
                </div>
              </div>
            </div>
          </q-td>
          <q-td class="col-action">
            <div class="wrappable-table-column">
              <MQBylineLogo v-if="props.row.has_bylines" class="mq-byline" />
              <q-btn
                v-for="(pdfUrl,index) in props.row.electronic_locations"
                :key="index"
                type="a"
                :href="pdfUrl"
                target="_blank"
                class="icon-link no-padding"
                flat
                no-ripple
                dense
                icon="description"
                :title="getFileName(pdfUrl)"
              />
              <q-btn
                flat
                no-ripple
                dense
                class="icon-link no-padding"
                icon="notes"
                title="Abstract"
                @click="openAbstract(props.row)"
              />
              <q-btn
                v-if="props.row.isResearchStatementRequired()"
                flat
                no-ripple
                dense
                class="icon-link no-padding"
                :color="props.row.getResearchStatementStatus()"
                :title="props.row.getResearchStatementTitle()"
                @click="openResearchStatement(props.row)"
              >
                <q-icon name="sticky_note_2" class="material-icons-outlined" />
              </q-btn>
            </div>
          </q-td>
          <q-td>{{ props.row.pure_id }}</q-td>
          <q-td>
            <div v-if="isFinalised(props.row)" class="row items-center text-mq-blood-red">
              <span>{{ props.row.portfolio.displayText() }}</span>
              <q-icon name="lock" size="18px" class="q-ml-sm" />
            </div>
            <q-select
              v-else-if="canUserManagePortfolio"
              v-model="props.row.portfolio.id"
              class="term-title-condensed text-black q-py-none"
              :options="availableOptions"
              align="left"
              @input="changeMembership(props.row)"
            />
            <div v-else class="row items-center">
              <span>{{ props.row.portfolio.id && props.row.portfolio.displayText() }}</span>
            </div>
          </q-td>
          <q-td
            v-for="(claimClass, claimIndex) in ['col-claim', 'col-claim', 'col-claim']"
            :key="claimIndex"
            :class="[claimClass]"
          >
            <div class="claim-container">
              <template v-if="props.row.getActiveClaims()[claimIndex]">
                <div>{{ props.row.getActiveClaims()[claimIndex].forc.four_digit_code }}</div>
                <q-tooltip>{{ props.row.getActiveClaims()[claimIndex].forc.description }}</q-tooltip>
                <div>{{ props.row.getActiveClaims()[claimIndex].apportion }}%</div>
                <div v-if="props.row.getActiveClaims()[claimIndex].is_peer_reviewed">&#x2713;</div>
                <div v-else />
              </template>
              <template v-else>
                -
              </template>
            </div>
          </q-td>
        </q-tr>
      </template>
      <div slot="bottom" slot-scope="props" class="fit">
        <slot name="bottom" :props="props" />
      </div>
    </q-table>
    <radar-loading :visible="progress.searching || portfolioProgress.savingMembership || portfolioProgress.processing" />
    <research-output-abstract-modal
      ref="abstract"
      :title="abstractTitle"
      :abstract="abstract"
    />
    <research-statement-modal
      ref="statement"
      :research-output="researchOutput"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import ResearchOutputStatus from '@/models/research-output/research-output-status';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import AllFieldsExpandableTable from '@/mixins/AllRowsExpandableTable';
import RadarLoading from '@/components/common/RadarLoading.vue';
import AuthorNamesRouterLink from '@/components/common/AuthorNamesRouterLink.vue';
import ResearchOutputAbstractModal from '@/components/research-output/ResearchOutputAbstractModal.vue';
import MQBylineLogo from '@/components/common/MQBylineLogo.vue';
import ResearchStatementModal from './ResearchStatementModal.vue';

import { portfolioFields } from './portfolio-fields';

export default {
  name: 'PortfolioList',
  components: {
    RadarLoading,
    AuthorNamesRouterLink,
    ResearchOutputAbstractModal,
    ResearchStatementModal,
    MQBylineLogo,
  },
  mixins: [PermissionsMixin, AllFieldsExpandableTable],
  props: {
    portfolioOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: portfolioFields,
      pagination: {
        sortBy: 'title',
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      selectedEdit: null,
      bulkPortfolioId: null,
      selected: [],
      abstractTitle: undefined,
      abstract: undefined,
      researchOutput: undefined,
    };
  },
  computed: {
    ...mapGetters('eras', ['workingEra']),
    ...mapState('researchOutputs', ['researchOutputs', 'progress']),
    ...mapState('portfolios', ['portfolios', 'portfolioStatus']),
    ...mapState('portfolios', { portfolioProgress: state => state.progress }),
    availableOptions() {
      return [
        { label: 'Remove from portfolio', value: 0 },
        ...this.portfolioOptions.map(po => ({ ...po, ...{ disable: po.rightIcon, rightColor: 'light' } })),
      ];
    },
  },
  methods: {
    ...mapActions('portfolios', ['updatePortfolioMembership']),
    ...mapActions('researchStatements', ['fetchResearchStatement']),
    async fetchROResearchStatement(researchOutputId) {
      await this.fetchResearchStatement({
        researchOutputId,
        eraId: this.workingEra.id,
      });
    },
    roStatusIcon(status) {
      return ResearchOutputStatus.getIconFor(status);
    },
    roStatusClass(status) {
      return ResearchOutputStatus.getStatusValue(status);
    },
    roStatusText(status) {
      return ResearchOutputStatus.getStatusTextFor(status);
    },
    getFileName(str) {
      return str.split('/').pop().replace(/%20/g, ' ');
    },
    isFinalised(researchOutput) {
      return researchOutput.portfolio.status === this.portfolioStatus.finalised;
    },
    unselectFinalised() {
      const finalised = this.researchOutputs.filter(ro => this.isFinalised(ro));
      this.selected = _.difference(this.selected, finalised);
    },
    openAbstract(researchOutput) {
      this.abstractTitle = researchOutput.is_translated
        ? researchOutput.title_translated
        : researchOutput.title_nativescript;
      this.abstract = researchOutput.abstract;
      this.$refs.abstract.open();
    },
    async openResearchStatement(researchOutput) {
      this.researchOutput = researchOutput;
      try {
        await this.fetchROResearchStatement(researchOutput.id);
        this.$refs.statement.open();
      } catch (e) {
        this.$notify.failure(e);
      }
    },
    async changeMembership(row) {
      const ro = row;
      const portfolioId = ro.portfolio.id || null;
      const param = [{ roId: ro.id, portfolioId }];
      try {
        await this.updatePortfolioMembership(param);
      } catch (e) {
        this.$notify.failure(e);
      } finally {
        this.$emit('change'); // always update so it will replace user input with what is on the server.
      }
    },
    async confirmBulkMembership(portfolioName) {
      const roCountDescription = this.selected.length > 1
        ? `these ${this.selected.length} Research Outputs`
        : 'this Research Output';
      const bulkAssignMessage = portfolioName
        ? `Are you sure you want to assign ${roCountDescription} to ${portfolioName}?`
        : `Are you sure you want to unassign ${roCountDescription}?`;
      try {
        await this.$q.dialog({
          title: 'Assign multiple Research Outputs',
          message: bulkAssignMessage,
          ok: 'Yes',
          cancel: 'No',
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async changeBulkMembership() {
      const portfolioName = this.bulkPortfolioId > 0
        ? _.find(this.portfolios, { id: this.bulkPortfolioId }).name
        : '';
      const confirm = await this.confirmBulkMembership(portfolioName);
      if (confirm) {
        try {
          const portfolioId = this.bulkPortfolioId || null;
          const param = this.selected.map(ro => ({ roId: ro.id, portfolioId }));
          await this.updatePortfolioMembership(param);
          const plural = this.selected.length > 1 ? 's' : '';
          const successMsg = portfolioName
            ? `${this.selected.length} Research Output${plural} assigned to ${portfolioName}`
            : `${this.selected.length} Research Output${plural} unassigned`;
          this.$notify.success(successMsg);
          this.selected = [];
          this.$emit('change');
        } catch (e) {
          this.$notify.failure(e);
        }
      }
      this.bulkPortfolioId = null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.portfolio-select
  height 30px
  font-size 11px
  font-weight 400
  text-transform none
  letter-spacing 0

.header-top-section
  height 40px

.col-toggle
  width 30px
  text-align right

.col-action
  .wrappable-table-column
    min-width 70px
    max-width 115px
    margin-top -8px

.col-pure-id
  width 100px

.col-portfolio
  min-width 300px

.col-claim
  min-width 150px
  background-color transparentify($mq-sand, #fff, .1)

.claim-container
  display flex
  justify-content center
  max-width 110px
  margin 0 auto
  text-align right

  div:nth-of-type(2)
    flex-grow 1

  div:last-of-type
    min-width 20px

.ro-container
  display flex
  min-width 190px

.ro-type
  min-width 20px
  text-align center
  margin-right 10px
  line-height 1.5

.finalised
  background-color rgba(190, 120, 120, 0.1) !important

.valid
  background-color #659d4e

.under
  background-color #066ba0

.over
  background-color #802243

.claim_count_error
  background-color $primary

.no_claims
  background-color #a79c78

.portfolio_member
  background-color #606a8b

.ineligible
  background-color #4e4e4e

.clawback_invalid
    background-color #cd4646

.ro-title
  text-align justify

a:link
  &.javascript-link
    color: $dark
    text-decoration none

    &:hover
      text-decoration underline

a:visited
  color $blue

  &:hover
    text-decoration underline

</style>
