class FundingYear {
  constructor(grantYear = {}) {
    this.id = grantYear.id;
    this.total = grantYear.total;
    this.year = grantYear.year;
    this.income_item = grantYear.income_item;
  }

  static fromList(grantYears = []) {
    return grantYears.map(year => new FundingYear(year));
  }
}

export default FundingYear;
