export const getColumnByName = (columns, columnName) => (columns.find(col => col.name === columnName));

export const tableForcFieldSearchStartWithCodeOrContainsDescription = (rows, filterTerm, columns, cellValue, columnName) => {
  const forcCol = getColumnByName(columns, columnName);
  const sanitizedFilterTerm = filterTerm.trim().toLowerCase();
  return rows.filter((row) => {
    const forc = cellValue(forcCol, row);
    return forc.text.startsWith(sanitizedFilterTerm)
        || forc.description.toLowerCase().includes(sanitizedFilterTerm);
  });
};

export default {
  tableForcFieldSearchStartWithCodeOrContainsDescription,
  getColumnByName,
};
