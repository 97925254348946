import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import ResearcherDetails from '@/components/researcher/researcher-details/ResearcherDetails.vue';
import ResearchersHome from '@/components/researcher/researcher-search/ResearchersHome.vue';

const eligibleResearcherRoutes = {
  path: 'researchers',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'researchers',
      component: ResearchersHome,
      meta: {
        activeRoute: 'researchers',
      },
    }, {
      path: ':researcherId',
      name: 'researcher-details',
      props: true,
      meta: {
        activeRoute: 'researchers',
      },
      component: ResearcherDetails,
    },
  ],
};

export default eligibleResearcherRoutes;
