<template>
  <div :class="'badge-container-'+size">
    <div class="badge" :class="badgeClass" :style="badgeStyle">
      {{ letter }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    color: {
      type: String,
      default: 'tertiary',
    },
    letter: {
      type: String,
      default: '',
    },
    badgeStyle: {
      type: String,
      default: 'none',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    badgeClass() {
      const sizeAndBackground = [
        this.size,
        `bg-${this.color}`,
      ];
      if (!this.border) return sizeAndBackground;

      return [...sizeAndBackground, 'badge-border'];
    },
  },
};
</script>

<style lang="stylus" scoped>
  .badge-container-large
    display: table
    line-height 0
    width 6rem
    height 6rem
    padding 4px
    font-size 4.4rem

  .badge-container-small
    display: table
    background-color: black
    border-radius: 50%
    line-height 0
    width 40px
    height 40px
    font-size 30px

  .badge
    border-radius: 50%
    display table-cell
    vertical-align middle
    text-align center
    text-decoration none

  .badge-border
    border: .2rem solid white;

</style>
