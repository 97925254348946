<template>
  <div class="relative-position">
    <radar-loading :visible="isLoading" />
    <div>
      <div class="bg-mq-soft-light q-pa-sm">
        <div class="row justify-between page-title-dense items-baseline group">
          <div class="col-auto">
            <q-btn
              dense
              flat
              title="Expand all"
              icon="unfold_more"
              class="font-weight-bolder"
              color="mq-soft"
              @click="expandAll()"
            />
            <q-btn
              dense
              flat
              title="Collapse all"
              icon="unfold_less"
              class="font-weight-bolder"
              color="mq-soft"
              @click="collapseAll()"
            />
          </div>
          <div class="col-auto q-pr-xs">
            <q-btn-toggle
              v-model="forcTypeFilter"
              no-wrap
              size="sm"
              outline
              toggle-color="secondary"
              :options="[
                {label: 'All', value: 'all'},
                {label: 'Peer review', value: 'peerReview'},
                {label: 'Citation', value: 'citation'}
              ]"
            />
          </div>
        </div>
        <div class="row items-baseline group">
          <div class="col">
            <q-field class="content-bg-white no-padding-top">
              <q-select
                v-model="filterForcs"
                :options="forcOptions"
                class="q-pl-sm"
                chips
                chips-bg-color="mq-neutral"
                chips-color="dark"
                placeholder="Filter FoRCs"
                clearable
                multiple
                autofocus-filter
                :filter="labelStartWithOrSublabelContainsFilter"
              />
            </q-field>
          </div>
        </div>
      </div>
      <div class="page-scroll-height q-mx-md">
        <!--
        Instead of filtering comparisons component create/destroy with filter changes,
        just show or hide them, otherwise there are too many create/destroys and freezes
        the browser for some time while filtering FoRCs
        -->
        <forc-performance-comparison
          v-for="comparison in forcPerformanceComparisons"
          v-show="filteredForcIds.includes(comparison.forc_id)"
          :key="comparison.forc_id"
          ref="forcPerformanceComparisons"
          :comparison="comparison"
          :forcs="filteredForcs"
          :class="{'selected': selectedForcId === comparison.forc_id}"
          class="forc-performance-comparison bg-mq-neutral-light q-pr-lg q-py-md border border-mq-separator"
          @select="toggleForcSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { labelStartWithOrSublabelContainsFilter } from '@/utils/search';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ForcPerformanceComparison from './ForcPerformanceComparison.vue';

export default {
  name: 'WhatIfForcPerformanceComparisons',
  components: { RadarLoading, ForcPerformanceComparison },
  data() {
    return {
      filterForcs: [],
      filterForcTexts: [],
      forcTypeFilter: 'all',
      selectedForcId: undefined,
    };
  },
  computed: {
    ...mapState('whatIfScenario', {
      forcPerformanceComparisons: state => state.forcPerformanceComparisons,
      isLoading: state => state.progress.forcPerformanceComparision,
    }),
    ...mapGetters('referenceData', ['forcs', 'twoAndFourDigitForcsForWorkingEra']),
    whatIfId() {
      return this.$route.params.whatIfId;
    },
    impactedForcs() {
      const forcIds = this.forcPerformanceComparisons.map(comparison => comparison.forc_id);

      return this.forcs.filter(forc => forcIds.includes(forc.id));
    },
    filteredFourDigitForcs() {
      if (_.isEmpty(this.filterForcs)) {
        return this.impactedForcs;
      }
      return this.impactedForcs.filter(forc => forc.isWithinRangeOfAny(this.filterForcs));
    },
    filteredForcs() {
      return this.filteredFourDigitForcs.filter((forc) => {
        if (this.forcTypeFilter === 'all') return true;
        return (this.forcTypeFilter === 'peerReview') === forc.isPeerReviewEligible();
      });
    },
    filteredForcIds() {
      return this.filteredForcs.map(forc => forc.id);
    },
    impactedTwoDigitForcs() {
      const twoDigitCodes = this.impactedForcs.reduce(
        ((twoDigitForcCodes, forc) => twoDigitForcCodes.add(forc.two_digit_code)),
        new Set(),
      );
      return this.forcs.filter(forc => forc.isTwoDigitForc() && twoDigitCodes.has(forc.two_digit_code));
    },
    forcOptions() {
      const impactedTwoAndFourDigitForcs = _.union(this.impactedForcs, this.impactedTwoDigitForcs);
      const sortedForcs = _.sortBy(impactedTwoAndFourDigitForcs, forc => forc.text);
      return sortedForcs.map(forc => ({
        label: forc.text,
        sublabel: forc.description,
        value: forc,
      }));
    },
  },
  methods: {
    ...mapActions('whatIfScenario', ['fetchForcPerformanceComparisons']),
    labelStartWithOrSublabelContainsFilter,
    getForcById(id) {
      return this.impactedForcs.find(forc => forc.id === Number(id));
    },
    expandAll() {
      this.$refs.forcPerformanceComparisons.forEach(comparison => comparison.expandDetails());
    },
    collapseAll() {
      this.$refs.forcPerformanceComparisons.forEach(comparison => comparison.collapseDetails());
    },
    toggleForcSelection(forc) {
      if (this.selectedForcId === forc.id) {
        this.selectedForcId = undefined;
        this.$emit('select', undefined);
      } else {
        this.selectedForcId = forc.id;
        this.$emit('select', forc);
      }
    },
  },
  watch: {
    whatIfId() {
      this.fetchForcPerformanceComparisons(this.whatIfId);
    },
  },
  mounted() {
    this.fetchForcPerformanceComparisons(this.whatIfId);
  },
};
</script>

<style scoped lang="stylus">
  .page-scroll-height
    height calc(100vh - 96px)
    overflow auto

  .forc-performance-comparison.selected
    border 1px solid $mq-magenta !important

  .forc-performance-comparison:not(:first-child)
    border-top 0
</style>
