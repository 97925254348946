<template>
  <div v-if="validation.isInvalid()" class="fit">
    <div class="text-weight-bold">
      Errors:
    </div>
    <ol class="term-definition-condensed">
      <li v-for="(error, index) in validation.errors" :key="index">
        {{ error }}
      </li>
    </ol>
  </div>
</template>

<script>
import Validation from '@/models/validation';

export default {
  name: 'GridValidationErrors',
  props: {
    validation: {
      type: Validation,
      default: () => new Validation(),
    },
  },
};
</script>

<style lang="stylus" scoped>
ol, ul
  margin 0
  padding-inline-start 1rem
  white-space normal
</style>
