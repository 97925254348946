import _ from 'lodash';
import api from '@/api';
import { Dispute } from '@/models/disputes/dispute';
import ResearchOutput from '@/models/research-output/research-output';
import ResearchOutputClaim from '@/models/research-output/research-output-claim';
import ResearchOutputStatus from '@/models/research-output/research-output-status';
import ResearchOutputSearchParam from '@/models/research-output/research-output-search-param';

export default {
  namespaced: true,
  state: {
    pagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    researchOutputs: [],
    currentIndex: 0,
    types: [
      'A1: Book',
      'B1: Book Chapter',
      'C1: Journal Article',
      'E1: Conference Publication',
      'Q1: Live Performance',
      'Q2: Original Creative Work',
      'Q3: Recorded or Rendered Work',
      'Q4: Curated Exhibition Or Event',
      'Q5: Report For External Body',
    ],
    roStatuses: [
      'valid: Valid',
      'invalid: Invalid',
      'under: < 100%',
      'over: > 100%',
      'claim_count_error: > 3 claims',
      'no_claims: No claims',
      'clawback_invalid: Non compliant',
    ],
    searchQueries: [],
    progress: {
      searching: false,
      fetchingResearchOutput: false,
      savingResearchOutput: false,
      savingClaim: false,
      fetchingClaims: false,
      deletingClaim: false,
      savingEligibility: false,
    },
  },
  mutations: {
    UPDATE_RESEARCH_OUTPUTS(state, { researchOutputs, currentPage, totalResults }) {
      state.researchOutputs = researchOutputs;
      state.pagination.currentPage = currentPage || 1;
      state.pagination.totalResults = totalResults || 0;
    },
    UPDATE_RESEARCH_OUTPUT(state, researchOutput) {
      state.researchOutputs[state.currentIndex] = researchOutput;
    },
    UPDATE_CLAIMS(state, claims) {
      state.researchOutputs[state.currentIndex].ro_claims = claims;
    },
    UPDATE_DISPUTE(state, dispute) {
      state.researchOutputs[state.currentIndex].dispute_data = dispute;
    },
    UPDATE_RESEARCH_OUTPUT_STATUS(state, roStatus) {
      state.researchOutputs[state.currentIndex].ro_status = roStatus;
    },
    UPDATE_RESEARCH_OUTPUT_ELIGIBILITY(state, status) {
      state.researchOutputs[state.currentIndex].ro_status = Object.assign(state.researchOutputs[state.currentIndex].ro_status, status);
    },
    UPDATE_RO_INDEX(state, index) {
      state.currentIndex = index;
    },
    UPDATE_SEARCH_QUERIES(state, queries) {
      state.searchQueries = queries;
    },
    REMOVE_SEARCH_QUERY(state, queryId) {
      state.searchQueries = state.searchQueries.filter(sq => sq.id !== queryId);
    },
    UPDATE_PROGRESS(state, loading) {
      state.progress = { ...state.progress, ...loading };
    },
  },
  actions: {
    async searchResearchOutputs({ commit, rootGetters }, searchParams) {
      commit(
        'UPDATE_PROGRESS',
        { searching: true, fetchingResearchOutput: true },
      );

      const searchParameters = ResearchOutputSearchParam.create(searchParams);
      searchParameters.excludeActive = rootGetters['eras/eras'].find(era => era.id === searchParams.eraId).isUpcoming();
      searchParameters.page = searchParameters.page || 1;

      try {
        const response = await api.researchOutputs.search(searchParameters.toApiParams());
        const researchOutputs = ResearchOutput.fromList(response.data.results);

        commit('UPDATE_RESEARCH_OUTPUTS', {
          researchOutputs,
          currentPage: searchParameters.page,
          totalResults: response.data.count,
        });
        commit('UPDATE_RO_INDEX', 0);
      } finally {
        commit(
          'UPDATE_PROGRESS',
          { searching: false, fetchingResearchOutput: false },
        );
      }
    },
    fetchResearchOutput({ state, commit }, { researchOutputId }) {
      const index = _.findIndex(
        state.researchOutputs,
        ro => ro.id === researchOutputId,
      );
      commit('UPDATE_RO_INDEX', index);
    },
    async saveResearchOutput({ commit, state }, { id, params }) {
      commit('UPDATE_PROGRESS', { savingResearchOutput: true });
      try {
        const existingResearchOutput = state.researchOutputs.find(researchOutput => researchOutput.compare(id));
        const { data } = await api.researchOutputs.update({ id, params });
        existingResearchOutput.updatePartial(data);
        commit('UPDATE_RESEARCH_OUTPUT', existingResearchOutput);
      } finally {
        commit('UPDATE_PROGRESS', { savingResearchOutput: false });
      }
    },
    async saveClaim({ commit, state }, researchOutputClaim) {
      const createClaim = () => api.researchOutputs.createClaim(researchOutputClaim);
      const updateClaim = () => api.researchOutputs.updateClaim(researchOutputClaim);

      commit('UPDATE_PROGRESS', { savingClaim: true });
      try {
        const isNew = !researchOutputClaim.id;
        await (isNew ? createClaim() : updateClaim());
        const existingResearchOutput = state.researchOutputs.find(researchOutput => researchOutput.compare(researchOutputClaim.research_output_id));
        existingResearchOutput.addOrUpdateClaim(researchOutputClaim);
        commit('UPDATE_RESEARCH_OUTPUT', existingResearchOutput);
      } finally {
        commit('UPDATE_PROGRESS', { savingClaim: false });
      }
    },

    async fetchClaims({ commit }, { researchOutputId, claimStatus }) {
      commit('UPDATE_PROGRESS', { fetchingClaims: true });
      try {
        const response = await api.researchOutputs.getClaims(researchOutputId, claimStatus);
        const claims = ResearchOutputClaim.fromList(response.data.ro_claims);
        commit('UPDATE_CLAIMS', claims);

        const dispute = new Dispute(response.data.dispute_data);
        commit('UPDATE_DISPUTE', dispute);

        const roStatus = response.data.ro_status
          ? new ResearchOutputStatus(response.data.ro_status)
          : ResearchOutputStatus.noClaimsStatus();
        commit('UPDATE_RESEARCH_OUTPUT_STATUS', roStatus);
      } finally {
        commit('UPDATE_PROGRESS', { fetchingClaims: false });
      }
    },

    async deleteClaim({ commit, getters }, researchOutputClaim) {
      commit('UPDATE_PROGRESS', { deletingClaim: true });
      try {
        const { researchOutput } = getters;
        await api.researchOutputs.deleteClaim(researchOutputClaim);
        researchOutput.deleteClaim(researchOutputClaim);
        commit('UPDATE_RESEARCH_OUTPUT', researchOutput);
      } finally {
        commit('UPDATE_PROGRESS', { deletingClaim: false });
      }
    },
    escalateClaim(context, disputeId) {
      return api.disputes.escalateClaim(disputeId);
    },
    resolveClaim(context, disputeId) {
      return api.disputes.resolveClaim(disputeId);
    },
    async saveEligibilityStatus({ commit }, { researchOutputId, eligibility }) {
      commit('UPDATE_PROGRESS', { savingEligibility: true });
      try {
        const eligibilityStatus = {
          forced_eligibility: eligibility.status,
          comment: eligibility.comment,
        };
        const response = await api.researchOutputs.updateEligibilityStatus(researchOutputId, eligibilityStatus);
        const status = { forced: response.data.forced_eligibility, comment: response.data.latest_comment.comment };
        commit('UPDATE_RESEARCH_OUTPUT_ELIGIBILITY', status);
      } finally {
        commit('UPDATE_PROGRESS', { savingEligibility: false });
      }
    },
    async fetchSearchQueries({ state, commit }) {
      if (state.searchQueries.length === 0) {
        const response = await api.searchQueries.getAll();
        commit('UPDATE_SEARCH_QUERIES', response.data.results);
      }
    },
    async deleteSearchQuery({ commit }, queryId) {
      await api.searchQueries.delete(queryId);
      commit('REMOVE_SEARCH_QUERY', queryId);
    },
    async createSearchQuery({ commit }, query) {
      const response = await api.searchQueries.create(query);
      commit('UPDATE_SEARCH_QUERIES', response.data.results);
    },
  },
  getters: {
    researchOutput: state => state.researchOutputs[state.currentIndex],
    dispute: state => state.researchOutputs[state.currentIndex].dispute_data, // || new Dispute(),
    claims: state => state.researchOutputs[state.currentIndex].ro_claims,
    orderedSearchQueries: state => _.sortBy(state.searchQueries, 'name'),
  },
};
