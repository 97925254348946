import _ from 'lodash';

export const getNonEmptyValues = query => _.pickBy(query, value => !_.isNil(value));

export const startsWithFilter = (filterTerm, { label }) => label.startsWith(filterTerm);

export const forcStartsWithFilter = (filterTerm, { value: forc }) => startsWithFilter((filterTerm, { label: forc.text }));

export const labelStartWithOrSublabelContainsFilter = (filterTerm, { label, sublabel }) => {
  const matchesLabel = label.startsWith(filterTerm);
  if (matchesLabel) return true;

  if (sublabel) {
    return sublabel.toLowerCase().includes(filterTerm.toLowerCase());
  }
  return false;
};

/** Returns whether select list label has item matching user input */
export const labelContainsFilter = (filterTerm, { label }) => {
  return label.toLowerCase().includes(filterTerm.toLowerCase());
};

export const forcStartWithOrDescriptionContainsFilter = (filterTerm, { value: forc }) => labelStartWithOrSublabelContainsFilter(
  filterTerm,
  { label: forc.text, sublabel: forc.description },
);

export default {
  getNonEmptyValues,
  startsWithFilter,
  forcStartsWithFilter,
  labelStartWithOrSublabelContainsFilter,
  labelContainsFilter,
  forcStartWithOrDescriptionContainsFilter,
};
