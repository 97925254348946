import { HERDC_INCOME_TYPES } from '@/models/research-income/funding';

class ResearchIncomeByYear {
  constructor(value) {
    this.category = value.herdc_income_type;
    this.subCategory = HERDC_INCOME_TYPES.get(this.category);
    this.grantCount = value.grant_count_apportioned;
    this.years = Object.fromEntries(value.year_totals.map(y => [y.year, y.year_total_apportioned]));
  }

  static fromList(list = []) {
    return list.map(item => new ResearchIncomeByYear(item));
  }
}

export default ResearchIncomeByYear;
