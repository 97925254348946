<template>
  <q-chip
    v-if="!era.isEmpty()"
    square
    :color="era.isActive()? 'positive': era.isUpcoming()? 'info': 'warning'"
    :small="!dense"
    :dense="dense"
  >
    {{ eraStatus }}
  </q-chip>
</template>

<script>
import _ from 'lodash';
import Era from '@/models/era/era';

export default {
  name: 'EraStatus',
  props: {
    era: {
      type: Era,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eraStatus() {
      return _.toUpper(this.era.submission_status);
    },
  },
};
</script>
