<template>
  <div class="full-width">
    <h2 class="r-heading-3 text-mq-soft">Sensitivities</h2>
    <div class="q-py-md">
      <q-checkbox
        v-for="sensitivityType in sensitivityTypes"
        :key="sensitivityType.id"
        v-model="newSelectedSensitivityTypes"
        :val="sensitivityType.id"
        class="q-pa-md"
        :disable="isReadonlyModeEnabled || isOtherTypeSelected"
      >
        <q-chip small class="q-ml-md q-mr-sm" color="mq-soft-muted">
          {{ sensitivityType.value }}
        </q-chip>
        <span class="text-weight-regular text-body1">{{
          sensitivityType.description
        }}</span>
      </q-checkbox>
    </div>
    <div class="q-pa-md">
      <separator-with-text title="OR" />
    </div>
    <div class="q-pa-md row items-start">
      <q-checkbox
        v-model="isOtherTypeSelected"
        :disable="isReadonlyModeEnabled"
      />
      <div class="flex-1 q-ml-md column gap-sm">
        <q-input
          v-model="updatedOtherTypeText"
          placeholder="Free text"
          class="bg-blue-grey-1 no-shadow"
          inverted-light
          type="textarea"
          color="default"
          :maxlength="otherSensitivityMaxChars"
          rows="7"
          :disable="isReadonlyModeEnabled || !isOtherTypeSelected"
        />
        <p v-if="isOtherTypeSelected" class="self-end text-blue-grey q-body-1">
          {{ updatedOtherTypeText.length }}/{{ otherSensitivityMaxChars }}
        </p>
      </div>
    </div>
    <div v-if="!isReadonlyModeEnabled" class="row q-py-md q-px-sm gap-sm">
      <q-btn
        outline
        label="Cancel"
        color="primary"
        @click="handleCancelClick"
      />
      <q-btn
        outline
        label="Save"
        color="primary"
        :disable="isSaveButtonDisabled"
        @click="handleSaveClick"
      />
    </div>
  </div>
</template>
<script>
import SeparatorWithText from '../../common/SeparatorWithText.vue';

export default {
  name: 'ResearchOutputSensitivities',
  components: {
    SeparatorWithText,
  },
  props: {
    sensitivityTypes: {
      type: Array,
      default: () => [],
    },
    selectedSensitivityTypes: {
      type: Array,
      default: () => [],
    },
    selectedOtherTypeText: {
      type: String,
      default: '',
    },
    isReadonlyModeEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newSelectedSensitivityTypes: [],
      updatedOtherTypeText: '',
      isOtherTypeSelected: false,
      otherSensitivityMaxChars: 1500,
    };
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.selectedOtherTypeText) {
        return this.updatedOtherTypeText === this.selectedOtherTypeText;
      }

      if (this.selectedSensitivityTypes.length) {
        return (
          this.newSelectedSensitivityTypes.length
            === this.selectedSensitivityTypes.length
          && this.newSelectedSensitivityTypes.every(type => this.selectedSensitivityTypes.includes(type))
        );
      }
      return !(
        this.isOtherTypeSelected || this.newSelectedSensitivityTypes.length
      );
    },
  },
  methods: {
    handleCancelClick() {
      this.setInitialSelectedValues();
    },
    setInitialSelectedValues() {
      this.newSelectedSensitivityTypes = this.selectedSensitivityTypes;
      this.isOtherTypeSelected = !!this.selectedOtherTypeText;
      this.updatedOtherTypeText = this.selectedOtherTypeText;
    },
    handleSaveClick() {
      if (this.isOtherTypeSelected && !this.updatedOtherTypeText.trim()) {
        return this.$notify.failure('Text is required if ‘Other’ is selected');
      }
      return this.$emit('save', {
        sensitivity_types: this.newSelectedSensitivityTypes,
        other_sensitivity_note: this.updatedOtherTypeText,
      });
    },
  },
  watch: {
    isOtherTypeSelected(isSelected) {
      if (isSelected) {
        this.newSelectedSensitivityTypes = [];
      } else {
        this.updatedOtherTypeText = '';
      }
    },
    newSelectedSensitivityTypes(values = []) {
      if (values.length) {
        this.isOtherTypeSelected = false;
      }
    },
  },
  created() {
    this.setInitialSelectedValues();
  },
};
</script>
<style lang="stylus" scoped>
.q-chip {
  border-radius: 50%;
  padding: 0 6px;
  min-height: 0;
  font-size: x-small;
}

.q-option {
  align-items: flex-start;
}

.flex-1 {
  flex: 1;
}

.gap-sm {
  gap: 10px;
}
</style>
