<template>
  <div class="q-pa-md">
    <slot name="title" />
    <div class="body-2">
      <template v-if="hasClaims">
        <div
          v-for="claim in researcherClaimVersion.researcher_claims"
          :key="researcherClaimVersion.id+ '-' +claim.forc.id"
        >
          <div class="border-bottom q-mb-md text-left">
            <span class="inline-block q-pr-xl">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
            <span class="inline-block q-pl-xl"> {{ claim.apportion }}%</span>
          </div>
        </div>
      </template>
      <div v-else class="border-bottom text-left q-pb-sm">No FoRCs</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ResearcherClaimVersion from '@/models/researcher/researcher-claim-version';

export default {
  name: 'ResearcherClaimVersionReadOnly',
  props: {
    researcherClaimVersion: {
      type: ResearcherClaimVersion,
      default: null,
    },
  },
  computed: {
    hasClaims() {
      return !_.isEmpty(_.get(this.researcherClaimVersion, 'researcher_claims', []));
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
