import _ from 'lodash';
import api from '@/api';
import Researcher from '@/models/researcher/researcher';
import ResearcherClaimVersion from '@/models/researcher/researcher-claim-version';
import ResearcherStatistics from '@/models/researcher/researcher-statistics';

export default {
  namespaced: true,
  state: {
    pagination: {
      totalResults: 0,
      pageSize: 10,
      currentPage: 1,
    },
    researchers: [],
    researcher: new Researcher(),
    researcherClaimVersions: [],
    priorityList: ['A', 'B', 'C', 'D'],
    workFunctions: [
      'Teaching Only',
      'Research Only',
      'Teaching & Research',
    ],
    eligibility: [
      'eligible: Eligible',
      'ineligible: Ineligible',
    ],
    researcherStatistics: new ResearcherStatistics(),
    progress: {
      loading: false,
      saving: false,
    },
  },
  mutations: {
    UPDATE_RESEARCHERS(state, { researchers, currentPage, totalResults }) {
      state.researchers = researchers;
      state.pagination.currentPage = currentPage || 1;
      state.pagination.totalResults = totalResults || 0;
    },
    UPDATE_RESEARCHER(state, researcher) {
      state.researcher = researcher;
    },
    UPDATE_RESEARCHER_CLAIMS_VERSIONS(state, researcherClaimVersions) {
      state.researcherClaimVersions = researcherClaimVersions;
    },
    UPDATE_RESEARCHER_STATISTICS(state, researcherStatistics) {
      state.researcherStatistics = researcherStatistics;
    },
    UPDATE_RESEARCHER_ELIGIBILITY(state, updatedStatus) {
      state.researcher.status = Object.assign(state.researcher.status, updatedStatus);
    },
    UPDATE_PRIMARY_POSITION(state, positionId) {
      state.researcher.researcher_positions.forEach((position) => {
        // eslint-disable-next-line no-param-reassign
        position.is_primary = position.id === positionId;
      });
    },
    UPDATE_RESEARCHER_STATS(state, { id, stats }) {
      const researcher = _.find(state.researchers, { id });
      if (researcher) {
        researcher.statistics = new ResearcherStatistics(stats);
      }
    },
    UPDATE_PROGRESS(state, progress) {
      state.progress = { ...state.progress, ...progress };
    },
  },
  actions: {
    async searchResearchers({ commit, rootGetters, dispatch }, searchParameters) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const allEras = rootGetters['eras/eras'];

        const isUpcomingEra = allEras
          .find(era => era.id === searchParameters.eraId)
          .isUpcoming();

        const params = {
          searchname: searchParameters.name,
          priority: searchParameters.priority,
          faculty_department_name: searchParameters.facultyOrDepartmentName,
          era_id: searchParameters.eraId,
          work_function: searchParameters.workFunction,
          exclude_active: isUpcomingEra,
          forc_ids: searchParameters.withNoForcOnly
            ? null
            : searchParameters.forcIds,
          page: searchParameters.page || 1,
          with_no_forc_only: searchParameters.withNoForcOnly,
          multiple_current_without_primary: searchParameters.withoutAssignedPrimaryPosition,
          filter_by_eligibility_status: searchParameters.eligibility,
          radar_id: searchParameters.radarId,
          has_orcid: searchParameters.hasOrcId,
          is_indigenous_researcher: searchParameters.onlyIndigenousResearcher,
        };

        const response = await api.researchers.search(params);
        const researchers = Researcher.fromList(response.data.results);

        commit(
          'UPDATE_RESEARCHERS',
          {
            researchers,
            currentPage: searchParameters.page || 1,
            totalResults: response.data.count,
          },
        );
        researchers.forEach(r => dispatch('getResearcherStats', r.id));
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },
    async getResearcherClaimVersionsForEra({ commit }, researcherId) {
      const researcherClaimVersionsResponse = await api.researchers.getClaimVersions(researcherId);
      const researcherClaimVersions = ResearcherClaimVersion.fromList(researcherClaimVersionsResponse.data);
      commit('UPDATE_RESEARCHER_CLAIMS_VERSIONS', researcherClaimVersions);
    },
    async getResearcherWithClaimsAndEraStats({ commit }, researcherId) {
      commit('UPDATE_PROGRESS', { loading: true });
      try {
        const [researcherResponse, researcherClaimVersionsResponse, researcherStatisticsResponse] = await Promise.all([
          api.researchers.get(researcherId),
          api.researchers.getClaimVersions(researcherId),
          api.researchers.getStatistics(researcherId),
        ]);
        const researcher = new Researcher(researcherResponse.data);
        const researcherClaimVersions = ResearcherClaimVersion.fromList(researcherClaimVersionsResponse.data);
        const researcherStatistics = new ResearcherStatistics(researcherStatisticsResponse.data);
        commit('UPDATE_RESEARCHER', researcher);
        commit('UPDATE_RESEARCHER_CLAIMS_VERSIONS', researcherClaimVersions);
        commit('UPDATE_RESEARCHER_STATISTICS', researcherStatistics);
      } finally {
        commit('UPDATE_PROGRESS', { loading: false });
      }
    },
    async getResearcherStats({ commit }, id) {
      const response = await api.researchers.getStatistics(id);
      commit('UPDATE_RESEARCHER_STATS', { id, stats: response.data });
    },
    async saveResearcherClaims({ commit }, { researcherId, researcherClaims }) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        const claimBasicDetails = researcherClaims.map(claim => claim.basicDetails());
        await api.researchers.saveClaims(researcherId, claimBasicDetails);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
    async saveEligibilityStatus({ commit }, { researcherId, eligibility }) {
      const eligibilityStatus = { forced_eligibility: eligibility.status, comment: eligibility.comment };
      const response = await api.researchers.updateEligibilityStatus(researcherId, eligibilityStatus);
      const status = { forced: response.data.forced_eligibility, comment: response.data.latest_comment.comment };
      commit('UPDATE_RESEARCHER_ELIGIBILITY', status);
    },
    async assignPrimaryPosition({ commit }, { researcherId, positionId }) {
      commit('UPDATE_PROGRESS', { saving: true });
      try {
        const response = await api.researchers.assignPrimaryPosition(researcherId, positionId);
        commit('UPDATE_PRIMARY_POSITION', response.data.position_id);
      } finally {
        commit('UPDATE_PROGRESS', { saving: false });
      }
    },
  },
  getters: {
    researcherClaimVersionLatestManual:
      state => state.researcherClaimVersions
        .find(version => version.isLatest() && version.isManualOverride()),

    researcherClaimVersionAutomated:
      state => state.researcherClaimVersions
        .find(version => version.isAutomated()),
  },
};
