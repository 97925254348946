import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import ResearchOutputSearchAndList
  from '@/components/research-output/search/ResearchOutputSearchAndList.vue';

const researchOutputRoutes = {
  path: 'research-outputs',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'research-outputs',
      component: ResearchOutputSearchAndList,
      // Default search parameters
      props: {
        eligibility: 'eligible',
      },
      meta: {
        activeRoute: 'research-outputs',
      },
    },
  ],
};

export default researchOutputRoutes;
