import axios, { useHttp } from './http';

const http = useHttp('research_outputs');

export default {
  searchBulk: params => axios.get('api/bulk_code/research_outputs/', { params }),
  search: params => http.get('', { params }),
  get: roId => http.get(roId),
  // Only save attributes internal to radar app - all others come from upstream system
  update: ({ id, params }) => http.put(id, params),

  getClaimEvents: (roId, page) => http.get([roId, 'claimevents'], { params: { page } }),
  saveBulkClaims: claims => http.post('bulk_claim', claims),

  getClaims: (roId, status) => http.get([roId, 'claims'], { params: { claim_status: status } }),
  createClaim: claim => http.post([claim.research_output_id, 'claims'], claim.basicDetails()),
  updateClaim: claim => http.put([claim.research_output_id, 'claims', claim.id], claim.basicDetails()),
  deleteClaim: claim => http.delete([claim.research_output_id, 'claims', claim.id]),

  getResearchStatement: roId => http.get([roId, 'research_statements']),
  createResearchStatement: (roId, statement) => http.post([roId, 'research_statements'], { statement }),
  updateResearchStatement: (roId, rsId, statement) => http.put([roId, 'research_statements', rsId], { statement }),

  updateEligibilityStatus: (roId, status) => http.put([roId, 'eligibility_status'], status),
};
