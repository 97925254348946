import _ from 'lodash';
import Author from '@/models/research-output/author';
import Validation from '@/models/validation';

class ChildForAppliedMeasure {
  constructor(researchOutput = {}) {
    this.id = researchOutput.id;
    this.pure_uuid = researchOutput.pure_uuid;
    this.external_id = researchOutput.external_id;
    this.research_output_type = researchOutput.research_output_type;
    this.research_output_type_name = researchOutput.research_output_type_name;
    this.managing_organisation_uuid = researchOutput.managing_organisation_uuid;
    this.managing_organisation_name = researchOutput.managing_organisation_name;
    this.title_nativescript = researchOutput.title_nativescript;
    this.title_language = researchOutput.title_language;
    this.title_translated = researchOutput.title_translated;
    this.pub_year = researchOutput.pub_year;
    this.creators = Author.fromList(researchOutput.creators);
    this.identifier = researchOutput.identifier;
    this.country = researchOutput.country;
    this.is_eligible = !!researchOutput.is_eligible;
    this.validation = new Validation();
  }

  get title() {
    return this.is_translated ? this.title_translated : this.title_nativescript;
  }

  static fromList(researchOutputs = []) {
    return researchOutputs
      .map(researchOutput => new ChildForAppliedMeasure(researchOutput));
  }

  uniqueCreators() {
    return _.uniqBy(this.creators, 'id');
  }
}

export default ChildForAppliedMeasure;
