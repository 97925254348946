import _ from 'lodash';

const convertEmptyStringToUndefined = value => (value === ''
  ? undefined
  : value);

class Forc {
  constructor(forc = {}) {
    this.id = forc.id;
    this.two_digit_code = convertEmptyStringToUndefined(forc.two_digit_code);
    this.four_digit_code = convertEmptyStringToUndefined(forc.four_digit_code);
    this.six_digit_code = convertEmptyStringToUndefined(forc.six_digit_code);
    this.meta_id = forc.meta_id;
    this.description = forc.description;
    this.two_digit_forc_id = forc.two_digit_forc_id;
    this.four_digit_forc_id = forc.four_digit_forc_id;
    this.is_peer_review_eligible = forc.is_peer_review_eligible;
  }

  get text() {
    return this.six_digit_code || this.four_digit_code || this.two_digit_code || '';
  }

  get fourDigitForcId() {
    return this.isFourDigitForc() ? this.id : this.four_digit_forc_id;
  }

  static fromList(forcs = []) {
    return forcs.map(forc => new Forc(forc));
  }

  static sortAsNumbers(forcs) {
    return _.sortBy(forcs, forc => Number(forc.text));
  }

  static compareForNumberSort(forc1, forc2) {
    return Number(forc1.text) - Number(forc2.text);
  }

  static sortAsText(forcs) {
    return _.sortBy(forcs, forc => forc.text);
  }

  static getTwoAndFourDigitForcs(forcs) {
    const twoAndFourDigitForcs = forcs.filter(forc => !forc.isSixDigitForc());
    return Forc.sortAsText(twoAndFourDigitForcs);
  }

  static getFourAndSixDigitForcs(forcs) {
    const fourAndSixDigitForcs = forcs
      .filter(forc => !!(forc.four_digit_code || forc.six_digit_code));
    return Forc.sortAsText(fourAndSixDigitForcs);
  }

  static areForcsWithinRangeOfAnyOfTheForcs(childrenForcs, parentForcs) {
    return !_.isEmpty(_.intersectionWith(
      childrenForcs, parentForcs,
      (childForc, parentForc) => childForc.isWithinRangeOf(parentForc),
    ));
  }

  isPeerReviewEligible() {
    return this.is_peer_review_eligible;
  }

  isTwoDigitForc() {
    return !!(this.two_digit_code && !this.four_digit_code);
  }

  isFourDigitForc() {
    return !!(this.four_digit_code && !this.six_digit_code);
  }

  isSixDigitForc() {
    return !!this.six_digit_code;
  }

  isSameAs(that) {
    return this.id === that.id;
  }

  isWithinRangeOf(that) {
    return this.text.startsWith(that.text);
  }

  isWithinRangeOfAny(forcs) {
    return _.some(forcs, forc => this.isWithinRangeOf(forc));
  }
}

export default Forc;
