import AppliedMeasure from './applied-measure';
import AppliedMeasureCounts from './applied-measure-counts';

class AppliedMeasureStatistics {
  constructor(type = null, stats = {}) {
    this.type = type;
    this.stats = new AppliedMeasureCounts(stats);
  }

  static toArray(val) {
    return Object.entries(val || {})
      .map(([key, stats]) => new AppliedMeasureStatistics(key, stats));
  }

  static patentStats(stats) {
    return stats.filter(stat => stat.type === AppliedMeasure.TYPES.PATENT_FAMILY)[0];
  }
}

export default AppliedMeasureStatistics;
