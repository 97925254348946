<template>
  <div class="stats__container">
    <router-link
      class="stats__total"
      target="_blank"
      :tag="totalCount ? 'a' : 'div'"
      :to="{ name: linkName, query: { authorNameContains: oneId, eligibility }}"
    >
      <div :class="totalCount ? null : 'no-link'">
        <div>Total</div>
        <div>{{ totalCount }}</div>
      </div>
    </router-link>
    <div v-if="(!totalOnly && typeof(total) !== 'number')" class="stats__count">
      <router-link
        v-for="(count, category) in displayStats"
        :key="category"
        class="stats__item"
        target="_blank"
        :tag="category && count ? 'a' : 'div'"
        :to="{ name: linkName, query: { authorNameContains: oneId, researchOutputTypes: category, eligibility }}"
      >
        <div :class="category && count ? null : 'no-link'">
          <div>{{ category }}</div>
          <div>{{ count }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearcherStats',
  props: {
    linkName: {
      type: String,
      required: true,
    },
    oneId: {
      type: String,
      default: null,
    },
    stats: {
      type: Array,
      default: () => [],
    },
    eligibility: {
      type: String,
      default: null,
    },
    totalOnly: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    displayStats() {
      const initCategories = [
        ['A1', 0], ['B1', 0], ['C1', 0], ['E1', 0], ['', ''],
        ['Q1', 0], ['Q2', 0], ['Q3', 0], ['Q4', 0], ['Q5', 0],
      ];
      return Object.fromEntries([...initCategories, ...this.stats]);
    },
    totalCount() {
      return typeof (this.total) === 'number'
        ? this.total
        : this.stats.map(s => s[1] || 0).reduce((acc, count) => acc + count, 0);
    },
  },
};
</script>

<style lang="stylus" scoped>
$stats-item
  padding 6px
  color black
  text-decoration none

  & > div
    padding 6px 0
    border-radius 4px

    &:not(.no-link):hover
      background-color $indigo-2
      font-weight 700

    &.no-link:hover
      cursor not-allowed

    div:last-child
      padding-top 5px
      color $mq-magenta

.stats
  &__container
    display flex
    flex-wrap wrap
    margin-top 10px
    text-transform uppercase
    font-size 12px
    text-align center

  &__total
    flex-basis 50px
    @extend $stats-item

  &__count
    flex 1
    flex-basis 80%
    display flex
    flex-wrap wrap
    background-color $mq-neutral

  &__item
    flex-basis 20%
    max-width 50px
    @extend $stats-item

    &:nth-child(5)
      flex-basis calc(max(100% - 200px, 20%))
      max-width none;

</style>
