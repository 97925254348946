import _ from 'lodash';

const ELIGIBILITY_STATUS = {
  eligible: 'Eligible',
  ineligible: 'Ineligible',
};

class ResearchOutputStatus {
  static displayValues = {
    valid: {
      icon: 'done',
      text: 'Valid',
    },
    under: {
      icon: 'trending_down',
      text: 'Under',
    },
    over: {
      icon: 'trending_up',
      text: 'Over',
    },
    claim_count_error: {
      icon: 'fas fa-cubes',
      text: '> 3 claims',
    },
    no_claims: {
      icon: 'layers_clear',
      text: 'No claims',
    },
    portfolio_member: {
      icon: 'business_center',
      text: 'Portfolio',
    },
    ineligible: {
      icon: 'highlight_off',
      text: 'Ineligible',
    },
    clawback_invalid: {
      icon: 'unpublished',
      text: 'Non compliant',
    },
  };

  constructor(researchOutputStatus = {}) {
    this.id = researchOutputStatus.id;
    this.era_submission_id = researchOutputStatus.era_submission_id;
    this.research_output_id = researchOutputStatus.research_output_id;
    this.claim_status = researchOutputStatus.claim_status;
    this.portfolio_member = researchOutputStatus.portfolio_member;
    this.forced = researchOutputStatus.forced_eligibility || researchOutputStatus.forced;
    this.automatic = researchOutputStatus.automatic_eligibility || researchOutputStatus.automatic;
    this.comment = researchOutputStatus.comment;
  }

  static noClaimsStatus() {
    return new ResearchOutputStatus({ claim_status: 'no_claims' });
  }

  static getStatusTextFor(researchOutputStatus) {
    const claimStatus = ResearchOutputStatus.getStatusValue(researchOutputStatus);

    return ResearchOutputStatus.displayValues[claimStatus].text;
  }

  static getIconFor(researchOutputStatus) {
    const status = ResearchOutputStatus.getStatusValue(researchOutputStatus);
    return ResearchOutputStatus.displayValues[status].icon;
  }

  static getStatusValue(researchOutputStatus) {
    if (!researchOutputStatus.isEligible()) { return 'ineligible'; }
    if (this.isPortfolioMember(researchOutputStatus)) { return 'portfolio_member'; }
    return _.get(researchOutputStatus, 'claim_status', 'no_claims');
  }

  static isPortfolioMember(researchOutputStatus) {
    return _.get(researchOutputStatus, 'portfolio_member', false);
  }

  isValid() {
    return this.claim_status === 'valid';
  }

  hasClaims() {
    return this.claim_status !== 'no_claims';
  }

  isAutomatic() {
    return this.forced === 'automatic';
  }

  isEligible() {
    return (this.isAutomatic() ? this.automatic : this.forced) === 'eligible';
  }

  displayEligibilityText() {
    if (this.id) {
      return this.isAutomatic()
        ? `Automatic ${ELIGIBILITY_STATUS[this.automatic]}`
        : `Forced ${ELIGIBILITY_STATUS[this.forced]}`;
    }
    return '-';
  }
}

export default ResearchOutputStatus;
