import User from '@/models/user/user';
import EraFaculty from '@/models/era/era-faculty';

export const WHAT_IF_EVENT_ACTIONS = {
  CREATED: 'created',
  SUBMITTED: 'submitted',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  APPLIED: 'applied',
  REOPENED: 're-opened',
};

class WhatIfEvent {
  constructor(whatIfEvent = {}) {
    this.id = whatIfEvent.id;
    this.updated_at = whatIfEvent.updated_at;
    this.updated_by = whatIfEvent.updated_by && new User(whatIfEvent.updated_by);
    this.comment = whatIfEvent.comment;
    this.event_type = whatIfEvent.event_type;
    this.approved_by = whatIfEvent.approved_by && new User(whatIfEvent.approved_by);
    this.rejected_by = whatIfEvent.rejected_by && new User(whatIfEvent.rejected_by);
    this.updated_by_faculty = whatIfEvent.updated_by_faculty && new EraFaculty(whatIfEvent.updated_by_faculty);
  }

  static fromList(whatIfEvents = []) {
    return whatIfEvents.map(event => new WhatIfEvent(event));
  }

  isCreated() {
    return this.event_type === WHAT_IF_EVENT_ACTIONS.CREATED;
  }

  isSubmitted() {
    return this.event_type === WHAT_IF_EVENT_ACTIONS.SUBMITTED;
  }

  isAccepted() {
    return this.event_type === WHAT_IF_EVENT_ACTIONS.ACCEPTED;
  }

  isApplied() {
    return this.event_type === WHAT_IF_EVENT_ACTIONS.APPLIED;
  }

  isRejected() {
    return this.event_type === WHAT_IF_EVENT_ACTIONS.REJECTED;
  }

  hasBeenSubmittedBy(facultyIds = []) {
    return this.isSubmitted() && facultyIds.includes(this.updated_by_faculty?.id);
  }
}

export default WhatIfEvent;
