<template>
  <div class="q-mt-md text-left legend text-weight-medium">
    <div class="claims-history__title">
      <span v-if="hasClaimVersions">History</span>
      <span v-else>No History</span>
    </div>
    <div v-for="claimVersion in claimVersions" :key="claimVersion.id">
      <div class="q-mt-sm claims-history__author">
        {{ displayName(claimVersion.created_by) }} on the {{ formatDateTime(claimVersion.updated_at) }}
      </div>
      <div class="claim-history__row">
        <span v-for="claim in claimVersion.claims" :key="claim.id" class="claims-history__forc" :class="{ 'include-peer-review': includePeerReview }">
          <span class="claims-history__forc-code">{{ claim.forc.text }}<q-tooltip>{{ claim.forc.description }}</q-tooltip></span>
          <span class="claims-history__apportion">{{ claim.apportion }}%</span>
          <span v-if="includePeerReview" class="claims-history__peer-review">{{ displayTick(claim.isPeerReviewed) }}</span>
        </span>
      </div>
      <div class="q-mt-sm claims-history__comment">
        {{ claimVersion.comment }}
      </div>
      <q-card-separator class="q-mt-lg" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDateTime } from '@/utils/date';

export default {
  name: 'ClaimsHistory',
  props: {
    claimVersions: {
      type: Array,
      default: () => [],
    },
    includePeerReview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    reverseSortedClaimVersions() {
      return _.sortBy(this.claimVersions, version => -version.updated_at);
    },
    hasClaimVersions() {
      return !_.isEmpty(this.reverseSortedClaimVersions);
    },
  },
  methods: {
    formatDateTime,
    displayName(createdBy) {
      return createdBy ? createdBy.displayName() : 'System Change';
    },
    displayTick(isPeerReviewed) {
      return isPeerReviewed ? '✓' : null;
    },
  },
};
</script>

<style lang="stylus" scoped>

.claims-history
  &__title
    @extend $term-title
    text-transform uppercase

  &__author
    font-size 12px

  &__forc
    display inline-flex
    width 33%
    outline: 1px solid #ccc
    outline-offset: -6px
    padding: 8px 4px

  &__row
    margin-left: -2px
    margin-right: -8px

  &__forc-code
    flex-basis (4 / 7) * 100%
    text-align center

    &.include-peer-review
      flex-basis 50%

  &__apportion
    flex-basis (3 / 7) * 100%
    text-align center

    &.include-peer-review
      flex-basis 37.5%

  &__peer-review
    flex-basis 12.5%
    text-align center

  &__comment
    font-weight 400
</style>
