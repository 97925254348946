import _ from 'lodash';
import { validateAddsToHundred, validateDuplicateClaims } from '@/utils/generic-claims-validator';
import ClaimItem from '@/models/era/claim-item';
import ClaimVersion from '@/models/era/claim-version';
import Validation from '@/models/validation';
import ChildForAppliedMeasure from './child-for-applied-measure';

export const APPLIED_MEASURE_TYPE = {
  PATENT_FAMILY: 'Patent Family',
  PLANT_BREEDER_RIGHTS_FAMILY: 'Plant Breeder Rights Family',
  REGISTERED_DESIGN: 'Registered Design',
  NHMRC_ENDORSED_GUIDELINES: 'NHMRC Endorsed Guidelines',
};

class AppliedMeasure {
  static TYPES = {
    PATENT_FAMILY: 'PATENT_FAMILY',
    PLANT_BREEDER_RIGHTS_FAMILY: 'PLANT_BREEDER_RIGHTS_FAMILY',
    REGISTERED_DESIGN: 'REGISTERED_DESIGN',
    NHMRC_ENDORSED_GUIDELINES: 'NHMRC_ENDORSED_GUIDELINES',
  };

  constructor(appliedMeasure = {}) {
    this.id = appliedMeasure.id;
    this.applied_measure_type = appliedMeasure.applied_measure_type;
    this.name = appliedMeasure.name;
    this.children = appliedMeasure.children ? ChildForAppliedMeasure.fromList(appliedMeasure.children) : [];
    this.comment = appliedMeasure.comment;
    this.automated_claim_version = appliedMeasure.automated_claim_version
      ? new ClaimVersion(appliedMeasure.automated_claim_version) : new ClaimVersion();
    this.claim_version = appliedMeasure.claim_version
      ? new ClaimVersion(appliedMeasure.claim_version) : new ClaimVersion();
    this.last_three_version_history = appliedMeasure.last_three_version_history
      ? ClaimVersion.fromList(appliedMeasure.last_three_version_history) : [];
    this.previous_era_claim_version = appliedMeasure.previous_era_claim_version
      ? new ClaimVersion(appliedMeasure.previous_era_claim_version) : new ClaimVersion();
    this.latest_claim_version = undefined;
    this.under_edit_claims = undefined;
    this.updateLatestClaimVersionWith(appliedMeasure.claim_version);
    this.is_eligible = !!appliedMeasure.is_eligible;
    this.validation = new Validation();
  }

  static fromList(appliedMeasures = []) {
    return appliedMeasures.map(item => new AppliedMeasure(item));
  }

  updateLatestClaimVersionWith(latestClaimVersion) {
    this.latest_claim_version = (latestClaimVersion)
      ? new ClaimVersion(latestClaimVersion) : [];
    this.under_edit_claims = (this.latest_claim_version)
      ? ClaimItem.fromList(this.latest_claim_version.claims) : [];
  }

  getYearsSorted() {
    return _.uniq(this.children.map(child => child.pub_year)).sort();
  }

  getAppliedMeasureTypeName() {
    return APPLIED_MEASURE_TYPE[this.applied_measure_type];
  }

  uniqueCreators() {
    const authors = _.flatten(this.children.map(child => child.uniqueCreators()));
    return _.uniqBy(authors, 'id');
  }

  getPreviousClaims() {
    return _.get(this.previous_era_claim_version, 'claims', []);
  }

  hasPreviousClaimVersions() {
    return !_.isEmpty(this.getPreviousClaims());
  }

  getPreviousClaimAt(index) {
    return this.getPreviousClaims()[index];
  }

  getLatestClaims() {
    return _.get(this.latest_claim_version, 'claims', []);
  }

  addClaim(claim) {
    const existingClaimForSameForc = this.under_edit_claims
      .find(existingClaim => existingClaim.forc.isSameAs(claim.forc));

    if (existingClaimForSameForc) {
      existingClaimForSameForc.apportion = claim.apportion;
      return;
    }

    if (this.under_edit_claims && this.under_edit_claims.length === 3) {
      return;
    }
    this.under_edit_claims.push(new ClaimItem(claim));
  }

  addEmptyClaims() {
    while (this.under_edit_claims.length < 3) {
      this.under_edit_claims.push(new ClaimItem());
    }
  }

  removeEmptyClaims() {
    this.under_edit_claims = this.under_edit_claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
  }

  removeClaimAt(index) {
    this.under_edit_claims.splice(index, 1);
  }

  addError(error) {
    this.validation.addErrors(error);
  }

  isValid() {
    return this.validation.isValid();
  }

  isInvalid() {
    return this.validation.isInvalid();
  }

  validate() {
    const missingValues = this.under_edit_claims
      .map((claim, index) => {
        if (!claim.forc.four_digit_code && claim.apportion) {
          return `Claim ${index + 1}: The FoRC code is missing`;
        } if (claim.forc.four_digit_code && !claim.apportion) {
          return `Claim ${index + 1}: The apportion is missing`;
        }
        return null;
      });

    const claims = this.under_edit_claims.filter(claim => !!claim.apportion && !!claim.forc.four_digit_code);
    const noClaims = claims.length === 0 ? 'You must have at least one claim present' : null;
    const duplicateClaimsError = validateDuplicateClaims(claims);
    const addsToHundredError = validateAddsToHundred(claims, true);

    this.validation.clearErrors();
    this.validation.errors.push(...missingValues);
    this.validation.addErrors(noClaims, duplicateClaimsError, addsToHundredError);

    return !this.validation.hasErrors();
  }

  hasClaimChanges() {
    const latestClaims = this.getLatestClaims();
    if (latestClaims.length !== this.under_edit_claims.length) {
      return true;
    }

    if (this.under_edit_claims.length === 0) return false;

    return this.under_edit_claims
      .some((existingClaim, index) => !existingClaim.isSameAs(latestClaims[index]));
  }

  basicClaimDetails() {
    return {
      applied_measure: this.id,
      claims: this.under_edit_claims.map(claim => claim.basicDetails()),
      comment: this.comment,
    };
  }
}

export default AppliedMeasure;
