import { VIEW_XML_SUBMISSION } from '@/models/user/access/permissions';
import EmptyRouterView from '@/layouts/EmptyRouterView.vue';
import XmlOverview from '@/components/xml/XmlOverview.vue';

const XmlRoutes = {
  path: 'xml',
  component: EmptyRouterView,
  props: true,
  children: [
    {
      path: '',
      name: 'xml',
      component: XmlOverview,
      meta: {
        auth: [VIEW_XML_SUBMISSION],
        activeRoute: 'xml',
      },
    },
  ],
};

export default XmlRoutes;
