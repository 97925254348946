export const xmlFields = [
  {
    name: 'type',
    label: 'Type',
    field: 'checkType',
    classes: '',
    align: 'left',
  },
  {
    name: 'lineNumber',
    label: 'Line Number',
    field: 'lineNumber',
    classes: '',
    align: 'center',
  }, {
    name: 'errorMessage',
    label: 'Error Message',
    field: 'errorMessage',
    classes: '',
    align: 'left',
  }, {
    name: 'uuid',
    label: 'XML Block',
    field: 'uuid',
    classes: '',
    align: 'center',
  },
];

export default { xmlFields };
