import { Notify } from 'quasar';
import axios from '@/api/http';
import { store } from './index';

export const notifyUnAuthorized = () => {
  Notify.create({
    message: 'Error!',
    detail: 'You don\'t have access to requested content',
    type: 'negative',
  });
};

axios.interceptors.response.use(
  response => response,
  (error) => {
    // auto logout if 401 or 403 response is returned from api
    if (error.response && [401, 403].includes(error.response.status)) {
      Notify.create({
        message: 'Error!',
        detail: 'You don\'t have access to requested content',
        type: 'negative',
      });
      store.dispatch('auth/login');
    }
    return Promise.reject(error);
  },
);

export default { notifyUnAuthorized };
