<template>
  <q-page :style-fn="pageHeight" class="column no-wrap">
    <portal to="app-header">
      <div class="page-title flex no-wrap justify-between">
        <span>Reporting</span>
        <!--<forc-selector
          v-model="selectedForc"
          name="forc-selector"
          class="forc-select"
          :forcs="twoAndFourDigitForcsForWorkingEra"
          :forc-description-length="150"
          align="left"
          placeholder="Select a 2-digit or 4-digit FoRC for reporting"
          @change="getReportData"
        />-->

        <forc-selector
          v-model="selectedForc"
          :forcs="sortedUserApplicableForcs"
          placeholder="Select a 2-digit or 4-digit FoRC for reporting"
          class="forc-select"
          name="forc-selector"
          :forc-description-length="150"
          align="left"
          @change="getReportData"
        />
      </div>
    </portal>
    <q-tabs
      v-model="selectedTab"
      class="head-bg-transparent shadow-1 z-fab q-mb-lg"
      style="overflow: visible !important"
      color="primary"
      two-lines
      inverted
    >
      <q-tab
        v-for="tab in tabs"
        :key="tab.label"
        slot="title"
        :name="tab.component"
        :label="tab.label"
      />
    </q-tabs>
    <keep-alive>
      <component :is="selectedTab" v-if="selectedTab" class="col-grow overflow-auto" />
      <div v-else>Tab not implemented yet!</div>
    </keep-alive>
    <radar-loading :visible="processCount > 0" />
  </q-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { VIEW_ANALYTICS_PERFORMANCE_METRICS } from '@/models/user/access/permissions';
import RadarLoading from '@/components/common/RadarLoading.vue';
import ForcSelector from '@/components/common/ForcSelector.vue';
import ReportingMixin from '@/mixins/ReportingMixin';
import RptIntradisciplinaryProfile from './reports/RptIntradisciplinaryProfile.vue';
import RptInterdisciplinaryProfile from './reports/RptInterdisciplinaryProfile.vue';
import RptVolumeAndActivity from './reports/RptVolumeAndActivity.vue';
import RptPublishingProfile from './reports/RptPublishingProfile.vue';
import RptCitationAnalysis from './reports/RptCitationAnalysis.vue';
import RptPeerReview from './reports/RptPeerReview.vue';
import RptResearchIncome from './reports/RptResearchIncome.vue';
import RptAppliedMeasures from './reports/RptAppliedMeasures.vue';

const CITATION_REPORT = 'Citation Analysis';
const PEER_REVIEW_REPORT = 'Peer Review';

const TABS = [
  { label: 'Intradisciplinary Profile', component: 'rpt-intradisciplinary-profile' },
  { label: 'Interdisciplinary Profile', component: 'rpt-interdisciplinary-profile' },
  { label: 'Volume and Activity', component: 'rpt-volume-and-activity' },
  { label: 'Publishing Profile', component: 'rpt-publishing-profile' },
  { label: CITATION_REPORT, component: 'rpt-citation-analysis' },
  { label: PEER_REVIEW_REPORT, component: 'rpt-peer-review' },
  { label: 'Research Income', component: 'rpt-research-income' },
  { label: 'Applied Measures', component: 'rpt-applied-measures' },
];

export default {
  name: 'ReportingHome',
  components: {
    RadarLoading,
    ForcSelector,
    RptIntradisciplinaryProfile,
    RptInterdisciplinaryProfile,
    RptVolumeAndActivity,
    RptPublishingProfile,
    RptCitationAnalysis,
    RptPeerReview,
    RptResearchIncome,
    RptAppliedMeasures,
  },
  mixins: [ReportingMixin],
  data() {
    return {
      selectedTab: TABS[0].component,
      selectedForc: null,
    };
  },
  computed: {
    ...mapState('reporting', ['processCount']),
    ...mapGetters('eras', ['workingEra']),
    ...mapGetters('auth', ['hasPermission', 'userForcsForRoClaim']),
    ...mapGetters('referenceData', ['getAllForcs', 'workingEraForcReference']),
    // ...mapGetters('referenceData', ['twoAndFourDigitForcsForWorkingEra']),
    canViewCitationAnalysis() {
      return this.hasPermission(VIEW_ANALYTICS_PERFORMANCE_METRICS);
    },
    tabs() {
      let excludedTab = null;
      if (this.selectedForc?.four_digit_forc_id) {
        excludedTab = this.selectedForc.is_peer_review_eligible ? CITATION_REPORT : PEER_REVIEW_REPORT;
      }
      const excludeCitationAnalysis = this.canViewCitationAnalysis ? '' : CITATION_REPORT;
      return TABS
        .filter(t => t.label !== excludedTab)
        .filter(t => t.label !== excludeCitationAnalysis);
    },
  },
  methods: {
    ...mapActions('reporting', [
      'setSelectedForc',
      'getIntraDisciplinaryProfile',
      'getInterDisciplinaryProfile',
      'getHerdcCount',
      'getFteProfile',
      'getRciClass',
      'getPublishingProfiles',
      'getResearchIncomeByYear',
      'getFteBenchmarks',
      'getCountryPatents',
      'getCommercialisationIncome',
      'progress',
    ]),
    async getReportData(forc) {
      if (forc.four_digit_forc_id
        && ((forc.is_peer_review_eligible && this.selectedTab === 'rpt-citation-analysis')
        || (!forc.is_peer_review_eligible && this.selectedTab === 'rpt-peer-review'))) {
        this.selectedTab = TABS[0].component;
      }

      await Promise.all([
        this.setSelectedForc(forc),
        this.getIntraDisciplinaryProfile(forc.id),
        this.getInterDisciplinaryProfile(forc.id),
        this.getHerdcCount(forc.id),
        this.getFteProfile(forc.id),
        this.getPublishingProfiles(forc.id),
        this.getResearchIncomeByYear(forc.id),
        this.getFteBenchmarks(forc.id),
        this.getCountryPatents(forc.id),
        this.getCommercialisationIncome(forc.id),
      ]);
    },
    pageHeight(offset) {
      const height = offset ? `calc(100vh - ${offset}px)` : '100vh';
      return { minHeight: height, maxHeight: height, overflowY: 'hidden' };
    },
  },
  created() {
    if (this.canViewCitationAnalysis) {
      this.getRciClass();
    }
  },
};
</script>

<style lang="stylus" scoped>
.forc-select
  min-width 400px
</style>
