<template>
  <div class="row">
    <div class="col-12">
      <form novalidate data-vv-scope="manual-forcs" @submit.prevent="save">
        <div>
          <template v-if="canUserManageResearcherClaim">
            <div class="headline-title"> Add a FoRC (Maximum 3)</div>
            <div class="claim-row">
              <claim-input
                ref="claimInput"
                :forcs="validForcsToBeAdded"
                :disable="claimsForEra.length === 3"
                name="researcher-claim-input"
                :min-apportion="10"
                @add="addNewClaim"
              />
            </div>
          </template>
          <div
            v-if="hasClaims && (hasDuplicateForc || !sumAddsToHundred)"
            class="text-negative bordered group"
          >
            <div v-if="hasDuplicateForc" class="group">
              <q-icon name="error" />
              Duplicate FoRC selected
            </div>
            <div v-if="!sumAddsToHundred" class="group">
              <q-icon name="error" />
              Sum of apportions should add to 100%
            </div>
          </div>
          <div class="q-mt-xl">
            <div class="headline-title q-mb-lg">Forcs</div>
            <div
              v-for="(researcherClaim, index) in claimsForEra"
              :key="(researcherClaim.forc.id + '-' + index)"
              class="claim-row"
              dense
            >
              <claim
                :forcs="validForcs"
                :claim="researcherClaim"
                :disable="!canUserManageResearcherClaim"
                :name="'manual-forcs.researcher-claim-'+researcherClaim.forc.id+ '-' + index"
                :min-apportion="10"
                validation-scope="manual-forcs"
              >
                <q-btn
                  v-if="canUserManageResearcherClaim"
                  slot="suffix"
                  class="remove-button"
                  title="Remove"
                  round
                  outline
                  size="sm"
                  text-color="negative"
                  icon="close"
                  @click="removeClaimAt(index)"
                />
              </claim>
            </div>
          </div>
          <q-item
            v-if="!claimsForEra.length"
            class="radar-info claim-row"
          >
            No manual override forcs
          </q-item>
        </div>
        <div v-if="canUserManageResearcherClaim" class="group q-mt-xl">
          <q-btn
            label="Save"
            color="primary"
            :disable="!(areClaimsValid && hasChanges)"
            @click="save()"
          />
          <q-btn
            label="Reset"
            color="tertiary"
            text-color="dark"
            @click="reset()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Era from '@/models/era/era';
import Researcher from '@/models/researcher/researcher';
import ResearcherClaim from '@/models/researcher/researcher-claim';
import ResearcherClaimVersion from '@/models/researcher/researcher-claim-version';
import PermissionsMixin from '@/mixins/PermissionsMixin';
import Claim from '@/components/researcher/common/ResearcherClaim.vue';
import ClaimInput from './ResearcherClaimInput.vue';

export default {
  name: 'ResearcherClaimVersion',
  components: {
    ClaimInput,
    Claim,
  },
  mixins: [PermissionsMixin],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    researcherClaimVersion: {
      type: ResearcherClaimVersion,
      default: () => new ResearcherClaimVersion(),
    },
    researcher: {
      type: Researcher,
      required: true,
    },
    era: {
      type: Era,
      required: true,
    },
    validForcs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      claimsForEra: [],
    };
  },
  computed: {
    hasDuplicateForc() {
      const forcIdsPresent = this.claimsForEra.map(claim => claim.forc.id);
      return (forcIdsPresent.length !== _.uniq(forcIdsPresent).length);
    },
    sumAddsToHundred() {
      return _.sumBy(this.claimsForEra, claim => claim.apportion) === 100;
    },
    hasClaims() {
      return !_.isEmpty(this.claimsForEra);
    },
    areClaimsValid() {
      return !this.hasClaims
        || (!this.hasDuplicateForc && this.sumAddsToHundred && !this.errorCountForManualForcs);
    },
    selectedForcs() {
      return this.claimsForEra.map(claim => claim.forc);
    },
    validForcsToBeAdded() {
      return _.differenceBy(this.validForcs, this.selectedForcs, 'id');
    },
    // workaround for vee-validate not able to inject proper validator in for-loop
    errorCountForManualForcs() {
      return this.errors.items.filter(item => item.scope === 'manual-forcs').length;
    },
    hasChanges() {
      if (this.claimsForEra.length !== this.researcherClaimVersion.researcher_claims.length) {
        return true;
      }

      const difference = _.differenceWith(
        this.claimsForEra,
        this.researcherClaimVersion.researcher_claims,
        (newClaim, originalClaim) => newClaim.isSameAs(originalClaim),
      );

      return !_.isEmpty(difference);
    },
  },
  methods: {
    resetForcForNewClaim() {
      this.$refs.claimInput.reset();
    },
    async save() {
      await this.$validator.validateAll('manual-forcs');
      if (!this.areClaimsValid) {
        return;
      }
      this.$emit('save', this.claimsForEra);
    },
    reset() {
      this.identifyClaimsForEra();
    },
    removeClaimAt(index) {
      this.claimsForEra.splice(index, 1);
    },
    addNewClaim(claim) {
      const researcherClaim = new ResearcherClaim(claim);
      this.claimsForEra.push(researcherClaim);
      this.resetForcForNewClaim();
    },
    identifyClaimsForEra() {
      this.claimsForEra = this.researcherClaimVersion.researcher_claims
        .map(researcherClaim => new ResearcherClaim(researcherClaim));
    },
  },
  watch: {
    researcherClaimVersion() {
      this.identifyClaimsForEra();
    },
  },
  mounted() {
    this.identifyClaimsForEra();
  },
};
</script>

<style lang="stylus" scoped>
.claim-row
  margin-top 15px
  padding 10px
  background-color $tertiary
  border 3px solid rgba(0, 0, 0, .1)
  border-radius 6px
</style>
