import { dom } from 'quasar';

export const isOverflown = element => element.scrollHeight > element.clientHeight
  || element.scrollWidth > element.clientWidth;

export const makeElementInvisibleOnOverflow = (element) => {
  const visibility = isOverflown(element) ? 'hidden' : 'visible';
  dom.css(element, { visibility });
};

export default { isOverflown, makeElementInvisibleOnOverflow };
